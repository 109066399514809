import { PROFIL_USER } from "constants/user/user";
import { IUserCellProps } from "interfaces/table/table";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import ChipStyled from "styles/components/chip/ChipStyled";

export const ProfileCell: FunctionComponent<IUserCellProps> = ({ cell }) => {
  const intl = useIntl();
  const profiluserElement = PROFIL_USER[cell.row.original.profile];
  return (
    <ChipStyled
      style={{ cursor: "pointer", color: profiluserElement.color }}
      label={intl.formatMessage({ id: profiluserElement.label })}
      color={profiluserElement.color}
    />
  );
};
