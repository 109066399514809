import React, {FunctionComponent} from "react"
import {Navigate, RouteProps, useLocation} from "react-router-dom";
import {HOME_PATH, LOGIN_PATH,} from "../../constants/routes/RoutePaths";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import {PermissionLine} from "../../constants/permissions/permissions";
import PermissionRoute from "./PermissionRoute";
import {authUtils} from "../../utils/api/authUtils";

/**
 * Route you can only access if you're logged in
 * @param children
 * @constructor
 */

interface PrivateRouteProps extends RouteProps {
    permission?: PermissionLine,
    redirectPath?: string
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
                                                                permission,
                                                                redirectPath = HOME_PATH,
                                                                children
                                                            }) => {

    const location = useLocation();
    const isLogged = !!localStorage.getItem(LocalStorageEnum.TOKEN);
    authUtils.refreshTokenExpirationDate();
    return isLogged ? <PermissionRoute permission={permission} redirectPath={redirectPath}>{children}</PermissionRoute> : <Navigate to={LOGIN_PATH} state={location.pathname !== "/login" ? location.pathname : null} replace/>;
}

export default PrivateRoute;
