import {styled} from "@mui/material/styles";
import {Paper, PaperProps} from "@mui/material";
import {paletteTheme} from "../../themes/palette";

export default styled(Paper)<PaperProps>(() => {
    return ({
        border: `1px solid ${paletteTheme.colors.GRAY}`,
        borderRadius: "0 0 2px 2px",
        flexGrow: 1,
        margin: 0,
        padding: 0,
        boxShadow: "none",
        minWidth: "150px",
        "& .MuiAutocomplete-listbox": {
            maxHeight: "250px",
            padding: 0,
            "& .MuiAutocomplete-option": {
                padding: "5px",
                whiteSpace: "nowrap",
                backgroundColor: "transparent",
                "&li[aria-selected='true']": {
                    backgroundColor: "transparent",
                    color: paletteTheme.colors.DEFAULT_TEXT_COLOR
                },
                "& .MuiBox-root": {
                    marginBottom: 0
                }
            },
            "& li[aria-selected='true']": {
                backgroundColor: "transparent",
                "& .MuiTypography-root": {
                    color: paletteTheme.colors.DEFAULT_TEXT_COLOR
                }
            }
        },
        "& .MuiAutocomplete-noOptions, & .MuiAutocomplete-loading": {
            padding: "10px",
            fontSize: "10px",
            fontWeight: 600,
            color: paletteTheme.colors.GRAY,
        }
    })
})
