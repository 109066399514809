import React from "react";
import styles from "components/atoms/table/SeparatorLine/separatorLine.module.scss";

export const SeparatorLine = () => {
  return (
    <div className={"flex flex-center " + styles.separator}>
      <span className={styles.dot} ></span>
      <span className={styles.dot} ></span>
      <span className={styles.dot} ></span>
    </div>
  );
};
