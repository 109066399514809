import React, {FunctionComponent} from "react";
import {Box, Card as CardMaterialUI, CardProps as CardPropsMaterialUI, Typography} from "@mui/material"
import {FormattedMessage} from "react-intl";
import {paletteTheme} from "../../../styles/themes/palette";


interface CardProps extends CardPropsMaterialUI {
    cardTitle?: string | JSX.Element,
    header?: string
}

const CardPopup: FunctionComponent<CardProps> = ({
                                                children,
                                                sx,
                                                cardTitle,
                                                header,
                                                ...rest
                                            }) =>
    (<CardMaterialUI {...rest} sx={{...sx, border: `2px solid ${paletteTheme.colors.PRIMARY}`}}>
        {cardTitle && <Box marginBottom={5}>{typeof cardTitle === "string" ? <FormattedMessage id={cardTitle}/> : cardTitle} </Box>}
        {header && <Typography variant="h2" marginBottom={5}><FormattedMessage id={header}></FormattedMessage></Typography>}
        {children}
    </CardMaterialUI>)


export default CardPopup;
