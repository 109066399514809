import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { BackLink } from "components/molecules/BackLink";
import Typography from "@mui/material/Typography";
import { paletteTheme } from "styles/themes/palette";
import styles from "components/molecules/transaction/TransactionDetailHistory/TransactionHistoryTitle/transactionHistoryTitle.module.scss";
import { MessageKeys } from "constants/messages/i8ln/EnglishMessages";

interface TransactionHistoryCardTitleProps {
  transactionId: string;
  title: MessageKeys;
  backUrl: string;
}

export const TransactionHistoryCardTitle: FunctionComponent<TransactionHistoryCardTitleProps> = ({
  transactionId,
  title,
  backUrl,
}) => {
  const intl = useIntl();

  return (
    <div className={`flex flex-col-center flex-row-between ${styles.wrapper}`}>
      <Typography variant="h2" paddingTop="4px" color={paletteTheme.colors.PRIMARY}>
        <span className={styles.transactions}>{intl.formatMessage({ id: title }).toUpperCase()}&nbsp;</span>
        <span className={styles["transaction-title"]}>
          {intl.formatMessage({ id: "transaction_det_page_title_2" }, { transactionId: transactionId }).toUpperCase()}
        </span>
      </Typography>
      <BackLink to={`/${backUrl}`} label="back_transactions_list" showCloseIconOnXSSM={true} />
    </div>
  );
};
