import React, {FunctionComponent} from "react";
import FlexBox from "../flex/FlexBox";
import {FlexAttributValue} from "../../../types/flex/flex";
import {Box, CircularProgress, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface TableFooterProps {
    totalCount: number,
    displayedCount: number,
    lastPage?: boolean,
    isLoadingNextPage: boolean,
    loading: boolean
}

const TablePagination: FunctionComponent<TableFooterProps> = ({
                                                                  totalCount,
                                                                  displayedCount,
                                                                  isLoadingNextPage,
                                                                  loading,
                                                              }) => {
    const { smDown, lgUp } = useMediaQueryUtil();
    
    return(
    <FlexBox marginTop={2} flexDirection={FlexAttributValue.ROW} justifyContent={FlexAttributValue.CENTER} style={{ marginLeft: lgUp || smDown ? "0" : "10%" }}>
        {!loading && totalCount > 0 ?
            <FlexBox flexDirection={FlexAttributValue.COLUMN} height={56} justifyContent={FlexAttributValue.CENTER}
                     alignItems={FlexAttributValue.CENTER}>
                <Typography textAlign="center" lineHeight="15px" marginBottom={1}>
                    <FormattedMessage id="number_element" values={{displayedCount, totalCount}}/>
                </Typography>
                {isLoadingNextPage && <CircularProgress color="primary" size={12}/>}
            </FlexBox>
            : <Box height={56}/>
        }
    </FlexBox>)

}
export default TablePagination;
