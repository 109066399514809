import { MessageKeys } from "constants/messages/i8ln/EnglishMessages";
import { FilterFieldType } from "interfaces/filter/filterColumn";

export interface TrendByFilterLine {
  id: string;
  label: MessageKeys;
  type?: FilterFieldType.MULTISELECT;
  multiple?: boolean;
  translateLabel?: boolean;
  displayCheckboxes?: boolean;
}

export interface TrendByFilterLines {
  [key: string]: TrendByFilterLine;
}

export const PORTFOLIO_TREND_BY_FILTERS: TrendByFilterLines = {
  TREND_BY: {
    id: "trendBy",
    label: "portf_filter_display_trend_by",
    type: FilterFieldType.MULTISELECT,
    translateLabel: false,
    multiple: false,
    displayCheckboxes: false,
  }
};
