import React, { FunctionComponent } from "react";
import { Entity } from "interfaces/perimeter/perimeter";
import { Company } from "interfaces/perimeter/company";
import { CardRow } from "components/atoms/card/CardRow";
import ChipList from "components/molecules/ChipList";
import { Switch } from "components/atoms/switch/Switch";
import Typography from "@mui/material/Typography";

interface AccountPerimeterProps {
  perimeter: Company;
  merchants: Array<Entity>;
}

export const UserAccountCompanyPerimeterDetails: FunctionComponent<AccountPerimeterProps> = ({ perimeter, merchants }) =>
(
  <div>
    <CardRow id="peri_company" label="peri_company">
      <Typography>{perimeter.company.name}</Typography>
    </CardRow>
    <CardRow id="peri_merchant" label="peri_merchant">
      <ChipList entities={merchants} />
    </CardRow>
    <CardRow id="no-label" label="no-label">
      <Switch
        cursor="default"
        disabled
        label="inherit_future_merchants"
        checked={perimeter.hasCompanyPerimeter}
      />
    </CardRow>
  </div>
)
