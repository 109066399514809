import React from "react";
import { KeyNumberElement } from "interfaces/transaction/transaction";
import { Options } from "constants/options/option";
import { PriceCheck, Store, Storefront } from "@mui/icons-material";
import { ManagableColumn } from "interfaces/table/table";
import { PortfolioTrendsData } from "components/molecules/portfolio/PortfolioTrendsSection";
import { PortfolioInfoTooltip } from "components/atoms/tooltip/PortfolioInfoTooltip";

export const PORTFOLIO_KEY_NUMBERS: Record<string, KeyNumberElement> = {
  NUMBER_OF_MERCHANT: {
    id: "nbEntities",
    label: "portf_merchants",
    icon: <Storefront />,
  },
  NUMBER_OF_COMPANY: {
    id: "nbEntities",
    label: "portf_companies",
    icon: <Store />,
  },
  TOTAL_DISTRIBUTOR_COMISSION: {
    id: "totalDistributorComission",
    label: "portf_dist_com",
    currency: true,
    icon: <PriceCheck />,
  },
  TOTAL_COMMISSION: {
    id: "totalCommission",
    label: "total_fees",
    currency: true,
    icon: <PriceCheck />,
  },
};

export const PORTFOLIO_TOP_SORTING: Array<Options> = [
  { id: "numberOfTransaction", name: "portf_col_nbr_tx" },
  { id: "grossAmount", name: "portf_col_gross_sum" },
  { id: "successRate", name: "portf_col_success_rate" },
];

// Portfolio Top/Bottom table columns

export const PORTFOLIO_COLUMNS: Record<string, ManagableColumn> = {
  ID: {
    id: "ID",
    accessor: "id",
    header: "rank",
    order: 0,
    cell: {
      type: "text",
    },
    options: {
      maxWidth: 30,
      width: 30,
      minWidth: 30,
    },
    disableSortBy: true,
  },
  COMPANY_NAME: {
    id: "COMPANY_NAME",
    accessor: "COMPANY_NAME",
    header: "tpd_company_name",
    order: 1,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  MERCHANT_NAME: {
    id: "MERCHANT_NAME",
    accessor: "MERCHANT_NAME",
    header: "tpd_merchant_name",
    order: 1,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  NUMBER_TRANSACTION: {
    id: "NUMBER_TRANSACTION",
    accessor: "NUMBER_TRANSACTION",
    header: "portf_col_nbr_tx",
    order: 2,
    cell: {
      type: "number",
      defaultValue: "0",
    },
  },
  GROSS_AMOUNT: {
    id: "GROSS_AMOUNT",
    accessor: "GROSS_AMOUNT",
    header: "portf_col_gross_sum",
    order: 3,
    cell: {
      type: "money",
      defaultValue: "0",
    },
  },
  SUCCESS_RATE: {
    id: "SUCCESS_RATE",
    accessor: "SUCCESS_RATE",
    header: "portf_col_success_rate",
    order: 4,
    cell: {
      type: "percent",
      defaultValue: "",
    },
    disableSortBy: true,
    tooltip: "portf_tooltip_tx_success_rate",
  },
  DISTRIBUTOR_COMMISSION: {
    id: "DISTRIBUTOR_COMMISSION",
    accessor: "DISTRIBUTOR_COMMISSION",
    header: "portf_col_dist_com",
    order: 5,
    cell: {
      type: "money",
      defaultValue: "0",
    },
    disableSortBy: true,
    tooltip: "portf_tooltip_distributor_commission",
  },
  MARKET_PAY_COMMISSION: {
    id: "MARKET_PAY_COMMISSION",
    accessor: "MARKET_PAY_COMMISSION",
    header: "portf_col_mp_com",
    order: 6,
    cell: {
      type: "money",
      defaultValue: "0",
    },
    disableSortBy: true,
    tooltip: "portf_tooltip_mp_commission",
  },
  AVG_COMMISSION_RATE: {
    id: "AVG_COMMISSION_RATE",
    accessor: "AVG_COMMISSION_RATE",
    header: "portf_col_avg_com_rate",
    order: 7,
    cell: {
      type: "percent",
      defaultValue: "",
    },
    disableSortBy: true,
    tooltip: "portf_tooltip_avg_commission_rate",
  },

  TOTAL_COMMISSION: {
    id: "TOTAL_COMMISSION",
    accessor: "TOTAL_COMMISSION",
    header: "portf_col_total_commission",
    order: 8,
    cell: {
      type: "money",
      defaultValue: "0",
    },
    disableSortBy: true,
    tooltip: "portf_tooltip_total_commission",
  },
};

// Portfolio volume trends table columns

export const TRENDS_PORTFOLIO_COLUMNS: Record<string, ManagableColumn> = {
  ID: {
    id: "ID",
    accessor: "id",
    header: "rank",
    order: 0,
    cell: {
      type: "text",
    },
    options: {
      maxWidth: 25,
      minWidth: 20,
    },
    disableSortBy: true,
  },
  MERCHANT_NAME: {
    id: "MERCHANT_NAME",
    accessor: "merchant",
    header: "tpd_merchant_name",
    order: 1,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  DAY_PERCENTAGE_VOLUME: {
    id: "DAY_PERCENTAGE_VOLUME",
    accessor: "RATE_VOLUME_EVOLUTION_DAY",
    header: "portf_col_day",
    order: 4,
    cell: {
      type: "percent",
      defaultValue: "",
    },
    tooltip: "portf_col_day_tooltip",
    isTooltipCell: true,
    tooltipCell: (item: PortfolioTrendsData) => (
      <PortfolioInfoTooltip
        firstTitle="portf_col_gross_sum"
        secondTitle="homepage_graph_leg_num"
        labelEvolution={"tooltip_daily_evolution"}
        amount1={item.tooltipData.tooltipDay1Amount}
        amount2={item.tooltipData.tooltipDay2Amount}
        startDate={item.tooltipData.tooltipDay1Date}
        endDate={item.tooltipData.tooltipDay2Date}
        volume1={item.tooltipData.tooltipDay1Volume}
        volume2={item.tooltipData.tooltipDay2Volume}
        rateAmount={item.RATE_AMOUNT_EVOLUTION_DAY}
        rateVolume={item.RATE_VOLUME_EVOLUTION_DAY}
      />
    ),
  },
  WEEK_PERCENTAGE_VOLUME: {
    id: "WEEK_PERCENTAGE_VOLUME",
    accessor: "RATE_VOLUME_EVOLUTION_WEEK",
    header: "portf_col_week",
    order: 5,
    cell: {
      type: "percent",
      defaultValue: "",
    },
    tooltip: "portf_col_week_tooltip",
    isTooltipCell: true,
    tooltipCell: (item: PortfolioTrendsData) => (
      <PortfolioInfoTooltip
        firstTitle="portf_col_gross_sum"
        secondTitle="homepage_graph_leg_num"
        labelEvolution={"tooltip_weekly_evolution"}
        amount1={item.tooltipData.tooltipWeek1Amount}
        amount2={item.tooltipData.tooltipWeek2Amount}
        startDate={item.tooltipData.tooltipWeek1Start + " - " + item.tooltipData.tooltipWeek1End}
        endDate={item.tooltipData.tooltipWeek2Start + " - " + item.tooltipData.tooltipWeek2End}
        volume1={item.tooltipData.tooltipWeek1Volume}
        volume2={item.tooltipData.tooltipWeek2Volume}
        rateAmount={item.RATE_AMOUNT_EVOLUTION_WEEK}
        rateVolume={item.RATE_VOLUME_EVOLUTION_WEEK}
      />
    ),
  },
  MONTH_PERCENTAGE_VOLUME: {
    id: "MONTH_PERCENTAGE_VOLUME",
    accessor: "RATE_VOLUME_EVOLUTION_MONTH",
    header: "portf_col_month",
    order: 6,
    cell: {
      type: "percent",
      defaultValue: "",
    },
    tooltip: "portf_col_month_tooltip",
    isTooltipCell: true,
    tooltipCell: (item: PortfolioTrendsData) => (
      <PortfolioInfoTooltip
        firstTitle="portf_col_gross_sum"
        secondTitle="homepage_graph_leg_num"
        labelEvolution={"tooltip_monthly_evolution"}
        amount1={item.tooltipData.tooltipMonth1Amount}
        amount2={item.tooltipData.tooltipMonth2Amount}
        startDate={item.tooltipData.tooltipMonth1Start + " - " + item.tooltipData.tooltipMonth1End}
        endDate={item.tooltipData.tooltipMonth2Start + " - " + item.tooltipData.tooltipMonth2End}
        volume1={item.tooltipData.tooltipMonth1Volume}
        volume2={item.tooltipData.tooltipMonth2Volume}
        rateAmount={item.RATE_AMOUNT_EVOLUTION_MONTH}
        rateVolume={item.RATE_VOLUME_EVOLUTION_MONTH}
      />
    ),
  },
  DAY_PERCENTAGE_AMOUNT: {
    id: "DAY_PERCENTAGE",
    accessor: "RATE_AMOUNT_EVOLUTION_DAY",
    header: "portf_col_day",
    order: 4,
    cell: {
      type: "percent",
      defaultValue: "",
    },
    tooltip: "portf_col_day_tooltip",
    isTooltipCell: true,
    tooltipCell: (item: PortfolioTrendsData) => (
      <PortfolioInfoTooltip
        firstTitle="portf_col_gross_sum"
        secondTitle="homepage_graph_leg_num"
        labelEvolution={"tooltip_daily_evolution"}
        amount1={item.tooltipData.tooltipDay1Amount}
        amount2={item.tooltipData.tooltipDay2Amount}
        startDate={item.tooltipData.tooltipDay1Date}
        endDate={item.tooltipData.tooltipDay2Date}
        volume1={item.tooltipData.tooltipDay1Volume}
        volume2={item.tooltipData.tooltipDay2Volume}
        rateAmount={item.RATE_AMOUNT_EVOLUTION_DAY}
        rateVolume={item.RATE_VOLUME_EVOLUTION_DAY}
      />
    ),
  },
  WEEK_PERCENTAGE_AMOUNT: {
    id: "WEEK_PERCENTAGE",
    accessor: "RATE_AMOUNT_EVOLUTION_WEEK",
    header: "portf_col_week",
    order: 5,
    cell: {
      type: "percent",
      defaultValue: "",
    },
    tooltip: "portf_col_week_tooltip",
    isTooltipCell: true,
    tooltipCell: (item: PortfolioTrendsData) => (
      <PortfolioInfoTooltip
        firstTitle="portf_col_gross_sum"
        secondTitle="homepage_graph_leg_num"
        labelEvolution={"tooltip_weekly_evolution"}
        amount1={item.tooltipData.tooltipWeek1Amount}
        amount2={item.tooltipData.tooltipWeek2Amount}
        startDate={item.tooltipData.tooltipWeek1Start + " - " + item.tooltipData.tooltipWeek1End}
        endDate={item.tooltipData.tooltipWeek2Start + " - " + item.tooltipData.tooltipWeek2End}
        volume1={item.tooltipData.tooltipWeek1Volume}
        volume2={item.tooltipData.tooltipWeek2Volume}
        rateAmount={item.RATE_AMOUNT_EVOLUTION_WEEK}
        rateVolume={item.RATE_VOLUME_EVOLUTION_WEEK}
      />
    ),
  },
  MONTH_PERCENTAGE_AMOUNT: {
    id: "MONTH_PERCENTAGE",
    accessor: "RATE_AMOUNT_EVOLUTION_MONTH",
    header: "portf_col_month",
    order: 6,
    cell: {
      type: "percent",
      defaultValue: "",
    },
    tooltip: "portf_col_month_tooltip",
    isTooltipCell: true,
    tooltipCell: (item: PortfolioTrendsData) => (
      <PortfolioInfoTooltip
        firstTitle="tooltip_gross_sum"
        secondTitle="tooltip_nb_transaction"
        labelEvolution={"tooltip_monthly_evolution"}
        amount1={item.tooltipData.tooltipMonth1Amount}
        amount2={item.tooltipData.tooltipMonth2Amount}
        startDate={item.tooltipData.tooltipMonth1Start + " - " + item.tooltipData.tooltipMonth1End}
        endDate={item.tooltipData.tooltipMonth2Start + " - " + item.tooltipData.tooltipMonth2End}
        volume1={item.tooltipData.tooltipMonth1Volume}
        volume2={item.tooltipData.tooltipMonth2Volume}
        rateAmount={item.RATE_AMOUNT_EVOLUTION_MONTH}
        rateVolume={item.RATE_VOLUME_EVOLUTION_MONTH}
      />
    ),
  },
};

// Billing table columns

export const BILLING_COLUMNS: Record<string, ManagableColumn> = {
  COMPANY: {
    id: "COMPANY",
    accessor: "COMPANY",
    header: "col_man_company_name",
    order: 0,
    cell: {
      type: "text",
    },
  },
  SETTLEMENT_MONTH: {
    id: "SETTLEMENT_MONTH",
    accessor: "SETTLEMENT_MONTH",
    header: "col_settlement_month",
    order: 1,
    cell: {
      type: "text",
    },
  },
  DISTRIB_AMT: {
    id: "DISTRIB_AMT",
    accessor: "DISTRIB_AMT",
    header: "col_retrocession",
    order: 2,
    cell: {
      type: "money",
    },
  },
  SHARE_MARGIN_RATE: {
    id: "SHARE_MARGIN_RATE",
    accessor: "SHARE_MARGIN_RATE",
    header: "distrib_share_margin_rate",
    order: 3,
    cell: {
      type: "percent",
    },
    disableSortBy: true,
  },
  SHARE_MARGIN_AMOUNT: {
    id: "SHARE_MARGIN_AMOUNT",
    accessor: "SHARE_MARGIN_AMOUNT",
    header: "distrib_share_margin_amt",
    order: 4,
    cell: {
      type: "money",
    },
    disableSortBy: true,
  },
  TRANSACTION_NUMBER: {
    id: "TRANSACTION_NUMBER",
    accessor: "TRANSACTION_NUMBER",
    header: "portf_col_nbr_tx",
    order: 5,
    cell: {
      type: "number",
    },
  },
  GROSS_AMOUNT: {
    id: "GROSS_AMOUNT",
    accessor: "GROSS_AMOUNT",
    header: "col_man_gross_amount",
    order: 6,
    cell: {
      type: "money",
    },
  },
  MERCHANT_FEES: {
    id: "MERCHANT_FEES",
    accessor: "MERCHANT_FEES",
    header: "col_man_total_fees",
    order: 7,
    cell: {
      type: "money",
    },
  },
  NET_AMOUNT: {
    id: "NET_AMOUNT",
    accessor: "NET_AMOUNT",
    header: "col_man_net_amount",
    order: 8,
    cell: {
      type: "money",
    },
  },
  INTERCHANGE_FEES: {
    id: "INTERCHANGE_FEES",
    accessor: "INTERCHANGE_FEES",
    header: "col_interchange_fees",
    order: 9,
    cell: {
      type: "money",
    },
  },
  SCHEME_FEES: {
    id: "SCHEME_FEES",
    accessor: "SCHEME_FEES",
    header: "col_man_scheme_fees",
    order: 10,
    cell: {
      type: "money",
    },
  },

  // MPT-1352 - hide columns 

  // PROCESSING_FEES_AMT_TTC: {
  //   id: "PROCESSING_FEES_AMT_TTC",
  //   accessor: "PROCESSING_FEES_AMT_TTC",
  //   header: "col_processing_ttc",
  //   order: 11,
  //   cell: {
  //     type: "money",
  //   },
  // },
  // PROCESSING_FEES_AMT_TVA: {
  //   id: "PROCESSING_FEES_AMT_TVA",
  //   accessor: "PROCESSING_FEES_AMT_TVA",
  //   header: "col_processing_tva",
  //   order: 12,
  //   cell: {
  //     type: "percent",
  //   },
  //   disableSortBy: true,
  // },
  // PROCESSING_FEES_AMT_HT: {
  //   id: "PROCESSING_FEES_AMT_HT",
  //   accessor: "PROCESSING_FEES_AMT_HT",
  //   header: "col_processing_ht",
  //   order: 13,
  //   cell: {
  //     type: "money",
  //   },
  // },
  // TRANSACTION_TYPE: {
  //   id: "TRANSACTION_TYPE",
  //   accessor: "TRANSACTION_TYPE",
  //   header: "col_man_trans_type",
  //   order: 14,
  //   cell: {
  //     type: "text",
  //   },
  // },
  // UUID: {
  //   id: "UUID",
  //   accessor: "UUID",
  //   header: "trans_det_tab_uuid",
  //   order: 15,
  //   cell: {
  //     type: "text",
  //   },
  //   disableSortBy: true,
  // },
  // COUNTRY: {
  //   id: "COUNTRY",
  //   accessor: "COUNTRY",
  //   header: "col_man_country",
  //   order: 16,
  //   cell: {
  //     type: "text",
  //   },
  // },
  
  IMPOSTA_DI_BOLLO: {
    id: "IMPOSTA_DI_BOLLO",
    accessor: "IMPOSTA_DI_BOLLO",
    header: "col_imposta_di_bollo",
    order: 17,
    cell: {
      type: "money",
    },
  },
};
