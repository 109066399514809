import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Range, RangeKeyDict } from "react-date-range";
import { DateRangePickerCalendar } from "components/molecules/datepicker/DateRangePickerCalendar";
import { useIntl } from "react-intl";
import { paletteTheme } from "styles/themes/palette";
import { MaterialUiColor } from "types/materialui/materialui";
import FilterTooltip from "components/molecules/tooltip/FilterTooltip";
import { filterTooltipUtils } from "utils/filterTooltipUtils";
import { useFilterTooltip } from "hooks/tooltip/useFilterTooltip";
import { Popover } from "@mui/material";
import { Event } from "@mui/icons-material";
import OutlinedTextFieldFilterStyled from "styles/components/textfield/OutlinedTextFieldFilterStyled";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

export interface DateRangePickerInputProps {
    id: string;
    range: Range;
    initialStartDate: string;
    initialEndDate: string;
    onChange: ((rangesByKey: RangeKeyDict) => void);
    onReset: () => void;
    label?: string;
}

export const DateRangePickerInput: FunctionComponent<DateRangePickerInputProps> = (props) => {

    const { id, range, label, onReset: onResetProps, onChange, initialStartDate, initialEndDate } = props;

    const intl = useIntl();

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [isMobile, setIsMobile] = useState(false);
    const [valueFormatted, setValueFormatted] = useState<string>("");

    const divRef = useRef<HTMLDivElement>();
    const { displayTooltip, setDisplayTooltip } = useFilterTooltip();
    const { smUp, smDown } = useMediaQueryUtil();

    const isOverflow = filterTooltipUtils.isOverflow(divRef.current?.firstElementChild.firstElementChild);

    const onReset = () => {
        onResetProps()
        setValueFormatted("");
    }

    const handleClick = () => {
        setAnchorEl(divRef.current);
    };

    const handleClickIcon = () => {
        setAnchorEl(divRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        let newValue = range.startDate ? intl.formatDate(range.startDate) : null;
        if (newValue !== null && range.endDate.getTime() !== range.startDate.getTime()) {
            newValue += ` - ${intl.formatDate(range.endDate)}`
        }
        setValueFormatted(newValue ?? "")
    }, [range, intl.locale]);

    useEffect(() => {
        smDown ? setIsMobile(true) : setIsMobile(false);
    }, [smDown, smUp])

    return (
        <FilterTooltip overflowText={label} placement="top" openTooltip={isOverflow && displayTooltip} isPopoverFilter>
            <div ref={divRef} className="position-relative d-inline-block">
                <OutlinedTextFieldFilterStyled
                    value={valueFormatted}
                    label={label}
                    sx={{ cursor: "pointer" }}
                    onChange={(e) => e.preventDefault()}
                    onFocus={(e) => e.preventDefault()}
                    onClick={handleClick}
                    onMouseEnter={() => setDisplayTooltip(true)}
                    onMouseLeave={() => setDisplayTooltip(false)}
                />
                <Event
                    sx={{ position: "absolute", right: "4px", top: "3px", fontSize: 19, cursor: "pointer" }}
                    onClick={handleClickIcon}
                    color={MaterialUiColor.PRIMARY} />
                <Popover
                    id={id}
                    anchorEl={anchorEl}
                    PaperProps={{
                        elevation: 0,
                        sx: { border: `1px solid ${paletteTheme.colors.PRIMARY}`, background: `${paletteTheme.colors.WHITE}`, borderRadius: "4px !important" }
                    }}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <DateRangePickerCalendar
                        range={range}
                        onChange={onChange}
                        onReset={onReset}
                        initialStartDate={initialStartDate}
                        initialEndDate={initialEndDate}
                        isMobile={isMobile}
                    />
                </Popover>
            </div>
        </FilterTooltip>
    );
}
