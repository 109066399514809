import React, {FunctionComponent} from "react";
import {authUtils} from "../../../utils/api/authUtils";
import {SelectProps, Select as MuiSelect} from "@mui/material";


const Select: FunctionComponent<SelectProps> = ({onOpen, onClose, children, ...rest}) => {

    const onOpenWithTracker = (event: React.ChangeEvent<HTMLInputElement>) => {
        authUtils.refreshTokenExpirationDate();
        onOpen && onOpen(event);
    };

    const onCloseWithTracker = (event: React.ChangeEvent<HTMLInputElement>) => {
        authUtils.refreshTokenExpirationDate();
        onClose && onClose(event);
    };

    return (<MuiSelect
        {...rest}
        onOpen={onOpenWithTracker}
        onClose={onCloseWithTracker}>{children}</MuiSelect>);
}


export default Select;
