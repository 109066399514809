import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { RouteProps, useNavigate } from "react-router-dom";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { useTransactionFilters } from "hooks/filter/useTransactionFilters";
import { GenericSearchData } from "interfaces/table/TableInterface";
import { AcquiredTransactionSearchResponse } from "interfaces/api/ApiInterfaces";
import { transactionService } from "services/transaction/TransactionService";
import { useGenericTableFetch } from "hooks/table/useGenericTableFetch";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import { transactionUtils } from "utils/business/transactionUtils";
import { computeColumns, getAcquiredTransactionsColumns, getManagableColumns } from "utils/table/tableUtils";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { ManagableColumn } from "interfaces/table/table";
import { AcquiredTransactionData, AcquiredTransactionResponse } from "interfaces/transaction/acquiredTransaction";
import styles from "view/TransactionView/AcquiredTrransactionsView/acquiredTrransactionsView.module.scss";
import Card from "components/atoms/card/Card";
import { FilterLayout } from "components/layouts/FilterLayout";
import Table from "components/atoms/table/Table";
import Typography from "@mui/material/Typography";
import { paletteTheme } from "styles/themes/palette";
import { KeyNumbers } from "interfaces/transaction/transaction";
import { ACUIRED_KEY_NUMBERS, DEFAULT_ACUIRED_KEY_NUMBERS } from "constants/transaction/transaction";
import { KeyNumbersCards } from "components/molecules/keyNumbersCards";
import { Row } from "react-table";
import { ACQUIRED_TRANSACTIONS_PATH } from "constants/routes/RoutePaths";
import { useIntl } from "react-intl";
import ContainedButton from "components/atoms/button/ContainedButton";
import ManageColumnDialog from "components/molecules/dialog/ManageColumn/ManageColumnDialog";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import { useTransactionFilterConfig } from "hooks/filter/useTransactionFilterConfig";
import {
  ACQUIRED_TRANSACTIONS_ADDITIONAL_FILTERS,
  ACQUIRED_TRANSACTIONS_MAIN_FILTERS,
} from "constants/transaction/transactionFilter";
import { CONTENT_TYPE_EXPORT_TRANSACTION } from "constants/export/export";
import { TransactionType } from "interfaces/filter/filterColumn";
import { useExport } from "hooks/export/useExport";
import ManyExportLineDialog from "components/molecules/dialog/ManyExportLineDialog";
import { rightUtils } from "utils/business/rightUtils";
import { useAuthUser } from "hooks/useAuthUser";
import { DropdownButton } from "components/atoms/button/DropdownButton";

export const AcquiredTransactionsView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const { user } = useAuthUser();
  const isAdmin = rightUtils.isAdmin(user);
  const isDistributor = rightUtils.isDistributor(user);
  const { formatMessage } = useIntlFormatter();
  const [totalCount, setTotalCount] = useState<number | string>(0);
  const { smDown, mdDown, lgDown, lgUp } = useMediaQueryUtil();
  const [isMobile, setIsMobile] = useState(false);
  const [keyNumbers, setKeyNumbers] = useState<KeyNumbers>(DEFAULT_ACUIRED_KEY_NUMBERS);
  const [openManageColumn, setOpenManageColumn] = useState<boolean>(false);
  const [managedColumns, setManagedColumns] = useState<Array<ManagableColumn>>([]);

  const {
    additionalFilterConfig,
    transactionFilters,
    transactionAdditionalFilters,
    showContent,
    tableRef,
    sharedFilterProps,
  } = useTransactionFilters(TransactionType.ACQUIRED);

  const navigate = useNavigate();

  const localStorageManagedColumns: ManagableColumn[] = JSON.parse(
    localStorage.getItem(LocalStorageEnum.ACQUIERED_TRANSACTION_COLUMN)
  );

  const { mainFilters, additionalFilters } = useTransactionFilterConfig({
    typeTransaction: TransactionType.ACQUIRED,
    initialMainFilter: ACQUIRED_TRANSACTIONS_MAIN_FILTERS,
    initialAdditionalFilters: ACQUIRED_TRANSACTIONS_ADDITIONAL_FILTERS,
    configs: {
      sharedFilterProps,
      ...additionalFilterConfig,
    },
  });

  const columns = useMemo(() => {
    const defaulStyles = {
      width: smDown ? 70 : 90,
      minWidth: 70,
      maxWidth: 300,
    };
    const enabledColumn = managedColumns.filter((column) => !column.disableColumn);
    return [...computeColumns(enabledColumn, intl, defaulStyles)];
  }, [managedColumns, intl.locale]);

  const acquiredTransactionsResponse = (
    response: AcquiredTransactionSearchResponse<AcquiredTransactionResponse>
  ): GenericSearchData<AcquiredTransactionData> => {
    setKeyNumbers({ ...response.aggregation, totalElements: response.totalElements });
    return transactionUtils.acquiredTransactionsResponse(response, intl);
  };
  const { onFetchData, count } = useGenericTableFetch<AcquiredTransactionResponse, AcquiredTransactionData>(
    transactionService.getAcquiredTransactions(transactionFilters, transactionAdditionalFilters),
    acquiredTransactionsResponse
  );

  const onChangeColumns = (_columns: Array<ManagableColumn>) => {
    const enabledColumn = _columns.filter((column) => !column.disableColumn);
    localStorage.setItem(LocalStorageEnum.ACQUIERED_TRANSACTION_COLUMN, JSON.stringify(enabledColumn));
    setManagedColumns(enabledColumn);
  };



  useEffect(() => {
    if (smDown) {
      setManagedColumns(getAcquiredTransactionsColumns("xs", isDistributor, isAdmin));
      setIsMobile(true);
      setOpenManageColumn(false);
      return;
    }
    if (mdDown) {
      setIsMobile(false);
      setOpenManageColumn(false);
      return;
    }
    if (lgDown) {
      setManagedColumns(getAcquiredTransactionsColumns("sm", isDistributor, isAdmin));
      setIsMobile(false);
      setOpenManageColumn(false);
      return;
    }
    setManagedColumns(localStorageManagedColumns || getAcquiredTransactionsColumns("md", isDistributor, isAdmin));
    setIsMobile(false);
    return;
  }, [smDown, mdDown, lgDown, lgUp]);

  const { openManyExportLine, setOpenManyExportLine, isExportLoading, onDownload } = useExport(
    count,
    process.env.REACT_APP_MAX_TRANSACTION_EXPORT,
    (type: string) =>
      transactionService.downloadExportAcquiredTransactions(transactionFilters, transactionAdditionalFilters, type),
    "acquired_transaction"
  );

  return (
    <Card
      cardTitle={
        <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
          {formatMessage("acq_transactions_title")}
        </Typography>
      }>
      <div className={`flex flex-row-between flex-col-start ${styles["label-saved-view-wrap"]}`}>
        <Typography variant="h2" marginBottom="10px">
          {formatMessage("filter_by")}
        </Typography>
      </div>
      <FilterLayout
        mainFilters={mainFilters}
        additionalFilters={additionalFilters}
        expandCollapseFiltersConfig={additionalFilterConfig.expandCollapseFiltersConfig}
      />
      <KeyNumbersCards
        keyNumberElements={ACUIRED_KEY_NUMBERS}
        keyNumbers={showContent ? keyNumbers : DEFAULT_ACUIRED_KEY_NUMBERS}
        title="acq_trans_key_numbers_title"
      />
      <div style={{ display: showContent ? "block" : "none" }}>
        <div className={`flex flex-row-between flex-col-end ${styles["wrap-man-button"]}`}>
          <Typography>
            {formatMessage("table_label", {
              count: <strong style={{ color: paletteTheme.colors.PRIMARY }}>{totalCount}</strong>,
            })}
          </Typography>
          {lgUp && (
            <div>
              <ContainedButton onClick={() => setOpenManageColumn(true)}>
                {formatMessage("col_man_button")}
              </ContainedButton>
            </div>
          )}
        </div>

        <Table
          ref={tableRef}
          columns={columns}
          onFetch={onFetchData}
          onClickRow={(row: Row<AcquiredTransactionData>) => {
            navigate(
              `/${ACQUIRED_TRANSACTIONS_PATH}/${encodeURIComponent(row.original.original.transactionSettlementId)}`,
              {
                state: { transactionDetails: row.original.original },
              }
            );
          }}
          FooterButtonList={
            <>
              <DropdownButton
                isExportLoading={isExportLoading}
                onDownload={onDownload}
                availableExports={CONTENT_TYPE_EXPORT_TRANSACTION}
              />
            </>
          }
          disableNextPage={!perimeterUtils.hasPerimeterFilters(transactionFilters)}
          updateTotalCountParent={(count: number | string) => setTotalCount(count)}
          defaultSortBy={{ id: "OPERATION_DATE", desc: true }}
        />
      </div>

      {openManageColumn && lgUp && (
        <ManageColumnDialog
          manageableColumns={getManagableColumns(isDistributor, isAdmin)}
          defaultColumns={getAcquiredTransactionsColumns("md", isDistributor, isAdmin)}
          openDialog={openManageColumn}
          onCloseDialog={() => setOpenManageColumn(false)}
          submitMessage="col_man_apply"
          columns={managedColumns}
          onChangeColumns={onChangeColumns}
        />
      )}

      {openManyExportLine && (
        <ManyExportLineDialog
          openDialog={openManyExportLine}
          onCloseDialog={() => setOpenManyExportLine(false)}
          maxLines={process.env.REACT_APP_MAX_TRANSACTION_EXPORT}
        />
      )}
    </Card>
  );
};
