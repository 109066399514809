import { User } from "interfaces/user/user";
import { rightUtils } from "utils/business/rightUtils";

export const isSwitchVisible = (
    authUser: User,
    user: User,
    isCreate = false
): boolean => {
    if (authUser.profile !== "ADMIN")
        return !isCreate && rightUtils.isDistributor(authUser);
    if (user.profile === "ADMIN")
        return false;
    return !isCreate;
}
