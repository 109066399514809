import { SearchData, TableData } from "interfaces/table/TableInterface";
import { Dispatch, ForwardedRef, SetStateAction, useEffect, useImperativeHandle, useState } from "react";
import { sortUtils } from "utils/api/sortUtils";
import { ApiError, errorUtils } from "utils/api/errorUtils";
import { TableRef } from "components/atoms/table/Table";
import { SortingRule } from "react-table";
import { QueryParam } from "interfaces/UserInterface";

interface TableInstance {
  isMounted: boolean;
  loading: boolean;
  fetchError: boolean;
  onFetchDataWithFilters: () => void;
}

export interface StaticTableOptions {
  defaultSortBy: SortingRule<object>;
  sortBy: Array<SortingRule<object>>;
  pageSize: number;
}

export function useStaticTable(
  onChangeData: Dispatch<SetStateAction<TableData[]>>,
  onFetch: (param: QueryParam) => Promise<SearchData>,
  { defaultSortBy, sortBy, pageSize}: StaticTableOptions,
  ref: ForwardedRef<TableRef>
): TableInstance {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<boolean>(false);

  const onFetchDataWithFilters = (afterFetch?: () => void) => {
    setFetchError(false);
    onFetch(sortUtils.sortToPageable(0, pageSize, sortUtils.getCurrentSort(sortBy, defaultSortBy)))
      .then((res) => {
        onChangeData(res.datas);
        afterFetch && afterFetch();
      })
      .catch((e: Promise<ApiError> | ApiError) => {
        errorUtils.handleBackErrors(e);
        // On fetch error, display error message and remove loader
        setLoading(false);
        setFetchError(true);
      });
  };

  // Fetch with new filters with loader
  const onFetchDataWithNewFiltersWithLoading = () => {
    setLoading(true);
    onFetchDataWithFilters(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    onFetchDataWithNewFiltersWithLoading();
    setIsMounted(true);
  }, []);

  // Access to fetch outside of table!
  useImperativeHandle(ref, () => ({
    fetch: onFetchDataWithNewFiltersWithLoading,
    fetchOnSort: onFetchDataWithFilters,
  }));

  return {
    loading,
    isMounted,
    fetchError,
    onFetchDataWithFilters,
  };
}
