import React, { FunctionComponent } from "react";
import { RouteProps, useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { MaterialUiColor } from "types/materialui/materialui";
import ContainedButton from "components/atoms/button/ContainedButton";
import { LOGIN_PATH } from "constants/routes/RoutePaths";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { Stack, Typography } from "@mui/material";
import styles from "view/password/password.module.scss";

export const UpdatePasswordConfirmView: FunctionComponent<RouteProps> = () => {

    const navigate = useNavigate()
    const backToLogin = () => {
        navigate(`/${LOGIN_PATH}`);
    }
    const { smDown } = useMediaQueryUtil();

    const type = new URLSearchParams(useLocation().search).get("type");
    const isInit = type === "init";

    return (
        <Stack className={styles["stack-auth-layout"]}>
            <div className={"flex flex-col flex-col-center"} >
                <Typography variant="h1" color={MaterialUiColor.PRIMARY} className="color-primary fw-bold uppercase">
                    <FormattedMessage id={isInit ? "init_pw_page_title" : "set_password"} />
                </Typography>
            </div>
            <div className={"flex flex-col flex-col-center"} >
                <Typography variant="h4" textAlign="center" fontWeight={300}>
                    <FormattedMessage id={isInit ? "init_pw_page2_text" : "reset_password_confirmation"} />
                </Typography>
            </div>
            <div className={"flex flex-col flex-col-center"} >
                <ContainedButton
                    type="button"
                    onClick={backToLogin}
                    style={
                        {
                            width: smDown ? "100%" : "auto",
                            height: smDown ? "40px" : "32px",
                        }
                    }
                >
                    <FormattedMessage id="back_login" />
                </ContainedButton>
            </div>
        </Stack>
    )
}
