import { toastUtils } from "utils/toast/toastUtils";
import { languageUtils } from "utils/common/languageUtils";

export interface ValidationError {
  code: string,
  field: string,
  message: string
}

export type PromisableApiError = ApiError | Promise<ApiError>;

export interface ApiError extends Error {
  error: string;
  status: number;
  message: string;
  path: string;
  errors: Array<ValidationError>;
  body?: object;
}

const isApiError = (x: unknown): x is ApiError => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return typeof x.error === "string";
};

function interceptError(e: PromisableApiError, aimedError: string): boolean {
  return ("errors" in e && e.errors !== null && e.errors.length === 1 && e.errors[0].code === aimedError)
}

function interceptErrorStatus(e: PromisableApiError, aimedStatus: number): boolean {
  return ("errors" in e && e.status !== null && e.status === aimedStatus)
}

function handleError(apiError: ApiError, customText?: string): void {
  toastUtils.errorToast(customText ? languageUtils.getMessage(customText) : apiError.message);
}

function handleErrors(apiError: PromisableApiError, onError: (apiError: ApiError, customText?: string) => void, customText?: string): void {
  if (apiError instanceof Promise) {
    apiError.then((_apiErrors: ApiError) => {
      if (_apiErrors.name !== "AbortError") {
        onError(_apiErrors, customText)
      }
    }).catch(() => toastUtils.errorToast(languageUtils.getMessage(customText ?? "unknown_error")))
  } else {
    if (apiError.name !== "AbortError") {
      onError(apiError, customText)
    }
  }
}

// 403 error are managed via page access
function handleBackErrors(apiError: PromisableApiError, customText?: string): void {
  if (!interceptErrorStatus(apiError, 403)) {
    handleErrors(apiError, handleError, customText);
  }
}

export const errorUtils = {
  handleBackErrors,
  handleErrors,
  interceptError,
  interceptErrorStatus,
  isApiError
}
