import { TypeColumnName } from "types/transaction/acquiredTransaction";
import { ManagableColumn, ManageableColumnItem } from "interfaces/table/table";

export const ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS: Record<TypeColumnName, ManagableColumn> = {
  OPERATION_DATE: {
    id: "OPERATION_DATE",
    accessor: "OPERATION_DATE",
    header: "transaction_date",
    order: 2,
    cell: {
      type: "date",
    },
  },
  GROSS_AMOUNT: {
    id: "GROSS_AMOUNT",
    accessor: "GROSS_AMOUNT",
    header: "gross_amount",
    order: 5,
    cell: {
      type: "money",
    },
  },
  CARD_BRAND: {
    id: "CARD_BRAND",
    accessor: "CARD_BRAND",
    header: "col_card_brand",
    order: 17,
    cell: {
      type: "text",
    },
  },
  BATCH_ID: {
    id: "BATCH_ID",
    accessor: "BATCH_ID",
    header: "col_man_settlement_batch",
    order: 20,
    cell: {
      type: "text",
    },
  },
  SETTLEMENT_DATE: {
    id: "SETTLEMENT_DATE",
    accessor: "SETTLEMENT_DATE",
    header: "col_man_settlement_date",
    order: 21,
    cell: {
      type: "date",
    },
  },
  TLC_NUMBER: {
    id: "TLC_NUMBER",
    accessor: "TLC_NUMBER",
    header: "col_tlc_number",
    order: 10,
    cell: {
      type: "text",
    },
  },
  FEES: {
    id: "TOTAL_ACQUIRING_FEES",
    accessor: "TOTAL_ACQUIRING_FEES",
    header: "col_man_fees",
    order: 35,
    cell: {
      type: "money",
    },
  },
  NET_AMOUNT: {
    id: "NET_AMOUNT",
    accessor: "NET_AMOUNT",
    header: "pay_tab_settlement_am",
    order: 23,
    cell: {
      type: "money",
    },
  },
  TRANSACTION_SETTLEMENT_ID: {
    id: "TRANSACTION_SETTLEMENT_ID",
    accessor: "TRANSACTION_SETTLEMENT_ID",
    header: "col_transaction_settlement_id",
    order: 0,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  CONTRACT_NUMBER: {
    id: "CONTRACT_NUMBER",
    accessor: "CONTRACT_NUMBER",
    header: "col_man_contract_num",
    order: 1,
    cell: {
      type: "text",
    },
  },
  OPERATION_TIME: {
    id: "OPERATION_TIME",
    accessor: "OPERATION_TIME",
    header: "tpd_op_time",
    order: 3,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  TRANSACTION_TYPE: {
    id: "TRANSACTION_TYPE",
    accessor: "TRANSACTION_TYPE",
    header: "col_man_trans_type",
    order: 4,
    cell: {
      type: "text",
    },
  },
  CURRENCY: {
    id: "CURRENCY",
    accessor: "CURRENCY",
    header: "col_man_currency",
    order: 6,
    cell: {
      type: "text",
    },
  },
  AUTHORISATION_CODE: {
    id: "AUTHORISATION_CODE",
    accessor: "AUTHORISATION_CODE",
    header: "col_man_authorisation_code",
    order: 7,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  PAYMENT_TYPE: {
    id: "PAYMENT_TYPE",
    accessor: "PAYMENT_TYPE",
    header: "col_man_payment_type",
    order: 8,
    cell: {
      type: "text",
    },
  },
  PAYMENT_SOLUTION: {
    id: "PAYMENT_SOLUTION",
    accessor: "PAYMENT_SOLUTION",
    header: "col_man_payment_solution",
    order: 9,
    cell: {
      type: "text",
    },
  },
  CARD_ZONE: {
    id: "CARD_ZONE",
    accessor: "CARD_ZONE",
    header: "col_card_zone",
    order: 11,
    cell: {
      type: "text",
    },
  },
  ISSUING_COUNTRY: {
    id: "ISSUING_COUNTRY",
    accessor: "ISSUING_COUNTRY",
    header: "col_man_card_iss_coun",
    order: 12,
    cell: {
      type: "text",
    },
  },
  CARD_LEVEL: {
    id: "CARD_LEVEL",
    accessor: "CARD_LEVEL",
    header: "col_man_card_level",
    order: 13,
    cell: {
      type: "text",
    },
  },
  CARD_TYPE: {
    id: "CARD_TYPE",
    accessor: "CARD_TYPE",
    header: "col_man_card_type",
    order: 14,
    cell: {
      type: "text",
    },
  },
  BIN: {
    id: "BIN",
    accessor: "BIN",
    header: "col_man_pan_first_six",
    order: 15,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  PAN_LAST_FOUR_DIGITS: {
    id: "PAN_LAST_FOUR_DIGITS",
    accessor: "PAN_LAST_FOUR_DIGITS",
    header: "col_man_pan_last_four",
    order: 16,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  ACQUIRER: {
    id: "ACQUIRER",
    accessor: "ACQUIRER",
    header: "col_man_acquirer",
    order: 18,
    cell: {
      type: "text",
    },
  },
  SCHEME: {
    id: "SCHEME",
    accessor: "SCHEME",
    header: "col_man_scheme",
    order: 19,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  SETTLEMENT_STATUS: {
    id: "SETTLEMENT_STATUS",
    accessor: "SETTLEMENT_STATUS",
    header: "col_man_settlement_status",
    order: 22,
    cell: {
      type: "text",
    },
  },
  ACCOUNT: {
    id: "ACCOUNT",
    accessor: "ACCOUNT",
    header: "col_man_account_name",
    order: 24,
    cell: {
      type: "text",
    },
  },
  COMPANY: {
    id: "COMPANY",
    accessor: "COMPANY",
    header: "col_man_company_name",
    order: 25,
    cell: {
      type: "text",
    },
  },
  MERCHANT: {
    id: "MERCHANT",
    accessor: "MERCHANT",
    header: "col_man_merchant_name",
    order: 26,
    cell: {
      type: "text",
    },
  },
  COUNTRY: {
    id: "COUNTRY",
    accessor: "COUNTRY",
    header: "col_man_country",
    order: 27,
    cell: {
      type: "text",
    },
  },
  CALCULATION_METHOD: {
    id: "CALCULATION_METHOD",
    accessor: "CALCULATION_METHOD",
    header: "col_calculation_method",
    order: 28,
    cell: {
      type: "text",
    },
  },
  MARKETPAY_FIXED_FEES: {
    id: "MARKETPAY_FIXED_FEES",
    accessor: "MARKETPAY_FIXED_FEES",
    header: "col_marketpay_fixed_fees",
    order: 29,
    cell: {
      type: "money",
    },
  },
  MARKETPAY_FEES_RATE: {
    id: "MARKETPAY_FEES_RATE",
    accessor: "MARKETPAY_FEES_RATE",
    header: "col_marketpay_fees_rate",
    order: 30,
    cell: {
      type: "percent",
    },
  },
  FIXED_SCHEME_FEES: {
    id: "FIXED_SCHEME_FEES",
    accessor: "FIXED_SCHEME_FEES",
    header: "col_fixed_scheme_fees",
    order: 31,
    cell: {
      type: "money",
    },
  },
  SCHEME_FEES_RATE: {
    id: "SCHEME_FEES_RATE",
    accessor: "SCHEME_FEES_RATE",
    header: "col_scheme_fees_rate",
    order: 32,
    cell: {
      type: "percent",
    },
  },
  INTERCHANGE_FEES: {
    id: "INTERCHANGE_FEES",
    accessor: "INTERCHANGE_FEES",
    header: "col_interchange_fees",
    order: 33,
    cell: {
      type: "money",
    },
  },
  SCHEME_FEES: {
    id: "SCHEME_FEES",
    accessor: "SCHEME_FEES",
    header: "col_man_scheme_fees",
    order: 34,
    cell: {
      type: "money",
    },
    disableSortBy: true,
  },
  TOTAL_MARKETPAY_FEES: {
    id: "TOTAL_MARKETPAY_FEES",
    accessor: "TOTAL_MARKETPAY_FEES",
    header: "col_total_marketpay_fees",
    order: 35,
    cell: {
      type: "money",
    },
  },
  FIXED_FEES_INCLUDED: {
    id: "FIXED_FEES_INCLUDED",
    accessor: "FIXED_FEES_INCLUDED",
    header: "tpd_fixed_fees",
    order: 36,
    cell: {
      type: "money",
    },
  },
  VARIABLE_FEES_INCLUDED: {
    id: "VARIABLE_FEES_INCLUDED",
    accessor: "VARIABLE_FEES_INCLUDED",
    header: "tpd_variable_fees",
    order: 45,
    cell: {
      type: "money",
    },
  },
  PROCESSING_HT: {
    id: "PROCESSING_HT",
    accessor: "PROCESSING_HT",
    header: "col_processing_ht",
    order: 42,
    cell: {
      type: "money",
    },
    disableSortBy: true,
  },
  PROCESSING_TTC: {
    id: "PROCESSING_TTC",
    accessor: "PROCESSING_TTC",
    header: "col_processing_ttc",
    order: 43,
    cell: {
      type: "money",
    },
    disableSortBy: true,
  },
  PROCESSING_TVA: {
    id: "PROCESSING_TVA",
    accessor: "PROCESSING_TVA",
    header: "col_processing_tva",
    order: 44,
    cell: {
      type: "percent",
    },
    disableSortBy: true,
  },
  DISTRIB_AMOUNT: {
    id: "DISTRIB_AMOUNT",
    accessor: "DISTRIB_AMOUNT",
    header: "col_retrocession",
    order: 45,
    cell: {
      type: "money",
    },
    disableSortBy: false,
  },
  SHARE_MARGIN_AMOUNT: {
    id: "SHARE_MARGIN_AMOUNT",
    accessor: "SHARE_MARGIN_AMOUNT",
    header: "distrib_share_margin_amt",
    order: 46,
    cell: {
      type: "money",
    },
    disableSortBy: true,
  },
  SHARE_MARGIN_RATE: {
    id: "SHARE_MARGIN_RATE",
    accessor: "SHARE_MARGIN_RATE",
    header: "distrib_share_margin_rate",
    order: 47,
    cell: {
      type: "percent",
    },
    disableSortBy: true,
  },
  MP_SHARE_MARGIN_AMOUNT: {
    id: "MP_SHARE_MARGIN_AMOUNT",
    accessor: "MP_SHARE_MARGIN_AMOUNT",
    header: "mp_share_margin_amt",
    order: 48,
    cell: {
      type: "money",
    },
    disableSortBy: true,
  },
  MP_SHARE_MARGIN_RATE: {
    id: "MP_SHARE_MARGIN_RATE",
    accessor: "MP_SHARE_MARGIN_RATE",
    header: "mp_share_margin_rate",
    order: 49,
    cell: {
      type: "percent",
    },
    disableSortBy: true,
  },

};

export const ACQUIERED_TRANSACTION_COLUMNS: ManagableColumn[] = [
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.TRANSACTION_SETTLEMENT_ID,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.CONTRACT_NUMBER,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.OPERATION_DATE,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.OPERATION_TIME,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.TRANSACTION_TYPE,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.GROSS_AMOUNT,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.CURRENCY,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.AUTHORISATION_CODE,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.PAYMENT_TYPE,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.PAYMENT_SOLUTION,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.TLC_NUMBER,
];
export const ACQUIERED_CUSTOMER_COLUMNS: ManagableColumn[] = [
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.CARD_ZONE,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.ISSUING_COUNTRY,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.CARD_LEVEL,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.CARD_TYPE,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.BIN,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.PAN_LAST_FOUR_DIGITS,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.CARD_BRAND,
];
export const ACQUIERED_SETTLEMENT_COLUMNS: ManagableColumn[] = [
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.ACQUIRER,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.SCHEME,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.BATCH_ID,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.SETTLEMENT_DATE,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.SETTLEMENT_STATUS,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.NET_AMOUNT,
];
export const ACQUIERED_MERCHANT_COLUMNS: ManagableColumn[] = [
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.ACCOUNT,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.COMPANY,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.MERCHANT,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.COUNTRY,
];

export const ACQUIERED_FEE_CALCULATION_COLUMNS: ManagableColumn[] = [
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.CALCULATION_METHOD,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.MARKETPAY_FIXED_FEES,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.MARKETPAY_FEES_RATE,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.FIXED_SCHEME_FEES,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.SCHEME_FEES_RATE,
];

export const ACQUIERED_FEE_AMOUNT_COLUMNS: ManagableColumn[] = [
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.INTERCHANGE_FEES,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.SCHEME_FEES,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.TOTAL_MARKETPAY_FEES,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.FIXED_FEES_INCLUDED,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.VARIABLE_FEES_INCLUDED,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.PROCESSING_HT,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.PROCESSING_TVA,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.PROCESSING_TTC,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.FEES,
];

export const ACQUIERED_DISTRIBUTION_COLUMNS: ManagableColumn[] = [
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.DISTRIB_AMOUNT,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.SHARE_MARGIN_AMOUNT,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.SHARE_MARGIN_RATE,
];

export const ACQUIERED_ADMIN_DISTRIBUTION_COLUMNS: ManagableColumn[] = [
  ...ACQUIERED_DISTRIBUTION_COLUMNS, 
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.MP_SHARE_MARGIN_AMOUNT,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.MP_SHARE_MARGIN_RATE,
];

export const ACQUIERED_TRANSACTION_MANAGEABLE_COLUMNS: ManageableColumnItem[] = [
  { label: "col_man_titles_transaction", columns: ACQUIERED_TRANSACTION_COLUMNS },
  { label: "col_man_titles_customer", columns: ACQUIERED_CUSTOMER_COLUMNS },
  { label: "col_man_titles_settlement", columns: ACQUIERED_SETTLEMENT_COLUMNS },
  { label: "col_man_titles_merchant", columns: ACQUIERED_MERCHANT_COLUMNS },
  { label: "col_man_titles_fee_calc", columns: ACQUIERED_FEE_CALCULATION_COLUMNS },
  { label: "col_man_titles_fee_amount", columns: ACQUIERED_FEE_AMOUNT_COLUMNS },
];
