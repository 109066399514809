import {AppRouteInterface} from "../../interfaces/RouteInterfaces";
import {Outlet, Route} from "react-router-dom";
import PrivateRoute from "../../components/routes/PrivateRoute";
import React from "react";


/***
 * React-router logic of defining routes.
 * This generate a recursive components
 * @param route
 */
const recursiveRoutes = (route: AppRouteInterface): JSX.Element => {

    const elementFormatted = route.element ? <route.element/> : <Outlet/>;
    const element: JSX.Element = route.private ?
        <PrivateRoute permission={route.permission} redirectPath={route.redirectPath}>
            {elementFormatted}
        </PrivateRoute> :
        elementFormatted;

    return (<Route key={`${route.path}${element.key}`} path={route.path} index={route.index} element={element}>
        {route.children && route.children.map(_route => recursiveRoutes(_route))}
    </Route>)
}


export const routeUtils = {
    recursiveRoutes
}
