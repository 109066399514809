import React, { FunctionComponent } from "react";
import { paletteTheme } from "styles/themes/palette";
import Typography from "@mui/material/Typography";
import styles from "components/molecules/histogram/HistogramHeader/HistogramTitle/histogramTitle.module.scss";

interface TitleProps {
    chartTitle: string,
}

export const HistogramTitle: FunctionComponent<TitleProps> = ({ chartTitle }) => {
    return <div className={`${styles.title}`}>
        <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
            {chartTitle}
        </Typography>
    </div>
}
