import React, { FunctionComponent } from "react";
import { Action } from "components/atoms/button/more-actions/ListActions";
import { ICellProps } from "interfaces/table/table";
import { useIntl } from "react-intl";
import { MoreActions } from "components/molecules/MoreActions";
import { SearchIcon } from "components/atoms/icons/SearchIcon";
import { useNavigate } from "react-router-dom";
import { ACQUIRED_TRANSACTIONS_PATH } from "constants/routes/RoutePaths";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import { SettlementResponse } from "interfaces/settlement/settlement";
import { perimeterService } from "services/user/PerimeterService";

interface SettlementFilter {
  operationDate: Range;
}

export const MoreActionsSettlements: FunctionComponent<ICellProps> = ({ cell }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const settlementsFilters: SettlementFilter = JSON.parse(localStorage.getItem(LocalStorageEnum.SETTLEMENTS_FILTERS));

  const openTransaction = async () => {
    let selectedAccount = {};
    let selectedMerchant = {};
    let selectedCompany = {};
    try {
      const merchantInfo = await perimeterService.searchMerchantById((cell.row.original as SettlementResponse).magasinId);
      selectedAccount = merchantInfo.account;
      selectedCompany = merchantInfo.company;
      selectedMerchant = merchantInfo.merchant;
    } catch {
    }
    navigate(`/${ACQUIRED_TRANSACTIONS_PATH}`, {
      state: {
        settlementDate: settlementsFilters.operationDate,
        batchId: (cell.row.original as SettlementResponse).batchId,
        store: selectedMerchant,
        company: selectedCompany,
        account: selectedAccount
      },
    });
  };

  const actions: Action[] = [
    {
      id: "VIEW_ACQUIRED",
      icon: <SearchIcon />,
      title: intl.formatMessage({ id: "settlements_view_acq_transactions" }),
      handler: openTransaction,
      typeButton: "primary",
    },
  ];

  return <MoreActions actions={actions} />;
};
