import React, {FunctionComponent, useState} from "react";
import FlexBox from "../../../atoms/flex/FlexBox";
import {FlexAttributValue} from "../../../../types/flex/flex";
import {Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {CloseOutlined} from "@mui/icons-material";
import ContainedButton from "../../../atoms/button/ContainedButton";
import {paletteTheme} from "../../../../styles/themes/palette";
import Label from "../../../atoms/Label";
import OutlinedTextFieldCardStyled from "../../../../styles/components/textfield/OutlinedTextFieldCardStyled";
import {VIEW_ERRORS} from "../../../../constants/errors/view";
import ErrorMessage from "../../../atoms/ErrorMessage";
import DiscardButtonStyled from "../../../../styles/components/button/DiscardButtonStyled";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {VIEW_TYPE} from "../../../../constants/view/view";
import {viewService} from "../../../../services/view/ViewService";
import {errorUtils, PromisableApiError} from "../../../../utils/api/errorUtils";
import {ViewContent, ViewPerimeterContent} from "../../../../interfaces/view/view";
import {useValidation} from "../../../../hooks/validation/useValidation";
import {validationErrorsViewUtils} from "../../../../utils/business/validationErrorsViewUtils";
import {validationErrorsUtils} from "../../../../utils/business/validationErrorsUtils";

interface SaveViewDialogContentProps {
    onCloseDialog: () => void,
    filters: ViewContent,
    perimeterFilters: ViewPerimeterContent,
    onSave: () => void,
    submitMessage: string,
}

const SaveViewDialogContent: FunctionComponent<SaveViewDialogContentProps> = ({
                                                                                  onCloseDialog,
                                                                                  filters,
                                                                                  perimeterFilters,
                                                                                  onSave,
                                                                                  submitMessage
                                                                              }) => {

    const [input, setInput] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const {
        validated,
        isValidated,
        errors, setErrors
    } = useValidation(() => validationErrorsViewUtils.validateViewName(input))

    const {user} = useAuthUser();

    const onChangeInput = (input: string) => {
        setInput(input)
        if (isValidated) {
            setErrors(validationErrorsViewUtils.validateViewName(input))
        }
    }

    const onClose = () => {
        setInput("")
        onCloseDialog();
    }

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChangeInput(event.target.value)
    }

    const handleSubmit = () => {

        setLoading(true)

        if (validated()) {
            const view = {
                name: input,
                perimeterFilters,
                filters,
                columns: filters.columns,
                type: VIEW_TYPE.TRANSACTION.id,
                idUser: user.id
            };

            viewService.create(view).then(() => {
                setLoading(false)
                onSave()
            }).catch((e: PromisableApiError) => {
                setLoading(false)
                errorUtils.handleErrors(e, (apiError) => setErrors(validationErrorsUtils.cleanAndUpdate(apiError.errors)))
            })
        } else {
            setLoading(false)
        }
    };
    const nameErrors = [VIEW_ERRORS.VIEW_NAME_ALREADY_EXISTS, VIEW_ERRORS.SV_NAME_IS_REQUIRED, VIEW_ERRORS.SV_NAME_IS_TOO_SHORT, VIEW_ERRORS.SV_NAME_IS_TOO_LONG];
    return (<>
            <FlexBox justifyContent={FlexAttributValue.SPACE_BETWEEN} marginBottom="20px">
                <Typography variant="h1" color="primary">
                    <FormattedMessage id="sv_save_this_view"/>
                </Typography>
                <CloseOutlined sx={{cursor: "pointer", color: paletteTheme.colors.GRAY}}
                               onClick={onClose}>

                </CloseOutlined>
            </FlexBox>

            <FlexBox marginBottom={3} alignItems={FlexAttributValue.CENTER} position="relative"
                     justifyContent={FlexAttributValue.SPACE_BETWEEN}>
                <Label
                    id="view_name"
                    label="sv_view_name"
                    required
                    sx={{fontSize: 14, fontWeight: 600, position: "initial"}}/>

                <FlexBox position="relative">
                    <OutlinedTextFieldCardStyled
                        id="view_name"
                        value={input}
                        onChange={onChange}
                        error={isValidated && nameErrors.some(error => !!errors[error.id])}/>
                    <FlexBox fontSize={14} position="absolute" top={25}>
                        {nameErrors.map(_error =>
                            <ErrorMessage
                                key={_error.id}
                                error={_error.id}
                                errors={errors}/>)}
                    </FlexBox>
                </FlexBox>
            </FlexBox>

            <FlexBox flexDirection={FlexAttributValue.ROW} justifyContent={FlexAttributValue.END}>
                <FlexBox flexDirection={FlexAttributValue.ROW}>
                    {
                        onCloseDialog &&
                        <DiscardButtonStyled sx={{marginRight: 1}} onClick={onCloseDialog}>
                            <FormattedMessage id="cancel"/>
                        </DiscardButtonStyled>
                    }
                    <ContainedButton
                        loading={loading}
                        onClick={handleSubmit}>
                        <FormattedMessage id={submitMessage}/>
                    </ContainedButton>
                </FlexBox>
            </FlexBox>
        </>
    )
}

export default SaveViewDialogContent;
