import React, { FunctionComponent } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { TMenuItem } from "interfaces/menu/menu";
import { ItemMenu } from "components/molecules/menu/SideMenu/ItemMenu";
import { ParentItemMenu } from "components/molecules/menu/SideMenu/ParentItemMenu";
import { User } from "interfaces/user/user";
import UserMenuLabel from "components/molecules/menu/SideMenu/SideMenuItem/UserMenuLabel";

export interface ISideMenuItem {
  item: TMenuItem;
  isReduced?: boolean;
  className?: string;
  user?: User;
}

const checkActiveUrl = (item: TMenuItem, currentUrl: string) => {
  if (item.isParentItem === true) {
    return false;
  }
  return currentUrl.startsWith(item.href);
};

export const SideMenuItem: FunctionComponent<ISideMenuItem> = ({ item, isReduced, className, user }) => {
  const { formatMessage } = useIntlFormatter();

  const { pathname } = useLocation();
  if (item.isParentItem === true) {
    return (
      <ParentItemMenu
        Icon={item.Icon}
        isReduced={isReduced}
        isSubItemActive={item.subItems.some((el) => checkActiveUrl(el, pathname))}
        label={formatMessage(item.label)}
        subItems={item.subItems}
      />
    );
  }
  return (
    <NavLink
      to={item.href}
      className={({ isActive }) => {
        return `mp-text-link no-underline ${isActive ? "active" : ""}`;
      }}
      rel="noreferrer"
    >
      <ItemMenu label={item.label === "menu_my_account" ? user && <UserMenuLabel user={user}/> :formatMessage(item.label)} Icon={item.Icon} isReduced={isReduced} className={className} />
    </NavLink>
  );
};
