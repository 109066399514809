import React, {
  FunctionComponent,
  useMemo,
} from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import LabeledCheckbox from "components/atoms/checkbox/LabeledCheckbox";
import { UserDetails } from "interfaces/user/user";
import { createUserUtils } from "utils/business/createUserUtils";
import { PROFIL_USER } from "constants/user/user";
import { PermissionCategory } from "constants/permissions/permissions";
import { FlexAttributValue } from "types/flex/flex";
import { permissionUtils } from "utils/business/permissionUtils";
import { PermissionDetail } from "components/molecules/permission/PermissionDetail";

interface PermissionDisplayProps {
  category: PermissionCategory;
  userDetails: UserDetails;
  authPermissions: Array<string>;
  idUser?: string;
  onChangePermissions?: (userDetails: UserDetails) => void;
  edit?: boolean;
}

export const PermissionDisplay: FunctionComponent<PermissionDisplayProps> = ({
  category,
  userDetails,
  authPermissions,
  onChangePermissions,
  edit = false,
  idUser,
}) => {

  const handleChange = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const permissions = [...userDetails.permissions];
    if (e.target.checked) {
      permissions.push(id);
    } else {
      permissions.splice(permissions.indexOf(id), 1);
    }
    onChangePermissions({ ...userDetails, permissions });
  }

  // Check if user has at least one permission in the category
  const hasAtLeastOnePermission = useMemo(() => {
    return category.permissions.some(permission => userDetails.permissions.includes(permission.id));
  }, [userDetails.permissions, category.permissions])

  if (!hasAtLeastOnePermission && !edit) {
    return (<Box marginBottom={5}></Box>)
  }

  return (
    <div style={{marginBottom: "24px"}}>

      <Typography variant="h2" fontSize={14} marginBottom={2}>
        <FormattedMessage id={category.label} />
      </Typography>

      {!createUserUtils.isSelfFromParam(idUser) && edit ?
        category.permissions.map(item => {
          const irreversibleForProfile = permissionUtils.isIrreversibleForProfile(item, userDetails.profile);
          const disableForAuthUser = authPermissions ? !authPermissions.some(permission => permission === item.id) : !userDetails.permissions.some(permission => permission === item.id);
          return <LabeledCheckbox key={item.id} label={item.label}
            disabled={irreversibleForProfile || disableForAuthUser}
            checked={irreversibleForProfile || userDetails.permissions.some(permission => permission === item.id)}
            alignLabel={FlexAttributValue.START}
            onChange={(e) => {
              handleChange(item.id, e)
            }}>
          </LabeledCheckbox>;
        }
        ) :
        // Is admin or has irreversibles permissions or has at least one permission
        category.permissions.filter(item => userDetails.profile === PROFIL_USER.ADMIN.id
          || (item.irreversibleForProfiles && item.irreversibleForProfiles.some(profile => userDetails.profile === profile))
          || userDetails.permissions.some(permission => permission === item.id))
          .map(item => <PermissionDetail key={item.id} label={item.label} />)
      }

    </div>
  );
}
