import React, { FunctionComponent } from "react";
import { BoxProps } from "@mui/material";
import { Perimeter } from "interfaces/perimeter/perimeter";
import { UserAccountCompanyPerimeterDetails } from "components/molecules/useraccount/details/UserAccountCompanyPerimeterDetails";

interface AccountPerimeterDetailsProps extends BoxProps {
  perimeter: Perimeter;
}

export const UserAccountPerimeterCompanyList: FunctionComponent<AccountPerimeterDetailsProps> = ({
  perimeter,
}) =>
  <>
    {perimeter?.companies.map((_perimeter, index) =>
      <UserAccountCompanyPerimeterDetails
        key={_perimeter.company.id}
        perimeter={_perimeter}
        merchants={_perimeter.merchants}
      />)
    }
  </>
