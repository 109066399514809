import React from "react";
import { Identify } from "constants/options/option";

interface ChangeCheckboxInstance<T> {
  handleChange: (
    option: T, e: React.ChangeEvent<HTMLInputElement>,
    multiple?: boolean,
    handleToggleFilter?: (closed: boolean) => void) => void
}

export function useChangeCheckbox<T extends Identify>(
  values: T[],
  onChange: ((values: T[]) => void),
  multiple = true,
  handleToggleFilter?: (closed: boolean) => void
)
  : ChangeCheckboxInstance<T> {


  const handleChange = (option: T, e: React.ChangeEvent<HTMLInputElement>) => {
    const tempValues = [...values];

    if (!multiple) {
      if (!e.target.checked) {
        handleToggleFilter(true);
        return;
      }
      if (tempValues) {
        tempValues.splice(0, 1);
      }
    }

    if (e.target.checked) {
      tempValues.push(option)
    } else {
      tempValues.splice(tempValues.findIndex(value => value.id === option.id), 1);
    }

    onChange(tempValues);

    if (!multiple) {
      // close select drop-down if multiple checkbox selection === false
      handleToggleFilter(true)
    }
  }

  return {
    handleChange,
  };
}
