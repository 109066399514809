import React, { FunctionComponent } from "react";
import { HistogramSelectorLine } from "interfaces/histogram/histogram";
import { DropdownStyled } from "components/atoms/select/DropdownStyled";
import { TEMPORALITY_SELECTOR_OPTIONS } from "constants/histogram/histogram";
import { SelectChangeEvent } from "@mui/material";
import styles from "components/molecules/histogram/HistogramHeader/HistogramDropdowns/histogramDropdowns.module.scss";

interface DropDownsProps {
  period: string;
  amount: string;
  amountSelectorOptions: HistogramSelectorLine[];
  onChangePeriod: (value: string) => void;
  onChangeAmount: (value: string) => void;
}

export const HistogramDropDowns: FunctionComponent<DropDownsProps> = ({
  period,
  amount,
  amountSelectorOptions,
  onChangePeriod,
  onChangeAmount,
}) => {
  const handlePeriodChange = (event: SelectChangeEvent) => {
    onChangePeriod(event.target.value);
  };

  const handleAmountChange = (event: SelectChangeEvent) => {
    onChangeAmount(event.target.value);
  };
  return (
    <div className={`flex ${styles["dropdown-wrap"]}`}>
      <div className={`flex ${styles.left} ${styles.select}`}>
        <DropdownStyled
          selectedValue={period}
          options={TEMPORALITY_SELECTOR_OPTIONS}
          onChange={(event: SelectChangeEvent) => { handlePeriodChange(event) }}
          placeholderId="graph_dropdown_interval" />
      </div>
      <div className={`flex ${styles.right} ${styles.select}`}>
        <DropdownStyled
          selectedValue={amount}
          options={amountSelectorOptions}
          onChange={(event: SelectChangeEvent) => { handleAmountChange(event) }}
          placeholderId="graph_dropdown_amount" />
      </div>
    </div>
  )
}
