import { ConstantLabelLine, PERIMETER_LEVEL } from "constants/perimeter/perimeter";
import { IUserCellProps } from "interfaces/table/table";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import TextCell from "../../TextCell";

export const LevelCell: FunctionComponent<IUserCellProps> = ({ cell }) => {
  const intl = useIntl();
  const level: ConstantLabelLine = PERIMETER_LEVEL[cell.row.original.perimeterLevel];
  return <TextCell value={level ? intl.formatMessage({ id: level.label }) : ""} />;
};
