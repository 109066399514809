import { useWrappedFilterComponents } from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { FilterColumn, FilterFieldType, FiltersFieldConfigs, TransactionType } from "interfaces/filter/filterColumn";
import { TransactionAdditionalFilter } from "interfaces/transaction/transaction";

interface TransactionFilterConfig {
  typeTransaction: TransactionType;
  initialMainFilter?: TransactionAdditionalFilter[];
  initialAdditionalFilters?: TransactionAdditionalFilter[];
  configs: FiltersFieldConfigs;
}

export const useTransactionFilterConfig = ({
  typeTransaction,
  initialMainFilter = [],
  initialAdditionalFilters = [],
  configs: {
    sharedFilterProps,
    perimeterConfig,
    datePickerConfig,
    amountRangeConfig,
    multiChoiceConfig,
    textFieldConfig,
    autoCompleteCheckboxConfig,
    queryAndResetConfig,
  },
}: TransactionFilterConfig): { mainFilters: FilterColumn[]; additionalFilters: FilterColumn[] } => {
  const { onSearch, onReset, errors } = sharedFilterProps;

  const { autocompleteCheckbox, dateRange, textField, multiChoiceCheckbox, amountRange, queryAndReset } =
    useWrappedFilterComponents();

  const buildFilterField = (initConfig: TransactionAdditionalFilter, typeTransaction = TransactionType.ACCEPTED) => {
    const filterField: FilterColumn = {
      name: initConfig.id,
      component: null,
    };
    switch (initConfig.type) {
      case FilterFieldType.TEXT:
        filterField.component = textField(initConfig, textFieldConfig);
        break;
      case FilterFieldType.MULTISELECT:
        filterField.component = multiChoiceCheckbox(initConfig, multiChoiceConfig, typeTransaction);
        break;
      case FilterFieldType.DATE:
        filterField.component = dateRange(initConfig.id, datePickerConfig, typeTransaction);
        break;
      case FilterFieldType.RANGE:
        filterField.component = amountRange(initConfig, amountRangeConfig);
        break;
      default:
        break;
    }

    return filterField;
  };

  const defaultMainFilter: FilterColumn[] = [
    {
      name: "Account dropdown",
      component: autocompleteCheckbox("account", perimeterConfig, autoCompleteCheckboxConfig),
      index: 1,
    },
    {
      name: "Company dropdown",
      component: autocompleteCheckbox("company", perimeterConfig, autoCompleteCheckboxConfig),
      index: 2,
    },
    {
      name: "Merchant dropdown",
      component: autocompleteCheckbox("merchant", perimeterConfig, autoCompleteCheckboxConfig),
      index: 3,
    },
    {
      name: "Search and reset",
      component: queryAndReset(onSearch, onReset, errors, queryAndResetConfig),
      index: 99,
      config: {
        xs: { colSpan: 2 },
        sm: {
          colSpan: 1,
          colStart: 5,
        },
      },
    },
  ];

  const mainFilters: FilterColumn[] = [
    ...defaultMainFilter,
    ...initialMainFilter.map((item, i) => ({
      ...buildFilterField(item, typeTransaction),
      index: defaultMainFilter.length + i,
    })),
  ];

  const additionalFilters: FilterColumn[] = [
    ...initialAdditionalFilters.map((item, i) => ({
      ...buildFilterField(item, typeTransaction),
      index: i,
      isAdditional: true,
    })),
  ];

  return {
    mainFilters,
    additionalFilters,
  };
};
