import React, {FunctionComponent} from "react";
import FlexBox from "../../../atoms/flex/FlexBox";
import {FlexAttributValue} from "../../../../types/flex/flex";
import {Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {Check} from "@mui/icons-material";
import ContainedButton from "../../../atoms/button/ContainedButton";

interface SaveViewConfirmDialogContentProps {
    onConfirm: () => void
}

const SaveViewConfirmDialogContent: FunctionComponent<SaveViewConfirmDialogContentProps> = ({
                                                                         onConfirm
                                                                     }) => {
    return (
            <FlexBox justifyContent={FlexAttributValue.CENTER} alignItems={FlexAttributValue.CENTER} flexDirection={FlexAttributValue.COLUMN} marginBottom="10px">
                <Check color="primary" fontSize="large"/>
                <Typography margin={1} variant="body2">
                    <FormattedMessage id="sv_creation_confirmation"/>
                </Typography>
                <ContainedButton onClick={onConfirm}><FormattedMessage id="ok"/></ContainedButton>
            </FlexBox>
    )
}

export default SaveViewConfirmDialogContent;
