import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import FlexBox from "./flex/FlexBox";
import {FlexAttributValue} from "../../types/flex/flex";
import {InputLabel, SxProps, Theme} from "@mui/material";

interface LabelProps {
    id: string,
    className?: string,
    label?: string,
    required?: boolean,
    sx?: SxProps<Theme>
}

const Label: FunctionComponent<LabelProps> = ({
                                                  children,
                                                  className,
                                                  label,
                                                  required = false,
                                                  id,
                                                  sx
                                              }) =>
    (
        <FlexBox flexDirection={FlexAttributValue.COLUMN} className="mp-label-input">
            <InputLabel htmlFor={id} className={`mp-label ${className}`} sx={sx}>
                <FormattedMessage id={label}></FormattedMessage>{required ? <span>*</span> : <></>}
            </InputLabel>
            {children}
        </FlexBox>
    )


export default Label;
