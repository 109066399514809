import {QueryParam} from "../../interfaces/UserInterface";

const queryParamToPageable = ({page, pageSize, sortDirection, sortField}: QueryParam): string => {
    let formattedQuery = `?size=${pageSize}&page=${page}`
    if (sortDirection && sortField) {
        formattedQuery += `&sort=${sortField},${sortDirection}`
    }

    return formattedQuery;
}

const paginateQuery = ({page, pageSize, sortDirection, sortField}: QueryParam): string => {
    let formattedQuery = `?pageSize=${pageSize}&pageNumber=${page}`;
    if (sortDirection && sortField) {
        formattedQuery += `&sortBy=${sortField}&sortDirection=${sortDirection}`;
    }

    return formattedQuery;
}

const filterURLSearchParams = (params: URLSearchParams): URLSearchParams => {
    const paramsCopy = new URLSearchParams(params);
    [...paramsCopy.entries()].forEach(([key, value]) => {
        if (value === undefined || value === "") {
            paramsCopy.delete(key)
        }
    });
    return paramsCopy
}

export const queryUtils = {
    queryParamToPageable,
    paginateQuery,
    filterURLSearchParams
}
