import React, { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { RouteProps, useLocation, useNavigate, useParams } from "react-router-dom";
import { TransactionKeyDetailCard } from "components/molecules/transaction/TransactionDetailCard";
import {
  ACQUIRED_TRANSACTIONS,
  ACQUIRED_CUSTOMER,
  ACQUIRED_SETTLEMENT,
  ACQUIRED_MERCHANT,
  ACQUIRED_CALCULATION,
  ACQUIRED_FEES_AMOUNT,
  ACQUIRED_DISTRIB,
  ACQUIRED_DISTRIB_ADMIN,
} from "constants/transaction/transaction";
import { DetailInformation } from "interfaces/transaction/transaction";
import { ACQUIRED_TRANSACTIONS_PATH } from "constants/routes/RoutePaths";
import { BackLink } from "components/molecules/BackLink";
import { AcquiredTransactionResponse } from "interfaces/transaction/acquiredTransaction";
import Card from "components/atoms/card/Card";
import { TransactionHistoryCardTitle } from "components/molecules/transaction/TransactionDetailHistory/TransactionHistoryTitle";
import { DetailBlock } from "components/molecules/transaction/DetailBlock";
import { rightUtils } from "utils/business/rightUtils";
import { useAuthUser } from "hooks/useAuthUser";

interface AcquiredDetailState {
  transactionDetails?: AcquiredTransactionResponse;
}

const AcquiredDetailView: FunctionComponent<RouteProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const { idTransaction } = useParams();
  const { user } = useAuthUser();
  const isDistributor = rightUtils.isDistributor(user);
  const isAdmin = rightUtils.isAdmin(user);
  const transactionDetails: AcquiredTransactionResponse = (location.state as AcquiredDetailState)?.transactionDetails;

  useEffect(() => {
    if (!transactionDetails) {
      navigate(`/${ACQUIRED_TRANSACTIONS_PATH}`);
    }
  }, []);
  const transaction: DetailInformation = {
    ...transactionDetails?.transactionInfo,
    operationDatetime: intl.formatTime(transactionDetails?.transactionInfo.operationDateTime),
    operationDate: intl.formatDate(transactionDetails?.transactionInfo.operationDate),
    transactionSettlementId: transactionDetails.transactionSettlementId,
  };

  const customer: DetailInformation = { ...transactionDetails?.customer };
  const settlement: DetailInformation = {
    ...transactionDetails?.settlement,
    settlementDate: intl.formatDate(transactionDetails?.settlement.settlementDate),
  };
  const merchant: DetailInformation = { ...transactionDetails?.merchant };
  const { processingFeesVAT, processingFeesTaxIncluded, processingFeesTaxExcluded, ...feesCalculation }: DetailInformation = { ...transactionDetails?.feesCalculation };
  const feesAmount: DetailInformation = { processingFeesVAT, processingFeesTaxIncluded, processingFeesTaxExcluded, ...transactionDetails?.feesAmount };

  const distrib: DetailInformation = {
    distribAmount: transactionDetails?.distrib?.distribAmount,
    distribShareMarginAmt: transactionDetails?.distrib?.distribShareMarginAmt,
    distribShareMarginRate: transactionDetails?.distrib?.distribShareMarginRate
  };
  const adminDistrib: DetailInformation = {
    ...distrib,
    mpShareMarginAmt: transactionDetails?.distrib?.mpShareMarginAmt,
    mpShareMarginRate: transactionDetails?.distrib?.mpShareMarginRate,
  };
  const dist = isAdmin ? adminDistrib : distrib;
  const distInfo = isAdmin ? ACQUIRED_DISTRIB_ADMIN : ACQUIRED_DISTRIB;

  return (
    <>
      <Card
        cardTitle={
          <TransactionHistoryCardTitle
            backUrl={ACQUIRED_TRANSACTIONS_PATH}
            title="acq_details_title"
            transactionId={idTransaction}
          />
        }>
        <DetailBlock
          detailInformation={transaction}
          detailInformationElement={ACQUIRED_TRANSACTIONS}
          header="tp_titles_transaction"></DetailBlock>
      </Card>
      <TransactionKeyDetailCard
        title="tp_titles_customer"
        detailInformation={customer}
        detailInformationElement={ACQUIRED_CUSTOMER}
      />
      <TransactionKeyDetailCard
        title="tp_titles_settlement"
        detailInformation={settlement}
        detailInformationElement={ACQUIRED_SETTLEMENT}
      />
      <TransactionKeyDetailCard
        title="tp_titles_merchant"
        detailInformation={merchant}
        detailInformationElement={ACQUIRED_MERCHANT}
      />
      <TransactionKeyDetailCard
        title="tp_fees_calculation"
        detailInformation={feesCalculation}
        detailInformationElement={ACQUIRED_CALCULATION}
      />
      <TransactionKeyDetailCard
        title="tp_fees_amount"
        detailInformation={feesAmount}
        detailInformationElement={ACQUIRED_FEES_AMOUNT}
      />
      {(isDistributor || isAdmin) &&
        <TransactionKeyDetailCard
          title="category_distribution"
          detailInformation={dist}
          detailInformationElement={distInfo}
        />
      }

      <BackLink to={`/${ACQUIRED_TRANSACTIONS_PATH}`} label="back_transactions_list" />
    </>
  );
};
export default AcquiredDetailView;
