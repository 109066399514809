import React, { FunctionComponent } from "react";
import Typography from "@mui/material/Typography";
import RadioGroup from "components/atoms/radio/RadioGroup";
import { TEMPORALITY_SELECTOR_OPTIONS } from "constants/histogram/histogram";
import { paletteTheme } from "styles/themes/palette";
import { BarLineChart } from "components/molecules/histogram/HistogramBody/BarLineChart";
import { HistogramSelectorLine } from "interfaces/histogram/histogram";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import styles from "components/molecules/histogram/HistogramBody/histogramBody.module.scss";

interface HistogramBodyProps {
    linedChartLabel: string,
    barChartLabel: string,
    lineData: number[],
    barData: number[],
    linedChartAxisLabel?: string,
    barChartAxisLabel?: string,
    xAxis: string[],
    period: string,
    onChangePeriod: (value: string) => void,
    amount: string,
    amountSelectorOptions: HistogramSelectorLine[],
    onChangeAmount: (value: string) => void,
    loading: boolean,
}

export const HistogramBody: FunctionComponent<HistogramBodyProps> = ({
    linedChartLabel,
    barChartLabel,
    lineData,
    barData,
    linedChartAxisLabel,
    barChartAxisLabel,
    xAxis,
    period,
    onChangePeriod,
    amount,
    amountSelectorOptions,
    onChangeAmount,
    loading,
}) => {

    const { lgUp } = useMediaQueryUtil();

    return (
        <>
            <div className={`flex flex-col ${styles["chart-and-label-area"]} `}>
                <div className={`flex ${styles["label-wrapper"]}`}>
                    <div className="flex">
                        <Typography variant="h6">
                            {barChartAxisLabel}
                        </Typography>
                    </div>
                    <div className="flex">
                        <Typography variant="h6">
                            {linedChartAxisLabel}
                        </Typography>
                    </div>
                </div>

                <div className="flex flex-col">
                    <BarLineChart
                        barData={barData}
                        lineData={lineData}
                        linedChartLabel={linedChartLabel}
                        barChartLabel={barChartLabel}
                        xAxis={xAxis}
                        loading={loading}
                    />
                </div>
            </div>
            <div className={`flex ${styles["radio-selection-area"]}`}>
                {/* LG scrren - display radio buttons instead of dropdowns */}
                {lgUp &&
                    <div className={`flex-col ${styles["radio-selection-wrapper"]}`}>
                        <RadioGroup
                            options={TEMPORALITY_SELECTOR_OPTIONS}
                            value={period}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangePeriod(event.target.value)}
                            style={{ paddingBottom: "16px" }}
                        />
                        <RadioGroup
                            options={amountSelectorOptions}
                            value={amount}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeAmount(event.target.value)}
                            style={{
                                paddingTop: "16px",
                                borderTop: `1px solid ${paletteTheme.colors.GRAY}`,
                            }}
                        />
                    </div>
                }
            </div>
        </>
    )
}
