import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteProps, useNavigate } from "react-router-dom";
import { UserDetails } from "interfaces/user/user";
import { Perimeter } from "interfaces/perimeter/perimeter";
import { HOME_PATH, USER_PATH } from "constants/routes/RoutePaths";
import { useAuthUser } from "hooks/useAuthUser";
import { UserAccountEdit } from "components/molecules/useraccount/edit/UserAccountEdit";
import { rightUtils } from "utils/business/rightUtils";
import { PERMISSIONS } from "constants/permissions/permissions";
import { toastUtils } from "utils/toast/toastUtils";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import { PROFIL_USER } from "constants/user/user";
import { useIntl } from "react-intl";
import { PersonAddOutlined } from "@mui/icons-material";

export const UserNewView: FunctionComponent<RouteProps> = () => {
  const navigate = useNavigate();
  const [userDetails] = useState<UserDetails>({
    id: "",
    email: "",
    profile: PROFIL_USER.OPERATOR.id,
    language: null,
    firstName: "",
    lastName: "",
    distributor: false,
    permissions: []
  });
  const [perimeter] = useState<Perimeter>(perimeterUtils.buildEmptyPerimeter());
  const { user: authUser, permissions: authPermissions } = useAuthUser();
  const intl = useIntl();

  useEffect(() => {
    if (authUser) {
      if (!rightUtils.hasPermission(authPermissions, PERMISSIONS.USER_CREATE)) {
        toastUtils.errorToastI8ln(PERMISSIONS.USER_CREATE.forbiddenLabel)
        navigate(`/${HOME_PATH}`)
      }
    }

  }, [authUser])

  return (
    <UserAccountEdit
      title="create_user_title"
      idUser={userDetails.id}
      authPermissions={authPermissions}
      userDetails={userDetails}
      perimeter={perimeter}
      onDiscard={() => navigate(`/${USER_PATH}`)}
      onAfterSubmit={(userUpdated) => {
        navigate(`/${USER_PATH}/${userUpdated.id}`)
        toastUtils.successToastI8ln(intl.formatMessage({ id: "acc_creation_conf_message" }, { email: userUpdated.email }));
        if(userUpdated.paywishRole){
          toastUtils.successToastI8ln (intl.formatMessage({ id: "paywish_user_success_msg" }, { email: userUpdated.email }));
        }
      }}
      saveButtonLabel="create_user_button"
      saveButtonStartIcon={<PersonAddOutlined />} />
  )
}
