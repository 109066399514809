import { MessageKeys } from "constants/messages/i8ln/EnglishMessages";
import { 
    FormatNumberOptions, 
    useIntl,
 } from "react-intl";

interface UseIntlFormatter {
    formatMessage: (message: MessageKeys | string, values?: Record<string, string | number | React.ReactNode>) => string,
    formatDate: (date: string | number | Date) => string,
    formatNumber: (value: Parameters<Intl.NumberFormat["format"]>[0], opts?: FormatNumberOptions) => string,
}
/**
 * Custom hook for formatting messages and dates using the `react-intl` library.
 * @returns An object with `formatMessage` and `formatDate` functions.
 */
export const useIntlFormatter = (): UseIntlFormatter => {
    const intl = useIntl();

    /**
     * Formats a message using the `react-intl` library.
     * @param message The message to be formatted, as defined in `EnglishMessagesKeys`.
     * @returns The formatted message.
     */
    const formatMessage = (message: MessageKeys, values = {}) => {
        return intl.formatMessage({ id: message}, values);
    };

    /**
     * Formats a date using the `react-intl` library.
     * @param date The date to be formatted. Can be a string, number, or `Date` object.
     * @returns The formatted date.
     */
    const formatDate = (date: string | number | Date) => {
        return intl.formatDate(date);
    };

    /**
     * Formats number using the `react-intl` library.
     * @param value The number to be formatted. Can be a `number`, or `bigint`.
     * @param options Additional options for formatting `FormatNumberOptions`.
     * @returns The formatted number.
     */

    const formatNumber = (value: number | bigint, options?: FormatNumberOptions): string => {
        return intl.formatNumber(value, options ?? {});
    };

    return {
        formatMessage,
        formatDate,
        formatNumber,
    };
};
