import React, { FunctionComponent } from "react";
import { KeyNumbers, KeyNumberElement } from "interfaces/transaction/transaction";
import Typography from "@mui/material/Typography";
import { KeyNumberCard } from "components/molecules/keyNumbersCards/keyNumberCard";
import { FormattedMessage } from "react-intl";
import { paletteTheme } from "styles/themes/palette";
import styles from "components/molecules/keyNumbersCards/keyNumbersCards.module.scss";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";

export interface KeyNumbersCardsProps {
  keyNumbers: KeyNumbers;
  keyNumberElements: KeyNumberElement[];
  title?: string;
  dashboardLayout?: boolean;
}

const spanWholeLastRow = (keyNumberElements: KeyNumberElement[], keyNumberEl: KeyNumberElement) => {
  const isOddLength = keyNumberElements.length % 2 !== 0;
  const isLastItem = keyNumberElements[keyNumberElements.length - 1] === keyNumberEl;
  return isOddLength && isLastItem;
};

export const KeyNumbersCards: FunctionComponent<KeyNumbersCardsProps> = ({
  keyNumbers,
  keyNumberElements,
  title,
  dashboardLayout = false,
}) => {
  const { formatMessage } = useIntlFormatter();

  return (
    <div className={`${styles["key-card-wrapper"]} ${dashboardLayout ? styles["dashboard-layout"] : ""} `}>
      <div className={`${styles.title}`}>
        {title && (
          <Typography fontSize="14px" color={paletteTheme.colors.DEEP_GREEN}>
            <FormattedMessage id={title} />
          </Typography>
        )}
      </div>
      {keyNumberElements.map((keyNumber) => (
        <div
          key={keyNumber.id}
          className={`${styles.row} ${styles.card} ${
            spanWholeLastRow(keyNumberElements, keyNumber) ? styles["span-whole"] : ""
          } ${keyNumber.startFromNewLine ? styles["span-new-line"] : ""}`}>
          <KeyNumberCard
            key={keyNumber.id}
            number={keyNumbers[keyNumber.id]}
            currency={keyNumber.currency}
            legend={formatMessage(keyNumber.label)}
            icon={keyNumber.icon}
          />
        </div>
      ))}
    </div>
  );
};
