import React, { FunctionComponent } from "react";
import { ColParams, ColumnConfig, FilterColumn } from "interfaces/filter/filterColumn";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import styles from "components/molecules/filters/filters.module.scss";

interface FiltersProps {
    columns: FilterColumn[];
}

const getColConfig = (params: ColParams) => {
    let span = "";
    if (params) {
        const { colSpan, colStart } = params;
        span = `span ${colSpan ?? 1}`;

        if (colStart) {
            span += `/ ${colStart}`;
        }
    }
    return span;
}

export const Filters: FunctionComponent<FiltersProps> = ({ columns }) => {
    // columns are sorted by index to ensure that they are displayed in desired place in the grid
    const sortedColumns = columns.sort((a, b) => { return a.index > b.index ? 1 : -1; });

    const { smDown, mdDown, lgDown, lgUp } = useMediaQueryUtil();

    const calculateSpan = (config: ColumnConfig) => {
        if (config) {
            const { xs, sm, md, lg } = config;
            if (smDown) return getColConfig(xs);
            if (mdDown) return getColConfig(sm);
            if (lgDown) return getColConfig(md);
            if (lgUp) return getColConfig(lg);
        }
        return "";
    }

    const hideColumn = (config: ColumnConfig) => {
        if (config) {
            const { xs, sm, md, lg } = config;
            if (smDown) return xs?.hide ?? false;
            if (mdDown) return sm?.hide ?? false;
            if (lgDown) return md?.hide ?? false;
            if (lgUp) return lg?.hide ?? false;
        }
        return false;
    }

    return (
        <>
            {sortedColumns.map((column, index) =>
                <React.Fragment key={index}>
                    {!hideColumn(column.config) &&
                        <div
                            className={`${styles.col} ${styles[`${column.className || ""}`]}`}
                            key={`${column.name}-${index}`}
                            style={{ gridColumn: calculateSpan(column.config) }}
                        >
                            {column.component}
                        </div>
                    }
                </React.Fragment>
            )}
        </>
    )
}
