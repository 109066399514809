import React, {FunctionComponent} from "react";
import {RouteProps} from "react-router-dom";
import {useAuthUser} from "../hooks/useAuthUser";
import {perimeterService} from "../services/user/PerimeterService";
import { UserAccountDisplay } from "components/molecules/useraccount/display/UserAccountDisplay";
import {useAccountPerimeter} from "../hooks/account/useAccountPerimeter";
import WrappedLoadingContent from "../components/molecules/WrappedLoadingContent";

const AccountView: FunctionComponent<RouteProps> = () => {
    const {userDetails, cleanAndRefreshUserDetails} = useAuthUser();

    const {
        refreshPerimeter,
        getAccount,
        accounts,
        loading,
        perimeter
    } = useAccountPerimeter(userDetails,
        {
            onGetPerimeter: () => perimeterService.getSelfPerimeter(),
            onGetAccounts: () => perimeterService.getAuthorizedAccounts(),
            onGetAccount: (idAccount: string) => perimeterService.getDetailsAuthorizedCompanies(idAccount)
        })
    return (
        <WrappedLoadingContent loading={loading}>
            <UserAccountDisplay
                userDetails={userDetails}
                onUpdateUserDetails={cleanAndRefreshUserDetails}
                perimeter={perimeter}
                accounts={accounts}
                onCollapseAccount={getAccount}
                onUpdatePerimeter={refreshPerimeter}/>
        </WrappedLoadingContent>
    )
}

export default AccountView;
