import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { RouteProps } from "react-router-dom";
import Card from "components/atoms/card/Card";
import { useAuthUser } from "hooks/useAuthUser";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import { SettlementData, SettlementResponse } from "interfaces/settlement/settlement";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { paletteTheme } from "styles/themes/palette";
import { filterUtils } from "utils/business/filterUtils";
import Table from "components/atoms/table/Table";
import { settlementService } from "services/settlement/SettlementService";
import { FilterValues } from "interfaces/filter/filter";
import { KeyNumbers } from "interfaces/transaction/transaction";
import { SettlementSearchResponse } from "interfaces/api/ApiInterfaces";
import { GenericSearchData } from "interfaces/table/TableInterface";
import { useGenericTableFetch } from "hooks/table/useGenericTableFetch";
import { useFilter } from "hooks/filter/useFilter";
import { settlementUtils } from "utils/business/settlementUtils";
import MainFilters from "components/molecules/filter/MainFilters";
import ManyExportLineDialog from "components/molecules/dialog/ManyExportLineDialog";
import { useExport } from "hooks/export/useExport";
import { formatToEuro } from "constants/intl/number/format";
import { intlUtils } from "utils/intlUtils";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import { CONTENT_TYPE_EXPORT_SETTLEMENT } from "constants/export/export";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { computeColumns, getSettlementsColumns } from "utils/table/tableUtils";
import styles from "view/SettlementView/settlementView.module.scss";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { ManagableColumn } from "interfaces/table/table";
import { KeyNumbersCards } from "components/molecules/keyNumbersCards";
import { SETTLEMENT_KEY_NUMBERS } from "constants/settlement/settlement";
import { INITIAL_SETTLEMENT_FILTER } from "constants/transaction/transactionFilter";
import { DropdownButton } from "components/atoms/button/DropdownButton";

export const SettlementView: FunctionComponent<RouteProps> = () => {
  const defaultSettlementKeyNumbers = {
    totalGrossAmount: 0,
    totalCommission: 0,
    totalFinanced: 0,
  };

  const intl = useIntl();
  const { formatMessage } = useIntlFormatter();
  const { smDown, mdDown, lgDown, lgUp } = useMediaQueryUtil();
  const { user, userDetails } = useAuthUser();
  const savedInitialSettlementFilters: FilterValues = JSON.parse(localStorage.getItem(LocalStorageEnum.GLOBAL_PERIMETER_FILTERS));
  const [keyNumbers, setKeyNumbers] = useState<KeyNumbers>(defaultSettlementKeyNumbers);
  const [initialColumns, setInitialColumns] = useState<Array<ManagableColumn>>(getSettlementsColumns());
  const [totalCount, setTotalCount] = useState<number | string>(0);

  useEffect(() => {
    if (smDown) {
      setInitialColumns(getSettlementsColumns("xs"));
      return;
    }
    if (mdDown) {
      setInitialColumns(getSettlementsColumns("sm"));
      return;
    }
    if (lgDown) {
      setInitialColumns(getSettlementsColumns("md"));
      return;
    }
    setInitialColumns(getSettlementsColumns());
  }, [smDown, mdDown, lgDown, lgUp]);

  const {
    perimeterOptions,
    onChangePerimeterOptions,
    initialPerimeterOptions: initialOptions,
    filters: settlementFilters,
    onChangeFilters,
    onSearchFilter,
    onResetFilter,
    showContent,
    tableRef,
  } = useFilter<FilterValues>(
    userDetails,
    filterUtils.getSettlementsInitialFilters(),
    [LocalStorageEnum.GLOBAL_PERIMETER_FILTERS, LocalStorageEnum.SETTLEMENTS_FILTERS],
    settlementUtils.initSavedSettlementFilter(savedInitialSettlementFilters)
  );

  const onReset = () => {
    onChangeFilters(filterUtils.getInitialFiltersByPerimeter(perimeterOptions, INITIAL_SETTLEMENT_FILTER.operationDate));
    setKeyNumbers(defaultSettlementKeyNumbers);
    onResetFilter();
  };

  const onSearch = () => {
    onSearchFilter();
  };

  const columns = useMemo(() => {
    const defaulStyles = {
      width: smDown ? 70 : 90,
      minWidth: 70,
      maxWidth: 300,
    };
    return [
      ...computeColumns(initialColumns, intl, defaulStyles),
    ];
  }, [initialColumns, intl.locale]);

  const responseToData = (
    response: SettlementSearchResponse<SettlementResponse>
  ): GenericSearchData<SettlementData> => {
    setKeyNumbers({ ...response.aggregation });
    return {
      datas: response.content.map((settlement) => {
        return {
          batchId: settlement.batchId,
          settlementDate: settlement.settlementDate,
          paymentSolution: settlement.solution,
          grossAmount: intlUtils.formatNumber(intl, settlement.grossAmount, formatToEuro),
          commission: intlUtils.formatNumber(intl, settlement.totalCommission, formatToEuro),
          financed: intlUtils.formatNumber(intl, settlement.financed, formatToEuro),
          storeName: settlement.storeName,
          magasinId: settlement.magasinId,
          buId: settlement.buId
        };
      }),
      last: response.last,
      totalElements: response.totalElements,
    };
  };

  const { onFetchData, count } = useGenericTableFetch<SettlementResponse, SettlementData>(
    settlementService.getSettlements(settlementFilters),
    responseToData
  );

  const { openManyExportLine, setOpenManyExportLine, isExportLoading, onDownload } = useExport(
    count,
    process.env.REACT_APP_MAX_SETTLEMENT_EXPORT,
    (type: string) => settlementService.downloadExportSettlements(settlementFilters, type, undefined, intl.locale.toString()),
    "export_settlements"
  );

  return (
    <Card
      cardTitle={
        <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
          <FormattedMessage id="settlements_title" />
        </Typography>
      }>
      <MainFilters
        filters={settlementFilters}
        onChangeFilters={onChangeFilters}
        user={user}
        onChangePerimeterOptions={onChangePerimeterOptions}
        perimeterOptions={perimeterOptions}
        initialOptions={initialOptions}
        onSearch={onSearch}
        onReset={onReset}
        operationLabel="filter_settlement_date"
        marginBottom="40px"
      />
      <KeyNumbersCards
        keyNumberElements={SETTLEMENT_KEY_NUMBERS}
        keyNumbers={showContent ? keyNumbers : defaultSettlementKeyNumbers}
        title="settled_by_mp"
      />
      <div style={{ display: showContent ? "block" : "none" }}>
        <div className={`flex flex-row-between flex-col-end ${styles.wrapManButton}`}>
          <Typography>
            {formatMessage("table_label", {
              count: (
                <strong style={{ color: paletteTheme.colors.PRIMARY }}>
                  {totalCount}
                </strong>
              )
            })}
          </Typography>
        </div>
        <Table
          ref={tableRef}
          columns={columns}
          onFetch={onFetchData}
          disableNextPage={!perimeterUtils.hasPerimeterFilters(settlementFilters)}
          FooterButtonList={
            <>
              <DropdownButton
                isExportLoading={isExportLoading}
                onDownload={onDownload}
                availableExports={CONTENT_TYPE_EXPORT_SETTLEMENT}
              />
            </>
          }
          updateTotalCountParent={(count: number | string) => setTotalCount(count)}
        />
      </div>

      <ManyExportLineDialog
        openDialog={openManyExportLine}
        onCloseDialog={() => setOpenManyExportLine(false)}
        maxLines={process.env.REACT_APP_MAX_SETTLEMENT_EXPORT}
      />

      <div
        className="flex-col flex-col-center"
        style={{ marginBottom: "30px", display: showContent ? "none" : "flex" }}>
        <Typography variant="h2">{formatMessage("admin_trans_view_title")}</Typography>
        <Typography>{formatMessage("admin_trans_view_text")}</Typography>
      </div>
    </Card>
  );
};
