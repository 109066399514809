import React, {FunctionComponent} from "react";
import {Link as ReactLink} from "react-router-dom";
import {Link as LinkUI, LinkProps as LinkUIProps} from "@mui/material"
import {useChangeContext} from "../../../constants/context/ChangeContext";

interface LinkProps extends LinkUIProps {
    to?: string,
    search?: string,
    className?: string
    noUnderline?: boolean
    component?: React.ElementType
    disableWatch?: boolean
}

const WatchedLinkUI: FunctionComponent<LinkProps> = ({
                                                         to = "",
                                                         search = "",
                                                         children,
                                                         className = "",
                                                         noUnderline = false,
                                                         component,
                                                         disableWatch = false,
                                                         onClick: onClickProps,
                                                         ...rest
                                                     }) => {
    const {unsaveChange, setOpenDialog, setNavigateTo} = useChangeContext();
    const toFormatted = {pathname: to, search};
    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (unsaveChange && !disableWatch) {
            setOpenDialog(true)
            setNavigateTo(toFormatted)
        } else {
            onClickProps(e)
        }
    }

    return (
        <ReactLink
            to={toFormatted}
            onClick={onClick}
            className={`mp-text-link ${noUnderline ? "no-underline " : " "} ${className}`}
            style={{    
                display: "flex",
                width: "100%",
                justifyContent:"center",
                }}
            >
            <LinkUI
                {...rest}
                onClick={(e: React.MouseEvent<HTMLSpanElement>) => e.preventDefault()}
                component={component}
            >{children}
            </LinkUI>
        </ReactLink>
    );
}

export default WatchedLinkUI;
