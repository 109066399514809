import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {IntlMessage} from "../../../constants/intl/intl";

export interface AdvancedFormattedMessageProps {
    title: string | IntlMessage
}

const AdvancedFormattedMessage: FunctionComponent<AdvancedFormattedMessageProps> = ({title}) => (
    typeof title === "string" ? <FormattedMessage id={title}/> : <FormattedMessage id={title.id} values={title.values}/>
)

export default AdvancedFormattedMessage;
