import React, { FunctionComponent } from "react";

export const DeleteAlertIcon: FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39" fill="none">
    <path
      d="M7 30.875V11.375H26.5V30.875C26.5 32.6625 25.0375 34.125 23.25 34.125H10.25C8.4625 34.125 7 32.6625 7 30.875ZM10.25 14.625V30.875H23.25V14.625H10.25ZM22.4375 6.5H28.125V9.75H5.375V6.5H11.0625L12.6875 4.875H20.8125L22.4375 6.5ZM31.375 27.625V24.375H34.625V27.625H31.375ZM31.375 21.125V11.375H34.625V21.125H31.375Z"
      fill="#D3412D"
    />
  </svg>
);
