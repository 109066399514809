import { ApiError, PromisableApiError, errorUtils } from "utils/api/errorUtils";
import { validationErrorsUtils } from "utils/business/validationErrorsUtils";
import { ValidateErrorMap } from "../edit/UserAccountEdit";
import { Options } from "constants/options/option";
import { arrayUtils } from "utils/common/arrayUtils";
import { Entity, PerimeterUpdate } from "interfaces/perimeter/perimeter";
import { toastUtils } from "utils/toast/toastUtils";
import { languageUtils } from "utils/common/languageUtils";

async function manageOnSubmitErrors(e: PromisableApiError, setter: (errors: ValidateErrorMap) => void) {
  try {
    const apiError = await e;
    if (errorUtils.interceptError(
      e, "EMAIL_ALR_USED")) {
      errorUtils.handleErrors(e, (apiError) => setter(validationErrorsUtils.cleanAndUpdate(apiError.errors)))
    } else {
      // duplicate paywish user error
      if (apiError.status === 409) {
        toastUtils.errorToast(languageUtils.getMessage("paywish_user_error_duplicate"))
      } else {
        errorUtils.handleBackErrors(e, "paywish_user_error_msg");
        errorUtils.handleErrors(e, (apiError) => setter(validationErrorsUtils.cleanAndUpdate(apiError.errors)), "paywish_user_error_msg")
      }
    }
  } catch (error) {}
}

function isOptionEmpty(_option: Options) {
  return _option && _option.id !== ""
}

function isQueryEmptyAndInitialAuthorizedAccountNotEmpty(queryAccount: string, allAuthorizedAccounts: Array<Entity>) {
  return !queryAccount && !arrayUtils.isEmpty(allAuthorizedAccounts);
}

function getInitalOptions(initialPerimeter: PerimeterUpdate) {
  return !!initialPerimeter.account.id ? [initialPerimeter.account] : []
}

export const userAccountUtils = {
  manageOnSubmitErrors,
  isOptionEmpty,
  isQueryEmptyAndInitialAuthorizedAccountNotEmpty,
  getInitalOptions
}
