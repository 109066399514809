import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import Card from "../../atoms/card/Card";
import {Box, Divider, Typography} from "@mui/material";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {paletteTheme} from "../../../styles/themes/palette";
import Notification from "../notification/Notification";
import { KeyNumbers } from "../../../interfaces/transaction/transaction";
import { PerimeterOptions } from "constants/perimeter/perimeter";
import {FilterValues} from "../../../interfaces/filter/filter";
import { WrappedPerimeterFilters } from "components/molecules/filter/WrappedPerimeterFilters";
import { DailyKeyNumbersDisplay } from "./DailyKeyNumbersDisplay/DailyKeyNumbersDisplay";
import {rightUtils} from "../../../utils/business/rightUtils";
import {useNotificationContext} from "../../../constants/context/NotificationContext";
import NotificationHideButton from "../notification/NotificationHideButton";

interface HomepageIntroductionProps {
    onChangePerimeterOptions: (value: PerimeterOptions) => void,
    perimeterOptions: PerimeterOptions,
    initialOptions: PerimeterOptions,
    filters: FilterValues
    onChangeFilters: (filters: FilterValues) => void
    onSearch: () => void
    onReset: () => void
    transDailyKeyNumbers: KeyNumbers,
    settDailyKeyNumbers: KeyNumbers,
}

const HomepageIntroduction: FunctionComponent<HomepageIntroductionProps> = ({
                                                                                onChangePerimeterOptions,
                                                                                perimeterOptions,
                                                                                initialOptions,
                                                                                filters,
                                                                                onChangeFilters,
                                                                                onSearch,
                                                                                onReset,
                                                                                transDailyKeyNumbers,
                                                                                settDailyKeyNumbers
                                                                            }) => {
    const {user, userDetails} = useAuthUser();
    const currentHour = (new Date()).getHours();
    const isOperator = rightUtils.isOperator(user);
    const {
        userHomepageActiveNotifications,
        hideNotification,
        hasUserActiveNotifications,
        onHideAll
    } = useNotificationContext();

    return (
        <Card>
            <Typography variant="h2" display="inline">
                {currentHour > 7 && currentHour < 18 ? <FormattedMessage id="good_morning"/> :
                    <FormattedMessage id="good_evening"/>}
            </Typography>
            <Typography variant="h2" display="inline" textTransform="uppercase" fontWeight={700}
                        color={paletteTheme.colors.PRIMARY}>
                {` ${user?.firstName} ${user?.lastName}`}
            </Typography>

            {hasUserActiveNotifications &&
                <Box marginTop="20px">
                    {userHomepageActiveNotifications.map(notif => (
                        <Notification
                            key={notif.id}
                            notification={notif}
                            onClick={hideNotification}
                            marginLeft="10px"
                            canHide>
                        </Notification>
                    ))}
                    <NotificationHideButton label="notif_hide_all_button" onHide={onHideAll}
                                            marginLeft="22px"></NotificationHideButton>
                </Box>
            }

            <Divider sx={{margin: "20px 10px"}}/>
            <Typography variant="h2" marginTop="39px">
                <FormattedMessage id="homepage_today"/>
            </Typography>
            {!isOperator && <WrappedPerimeterFilters
                onChangePerimeterOptions={onChangePerimeterOptions}
                perimeterOptions={perimeterOptions}
                initialOptions={initialOptions}
                filters={filters}
                onChangeFilters={onChangeFilters}
                user={user}
                hideTitle
                onSearch={onSearch}
                onReset={onReset}/>
            }

            {user && <DailyKeyNumbersDisplay
                        user={userDetails}
                        transDailyKeyNumbers={transDailyKeyNumbers}
                        settDailyKeyNumbers={settDailyKeyNumbers}
                    />
            }
        </Card>
    )
}

export default HomepageIntroduction;
