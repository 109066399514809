import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subMonths } from "date-fns";
import { ShortcutRange } from "../../interfaces/datepicker/datepicker";
import { Range } from "react-date-range";
import { dateUtils } from "utils/common/dateUtils";

export const SHORTCUT_RANGES: ShortcutRange[] = [
  {
    label: "date_picker_today",
    range: {
      startDate: new Date(),
      endDate: new Date()
    }
  },
  {
    label: "date_picker_yesterday",
    range: {
      startDate: subDays(new Date(), 1),
      endDate: subDays(new Date(), 1)
    }
  },
  {
    label: "date_picker_current_month",
    range: {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date())
    }
  },
  {
    label: "date_picker_last_week",
    range: {
      startDate: startOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }),
      endDate: endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 })
    }

  },
  {
    label: "date_picker_last_two_weeks",
    range: {
      startDate: startOfWeek(subDays(new Date(), 14), { weekStartsOn: 1 }),
      endDate: endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 })
    }
  },
  {
    label: "date_picker_last_month",
    range: {
      startDate: startOfMonth(subMonths(new Date(), 1)),
      endDate: endOfMonth(subMonths(new Date(), 1))
    }
  }
];


const getPastDate = (daysAgo: number): Date => {
  const currentDate = new Date();
  const pastDate = new Date();
  pastDate.setDate(currentDate.getDate() - daysAgo);
  return pastDate;
};

export const EMPTY_DATE_INITIAL_STATE: Range = {
  startDate: null,
  // the following line is a workaround to set initial empty range, which the library doesn't support yet
  // https://github.com/hypeserver/react-date-range/issues/330
  endDate: new Date(""),
  showDateDisplay: false,
  key: "selection",
};

export const INITIAL_RANGE_TODAY: Range = {
  startDate: dateUtils.resetTimeToMidnight(new Date()),
  endDate: dateUtils.resetTimeToMidnight(new Date()),
  showDateDisplay: false,
  key: "selection",
};

export const INITIAL_RANGE_LAST_WEEK: Range = {
  startDate: getPastDate(8),
  endDate: getPastDate(1),
  showDateDisplay: false,
  key: "selection",
};
