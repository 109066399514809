import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "services/auth/AuthService";
import { useAuthUser } from "hooks/useAuthUser";
import { LOGIN_PATH } from "constants/routes/RoutePaths";
import { useChangeContext } from "constants/context/ChangeContext";
import { MENU_ITEMS_USER } from "constants/menu/menu";
import { useUserContext } from "constants/context/UserContext";
import { SideMenuItem } from "components/molecules/menu/SideMenu/SideMenuItem";
import { SideMenuItemsProps } from "components/molecules/menu/SideMenuPageItems";
import { LanguageDropdownMobile } from "components/molecules/menu/LanguageDropdownMobile";
import { MenuList } from "@mui/material";
import { ItemMenu } from "../SideMenu/ItemMenu";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { ExitToApp } from "@mui/icons-material";

interface ISideMenuUserItems extends SideMenuItemsProps {
  isMobile: boolean;
  onCloseMobileMenu: () => void;
}

const SideMenuUserItems: FunctionComponent<ISideMenuUserItems> = ({ isReduced, isMobile, onCloseMobileMenu }) => {
  const { formatMessage } = useIntlFormatter();
  const { setUnsaveChange } = useChangeContext();
  const { setUser, setPermissions } = useUserContext();
  const { user } = useAuthUser();
  const navigate = useNavigate();

  const handleLogOutOnClick = () => {
    setUnsaveChange(false);
    setUser(null);
    setPermissions([]);
    authService.logout();
    navigate(LOGIN_PATH);
  };

  return (
    <>
      {!!user && (
        <MenuList sx={{ padding: 0 }}>
          {MENU_ITEMS_USER.map((item, idx) => (
            <li key={idx}>
              <SideMenuItem item={item} isReduced={isReduced} user={user}/>
            </li>
          ))}
          {isMobile && <LanguageDropdownMobile mobile={true} isReduced={isReduced} onCloseMobileMenu={onCloseMobileMenu} />}
          <div role="buton" onClick={() => handleLogOutOnClick()}>
            <ItemMenu Icon={ExitToApp} label={formatMessage("log_out")} isReduced={isReduced} />
          </div>
        </MenuList>
      )}
    </>
  );
};

export default SideMenuUserItems;
