import React, { Dispatch, SetStateAction } from "react";
import { Range } from "react-date-range";
import { AmountRangeWrapper } from "components/molecules/filters/FilterComponentsWrappers/AmountRangeWrapper";
import { AutocompleteCheckboxWrapper } from "components/molecules/filters/FilterComponentsWrappers/AutocompleteCheckboxWrapper";
import { DateRangePickerInputWrapper } from "components/molecules/filters/FilterComponentsWrappers/DateRangePickerInputWrapper";
import { MultiChoiceCheckboxWrapper } from "components/molecules/filters/FilterComponentsWrappers/MultichoiceCheckboxWrapper";
import { TextFieldWrapper } from "components/molecules/filters/FilterComponentsWrappers/TextFieldWrapper";
import { QueryAndReset } from "components/molecules/filters/QueryAndReset";
import { DocumentsFilterValues, FilterValues, PortfolioTopBottomFilterValues, PortfolioValumeTrendsFilterValues } from "interfaces/filter/filter";
import { PerimeterOptions } from "constants/perimeter/perimeter";
import {
  TransactionAdditionalFilter,
  TransactionAdditionalFiltersState,
  TransactionAdditionalFiltersValues,
} from "interfaces/transaction/transaction";
import {
  CompanyFilterOptions,
  MerchantFilterOptions,
  Options,
} from "constants/options/option";
import { TransactionType } from "interfaces/filter/filterColumn";

export interface CommonFilterProps {
  onSearch: () => void;
  onReset: () => void;
  errors: Record<string, boolean>;
}

export interface AmountRangeConfig {
  errors: Record<string, boolean>;
  setErrors: Dispatch<SetStateAction<Record<string, boolean>>>;
  onChangeAmountRangeFilter: (changedField: string) => (value: string) => void;
  transactionAdditionalFilters: TransactionAdditionalFiltersValues;
}

export interface PerimeterConfig {
  isAdminOrGroupPerimeter: boolean;
  filters: FilterValues;
  perimeterOptions: PerimeterOptions;
  initialOptions: PerimeterOptions;
  onChangeFilters: (filters: FilterValues) => void;
  onChangePerimeterOptions: (value: PerimeterOptions) => void;
  uniqueCompany?: boolean;
  uniqueMerchant?: boolean;
}

export interface AutocompleteCheckboxConfig {
  onChangeAccountsSelection: (selectedAccounts: Options[]) => void;
  onChangeCompaniesSelection: (selectedCompanies: CompanyFilterOptions[]) => void;
  onChangeMerchantsSelection: (selectedMerchants: MerchantFilterOptions[]) => void;
  onChangeAccountName: (search: string) => void;
  onChangeCompanyName: (search: string, page?: number, accounts?: string[]) => void;
  onChangeMerchantName: (search: string) => void;
  handleAccountsSelection: () => void;
  handleCompaniesSelection: () => void;
}

export interface TextFieldConfig {
  filters: FilterValues;
  transactionAdditionalFilters: TransactionAdditionalFiltersValues;
  onChangeAlphanumericFilter: (field: string) => (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeFilterValue: (field: string) => (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export interface DatePickerConfig {
  isAdmin: boolean;
  filters: FilterValues;
  transactionAdditionalFilters?: TransactionAdditionalFiltersValues;
  portfolioTopBottomFilterValues?: PortfolioTopBottomFilterValues;
  onChangeDateFilter?: (field: string) => (value: Range) => void;
  onChangeOperationDate?: (operationDate: Range) => void;
  onChangeSettlementDate?: (settlementDate: Range) => void;
  onChangeAcceptationDate?: (acceptationRange: Range) => void;
}

export interface MultichoiceConfig {
  transactionAdditionalFilters?: TransactionAdditionalFiltersValues;
  documentsFilters?: DocumentsFilterValues;
  trendByFilters?: PortfolioValumeTrendsFilterValues;
  onChangeMultichoiceFilter: (field: string) => (values: Options[]) => void;
}

export interface ExpandCollapseFiltersConfig {
  iconSize: number;
  appliedFiltersCount: number;
  resetFilterCount: number;
  moreFiltersButtonState: TransactionAdditionalFiltersState;
  onMoreLessFiltersClick: () => void;
}

export interface QueryAndResetConfig {
  labelErrorOnDisable: string;
  disabledWithPopup: boolean;
  startIcon?: React.ReactNode;
  buttonText?: string;
  loading?: boolean;
}

export const useWrappedFilterComponents = () => {

  // also used for single-choice select
  const multiChoiceCheckbox = (itemConfig: TransactionAdditionalFilter, multichoiceConfig: MultichoiceConfig, type: TransactionType | "DOCUMENTS" | "PORTFOLIO_TREND_BY" ) => (
    <MultiChoiceCheckboxWrapper
      itemConfig={itemConfig}
      multichoiceConfig={multichoiceConfig}
      type={type}
    />);

  const textField = (itemConfig: TransactionAdditionalFilter, textFieldConfig: TextFieldConfig) => (
    <TextFieldWrapper
      itemConfig={itemConfig}
      textFieldConfig={textFieldConfig}
    />);

  const dateRange = (name: string, datePickerConfig: DatePickerConfig, typeTransaction: TransactionType | "PortfolioTopBottom") => (
    <DateRangePickerInputWrapper
      name={name}
      datePickerConfig={datePickerConfig}
      type={typeTransaction}
    />);

  const amountRange = (itemConfig: TransactionAdditionalFilter, amountRangeConfig?: AmountRangeConfig) => (
    <AmountRangeWrapper
      itemConfig={itemConfig}
      amountRangeConfig={amountRangeConfig}
    />);

  const autocompleteCheckbox = (name: string, perimeterConfig?: PerimeterConfig, autoCompleteCheckboxConfig?: AutocompleteCheckboxConfig) => (
    <AutocompleteCheckboxWrapper
      name={name}
      perimeterConfig={perimeterConfig}
      autoCompleteCheckboxConfig={autoCompleteCheckboxConfig}
    />);

  const queryAndReset = (onSearch: () => void, onReset: () => void, errors, queryAndResetConfig: QueryAndResetConfig) => <QueryAndReset onSearch={onSearch} onReset={onReset} errors={errors} {...queryAndResetConfig} />;

  return {
    autocompleteCheckbox,
    amountRange,
    dateRange,
    textField,
    multiChoiceCheckbox,
    queryAndReset,
  }
}
