import {DependencyList, useEffect} from "react";
import {Destructor} from "../constants/hook/hooks";


/**
 * Auto implementation of AbortController in a useEffect.
 * Everytime you fetch in a useEffet, you should use it instead
 */
export const useEffectAbortable = (_useEffect: (ac: AbortController) => void | Destructor, deps: DependencyList) => {
    useEffect(() => {
        const abortController = new AbortController();
        const onUnmount = _useEffect(abortController);
        return () => {
            onUnmount && onUnmount()
            abortController.abort();
        }
    }, deps)
}
