import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { FlexAttributValue } from "types/flex/flex";
import FlexBox, { FlexBoxProps } from "components/atoms/flex/FlexBox";
import { ResetIconButton } from "components/atoms/button/ResetButton/ResetIconButton";
import { QueryButton } from "components/atoms/button/SearchButton";
import { Typography } from "@mui/material";

export interface TransactionFiltersProps extends FlexBoxProps {
  onSearch: () => void;
  onReset: () => void;
  errors?: Record<string, boolean>;
  disabledWithPopup?: boolean;
  hideTitle?: boolean;
  marginTop?: string;
}

export const MainFiltersLayout: FunctionComponent<TransactionFiltersProps> = ({
  errors = {},
  hideTitle,
  onSearch,
  onReset,
  disabledWithPopup,
  marginTop = "10px",
  children,
  ...rest
}) => {
  return (
    <FlexBox {...rest}
      justifyContent={FlexAttributValue.START}
      flexDirection={FlexAttributValue.COLUMN}
      alignItems={FlexAttributValue.START}
      flexWrap={FlexAttributValue.WRAP}>
      {!hideTitle && <Typography variant="h2" marginBottom="10px"><FormattedMessage id="filter_by" /></Typography>}
      <FlexBox>
        {children}
        <FlexBox marginRight="10px" marginTop={marginTop}>
          <QueryButton
            onClick={() => onSearch()}
            disabled={disabledWithPopup || Object.keys(errors).length > 0}
            disabledWithPopup={disabledWithPopup && Object.keys(errors).length === 0}
            labelErrorOnDisable="filter_search_button_toaster"
          >
            <FormattedMessage id="search" />
          </QueryButton>
        </FlexBox>
        <FlexBox marginRight="10px" marginTop={marginTop} alignItems={FlexAttributValue.CENTER}>
          <ResetIconButton
            onClick={onReset}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}
