import React, {
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { UserAccountPerimeterDetails } from "components/molecules/useraccount/details/UserAccountPerimeterDetails";
import Card from "components/atoms/card/Card";
import { PermissionDisplay } from "components/molecules/permission/PermissionDisplay";
import {
  SETTLEMENTS_PERMISSIONS,
  PORTFOLIO_PERMISSIONS,
  TRANSACTIONS_PERMISSIONS,
  USERS_PERMISSIONS,
} from "constants/permissions/permissions";
import ContainedButton from "components/atoms/button/ContainedButton";
import { FormattedMessage } from "react-intl";
import OutlinedButton from "components/atoms/button/OutlinedButton";
import {
  Clear,
  PriorityHigh,
  SaveOutlined,
} from "@mui/icons-material";
import {
  UpdateUserInfo,
  UserDetails,
} from "interfaces/user/user";
import { Perimeter } from "interfaces/perimeter/perimeter";
import { UserAccountInformationEdit } from "components/molecules/useraccount/edit/UserAccountInformationEdit";
import { UserAccountPerimeterEdit } from "components/molecules/useraccount/edit/UserAccountPerimeterEdit";
import { rightUtils } from "utils/business/rightUtils";
import { MaterialUiColor } from "types/materialui/materialui";
import { useAccountEdit } from "hooks/account/useAccountEdit";
import { UserDetailsResponse } from "interfaces/UserInterface";
import { createUserUtils } from "utils/business/createUserUtils";
import { IntlMessage } from "constants/intl/intl";
import ConfirmDialog from "components/molecules/dialog/ConfirmDialog";
import { scrollUtils } from "utils/common/scrollUtils";
import { UserGroupAccountPerimeterDetails } from "components/molecules/useraccount/details/UserGroupAccountPerimeterDetails";
import { AccountResponse } from "interfaces/perimeter/account";
import { BackLink } from "components/molecules/BackLink";
import { USER_PATH } from "constants/routes/RoutePaths";
import styles from "components/molecules/useraccount/edit/UserAccountEdit/userAccountEdit.module.scss";
import { UserAccountPayWishUserEdit } from "components/molecules/useraccount/edit/UserAccountPayWishUserEdit";

interface AccountEditProps {
  title: string | IntlMessage;
  userDetails: UserDetails;
  authPermissions: Array<string>;
  idUser?: string;
  perimeter: Perimeter;
  onDiscard: () => void;
  onAfterSubmit: (userUpdated: UserDetailsResponse) => void;
  saveButtonLabel: string;
  saveButtonStartIcon: JSX.Element;
  accounts?: Array<AccountResponse>;
  onCollapseAccount?: (idAccount: string) => Promise<Perimeter>;
}

export type ValidateError = boolean | Record<string, boolean>;

export interface ValidateErrorMap {
  [key: string]: ValidateError;
}

export const UserAccountEdit: FunctionComponent<AccountEditProps> = ({
  title,
  userDetails,
  authPermissions,
  idUser = null,
  perimeter,
  onDiscard: onDiscardProps,
  onAfterSubmit,
  saveButtonLabel,
  saveButtonStartIcon,
  accounts = [],
  onCollapseAccount,
}) => {

  const [loading, setLoading] = useState<boolean>(false);

  const {
    errors,
    validated,
    editedPerimeter,
    user,
    setUser,
    onUpdatePerimeter,
    onChangePersInfo,
    onChangeProfile,
    onChangeDistributor,
    onSubmitCreate,
    onSubmitUpdateSelf,
    onSubmitUpdate,
    disableBlockingPopup,
    openDiscardDialog,
    onCloseDiscardDialog,
    onDiscard: onDiscardEdit,
    payWishMerchants,
    onChangePaywishUserRole,
    onChangePaywishMerchant,
    onTogglePaywishUser,
  } = useAccountEdit(perimeter, userDetails, idUser,
    {
      onAfterSubmit,
      onFinallyAfterSubmit: () => setLoading(false)
    });

  const onDiscard = () => {
    scrollUtils.scrollToTop();
    onDiscardProps();
    disableBlockingPopup();
    onDiscardEdit();
  }

  const canUpdatePerimeter = user && rightUtils.isOperator(user) && !createUserUtils.isSelfFromParam(idUser);

  const handleSubmit = () => {
    const ac = new AbortController();
    if (validated()) {
      setLoading(true);
      const newUserInformations: UpdateUserInfo = {
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        distributor: user.distributor,
        paywishRole: user.paywishRole,
        djmId: user.djmId,
      };
      if (createUserUtils.isNewUser(user)) {
        onSubmitCreate(newUserInformations, ac);
      } else if (!idUser) {
        onSubmitUpdateSelf(newUserInformations, ac);
      } else {
        onSubmitUpdate(newUserInformations, ac);
      }
    }
    // Abort calls on dismount
    return () => ac.abort();
  }
  const displayBacklink = createUserUtils.isNewUser(user) || (idUser && !createUserUtils.isSelfFromParam(idUser));
  const createUser = createUserUtils.isNewUser(user);

  const hasPayWishMerchants = payWishMerchants?.length > 0 ?? false;
  const displayEditPaywish = (!createUser && user.paywishRole) || (createUser && hasPayWishMerchants && user.profile === "OPERATOR");

  useEffect(() => {
    if (!displayEditPaywish) {
      onTogglePaywishUser(false);
    }
  }, [displayEditPaywish])

  return (<>
    <UserAccountInformationEdit
      user={user}
      idUser={idUser}
      onChangePersInfo={onChangePersInfo}
      onChangeProfile={onChangeProfile}
      onChangeDistributor={onChangeDistributor}
      errors={errors}
      cardTitle={title}
      isCreate={createUser} />
    <Card header="peri">
      {canUpdatePerimeter ?
        <UserAccountPerimeterEdit
          perimeter={perimeter}
          editedPerimeter={editedPerimeter}
          perimetersErrors={errors}
          onChangePerimeter={onUpdatePerimeter}
        /> : rightUtils.hasGroupPerimeter(userDetails) ?
          <UserGroupAccountPerimeterDetails
            onCollapseAccount={onCollapseAccount}
            user={userDetails}
            accounts={accounts} /> :
          <UserAccountPerimeterDetails
            user={userDetails}
            perimeter={perimeter} />
      }
    </Card>
    {displayEditPaywish &&
      <Card header="title_paywish">
        <UserAccountPayWishUserEdit
          onChangePaywishUserRole={onChangePaywishUserRole}
          onChangePaywishMerchant={onChangePaywishMerchant}
          createUser={createUser}
          paywishRole={user.paywishRole}
          onTogglePaywishUser={onTogglePaywishUser}
          paywishMerchants={payWishMerchants}
        />
      </Card>
    }
    <Card header="permissions">
      {user && user.permissions &&
        <div className={styles.container}>
          <div className={styles.col}>
            <PermissionDisplay
              category={TRANSACTIONS_PERMISSIONS}
              userDetails={user}
              authPermissions={authPermissions}
              idUser={idUser}
              onChangePermissions={setUser}
              edit />
            <PermissionDisplay
              category={PORTFOLIO_PERMISSIONS}
              idUser={idUser}
              userDetails={user}
              authPermissions={authPermissions}
              onChangePermissions={setUser}
              edit />
          </div>
          <div className={styles.col}>
            <PermissionDisplay
              category={SETTLEMENTS_PERMISSIONS}
              userDetails={user}
              authPermissions={authPermissions}
              idUser={idUser}
              onChangePermissions={setUser}
              edit />
            <PermissionDisplay
              category={USERS_PERMISSIONS}
              idUser={idUser}
              userDetails={user}
              authPermissions={authPermissions}
              onChangePermissions={setUser}
              edit />
          </div>
        </div>
      }
    </Card>

    <div style={{ justifyContent: "center", display: "flex", position: "relative" }} >
      {displayBacklink &&
        <div className={styles.backlink}>
          <BackLink to={`/${USER_PATH}`} label="back_user_list" />
        </div>
      }
      <div className={styles["btn-wrap"]}>
        <OutlinedButton
          onClick={onDiscard}
          color={MaterialUiColor.SECONDARY}
          startIcon={<Clear />}
          sx={{ marginRight: 2 }}>
          <FormattedMessage id="discard_changes" />
        </OutlinedButton>
      </div>
      <div className={styles["btn-wrap"]}>
        <ContainedButton
          onClick={handleSubmit}
          loading={loading}
          startIcon={saveButtonStartIcon}>
          <FormattedMessage id={saveButtonLabel} />
        </ContainedButton>
      </div>

    </div>

    <ConfirmDialog openDialog={openDiscardDialog}
      onCloseDialog={onCloseDiscardDialog}
      onDiscard={onDiscard}
      handleSubmit={handleSubmit}
      dialogIcon={
        <>
          <SaveOutlined color="primary" fontSize="large" />
          <PriorityHigh sx={{ position: "absolute", top: 9, left: "25px" }} color="primary"
            fontSize="small" />
        </>
      }
      idMessage="unsaved_ch_err_mess"
      idConfirmMessage="unsaved_ch_err_mess_save"
      submitMessage="save">
    </ConfirmDialog>
  </>)
}
