import React, {FunctionComponent, useEffect} from "react"
import {Routes} from "react-router-dom"
import {AppRoutes} from "./constants/routes/AppRoutes"
import {AppRouteInterface} from "./interfaces/RouteInterfaces";
import {routeUtils} from "./utils/common/routeUtils";
import "react-toastify/dist/ReactToastify.css";
import { 
    CssBaseline, 
    ThemeProvider,
} from "@mui/material";
import "./styles/css/_app.scss"
import {MP_THEME} from "./styles/themes/theme";
import ScrollToTop from "./components/ScrollToTop";
import {useAuthUser} from "./hooks/useAuthUser";
import { LocalStorageEnum } from "constants/localstorage/localstortage";

const AppTheme: FunctionComponent = () => {

    const {refreshUserDetails} = useAuthUser();

    useEffect(() => {
        if(localStorage.getItem(LocalStorageEnum.TOKEN)){
            refreshUserDetails()
        }
    },[])

    return (
        <ThemeProvider theme={MP_THEME}>
            <CssBaseline />
            <React.StrictMode>
                <ScrollToTop>
                    <Routes>
                        {AppRoutes.map((route: AppRouteInterface) => routeUtils.recursiveRoutes(route))}
                    </Routes>
                </ScrollToTop>
            </React.StrictMode>
        </ThemeProvider>
    )
}

export default AppTheme
