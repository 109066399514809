import {
    HistogramAmountSelectors,
    HistogramResponse,
    HistogramSelectorLine,
    HistogramTemporalitySelectors,
    HistogramTypes
} from "../../interfaces/histogram/histogram";
import {KeyNumbers} from "../../interfaces/transaction/transaction";

export const TEMPORALITY_SELECTOR: HistogramTemporalitySelectors = {
    MONTH: {id: "MONTH", name: "homepage_graph_month"},
    WEEK: {id: "WEEK", name: "homepage_graph_week"},
    DAY: {id: "DAY", name: "homepage_graph_day"},
}

export const TRANSACTION_AMOUNT_SELECTOR: HistogramAmountSelectors = {
    firstSelector: {id: "gross_amount", name: "homepage_graph_leg_gross"},
    secondSelector: {id: "net_amount", name: "homepage_graph_leg_net"},
}

export const SETTLEMENT_AMOUNT_SELECTOR: HistogramAmountSelectors = {
    firstSelector: {id: "settled", name: "graph_hp_sett_filter_value_1"},
    secondSelector: {id: "fees", name: "graph_hp_sett_filter_value_2"},
}

export const TEMPORALITY_SELECTOR_OPTIONS: HistogramSelectorLine[] = [
    TEMPORALITY_SELECTOR.MONTH,
    TEMPORALITY_SELECTOR.WEEK,
    TEMPORALITY_SELECTOR.DAY,
]
export const INITIAL_HISTOGRAM_STATE: HistogramResponse = {
    firstAmount: [],
    secondAmount: [],
}

export const INITIAL_TRANS_DAILY_KEY_NUMBERS: KeyNumbers = {
    lastNumberTransaction: 0,
    lastGrossAmount: 0
}

export const INITIAL_SETTLEMENT_DAILY_KEY_NUMBERS: KeyNumbers = {
    lastGrossAmount: 0,
    lastFinanced: 0,
    lastFees: 0
}

export const HISTOGRAM_TYPES: HistogramTypes = {
    TRANSACTION: {
        id: "TRANSACTION",
        title: "graph_transactions",
        linedChartAxisLabel: "homepage_graph_leg_num",
        linedChartLabel: "graph_trans_hp_bot_leg_num",
        barChartAxisLabel: "homepage_graph_leg_am",
        barChartLabel: "graph_trans_hp_bot_leg_am",
    },
    SETTLEMENT: {
        id: "SETTLEMENT",
        title: "graph_settlements",
        linedChartAxisLabel: "graph_hp_sett_leg_num",
        linedChartLabel: "graph_hp_bot_sett_leg_num",
        barChartAxisLabel: "graph_hp_sett_leg_am",
        barChartLabel: "graph_hp_bot_sett_leg_am",
    },
}
