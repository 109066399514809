export enum NotificationChannel {
    EMAIL = "EMAIL",
    IN_APP = "IN_APP",
}

export enum NotificationSettingsType {
    NEW_MERCHANT = "NEW_MERCHANT",
    NEW_COMPANY = "NEW_COMPANY",
    NEW_ACCOUNT = "NEW_ACCOUNT",
    NEW_OPERATOR = "NEW_OPERATOR",
    NEW_MERCHANT_ADMIN = "NEW_MERCHANT_ADMIN",
    NEW_MARKETPAY_ADMIN = "NEW_MARKETPAY_ADMIN",
}

export interface NotificationToggle{
    readonly channel:NotificationChannel;
    readonly type: NotificationSettingsType;
}

export function toggleEq(
    a: NotificationToggle,
    b: NotificationToggle,
):boolean{
    return a.channel === b.channel && a.type === b.type;
}

export function newToggle(
    channel:NotificationChannel,
    notifType: NotificationSettingsType,
):NotificationToggle{
    return {channel, type:notifType};
}

