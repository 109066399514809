import {styled} from "@mui/material/styles";
import {MenuItem, MenuItemProps} from "@mui/material";
import {paletteTheme} from "../themes/palette";

export default styled(MenuItem)<MenuItemProps>(() => {
    return ({
        color: paletteTheme.colors.GRAY,
        border: "2px solid white",
        borderRadius: "0",
        padding: "0 7px",
        height: "auto",
        fontSize: "14px",
        background:"white",

        "&:hover": {
            color: paletteTheme.colors.BACKGROUND_COLOR_SIDE_MENU,
            backgroundColor: paletteTheme.colors.GRAY_DROPDOWN,
            border: `2px solid ${paletteTheme.colors.GRAY_DROPDOWN}`,
            borderRadius: "0",
            padding: "0 7px"
        },
        "&.Mui-selected": {
            border: `1px solid ${paletteTheme.colors.PRIMARY}`,
            backgroundColor: `${paletteTheme.colors.PRIMARY} !important`, // Overriding backgroundColor without important generate a color bug
            color: "white",
            "&:hover": {
                border: `1px solid ${paletteTheme.colors.GRAY_DROPDOWN}`,
                color: paletteTheme.colors.BACKGROUND_COLOR_SIDE_MENU,
                backgroundColor: `${paletteTheme.colors.GRAY_DROPDOWN} !important`,
            }
        }
    });
});
