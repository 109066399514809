import { styled } from "@mui/material/styles";
import {
  OutlinedTextField,
  OutlinedTextFieldProps,
} from "components/atoms/textfield/OutlinedTextField";
import { paletteTheme } from "styles/themes/palette";

export default styled(OutlinedTextField)<OutlinedTextFieldProps>(() => {
  return ({
    height: "40px",
    borderRadius: "2px",
    fontSize: 14,
    "& .MuiOutlinedInput-notchedOutline": {
      height: "43px",
    },
    " & .MuiInputBase-root": {
      height: "40px",
    },
    "& .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root": {
      borderRadius: "2px",
      fontSize: 14,

      "&.Mui-error input": {
        color: paletteTheme.colors.DANGER,
        borderColor: paletteTheme.colors.DANGER
      },
      borderColor: paletteTheme.colors.PRIMARY,
    },
    "& input": {
      height: "40px",
      padding: "0 14px",
      fontWeight: 300,
      "&::placeholder": {
        fontWeight: 300
      }
    },
  });
});
