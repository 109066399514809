import React, { FunctionComponent } from "react";
import { User } from "interfaces/user/user";
import { PerimeterOptions } from "constants/perimeter/perimeter";
import { FilterValues } from "interfaces/filter/filter";
import { rightUtils } from "utils/business/rightUtils";
import { arrayUtils } from "utils/common/arrayUtils";
import { filterUtils } from "utils/business/filterUtils";
import { useAutocompleteSearchFilter } from "hooks/filter/useAutocompleteSearchFilter";
import { useOnChangeFilter } from "hooks/filter/useOnChangeFilter";
import FlexBox, { FlexBoxProps } from "components/atoms/flex/FlexBox";
import AutocompleteCheckbox from "components/molecules/autocomplete/AutocompleteCheckbox";

export interface TransactionFiltersProps extends FlexBoxProps {
  onChangePerimeterOptions: (value: PerimeterOptions) => void;
  perimeterOptions: PerimeterOptions;
  initialOptions: PerimeterOptions;
  filters: FilterValues;
  onChangeFilters: (filters: FilterValues) => void;
  user: User;
  marginTop?: string;
  disabledMerchant?: boolean;
  uniqueCompany?: boolean;
  uniqueMerchant?: boolean;
}

const PerimeterFilters: FunctionComponent<TransactionFiltersProps> = ({
  onChangePerimeterOptions,
  perimeterOptions,
  initialOptions,
  filters,
  onChangeFilters,
  marginTop = "10px",
  user,
  disabledMerchant = false,
  uniqueCompany = false,
  uniqueMerchant = false,
}) => {
  const isAdminOrGroupPerimeter = rightUtils.isAdminOrGroupPerimeter(user);

  const {
    onChangeAccountsSelection,
    onChangeCompaniesSelection,
    onChangeMerchantsSelection,
  } = useOnChangeFilter(filters, onChangeFilters);

  const {
    onChangeAccountName,
    onChangeCompanyName,
    onChangeMerchantName,
    handleCompaniesSelection,
    handleAccountsSelection,
  } = useAutocompleteSearchFilter(perimeterOptions, onChangePerimeterOptions, filters);

  return (<>
    <FlexBox marginRight="10px" marginTop={marginTop}>
      <AutocompleteCheckbox
        label={({ message: "filter_account" })}
        values={filters.accounts}
        onChange={onChangeAccountsSelection}
        options={perimeterOptions.accounts}
        onChangeQuery={onChangeAccountName}
        disableChoices={filterUtils.hasUniqueElementNotAdmin(isAdminOrGroupPerimeter, initialOptions?.accounts)}
        customOnBlur={handleAccountsSelection}
        uniqueElement
      />
    </FlexBox>
    <FlexBox marginRight="10px" marginTop={marginTop}>
      <AutocompleteCheckbox
        label={({ message: "filter_company" })}
        values={filters.companies.selectedOptions}
        onChange={onChangeCompaniesSelection}
        options={perimeterOptions.companies}
        onChangeQuery={(query: string, page: number) => onChangeCompanyName(query, page, filters.accounts.map(option => option.id))}
        customOnBlur={handleCompaniesSelection}
        disabled={arrayUtils.isEmpty(filters.accounts)}
        disableChoices={filterUtils.hasUniqueElementNotAdmin(isAdminOrGroupPerimeter, initialOptions?.companies)}
        uniqueElement={uniqueCompany}
      />
    </FlexBox>
    {!disabledMerchant && <FlexBox marginRight="10px" marginTop={marginTop}>
      <AutocompleteCheckbox
        label={({ message: "filter_merchant" })}
        values={filters.merchants.selectedOptions}
        onChange={onChangeMerchantsSelection}
        options={perimeterOptions.merchants}
        onChangeQuery={onChangeMerchantName}
        disabled={arrayUtils.isEmpty(filters.accounts) || arrayUtils.isEmpty(filters.companies.selectedOptions)}
        disableChoices={filterUtils.hasUniqueElementNotAdmin(isAdminOrGroupPerimeter, initialOptions?.merchants)}
        uniqueElement={uniqueMerchant}
      />
    </FlexBox>}
  </>);
}

export default PerimeterFilters;
