import { MutableRefObject, useEffect, useRef, useState } from "react";
import { rightUtils } from "utils/business/rightUtils";
import { perimeterService } from "services/user/PerimeterService";
import { errorUtils } from "utils/api/errorUtils";
import { selectUtils } from "utils/business/selectUtils";
import { UserDetails } from "interfaces/user/user";
import { PerimeterFilterValues} from "interfaces/transaction/transaction";
import { PerimeterOptions } from "constants/perimeter/perimeter";
import { filterUtils } from "utils/business/filterUtils";
import { Options } from "constants/options/option";
import { useEffectAbortable } from "hooks/useEffectAbortable";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import { TableRef } from "components/atoms/table/Table";
import { FilterDetailResponse } from "interfaces/perimeter/company";
import { useShowContentToUser } from "hooks/filter/useShowContentToUser";
import { FilterDateValues, FilterValues, RedirectFilters } from "interfaces/filter/filter";
import { documentService } from "services/document/DocumentService";
import { fileUtils } from "utils/common/fileUtils";
import { DocumentRequest } from "interfaces/document/document";
import { toastUtils } from "utils/toast/toastUtils";
import { BlobWithFilename } from "../../services/HttpService";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

interface FilterInstance {
  initialPerimeterOptions: PerimeterOptions;
  perimeterOptions: PerimeterOptions;
  onChangePerimeterOptions: (value: PerimeterOptions) => void;
  initialFilters: FilterValues;
  filters: FilterValues;
  onChangeFilters: (filters: FilterValues) => void;
  showContent: boolean;
  onResetFilter: () => void;
  onSearchFilter: () => void;
  tableRef: MutableRefObject<TableRef>;
}

type LocalStorageItemName = LocalStorageEnum | LocalStorageEnum[];

/**
 * Perimeter filter hook to get perimeterOptions easily on your pages
 */
export function useFilter<T extends PerimeterFilterValues>(
  user: UserDetails,
  initialFilters: T,
  // name for local storage item where the filters should be saved
  localStorageItemName?: LocalStorageItemName,
  // saved === saved in local storage
  savedLocalStorageFilters?: FilterValues,
  documents?: boolean
): FilterInstance {
  const { locale } = useIntl();

  const defaultOptions = {
    merchants: [],
    accounts: [],
    companies: [],
  };
  const [perimeterOptions, setPerimeterOptions] = useState<PerimeterOptions>(defaultOptions);
  const [_initialFilters, setInitialFilters] = useState<FilterValues>({ ...initialFilters });
  const [filters, setFilters] = useState<FilterValues>(
    savedLocalStorageFilters ? { ...savedLocalStorageFilters } : { ...initialFilters }
  );
  const [initialPerimeterOptions, setInitialPerimeterOptions] = useState<PerimeterOptions>(defaultOptions);
  const [firstFetch, setFirstFetch] = useState<boolean>(true);
  const [currLocale, setcurrLocale] = useState(locale);
  const location = useLocation();
  const redirectFilters = location.state as RedirectFilters;
  const tableRef: MutableRefObject<TableRef> = useRef<TableRef>();

  // Show tables if you have only one account
  // Should not fecth any data on false
  const { showContent, onShow, onHideIfAdmin, onHide } = useShowContentToUser(user, documents);

  /**
   * Fetch data in the table
   * We want to avoid managing table data outside of the table components
   * Using a ref permits it!
   */
  const fetch = () => {
    if (tableRef?.current) {
      tableRef.current.fetch();
    }
  };

  /**
   * Manage authorized perimreters on mount
   *
   * Admin/Group users has multiple accounts.
   * Operator/Merchant Admin only has one account
   *
   * 3 request to get : Account / Company / Store available values
   * The first request has no filter. It permits later to identify when users have only one option of each
   */
  const manageUseEffect = async (ac: AbortController): Promise<FilterDetailResponse> => {
    if (!user.id) {
      return {
        accounts: [],
        companies: [],
        merchants: [],
      };
    }

    let accounts: Array<Options> = [];

    if (!rightUtils.isAdminOrGroupPerimeter(user)) {
      const authorizedAccount = await perimeterService.getAuthorizedAccounts({ signal: ac.signal });
      if (authorizedAccount.length === 1) {
        accounts = selectUtils.mapPerimeterToAccountOptions(authorizedAccount[0]);
      }
    } else {
      const resAccounts = await perimeterService.searchAccounts({ signal: ac.signal }, "");
      accounts = selectUtils.mapEntityToOptions(resAccounts.content);
    }

    const companies = await perimeterService.searchCompanies(
      { signal: ac.signal },
      "",
      savedLocalStorageFilters?.accounts.map((item) => item.id)
    );
    const merchants = await perimeterService.searchMerchants(
      { signal: ac.signal },
      "",
      savedLocalStorageFilters?.accounts.map((item) => item.id),
      savedLocalStorageFilters?.companies?.selectedOptions.map((item) => item.id)
    );

    return {
      accounts,
      companies: companies.content,
      merchants: merchants.content,
    };
  };

  const onResetFilter = () => {
    if (Array.isArray(localStorageItemName)) {
      localStorageItemName.forEach((item) => {
        localStorage.removeItem(item);
      });
    } else {
      localStorage.removeItem(localStorageItemName);
    }
    onHideIfAdmin();
  };

  const handleSetLocalStorage = () => {
    if (localStorageItemName) {
      const perimeterFilter: PerimeterFilterValues = {
        accounts: filters.accounts,
        merchants: filters.merchants,
        companies: filters.companies,
      };
      // no matter what - set global perimeter values
      localStorage.setItem(LocalStorageEnum.GLOBAL_PERIMETER_FILTERS, JSON.stringify(perimeterFilter));
      if (typeof localStorageItemName !== "string" && localStorageItemName.length > 1) {
        // ACC TRANSACTIONS
        if (localStorageItemName.find((item) => item === LocalStorageEnum.ACCEPTED_TRANSACTIONS_FILTERS)) {
          localStorage.setItem(LocalStorageEnum.ACCEPTED_TRANSACTIONS_FILTERS, JSON.stringify(filters.operationDate));
        }
        // ACQ TRANSACTIONS
        if (localStorageItemName.find((item) => item === LocalStorageEnum.ACQUIRED_TRANSACTIONS_FILTERS)) {
          const datesFilters: FilterDateValues = { settlementDate: filters.settlementDate };
          if (filters.operationDate.startDate) {
            datesFilters.operationDate = filters.operationDate;
          }
          localStorage.setItem(LocalStorageEnum.ACQUIRED_TRANSACTIONS_FILTERS, JSON.stringify(datesFilters));
        }
        // SETTLEMENTS
        if (localStorageItemName.find((item) => item === LocalStorageEnum.SETTLEMENTS_FILTERS)) {
          localStorage.setItem(
            LocalStorageEnum.SETTLEMENTS_FILTERS,
            JSON.stringify({ operationDate: filters.operationDate })
          );
        }
        // PORTFOLIO --BILLING
        if (localStorageItemName.find((item) => item === LocalStorageEnum.PORTFOLIO_BILLING_FILTERS)) {
          localStorage.setItem(
            LocalStorageEnum.PORTFOLIO_BILLING_FILTERS,
            JSON.stringify({ settlementYears: filters.settlementYears, settlementMonths: filters.settlementMonths })
          );
        }
      }
    }
  };

  const downloadDocument = async () => {
    const year = filters.settlementYears.at(0).id;
    const month = filters.settlementMonths.at(0).id;
    const documentRequest: DocumentRequest = {
      accountId: filters.accounts.at(0).id,
      companyId: filters.companies.selectedOptions.at(0).id,
      merchantId: filters.merchants.selectedOptions.at(0).id,
      settlementMonth: `${year}-${month}`,
    };

    try {
      const blobWithName: BlobWithFilename = await documentService.downloadDocument(documentRequest);
      void (await fileUtils.downloadWithFilename(blobWithName, "export_download.pdf"));
      onHide();
    } catch (e) {
      toastUtils.errorToast("Failed to get document");
    }
  };

  const onSearchFilter = () => {
    handleSetLocalStorage();
    onShow();
    if (!documents) {
      fetch();
    } else {
      void downloadDocument();
    }
  };

  /**
   * Get all companies to display in your filter for admin
   * Get your authorized accounts/companies/merchants to display in your filter
   *
   * If you have only one option of each, it will be automatically picked and the dropdown will be disabled
   */
  useEffectAbortable(
    (ac) => {
      manageUseEffect(ac)
        .then((res) => {
          const companies = selectUtils.mapPerimeterToCompaniesOptions(res.companies, documents);
          const merchants = selectUtils.mapPerimeterToMerchantsOptions(res.merchants, documents);

          const _initialOptions = {
            ...perimeterOptions,
            accounts: res.accounts,
            companies: companies,
            merchants: merchants,
          };
          setPerimeterOptions(_initialOptions);
          setInitialPerimeterOptions(_initialOptions);
          // logic to handle refreshing of perimeter filter list values when language has been changed
          if (currLocale !== locale) {
            if (filters.companies.all) {
              setFilters((prev) => {
                return { ...prev, companies: { ..._initialOptions.companies, selectedOptions: [_initialOptions.companies.at(0)], all: true } };
              })
            }
            if (filters.merchants.all) {
              setFilters((prev) => {
                return { ...prev, merchants: { ..._initialOptions.merchants, selectedOptions: [_initialOptions.merchants.at(0)], all: true } };
              })
            }
            setcurrLocale(locale);
            return;
          }

          let newFilters: FilterValues;

          // If you have saved filters and you have 1 accounts
          let tempRedirectFilter = {}
          if (redirectFilters) {
            if (redirectFilters.company?.id && redirectFilters.store?.id && redirectFilters.account?.id) {
              tempRedirectFilter = { accounts: [redirectFilters.account], companies: { selectedOptions: [redirectFilters.company], all: false }, merchants: { selectedOptions: [redirectFilters.store], all: false } }
            }
          }
          if (!documents && savedLocalStorageFilters?.accounts?.length === 1) {
            onShow();
            setFirstFetch(false);
            newFilters = {
              ..._initialFilters,
              ...filterUtils.getInitialFiltersByPerimeterOptionsAndPerimeter<FilterValues>(
                { ...savedLocalStorageFilters, ...tempRedirectFilter },
                initialPerimeterOptions,
                false,
              ),
            };
          } else {
            newFilters = {
              ..._initialFilters,
              ...filterUtils.getInitialFiltersByPerimeterOptionsAndPerimeter<FilterValues>(
                { ..._initialFilters, ...tempRedirectFilter },
                _initialOptions,
                documents,
              ),
            };
            setFirstFetch(newFilters.accounts.length !== 1);
          }
          setInitialFilters(newFilters);
          setFilters(newFilters);
          handleSetLocalStorage();
        })
        .catch((e) => {
          if (errorUtils.isApiError(e)) {
            errorUtils.handleBackErrors(e);
          }
        });
    },
    [user.id, locale]
  );

  // On first fetch required, wait until the component has updated filters based on saved filters
  // Watcher on inital filters fetch.
  // This behavior may be deprecated now
  useEffect(() => {
    if (!firstFetch) {
      setFirstFetch(true);
      fetch();
    }
  }, [filters]);

  return {
    perimeterOptions,
    onChangePerimeterOptions: setPerimeterOptions,
    initialFilters: _initialFilters,
    initialPerimeterOptions: initialPerimeterOptions,
    filters,
    onChangeFilters: setFilters,
    onResetFilter,
    onSearchFilter,
    showContent,
    tableRef,
  };
}
