import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Tooltip} from "@mui/material";

interface IconToolTipProps {
    title: string,
}

const IconTooltip: FunctionComponent<IconToolTipProps> = ({
                                                             title,
                                                             children
                                                         }) =>
    (<Tooltip placement="right-start"
              PopperProps={{
                  sx: {
                      top: "-7px !important",
                      "& .MuiTooltip-tooltip": {
                          marginLeft: "1px !important"
                      }
                  }
              }}
              title={<FormattedMessage id={title}/>}>
        <>{children}</>
    </Tooltip>)


export default IconTooltip;
