import React, { FunctionComponent, useMemo } from "react";
import { CardRow } from "components/atoms/card/CardRow";
import { BoxProps, Link as LinkMaterialUI, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { UserDetails } from "interfaces/user/user";
import { paletteTheme } from "styles/themes/palette";
import UserEdit from "components/atoms/icons/UserEdit";
import { MaterialUiColor } from "types/materialui/materialui";
import Card from "components/atoms/card/Card";
import { IntlMessage } from "constants/intl/intl";
import AdvancedFormattedMessage from "components/atoms/intl/AdvancedFormattedMessage";
import { DeleteForeverOutlined, EmailOutlined } from "@mui/icons-material";
import { rightUtils } from "utils/business/rightUtils";
import { PERMISSIONS } from "constants/permissions/permissions";
import ButtonOneShot from "components/atoms/button/ButtonOneShot";
import { useAuthUser } from "hooks/useAuthUser";
import { useInitUser } from "hooks/account/useInitUser";
import { Switch } from "components/atoms/switch/Switch";
import { createUserUtils } from "utils/business/createUserUtils";
import { isSwitchVisible } from "services/user/DistributorToggleService";
import { ProfileDisplay } from "components/molecules/useraccount/display/ProfileDisplay";
import styles from "components/molecules/useraccount/details/UserAccountInformationDetails/userAccountInformationDetails.module.scss";
import { BackLink } from "components/molecules/BackLink";
import { USER_PATH } from "constants/routes/RoutePaths";

interface AccountInformationsProps extends BoxProps {
  user: UserDetails;
  idUser: string;
  onEdit: () => void;
  onDelete: () => void;
  cardTitle: string | IntlMessage;
  self: boolean;
  permissions: string[];
}

export const UserAccountInformationDetails: FunctionComponent<AccountInformationsProps> = ({
  user,
  idUser,
  onEdit,
  onDelete,
  cardTitle,
  self,
  permissions,
}) => {
  const { user: authUser } = useAuthUser();

  const { isClicked, isLoading, onInitUser } = useInitUser({ email: user?.email, initialized: user?.initialized });

  const isAuthUserAdmin = useMemo(() => rightUtils.isAdmin(authUser), [authUser]);
  const isUserAdmin = useMemo(() => rightUtils.isAdmin(user), [user]);
  const firstName = "first_name";
  const lastName = "last_name";

  const visible = isSwitchVisible(authUser, user);

  const displayBacklink = createUserUtils.isNewUser(user) || (idUser && !createUserUtils.isSelfFromParam(idUser));

  return (
    <>
      <Card
        cardTitle={
          <div className={styles["heading"]}>
            <div className={styles["title-wrap"]}>
              <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
                <AdvancedFormattedMessage title={cardTitle} />
              </Typography>
              <div className={styles["heading-buttons"]}>
                <div className={styles["edit-btn"]}>
                  <LinkMaterialUI component="button" onClick={onEdit}>
                    <div className="flex flex-center">
                      <UserEdit color={MaterialUiColor.SECONDARY} />
                      <Typography display="inline" color={MaterialUiColor.SECONDARY}>
                        <FormattedMessage id="edit_button" />
                      </Typography>
                    </div>
                  </LinkMaterialUI>
                </div>
                {!self && rightUtils.hasPermission(permissions, PERMISSIONS.USER_DELETE) && (
                  <div className={styles["delete-btn"]}>
                    <LinkMaterialUI component="button" onClick={onDelete}>
                      <div className="flex flex-center">
                        <DeleteForeverOutlined color={MaterialUiColor.SECONDARY} />
                        <Typography display="inline" color={MaterialUiColor.SECONDARY}>
                          <FormattedMessage id="delete" />
                        </Typography>
                      </div>
                    </LinkMaterialUI>
                  </div>
                )}
              </div>
            </div>

            <div className={styles["back-btn-wrap"]}>
              {displayBacklink && <BackLink to={`/${USER_PATH}`} label="back_user_list" showCloseIconOnXSSM={true} />}
            </div>
          </div>
        }
        header="pers_info">
        <div className={styles["wrap-content"]}>
          <div className={styles["wrap-info"]}>
            <CardRow id={firstName} key={firstName} label={firstName}>
              <Typography textTransform="capitalize">{user?.firstName.toLowerCase()}</Typography>
            </CardRow>

            <CardRow id={lastName} key={lastName} label={lastName}>
              <Typography textTransform="uppercase">{user?.lastName}</Typography>
            </CardRow>

            <CardRow id="account_email" key="email" label="account_email">
              <Typography>{user?.email}</Typography>
            </CardRow>
          </div>

          {user && isAuthUserAdmin && (
            <div className={`flex ${styles["welcome-email"]}`}>
              <ButtonOneShot
                loading={isLoading}
                startIcon={<EmailOutlined fontSize="small" sx={{ ":nth-of-type(1)": { fontSize: 14 } }} />}
                labelClicked="welcome_email_sent"
                label="trigger_welcome_email"
                onClick={onInitUser}
                isClicked={isClicked}
              />
            </div>
          )}
        </div>
      </Card>
      <Card header="account_account">
        <ProfileDisplay user={user} marginBottom={isUserAdmin ? 3 : 1}></ProfileDisplay>
        {visible && (
          <CardRow 
          id="distributor" 
          label="distributor" 
          fontWeight={400} 
          customClassName="sub-item"
          customLabelClassName="sub-label"
          customContentClassName="switch"
          >
            <Switch checked={user.distributor} disabled/>
          </CardRow>
        )}
      </Card>
    </>
  );
};
