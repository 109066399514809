/**
 * File listing every translated label in Italian
 */

export const ItalianMessages = {
  // LOGIN PAGE
  welcome: "Benvenuto sul",
  merchant_portal: "PORTALE MERCHANT!",
  email: "Indirizzo email",
  password: "Password",
  email_example: "email@email.com",
  login_button: "Log in",
  forgot_password_cta: "Password dimenticata?",
  logo_alt: "MarketPay logo",

  // LOGIN ERROR MESSAGES
  email_wrong_format: "L'indirizzo email deve essere indicato nel formato corretto",
  email_required: "L'indirizzo email é obbligatorio",
  password_required: "La password é obbligatoria",
  user_unknown: "Il vostro indirizzo email è sconosciuto",
  invalid_password: "Password/email non corretti",
  password_pending: "Il suo accont non è completo. Cliccare sul link ricevuto via mail per impostare la password",
  blocked_user: "Il vostro account é stato bloccato. Per favore, contattare il nostro supporto per ulteriori informazioni",

  // RESET PASSWORD VIEW
  pw_recovery_title: "Recuperare password",
  reset_password_text: "Inserite la vostra email e vi verrà inviato un link per ripristinare la vostra password",
  submit_button: "Mandare",

  // RESET PASSWORD CONFIRM VIEW
  pw_confirm_title: "La vostra richiesta di creazione di nuova password é stata effettuata",
  pw_recovery_confirmation_body: "Ti abbiamo appena inviato un link per reimpostare la password all'indirizzo email che hai fornito.",
  pw_recovery_confirmation_not_sent: "Se non hai ricevuto l'e-mail di reimpostazione della password, controlla i filtri antispam e posta indesiderata del tuo account e-mail.",
  pw_confirm_your_email: "Tuo email : {email}",
  pw_resend_init_password_mail: "La password non é ancora stata impostata. Invieremo l'email per la creazione della password all'indirizzo email fornito",

  // CHANGE PASSWORD
  change_password_sent_email: "Un link per resettare la password é stato inviato all'indirizzo email",
  change_password_title: "Password",
  change_password_reset_password: "Modificare la password",
  change_password_send_email: "Cliccare sul pulsante qui sotto per ricevere una mail per inizializzare la password al vostro indirizzo email",
  change_password_button: "Modificare la password",

  // UPDATE PASSWORD VIEW

  // INIT PASSWORD
  init_pw_page_title: "Settare la password",
  init_pw_page_field2: "Confermare la password",
  init_pw_page2_text: "La tua password è stata impostata correttamente!",

  // UPDATE PASSWORD
  set_password: "Settare la nuova password",
  new_password_field: "Nuova password",
  confirm_new_password_field: "Confermare la nuova password",
  password_requirements_label: "Per proteggere l'account, assicurati che la password:", // password_instructions
  password_requirements_include_label: "Contenga almeno:", // password_instructions
  password_min_length: "8 caratteri", // password_instructions
  reset_password_button: "Resettare la password",
  unmatched_passwords: "La conferma della password deve corrispondere alla password",
  password_lowercase: "1 minuscola", // password_instructions
  password_uppercase: "1 maiuscola", // password_instructions
  password_number: "1 numero", // password_instructions
  password_special_character: "un carattere speciale (~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/)", // password_instructions
  confirm_password_required: "È necessario confermare la password",
  reset_password_confirmation: "La sua password è stata modificata correttamente",
  back_login: "Ritorno alla pagina di log in",
  send_feedback: "Invia un feedback",

  // SIDE MENU
  menu_home: "Dashboard",
  menu_transactions: "Transazioni",
  menu_accepted: "Accettato",
  menu_acquired: "Acquisito",
  menu_settlements: "Pagamenti",
  menu_portfolio: "Portfolio",
  menu_users: "Utenti",
  menu_my_account: "Il mio conto",
  menu_my_reports: "I miei rapporti",
  menu_password: "La mia password",
  log_out: "Log out",
  menu_notification_settings: "Le mie notifiche",
  menu_language: "Lingua",

  // PROFILES
  merchant_admin: "Admin Merchant",
  market_pay_admin: "Admin Market Pay",
  operator: "Operatore",
  profil_spring: "SPRING",

  // ACCOUNT PAGE

  // PERSONAL INFORMATION
  account_my_account: "Il mio conto",
  account_user: "Utente {id} - {firstName} {lastName}",
  create_user_title: "Nuovo utente",
  pers_info: "Informazioni personali",
  first_name: "Nome",
  last_name: "Cognome",
  creation_date: "Data di creazione",
  last_activity: "Ultima attività",
  account_email: "Email",
  firstname_required: "Il nome è obbligatorio",
  firstname_wrong_format: "Il tuo nome può includere solo lettere, spazi e trattini", // name_rule
  lastname_required: "Il cognome è obbligatorio",
  lastname_wrong_format: "Il tuo cognome può includere solo lettere, spazi e trattini", // name_rule
  email_alr_used: "Questo indirizzo email è già legato a un account esistente",
  distributor: "Distributor",
  distributor_toggle: "Distributor view",

  // TYPE
  account_account: "Account",
  account_type: "Tipo",

  // PERIMETER
  level_col: "Livello",
  peri: "Perimetro",
  permissions: "Diritti",
  title_paywish: "Conto PayWish",
  peri_account: "Organizzazione",
  perimeter_group: "Gruppo",
  peri_company: "Società",
  peri_merchant: "Negozio",
  edit_button: "Modificare",
  delete: "Cancellare",
  account_all_perimeter: "Tutti gli account",
  account_no_perimeter: "Nessun",
  save_changes: "Salvare le modifiche",
  discard_changes: "Non salvare",
  account_updated: "Le modifiche sono state applicate",
  account_tooltip_remove: "Eliminare questa società dal perimetro",
  account_tooltip_reset: "Reimpostare le scente iniziali",
  add_company: "Aggiungere una società",
  no_results: "Nessun risultato",
  add_all: "Aggiungi tutti",
  required_account: "Questo campo è obbligatorio",
  required_company: "Questo campo è obbligatorio",
  required_merchants: "Questo campo è obbligatorio",
  inherit_future_merchants: "Eredita negozi futuri",
  inherit_future_companies: "Eredita società future",
  account_add_all_companies: "Aggiungere tutte le società",
  paywish_user_subtitle: "Utente PayWish",
  paywish_usertype: "Tipo",
  paywish_admin: "PayWish Admin",
  paywish_user: "PayWish User",
  paywish_select_usertype: "Selezionare il tipo",
  paywish_select_merchant: "Selezionare esercenti",
  paywish_pw_merchant: "PayWish esercenti",
  paywish_user_success_msg: "Un utente PayWish è stato creato con successo",
  paywish_user_error_msg: "Si è verificato un errore tecnico, non è stato possibile creare un utente in questo momento. Si prega di riprovare più tardi.",
  paywish_user_error_duplicate: "Esiste già un utente PayWish con questo indirizzo e-mail.",

  // TRANSACTIONS
  permission_transactions: "Transazioni",
  permission_approve_ref: "Approva una richiesta di rimborso",
  permission_view_acc_acq_tx: "Visualizzare le transazioni accettate e acquisite",
  permission_trig_two_steps_ref: "Attiva un rimborso in due passaggi",
  permission_trig_direct_ref: "Attiva un rimborso diretto",

  // SETTLEMENTS
  permission_category_settlements: "Pagamenti",
  permission_view_settlements: "Consulta la lista dei pagamenti",
  settlements_view_acq_transactions: "Visualizza le transazioni acquisite",

  // USERS
  permission_users_man: "Utenti",
  permission_add_user: "Aggiungere un nuovo utente a Merchant Portal",
  permission_del_user: "Cancellare un utente",
  permission_user_update: "Modificare un utente",
  permission_export_users: "Esporta elenco utenti",

  // PORTFOLIO
  portfolio: "Portafoglio",
  permission_category_portfolio: "Portafoglio",
  permission_portfolio: "Accedere alla vista Portafoglio",
  forbiddenLabel: "You do not have the required permissions to access this page",
  my_portfolio: "Il Mio Portafoglio",
  title_volume_trends: "Tendenze dei volumi",
  portf_title_billing: "Fatturazione",
  portf_title_top_bottom: "In alto/in basso",
  portf_col_day: "Giorno %",
  portf_col_week: "Settimana %",
  portf_col_month: "Mese %",
  portf_merchants: "Commercianti",
  portf_companies: "Aziendas",
  portf_dist_com: "Commissione per i distributori",
  portf_tx_err: "Errori di transazione",
  switch_merchant: "Commerciante",
  switch_company: "Azienda",
  portf_sort: "Ordina per",
  portf_col_nbr_tx: "Numero di transazioni",
  portf_col_gross_sum: "Importo lordo",
  portf_col_success_rate: "Tasso di successo delle transazioni",
  portf_col_dist_com: "Commissione per i distributori",
  portf_col_avg_com_rate: "Tasso medio di commissione",
  portf_col_mp_com: "Commissionne Market Pay ",
  portf_col_total_fees: "Commissione totale",
  portf_col_total_commission: "Commissione totale",
  rank: "#",
  portf_filter_display_trend_by: "Visualizzare i valori % in base a:",
  portf_tooltip_nbr_tx: "Il numero di transazioni visualizzate si basa sull'ordinamento delle colonne.",
  portf_tooltip_gross: "L'importo lordo viene visualizzato in base all'ordinamento delle colonne",
  portf_col_day_tooltip: "Ordinare su questa colonna per visualizzare i dati delle transazioni di ieri.",
  portf_col_week_tooltip: "Ordinare su questa colonna per visualizzare i dati delle transazioni degli ultimi 7 giorni.",
  portf_col_month_tooltip: "Ordinare su questa colonna per visualizzare i dati delle transazioni dell'ultimo mese.",
  portf_tooltip_tx_success_rate: "Il tasso di successo delle transazioni rappresenta la percentuale di transazioni accettate con successo rispetto al numero totale di transazioni nel risultato della ricerca.",
  portf_tooltip_distributor_commission: "La commissione del distributore rappresenta la parte della commissione di Market Pay che viene raccolta per conto del distributore.",
  portf_tooltip_mp_commission: "La commissione di Market Pay rappresenta sia l'importo della commissione raccolta da Market Pay, sia l'importo della commissione raccolta per conto del distributore.",
  portf_tooltip_total_commission: "La commissione totale rappresenta l'importo totale delle commissioni di Market Pay, delle commissioni di schema e delle commissioni di interscambio.",
  portf_tooltip_avg_commission_rate: "Tasso medio di commissione rappresenta il tasso medio di commissione di Market Pay su tutte le transazioni nel risultato.",
  tooltip_daily_evolution: "Evoluzione quotidiana",
  tooltip_weekly_evolution: "Evoluzione settimanale",
  tooltip_monthly_evolution: "Evoluzione mensile",
  tooltip_nb_transaction: "Numero di transazioni",
  tooltip_gross_sum: "Importo lordo",
  // REPORTS PAGE
  my_reports_title: "I miei rapporti",
  reports_table_report_name_col: "Nome",
  reports_table_latest_edition_col: "Ultima edizione",
  reports_table_frequency_col: "Frequenza",
  reports_table_frequency_col_daily: "Giornaliero",
  reports_table_frequency_col_weekly: "Settimanale",
  reports_table_frequency_col_monthly: "Mensile",
  reports_table_latest_report_col: "Ultimi rapporti",
  reports_table_download_button: "Scaricare",
  reports_table_delete: "Cancellare",
  reports_table_delete_message: "È sicuro di voler eliminare {name}?",
  reports_table_delete_confirm: "Cancellare",
  reports_table_delete_cancel: "Anullare",

  // DOCUMENTS PAGE
  doc_menu: "Documenti",
  doc_title: "Documenti",
  doc_header: "Scarica l'estratto conto delle spese di pagamento mensili",
  doc_settlement_year: "Anno",
  doc_settlement_month: "Mese",
  doc_rafec_download: "Scaricare",

  // USERS PAGE
  users_acc_col_all: "Tutti",
  users_acc_col: "Organizzazione",
  users_search: "Cerca",
  users_search_bar: "Ricerca per nome, cognome o indirizzo email",
  add_user_button: "Aggiumgere",
  user_not_found: "User non trovato",
  acc_creation_conf_message: "Il nuovo account è stato creato correttamente. Un'e-mail di configurazione della password è stata inviata a {email}",
  back_user_list: "Ritorno alla lista",
  create_user_button: "Creare un utente",
  trigger_welcome_email: "Invia email di benvenuto",
  welcome_email_header: "Email",
  welcome_email_sent: "E-mail di benvenuto inviata",
  welc_email_butt_error: "Non siamo stati in grado di inviare l'e-mail. Per favore riprova più tardi.",

  // LANGUAGE
  language_en: "EN",
  language_fr: "FR",
  language_it: "IT",
  language_es: "SP",
  language_nl: "NL",
  language_pt: "PT",

  // LOADER
  loader_search_results: "Ricerca risultati...",

  // TABLE
  number_element: "{displayedCount} di {totalCount}",
  show_more: "Mostra altri {pageSize} risultati",
  no_data_found: "Oops! La vostra ricerca ha dato 0 risultato",
  fetch_error: "Oops! Something wrong happened.",
  table_label:
    "La vostra ricerca ha dato {count, plural, =0 {0 risultati} one {1 risultato} other {{count} risultati}}",
  query_results: "La vostra ricerca ha dato",
  query_results_return: " {count, plural, one {risultato} other {risultati}}:",
  query_results_no_results: "Nessun risultato",
  export_csv_button: "Esportare in CSV",
  export_button: "Esportare",
  sr_schedule_button: "Programmare un rapporto",
  sr_pop_up_title: "Programmare un rapporto",
  sr_pop_up_report_name_label: "Nome del rapporto",
  sr_pop_up_report_name_placeholder: "Esportazione settimanale delle transazioni non riuscite",
  sr_pop_up_frequency_label: "Frequenza",
  sr_pop_up_frequency_placeholder: "Selezionare",
  sr_pop_up_close_button: "Chiudere",
  sr_pop_up_save_button: "Salvare",
  sr_frequency_monthly: "Mensile",
  sr_frequency_weekly: "Settimanale",
  sr_frequency_daily: "Giornaliero",
  sr_frequency_monthly_disclaimer:
    "Il primo giorno di ogni mese viene creato un nuovo report che include i dati del mese scorso.",
  sr_frequency_weekly_disclaimer: "Ogni lunedì mattina verrà creato un nuovo report con i dati dell'ultima settimana.",
  sr_frequency_daily_disclaimer: "Ogni mattina viene creato un nuovo report con i dati dell'ultimo giorno.",
  sr_conf_1_message: "Si sta per programmare un {frequency} rapporto denominato {reportName}.",
  sr_conf_1_message_confirm: "Conferma?",
  sr_conf_1_cancel_button: "Anullare",
  sr_conf_1_confirm_button: "Confermare",
  sr_conf_2_message_1: "Il vostro rapporto è stato programmato.",
  sr_conf_2_message_2: "Visitate I {myReportsLink} per trovare e gestire tutti i vostri rapporti programmati.",
  sr_conf_2_message_link: "miei rapporti",
  sr_conf_2_button: "OK",
  sr_error_empty_name: "Assegnare un nome al rapporto.",
  sr_error_existing_name: "Questo nome è già in uso. Sceglietene un altro.",
  sr_error_empty_frequency: "Scegliere la frequenza del rapporto.",
  sr_error_name_too_short: "Il nome deve essere lungo almeno 3 caratteri.",
  sr_saving_error:
    "Il vostro rapporto non può essere salvato in questo momento a causa di un errore tecnico. Si prega di riprovare più tardi.",
  export_limit_message_no_number:
    "Il rapporto che si sta cercando di scaricare è troppo grande. Eseguire una ricerca più ristretta e riprovare.",
  toggle_sorting: "Fare clic per ordinare",
  no_reports_scheduled: "Non è stato programmato alcun rapporto.",

  // ACTIVITY
  activity_years_ago: "{interval} anni fa",
  activity_month_ago: "{interval, plural, one {1 mese} other {{interval} mesi}} fa",
  activity_day_ago: "{interval, plural, one {1 giorno} other {{interval} giorni}} fa",
  activity_today: "Oggi",
  activity_yesterday: "Ieri",
  activity_never: " ",

  // PERMISSIONS FORBIDDEN
  not_allowed_to_create_user: "Non avete l'abilitazione per creare user",
  not_allowed_to_delete_user: "Non avete l'abilitazione per cancellare user",
  not_allowed_to_get_user: "Non avete l'abilitazione per consultare user",

  // DISCARD POPUP
  toast_discard_change: "Non salvare",
  unsaved_ch_err_mess: "Le tue modifiche potrebbero andare perse.",
  unsaved_ch_err_mess_save: "Vuoi salvarle?",
  save: "Salvare",
  cancel: "Anullare",
  ok: "Ok",
  discard: "Ignorare",

  // DELETE POPUP
  delete_account_title: "Cancellare l'account: {email}",
  delete_account_dialog_box: "Siete sicuri di voler cancellare questo account",
  delete_account_success: "Lo user é stato rimosso",

  // MONTHS
  january: "Gennaio",
  february: "Febbraio",
  march: "Marzo",
  april: "Aprile",
  may: "Maggio",
  june: "Giugno",
  july: "luglio",
  august: "Agosto",
  september: "Settembre",
  october: "Ottobre",
  november: "Novembre",
  december: "Dicembre",

  // AMOUNT RANGE FILTER
  min_sup_max: "L'importo minimo deve essere uguale o inferiore all'importo massimo",
  negative_value: "I valori devono essere uguali o maggiori di 0",
  card_transactions: "Transazioni",
  card_trans_gross_amount: "Importo totale lordo",
  card_trans_net_amount: "Importo totale netto",
  card_trans_refunds_amount: "Rimborsi",
  card_trans_fees_amount: "Commissione",

  //TRANSACTION PAGE
  transactions_title: "Transazioni",
  acc_transactions_title: "Transazioni accettate",
  filter_country: "Paese",
  filter_account: "Organizzazione",
  filter_company: "Società",
  filter_merchant: "Negozio",
  filter_operation_date: "Data pagamento",
  search: "Cerca",
  filter_by: "Filtra per",
  admin_trans_view_title: "Applicare almeno un filtro per visualizzare le transazioni",
  admin_trans_view_text:
    "Filtra per Nazione, account, società, punto vendita o data del pagamento per affinare la ricerca",
  export_limit_message: "Non puoi esportare più di {max} righe in un file. Riduci la ricerca e riprova.",
  filter_search_button_toaster: "Seleziona un'organizzazione prima di avviare la ricerca",
  col_man_err_message:
    "Non è possibile visualizzare meno di 5 colonne nella tabella. Seleziona un altro campo prima di deselezionare questo.",

  // ADDITIONAL FILTERS
  more_filters: "Aggiungi filtri",
  less_filters: "Elimina filtri",
  order_number: "Numero ordine",
  payment_solution: "Soluzione di pagamento",
  payment_type: "Tipo carta",
  checkout_type: "Tipo di pagamento",
  settlement_status: "Finanziamento",
  settlement_date: "Data finanziamento",
  total_fees: "Commissione",
  trans_authorization: "Autorizzazione",
  trans_cancellation: "Cancella",
  trans_capture: "Capture",
  trans_settled: "Pagato",
  trans_refund: "Rimborso",
  trans_refund_request: "Richiesta rimborso",
  event_success: "Successo",
  event_declined: "Rifiuto",
  event_error: "Errore",
  solution_pay_online: "PayOnline",
  solution_pay_wish: "PayWish",
  solution_pay_onsite: "PayOnsite",
  type_debit: "Debito",
  type_credit: "Credito",
  checkout_contactless: "Contactless",
  checkout_online: "Online",
  checkout_insertion: "Inserimento",
  checkout_magstripe: "Banda magnetica",
  financing_paid: "Pagato",
  financing_pending: "In attesa",
  batch_id: "Batch ID",
  scheme: "Scheme",
  filter_remittance: "Rimessa",
  filter_service_type: "Tipo di servizio",
  filter_collection_type: "Tipo di raccolta",

  // POPUP COLUMNS
  col_man_button: "Colonne",
  col_man_title: "Colonne visualizzate",
  col_man_apply: "Applicare",
  col_man_discard: "Abbandonare",
  col_man_titles_acceptation: "Accettazione",
  col_man_titles_last_event: "Ultimo evento",
  col_man_titles_transaction: "Transazione",
  col_man_titles_settlement: "Finanziamento",
  col_man_titles_customer: "Cliente",
  col_man_titles_merchant: "Mercante",
  col_man_checkout_type: "Tipo di pagamento",
  col_man_payment_method: "Metodo di pagamento",
  col_man_payment_solution: "Soluzione di pagamento",
  col_man_payment_type: "Tipo di pagamento",
  col_man_authorisation_code: "Codice autorizzazione",
  col_man_pos_id: "ID POS",
  col_man_pos_name: "Nome POS",
  col_man_bank_acc_dom_country: "Domiciliazione",
  col_man_card_iss_bank: "Banca emettitrice",
  col_man_card_iss_coun: "Paese",
  col_man_card_level: "Livello carta",
  col_man_card_type: "Tipo di carta",
  col_man_pan_first_six: "BIN",
  col_man_pan_last_four: "Ultime quattro cifre",
  col_man_scheme: "Circuito",
  col_man_op_date: "Data dell'operazione",
  col_man_op_time: "Ora dell'operazione",
  col_man_currency: "Moneta",
  col_man_gross_amount: "Importo lordo",
  col_man_net_amount: "Importo netto",
  col_man_merch_trans_id: "ID Transazione esercente",
  col_man_order_num: "Numero d'ordine",
  col_man_trans_type: "Tipo transazione",
  col_man_trans_event: "Evento",
  col_man_status: "Stato",
  col_man_trans_event_id: "ID evento",
  col_man_root_trans_event_id: "ID transazione",
  col_man_event_timestamp: "Data e ora dell'evento",
  col_man_acquirer: "Acquirer",
  col_man_settlement_batch: "Finanziamento",
  col_man_settlement_date: "Data finanziamento",
  col_man_settlement_status: "Stato finanziamento",
  col_man_interchange: "Interchange",
  col_man_intermediate_fees: "Commissioni di intermediazione",
  col_man_mkp_fees: "Commissioni Market Pay",
  col_man_scheme_fees: "Commissioni Circuito",
  col_man_total_fees: "Commissioni totali",
  col_man_account_name: "Organizzazione",
  col_man_company_name: "Società",
  col_man_merchant_name: "Negozio",
  col_man_country: "Paese",
  col_man_contract_num: "Numero contratto",
  col_man_reset_button: "Torna alle impostazioni predefinite",
  col_man_titles_fee_calc: "Calcolo della commissione",
  col_man_titles_fee_amount: "Importo della commissione",
  col_settlement_month: "Mese di finanziamento",
  col_imposta_di_bollo: "Imposta di bollo",
  category_distribution: "Distribuzione",
  col_retrocession: "Retrocessione",
  distrib_share_margin_rate: "Quota margine distributore (tasso)",
  mp_share_margin_amt: "Quota margine Market Pay",
  mp_share_margin_rate: "Quota margine Market Pay (tasso)",
  distrib_share_margin_amt: "Quota margine distributore",
  col_operation_time_utc: "Ora dell'operazione (UTC)",
  col_operation_time_local: "Ora dell'operazione (Locale)",

  //COUNTRIES
  country_filter_fr: "Francia",
  country_filter_sp: "Spagna",
  country_filter_nl: "Paesi Bassi",
  country_filter_pt: "Portogallo",
  country_filter_it: "Italia",
  country_filter_bel: "Belgio",
  country_filter_ger: "Germania",

  // LOADING
  loading: "Caricamento...",

  // SETTLEMENTS
  card_settlements_gross_am: "Importo lordo",
  card_settlements_comm: "Commissione",
  card_settlements_fin_am: "Importo finanziato",
  settled_by_mp: "Pagato da Market Pay",
  filter_settlement_date: "Data finanziamento",

  // HOMEPAGE
  good_morning: "Buongiorno,",
  good_evening: "Buonasera",
  homepage_today: "Oggi",

  // GRAPH

  // COMMON
  homepage_graph_month: "Mese",
  homepage_graph_week: "Settimana",
  homepage_graph_day: "Giorno",
  week: "Settimana",
  graph_tooltip_number: "Numero",
  graph_tooltip_amount: "Importo",
  graph_tooltip_week_leg: "S",
  graph_dropdown_interval: "Intervallo di tempo",
  graph_dropdown_amount: "Tipo di importo",

  // TRANSACTIONS
  homepage_graph_leg_gross: "Importo lordo",
  homepage_graph_leg_net: "Importo netto",
  homepage_graph_leg_num: "Numero di transazioni",
  homepage_graph_leg_am: "Importo (€)",
  graph_trans_hp_bot_leg_num: "Numero di transazioni",
  graph_trans_hp_bot_leg_am: "Importo (€)",
  graph_transactions: "TRANSAZIONI",

  // SETTLEMENTS
  graph_hp_sett_filter_value_1: "Finanziato",
  graph_hp_sett_filter_value_2: "Commissione",
  graph_hp_sett_leg_num: "Numero de pagamenti",
  graph_hp_sett_leg_am: "Importo (€)",
  graph_hp_bot_sett_leg_num: "Numero de pagamenti",
  graph_hp_bot_sett_leg_am: "Importo (€)",
  graph_settlements: "PAGAMENTI",

  // MONTHS
  january_abr: "Gen",
  february_abr: "Feb",
  march_abr: "Mar",
  april_abr: "Apr",
  may_abr: "Mag",
  june_abr: "Giu",
  july_abr: "Lug",
  august_abr: "Ago",
  september_abr: "Set",
  october_abr: "Ott",
  november_abr: "Nov",
  december_abr: "Dic",

  //HOMEPAGE DAILY KEY NUMBERS
  homepage_title_sales: "Vendite",
  homepage_title_settlements: "Pagamenti (transazioni del giorno precedente)",
  homepage_key_numb_acc: "Transazioni accettate",
  homepage_key_numb_am: "Importo accetato totale",
  homepage_key_numb_settlement_gr_am: "Importo lordo",
  homepage_key_numb_fees: "Commissione",
  homepage_key_numb_fin: "Importo finanziato",

  // ACQUIRED TRANSACTIONS VIEW
  acq_transactions_title: "Transazioni acquisite",
  transaction_date: "Data della transazione",
  gross_amount: "Importo lordo",
  col_card_brand: "Circuito",
  col_man_fees: "Commissione",
  col_tlc_number: "Numero di rimessa",
  acq_trans_key_numbers_title: "Pagato da Market Pay",
  card_acq_trans_number: "Transazioni",
  card_acq_trans_gross_amount: "Importo lordo",
  card_acq_trans_fees_amount: "Commissione",
  card_acq_trans_net_amount: "Importo netto",
  acq_details_title: "Transazioni acquisite  > ",

  // SETTLEMENTS VIEW
  settlements_title: "Pagamenti",
  settlement_tab_batch: "Batch ID",
  set_tab_merchant: "Mercante",
  pay_tab_settlement_date: "Data dell'accredito",
  pay_tab_pay_sol: "Soluzione",
  pay_tab_pay_amo: "Totale lordo",
  settlement_tab_fees: "Commissione",
  pay_tab_settlement_am: "Accreditato",

  // TRANSACTION DETAILS
  tp_titles_acceptation: "Accettazione",
  tp_titles_customer: "Cliente",
  tp_titles_transaction: "Transazione",
  tp_titles_history: "Storico",
  tp_titles_settlement: "Finanziamento",
  tp_titles_merchant: "Esercente",
  tp_fees_calculation: "Calcolo delle tasse",
  tp_fees_amount: "Importo delle tasse",
  tpd_checkout_type: "Tipo di pagamento",
  tpd_payment_method: "Metodo di pagamento",
  tpd_payment_solution: "Soluzione di pagamento",
  tpd_payment_type: "Tipo di pagamento",
  tpd_authorisation_code: "Codice autorizzazione",
  tpd_pos_id: "ID POS",
  tpd_pos_name: "Nome POS",
  tpd_bank_acc_dom_country: "Domiciliazione",
  tpd_card_iss_bank: "Banca emettitrice",
  tpd_card_iss_coun: "Paese ",
  tpd_card_level: "Livello carta",
  tpd_card_type: "Tipo di carta",
  tpd_pan_first_six: "BIN",
  tpd_pan_last_four: "Ultime quattro cifre",
  tpd_scheme: "Circuito",
  tpd_op_date: "Data dell'operazione",
  tpd_op_date_utc: "Data dell'operazione (UTC)",
  tpd_op_time: "Ora dell'operazione",
  tpd_currency: "Moneta",
  tpd_gross_amount: "Importo lordo",
  tpd_net_amount: "Importo netto",
  tpd_trans_id: "ID Transazione",
  tpd_merch_trans_id: "ID Transazione esercente",
  tpd_order_num: "Numero d'ordine",
  tpd_trans_type: "Tipo transazione",
  tpd_trans_event: "Evento",
  tpd_status: "Stato",
  tpd_trans_event_id: "ID evento",
  tpd_root_trans_event_id: "ID transazione",
  tpd_event_timestamp: "Data e ora dell'evento",
  tpd_acquirer: "Acquirer",
  tpd_settlement_batch: "Batch finanziamento",
  tpd_settlement_date: "Data finanziamento",
  tpd_settlement_status: "Stato finanziamento",
  tpd_interchange: "Interchange",
  tpd_intermediate_fees: "Commissioni di intermediazione",
  tpd_mkp_fees: "Commissioni Market Pay",
  tpd_scheme_fees: "Commissioni Circuito",
  tpd_total_fees: "Commissioni totali",
  tpd_account_name: "Organizzazione",
  tpd_company_name: "Società",
  tpd_merchant_name: "Negozio",
  tpd_country: "Paese",
  tpd_contract_num: "Numero contratto",
  tpd_transaction_settlement_id: "ID regolamento transazione",
  tpd_tlc_number: "Numero di rimessa",
  tpd_card_zone: "Zona della carta",
  tpd_card_brand: "Circuito",
  tpd_settlement_id: "Batch ID",
  tpd_calculation_method: "Metodo di calcolo",
  tpd_marketpay_fixed_fees: "Commissioni fisse Market Pay",
  tpd_markeetpay_fees_rate: "Tasso di commissione Market Pay",
  tpd_fixed_scheme_fees: "Commissioni fisse del circuito",
  tpd_scheme_fees_rate: "Tasso di commissione del circuito",
  tpd_total_marketpay_fees: "Totale commissioni Market Pay",
  tpd_fixed_fees: "Commissioni fisse",
  tpd_variable_fees: "Commissioni variabili",
  tpd_total_acquiring_fees: "Totale commissioni di acquisizione",
  col_total_marketpay_fees: "Totale commissioni Market Pay",
  col_interchange_fees: "Commissioni di interscambio",
  col_scheme_fees_rate: "Tasso di commissione del circuito",
  col_fixed_scheme_fees: "Commissioni fisse del circuito",
  col_marketpay_fees_rate: "Tasso di commissione Market Pay",
  col_marketpay_fixed_fees: "Commissioni fisse Market Pay",
  col_calculation_method: "Metodo di calcolo",
  col_card_zone: "Zona della carta",
  col_transaction_settlement_id: "ID regolamento transazione",
  payment_type_card: "Carta",
  payment_type_banktransfer: "Bonifico bancario",
  payment_type_contactless: "Senza contatto",
  payment_type_wallet: "Wallet",
  pos_id: "POS ID",
  col_processing_ht: "Net processing fees",
  col_processing_tva: "Processing fees VAT",
  col_processing_ttc: "Gross processing fees",

  // TRANSACTION HISTORY TABLE
  trans_det_tab_uuid: "UUID",
  trans_det_tab_timestamp: "Data e ora",
  trans_det_tab_event: "Evento",
  trans_det_tab_status: "Stato",
  trans_det_tab_amount: "Importo",
  transaction_det_last_sync: "Ultimo aggiornamento : ",
  transaction_det_page_title_1: "Transazioni ->",
  transaction_det_page_title_2: "Detaglio transazione {transactionId}",
  back_transactions_list: "Ritorno alla lista delle transazioni",

  // NOTIFICATIONS
  notif_new_merchant: "Un nuovo punto vendita é stato aggiunto al",
  notif_new_comp: "Una nuova società é stata ggiunta al",
  notif_new_account: "Un nuovo account é stato aggiunto al",
  notif_new_merchant_admin_inapp: "È stato creato un nuovo Merchant Admin:",
  notif_new_marketpay_admin_inapp: "È stato creato un nuovo Market Pay Admin:",
  notif_new_operator_inapp: "È stato creato un nuovo Operator",
  notif_hide_button: "Nascondere",
  notif_hide_all_button: "Nascondere tutti",
  new_notif: "Hai {count} nuove notifiche:",
  no_new_notif: "Non hai nuove notifiche",
  notif_viewed: "Visualizzato",

  // NOTIFICATION SETTINGS
  my_notifications: "Le mie notifiche",
  notif_title: "Le mie notifiche",
  notif_peri_update: "Aggiornamenti perimetrali",
  notif_user_update: "Utenti",
  notif_in_app: "In-App",
  notif_email: "Email",
  notif_edit_merchant: "Nuovo commerciante",
  notif_edit_company: "Nuova compagnia",
  notif_edit_acc: "Nuovo account",
  notif_edit_group: "Nuovo gruppo",
  notif_edit_operator: "Nuovo Operatore",
  notif_edit_merchant_admin: "Nuovo Admin Merchant",
  notif_edit_markerpay_admin: "Nuovo Admin Market Pay",
  perim_creat_account: "Trova un Organizzazione",
  perim_creat_company: "Trova un Società",

  // FILTERS
  filter_all_comp: "Tutti",
  reset_button_text: "Azzeramento",

  // VIEWS
  sv_deletion_confirmation_toaster: "La tua visualizzazione è stata eliminata",
  sv_creation_confirmation: "La tua visualizzazione è stata salvata",
  sv_save_this_view: "Salva questa visualizzazione",
  sv_view_name: "Nome de la visualizzazione",
  sv_saved_views: "Visualizzazione salvato",
  sv_name_is_required: "La visualizzazione deve avere un nome",
  sv_name_is_too_short: "Il nome deve contenere almeno 3 caratteri",
  view_name_already_exists: "Questo nome di visualizzazione esiste già",
  sv_deletion_confirmation: "Eliminare veramente questa visualizzazione?",

  // DATEPICKER DEFINED RANGES
  date_picker_today: "Oggi",
  date_picker_yesterday: "Ieri",
  date_picker_current_month: "Mese attuale",
  date_picker_last_week: "La scorsa settimana",
  date_picker_last_two_weeks: "Ultime due settimane",
  date_picker_last_month: "Il mese scorso",
};
