import {PaletteOptions} from "@mui/material";
import {Colors, PaletteTheme} from "../../interfaces/theme/theme";


// All this is used for styled components

const colors: Colors = {
    DANGER: "#d3412d",
    DANGER_LIGHT: "#F5C0BC",
    PRIMARY: "#00a2a7",
    DARK_PRIMARY: "#007378",
    INFO: "#00c8d3",
    DARK_INFO: "#0097a2",
    SUCCESS: "#99C3C6",
    LIGHT_PRIMARY: "#58d4d8",
    WHITE: "#ffffff",
    DEFAULT_TEXT_COLOR: "#373F51",
    DISABLED_COLOR: "#8a8a8a",
    BACKGROUND_COLOR_SIDE_MENU: "#373F51",
    GRAY_BCK: "#F7F7F7",
    GRAY_LIGHT: "#ECECEC",
    GRAY: "#BABABA",
    GRAY_DARK: "#727272",
    GRAY_DROPDOWN: "#CDD1D8",
    SECONDARY: "#58647D",
    GREEN_WHITE: "#EBF4F5",
    GREEN_SOFT: "#64A4A7",
    DEEP_GREEN: "#00787D",
    LIGHT_PURPLE: "#58647D4D",
    TOAST_ERROR: "#FBE7E6",
    TOAST_INFO: "#3498db",
    TOAST_INFO_LIGHT: "#ecf0f3",
    TOAST_WARNING: "#f1c40f",
    TOAST_WARNING_LIGHT: "#fcf3cf",
}

const palette: PaletteOptions = {
    action: {
        disabledBackground: colors.DISABLED_COLOR
    },
    primary: {
        main: colors.PRIMARY,
        dark: colors.DARK_PRIMARY,
        light: colors.LIGHT_PRIMAR,
        contrastText: colors.WHITE
    },
    secondary: {
        main: colors.SECONDARY,
        dark: "#2d3a51",
        light: "#8591ac",
        contrastText: colors.WHITE
    },
    warning: {
        main: "#f0c46d",
        dark: "#bb943f",
        light: "#bb943f",
        contrastText: colors.WHITE
    },
    success: {
        main: colors.SUCCESS,
        dark: "#6a9395",
        light: "#cbf6f9",
        contrastText: colors.WHITE
    },
    info: {
        main: "#00c8d3",
        dark: "#0097a2",
        light: "#64fbff",
        contrastText: colors.WHITE
    },
    error: {
        main: colors.DANGER,
        dark: "#9a0003",
        light: colors.DANGER_LIGHT,
        contrastText: colors.WHITE
    },
    background:{
        default: colors.WHITE,
        paper: colors.GRAY_BCK,
    },
}

export const paletteTheme: PaletteTheme = {
    palette,
    colors
}
