import React, {
  FunctionComponent,
  useState,
} from "react";
import {
  Entity,
  UpdateEntity,
} from "interfaces/perimeter/perimeter";
import { CardRow } from "components/atoms/card/CardRow";
import { SelectChips } from "components/atoms/select/SelectChips";
import { arrayUtils } from "utils/common/arrayUtils";
import ErrorMessage from "components/atoms/ErrorMessage";
import { PERIMETER_ERROR } from "constants/errors/perimeter";
import { ValidateErrorMap } from "components/molecules/useraccount/edit/UserAccountEdit";
import IconToolTip from "components/molecules//tooltip/IconToolTip";
import { Switch } from "components/atoms/switch/Switch";
import { AuthorizedMerchantsResponse } from "interfaces/perimeter/merchant";
import { ResetIconButton } from "components/atoms/button/ResetButton/ResetIconButton";
import { REFERENTIAL_STATUS } from "constants/referential/referential";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface AccountMerchantPerimeterEditProps {
  authorizedMerchants: AuthorizedMerchantsResponse;
  merchants: Array<UpdateEntity>;
  defaultMerchants?: Array<Entity>;
  onChangeMerchant: (values: Entity[], hasAllMerchants: boolean) => void;
  canDelete?: boolean;
  hasCompanyPerimeter?: boolean;
  allFutureCompaniesChecked?: boolean;
  onFutureMerchantChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  validationError: ValidateErrorMap;
}

export const UserAccountMerchantPerimeterEdit: FunctionComponent<AccountMerchantPerimeterEditProps> = ({
  hasCompanyPerimeter,
  merchants,
  authorizedMerchants,
  defaultMerchants,
  validationError,
  onChangeMerchant,
  allFutureCompaniesChecked = false,
  onFutureMerchantChange,
}) => {

  const { smUp } = useMediaQueryUtil();

  const [futureMerchant, setFutureMerchant] = useState<boolean>(hasCompanyPerimeter);

  const canResetMerchants = defaultMerchants.length !== merchants.length ||
    (defaultMerchants.length === merchants.length &&
      !merchants.every(merchant => defaultMerchants.some(defaultMerchant => defaultMerchant.id === merchant.id)))
  const allOptions = arrayUtils.arrayOfUnique([...authorizedMerchants.merchants, ...defaultMerchants]);
  const displayError = validationError.REQUIRED_MERCHANTS && merchants.length === 0;

  const onChangeSwitchFutureMerchant = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setFutureMerchant(checked);
    onFutureMerchantChange(e, checked);
  };

  const onChangeChips = (values: Array<Entity>): void => {
    setFutureMerchant(false);
    onChangeMerchant(values, allOptions.length === values.length);
  };

  return (
    <>
      <CardRow
        id="peri_merchant"
        label="peri_merchant"
        customContentClassName="chips-box"
      >
        <SelectChips
          error={displayError}
          options={allOptions}
          value={merchants}
          onChangeValue={onChangeChips}
          style={{ position: "relative" }}
        />
        {canResetMerchants &&
          <div style={{ position: "absolute", right: smUp ? "-25px" : "-20px", top: "1px" }}>
            <IconToolTip title="account_tooltip_reset">
              <ResetIconButton
                onClick={() => onChangeMerchant(defaultMerchants, hasCompanyPerimeter)}
              />
            </IconToolTip>
          </div>
        }
      </CardRow>
      <CardRow id="no-label" label="no-label">
        {displayError &&
          <div style={{ width: "100%", marginTop: "-20px" }}>
            <ErrorMessage error={PERIMETER_ERROR.REQUIRED_MERCHANTS.id} errors={validationError} />
          </div>
        }
      </CardRow>

      {authorizedMerchants.hasCompanyPerimeter &&
        authorizedMerchants.merchants
          .filter(merchant => merchant.status === REFERENTIAL_STATUS.ACTIVE.id).length === merchants
            .filter(merchant => merchant.status === REFERENTIAL_STATUS.ACTIVE.id).length &&
        (
          <CardRow id="no-label" label="no-label">
            <Switch
              label="inherit_future_merchants"
              checked={futureMerchant || allFutureCompaniesChecked}
              disabled={allFutureCompaniesChecked}
              onChange={onChangeSwitchFutureMerchant}
            />
          </CardRow>
        )}
    </>
  );
}
