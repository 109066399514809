import {FormatNumberOptions, IntlShape} from "react-intl";


function formatNumber(intl: IntlShape, number: number, options?: FormatNumberOptions) {
    // Chromium INTL doesn't work properly for Spanish currency
    // So we use NL locale to format the number
    // If currency, remove the space between the number and the currency symbol
    if (intl.locale === "es") {
        const format = new Intl.NumberFormat("nl", options).format(number);
        if (options?.style === "currency")
            return format.slice(0, 1) + format.slice(2);
        return format;
    }
    return intl.formatNumber(number, options);
}

export const intlUtils = Object.freeze({
    formatNumber
})
