import {MenuProps, SxProps, Theme} from "@mui/material";
import {paletteTheme} from "../../themes/palette";

export const menuPropsStyles: Partial<MenuProps> = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    PaperProps: {
        elevation: 0,
        sx: {
            borderRadius: "0 0 2px 2px",
        },
    },
    MenuListProps: {
        sx: {
            border: `1px solid ${paletteTheme.colors.GRAY}`,
            overflow: "auto",
            borderRadius: "0 0 2px 2px",
            padding: "1px 0",
            margin: 0,
            boxShadow: "none",
            maxHeight: "250px",
        }
    }
}

export const selectStyles = (selectWidth: number, filled: boolean): SxProps<Theme> => {
    return {
        width: selectWidth,
        height: "24px",
        "& .MuiOutlinedInput-input.MuiSelect-multiple.MuiOutlinedInput-input.MuiInputBase-input": {
            padding: "0px 45px 0px 5px",
            fontSize: "12px",
            fontWeight: 600,
        },
        "& .MuiOutlinedInput-notchedOutline span": {
            "&:not(.notranslate)": {
                maxWidth: "72px",
            },
            padding: 0,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: filled ? paletteTheme.colors.PRIMARY : paletteTheme.colors.GRAY,
            paddingLeft: "5px",
        },
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: 2,
                paddingLeft: "2px"
            }
        }
    }
}
