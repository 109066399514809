import { Typography } from "@mui/material";
import FlexBox from "components/atoms/flex/FlexBox";
import React, { FunctionComponent } from "react";
import SwitchStyled from "styles/components/switch/SwitchStyled";
import { paletteTheme } from "styles/themes/palette";
import { VoidNoParamsFn } from "types/common/CommonTypes";
import { FlexAttributValue } from "types/flex/flex";

export const HeaderSwitch: FunctionComponent<{
    title:string,
    checked:boolean,
    onClick:VoidNoParamsFn,
}> = ({
    title,
    checked,
    onClick,
}) => <FlexBox alignItems={FlexAttributValue.CENTER} justifyContent={FlexAttributValue.END} paddingBottom={3}>
    <Typography variant="h2" fontSize="14px" display="inline" paddingRight="0.5rem" color={paletteTheme.palette.primary["main"]}>
        {title}
    </Typography>
    <SwitchStyled 
        checked={checked}
        onChange={onClick}
    />
</FlexBox>

