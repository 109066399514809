import React, { FunctionComponent } from "react";
import { HeaderGroup } from "react-table";
import { TableRow as TableRowMaterialUI } from "@mui/material";
import { TableHeaderCell } from "components/atoms/table/cells/TableHeaderCell";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface TableHeaderProps {
  headerGroup: HeaderGroup,
  resize?: boolean
}

export const TableHeader: FunctionComponent<TableHeaderProps> = ({ headerGroup, resize }) => {
  const { lgUp } = useMediaQueryUtil();
  return (
    <TableRowMaterialUI component="div" {...headerGroup.getHeaderGroupProps()} className="tr">
      {headerGroup.headers.map((column) => (
        <TableHeaderCell key={column.id} column={column} resize={resize ?? lgUp}/>
      ))}
    </TableRowMaterialUI>
  );
};
