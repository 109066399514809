import {SearchData, TableData} from "../../interfaces/table/TableInterface";
import {
    Dispatch,
    ForwardedRef,
    SetStateAction,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import {sortUtils} from "../../utils/api/sortUtils";
import {ApiError, errorUtils} from "../../utils/api/errorUtils";
import {TableRef} from "../../components/atoms/table/Table";
import {SortingRule} from "react-table";
import {QueryParam} from "../../interfaces/UserInterface";

interface TableInstance {
    totalCount: number,
    displayedCount: number,
    last: boolean,
    isMounted: boolean,
    loading: boolean,
    isNextPageLoading: boolean,
    fetchError: boolean,
    onNextPage: () => void,
    onFetchDataWithFilters: () => void
}

export interface TableOptions {
    defaultSortBy: SortingRule<object>,
    sortBy: Array<SortingRule<object>>,
    fetchOnMount?: boolean,
    pageSize: number
}

export function useTable(
    onChangeData: Dispatch<SetStateAction<TableData[]>>,
    onFetch: (param: QueryParam) => Promise<SearchData>, {
        defaultSortBy,
        sortBy,
        pageSize,
        fetchOnMount,
    }: TableOptions, ref: ForwardedRef<TableRef>): TableInstance {

    const [page, setPage] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [displayedCount, setDisplayedCount] = useState<number>(0);
    const [last, setLast] = useState<boolean>(false);
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [isNextPageLoading, setNextPageLoading] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<boolean>(false);

    const onFetchDataWithFilters = (afterFetch?: () => void) => {
        setFetchError(false)
        onFetch(sortUtils.sortToPageable(0, pageSize, sortUtils.getCurrentSort(sortBy, defaultSortBy))).then(res => {
            setPage(1)
            onChangeData(res.datas);
            setTotalCount(res.totalElements);
            setDisplayedCount(res.datas.length);
            setLast(res.last);
            afterFetch && afterFetch()
        })
            .catch((e: Promise<ApiError> | ApiError) => {
                errorUtils.handleBackErrors(e)
                // On fetch error, display error message and remove loader
                setLoading(false)
                setTotalCount(0)
                setFetchError(true)
                setLast(true);
            })
    }

    // Fetch with new filters with loader
    const onFetchDataWithNewFiltersWithLoading = () => {
        setLoading(true);
        onFetchDataWithFilters(() => {
            setLoading(false)
        })
    }

    // Add next results
    // We need to add the results to the current data
    // Increment the page number for next fetch.
    // Page start at 0, if you fetch via the loading fetch, page will be set to 1 so the next page function works
    const onNextPage = () => {
        setNextPageLoading(true)
        onFetch(sortUtils.sortToPageable(page, pageSize, sortUtils.getCurrentSort(sortBy, defaultSortBy))).then(res => {
            setPage(page + 1)
            onChangeData(prev => [...prev, ...res.datas]);
            setDisplayedCount(prev => prev + res.datas.length);
            setTotalCount(res.totalElements);
            setLast(res.last);
        })
            .catch((e: Promise<ApiError> | ApiError) => {
                    errorUtils.handleBackErrors(e)
                }
            ).finally(() => setNextPageLoading(false))
    }

    // Trigger fetch data onMount
    useEffect(() => {
        if (isMounted && fetchOnMount) {
            onFetchDataWithNewFiltersWithLoading();
        } else {
            setLoading(false)
        }
        setIsMounted(true)
    }, [])

    // Access to fetch outside of table!
    useImperativeHandle(ref, () => ({
        fetch: onFetchDataWithNewFiltersWithLoading,
        fetchOnSort: onFetchDataWithFilters
    }))

    return {
        totalCount,
        last,
        loading,
        isMounted,
        displayedCount,
        isNextPageLoading,
        fetchError,
        onNextPage,
        onFetchDataWithFilters
    };
}
