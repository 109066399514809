import { CellType } from "interfaces/table/table";
import React, { CSSProperties, FunctionComponent } from "react";

interface IClickableCellProps {
  [key: string]: unknown;
}

export const WrappedCell = <P extends object>(
  Component: FunctionComponent<IClickableCellProps>,
  options: {
    type?: CellType;
    styles?: CSSProperties;
    cellClickHandler?: (id: string) => void;
    defaultValue?: string;
    isTooltipCell?: boolean;
    tooltipCell?: (item: unknown) => React.ReactElement;
  }
) => {
  const WrapComponent: FunctionComponent<P & IClickableCellProps> = (props) => {
    const clickHandler = options.cellClickHandler ? options.cellClickHandler : undefined;
    return (
      <Component
        type={options.type}
        defaultValue={options.defaultValue}
        style={{ ...options.styles }}
        isTooltipCell={options.isTooltipCell}
        tooltipCell={options.tooltipCell}
        onClickCell={clickHandler}
        {...props}
      />
    );
  };
  return WrapComponent;
};
