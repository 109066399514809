import { Range } from "react-date-range";
import { 
  EMPTY_DATE_INITIAL_STATE, 
  INITIAL_RANGE_LAST_WEEK, INITIAL_RANGE_TODAY,
} from "constants/datepicker/datepicker";
import {
  INITIAL_SETTLEMENT_FILTER,
  INITIAL_ACCEPTED_TRANSACTION_FILTER,
  INITIAL_ACCEPTED_TRANSACTION_FILTER_ADMIN,
  INITIAL_ACQUIRED_TRANSACTION_FILTER,
  INITIAL_ACQUIRED_TRANSACTION_FILTER_ADMIN,
  PORTFOLIO_VOLUME_TRENDS_BY,
} from "constants/transaction/transactionFilter";
import { INITIAL_PERIMETER_FILTER, PerimeterOptions } from "constants/perimeter/perimeter";
import { rightUtils } from "utils/business/rightUtils";
import { User } from "interfaces/user/user";
import { arrayUtils } from "utils/common/arrayUtils";
import { PerimeterFilterValues } from "interfaces/transaction/transaction";
import { CompanyFilterOptions, Options, MerchantFilterOptions } from "constants/options/option";
import { DocumentsFilterValues, FilterValues, PortfolioValumeTrendsFilterValues } from "interfaces/filter/filter";
import { KeyboardEvent } from "react";
import { selectUtils } from "utils/business/selectUtils";
import { ManagableColumn } from "interfaces/table/table";
import { DOCUMENTS_PAST_MONTH, DOCUMENTS_YEARS_VALUES } from "constants/documents/documentsFilter";
import { TransactionType } from "interfaces/filter/filterColumn";

function buildEmptyPerimeterFilterValues(): PerimeterFilterValues {
  return {
    accounts: [],
    companies: { selectedOptions: [], all: false },
    merchants: { selectedOptions: [], all: false },
  };
}

function getTransactionInitialFilters(user: User, transactionsType: TransactionType): FilterValues {
  const isAdminOrGroupPerimeter = rightUtils.isAdminOrGroupPerimeter(user);
  if (transactionsType === TransactionType.ACCEPTED) {
    const operationDate = isAdminOrGroupPerimeter
      ? INITIAL_ACCEPTED_TRANSACTION_FILTER_ADMIN?.operationDate
      : INITIAL_ACCEPTED_TRANSACTION_FILTER?.operationDate;
    return {
      operationDate,
      ...buildEmptyPerimeterFilterValues(),
    };
  } else {
    const operationDate = isAdminOrGroupPerimeter
      ? INITIAL_ACQUIRED_TRANSACTION_FILTER_ADMIN?.operationDate
      : INITIAL_ACQUIRED_TRANSACTION_FILTER?.operationDate;
    const settlementDate = isAdminOrGroupPerimeter
      ? INITIAL_ACQUIRED_TRANSACTION_FILTER_ADMIN?.settlementDate
      : INITIAL_ACQUIRED_TRANSACTION_FILTER?.settlementDate;
    return {
      operationDate,
      settlementDate,
      // tlcNumber: "",
      // contractNumber: "",
      ...buildEmptyPerimeterFilterValues(),
    };
  }
}

function getDocumentsInitialFilters(options?: PerimeterOptions): DocumentsFilterValues {
  return {
    settlementMonths: [DOCUMENTS_PAST_MONTH()],
    settlementYears: [DOCUMENTS_YEARS_VALUES().at(-1)],
    ...computePerimetersValues(options?.accounts, options?.companies, options?.merchants),
  };
}
function getInitialRangeState(user: User) {
  return rightUtils.isAdminOrGroupPerimeter(user) ? { ...EMPTY_DATE_INITIAL_STATE } : { ...INITIAL_RANGE_TODAY };
}

function getSettlementsInitialFilters(): FilterValues {
  return {
    operationDate: INITIAL_SETTLEMENT_FILTER.operationDate,
    ...buildEmptyPerimeterFilterValues(),
  };
}

function getPortfolioFilters(): FilterValues {
  return {
    operationDate: INITIAL_RANGE_LAST_WEEK,
    ...buildEmptyPerimeterFilterValues(),
  };
}

const getTopBottomFilters = (): FilterValues => {
  return {
    acceptationRange: INITIAL_RANGE_LAST_WEEK,
    ...buildEmptyPerimeterFilterValues(),
  };
}

const getVolumeTrendsInitialFilters = (options?: PerimeterOptions): PortfolioValumeTrendsFilterValues =>{
  return {
   trendBy: [PORTFOLIO_VOLUME_TRENDS_BY[0]],
    ...computePerimetersValues(options?.accounts, options?.companies, options?.merchants),
  };
}

function computePerimetersValues(
  accounts: Options[],
  companies: CompanyFilterOptions[],
  merchants: MerchantFilterOptions[]
): PerimeterFilterValues {
  const newAccounts = arrayUtils.hasUniqueElement(accounts) ? accounts : INITIAL_PERIMETER_FILTER.accounts;
  const newCompanies =
    arrayUtils.hasUniqueElement(companies) && !arrayUtils.isEmpty(newAccounts)
      ? companies
      : INITIAL_PERIMETER_FILTER.companies;
  const newMerchants =
    arrayUtils.hasUniqueElement(merchants) && !arrayUtils.isEmpty(newCompanies)
      ? merchants
      : INITIAL_PERIMETER_FILTER.merchants;

  return {
    accounts: newAccounts,
    companies: {
      selectedOptions: newCompanies,
      all: false,
    },
    merchants: {
      selectedOptions: newMerchants,
      all: false,
    },
  };
}

const initSavedPerimeterFilters = (savedFilters?: PerimeterFilterValues): PerimeterFilterValues => {
  return {
    companies: savedFilters?.companies ?? {
      selectedOptions: [],
      all: !arrayUtils.isEmpty(savedFilters?.accounts) && arrayUtils.isEmpty(savedFilters?.companies?.selectedOptions),
    },
    accounts: savedFilters?.accounts ?? [],
    merchants: savedFilters?.merchants ?? {
      selectedOptions: [],
      all: !arrayUtils.isEmpty(savedFilters?.accounts) && arrayUtils.isEmpty(savedFilters?.merchants?.selectedOptions),
    },
  };
};

const getInitialFiltersByPerimeter = (
  options: PerimeterOptions,
  operationDate?: Range,
  settlementDate?: Range
): FilterValues => {
  return {
    operationDate: operationDate ? operationDate : {},
    settlementDate: settlementDate ? settlementDate : {},
    ...computePerimetersValues(options.accounts, options.companies, options.merchants),
  };
};

function getPortfolioTrendsByPerimeterOptions(options: PerimeterOptions): FilterValues {
  return {
    ...computePerimetersValues(options.accounts, options.companies, options.merchants),
  };
}

/**
 * Auto fill the perimeter based on available options and current perimeter
 *
 * If you have no results in one of then, an "ALL" option will be added
 */
function getInitialFiltersByPerimeterOptionsAndPerimeter<T extends PerimeterFilterValues>(
  perimeter: T,
  options: PerimeterOptions,
  forbidOptionAllSelection?: boolean
): T {
  const perimeterOrOptionsAccount = arrayUtils.pickNoneEmptyArray(perimeter.accounts, options.accounts);
  const perimeterOrOptionsCompany = arrayUtils.pickNoneEmptyArray(
    perimeter.companies.selectedOptions,
    options.companies
  );
  const perimeterOrOptionsMerchant = arrayUtils.pickNoneEmptyArray(
    perimeter.merchants.selectedOptions,
    options.merchants
  );

  if (
    arrayUtils.isEmpty(perimeter.merchants.selectedOptions) &&
    arrayUtils.isEmpty(perimeter.companies.selectedOptions) &&
    arrayUtils.isEmpty(perimeter.accounts)
  ) {
    let companies = perimeterOrOptionsCompany.filter((company) =>
      perimeterOrOptionsAccount.map((account) => account.id).includes(company.idAccount)
    );
    let merchants = perimeterOrOptionsMerchant.filter((merchant) =>
      perimeterOrOptionsCompany.map((company) => company.id).includes(merchant.idCompany)
    );

    if (arrayUtils.isEmpty(perimeter.companies.selectedOptions) && options.companies.length > 1) {
      companies = forbidOptionAllSelection ? [] : [selectUtils.buildCheckboxAll()];
    }

    if (arrayUtils.isEmpty(perimeter.merchants.selectedOptions) && options.merchants.length > 1) {
      merchants = forbidOptionAllSelection ? [] : [selectUtils.buildCheckboxAll()];
    }

    return {
      ...perimeter,
      ...computePerimetersValues(perimeterOrOptionsAccount, companies, merchants),
    };
  }

  return perimeter;
}

/**
 * Manage interactions with "All" checkbox in autocomplete
 *
 * If All was selected and you click on an option, then All is unselected
 * If All is just selected, then you only keep All
 * Else you return all newValues
 */

const manageAllCheckbox = (newValues: Array<Options>, values: Array<Options>) => {
  const allInOldValues = values.find((item) => item.id === "");
  const allInNewValues = newValues.find((item) => item.id === "");
  if ((allInOldValues && !allInNewValues) || (allInOldValues && allInNewValues)) {
    return { selectedOptions: newValues.filter((item) => item.id !== ""), all: false };
  }

  if (!allInOldValues && allInNewValues) {
    return { selectedOptions: newValues.filter((item) => item.id === ""), all: true };
  }

  return { selectedOptions: newValues, all: false };
};

function hasUniqueElementNotAdmin(isAdmin: boolean, choices: Array<Options>) {
  return !isAdmin && arrayUtils.hasUniqueElement(choices) && choices[0].id !== "";
}

function unknownToArrayOfOptions(value: unknown, options: Array<Options>): Array<Options> {
  return Array.isArray(value)
    ? value
        .map((item: string | number) => options.find((event) => item.toString() === event.id))
        .filter((value) => !!value)
    : [];
}

function unknownToArrayOfManagableColumn(
  value: unknown,
  columns: Record<string, ManagableColumn>
): Array<ManagableColumn> {
  return Array.isArray(value) ? value.map((item: string | number) => columns[item]).filter((value) => !!value) : [];
}

function unknownToString(value: unknown): string {
  return typeof value === "string" ? value : null;
}

const onKeyPressOnlyNumber = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

const generateOperationDate = (operationDate: Range) => {
  const startDate = operationDate?.startDate;
  const endDate = operationDate?.endDate;

  return {
    ...operationDate,
    key: "selection",
    startDate: startDate ? new Date(startDate) : new Date(),
    endDate: endDate ? new Date(endDate) : new Date(),
  };
};

export const filterUtils = {
  getTransactionInitialFilters,
  getInitialFiltersByPerimeter,
  getInitialFiltersByPerimeterOptionsAndPerimeter,
  getSettlementsInitialFilters,
  getPortfolioFilters,
  getInitialRangeState,
  hasUniqueElementNotAdmin,
  onKeyPressOnlyNumber,
  initSavedPerimeterFilters,
  unknownToArrayOfOptions,
  unknownToArrayOfManagableColumn,
  unknownToString,
  buildEmptyPerimeterFilterValues,
  manageAllCheckbox,
  getPortfolioTrendsByPerimeterOptions,
  generateOperationDate,
  getDocumentsInitialFilters,
  getTopBottomFilters,
  getVolumeTrendsInitialFilters,
};
