import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useNavigate, useParams} from "react-router-dom";
import {UserDetails} from "../interfaces/user/user";
import {userService} from "../services/user/UserService";
import {errorUtils, PromisableApiError} from "../utils/api/errorUtils";
import {perimeterService} from "../services/user/PerimeterService";
import { UserAccountDisplay } from "components/molecules/useraccount/display/UserAccountDisplay";
import {MY_ACCOUNT_PATH, USER_PATH} from "../constants/routes/RoutePaths";
import {useAuthUser} from "../hooks/useAuthUser";
import {toastUtils} from "../utils/toast/toastUtils";
import {userMapper} from "../mapper/user/user";
import WrappedLoadingContent from "../components/molecules/WrappedLoadingContent";
import {useAccountPerimeter} from "../hooks/account/useAccountPerimeter";

const UserDetailView: FunctionComponent<RouteProps> = () => {
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState<UserDetails>();

    const {idUser} = useParams();
    const {user: authUser, permissions: authPermissions} = useAuthUser();

    const onUpdateUserDetails = () => {
        const ac = new AbortController();
        userService.getUser(parseInt(idUser), {signal: ac.signal})
            .then(res => {
                setUserDetails(userMapper.userResponseToUserDetails(res))
            })
            .catch((e: PromisableApiError) => {
                // Redirect authenticated user to home page if he does not have the right to get the user
                if (errorUtils.interceptErrorStatus(e, 404)) {
                    navigate(`/${USER_PATH}`)
                    toastUtils.errorToastI8ln("user_not_found")
                } else {
                    errorUtils.handleBackErrors(e);
                }
            })
        return () => ac.abort();
    }

    const {
        refreshPerimeter,
        getAccount,
        accounts,
        loading,
        perimeter
    } = useAccountPerimeter(userDetails, {
        onGetPerimeter: () => perimeterService.getPerimeter(parseInt(idUser)),
        onGetAccounts: () => perimeterService.getUserAuthorizedAccounts(idUser),
        onGetAccount: (idAccount: string) => perimeterService.getUserDetailsAuthorizedCompanies(idUser, idAccount)
    })

    // Redirect to account if self
    useEffect(() => {
        if (authUser) {
            if (idUser === authUser.id.toString()) {
                navigate(`/${MY_ACCOUNT_PATH}`)
            } else {
                onUpdateUserDetails();
            }
        }
    }, [authUser])


    return (
        <WrappedLoadingContent loading={loading}>
            <UserAccountDisplay
                idUser={idUser}
                userDetails={userDetails}
                authPermissions={authPermissions}
                onUpdateUserDetails={onUpdateUserDetails}
                perimeter={perimeter}
                onCollapseAccount={getAccount}
                accounts={accounts}
                onUpdatePerimeter={refreshPerimeter}/>
        </WrappedLoadingContent>
    )
}

export default UserDetailView;
