import {paletteTheme} from "../../themes/palette";
import {PaperProps, SxProps, Theme} from "@mui/material";

const inputLabelColor = (filled: boolean, disabled: boolean): string => {
    if (filled) {
        return paletteTheme.colors.PRIMARY;
    }
    if (disabled) {
        return paletteTheme.colors.GRAY;
    }
    return paletteTheme.colors.DEFAULT_TEXT_COLOR;
}

export const textFieldStyles = (displayAutocompleteField: boolean, filled: boolean, disabled: boolean): SxProps<Theme> => {
    return {
        "& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary .MuiAutocomplete-input": {
            height: displayAutocompleteField ? "unset" : "0",
        },
        "& .MuiInputLabel-root": {
            transform: filled ? "translate(4px, -8px)" : "translate(4px, 5px)",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            paddingLeft: "3px",
            borderColor: filled ? paletteTheme.colors.PRIMARY : paletteTheme.colors.GRAY,
        },
        "&:hover": {
            "& .MuiInputLabel-root:not(.Mui-focused)": {
                color: inputLabelColor(filled, disabled)
            }
        }
    }
}

export const autocompleteStyles = (selectWidth: number): SxProps<Theme> => {
    return {
        "&.MuiAutocomplete-root": {
            width: selectWidth,
            height: "24px",
            "& .MuiButtonBase-root:hover, & .MuiButtonBase-root:focus": {
                backgroundColor: "transparent",
                "& .MuiTouchRipple-root": {
                    opacity: 0
                }
            },
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                paddingLeft: "1px",
            },
        }
    }
}

export const paperStyles: PaperProps = {
    elevation: 0,
    sx: {
        borderRadius: "0 0 2px 2px",
        "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
            paddingLeft: "0px",
            height: "20px",
            display: "flex",
            alignItems: "center"
        }
    }
}
