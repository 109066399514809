import React, {FunctionComponent} from "react";
import {Box} from "@mui/material";
import {Options} from "../../../constants/options/option";

interface AutocompleteTagsProps {
    uniqueElement: boolean,
    displayAutocompleteField: boolean,
    selectWidth: number,
    values: Options[]
}

const AutocompleteTags: FunctionComponent<AutocompleteTagsProps> = ({
                                                                        uniqueElement,
                                                                        displayAutocompleteField,
                                                                        selectWidth,
                                                                        values
                                                                    }) =>
    (<>
        {
            uniqueElement && !displayAutocompleteField &&
            <Box className="ellipsis" sx={{width: selectWidth, paddingTop: "1px"}}>
                <span>{values && values[0].name}</span>
            </Box>
        }
        {!uniqueElement && values.length >= 1 ? `+ ${values.length}` : ""}
    </>)


export default AutocompleteTags;
