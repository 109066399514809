import { ManagableColumn } from "interfaces/table/table";
import { SettlementColumnName } from "./settlement";
import { MoreActionsSettlements } from "components/molecules/MoreActions/MoreActionsSettlements";

export const SETTLEMENT_POSSIBLE_COLUMN: Record<SettlementColumnName, ManagableColumn> = {
  BATCH_ID: {
    id: "BATCH_ID",
    accessor: "batchId",
    header: "col_man_settlement_batch",
    order: 1,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  STORE_NAME: {
    id: "STORE_NAME",
    accessor: "storeName",
    header: "set_tab_merchant",
    order: 2,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  PAYMENT_SOLUTION: {
    id: "PAYMENT_SOLUTION",
    accessor: "paymentSolution",
    header: "pay_tab_pay_sol",
    order: 4,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  SETTLEMENT_DATE: {
    id: "SETTLEMENT_DATE",
    accessor: "settlementDate",
    header: "pay_tab_settlement_date",
    order: 3,
    cell: {
      type: "date",
    },
    disableSortBy: true,
  },
  FEES: {
    id: "FEES",
    accessor: "commission",
    header: "settlement_tab_fees",
    order: 6,
    cell: {
      type: "text",
    },
    disableSortBy: false,
  },
  GROSS_AMOUNT_SETTLEMENTS: {
    id: "GROSS_AMOUNT_SETTLEMENTS",
    accessor: "grossAmount",
    header: "pay_tab_pay_amo",
    order: 5,
    cell: {
      type: "text",
    },
    disableSortBy: false,
  },
  SETTLED_AMOUNT: {
    id: "SETTLED_AMOUNT",
    accessor: "financed",
    header: "pay_tab_settlement_am",
    order: 7,
    cell: {
      type: "text",
    },
    disableSortBy: false,
  },
  VIEW_TRANSACTION: {
    id: "VIEW_TRANSACTION",
    accessor: "viewTransaction",
    header: "",
    order: 99,
    cell: {
      component: MoreActionsSettlements,
    },
    options: {
      maxWidth: 50,
      minWidth: 40,
      width: 30,
    },
    disableSortBy: true,
  },
};
