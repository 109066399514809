import { Messages } from "constants/messages/messages";
import {Languages} from "../../constants/messages/languages";
import {User} from "../../interfaces/user/user";


function getStoredLanguage() {
    const foundCookie = document.cookie.split(";").find(cookie => cookie.split("=")[0].trim() === "lang");
    return foundCookie ? foundCookie.split("=")[1] : null;
}

/***
 * get the navigator native language or favorite language based on localStorage or english
 */
const getNavigatorLanguage = (user?: User): string => {
    const language = getStoredLanguage();
    let favoriteLanguage: string;
    if (language) {
        favoriteLanguage = language.toLowerCase();
    } else if (user?.language) {
        favoriteLanguage = user.language.toLowerCase();
    } else {
        favoriteLanguage = navigator.language.split(/[-_]/)[0];
    }
    const compatibleLanguage = Languages[favoriteLanguage.toUpperCase()] || Languages.EN;
    return compatibleLanguage.id.toLowerCase()
}

const getNavigatorLocale = (user?: User): Locale => {
    return Languages[getNavigatorLanguage(user).toUpperCase()].locale;
}

/***
 * get the navigator native language or favorite language based on localStorage or english
 */
const getNavigatorLanguageFromUser = (user: User, preferredLanguage: string): string => {
    let favoriteLanguage: string = user?.language;
    if (!favoriteLanguage) {
        favoriteLanguage = navigator.language.split(/[-_]/)[0];
    }
    return preferredLanguage || favoriteLanguage || Languages.EN.id
}

/***
 * Get a label from messages files. This function can be used to i8ln label in services/utils
 */
function getMessage(id: string): string {
    return Messages[getNavigatorLanguage()][id]
}

export const languageUtils = {
    getNavigatorLanguage,
    getNavigatorLanguageFromUser,
    getMessage,
    getNavigatorLocale
}
