import React, { FunctionComponent } from "react";
import TableRow from "./TableRow";
import { Row } from "react-table";

interface VirtualiseRowProps {
  style: object,
  prepareRow: (Row) => void,
  row: Row,
  onClickRow?: (row: Row) => void
}

const VirtualiseRow: FunctionComponent<VirtualiseRowProps> = ({ prepareRow, row, style, onClickRow }) => {
  prepareRow(row);
  return (
    <TableRow
      key={`row-${row.id}`}
      prepareRow={prepareRow}
      row={row}
      style={{ ...style, cursor: onClickRow ? "pointer" : "auto" }}
      onClickRow={onClickRow}
    />
  );
};

export default VirtualiseRow;
