import React, { 
  FunctionComponent, 
  useState,
 } from "react";
import {
  Entity,
  Perimeter,
  PerimeterUpdate,
} from "interfaces/perimeter/perimeter";
import { Options } from "constants/options/option";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import { errorUtils } from "utils/api/errorUtils";
import { arrayUtils } from "utils/common/arrayUtils";
import { useEffectAbortable } from "hooks/useEffectAbortable";
import { usePerimeterSelector } from "hooks/usePerimeterSelector";
import { ValidateErrorMap } from "components/molecules/useraccount/edit/UserAccountEdit";
import { perimeterService } from "services/user/PerimeterService";
import { UserAccountPerimeterAccountSelect } from "components/molecules/useraccount/edit/UserAccountPerimeterAccountSelect";
import { UserAccountCompanyPerimeterEdit } from "components/molecules/useraccount/edit/UserAccountCompanyPerimeterEdit";
import CircleRoundedButton from "components/atoms/button/CircleRoundedButton";
import { userAccountUtils } from "components/molecules/useraccount/utils/UserAccountUtils";

interface AccountPerimeterEditProps {
  perimeter: Perimeter;
  editedPerimeter: PerimeterUpdate;
  onChangePerimeter: (perimeter: PerimeterUpdate) => void;
  perimetersErrors: ValidateErrorMap;
}

export const UserAccountPerimeterEdit: FunctionComponent<AccountPerimeterEditProps> = ({
  perimeter,
  editedPerimeter,
  onChangePerimeter,
  perimetersErrors,
}) => {

  const [initialPerimeter, setInitialPerimeter] = useState<PerimeterUpdate>(perimeterUtils.buildPerimeterUpdate(perimeter));
  const [authorizedAccounts, setAuthorizedAccounts] = useState<Array<Entity>>([]);
  const [allAuthorizedAccounts, setAllAuthorizedAccounts] = useState<Array<Entity>>([]);
  const [accountOptions, setAccountOptions] = useState<Array<Entity>>(userAccountUtils.getInitalOptions(initialPerimeter));

  const {
    remainingCompanies,
    allCompaniesAdded,
    authorizedMerchantResponseByCompany,
    authorizedCompanies,
    onRemoveCompany,
    onAddCompany,
    onAddAllCompanies,
    onRemoveAddAllCompanies,
    onChangeAccount,
    onChangeCompany: onChangeCompanyPerimeter,
    onChangeMerchants,
    onChangeCompanyTypePerimeter,
    onChangeAccountTypePerimeter,
    onGetAuthorizedMerchantsByAccount,
    onChangeAuthorizedMerchants,
  } = usePerimeterSelector(authorizedAccounts,
    {
      initialPerimeter,
      editedPerimeter,
    },
    onChangePerimeter,
  );

  const onNewPerimeter = (initialPerimeterCalculated: PerimeterUpdate, ac: AbortController) => {
    onChangePerimeter(initialPerimeterCalculated)
    perimeterService.getAuthorizedAccounts({ signal: ac.signal })
      .then(res => {
        if (arrayUtils.isEmpty(accountOptions)) {
          setAccountOptions(res)
          setAuthorizedAccounts(res)
        }
        setAllAuthorizedAccounts(res)
      })
      .catch(errorUtils.handleBackErrors)
  }

  const onUpdatePerimeter = (initialPerimeterCalculated: PerimeterUpdate) => {
    setAuthorizedAccounts([initialPerimeter.account])
    perimeterService.getAuthorizedMerchantsByAccount(initialPerimeter.account.id)
      .then(res => onGetAuthorizedMerchantsByAccount(res, initialPerimeterCalculated))
      .catch(errorUtils.handleBackErrors)
  }

  useEffectAbortable((ac) => {
    const initialPerimeterCalculated: PerimeterUpdate = perimeterUtils.buildPerimeterUpdate(perimeter);
    setInitialPerimeter(initialPerimeterCalculated)
    if (initialPerimeter.account.id) {
      onUpdatePerimeter(initialPerimeterCalculated)
    } else {
      onNewPerimeter(initialPerimeterCalculated, ac);
    }
  }, [perimeter])

  const onChangeCompany = (_option: Options, index: number) => {
    if (userAccountUtils.isOptionEmpty(_option)) {
      perimeterService.getMerchantsCompanies(_option.id).then(res => {
        onChangeAuthorizedMerchants(_option.id, res)
      }).catch(errorUtils.handleBackErrors)
    }
    onChangeCompanyPerimeter(_option, index);
  }

  const onChangeQueryAccount = (queryAccount: string) => {
    perimeterService.getAuthorizedAccounts({}, queryAccount)
      .then(res => {
        setAccountOptions(res)
        setAuthorizedAccounts(res)
      })
      .catch(errorUtils.handleBackErrors)

    if (userAccountUtils.isQueryEmptyAndInitialAuthorizedAccountNotEmpty(queryAccount, allAuthorizedAccounts)) {
      setAccountOptions(allAuthorizedAccounts)
      setAuthorizedAccounts(allAuthorizedAccounts)
    }
  }

  const canAddPerimeter = perimeterUtils.canAddPerimeter(remainingCompanies.length, editedPerimeter.companies.length, [
    ...authorizedCompanies.companies.map(item => item.id),
    ...initialPerimeter.companies.map(company => company.company.id)
  ]);

  const companyUpdates = editedPerimeter.companies;

  return (
    <>
      {editedPerimeter && <>
        <UserAccountPerimeterAccountSelect
          accountOptions={accountOptions}
          hasAccountPerimeter={authorizedCompanies.hasAccountPerimeter}
          editedPerimeter={editedPerimeter}
          initialPerimeter={initialPerimeter}
          allCompaniesAdded={allCompaniesAdded}
          authorizedCompaniesCount={authorizedCompanies.companies.length}
          onAddAllCompanies={onAddAllCompanies}
          onChangeAccount={onChangeAccount}
          onRemoveAddAllCompanies={onRemoveAddAllCompanies}
          onChangeAccountTypePerimeter={onChangeAccountTypePerimeter}
          onChangeQueryAccount={onChangeQueryAccount}
          validationError={perimetersErrors}
        />
        {companyUpdates.map((item, index) => {
          return <UserAccountCompanyPerimeterEdit
            key={item.company.uniqueKey}
            company={item}
            marginBottom={companyUpdates.length - 1 !== index ? 6 : 0}
            remainingCompanies={remainingCompanies}
            canDelete={companyUpdates.length !== 1}
            defaultMerchants={initialPerimeter.companies.find(_company => _company.company.id === item.company.id)?.merchants}
            authorizedMerchants={authorizedMerchantResponseByCompany[item.company.id] || { merchants: [] }}
            onChangeCompany={(e) => onChangeCompany(e, index)}
            onChangeMerchant={(e, hasAllMerchants) => onChangeMerchants(e, index, hasAllMerchants)}
            onFutureMerchantChange={(_e, checked) => onChangeCompanyTypePerimeter(checked, index)}
            allFutureCompaniesChecked={editedPerimeter.hasAccountPerimeter}
            onRemove={onRemoveCompany}
            validationError={perimeterUtils.errrorsToPerimeterErrors(item.company.uniqueKey, perimetersErrors)}
          />;
        }
        )}
      </>}
      {canAddPerimeter && <CircleRoundedButton onClick={onAddCompany} />}
    </>
  );
}
