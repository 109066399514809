import {styled} from "@mui/material/styles";
import {Switch, SwitchProps} from "@mui/material";
import {paletteTheme} from "../../themes/palette";

export default styled(Switch)<SwitchProps>(({theme}) => ({
    width: 28,
    height: 15,
    padding: 0,
    display: "flex",
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(13px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
            },
        },
        "&.Mui-disabled": {
            color: `${paletteTheme.colors.WHITE} !important`,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: "0.5 !important",
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 11,
        height: 11,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        })
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        boxShadow: "inset 0px 1px 2px rgba(0, 0, 0, 0.25)",
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },

}));
