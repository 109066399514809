import {PerimeterFilterValues} from "../../interfaces/transaction/transaction";
import {CompanyFilterOptions, Options, MerchantFilterOptions} from "../../constants/options/option";
import {arrayUtils} from "../../utils/common/arrayUtils";
import {filterUtils} from "../../utils/business/filterUtils";

interface FilterInstance {
    onChangeCountriesSelection: (selectedCountry: Array<Options>) => void,
    onChangeAccountsSelection: (selectedAccounts: Array<Options>) => void,
    onChangeCompaniesSelection: (selectedCompanies: Array<CompanyFilterOptions>) => void,
    onChangeMerchantsSelection: (selectedMerchants: Array<MerchantFilterOptions>) => void,
}

/**
 * On Change filter hook to get onChange functions easily on your pages
 * 
 * Delete child items selected when updating parent
 */
export function useOnChangeFilter<T extends PerimeterFilterValues>(
    filters?: T,
    onChangeFilters?: (filters: T) => void,
): FilterInstance {

    const onChangeCountriesSelection = (selectedCountries: Array<Options>) => {
        onChangeFilters({...filters, countries: selectedCountries})
    }

    const onChangeAccountsSelection = (selectedAccounts: Array<Options>) => {
        onChangeFilters({
            ...filters,
            accounts: selectedAccounts,
            merchants: {selectedOptions: [], all: false},
            companies: {selectedOptions: [], all: false}
        })
    }

    const onChangeCompaniesSelection = (selectedCompanies: Array<CompanyFilterOptions>) => {
        const values = filters.merchants.selectedOptions.filter(merchant => selectedCompanies.some(item => item.id === merchant.idCompany));
        const formattedCompanies = filterUtils.manageAllCheckbox(selectedCompanies, filters.companies.selectedOptions);
        onChangeFilters({
            ...filters,
            companies: formattedCompanies,
            merchants: {
                selectedOptions: values,
                all: arrayUtils.isEmpty(values)
            }
        })
    }

    const onChangeMerchantsSelection = (selectedMerchants: Array<MerchantFilterOptions>) => {
        const formattedMerchants = filterUtils.manageAllCheckbox(selectedMerchants, filters.merchants.selectedOptions);
        onChangeFilters({
            ...filters,
            merchants: formattedMerchants
        })
    }

    return {
        onChangeCountriesSelection,
        onChangeAccountsSelection,
        onChangeCompaniesSelection,
        onChangeMerchantsSelection,
    };
}
