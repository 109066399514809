import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import { StyledEngineProvider } from "@mui/styled-engine";
import cookie from "cookie";
import { LocalStorageEnum } from "./constants/localstorage/localstortage";
import { dateUtils } from "./utils/common/dateUtils";

// Check cookies for MERCHANT_PORTAL_ACCESS_TOKEN
// if such exists then update localStorage token with it value and delete cookie.
const cookies = cookie.parse(document.cookie);
const MERCHANT_PORTAL_ACCESS_TOKEN_COOKIE = "MERCHANT_PORTAL_ACCESS_TOKEN";
const token = cookies[MERCHANT_PORTAL_ACCESS_TOKEN_COOKIE];

if(token != null && token.length > 0){
  // we got token,
  // so we need to set it to local storage,
  const mepToken = {"token": token, "expired_at": dateUtils.getDateExpiration()};
  localStorage.setItem(LocalStorageEnum.TOKEN, JSON.stringify(mepToken));
  // and then delete (just in case)
  document.cookie = cookie.serialize(
    MERCHANT_PORTAL_ACCESS_TOKEN_COOKIE,
    "",
    {
      expires: new Date()
    }
  );
}

ReactDOM.render(
    <React.Fragment>
            <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <App/>
            </StyledEngineProvider>
            </BrowserRouter>
    </React.Fragment>,
    document.getElementById("root")
)
