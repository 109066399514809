import {styled} from "@mui/material/styles";
import {TextField, TextFieldProps} from "@mui/material";
import {paletteTheme} from "../../themes/palette";

export default styled(TextField)<TextFieldProps>(() => {
    return ({
        height: "24px",
        borderRadius: 0,
        border: "none",
        borderBottom: `1px solid ${paletteTheme.colors.GRAY}`,
        "&:Mui-hover": {
            borderRadius: 0, border: "none",
            borderBottom: `1px solid ${paletteTheme.colors.GRAY}`,
        },
        "& .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root": {
            height: "24px",
            border: "none",
        }
    });
});
