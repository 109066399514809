import { AppRouteInterface } from "interfaces/RouteInterfaces";
import { PERMISSIONS } from "constants/permissions/permissions";
import { ACQUIRED_TRANSACTIONS_PATH } from "constants/routes/RoutePaths";
import { AcquiredTransactionsView } from "view/TransactionView/AcquiredTrransactionsView";
import AcquiredDetailView from "view/TransactionView/AcquiredDetailView";

export const AcquiredTransactionRoutes: AppRouteInterface = {
  path: ACQUIRED_TRANSACTIONS_PATH,
  private: true,
  index: false,
  children: [
    {
      element: AcquiredTransactionsView,
      private: true,
      index: true,
      permission: PERMISSIONS.TRANSACTIONS_CONSULT,
    },
    {
      element: AcquiredDetailView,
      path: ":idTransaction",
      private: true,
      index: true,
      permission: PERMISSIONS.TRANSACTIONS_CONSULT,
    },
  ],
};
