import React, { FunctionComponent } from "react";
import ContainedButton from "components/atoms/button/ContainedButton";
import { FormattedMessage } from "react-intl";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ExportReferential } from "constants/export/export";
import {
  IosShare,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { paletteTheme } from "styles/themes/palette";

interface DropdownButtonProps {
  onDownload: (type: ExportReferential) => void,
  availableExports: Record<string, ExportReferential>,
  isExportLoading: boolean
}

export const DropdownButton: FunctionComponent<DropdownButtonProps> = ({ onDownload, isExportLoading, availableExports }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);
  const [offsetWidth, setOffsetWidth] = React.useState<number>(0);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOffsetWidth(event.currentTarget.offsetWidth)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ContainedButton
        startIcon={<IosShare fontSize="small" />}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        sx={open && {
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          backgroundColor: paletteTheme.colors.DARK_PRIMARY,
          "&:hover": {
            backgroundColor: paletteTheme.colors.LIGHT_PRIMARY,
          },
          "& .MuiButton-startIcon": {
            width: "20px"
          }
        }}
        loadingStart={isExportLoading}
        onClick={handleClick}>
        <FormattedMessage id="export_button" />
      </ContainedButton>
      <Menu
        disablePortal
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        sx={{
          "& .MuiPaper-root": {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0
          }
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            width: offsetWidth, padding: 0,
          }
        }}
      >
        {Object.values(availableExports).map(type =>
          <MenuItem
            sx={{
              color: paletteTheme.colors.WHITE, backgroundColor: paletteTheme.colors.DARK_PRIMARY,
              height: "28px",
              padding: "14px",
              "&:hover": {
                backgroundColor: paletteTheme.colors.DARK_INFO,
              }
            }}
            key={type.id}
            onClick={() => {
              onDownload(type)
              setOffsetWidth(anchorEl.offsetWidth)
            }}> <Box marginRight="6px">{type.Icon}</Box> {type.id}
          </MenuItem>)}
      </Menu>
    </>)
}
