import React, {FunctionComponent} from "react";
import CardPopup from "../../atoms/card/CardPopup";
import FlexBox from "../../atoms/flex/FlexBox";
import {FlexAttributValue} from "../../../types/flex/flex";
import {Dialog as DialogMaterialUI, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import OutlinedButton from "../../atoms/button/OutlinedButton";
import ContainedButton from "../../atoms/button/ContainedButton";
import {IntlMessage} from "../../../constants/intl/intl";
import AdvancedFormattedMessage from "../../atoms/intl/AdvancedFormattedMessage";
import DiscardButtonStyled from "../../../styles/components/button/DiscardButtonStyled";

export interface DialogProps {
    openDialog: boolean
    onCloseDialog: () => void
    onDiscard?: () => void
    handleSubmit: () => void
    dialogIcon: JSX.Element
    idMessage: string | IntlMessage
    idConfirmMessage: string
    submitMessage: string
}

const ConfirmDialog: FunctionComponent<DialogProps> = ({
                                                    openDialog,
                                                    onCloseDialog,
                                                    onDiscard,
                                                    handleSubmit,
                                                    dialogIcon,
                                                    idMessage,
                                                    idConfirmMessage,
                                                    submitMessage
                                                }) => {

    return (
        <DialogMaterialUI
            open={openDialog}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CardPopup sx={{width: "414px"}}>
                <FlexBox flexDirection={FlexAttributValue.COLUMN} justifyContent={FlexAttributValue.CENTER}
                         alignItems={FlexAttributValue.CENTER}>
                    <span className="position-relative">
                        {dialogIcon}
                    </span>
                    <Typography variant="h4" fontWeight="normal" marginTop={1}>
                        <AdvancedFormattedMessage title={idMessage}/>
                    </Typography>
                    <Typography variant="h4" fontWeight="normal" marginBottom={3}>
                        <FormattedMessage id={idConfirmMessage}/>
                    </Typography>
                </FlexBox>
                <FlexBox flexDirection={FlexAttributValue.ROW} justifyContent={FlexAttributValue.SPACE_BETWEEN}>
                    <OutlinedButton color="secondary" onClick={onCloseDialog}>
                        <FormattedMessage id="cancel"/>
                    </OutlinedButton>
                    <FlexBox flexDirection={FlexAttributValue.ROW}>
                        {
                            onDiscard &&
                            <DiscardButtonStyled sx={{marginRight: 1}} onClick={onDiscard}>
                                <FormattedMessage id="discard"/>
                            </DiscardButtonStyled>
                        }
                        <ContainedButton onClick={handleSubmit}>
                            <FormattedMessage id={submitMessage}/>
                        </ContainedButton>
                    </FlexBox>
                </FlexBox>

            </CardPopup>
        </DialogMaterialUI>
    )
}

export default ConfirmDialog;
