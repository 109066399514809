import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { RouteProps, useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { UPDATE_PASSWORD_ERRORS } from "constants/errors/updatePassword";
import { LoginPasswordInput } from "components/atoms/input/LoginPasswordInput";
import ContainedButton from "components/atoms/button/ContainedButton";
import InputRequirement from "components/atoms/requirement/InputRequirement";
import { resetPasswordTokenService } from "services/resetPasswordToken/ResetPasswordTokenService";
import { validationErrorsUtils } from "utils/business/validationErrorsUtils";
import { errorUtils, PromisableApiError } from "utils/api/errorUtils";
import { authUtils } from "utils/api/authUtils";
import { LOGIN_ERRORS } from "constants/errors/login";
import { UPDATE_PASSWORD_PATH } from "constants/routes/RoutePaths";
import { Requirement } from "interfaces/password/password";
import { passwordErrorUtils } from "utils/business/passwordErrorUtils";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { paletteTheme } from "styles/themes/palette";
import styles from "view/password/password.module.scss";

export const UpdatePasswordView: FunctionComponent<RouteProps> = () => {

    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const [disabled, setDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const location = useLocation();
    const token = new URLSearchParams(location.search).get("token");
    const type = new URLSearchParams(location.search).get("type");
    const isInit = type === "init";
    const navigate = useNavigate();
    const { smDown } = useMediaQueryUtil();

    const PASSWORD_REQUIREMENTS: Array<Requirement> = [
        { requirement: "password_lowercase", error: errors.PASSWORD_LOWERCASE },
        { requirement: "password_uppercase", error: errors.PASSWORD_UPPERCASE },
        { requirement: "password_number", error: errors.PASSWORD_NUMBER },
        { requirement: "password_special_character", error: errors.PASSWORD_SPECIAL_CHARACTER },
    ]

    const getLabel = (labelInit: string, labelReset: string) => {
        return isInit ? labelInit : labelReset;
    }
    const onChangeSetPasswordForm = (setter: (value: string) => void) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        setDisabled(false)
        setter(event.target.value)
        setValidated(true);
        const validationError = validationErrorsUtils.validatePasswordsForm(event.target.value, event.target.value);
        setErrors(validationError ?? {})
    }

    const onSubmit = () => {
        setValidated(true);
        setDisabled(true);
        const validationError = validationErrorsUtils.validatePasswordsForm(password, confirmPassword);
        if (validationError) {
            setErrors(validationError)
        } else {
            setLoading(true);
            resetPasswordTokenService.updatePassword(token, password, confirmPassword)
                .then(() => {
                    authUtils.cleanSession();
                    navigate(`/${UPDATE_PASSWORD_PATH}/confirm?type=${type}`);
                    setLoading(false);
                }).catch((e: PromisableApiError) => {
                    setLoading(false);
                    if (errorUtils.interceptError(e, "TOKEN_NOT_FOUND")) {
                        navigate(`/${UPDATE_PASSWORD_PATH}/confirm?type=${type}`);
                    } else {
                        errorUtils.handleBackErrors(e)
                        errorUtils.handleErrors(e, (apiError) => setErrors(validationErrorsUtils.cleanAndUpdate(apiError.errors)))
                    }
                })
        }
    }

    return (
        <Stack className={styles["stack-auth-layout"]}>

            <div className={"flex flex-col flex-col-center"} >
                <Typography variant="h1" fontWeight="bold" fontSize={16} color={paletteTheme.colors.PRIMARY}>
                    <FormattedMessage id={getLabel("init_pw_page_title", "set_password")} />
                </Typography>
            </div>

            <div className={styles["box-inputs"]} >
                <LoginPasswordInput
                    id={getLabel("password", "new_password_field")}
                    label={getLabel("password", "new_password_field")}
                    required
                    value={password}
                    validated={validated}
                    error={passwordErrorUtils.passwordFieldHasErrors(errors)}
                    possibleErrors={[LOGIN_ERRORS.PASSWORD_REQUIRED]}
                    onChange={onChangeSetPasswordForm(setPassword)}
                    inputProps={{ maxLength: 255 }}
                    LabelSx={{ fontSize: 14, fontWeight: 300 }}
                    errors={errors}
                >
                </LoginPasswordInput>

                <LoginPasswordInput
                    id={getLabel("init_pw_page_field2", "confirm_new_password_field")}
                    label={getLabel("init_pw_page_field2", "confirm_new_password_field")}
                    required
                    value={confirmPassword}
                    validated={validated}
                    validationPill={true}
                    error={passwordErrorUtils.passwordConfirmFieldHasErrors(errors)}
                    possibleErrors={[UPDATE_PASSWORD_ERRORS.CONFIRM_PASSWORD_REQUIRED, UPDATE_PASSWORD_ERRORS.UNMATCHED_PASSWORDS]}
                    onChange={onChangeSetPasswordForm(setConfirmPassword)}
                    inputProps={{ maxLength: 255 }}
                    LabelSx={{ fontSize: 14, fontWeight: 300 }}
                    errors={errors}>
                </LoginPasswordInput>
            </div>

            <div>
                <Typography variant="h4" fontWeight={300}>
                    <FormattedMessage id="password_requirements_label"></FormattedMessage>
                </Typography>
                <List sx={{ marginLeft: "6px" }}>
                    <ListItem sx={{ paddingY: 0 }}>
                        <InputRequirement
                            validated={validated}
                            error={errors.PASSWORD_MIN_LENGTH}
                            requirement="password_min_length"
                            bulletSize={8}>
                        </InputRequirement>
                    </ListItem>
                    <ListItem sx={{ paddingY: 0 }}>
                        <InputRequirement
                            validated={false}
                            error={false}
                            requirement="password_requirements_include_label"
                            bulletSize={8}>
                        </InputRequirement>
                    </ListItem>
                    <List sx={{ marginLeft: "12px" }}>
                        {PASSWORD_REQUIREMENTS.map(item => (
                            <ListItem key={item.requirement} sx={{ paddingY: 0 }}>
                                <InputRequirement
                                    validated={validated}
                                    error={item.error}
                                    requirement={item.requirement}
                                    bulletSize={6}>
                                </InputRequirement>
                            </ListItem>
                        ))
                        }
                    </List>
                </List>
            </div>

            <div className={"flex flex-col flex-col-center"}>
                <ContainedButton
                    disabled={disabled}
                    loading={loading}
                    type="button"
                    onClick={onSubmit}
                    style={
                        {
                            width: smDown ? "100%" : "auto",
                            height: smDown ? "40px" : "32px",
                        }
                    }
                >
                    <FormattedMessage id={getLabel("save", "reset_password_button")} />
                </ContainedButton>
            </div>
        </Stack>
    )
}
