import React, { FunctionComponent, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Language as LanguageIcon } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import LanguageMenuItemStyled from "styles/components/LanguageMenuItemStyled";
import { Languages } from "constants/messages/languages";
import { userService } from "services/user/UserService";
import { errorUtils } from "utils/api/errorUtils";
import { useAuthUser } from "hooks/useAuthUser";
import { languageUtils } from "utils/common/languageUtils";
import { HeaderCookie } from "components/molecules/header/Header";
import { ItemMenu } from "components/molecules/menu/SideMenu/ItemMenu";
import styles from "components/molecules/menu/LanguageDropdown/languageDropdown.module.scss";

interface LanguageDropdownMobileProps {
  mobile: boolean;
  onCloseMobileMenu: () => void;
  isReduced: boolean;
}
export const LanguageDropdownMobile: FunctionComponent<LanguageDropdownMobileProps> = ({ mobile, onCloseMobileMenu }) => {
  const { user, cleanAndRefreshUserDetails } = useAuthUser();
  const [cookies, setCookies] = useCookies<string, HeaderCookie>(["lang"]);
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsSubMenuOpen((prev) => !prev);
  };

  const handleOnChange = (event?: SelectChangeEvent<unknown>, id?: string) => {
    if (event || id) {
      // depending on the menu (mobile or desktop), use either id or event target value to set the new language
      const newLanguage = id ?? event.target.value;
      setCurrentLanguage(newLanguage.toString());
      if (mobile) {
        setIsSubMenuOpen(false);
        onCloseMobileMenu();
      }
      userService
        .updateSelfLanguage(newLanguage.toString())
        .then(() => {
          cleanAndRefreshUserDetails();
        })
        .catch(errorUtils.handleBackErrors);
    }
  };
  const setNewLangFromLocalStorage = () => {
    const newLanguage = languageUtils.getNavigatorLanguageFromUser(user, cookies.lang).toUpperCase();
    setCurrentLanguage(newLanguage);
  };

  useEffect(() => {
    if (user && !user?.language) {
      setNewLangFromLocalStorage();
      userService
        .updateSelfLanguage(currentLanguage)
        .then(() => {
          setCookies("lang", currentLanguage, { path: "/", secure: false, sameSite: "lax" });
        })
        .catch(errorUtils.handleBackErrors);
    }
  }, [user]);

  useEffect(() => {
    if (!currentLanguage) {
      setNewLangFromLocalStorage();
    }
  }, []);
  return (
    <div className={styles["language-select-mobile"]}>
      <div role="button" onClick={() => handleMenuClick()} className={` ${isSubMenuOpen ? "is-open active" : ""} lang-btn`}>
        <ItemMenu Icon={LanguageIcon} label="" isReduced={false} className="parent" />
      </div>
      {isSubMenuOpen && (
        <>
          {Object.values(Languages).map((lang) => (
            <LanguageMenuItemStyled
              sx={{ fontWeight: "bold" }}
              key={lang.id}
              value={lang.id}
              onClick={() => {
                handleOnChange(undefined, lang.id);
              }}
              className={`${currentLanguage === lang.id ? styles.selected : ""}`}
            >
              <FormattedMessage id={lang.label} />
            </LanguageMenuItemStyled>
          ))}
        </>
      )}
    </div>
  );
};
