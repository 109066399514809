import React, {
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { Switch } from "components/atoms/switch/Switch";
import { DropdownStyled } from "components/atoms/select/DropdownStyled";
import { SelectChangeEvent } from "@mui/material";
import { CardRow } from "components/atoms/card/CardRow";
import { UserAccountPayWishUser } from "components/molecules/useraccount/details/UserAccountPayWishUser";

const PayWishUserOptions = {
  PAYWISH_ADMIN: {
    id: "ADMIN",
    name: "paywish_admin",
  },
  PAYWISH_USER: {
    id: "USER",
    name: "paywish_user",
  },
}

interface PayWishUserEditProps {
  onChangePaywishUserRole: (value: string) => void;
  onChangePaywishMerchant: (value: string) => void;
  createUser: boolean;
  paywishRole: string;
  onTogglePaywishUser: (checked: boolean) => void;
  paywishMerchants: { id: string, name: string }[];
}

export const UserAccountPayWishUserEdit: FunctionComponent<PayWishUserEditProps> = ({
  onChangePaywishUserRole,
  onChangePaywishMerchant,
  createUser,
  paywishRole,
  onTogglePaywishUser,
  paywishMerchants
}) => {

  const [selectedUserType, setSelectedUserType] = useState(PayWishUserOptions.PAYWISH_ADMIN.id);
  const [selectedPaywishMerchant, setSelectedPaywisMerchant] = useState<string>(paywishMerchants.at(0).id);
  const [allowUserSelect, setAllowUserSelect] = useState(false);

  const handleUserTypeChange = (e: SelectChangeEvent) => {
    setSelectedUserType(e.target.value);
    onChangePaywishUserRole(e.target.value)
  }

  const handlePaywishMerchantChange = (e: SelectChangeEvent) => {
    setSelectedPaywisMerchant(e.target.value);
    onChangePaywishMerchant(e.target.value)
  }

  const handleUserSwitch = (checked: boolean) => {
    setAllowUserSelect(prev => !prev);
    onTogglePaywishUser(checked);
  }

  useEffect(() => {
    if (allowUserSelect) {
      onChangePaywishUserRole(selectedUserType);
      onChangePaywishMerchant(selectedPaywishMerchant)
    }
  }, [allowUserSelect])

  useEffect(() => {
    if (paywishMerchants.some(item => item.id !== selectedPaywishMerchant)) {
      setSelectedPaywisMerchant(paywishMerchants.at(0).id);
      onChangePaywishMerchant(paywishMerchants.at(0).id);
    }
  }, [paywishMerchants])


  return (
    <>

      {createUser ?
        <>
          <CardRow
            marginBottom={1}
            id="paywish_user_subtitle"
            label="paywish_user_subtitle"
            customLabelClassName="flex-3"
            customContentClassName="switch"
          >
            <Switch
              checked={allowUserSelect}
              onChange={(e, checked) => { handleUserSwitch(checked) }}
            />
          </CardRow>
          <CardRow marginBottom={1} id="paywish_pw_merchant" label="paywish_pw_merchant">
            <DropdownStyled
              selectedValue={selectedPaywishMerchant}
              options={paywishMerchants}
              onChange={(event: SelectChangeEvent) => { handlePaywishMerchantChange(event) }}
              placeholderId="paywish_select_merchant"
              disabled={!allowUserSelect}
            />
          </CardRow>
          <CardRow marginBottom={1} id="paywish_usertype" label="paywish_usertype">
            <DropdownStyled
              selectedValue={selectedUserType}
              options={[
                PayWishUserOptions.PAYWISH_ADMIN,
                // MPT-1071 - remove Paywish user option until further notice
                // PayWishUserOptions.PAYWISH_USER
              ]}
              onChange={(event: SelectChangeEvent) => { handleUserTypeChange(event) }}
              placeholderId="paywish_select_usertype"
              disabled={!allowUserSelect}
            />
          </CardRow>
        </> :
        <UserAccountPayWishUser paywishRole={paywishRole} />
      }
    </>
  );
}
