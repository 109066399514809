import { PerimeterFilterValues } from "interfaces/transaction/transaction";
import {
  BlobWithFilename,
  httpService,
} from "services/HttpService";
import {
  SETTLEMENT_EXPORT_SETTLEMENTS_PDF_ROUTE,
  SETTLEMENT_EXPORT_SETTLEMENTS_ROUTE,
  SETTLEMENT_GET_SETTLEMENT_HISTOGRAM_ROUTE,
  SETTLEMENT_GET_SETTLEMENTS_ROUTE,
} from "constants/routes/ApiRoutes";
import { QueryParam } from "interfaces/UserInterface";
import { queryUtils } from "utils/api/queryUtils";
import { SettlementResponse } from "interfaces/settlement/settlement";
import { SearchResponse } from "interfaces/api/ApiInterfaces";
import { FilterValues } from "interfaces/filter/filter";
import { settlementUtils } from "utils/business/settlementUtils";
import { SettlementHistogramResponse } from "interfaces/histogram/histogram";
import { HistogramUtils } from "utils/business/histogramUtils";
import { Temporality } from "types/temporality";

const buildFilters = (defaultFilters: FilterValues): string => {
  const searchParams: Record<string, string> = settlementUtils.computeFilters(defaultFilters);
  const params = new URLSearchParams({ ...searchParams })
  return `&${queryUtils.filterURLSearchParams(params).toString()}`
}

const buildPdfFilters = (defaultFilters: FilterValues, locale: string): string => {
  const searchParams: Record<string, string> = settlementUtils.computePdfExportFilters(defaultFilters, locale);
  const params = new URLSearchParams({ ...searchParams })
  return `&${queryUtils.filterURLSearchParams(params).toString()}`
}

const getSettlements = (defaultFilters: FilterValues) => ({
  page,
  pageSize,
  sortDirection,
  sortField
}: QueryParam): Promise<SearchResponse<SettlementResponse>> => {

  const url = `${SETTLEMENT_GET_SETTLEMENTS_ROUTE}${queryUtils.queryParamToPageable({
    page,
    pageSize,
    sortDirection,
    sortField
  })}&${buildFilters(defaultFilters)}`;
  return httpService.get(url);
}

const downloadExportSettlements = (
  defaultFilters: FilterValues,
  type: string,
  options?: RequestInit,
  locale?: string,
): Promise<BlobWithFilename> => {
  const url = `${SETTLEMENT_EXPORT_SETTLEMENTS_ROUTE}?${buildFilters(defaultFilters)}`;
  const urlPDF = `${SETTLEMENT_EXPORT_SETTLEMENTS_PDF_ROUTE}?${buildPdfFilters(defaultFilters, locale)}`;
  const formattedUrl = type === "PDF" ? urlPDF : url;
  return httpService.getReadableStreamWithFilename(formattedUrl, options);
}

const getSettlementsHistogram = (perimeterFilters: PerimeterFilterValues, temporality: Temporality, options?: RequestInit): Promise<SettlementHistogramResponse> => {
  const url = `${SETTLEMENT_GET_SETTLEMENT_HISTOGRAM_ROUTE}?${HistogramUtils.buildHistogramFilters(perimeterFilters, temporality)}`;
  return httpService.get(url, options);
}

export const settlementService = {
  getSettlements,
  downloadExportSettlements,
  getSettlementsHistogram,
}
