/**
 * File listing every translated label in Dutch
 */

export const DutchMessages = {

  // LOGIN PAGE
  welcome: "Welkom op de",
  merchant_portal: "MERCHANT PORTAL!",
  email: "E-mailadres ",
  password: "Wachtwoord",
  email_example: "email@email.com",
  login_button: "Login",
  forgot_password_cta: "Wachtwoord vergeten?",
  logo_alt: "MarketPay logo",

  // LOGIN ERROR MESSAGES
  email_wrong_format: "Verifieer het formaat van uw e-mailadres",
  email_required: "E--mail is verplicht",
  password_required: "E--mail is verplicht",
  user_unknown: "Het e-mailadres is onbekend",
  invalid_password: "Wachtwoordwoord en/of e-mailadres zijn niet correct. Probeer opnieuw of reset uw wachtwoord.",
  password_pending: "De creatie van uw account is niet compleet. Klik op de link die u ontvangen heeft per e-mail om uw wachtwoord in te stellen.",
  blocked_user: "Uw account is geblokkeerd. Contacteer support voor meer informatie.",

  // RESET PASSWORD VIEW
  pw_recovery_title: "Recupereer wachtwoord",
  reset_password_text: "Om uw e--mailadres te resetten, vul uw e-mailadres in en we sturen u een e-mail om uw wachtwoord opnieuw in te stellen.",
  submit_button: "Verzenden",

  // RESET PASSWORD CONFIRM VIEW
  pw_confirm_title: "Uw wachtwoord is aangevraagd",
  pw_recovery_confirmation_body: "We hebben u juist een link gestuurd om uw wachtwoord te resetten. als u deze e-mail net heeft ontvangen, verifeer uw spam folder van het e-mailadres dat u heeft opgegeven.",
  pw_recovery_confirmation_not_sent: "Als u de e-mail om het wachtwoord te resetten niet hebt ontvangen, controleer dan de spam- en junkfilters van uw e-mailaccount.",
  pw_confirm_your_email: "Uw e-mail : {email}",
  pw_resend_init_password_mail: "Uw wachtwoord is nog niet geïnitialiseerd. We sturen de ingestelde wachtwoord-e-mail terug naar het adres dat u hebt opgegeven",

  // CHANGE PASSWORD
  change_password_sent_email: "Een link om uw wachtwoord te resetten is verstuurd naar uw e-mailadres",
  change_password_title: "Verander wachtwoord",
  change_password_reset_password: "Wachtwoord opnieuw instellen",
  change_password_send_email: "Klik op de knop hieronder om een reset e-mail te ontvangen op het e-mailadres",
  change_password_button: "Reset mijn wachtwoord",

  // UPDATE PASSWORD VIEW

  // INIT PASSWORD
  init_pw_page_title: "Kies een wachtwoord",
  init_pw_page_field2: "Bevestiging",
  init_pw_page2_text: "Uw wachtwoord is succesvol ingesteld!",

  // UPDATE PASSWORD
  set_password: "Stel nieuw wachtwoord in",
  new_password_field: "Nieuw wachtwoord",
  confirm_new_password_field: "Bevestig wachtwoord",
  password_requirements_label: "Om uw account te beveiligen, verifieer uw wachtwoord : ", // password_instructions
  password_requirements_include_label: "bevat minstens :  ", // password_instructions
  password_min_length: "minstens 8 tekens", // password_instructions
  reset_password_button: "Reset uw wachtwoord",
  unmatched_passwords: "Wachtwoord bevestiging moet identiek zijn aan het wachtwoord",
  password_lowercase: "een hoofdletter,", // password_instructions
  password_uppercase: "een kleine letter,", // password_instructions
  password_number: "een nummer,", // password_instructions
  password_special_character: "en een speciaal symbool (~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/)", // password_instructions
  confirm_password_required: "Bevestig wachtwoord is vereist",
  reset_password_confirmation: "Uw wachtwoord is succesvol gewijzigd",
  back_login: "Terug naar log in pagina",
  send_feedback: "Feedback sturen",

  // SIDE MENU
  menu_home: "Dashboard",
  menu_transactions: "Transacties",
  menu_accepted: "Geaccepteerd",
  menu_acquired: "Verworven",
  menu_settlements: "Uitbetalingen",
  menu_portfolio: "Portfolio",
  menu_users: "Gebruikers",
  menu_my_account: "Mijn account",
  menu_my_reports: "Mijn rapporten",
  menu_password: "Wachtwoord",
  log_out: "Log uit",
  menu_notification_settings: "Mijn meldingen",
  menu_language: "Taal",

  // PROFILES
  merchant_admin: "Admin Handelaar",
  market_pay_admin: "Admin Market Pay",
  operator: "Exploitant",
  profil_spring: "SPRING",

  // ACCOUNT PAGE

  // PERSONAL INFORMATION
  account_my_account: "Mijn account",
  account_user: "Gebruiker {id} : {firstName} {lastName}",
  create_user_title: "Creeer een nieuwe gebruiker",
  pers_info: "Persoonlijke informatie",
  first_name: "Voornaam",
  last_name: "Naam",
  creation_date: "Datum van creatie",
  last_activity: "Laatste activiteit",
  account_email: "E-mail",
  firstname_required: "Voornaam is verplicht",
  firstname_wrong_format: "Je voornaam mag alleen letters, spaties en streepjes bevatten", // name_rule
  lastname_required: "Achternaam is verplicht",
  lastname_wrong_format: "Je achternaam mag alleen letters, spaties en streepjes bevatten", // name_rule
  email_alr_used: "Dit e-mailadres is al gekoppeld aan een bestaand account",
  distributor: "Distributeur",
  distributor_toggle: "Weergave distributeur",

  // TYPE
  account_account: "Account",
  account_type: "Account type",

  // PERIMETER
  level_col: "Niveau",
  peri: "Perimeter",
  title_paywish: "PayWish Account",
  permissions: "Rechten",
  peri_account: "Organisatie",
  perimeter_group: "Groep",
  peri_company: "Bedrijf",
  peri_merchant: "Winkel",
  edit_button: "Modifieer",
  delete: "Verwijderen",
  account_all_perimeter: "Alle accounts",
  account_no_perimeter: "Geen",
  save_changes: "Veranderingen opslaan",
  discard_changes: "Verandereringen ongedaan maken",
  account_updated: "Uw veranderingen zijn opgeslagen",
  account_tooltip_remove: "Verwijder dit bedrijf van uw perimeter",
  account_tooltip_reset: "Reset uw keuze",
  add_company: "Voeg een bedrijf toe",
  no_results: "Geen resultaat",
  add_all: "Alles toevoegen",
  required_account: "Verplicht veld",
  required_company: "Verplicht veld",
  required_merchants: "Verplicht veld",
  inherit_future_merchants: "Toekomstige winkels ",
  inherit_future_companies: "Toekomstige bedrijven",
  account_add_all_companies: "Voeg alle bedrijven toe",
  paywish_user_subtitle: "PayWish Gebruiker",
  paywish_usertype: "Type",
  paywish_admin: "PayWish Admin",
  paywish_user: "PayWish User",
  paywish_select_usertype: "Selecteer type",
  paywish_select_merchant: "Selecteer handelaren",
  paywish_pw_merchant: "PayWish handelaren",
  paywish_user_success_msg: "Een PayWish gebruiker is succesvol aangemaakt",
  paywish_user_error_msg: "Er is een technische fout opgetreden. Het was op dit moment niet mogelijk om een gebruiker aan te maken. Probeer het later nog eens.",
  paywish_user_error_duplicate: "Er bestaat al een PayWish gebruiker met dit e-mailadres",

  // TRANSACTIONS
  permission_transactions: "Transacties",
  permission_approve_ref: "Een terugbetalingsverzoek goedkeuren",
  permission_view_acc_acq_tx: "Bekijk geaccepteerde en overgenomen transacties",
  permission_trig_two_steps_ref: "Activeer een terugbetaling in 2 stappen",
  permission_trig_direct_ref: "Activeer een directe terugbetaling",

  // SETTLEMENTS
  permission_category_settlements: "Uitbetalingen",
  permission_view_settlements: "Bekijk de lijst van uitbetalingen",
  settlements_view_acq_transactions: "Bekijk verworven transacties",

  // USERS
  permission_users_man: "Beheer van de gebruikers",
  permission_add_user: "Creeer een nieuwe gebruiker van de Merchant Portal",
  permission_del_user: "Verwijder een gebruiker",
  permission_user_update: "Een gebruikersaccount bijwerken",
  permission_export_users: "Exporteer gebuikerslijst",

  // PORTFOLIO
  portfolio: "Portfolio",
  permission_category_portfolio: "Portfolio",
  permission_portfolio: "Toegang tot weergave Portfolio",
  forbiddenLabel: "Je hebt niet de vereiste rechten om deze pagina te openen",
  my_portfolio: "Mijn Portfolio",
  title_volume_trends: "Volumetrends",
  portf_title_billing: "Facturering",
  portf_title_top_bottom: "Boven/Beneden",
  portf_col_day: "Dag %",
  portf_col_week: "Week %",
  portf_col_month: "Maand %",
  portf_merchants: "Handelaren",
  portf_companies: "Bedrijven",
  portf_dist_com: "Commissie Distributeur",
  portf_tx_err: "Transactie fouten",
  switch_merchant: "Handelaar",
  switch_company: "Bedrijf",
  portf_sort: "Sorteren per",
  portf_col_nbr_tx: "Aantal transacties",
  portf_col_gross_sum: "Bruto bedrag",
  portf_col_success_rate: "Succesratio",
  portf_col_dist_com: "Commissie Distributeur",
  portf_col_avg_com_rate: "Gemiddelde commissie",
  portf_col_mp_com: "Market Pay commissie",
  portf_col_total_commission: "Totale commissie",
  portf_col_total_fees: "Totaal vergoedingen",
  rank: "#",
  portf_filter_display_trend_by: "%-waarden weergeven op basis van:",
  portf_tooltip_nbr_tx: "Aantal weergegeven transacties zijn gebaseerd op sorteren in kolommen",
  portf_tooltip_gross: "Brutobedrag wordt weergegeven op basis van kolomsortering",
  portf_col_day_tooltip: "Sorteer op deze kolom om transactiegegevens van gisteren weer te geven",
  portf_col_week_tooltip: "Sorteer op deze kolom om transactiegegevens van de laatste 7 dagen weer te geven",
  portf_col_month_tooltip: "Sorteer op deze kolom om transactiegegevens van de laatste maand weer te geven",
  portf_tooltip_tx_success_rate: "Transactie succespercentage staat voor het percentage succesvol geaccepteerde transacties ten opzichte van het totale aantal transacties in het zoekresultaat.",
  portf_tooltip_distributor_commission: "Distributiecommissie staat voor het deel van de commissie van Market Pay dat namens de distributeur wordt geïnd.",
  portf_tooltip_mp_commission: "Market Pay commissie vertegenwoordigt zowel het bedrag van de commissie verzameld door Market Pay, als het bedrag van de commissie verzameld namens de distributeur.",
  portf_tooltip_total_commission: "Totale commissie staat voor het totale bedrag aan Market Pay commissies, scheme fee commissies en interchange commissies ",
  portf_tooltip_avg_commission_rate: "Gemiddelde commissie staat voor de gemiddelde commissie van Market Pay over alle transacties in het resultaat.",
  tooltip_daily_evolution: "Dagelijkse evolutie",
  tooltip_weekly_evolution: "Wekelijkse evolutie",
  tooltip_monthly_evolution: "Maandelijkse evolutie",
  tooltip_nb_transaction: "Aantal transacties",
  tooltip_gross_sum: "Bruto bedrag",
  // REPORTS PAGE
  my_reports_title: "Mijn rapporten",
  reports_table_report_name_col: "Naam",
  reports_table_latest_edition_col: "Laatste editie",
  reports_table_frequency_col: "Frequentie",
  reports_table_frequency_col_daily: "Dagelijks",
  reports_table_frequency_col_weekly: "Wekelijks",
  reports_table_frequency_col_monthly: "Maandelijks",
  reports_table_latest_report_col: "Laatste rapport",
  reports_table_download_button: "Download",
  reports_table_delete: "Verwijder",
  reports_table_delete_message: "Weet je zeker dat je {name} wilt verwijderen?",
  reports_table_delete_confirm: "Verwijder",
  reports_table_delete_cancel: "Annuleer",

  // DOCUMENTS PAGE
  doc_menu: "Documenten",
  doc_title: "Documenten",
  doc_header: "Download je maandelijkse kostenoverzicht",
  doc_settlement_year: "Jaar",
  doc_settlement_month: "Maand",
  doc_rafec_download: "Download",

  // USERS PAGE
  users_acc_col_all: "Alle",
  users_acc_col: "Account",
  users_search: "Zoeken",
  users_search_bar: "Zoek op voornaam , naam of e-mailadres",
  add_user_button: "Toevoegen",
  user_not_found: "Gebruiker onvindbaar",
  acc_creation_conf_message: "Een nieuw account is succesvol gecreëerd. Er is een e-mail aan {email} u verstuurd om uw wachtwoord in te stellen.",
  back_user_list: "Terug naar gebruikerslijst",
  create_user_button: "Creëer  gebruiker",
  trigger_welcome_email: "Verstuur welkom e-mail",
  welcome_email_header: "E-mail",
  welcome_email_sent: "Welkom e-mail verstuurd",
  welc_email_butt_error: "Oeps, we zijn momenteel niet in staat deze e-mail te versturen, probeer het later nog eens.",

  // LANGUAGE
  language_en: "EN",
  language_fr: "FR",
  language_it: "IT",
  language_es: "SP",
  language_nl: "NL",
  language_pt: "PT",

  // LOADER
  loader_search_results: "Resultaat van de opzoeking...",

  // TABLE
  number_element: "{displayedCount} van {totalCount}",
  show_more: "Meer {pageSize} tonen",
  no_data_found: "Oeps, uw zoekopdracht leverde geen resultaten op",
  fetch_error: "Oeps,... het lijkt erop dat er iets fout is gegaan.",
  table_label: "Uw zoekopdracht heeft opgeleverd {count, plural, =0 {0 results} one {1 result} other {{count} results}}",
  query_results: "Uw zoekopdracht heeft opgeleverd",
  query_results_return: " {count, plural, one {result} other {results}}:",
  query_results_no_results: "Geen resultaten",
  export_csv_button: "Export CSV",
  export_button: "Exporteer",
  sr_schedule_button: "Plan een report",
  sr_pop_up_title: "Plan een report",
  sr_pop_up_report_name_label: "Naam report",
  sr_pop_up_report_name_placeholder: "Wekelijks extract van VISA transacties",
  sr_pop_up_frequency_label: "Frequentie",
  sr_pop_up_frequency_placeholder: "Selecteer",
  sr_pop_up_close_button: "Sluiten",
  sr_pop_up_save_button: "Opslaan",
  sr_frequency_monthly: "Maandelijks",
  sr_frequency_weekly: "Wekelijks",
  sr_frequency_daily: "Dagelijks",
  sr_frequency_monthly_disclaimer: "Een nieuw report inclusief de data van vorige maand zal voor u gegenereerd worden op de eerste dag van iedere maand",
  sr_frequency_weekly_disclaimer: "Een nieuw report inclusief de data van vorige week zal voor u gegenereerd worden op de maandagmorgen ",
  sr_frequency_daily_disclaimer: "Een nieuw report inclusief de data van gisteren zal voor u elke dag gegenereerd worden",
  sr_conf_1_message: "U staat op het punt een {frequency} naam report {reportName} te genereren",
  sr_conf_1_message_confirm: "Bevestigt u?",
  sr_conf_1_cancel_button: "Annuleer",
  sr_conf_1_confirm_button: "Opslaan",
  sr_conf_2_message_1: "Uw report is gepland.",
  sr_conf_2_message_2: "Bezoek {myReportsLink} om al uw rapporteringen te raadplegen",
  sr_conf_2_message_link: "Mijn rapporteringen",
  sr_conf_2_button: "OK",
  sr_error_empty_name: "Geef het rapport een naam.",
  sr_error_existing_name: "Deze naam is al in gebruik. Kies een andere naam.",
  sr_error_empty_frequency: "Kies de frequentie van uw rapport.",
  sr_error_name_too_short: "De naam moet minstens 3 tekens lang zijn.",
  sr_saving_error: "Je rapport kan momenteel niet worden opgeslagen vanwege een technische fout. Probeer het later nog eens.",
  export_limit_message_no_number: "Het rapport dat je probeert te downloaden is te groot. Voer een beperktere zoekopdracht uit en probeer het opnieuw.",
  toggle_sorting: "Klik om te sorteren",
  no_reports_scheduled: "Er zijn geen rapporten gepland.",

  // ACTIVITY
  activity_years_ago: "{interval} jaar geleden",
  activity_month_ago: "{interval, plural, one {1 maand} other {{interval} maanden}} geleden",
  activity_day_ago: "{interval, plural, one {1 dag} other {{interval} dagen}} ago",
  activity_today: "Vandaag",
  activity_yesterday: "Gisteren",
  activity_never: " ",

  // PERMISSIONS FORBIDDEN
  not_allowed_to_create_user: "U bent niet geautoriseerd om gebruikers aan te maken",
  not_allowed_to_delete_user: "U bent niet geautoriseerd om gebruikers te verwijderen",
  not_allowed_to_get_user: "U bent niet geautoriseerd om gebruikers te consulteren",

  // DISCARD POPUP
  toast_discard_change: "Uw wijzigingen zijn verworpen.",
  unsaved_ch_err_mess: "Uw wijzigingen kunnen verloren gaan.",
  unsaved_ch_err_mess_save: "Wilt u ze opslaan?",
  discard: "Verwijder",
  cancel: " Annuleer",
  ok: "Ok",
  save: "Opslaan",

  // DELETE POPUP
  delete_account_title: "Verwijder account: {email}",
  delete_account_dialog_box: "Bent u zeker dat u deze account wil verwijderen?",
  delete_account_success: "Gebruiker verwijderd",

  // MONTHS
  january: "Januari",
  february: "Februari",
  march: "Maart",
  april: "April",
  may: "Mei",
  june: "Juni",
  july: "Juli",
  august: "Augustus",
  september: "September",
  october: "Oktober",
  november: "November",
  december: "December",

  //AMOUNT RANGE FILTER
  min_sup_max: "Het minimumbedrag moest gelijk of minder zijn dan het maximumbedrag",
  negative_value: "Waarden moeten gelijk zijn aan of groter dan 0",
  card_transactions: "Transacties",
  card_trans_gross_amount: "Totaal bruto bedrag",
  card_trans_net_amount: "Totaal netto bedrag",
  card_trans_refunds_amount: "Terugbetalingen",
  card_trans_fees_amount: "Commissie",

  //TRANSACTION PAGE
  transactions_title: "Transacties",
  acc_transactions_title: "Geaccepteerde transacties",
  filter_country: "Land",
  filter_account: "Account",
  filter_company: "Bedrijf",
  filter_merchant: "Winkel",
  filter_operation_date: "Datum van acceptatie",
  search: "Zoeken",
  filter_by: "Filter op",
  admin_trans_view_title: "Pas minimaal 1 filter toe om de transacties te zien",
  admin_trans_view_text: "Filter per land, account, bedrijf, winkel of dag van betaling om uw zoekopdracht te verfijnen",
  export_limit_message: "Je kunt niet meer dan 50.000 transacties in een bestand exporteren.",
  filter_search_button_toaster: "Kies een account om uw zoekopdracht te starten",
  col_man_err_message: "U kunt niet minder dan 5 kolommen in de tabel weergeven. Selecteer een ander veld voordat u deze deselecteert.",

  // ADDITIONAL FILTERS
  more_filters: "Meer filters",
  less_filters: "Minder filters",
  order_number: "Bestelnummer",
  payment_solution: "Betaaloplossing",
  payment_type: "Type kaart",
  checkout_type: "Type product",
  settlement_status: "Status uitbetaling ",
  settlement_date: "Datum uitbetaling",
  total_fees: "Commissie",
  trans_authorization: "Toestemming",
  trans_cancellation: "Annulatie",
  trans_capture: "Vastlegging",
  trans_settled: "Uitbetaald",
  trans_refund: "Tereugbetaald",
  trans_refund_request: "Terugbetaling aangevraagd",
  event_success: "Succesvol",
  event_declined: "Geweigerd",
  event_error: "Foutmelding",
  solution_pay_online: "PayOnline",
  solution_pay_wish: "PayWish",
  solution_pay_onsite: "PayOnsite",
  type_debit: "Debit",
  type_credit: "Credit",
  checkout_contactless: "Contactless",
  checkout_online: "Online",
  checkout_insertion: "Invoegen",
  checkout_magstripe: "Magneetstreep",
  financing_paid: "Uitbetaald",
  financing_pending: "In afwachting",
  batch_id: "Batch ID",
  scheme: "Scheme",
  filter_remittance: "Remittance",
  filter_service_type: "Soort dienst",
  filter_collection_type: "Soort collectie",

  // POPUP COLUMNS
  col_man_button: "Kolommen beheren",
  col_man_title: "Kolommen zichtbaar maken",
  col_man_apply: "Toepassen",
  col_man_discard: "Verwijder",
  col_man_titles_acceptation: "Acceptatie",
  col_man_titles_last_event: "Laatste event",
  col_man_titles_transaction: "Transactie",
  col_man_titles_settlement: "Uitbetaling",
  col_man_titles_customer: "Klant",
  col_man_titles_merchant: "Handelaar",
  col_man_checkout_type: "Type product",
  col_man_payment_method: "Betalingswijze",
  col_man_payment_solution: "Betalingsoplossing",
  col_man_payment_type: "Type betaling",
  col_man_authorisation_code: "Authorisatiecode",
  col_man_pos_id: "POS ID",
  col_man_pos_name: "Naam POS",
  col_man_bank_acc_dom_country: "Woonplaats",
  col_man_card_iss_bank: "Uitgevende bank",
  col_man_card_iss_coun: "Uitgevend Land",
  col_man_card_level: "Niveau kaart",
  col_man_card_type: "Type kaart",
  col_man_pan_first_six: "BIN",
  col_man_pan_last_four: "Laatste 4 cijfers",
  col_man_scheme: "Scheme",
  col_man_op_date: "Datum acceptatie",
  col_man_op_time: "Uur acceptatie",
  col_man_currency: "Munteenheid",
  col_man_gross_amount: "Bruto bedrag",
  col_man_net_amount: "Nettobedrag",
  col_man_merch_trans_id: "Handelaar Transactie ID",
  col_man_order_num: "Bestelnummer",
  col_man_trans_type: "Type transactie",
  col_man_trans_event: "Event",
  col_man_status: "Status",
  col_man_trans_event_id: "Event ID",
  col_man_root_trans_event_id: "Transactie ID",
  col_man_event_timestamp: "Datum en uur event",
  col_man_acquirer: "Aquirer",
  col_man_settlement_batch: "ID-uitbetaling",
  col_man_settlement_date: "Datum uitbetaling",
  col_man_settlement_status: "Status uitbetaling",
  col_man_interchange: "Interchange",
  col_man_intermediate_fees: "Kosten tussenpersoon",
  col_man_mkp_fees: "Kosten Market Pay",
  col_man_scheme_fees: "Kosten Scheme",
  col_man_total_fees: "Totale commissie",
  col_man_account_name: "Account",
  col_man_company_name: "Bedrijf",
  col_man_merchant_name: "Handelaar Naam",
  col_man_country: "Land",
  col_man_contract_num: "Nummer contract",
  col_man_reset_button: "Terug naar standaardinstellingen",
  col_man_titles_fee_calc: "Kostenberekening",
  col_man_titles_fee_amount: "Bedrag aan kosten",
  col_settlement_month: "Maand van uitbetaling",
  col_imposta_di_bollo: "Imposta di bollo",
  category_distribution: "Distributie",
  col_retrocession: "Retrocessie",
  distrib_share_margin_amt: "Marge aandeel distributeur",
  distrib_share_margin_rate: "Percentage marge aandeel distributeur",
  mp_share_margin_amt: "Market Pay marge aandeel",
  mp_share_margin_rate: "Percentage marge Market Pay",
  col_operation_time_utc: "Uur van operatie (UTC)",
  col_operation_time_local: "Uur van operatie (Lokaal)",

  // COUNTRIES
  country_filter_fr: "Frankrijk",
  country_filter_sp: "Spanje",
  country_filter_nl: "Nederland",
  country_filter_pt: "Portugal",
  country_filter_it: "Italië",
  country_filter_bel: "België",
  country_filter_ger: "Duitsland",

  // LOADING
  loading: "Lading...",

  // SETTLEMENTS
  card_settlements_gross_am: "Bruto bedrag",
  card_settlements_comm: "Commissie",
  card_settlements_fin_am: "Uitbetaald bedrag",
  settled_by_mp: "Uitbetaald door Market Pay",
  filter_settlement_date: "Datum uitbetaling",

  // HOMEPAGE
  good_morning: "Hallo,",
  good_evening: "Goedeavond,",
  homepage_today: "Vandaag",

  // GRAPH

  // COMMON
  homepage_graph_month: "Maand",
  homepage_graph_week: "Week",
  homepage_graph_day: "Dag",
  week: "Week",
  graph_tooltip_number: "Nummer",
  graph_tooltip_amount: "Bedrag",
  graph_tooltip_week_leg: "W",
  graph_dropdown_interval: "Intervaltijd",
  graph_dropdown_amount: "Type bedrag",

  // TRANSACTIONS
  homepage_graph_leg_gross: "Bruto bedrag",
  homepage_graph_leg_net: "Netto bedrag",
  homepage_graph_leg_num: "Aantal transacties",
  homepage_graph_leg_am: "Bedrag (€)",
  graph_trans_hp_bot_leg_num: "Aantal transacties",
  graph_trans_hp_bot_leg_am: "Bedrag (€)",
  graph_transactions: "Transacties",

  // SETTLEMENTS
  graph_hp_sett_filter_value_1: "Uitbetaald",
  graph_hp_sett_filter_value_2: "Commissie",
  graph_hp_sett_leg_num: "Aantal uitbetalingen",
  graph_hp_sett_leg_am: "Bedrag (€)",
  graph_hp_bot_sett_leg_num: "Aantal uitbetalingen",
  graph_hp_bot_sett_leg_am: "Bedrag (€)",
  graph_settlements: "Uitbetaling",

  // MONTHS
  january_abr: "Jan",
  february_abr: "Feb",
  march_abr: "Ma",
  april_abr: "Apr",
  may_abr: "Mei",
  june_abr: "Jun",
  july_abr: "Jul",
  august_abr: "Aug",
  september_abr: "Sep",
  october_abr: "Okt",
  november_abr: "Nov",
  december_abr: "Dec",

  // HOMEPAGE DAILY KEY NUMBERS
  homepage_title_sales: "Verkopen",
  homepage_title_settlements: "Uitbetalingen ( transacties voorgaande dag)",
  homepage_key_numb_acc: "Geaccepteerde transacties",
  homepage_key_numb_am: "Totaal geaccepteerde transacties",
  homepage_key_numb_settlement_gr_am: "Totaal uitbetaald brutobedrag",
  homepage_key_numb_fees: "Commissies",
  homepage_key_numb_fin: "Totaal uitbetaald bedrag",

  // ACQUIRED TRANSACTIONS VIEW
  acq_transactions_title: "Verworven transacties",
  transaction_date: "Datum transactie",
  gross_amount: "Bruto bedrag",
  col_card_brand: "Kaartmerk",
  col_man_fees: "Commissie",
  col_tlc_number: "Remittance nummer",
  acq_trans_key_numbers_title: "Betaald door Market Pay",
  card_acq_trans_number: "Transacties",
  card_acq_trans_gross_amount: "Bruto bedrag",
  card_acq_trans_fees_amount: "Commissie",
  card_acq_trans_net_amount: "Netto bedrag",
  acq_details_title: "Verworven transacties  > ",

  // SETTLEMENTS VIEW
  settlements_title: "Uitbetalingen",
  settlement_tab_batch: "ID uitbetaling",
  set_tab_merchant: "Handelaar",
  pay_tab_settlement_date: "Datum uitbetaling",
  pay_tab_pay_sol: "Oplossing",
  pay_tab_pay_amo: "Bruto bedrag",
  settlement_tab_fees: "Commissie",
  pay_tab_settlement_am: "Uitbetaald",

  // TRANSACTION DETAILS
  tp_titles_acceptation: "Geaccepteerd",
  tp_titles_customer: "Klant",
  tp_titles_transaction: "Transactie",
  tp_titles_history: "Historiek",
  tp_titles_settlement: "Uitbetaling",
  tp_titles_merchant: "Handelaar",
  tp_fees_calculation: "Berekening van vergoedingen",
  tp_fees_amount: "Bedrag vergoedingen",
  tpd_checkout_type: "Type product",
  tpd_payment_method: "Betaalwijze",
  tpd_payment_solution: "Betaaloplossing",
  tpd_payment_type: "Type betaling",
  tpd_authorisation_code: "Authorisatiecode",
  tpd_pos_id: "POS ID",
  tpd_pos_name: "Naam POS",
  tpd_bank_acc_dom_country: "Woonplaats",
  tpd_card_iss_bank: "Uitgevende bank",
  tpd_card_iss_coun: "Uitgevend land",
  tpd_card_level: "Niveau Kaart",
  tpd_card_type: "Type kaart",
  tpd_pan_first_six: "BIN",
  tpd_pan_last_four: "Laaste 4 cijfers",
  tpd_scheme: "Scheme",
  tpd_op_date: "Operatie datum",
  tpd_op_date_utc: "Operatie datum (UTC)",
  tpd_op_time: "Uur van operatie",
  tpd_currency: "Munteenheid",
  tpd_gross_amount: "Bruto bedrag",
  tpd_net_amount: "Netto bedrag",
  tpd_trans_id: "Transactie ID",
  tpd_merch_trans_id: "Handelaar transactie ID",
  tpd_order_num: "Bestelnummer",
  tpd_trans_type: "Type transactie",
  tpd_trans_event: "Event",
  tpd_status: "Status",
  tpd_trans_event_id: "Event ID",
  tpd_root_trans_event_id: "Transactie ID",
  tpd_event_timestamp: "Datum en uur van het event",
  tpd_acquirer: "Acquirer",
  tpd_settlement_batch: "Uitbetaling",
  tpd_settlement_date: "Datum uitbetaling",
  tpd_settlement_status: "Status uitbetaling",
  tpd_interchange: "Interchange",
  tpd_intermediate_fees: "Kosten tussenpersoon",
  tpd_mkp_fees: "Kosten Market Pay",
  tpd_scheme_fees: "Kosten Scheme",
  tpd_total_fees: "Total commissie",
  tpd_account_name: "Account",
  tpd_company_name: "Bedrijf",
  tpd_merchant_name: "Handelaar",
  tpd_country: "Land",
  tpd_contract_num: "Contractnummer",
  tpd_transaction_settlement_id: "ID afwikkeling transactie",
  tpd_tlc_number: "Remittance nummer",
  tpd_card_zone: "Kaartzone",
  tpd_card_brand: "Kaartmerk",
  tpd_settlement_id: "ID uitbetaling",
  tpd_calculation_method: "Berekeningsmethode",
  tpd_marketpay_fixed_fees: "Market Pay vaste vergoedingen",
  tpd_markeetpay_fees_rate: "Market Pay vergoedingstarief",
  tpd_fixed_scheme_fees: "Vaste vergoedingen",
  tpd_scheme_fees_rate: "Schema vergoeding",
  tpd_total_marketpay_fees: "Totaal vergoedingen Market Pay",
  tpd_fixed_fees: "Vaste vergoedingen",
  tpd_variable_fees: "Variabele vergoedingen",
  tpd_total_acquiring_fees: "Totaal wervingskosten",
  col_total_marketpay_fees: "Totaal vergoedingen Market Pay",
  col_interchange_fees: "Interchange vergoedingen",
  col_scheme_fees_rate: "Schema vergoeding",
  col_fixed_scheme_fees: "Vaste vergoedingen",
  col_marketpay_fees_rate: "Market Pay vergoedingstarief",
  col_marketpay_fixed_fees: "Market Pay vaste vergoedingen",
  col_calculation_method: "Berekeningsmethode",
  col_card_zone: "Kaartzone",
  col_transaction_settlement_id: "ID afwikkeling transactie",
  payment_type_card: "Kaart",
  payment_type_banktransfer: "Overschrijving",
  payment_type_contactless: "Contactloos",
  payment_type_wallet: "Wallet",
  pos_id: "POS ID",
  col_processing_ht: "Netto verwerkingskosten",
  col_processing_tva: "BTW op de verwerkingskosten",
  col_processing_ttc: "Bruto verwerkingskosten",

  // TRANSACTION HISTORY TABLE
  trans_det_tab_uuid: "UUID",
  trans_det_tab_timestamp: "Datum et uur",
  trans_det_tab_event: "Event",
  trans_det_tab_status: "Status",
  trans_det_tab_amount: "Bedrag",
  transaction_det_last_sync: "Laatste sync: ",
  transaction_det_page_title_1: "Transactie >",
  transaction_det_page_title_2: "Transactie {transactionId} details",
  back_transactions_list: "Terug naar transactielijst",

  // NOTIFICATIONS
  notif_new_merchant: "Nieuwe Handelaar in uw",
  notif_new_comp: "Nieuw bedrijf in uw",
  notif_new_account: "Nieuw account in uw",
  notif_new_merchant_admin_inapp: "Een nieuwe Admin Handelaar is gecreeerd :",
  notif_new_marketpay_admin_inapp: "Een nieuw Market Pay Admin is gecreeerd :",
  notif_new_operator_inapp: "Een nieuwe Operator is gecreeerd in uw Perimeter :",
  notif_hide_button: "Verberg",
  notif_hide_all_button: "Verberg alle",
  new_notif: "U heeft {count} nieuwe meldingen :",
  no_new_notif: "U heeft geen nieuwe meldingen",
  notif_viewed: "Bekeken",

  // NOTIFICATION SETTINGS
  my_notifications: "Mijn meldingen",
  notif_title: "Mijn meldingen",
  notif_peri_update: "Perimeter wijzigingen",
  notif_user_update: "Gebruikers",
  notif_in_app: "In-App",
  notif_email: "E-mail",
  notif_edit_merchant: "Nieuwe Handelaar",
  notif_edit_company: "Nieuwe Bedrijf",
  notif_edit_acc: "Nieuwe Account",
  notif_edit_group: "Nieuwe Groep",
  notif_edit_operator: "Nieuwe Operator",
  notif_edit_merchant_admin: "Nieuwe Admin Handelaar",
  notif_edit_markerpay_admin: "Nieuwe Admin Market Pay",
  perim_creat_account: "Typ een Account naam",
  perim_creat_company: "Typ een Bedrijfs naam",

  // FILTERS
  filter_all_comp: "Alle",
  reset_button_text: "Reset",

  // VIEWS
  sv_deletion_confirmation_toaster: "Uw weergave werd verwijderd",
  sv_creation_confirmation: "Uw weergave werd succesvol opgeslagen",
  sv_save_this_view: "Sla deze weergave  op",
  sv_view_name: "Bekijk naam",
  sv_saved_views: "Opgeslagen weergaves  bekijken",
  sv_name_is_required: "De weergave moet een naam hebben",
  sv_name_is_too_short: "De naam moet minstens 3 karakters hebben",
  view_name_already_exists: "Deze weergave bestaat al",
  sv_deletion_confirmation: "Uw weergave werd verwijderd ?",

  // DATEPICKER DEFINED RANGES
  date_picker_today: "Vandaag",
  date_picker_yesterday: "Gisteren",
  date_picker_current_month: "Huidige maand",
  date_picker_last_week: "Vorige week",
  date_picker_last_two_weeks: "Laaste 2 weken",
  date_picker_last_month: "Vorige maandh",
};
