import React, { FunctionComponent } from "react";
import CardPopup from "components/atoms/card/CardPopup";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "components/atoms/popup/DeleteReportDialog/deleteReportDialog.module.scss";
import { DeleteAlertIcon } from "components/atoms/icons/DeleteAlertIcon";
import { Typography } from "@mui/material";
import DiscardButtonStyled from "styles/components/button/DiscardButtonStyled";
import ContainedButton from "components/atoms/button/ContainedButton";

export interface DeleteReportDialogProps {
  message: string;
  cancelBtnLabel: string;
  confirmBtnLabel: string;
  isDialogOpen: boolean;
  confirmHandler: () => void;
  cancelHandler: () => void;
  loading: boolean;
}

export const DeleteReportDialog: FunctionComponent<DeleteReportDialogProps> = ({
  message,
  cancelBtnLabel,
  confirmBtnLabel,
  isDialogOpen,
  confirmHandler,
  cancelHandler,
  loading = false,
}) => {
  return (
    <Dialog open={isDialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <CardPopup className={styles["wrapper"]}>
        <DeleteAlertIcon />
        {loading ? (
          <CircularProgress className={styles["loader"]} />
        ) : (
          <>
            <Typography className={styles["message"]}>{message}</Typography>
            <div className={styles["buttons"]}>
              <DiscardButtonStyled onClick={cancelHandler}>{cancelBtnLabel}</DiscardButtonStyled>
              <ContainedButton onClick={confirmHandler}>{confirmBtnLabel}</ContainedButton>
            </div>
          </>
        )}
      </CardPopup>
    </Dialog>
  );
};
