import React, {
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { FormControl, Autocomplete, AutocompleteRenderInputParams, TextField, PopperProps } from "@mui/material";
import { ResetIconButton } from "components/atoms/button/ResetButton/ResetIconButton";
import PaperChipStyled from "styles/components/paper/PaperChipStyled";
import { useDebouncedInput } from "hooks/input/useDebouncedInput";
import AutocompletePopper from "components/molecules/autocomplete/AutocompletePopper";
import { useOnChangeAutocomplete } from "hooks/input/useOnChangeAutocomplete";
import { Options } from "constants/options/option";
import AutocompleteTags from "components/molecules/autocomplete/AutocompleteTags";
import AutocompleteChipOption from "components/molecules/autocomplete/AutocompleteChipOption";
import { Entity } from "interfaces/perimeter/perimeter";
import { ValidateError } from "components/molecules/useraccount/edit/UserAccountEdit";
import "./autocompleteSelect.module.scss"

export interface AutocompleteSelectProps {
  values: Array<Entity>;
  options: Array<Entity>;
  label?: string;
  placeholder?: string;
  selectWidth?: number;
  onChange: (values: Array<Options>) => void;
  translatedLabel?: boolean;
  showSelection?: boolean;
  query: string;
  onChangeQuery?: (query: string) => void;
  onResetQuery?: () => void;
  defaultSort?: (options: Array<Options>) => Array<Options>;
  renderOptions?: (props, option: Options, input: string, values: Array<Options>) => JSX.Element;
  uniqueElement?: boolean;
  disable?: boolean;
  hasError?: ValidateError;
}

/**
 * Autocomplete with :
 * - Multiple / Single inputs
 * - Debounced search
 * - Default sorting by option name
 * - Reset query button
 * - Checkbox dropdown
 */
export const AutocompleteSelect: FunctionComponent<AutocompleteSelectProps> = ({
  values,
  options,
  label,
  placeholder,
  selectWidth = 108,
  onChange,
  query,
  onChangeQuery,
  onResetQuery,
  defaultSort = (options: Array<Options>) => {
    return options.sort((a, b) => a.name > b.name ? 1 : -1);
  },
  uniqueElement,
  disable = false,
  hasError = false,
}) => {
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [input, setInput] = useState<string>(query ?? "");

  const isNoneOption = !values?.[0]?.id;
  const [displayAutocompleteField, setDisplayAutocompleteField] = useState<boolean>(isNoneOption);

  const sortedOptions = defaultSort(options);
  const onReset = () => {
    onChange([]);
    setInput("");
    onResetQuery();
  }

  const onBlur = () => {
    if (uniqueElement && !isNoneOption) {
      setDisplayAutocompleteField(false);
      setInput("");
      onChangeQuery && onChangeQuery("");
    }
  }

  const { handleUniqueChange } = useOnChangeAutocomplete(onChange, values);

  const handleChange = (e: React.SyntheticEvent, value: Options[]) => {
    if (uniqueElement) {
      handleUniqueChange(e, value);
      setDisplayAutocompleteField(false);
    } else {
      onChange(value);
    }
  }

  const onChangeFilter = useDebouncedInput((value: string) => onChangeQuery(value), "").onChange;

  const onChangeAutocompleteField = (value: string) => {
    setIsLoading(true);
    setInput(value);
    onChangeFilter(value);
  }

  const textFieldHeight = !uniqueElement || displayAutocompleteField ? {} : {
    "& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary .MuiAutocomplete-input": {
      height: "0"
    }
  };


  useEffect(() => {
    setIsLoading(false);
  }, [options])

  return (
    <FormControl>
      {values.length > 0 && onResetQuery &&
        <ResetIconButton
          onClick={onReset}
          className="select-inline"
        />
      }
      <Autocomplete
        multiple
        disableClearable
        disableCloseOnSelect={!uniqueElement}
        blurOnSelect={uniqueElement}
        clearOnBlur={false}
        onFocus={() => uniqueElement && setDisplayAutocompleteField(true)}
        onBlur={onBlur}
        filterOptions={(x: Options[]) => x}
        value={values}
        inputValue={input}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={isLoading}
        disabled={disable}
        loadingText={intl.formatMessage({ id: "loading" })}
        noOptionsText={intl.formatMessage({ id: "no_results" })}
        renderTags={() =>
          <AutocompleteTags uniqueElement={uniqueElement}
            displayAutocompleteField={displayAutocompleteField}
            selectWidth={selectWidth}
            values={values} />}
        renderOption={(props, option: Entity) =>
        (<AutocompleteChipOption {...props}
          search={input}
          translatedLabel={false}
          selectedOptions={values}
          option={option} />)}
        options={sortedOptions}
        onChange={(e, value: Options[]) => handleChange(e, value)}
        renderInput={(params: AutocompleteRenderInputParams) =>
          <TextField {...params} placeholder={placeholder}
            classes={{ root: hasError ? "error-field" : "" }}
            label={label}
            variant="outlined"

            sx={{
              height: "24px",
              ...textFieldHeight,
              "& .MuiOutlinedInput-root": {
                height: "24px",
                paddingRight: "45px",
                paddingTop: "0px",
                paddingBottom: "0px",

                "& .MuiOutlinedInput-input": {
                  height: "24px",
                  paddingTop: "0px",
                  paddingBottom: "0px",

                },
              }
            }}
            onChange={(e) => onChangeAutocompleteField(e.target.value)} />}
        sx={{
          "&.MuiAutocomplete-root": {
            width: selectWidth,
            height: "24px",
            "& .MuiButtonBase-root:hover, & .MuiButtonBase-root:focus": {
              backgroundColor: "transparent",
              "& .MuiTouchRipple-root": {
                opacity: 0
              }
            }
          }
        }}
        PaperComponent={(props) => <PaperChipStyled {...props} />}
        getOptionLabel={(option: Options) => option.name}
        PopperComponent={(props: PopperProps) => <AutocompletePopper key={options.length} {...props}
          minWidth={selectWidth} />}
      />
    </FormControl>
  )
}
