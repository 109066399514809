import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import { SelectChangeEvent, SelectProps } from "@mui/material";
import { Languages } from "constants/messages/languages";
import { userService } from "services/user/UserService";
import { errorUtils } from "utils/api/errorUtils";
import { useAuthUser } from "hooks/useAuthUser";
import { useCookies } from "react-cookie";
import { HeaderCookie } from "components/molecules/header/Header";
import { languageUtils } from "utils/common/languageUtils";
import styles from "components/molecules/menu/LanguageDropdown/languageDropdown.module.scss";
import { DropdownStyled } from "components/atoms/select/DropdownStyled";
import { MenuItem } from "interfaces/menu/menu";

interface LanguageDropdownProps extends SelectProps {
  mobile: boolean;
  subMenuOpen: boolean;
  onChange?: (event: SelectChangeEvent) => void;
  onOpen?: () => void;
  onCloseMobileMenu?: () => void;
  // selectedItem, selectedSubItem and onSelectMenuItem are mandatory for mobile menu, but optional for desktop
  selectedSubItem?: MenuItem;
  onClickMenuItem: (e: SyntheticEvent, item: MenuItem) => void;
}
const languageOptions = () => {
  return Object.values(Languages).map((obj) => ({ id: obj.id, name: obj.label }));
};

export const LanguageDropdown: FunctionComponent<LanguageDropdownProps> = ({ onOpen }) => {
  const { user, cleanAndRefreshUserDetails } = useAuthUser();
  const [cookies, setCookies] = useCookies<string, HeaderCookie>(["lang"]);
  const [currentLanguage, setCurrentLanguage] = useState("");

  const handleOnChange = (event?: SelectChangeEvent<unknown>, id?: string) => {
    if (event || id) {
      // depending on the menu (mobile or desktop), use either id or event target value to set the new language
      const newLanguage = id ?? event.target.value;
      setCurrentLanguage(newLanguage.toString());
      userService
        .updateSelfLanguage(newLanguage.toString())
        .then(() => {
          cleanAndRefreshUserDetails();
        })
        .catch(errorUtils.handleBackErrors);
    }
  };
  const setNewLangFromLocalStorage = () => {
    const newLanguage = languageUtils.getNavigatorLanguageFromUser(user, cookies.lang).toUpperCase();
    setCurrentLanguage(newLanguage);
  };

  useEffect(() => {
    if (user && !user?.language) {
      setNewLangFromLocalStorage();
      userService
        .updateSelfLanguage(currentLanguage)
        .then(() => {
          setCookies("lang", currentLanguage, { path: "/", secure: false, sameSite: "lax" });
        })
        .catch(errorUtils.handleBackErrors);
    }
  }, [user]);

  useEffect(() => {
    if (!currentLanguage) {
      setNewLangFromLocalStorage();
    }
  }, []);

  return (
    <>
      <div className={styles["language-select-desktop"]}>
        <DropdownStyled
          selectedValue={currentLanguage}
          options={languageOptions()}
          onOpen={onOpen}
          onChange={handleOnChange}
          customClassName="language-dropwdown"
          menuId="language"
        />
      </div>
    </>
  );
};
