import React, {FunctionComponent} from "react";
import {Row} from "react-table";
import {TableCell as TableCellMaterialUI} from "@mui/material";
import TableRowStyled from "styles/components/table/TableRowStyled";

interface TableRowProps {
    prepareRow: (row: Row) => void
    row: Row,
    style?: object,
    onClickRow?: (row: Row) => void
}

const TableRow: FunctionComponent<TableRowProps> = ({prepareRow, row, style, onClickRow}) => {
    prepareRow(row)
    
    const handlerClickRow = onClickRow ? () => onClickRow(row) : null
    return (
        <TableRowStyled {...row.getRowProps({style})} onClick={handlerClickRow}
                        className={parseInt(row.id, 10) % 2 === 0 ? "even" : "odd"}>
            {row.cells.map(cell =>
                <TableCellMaterialUI
                    {...cell.getCellProps()}
                    sx={{height: "41px", padding: "6px 10px", border: "none"}}
                    component="div"
                    key={`cell-${cell.column.id}-${cell.row.id}`}
                    className="td">
                    {cell.render("Cell")}
                </TableCellMaterialUI>
            )}
        </TableRowStyled>
    )
}

export default React.memo(TableRow);
