import { useEffect, useRef } from "react";

export default function useOutsideClick<T extends HTMLElement>(onHandler: () => void) {
  const ref = useRef<T | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return ref as React.MutableRefObject<any>;
}
