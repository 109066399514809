import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { ICellProps } from "interfaces/table/table";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { authUtils } from "utils/api/authUtils";
import { fileUtils } from "utils/common/fileUtils";
import { toastUtils } from "utils/toast/toastUtils";
import { ApiError } from "utils/api/errorUtils";
import { reportService } from "services/report/ReportService";
import ContainedButton from "components/atoms/button/ContainedButton";
import { Download } from "@mui/icons-material";
import { paletteTheme } from "styles/themes/palette";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

const DownloadButton: FunctionComponent<ICellProps> = ({ value }) => {
  const { formatMessage } = useIntlFormatter();
  const { smDown, mdDown } = useMediaQueryUtil();

  const onClickWithTracker = async () => {
    authUtils.refreshTokenExpirationDate();

    try {
      const blobWithName = await reportService.downloadReport(value);
      void (await fileUtils.downloadWithFilename(blobWithName, "export_report.csv"));
    } catch (error) {
      if (error instanceof Promise) {
        const promiseError = error as Promise<Error>;
        promiseError
          .then((result: ApiError) => {
            // we currenctly get error 500 instead of 403. The toast message text needs to be changed once the error code is fixed
            if (result.status === 403) {
              toastUtils.errorToast(formatMessage("export_limit_message_no_number"));
            } else {
              toastUtils.errorToast(formatMessage("export_limit_message_no_number"));
            }
          })
          .catch((e: Error) => toastUtils.errorToast(e.message));
      }
    }
  };

  return (
    <div>
      <ContainedButton
        startIcon={<Download fontSize="small" />}
        sx={
          open && {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            backgroundColor: paletteTheme.colors.PRIMARY,
            "&:hover": {
              backgroundColor: paletteTheme.colors.DARK_PRIMARY,
            },
            "& .MuiButton-startIcon": {
              width: "20px",
              marginRight: smDown ? "0" : "8px",
              marginLeft: smDown ? "0" : "-4px",
            },
          }
        }
        onClick={onClickWithTracker}>
        {!smDown && <FormattedMessage id="reports_table_download_button" />}
      </ContainedButton>
    </div>
  );
};

export default DownloadButton;
