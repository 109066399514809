import React, { FunctionComponent } from "react";
import { InfoIcon } from "components/atoms/icons/InfoIcon";
import styles from "./infoTooltip.module.scss";

export const InfoTooltip:FunctionComponent = ({children}) => {
  return (
    <div className={styles["tooltip-label"]}>
      <div className={styles["tooltip-icon"]}>
        <InfoIcon />
      </div>

      <div>{children}</div>
    </div>
  );
};
