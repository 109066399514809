import {useEffect, useState} from "react";
import {
    INITIAL_HISTOGRAM_STATE, INITIAL_SETTLEMENT_DAILY_KEY_NUMBERS,
    INITIAL_TRANS_DAILY_KEY_NUMBERS,
    TEMPORALITY_SELECTOR
} from "../../constants/histogram/histogram";
import {HistogramResponse} from "../../interfaces/histogram/histogram";
import {KeyNumbers, PerimeterFilterValues} from "../../interfaces/transaction/transaction";
import {transactionService} from "../../services/transaction/TransactionService";
import {errorUtils, PromisableApiError} from "../../utils/api/errorUtils";
import {settlementService} from "../../services/settlement/SettlementService"
import {Temporality} from "../../types/temporality";

interface UseGraph {
    temporality: Temporality,
    data: HistogramResponse,
    dailyKeyNumbers: KeyNumbers,
    loading: boolean,
    onChangeTemporality: (temporality: string) => void
    onSearchTransactions: () => void,
    onSearchSettlements: () => void,
    onResetGraph: () => void
}

export const useGraph = (onSearchFilter: () => void, filters: PerimeterFilterValues, showContent: boolean, isTransaction: boolean): UseGraph => {

    const [temporality, setTemporality] = useState<Temporality>(TEMPORALITY_SELECTOR.MONTH.id);
    const [data, setData] = useState<HistogramResponse>(INITIAL_HISTOGRAM_STATE);
    const [dailyKeyNumbers, setDailyKeyNumbers] = useState<KeyNumbers>();
    const [loading, setLoading] = useState<boolean>(false);

    // Chart data and daily numbers are retrieved from the same endpoint
    const onSearchTransactions = () => {
        setLoading(true);
        onSearchFilter();
        const ac = new AbortController();
        transactionService.getTransactionsHistogram(filters, temporality, {signal: ac.signal})
            .then(response => {
                setData({
                    firstAmount: response.firstAmount,
                    secondAmount: response.secondAmount
                });
                setDailyKeyNumbers({
                    lastGrossAmount: response.lastGrossAmount,
                    lastNumberTransaction: response.lastNumberTransaction
                });
                setLoading(false);
            })
            .catch((e: PromisableApiError) => {
                errorUtils.handleBackErrors(e);
                setLoading(false)
            })

        return () => ac.abort();
    }

    const onSearchSettlements = () => {
        setLoading(true);
        onSearchFilter();
        const ac = new AbortController();
        settlementService.getSettlementsHistogram(filters, temporality, {signal: ac.signal})
            .then(response => {
                setData({
                    firstAmount: response.firstAmount,
                    secondAmount: response.secondAmount
                });
                setDailyKeyNumbers({
                    lastGrossAmount: response.lastGrossAmount,
                    lastFinanced: response.lastFinanced,
                    lastFees: response.lastFees,
                });
                setLoading(false);
            })
            .catch((e: PromisableApiError) => {
                errorUtils.handleBackErrors(e);
                setLoading(false)
            })

        return () => ac.abort();
    }

    const onSearch = () => {
        isTransaction ? onSearchTransactions() : onSearchSettlements();
    }

    const onChangeTemporality = (temporality: Temporality) => {
        setTemporality(temporality)
    }

    const onResetGraph = () => {
        setData(INITIAL_HISTOGRAM_STATE);
        setDailyKeyNumbers(isTransaction ? INITIAL_TRANS_DAILY_KEY_NUMBERS : INITIAL_SETTLEMENT_DAILY_KEY_NUMBERS);
    }

    useEffect(() => {
        if (showContent && !loading) {
            onSearch()
        }
    }, [temporality])

    return {
        temporality,
        data,
        dailyKeyNumbers,
        loading,
        onChangeTemporality,
        onSearchTransactions,
        onSearchSettlements,
        onResetGraph
    }
}
