import {useEffect, useState} from "react";
import {useChangeContext} from "../../constants/context/ChangeContext";
import {useNavigate} from "react-router-dom";

interface UnsaveChangeInstance {
    changeFields: FieldChanged,
    onChangeField: (key: string, value: boolean) => void
    disableBlockingPopup: () => void,
    openDiscardDialog: boolean,
    onCloseDiscardDialog: () => void,
    navigateOnAction: () => void,
}

interface FieldChanged {
    [key: string]: boolean
}

// Hook to handle unsave change popup and managing the state of the fields
// This hook call a context which manage the blocking behavior of links
export const useUnsaveChange = (initalState: FieldChanged): UnsaveChangeInstance => {

    const {
        unsaveChange,
        openDialog,
        setUnsaveChange,
        setOpenDialog,
        navigateTo
    } = useChangeContext();
    const [changeFields, setChangeFields] = useState<FieldChanged>(initalState);
    const navigate = useNavigate();

    const onChangeField = (key: string, value: boolean) => {
        if (changeFields[key] !== value) {
            setChangeFields(prev => ({...prev, [key]: value}))
        }
    }

    const disableBlockingPopup = () => {
        setUnsaveChange(false);
    }

    const navigateOnAction = () => {
        navigate(navigateTo)
    }

    const onCloseDiscardDialog = () => {
        setOpenDialog(false);
    }

    // Change the context value to display unsaved popup
    useEffect(() => {
        const newChangeContextValue = Object.values(changeFields).some(item => item);
        if (unsaveChange && !newChangeContextValue) {
            setUnsaveChange(false)
        } else if (!unsaveChange && newChangeContextValue) {
            setUnsaveChange(true)
        }
    }, [changeFields])

    return {
        changeFields,
        onChangeField,
        disableBlockingPopup,
        openDiscardDialog: openDialog,
        onCloseDiscardDialog,
        navigateOnAction
    }

}
