import React, { FunctionComponent } from "react";

export const InfoIcon: FunctionComponent = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7.38672" r="7" fill="#99C3C6" />
    <path
      d="M6.13957 11.3867V6.38672H7.85957V11.3867H6.13957ZM6.99957 5.76672C6.71957 5.76672 6.4929 5.69005 6.31957 5.53672C6.14624 5.37672 6.05957 5.16672 6.05957 4.90672C6.05957 4.64672 6.14624 4.44005 6.31957 4.28672C6.4929 4.12672 6.71957 4.04672 6.99957 4.04672C7.27957 4.04672 7.50624 4.12672 7.67957 4.28672C7.8529 4.44005 7.93957 4.64672 7.93957 4.90672C7.93957 5.16672 7.8529 5.37672 7.67957 5.53672C7.50624 5.69005 7.27957 5.76672 6.99957 5.76672Z"
      fill="white"
    />
  </svg>
);
