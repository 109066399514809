import {SvgIconComponent} from "@mui/icons-material";

export enum NotificationType {
    USER = "user",
    PERIMETER = "perimeter"
  }

export interface NotificationResponse {
    id: string,
    idUser: string,
    targetId: number | undefined,
    createdAt: string,
    notificationType: string,
    detail: string,
    hidden: boolean,
    seen: boolean,
}

export interface NotificationMessage {
    notification: NotificationResponse
    label: string,
    url: string,
    icon: JSX.Element,
}

export interface NotificationProps {
    notificationMessage: NotificationMessage,
    targetUrl: string | undefined,
    isHidden: boolean,
    type: NotificationType
}

export interface NotificationLine {
    id: string,
    label: string,
    url: string,
    targetUrlBuilder?: (targetId:number) => string,
    urlColor: string,
    textColor: string
    IconNotification: SvgIconComponent,
    iconColor: string,
    type: NotificationType,
}

export interface Notifications {
    [key: string]: NotificationLine
}
