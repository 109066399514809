
import { Options } from "constants/options/option";
import { format } from "date-fns";
import { FilterValues } from "interfaces/filter/filter";
import { ReportRequest, ReportRequestFilters } from "interfaces/report/report";
import { TransactionAdditionalFiltersValues } from "interfaces/transaction/transaction";

const flatMap = (arr: Options[]) => {
    const hasAllOption = arr.some(item => item.name === "ALL");
    return hasAllOption ? [] : arr.flatMap(item => item.id);
}

const checkForInvalidDate = (value: string) => {
    if (value === "Invalid Date" || value === undefined || value === null) {
        return null;
    }
    // here we need to make sure that day and month always have two digits, because BE requires it
    const formatted = format(new Date(value), "yyyy-MM-dd");
    return formatted;
}

export const useScheduleReport = (

) => {

    const generateReportRequestFilters = (filters: FilterValues & TransactionAdditionalFiltersValues): ReportRequestFilters => {
        const formattedFilters =
        {
            batchId: filters.batchId,
            bin: filters.bin,
            checkoutType: flatMap(filters.checkoutType),
            countries: [],
            eventStatus: flatMap(filters.eventStatus),
            financingEndDate: checkForInvalidDate(filters?.financingDate?.endDate?.toString()),
            financingStartDate: checkForInvalidDate(filters?.financingDate?.startDate?.toString()),
            financingStatus: flatMap(filters.financingStatus),
            grossAmountMax: parseFloat(filters.grossAmountMax),
            grossAmountMin: parseFloat(filters.grossAmountMin),
            operationEndDate: checkForInvalidDate(filters?.operationDate?.endDate?.toString()),
            operationStartDate: checkForInvalidDate(filters?.operationDate?.startDate?.toString()),
            orderNumber: filters.orderNumber,
            paymentMethod: flatMap(filters.paymentMethod),
            paymentSolution: flatMap(filters.paymentSolution),
            paymentType: flatMap(filters.paymentType),
            perimeter: {
                accountId: flatMap(filters.accounts),
                companyId: flatMap(filters.companies.selectedOptions),
                merchantId: flatMap(filters.merchants.selectedOptions),
            },
            scheme: flatMap(filters.scheme),
            transactionalEvent: flatMap(filters.transactionalEvent),
            transactionalEventId: filters.transactionalEventId,
        };
        return formattedFilters;
    }

    const generateReportRequest = (name: string, frequency: string, filters: ReportRequestFilters) => {
        const report: ReportRequest = {
            frequency,
            frequencyDayOfMonth: 1,
            frequencyDayOfWeek: "MONDAY",
            name: name,
            transactionsReport: filters,
            version: 1,
        }
        return report;
    }

    return {
        generateReportRequestFilters,
        generateReportRequest,
    }
}
