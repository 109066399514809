import {
  AutocompleteCheckboxConfig,
  MultichoiceConfig,
  PerimeterConfig,
  CommonFilterProps,
  useWrappedFilterComponents,
  QueryAndResetConfig,
} from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { BILLING_FILTERS } from "constants/portfolio/portfolioFilter";
import { FilterColumn } from "interfaces/filter/filterColumn";
type BillingFilterConfig = {
  sharedFilterProps: CommonFilterProps;
  perimeterConfig: PerimeterConfig;
  autoCompleteCheckboxConfig: AutocompleteCheckboxConfig;
  multichoiceConfig: MultichoiceConfig;
  queryAndResetConfig: QueryAndResetConfig;
};

export const useBillingFilterConfig = (config: BillingFilterConfig) => {
  const { sharedFilterProps, perimeterConfig, autoCompleteCheckboxConfig, multichoiceConfig, queryAndResetConfig } =
    config;

  const { onSearch, onReset, errors } = sharedFilterProps;

  const { autocompleteCheckbox, multiChoiceCheckbox, queryAndReset } = useWrappedFilterComponents();

  const filters: FilterColumn[] = [
    {
      name: "Account dropdown",
      component: autocompleteCheckbox("account", perimeterConfig, autoCompleteCheckboxConfig),
      index: 0,
    },
    {
      name: "Company dropdown",
      component: autocompleteCheckbox("company", perimeterConfig, autoCompleteCheckboxConfig),
      index: 1,
    },
    {
      name: "Year dropdown",
      component: multiChoiceCheckbox(BILLING_FILTERS.SETTLEMENT_YEAR, multichoiceConfig, "DOCUMENTS"),
      index: 2,
    },
    {
      name: "Month dropdown",
      component: multiChoiceCheckbox(BILLING_FILTERS.SETTLEMENT_MONTH, multichoiceConfig, "DOCUMENTS"),
      index: 3,
    },
    {
      name: "Download and reset",
      component: queryAndReset(onSearch, onReset, errors, queryAndResetConfig),
      index: 4,
      config: {
        xs: { colSpan: 2 },
        sm: {
          colSpan: 1,
          colStart: 5,
        },
      },
    },
  ];

  return {
    filters,
  };
};
