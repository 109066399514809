import {styled} from "@mui/material/styles";
import Chip from "../../../components/atoms/chip/Chip";
import {ChipProps} from "@mui/material";

export default styled(Chip)<ChipProps>(() => ({
    padding: "3px 5px",
    height: "auto",
    fontWeight: "bold",
    width: "100%"
}));
