import React, { FunctionComponent } from "react";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import { Check } from "@mui/icons-material";
import { MaterialUiColor } from "types/materialui/materialui";

export interface LabeledCheckboxProps {
  label: string,
  labelColor?: string;
  labelFontSize?: string;
  translatedLabel?: boolean;
  marginTop?: string;
  marginBottom?: string;
}

export const PermissionDetail: FunctionComponent<LabeledCheckboxProps> = ({
  label,
  labelColor = "",
  labelFontSize = "12px",
  translatedLabel = true,
  marginBottom = "12px",
  marginTop = "0px",
}) => <div className="flex" style={{marginBottom: marginBottom}}>
    <Check fontSize="small" sx={{ fontSize: 14, marginRight: "3px" }} color={MaterialUiColor.PRIMARY} />
    <Typography
      color={labelColor || MaterialUiColor.PRIMARY}
      lineHeight={1}
      marginLeft={0.3}
      fontSize={labelFontSize}
      marginTop={marginTop}>
      {translatedLabel ? (<FormattedMessage id={label} />) : label}
    </Typography>
  </div>
