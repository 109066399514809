import React from "react";
import { FixedSizeList } from "react-window";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CallbackVirtualTable } from "components/atoms/table/Table";
import { SeparatorLine } from "components/atoms/table/SeparatorLine";

interface VirtualiseRowProps {
  loading: boolean;
  fetchError: boolean;
  itemCount: number;
  rows: ({ index, style }: CallbackVirtualTable) => JSX.Element;
  bottomItemCount?: number;
  bottomRows?: ({ index, style }: CallbackVirtualTable) => JSX.Element;
  staticPageSize?: number,
}

const VirtualizedStaticTable: React.ForwardRefRenderFunction<FixedSizeList, VirtualiseRowProps> = (
  { loading, fetchError, itemCount, bottomItemCount  = 0, rows, bottomRows, staticPageSize }, ref
) => {
  const ITEM_SIZE = 41;

  const tableHeight = ITEM_SIZE * (staticPageSize ? staticPageSize : (itemCount - bottomItemCount));


  if (fetchError) {
    return (
      <Typography marginTop={3} variant="h2" textAlign="center">
        <FormattedMessage id="fetch_error" />
      </Typography>
    );
  }

  return (
    !loading &&
    (itemCount > 0 ? (
      <>
        <FixedSizeList
          className="scroll-table"
          height={tableHeight}
          itemCount={itemCount - bottomItemCount}
          width="100%"
          itemSize={ITEM_SIZE}>
          {rows}
        </FixedSizeList>
        {bottomItemCount > 0 && (
          <>
            <SeparatorLine/>
            <FixedSizeList
              className="scroll-table"
              height={ITEM_SIZE * bottomItemCount}
              itemCount={bottomItemCount}
              width="100%"
              itemSize={ITEM_SIZE}>
              {bottomRows}
            </FixedSizeList>
          </>
        )}
      </>
    ) : (
      <Typography marginTop={3} variant="h2" textAlign="center">
        <FormattedMessage id="no_data_found" />
      </Typography>
    ))
  );
};

export default React.forwardRef(VirtualizedStaticTable);
