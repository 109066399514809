import React, { FunctionComponent, SyntheticEvent, useRef } from "react";
import { HeaderGroup } from "react-table";
import { TableCell as TableCellMaterialUI, Typography } from "@mui/material";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import styles from "components/atoms/table/cells/TableHeaderCell/tableHeaderCell.module.scss";
import { TableHeaderSortIcon } from "components/atoms/table/TableHeaderSortIcon";
import TooltipOverflowText from "components/molecules/tooltip/TooltipOverflowText/TooltipOverflowText";
import { InfoTooltip } from "components/atoms/tooltip/InfoTooltip";

interface TableProps {
  column: HeaderGroup & { tooltip?: string };
  resize?: boolean;
  tooltip?: string;
}

export const TableHeaderCell: FunctionComponent<TableProps> = ({ column, resize }) => {
  const { formatMessage } = useIntlFormatter();

  const tooltipLabel = column.tooltip && <InfoTooltip>{column.tooltip}</InfoTooltip>;

  const resizeRest = (resize && column.getResizerProps()) || {};
  const { getSortByToggleProps, toggleSortBy, canSort, isSorted, isResizing, isSortedDesc } = column;

  /**
   * This function is called when the user clicks on a column header.
   * It toggles the sorting of the table by the clicked column.
   *
   * @param {SyntheticEvent} event The mouse event object.
   * @param {ToggleSortBy} toggleSortBy The react-table v7 built-in function to toggleSorting. Based on Steamulo comments in the Table.tsx we do not use multisorting and therefore we pass the second param here as false
   * @param {boolean} isDesc A boolean indicating whether the table is sorted in descending order.
   */

  const sortOnClick = (event: SyntheticEvent, isDesc: boolean) => {
    event.persist();
    toggleSortBy(!isDesc, false);
  };

  const getSortCellProps = () => {
    if (!canSort) {
      return {};
    }
    return getSortByToggleProps({
      title: "",
      onClick: (event) => sortOnClick(event, isSortedDesc),
      style: { cursor: "pointer" },
    });
  };
  const textElementRef = useRef<HTMLDivElement>();

  return (
    <TableCellMaterialUI
      {...column.getHeaderProps()}
      component="div"
      key={column.id}
      className={`th ${column.id} ${styles.tableHeaderWrap}`}
    >
      <>
        <div className={`flex flex-row-between flex-col-center ${styles.tableHeaderCell}`}>
          <TooltipOverflowText
            textElementRef={textElementRef}
            text={tooltipLabel || column.render("Header")}
            isShowAlways={!!tooltipLabel}
            resizeClass="resizer"
          >
            <Typography
              ref={textElementRef}
              component="span"
              style={{ cursor: tooltipLabel ? "help" : "default" }}
              className={`ellipsis ${styles.tableHeaderTitle}`}
            >
              {column.render("Header")}
            </Typography>
          </TooltipOverflowText>

          {canSort && (
            <div {...getSortCellProps()}>
              <TableHeaderSortIcon
                isSorted={isSorted}
                isResizing={isResizing}
                isSortedDesc={isSortedDesc}
                tooltipText={formatMessage("toggle_sorting")}
              />
            </div>
          )}
          <div {...resizeRest} className={`resizer ${isResizing ? "isResizing" : ""}`}>
            <span></span>
          </div>
        </div>
      </>
    </TableCellMaterialUI>
  );
};
