import React, { FunctionComponent } from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import FilterList from "@mui/icons-material/FilterList";
import { Tooltip } from "@mui/material";

interface TableHeaderSortIconProps {
  isSorted: boolean;
  isResizing: boolean;
  isSortedDesc: boolean;
  tooltipText?: string;
}

/**
 * This is a custom icon for the react-table v7 library that allows users to toggle the sorting of the table by clicking on the column headers.
 * The icon changes based on the sorting direction and also takes into consideration if the column is currently being resized.
 */

export const TableHeaderSortIcon: FunctionComponent<TableHeaderSortIconProps> = ({
  isSorted,
  isResizing,
  isSortedDesc,
  tooltipText = "",
}) => {
  const color = isResizing ? "secondary" : "inherit";

  return (
    <Tooltip title={tooltipText} placement="bottom">
      {!isSorted ? (
        <span className="flex flex-center">
          <FilterList fontSize="small" color={color} />
        </span>
      ) : (
        <span className="flex flex-center">
          {isSortedDesc ? <ArrowDropDown color={color} /> : <ArrowDropUp color={color} />}
        </span>
      )}
    </Tooltip>
  );
};
