import {ErrorReferential} from "../../interfaces/error/error";

export const UPDATE_PASSWORD_ERRORS: ErrorReferential = {
    UNMATCHED_PASSWORDS: {id: "UNMATCHED_PASSWORDS"},
    CONFIRM_PASSWORD_REQUIRED: {id: "CONFIRM_PASSWORD_REQUIRED"},
    PASSWORD_MIN_LENGTH: {id: "PASSWORD_MIN_LENGTH"},
    PASSWORD_LOWERCASE: {id: "PASSWORD_LOWERCASE"},
    PASSWORD_UPPERCASE: {id: "PASSWORD_UPPERCASE"},
    PASSWORD_NUMBER: {id: "PASSWORD_NUMBER"},
    PASSWORD_SPECIAL_CHARACTER: {id: "PASSWORD_SPECIAL_CHARACTER"},
}
