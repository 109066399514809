import { ReactComponent as ArrowSign } from "assets/ArrowSign.svg";
import { ReactComponent as Refresh } from "assets/Refresh.svg";
import { ReactComponent as Bill } from "assets/Bill.svg";
import { ReactComponent as CheckedDatabase } from "assets/CheckedDatabase.svg";
import { ReactComponent as CheckedBill } from "assets/CheckedBill.svg";
import { ReactComponent as CheckboxesArrowDown } from "assets/CheckboxesArrowDown.svg";
import { ReactComponent as TransactionAcquired } from "assets/TransactionAcquired.svg";
import { ReactComponent as FilledCheckedDatabase } from "assets/FilledCheckedDatabase.svg";
import React from "react";
import { KeyNumberElement, DetailInformationElement } from "interfaces/transaction/transaction";
import { PriceCheck } from "@mui/icons-material";

export const TRANSACTION_KEY_NUMBERS: KeyNumberElement[] = [
  {
    id: "totalTransaction",
    label: "card_transactions",
    icon: <Refresh />,
  },
  {
    id: "totalGrossAmount",
    label: "card_trans_gross_amount",
    currency: true,
    icon: <FilledCheckedDatabase />,
  },
  {
    id: "totalNetAmount",
    label: "card_trans_net_amount",
    currency: true,
    icon: <CheckedDatabase />,
    startFromNewLine: true,
  },
  {
    id: "totalRefunds",
    label: "card_trans_refunds_amount",
    currency: true,
    icon: <ArrowSign />,
  },
  {
    id: "totalComission",
    label: "card_trans_fees_amount",
    currency: true,
    icon: <Bill />,
  },
];

export const DAILY_SALES_KEY_NUMBERS: KeyNumberElement[] = [
  {
    id: "lastNumberTransaction",
    label: "homepage_key_numb_acc",
    icon: <CheckboxesArrowDown />,
  },
  {
    id: "lastGrossAmount",
    label: "homepage_key_numb_am",
    currency: true,
    icon: <CheckedBill />,
  },
];

export const ACCEPTATION: DetailInformationElement[] = [
  {
    id: "checkoutType",
    label: "tpd_checkout_type",
  },
  {
    id: "paymentMethod",
    label: "tpd_payment_method",
  },
  {
    id: "paymentSolution",
    label: "tpd_payment_solution",
  },
  {
    id: "paymentType",
    label: "tpd_payment_type",
  },
  {
    id: "authorisationCode",
    label: "tpd_authorisation_code",
  },
  {
    id: "posId",
    label: "tpd_pos_id",
  },
  {
    id: "posName",
    label: "tpd_pos_name",
  },
];

export const CUSTOMER: DetailInformationElement[] = [
  {
    id: "bankAccountDomiciliationCountry",
    label: "tpd_bank_acc_dom_country",
  },
  {
    id: "cardIssuingBank",
    label: "tpd_card_iss_bank",
  },
  {
    id: "cardIssuingCountry",
    label: "tpd_card_iss_coun",
  },
  {
    id: "cardLevel",
    label: "tpd_card_level",
  },
  {
    id: "cardType",
    label: "tpd_card_type",
  },
  {
    id: "panfirstSixDigits",
    label: "tpd_pan_first_six",
  },
  {
    id: "panlastFourDigits",
    label: "tpd_pan_last_four",
  },
  {
    id: "scheme",
    label: "tpd_scheme",
  },
];

export const TRANSACTION: DetailInformationElement[] = [
  {
    id: "operationDate",
    label: "tpd_op_date_utc",
  },
  {
    id: "operationDatetimeLocal",
    label: "col_operation_time_local",
  },
  {
    id: "operationDatetimeUTC",
    label: "col_operation_time_utc",
  },
  {
    id: "currency",
    label: "tpd_currency",
  },
  {
    id: "grossAmount",
    label: "tpd_gross_amount",
    currency: true,
    type: "currency",
  },
  {
    id: "transactionId",
    label: "tpd_trans_id",
  },
  {
    id: "merchantTransactionId",
    label: "tpd_merch_trans_id",
  },
  {
    id: "orderNumber",
    label: "tpd_order_num",
  },
  {
    id: "transactionType",
    label: "tpd_trans_type",
  },
];

export const HISTORY: DetailInformationElement[] = [
  {
    id: "transactionalEvent",
    label: "tpd_trans_event",
  },
  {
    id: "eventStatus",
    label: "tpd_status",
  },
  {
    id: "transactionalEventId",
    label: "tpd_trans_event_id",
  },
  {
    id: "eventTimestamp",
    label: "tpd_event_timestamp",
  },
];

export const SETTLEMENT: DetailInformationElement[] = [
  {
    id: "acquirer",
    label: "tpd_acquirer",
  },
  {
    id: "batchId",
    label: "tpd_settlement_batch",
  },
  {
    id: "financingDate",
    label: "tpd_settlement_date",
  },
  {
    id: "financingStatus",
    label: "tpd_settlement_status",
  },
  {
    id: "netAmount",
    label: "tpd_net_amount",
    currency: true,
    type: "currency",
  },
  // MPT-461: Hide fees columns
  /*
    {
        id: "interchange",
        label: "tpd_interchange",
        currency: true
    },
    {
        id: "marketpayFees",
        label: "tpd_mkp_fees",
        currency: true
    },
    {
        id: "schemeFees",
        label: "tpd_scheme_fees"
    },*/
  {
    id: "totalCommission",
    label: "tpd_total_fees",
    currency: true,
    type: "currency",
  },
];

export const MERCHANT: DetailInformationElement[] = [
  {
    id: "accountName",
    label: "tpd_account_name",
  },
  {
    id: "companyName",
    label: "tpd_company_name",
  },
  {
    id: "storeName",
    label: "tpd_merchant_name",
  },
  {
    id: "countryName",
    label: "tpd_country",
  },
  {
    id: "contractNumber",
    label: "tpd_contract_num",
  },
  {
    id: "collectionTypePayonline",
    // id: "collectionType",
    label: "filter_collection_type",
  },
  {
    id: "servicePayOnline",
    // id: "serviceType",
    label: "filter_service_type",
  },
];

export const DEFAULT_ACUIRED_KEY_NUMBERS = {
  totalFees: 0,
  totalGrossAmount: 0,
  totalNetAmount: 0,
  totalElements: 0,
};

export const ACUIRED_KEY_NUMBERS: KeyNumberElement[] = [
  {
    id: "totalElements",
    label: "card_acq_trans_number",
    icon: <TransactionAcquired />,
  },
  {
    id: "totalGrossAmount",
    label: "card_acq_trans_gross_amount",
    currency: true,
    icon: <FilledCheckedDatabase />,
  },
  {
    id: "totalFees",
    label: "card_acq_trans_fees_amount",
    currency: true,
    icon: <PriceCheck />,
  },
  {
    id: "totalNetAmount",
    label: "card_acq_trans_net_amount",
    currency: true,
    icon: <CheckedDatabase />,
  },
];

export const ACQUIRED_TRANSACTIONS: DetailInformationElement[] = [
  {
    id: "transactionSettlementId",
    label: "tpd_transaction_settlement_id",
  },
  {
    id: "contractNumber",
    label: "tpd_contract_num",
  },
  {
    id: "operationDate",
    label: "tpd_op_date",
  },
  {
    id: "operationDateTime",
    label: "tpd_op_time",
  },
  {
    id: "transactionType",
    label: "tpd_trans_type",
  },
  {
    id: "grossAmount",
    label: "tpd_gross_amount",
    currency: true,
    type: "currency",
  },
  {
    id: "currency",
    label: "tpd_currency",
  },
  {
    id: "authorisationCode",
    label: "tpd_authorisation_code",
  },
  {
    id: "paymentType",
    label: "tpd_payment_type",
  },
  {
    id: "paymentSolution",
    label: "tpd_payment_solution",
  },
  {
    id: "tlcNumber",
    label: "tpd_tlc_number",
  },
];

export const ACQUIRED_CUSTOMER: DetailInformationElement[] = [
  {
    id: "cardZone",
    label: "tpd_card_zone",
  },
  {
    id: "issuingCountry",
    label: "tpd_card_iss_coun",
  },
  {
    id: "cardLevel",
    label: "tpd_card_level",
  },
  {
    id: "cardType",
    label: "tpd_card_type",
  },
  {
    id: "bin",
    label: "tpd_pan_first_six",
  },
  {
    id: "panLastFourDigits",
    label: "tpd_pan_last_four",
  },
  {
    id: "cardBrand",
    label: "tpd_card_brand",
  },
];

export const ACQUIRED_SETTLEMENT: DetailInformationElement[] = [
  {
    id: "acquirer",
    label: "tpd_acquirer",
  },
  {
    id: "scheme",
    label: "tpd_scheme",
  },
  {
    id: "batchId",
    label: "tpd_settlement_id",
  },
  {
    id: "settlementDate",
    label: "tpd_settlement_date",
  },
  {
    id: "settlementStatus",
    label: "tpd_settlement_status",
  },
  {
    id: "netAmount",
    label: "tpd_net_amount",
    currency: true,
    type: "currency",
  },
];

export const ACQUIRED_MERCHANT: DetailInformationElement[] = [
  {
    id: "account",
    label: "tpd_account_name",
  },
  {
    id: "company",
    label: "tpd_company_name",
  },
  {
    id: "merchant",
    label: "tpd_merchant_name",
  },
  {
    id: "country",
    label: "tpd_country",
  },
];
export const ACQUIRED_CALCULATION: DetailInformationElement[] = [
  {
    id: "calculationMethod",
    label: "tpd_calculation_method",
  },
  {
    id: "marketPayFixedFees",
    label: "tpd_marketpay_fixed_fees",
    currency: true,
    type: "currency",
  },
  {
    id: "marketPayFeesRate",
    label: "tpd_markeetpay_fees_rate",
  },
  {
    id: "fixedSchemeFees",
    label: "tpd_fixed_scheme_fees",
    currency: true,
    type: "currency",
  },
  {
    id: "schemeFeesRate",
    label: "tpd_scheme_fees_rate",
  },
];

export const ACQUIRED_FEES_AMOUNT: DetailInformationElement[] = [
  {
    id: "interchangeFees",
    label: "tpd_interchange",
    currency: true,
    type: "currency",
  },
  {
    id: "schemeFees",
    label: "tpd_scheme_fees",
    currency: true,
    type: "currency",
  },
  {
    id: "totalMarketPayFees",
    label: "tpd_total_marketpay_fees",
    currency: true,
    children: [
      {
        id: "fixedFeesIncluded",
        label: "tpd_fixed_fees",
        currency: true,
        type: "currency",
      },
      {
        id: "variableFeesIncluded",
        label: "tpd_variable_fees",
        currency: true,
        type: "currency",
      },
    ],
  },
  {
    id: "processingFeesTaxExcluded",
    label: "col_processing_ht",
    currency: true,
    type: "currency",
  },
  {
    id: "processingFeesTaxIncluded",
    label: "col_processing_ttc",
    currency: true,
    type: "currency",
  },
  {
    id: "processingFeesVAT",
    label: "col_processing_tva",
    currency: true,
    type: "percent",
  },
  {
    id: "totalAcquiringFees",
    label: "tpd_total_acquiring_fees",
    currency: true,
    type: "currency",
  },
];


export const ACQUIRED_DISTRIB: DetailInformationElement[] = [
  {
    id: "distribAmount",
    label: "col_retrocession",
    currency: true,
    type: "currency",
  },
  {
    id: "distribShareMarginAmt",
    label: "distrib_share_margin_amt",
    currency: true,
    type: "currency",
  },
  {
    id: "distribShareMarginRate",
    label: "distrib_share_margin_rate",
    currency: false,
    type: "percent",
  },
];


export const ACQUIRED_DISTRIB_ADMIN: DetailInformationElement[] = [
  ...ACQUIRED_DISTRIB,
  {
    id: "mpShareMarginAmt",
    label: "mp_share_margin_amt",
    currency: true,
    type: "currency",
  },
  {
    id: "mpShareMarginRate",
    label: "mp_share_margin_rate",
    currency: false,
    type: "percent",
  },
];
