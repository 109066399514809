import { INITIAL_RANGE_LAST_WEEK } from "constants/datepicker/datepicker";
import { DOCUMENTS_PAST_MONTH, DOCUMENTS_YEARS_VALUES } from "constants/documents/documentsFilter";
import { PORTFOLIO_VOLUME_TRENDS_BY } from "constants/transaction/transactionFilter";
import { FilterValues } from "interfaces/filter/filter";
import { filterUtils } from "utils/business/filterUtils";

export const initSavedTopBottomFilter = (savedFilters?: FilterValues): FilterValues => {
  if (!savedFilters) {
    return { acceptationRange: INITIAL_RANGE_LAST_WEEK, ...filterUtils.buildEmptyPerimeterFilterValues() };
  }
  return {
    ...filterUtils.initSavedPerimeterFilters(savedFilters), acceptationRange: INITIAL_RANGE_LAST_WEEK
  };
};

export const initSavedTrendsFilter = (savedFilters?: FilterValues): FilterValues => {
  if (!savedFilters) {
    return { ...filterUtils.buildEmptyPerimeterFilterValues(), trendBy: [PORTFOLIO_VOLUME_TRENDS_BY[0]] };
  }
  return {
    ...filterUtils.initSavedPerimeterFilters(savedFilters), trendBy: [PORTFOLIO_VOLUME_TRENDS_BY[0]]
  };
};

export const initSavedBillingFilter = (savedFilters?: FilterValues): FilterValues => {
  const filters = savedFilters
    ? {
      ...filterUtils.initSavedPerimeterFilters(savedFilters),
      settlementMonths: savedFilters.settlementMonths ?? [DOCUMENTS_PAST_MONTH()],
      settlementYears: savedFilters.settlementYears ?? [DOCUMENTS_YEARS_VALUES().at(-1)],
    }
    : 
    {
      ...filterUtils.buildEmptyPerimeterFilterValues(),
      settlementMonths: [DOCUMENTS_PAST_MONTH()],
      settlementYears: [DOCUMENTS_YEARS_VALUES().at(-1)],
    }
  return filters;
};
