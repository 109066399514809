import { enGB, fr, it, es, nl } from "date-fns/locale";
import { Messages } from "constants/messages/messages";
import { ConstantLabelLine } from "constants/perimeter/perimeter";

export interface LanguageReferentialLine extends ConstantLabelLine {
    messages: Record<string, string>;
    locale: Locale;
}

export interface LanguageReferential {
    [key: string]: LanguageReferentialLine;
}

export const Languages: LanguageReferential = {
    EN: { id: "EN", messages: Messages.en, label: "language_en", locale: enGB },
    FR: { id: "FR", messages: Messages.fr, label: "language_fr", locale: fr },
    IT: { id: "IT", messages: Messages.it, label: "language_it", locale: it },
    NL: { id: "NL", messages: Messages.nl, label: "language_nl", locale: nl },
    ES: { id: "ES", messages: Messages.es, label: "language_es", locale: es },
    // PT: {id: "PT", messages: Messages.pt, label: "language_pt", locale: pt},
}
