import React, {FunctionComponent, useState} from "react";
import {
    FormControl,
    SelectChangeEvent,
    SxProps,
    Theme,
    Typography, MenuItem
} from "@mui/material";
import {SelectProps as SelectPropsMaterialUI} from "@mui/material/Select/Select";
import {arrayUtils} from "../../../utils/common/arrayUtils";
import {paletteTheme} from "../../../styles/themes/palette";
import {Clear, KeyboardArrowDown, StarBorder} from "@mui/icons-material";
import {Options} from "../../../constants/options/option";
import FlexBox from "../flex/FlexBox";
import {FlexAttributValue} from "../../../types/flex/flex";
import {FormattedMessage} from "react-intl";
import Select from "./Select";


interface SelectProps extends SelectPropsMaterialUI {
    disableOnUnique?: boolean,
    disabled?: boolean,
    emptyOnAddition?: boolean,
    multiple?: boolean,
    sx?: SxProps<Theme>,
    options: Array<Options>,
    value?: Options,
    onChange: (option: SelectChangeEvent) => void
    handleDelete: (id: string) => void
}

const SelectView: FunctionComponent<SelectProps> = ({
                                                        id,
                                                        sx = {width: 260},
                                                        value,
                                                        options,
                                                        multiple = false,
                                                        disableOnUnique = false,
                                                        disabled,
                                                        onChange,
                                                        handleDelete,
                                                        ...rest
                                                    }) => {
    const optionsUnique = arrayUtils.arrayOfUnique(options)
    const [hoverDelete, setHoverDelete] = useState<boolean>(false);


    const menuProps = {

            PaperProps: {
                elevation: 0,
                sx: {
                    borderRadius: "0 0 2px 2px",
                }
            },
            MenuListProps: {
                sx: {
                    border: `1px solid ${paletteTheme.colors.GRAY}`,
                    borderRadius: "0 0 2px 2px",
                    padding: "5px 0",
                    boxShadow: "none",
                }
            }
        }
    ;

    const sxMenuItem = {
        margin: "0 8px",
        padding: "3px",        
        "&:hover.MuiButtonBase-root.delete-style, &.Mui-selected.MuiButtonBase-root:hover.delete-style": {
            color: "white",
            backgroundColor: paletteTheme.colors.DANGER,
            cursor: "pointer",
            "& .MuiSvgIcon-root, & .MuiTypography-root": {
                color: "white",
            },
            borderRadius: "3px"
        },
        "&:hover.MuiButtonBase-root, &.Mui-selected.MuiButtonBase-root:hover": {
            backgroundColor: paletteTheme.colors.SUCCESS,
            cursor: "pointer",
            borderRadius: "3px"
        },
        "&.Mui-selected": {
            borderRadius: "3px"
        },
    };

    return (
        <FormControl>
            <Select
                {...rest}
                style={{
                    width: "163px",
                }}
                labelId={id}
                value={value ? value.id : ""}
                multiple={multiple}
                displayEmpty
                MenuProps={{
                    ...menuProps,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    }
                }}
            renderValue={() =>
            <FlexBox
                alignItems={FlexAttributValue.CENTER}
                padding="2px"
                >
                <StarBorder fontSize="small" color="primary"/>
                <Typography
                    color="primary"
                    fontWeight={600}
                    marginLeft="5px">
                        {value ? value.name : <FormattedMessage id="sv_saved_views"/>}
                </Typography>
            </FlexBox>}
            IconComponent={(props) => <KeyboardArrowDown fontSize="small" {...props}/>}
            disabled={(disableOnUnique && optionsUnique.length === 1) || disabled}
            onChange={onChange}
            sx={{
            ...sx, height: "24px",
            "& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input": {
                height: "24px",
                paddingY: "0"
            }
        }}
            >
            {optionsUnique.map(item =>
                <MenuItem
                    value={item.id}
                    key={item.id}
                    disableRipple
                    disableTouchRipple
                    className={hoverDelete ? "delete-style" : ""}
                    sx={sxMenuItem}>
                    <FlexBox justifyContent={FlexAttributValue.SPACE_BETWEEN}
                             alignItems={FlexAttributValue.CENTER}
                             width="100%"
                             padding={0}
                    >
                        <FlexBox flexDirection={FlexAttributValue.ROW}>
                            <StarBorder fontSize="small"
                                        sx={{color: paletteTheme.colors.BACKGROUND_COLOR_SIDE_MENU}}/>
                            <Typography marginLeft="5px" paddingRight="2px" className="ellipsis">
                                {item.name}
                            </Typography>
                        </FlexBox>
                        <Clear fontSize="small" sx={{color: paletteTheme.colors.GRAY}}
                               onMouseEnter={() => setHoverDelete(true)}
                               onMouseLeave={() => setHoverDelete(false)}
                               onClick={(event) => {
                                   event.preventDefault()
                                   event.stopPropagation()
                                   handleDelete(item.id)
                               }}/>
                    </FlexBox>
                </MenuItem>)}
        </Select>
</FormControl>)
    ;
}


export default SelectView;
