import { styled } from "@mui/material/styles";
import {
  OutlinedTextField,
  OutlinedTextFieldProps,
} from "components/atoms/textfield/OutlinedTextField";
import { paletteTheme } from "styles/themes/palette";

// Used for styling DateRangePickerInput
export default styled(OutlinedTextField)<OutlinedTextFieldProps>(() => {
  return ({
    height: "24px",
    width: "118px",
    border: "none",
    "& .MuiInputLabel-root": {
      maxWidth: "82px"
    },
    "&:hover": {
      "& .MuiFormLabel-root.MuiInputLabel-root": {
        color: paletteTheme.colors.DEFAULT_TEXT_COLOR,
      }
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined, .MuiFormLabel-filled legend": {
      fontSize: 10,
      top: 11,
      fontWeight: 600,
    },
    "& .Mui-focused legend": {
      maxWidth: "0.01px"
    },
    "& .MuiFormLabel-filled + .Mui-focused legend": {
      maxWidth: "100%"
    },
    "& legend": {
      fontSize: 10,
    },
    "& .MuiFormLabel-filled + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: paletteTheme.colors.PRIMARY,
    },
    "& .MuiOutlinedInput-root": {
      caretColor: "transparent"
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined.MuiFormLabel-filled, & .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined.Mui-focused": {
      fontSize: 10,
      fontWeight: 600,
      color: paletteTheme.colors.PRIMARY,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined.MuiFormLabel-filled": {
      top: 0,
    },
    "& legend span": {
      "&:not(.notranslate)": {
        maxWidth: 79,
        paddingRight: "2px",
        paddingLeft: "2px"
      }
    },
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      transform: "translate(5px, -6px)",
      color: paletteTheme.colors.GRAY,
    },

    "& .MuiFormLabel-root.MuiInputLabel-root.MuiFormLabel-filled": {
      transform: "translate(4px, -8px)",
      color: paletteTheme.colors.GRAY,
      background: paletteTheme.colors.WHITE,
      padding: "0 1px"
    },
    "& .MuiInputBase-root": {
      height: "24px",
      width: "118px",
      fontSize: 10,
      fontWeight: 600,
      lineHeight: "100%",
      cursor: "pointer"
    },
    "& input": {
      width: "83px",
      padding: "3px 5px",
      cursor: "pointer"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      padding: "0 3px"
    }
  });
});
