import {
  AutocompleteCheckboxConfig,
  MultichoiceConfig,
  PerimeterConfig,
  CommonFilterProps,
  useWrappedFilterComponents,
  QueryAndResetConfig,
} from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { DOCUMENTS_FILTERS } from "constants/documents/documentsFilter";
import { FilterColumn } from "interfaces/filter/filterColumn";

export const useDocumentsFilterConfig = (
  sharedFilterProps?: CommonFilterProps,
  perimeterConfig?: PerimeterConfig,
  autoCompleteCheckboxConfig?: AutocompleteCheckboxConfig,
  multichoiceConfig?: MultichoiceConfig,
  queryAndResetConfig?: QueryAndResetConfig,
) => {

  const {
    onSearch,
    onReset,
    errors,
  } = sharedFilterProps;

  const {
    autocompleteCheckbox,
    multiChoiceCheckbox,
    queryAndReset,
  } = useWrappedFilterComponents();

  const filters: FilterColumn[] = [
    {
      name: "Year dropdown",
      component: multiChoiceCheckbox(DOCUMENTS_FILTERS.SETTLEMENT_YEAR, multichoiceConfig, "DOCUMENTS"),
      index: 1,
    },
    {
      name: "Month dropdown",
      component: multiChoiceCheckbox(DOCUMENTS_FILTERS.SETTLEMENT_MONTH, multichoiceConfig, "DOCUMENTS"),
      index: 2,
    },
    {
      name: "Account dropdown",
      component: autocompleteCheckbox("account", perimeterConfig, autoCompleteCheckboxConfig),
      index: 3,
    },
    {
      name: "Company dropdown",
      component: autocompleteCheckbox("company", perimeterConfig, autoCompleteCheckboxConfig),
      index: 4,
    },
    {
      name: "Merchant dropdown",
      component: autocompleteCheckbox("merchant", perimeterConfig, autoCompleteCheckboxConfig),
      index: 5,
    },
    {
      name: "Download and reset",
      component: queryAndReset(onSearch, onReset, errors, queryAndResetConfig),
      index: 6,
      config: {
        xs: { colSpan: 2 },
        sm: {
          colSpan: 1,
          colStart: 5,
        },
      },
    },
  ];

  return {
    filters,
  }
}
