import {useState} from "react";

interface UseFilterTooltip {
    displayTooltip: boolean;
    setDisplayTooltip: (displayTooltip: boolean) => void;
    closed: boolean;
    handleToggleFilter: (closed: boolean) => void
}

export const useFilterTooltip = (): UseFilterTooltip => {

    const [displayTooltip, setDisplayTooltip] = useState<boolean>(false);
    const [closed, setClosed] = useState<boolean>(true);

    const handleToggleFilter = (closed: boolean) => {
        setDisplayTooltip(false);
        setClosed(closed);
    }

    return {
        displayTooltip,
        setDisplayTooltip,
        closed,
        handleToggleFilter
    }
}
