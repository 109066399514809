import { MessageKeys } from "constants/messages/i8ln/EnglishMessages"
import { NotificationChannel, NotificationSettingsType } from "types/notifications/NotificationSettingsTypes";

const notifTypeToMessageCode: Record<NotificationSettingsType,MessageKeys> = {
    "NEW_MERCHANT": "notif_edit_merchant",
    "NEW_COMPANY":"notif_edit_company",
    "NEW_ACCOUNT": "notif_edit_acc",
    "NEW_OPERATOR": "notif_edit_operator",
    "NEW_MERCHANT_ADMIN":"notif_edit_merchant_admin", 
    "NEW_MARKETPAY_ADMIN": "notif_edit_markerpay_admin",
}

export function mapNotifTypeToMessageCode(
    notifType:NotificationSettingsType
):MessageKeys{
    return notifTypeToMessageCode[notifType];
}

const notificationChannelToCodeMap: Record<NotificationChannel, MessageKeys> = {
    EMAIL: "notif_email",
    IN_APP: "notif_in_app",
}

export function mapNotifChannelToMessageCode(
    channel:NotificationChannel
):MessageKeys{
    return notificationChannelToCodeMap[channel];
}

const profileToTypesMap: Record<string, NotificationSettingsType[]> = {
    OPERATOR: [
        NotificationSettingsType.NEW_MERCHANT, 
        NotificationSettingsType.NEW_COMPANY, 
        NotificationSettingsType.NEW_OPERATOR,
    ],
    MERCHANT_ADMIN: [
        NotificationSettingsType.NEW_MERCHANT, 
        NotificationSettingsType.NEW_COMPANY, 
        NotificationSettingsType.NEW_ACCOUNT,
        NotificationSettingsType.NEW_OPERATOR,
        NotificationSettingsType.NEW_MERCHANT_ADMIN,
    ],
    ADMIN: [
        NotificationSettingsType.NEW_MERCHANT, 
        NotificationSettingsType.NEW_COMPANY, 
        NotificationSettingsType.NEW_ACCOUNT,
        NotificationSettingsType.NEW_OPERATOR,
        NotificationSettingsType.NEW_MERCHANT_ADMIN,
        NotificationSettingsType.NEW_MARKETPAY_ADMIN,
    ],
}

export function getNotifTypesForProfile(
    profile:string,
): NotificationSettingsType[] {
    return profileToTypesMap[profile];
}

export const perimeterNotifTypes: NotificationSettingsType[] = [
    NotificationSettingsType.NEW_MERCHANT, 
    NotificationSettingsType.NEW_COMPANY, 
    NotificationSettingsType.NEW_ACCOUNT, 
];
export const userNotifTypes:NotificationSettingsType[] = [
    NotificationSettingsType.NEW_OPERATOR, 
    NotificationSettingsType.NEW_MERCHANT_ADMIN, 
    NotificationSettingsType.NEW_MARKETPAY_ADMIN
];
