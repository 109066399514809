import {
  saveAs
} from "file-saver";
import { BlobWithFilename } from "services/HttpService";
import { PromisableApiError } from "utils/api/errorUtils";
import { toastUtils } from "utils/toast/toastUtils";

interface DownloadOptions {
  onEndDownload: () => void,
  handleError: (e: PromisableApiError) => void
}

function download(blob: Blob, filename?: string): Promise<string | number | void> {
  return Promise
    .resolve(blob.arrayBuffer()
      .then(response => {
        const data = new Blob([response]);
        saveAs(data, filename, { autoBom: false });
      })
      .catch((e: Error) => toastUtils.errorToast(e.message)));
}

const downloadWithFilename = async (blobWithName: BlobWithFilename, defaultFileName: string) => {
  const { filename, blob } = blobWithName;
  const filenameToUse = filename ?? defaultFileName;
  return await download(await blob, filenameToUse);
};

function downloadPromise(
  promise: Promise<BlobWithFilename>, {
    onEndDownload,
    handleError
  }: DownloadOptions,
  defaultFileName: string
): void {
  promise
    .then(res => downloadWithFilename(res, defaultFileName)
      .catch((e: PromisableApiError) => {
        handleError(e)
      })
    )
    .catch((e: Error) => toastUtils.errorToast(e.message))
    .finally(() => onEndDownload());
}

export const fileUtils = {
  downloadPromise,
  downloadWithFilename,
}
