import { useState } from "react";
import { fileUtils } from "utils/common/fileUtils";
import { errorUtils } from "utils/api/errorUtils";
import { ExportReferential } from "constants/export/export";
import { BlobWithFilename } from "services/HttpService";

interface UseExport {
  openManyExportLine: boolean,
  setOpenManyExportLine: (openManyExportLine: boolean) => void
  isExportLoading: boolean,
  onDownload: (type: ExportReferential) => void
}

export const useExport = (
  count: number,
  maxElements: string,
  download: (id?: string) => Promise<BlobWithFilename>,
  filename: string,
): UseExport => {

  const [openManyExportLine, setOpenManyExportLine] = useState<boolean>(false);
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  const onDownload = (type: ExportReferential) => {
    if (count <= parseInt(maxElements)) {
      setIsExportLoading(true);
      fileUtils.downloadPromise(
        download(type?.id),
        {
          onEndDownload: () => setIsExportLoading(false),
          handleError: errorUtils.handleBackErrors
        },
         filename + type?.ext
      )
    } else {
      setOpenManyExportLine(true);
    }
  }

  return {
    openManyExportLine,
    setOpenManyExportLine,
    isExportLoading,
    onDownload,
  }
}
