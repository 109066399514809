type FlexStandard = "flex-start" | "flex-end" | "center";
type FlexSpace = "space-between" | "space-around";
type FlexStretch = "stretch";
type Inherit = "inherit";

export type FlexDirectionType = Inherit | "row" | "row-reverse" | "column" | "column-reverse";

export type JustifyContentType = Inherit | FlexStandard | FlexSpace | "space-evenly";

export type AlignItemsType = Inherit | FlexStandard | FlexStretch | "baseline";

export type AlignContentType = Inherit | FlexStandard | FlexSpace | FlexStretch;

export type FlexWrapType = Inherit | "nowrap" | "wrap" | "wrap-reverse";

export type FlexDisplayType = Inherit | "flex" | "inline-flex" | "none";


export enum FlexAttributValue {
    INHERIT = "inherit",
    FLEX = "flex",
    INLINE = "inline-flex",
    ROW = "row",
    ROW_REVERSE = "row-reverse",
    COLUMN = "column",
    COLUMN_REVERSE = "column-reverse",
    START = "flex-start",
    END = "flex-end",
    CENTER = "center",
    NOWRAP = "nowrap",
    WRAP = "wrap",
    WRAP_REVERSE = "wrap-reverse",
    SPACE_EVENLY = "space-evenly",
    BASELINE = "baseline",
    SPACE_BETWEEN = "space-between",
    SPACE_AROUND = "space-around",
    STRECTCH = "stretch",
    NONE = "none"
}

