import {useState} from "react";
import {ValidateErrorMap} from "../../components/molecules/useraccount/edit/UserAccountEdit";


interface ValidationInstance {
    errors: ValidateErrorMap,
    setErrors: (error: ValidateErrorMap) => void,
    validated: () => boolean,
    isValidated: boolean,
}

export const useValidation = (validations: () => ValidateErrorMap): ValidationInstance => {
    const [errors, setErrors] = useState<ValidateErrorMap>({});
    const [isValidated, setIsValidated] = useState<boolean>(false);

    const validated = () => {
        const validationError = validations();
        if (Object.keys(validationError).length > 0) {
            setErrors(validationError);
            setIsValidated(true)
            return false;
        }

        return true;
    }

    return {
        errors,
        setErrors,
        validated,
        isValidated
    }


}
