import { ReactComponent as FilledCheckedDatabase } from "../../assets/FilledCheckedDatabase.svg";
import { ReactComponent as CheckedDatabase } from "../../assets/CheckedDatabase.svg";
import { ReactComponent as Bill } from "../../assets/Bill.svg";
import React from "react";
import { KeyNumberElement } from "../../interfaces/transaction/transaction";

export const SETTLEMENT_KEY_NUMBERS: KeyNumberElement[] = [
  {
    id: "totalGrossAmount",
    label: "card_settlements_gross_am",
    currency: true,
    icon: <FilledCheckedDatabase />,
  },
  {
    id: "totalCommission",
    label: "card_settlements_comm",
    currency: true,
    icon: <Bill />,
  },
  {
    id: "totalFinanced",
    label: "card_settlements_fin_am",
    currency: true,
    icon: <CheckedDatabase />,
  },
];

export const DAILY_SETTLEMENT_KEY_NUMBERS: KeyNumberElement[] = [
  {
    id: "lastGrossAmount",
    label: "homepage_key_numb_settlement_gr_am",
    currency: true,
    icon: <FilledCheckedDatabase />,
  },
  {
    id: "lastFees",
    label: "homepage_key_numb_fees",
    currency: true,
    icon: <Bill />,
  },
  {
    id: "lastFinanced",
    label: "homepage_key_numb_fin",
    currency: true,
    icon: <CheckedDatabase />,
  },
];

export type SettlementColumnName =
  | "PAYMENT_SOLUTION"
  | "BATCH_ID"
  | "SETTLEMENT_DATE"
  | "FEES"
  | "GROSS_AMOUNT_SETTLEMENTS"
  | "SETTLED_AMOUNT"
  | "VIEW_TRANSACTION"
  | "STORE_NAME";
