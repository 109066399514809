import React, {
    FunctionComponent,
    RefObject, useEffect, useRef, useState
} from "react";
import {
    CardProps,
    Typography,
    TypographyProps
} from "@mui/material";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { formatToEuro } from "constants/intl/number/format";
import { paletteTheme } from "styles/themes/palette";
import OverflowTooltip from "components/molecules/tooltip/OverflowTooltip";
import styles from "components/molecules/keyNumbersCards/keyNumberCard/keyNumberCard.module.scss";
import { useDebounce } from "hooks/debounce/useDebounce";

export interface KeyNumberCardProps extends CardProps {
    number: number,
    currency?: boolean,
    legend: string,
    icon?: JSX.Element,
}

const calculateFontSize = (width: number, formattedNumber: string, minFont = 12, maxFont = 29): string => {
    const adjustedFontSize = Math.round((width / formattedNumber.length) * 1.75);
    const font = Math.min(Math.max(minFont, adjustedFontSize), maxFont);
    return `${font}px`;
}

export const KeyNumberCard: FunctionComponent<KeyNumberCardProps> = ({
    number,
    currency = false,
    legend,
    icon,
}) => {

    const { formatNumber } = useIntlFormatter();
    const formattedNumber = (_number: number): string => currency ? formatNumber(_number, formatToEuro) : formatNumber(_number);

    const numberWrapperRef: RefObject<HTMLDivElement> = useRef(null);
    const [fontSize, setFontSize] = useState("29px");

    const debouncedVal = useDebounce((entries: ResizeObserverEntry[]) => {
        entries.forEach((entry) => {
            const font = calculateFontSize(Math.round(entry.contentRect.width), formattedNumber(number));
            setFontSize(font)
        });
    }, 100);

    useEffect(() => {
        const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            debouncedVal(entries);
        });

        if (numberWrapperRef.current) {
            observer.observe(numberWrapperRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [formattedNumber]);



    const overflowTypographyProps: TypographyProps = {
        fontWeight: "bold",
        color: paletteTheme.colors.GREEN_SOFT,
        fontSize: fontSize,
        lineHeight: "29px",
    };

    return (
        <div className={`flex flex-col ${styles.wrapper}`}>
            <div className={`flex flex-row-between ${styles["amount-icon-row"]}`}>
                <div ref={numberWrapperRef}
                    className={styles["amount-row"]}>
                    <OverflowTooltip overflowText={formattedNumber(number)}
                        typographyProps={overflowTypographyProps}>
                        {formattedNumber(number)}
                    </OverflowTooltip>
                </div>
                <div className={styles["icon-row"]}>
                    <div className={`flex ${styles["icon-wrap"]}`}>
                        {icon}
                    </div>
                </div>
            </div>
            <div className={`${styles["title-row"]}`}>
                <Typography variant="h3" color={paletteTheme.colors.GREEN_SOFT}>
                    {legend}
                </Typography>
            </div>
        </div>
    );
}
