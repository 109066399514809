/**
 * File listing every route of the application
 * Route can accept children. All routes are generated recursivly
 */

import { COMPONENTS_PATH, HOME_PATH, LOGIN_PATH } from "constants/routes/RoutePaths";
import MenuRoutes from "constants/routes/subroutes/MenuRoutes";
import { UserRoutes } from "constants/routes/subroutes/UserRoutes";
import ResetPasswordRoutes from "constants/routes/subroutes/ResetPasswordRoutes";
import { UpdatePasswordRoutes } from "constants/routes/subroutes/UpdatePasswordRoutes";
import { AppRouteInterface } from "interfaces/RouteInterfaces";
import { AppLayout } from "components/layouts/AppLayout";
import { AuthLayout } from "components/layouts/AuthLayout";
import HomeView from "view/HomeView";
import LoginView from "view/LoginView";
import ComponentsView from "view/component/ComponentsView";

const devRoute: AppRouteInterface[] = process.env.REACT_APP_ENV !== "prod" ?
  [{
    path: COMPONENTS_PATH,
    private: false,
    element: ComponentsView,
    index: true,
  }] : []

export const AppRoutes: AppRouteInterface[] = [
  {
    path: HOME_PATH,
    private: true,
    element: AppLayout,
    children: [
      ...MenuRoutes,
      UserRoutes,
      {
        path: "*",
        private: true,
        element: HomeView
      }
    ]
  },
  {
    private: false,
    element: AuthLayout,
    children: [
      {
        path: LOGIN_PATH,
        private: false,
        index: true,
        element: LoginView,
      },
      ResetPasswordRoutes,
      UpdatePasswordRoutes,
    ]
  },
  ...devRoute
]
