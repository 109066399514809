import { TypeColumnName } from "../../types/transaction/transaction";
import { ManagableColumn, ManageableColumnItem } from "interfaces/table/table";

// Enumeration name must never be changed
export const TRANSACTION_POSSIBLE_COLUMNS: Record<TypeColumnName, ManagableColumn> = {
  AUTHORISATION_CODE: {
    id: "AUTHORISATION_CODE",
    accessor: "authorisationCode",
    header: "col_man_authorisation_code",
    order: 16,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  BANK_DOMICILIATION: {
    id: "BANK_DOMICILIATION",
    accessor: "bankAccountDomiciliationCountry",
    header: "col_man_bank_acc_dom_country",
    order: 19,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  CARD_ISSUING_BANK: {
    id: "CARD_ISSUING_BANK",
    accessor: "cardIssuingBank",
    header: "col_man_card_iss_bank",
    order: 20,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  CARD_ISSUING_COUNTRY: {
    id: "CARD_ISSUING_COUNTRY",
    accessor: "cardIssuingCountry",
    header: "col_man_card_iss_coun",
    order: 21,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  CARD_LEVEL: {
    id: "CARD_LEVEL",
    accessor: "cardLevel",
    header: "col_man_card_level",
    order: 22,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  CARD_TYPE: {
    id: "CARD_TYPE",
    accessor: "cardType",
    header: "col_man_card_type",
    cell: {
      type: "text",
    },
    order: 23,
    disableSortBy: true,
  },
  PAYMENT_METHOD: {
    id: "PAYMENT_METHOD",
    accessor: "paymentMethod",
    header: "col_man_payment_method",
    order: 13,
    cell: {
      type: "text",
    },
    disableSortBy: false,
  },
  PAYMENT_SOLUTION: {
    id: "PAYMENT_SOLUTION",
    accessor: "paymentSolution",
    header: "col_man_payment_solution",
    order: 14,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  PAYMENT_TYPE: {
    id: "PAYMENT_TYPE",
    accessor: "paymentType",
    header: "col_man_payment_type",
    order: 15,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  CHECKOUT_TYPE: {
    id: "CHECKOUT_TYPE",
    accessor: "checkoutType",
    header: "col_man_checkout_type",
    order: 12,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  PAN_FIRST_SIX_DIGITS: {
    id: "PAN_FIRST_SIX_DIGITS",
    accessor: "panfirstSixDigits",
    header: "col_man_pan_first_six",
    order: 24,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  PAN_LAST_FOUR_DIGITS: {
    id: "PAN_LAST_FOUR_DIGITS",
    accessor: "panlastFourDigits",
    header: "col_man_pan_last_four",
    order: 25,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  POS_ID: {
    id: "POS_ID",
    accessor: "posID",
    header: "col_man_pos_id",
    order: 17,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  POS_NAME: {
    id: "POS_NAME",
    accessor: "posName",
    header: "col_man_pos_name",
    order: 18,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  SCHEME: {
    id: "SCHEME",
    accessor: "scheme",
    header: "col_man_scheme",
    order: 26,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  TRANSACTIONAL_EVENT: {
    id: "TRANSACTIONAL_EVENT",
    accessor: "transactionalEvent",
    header: "col_man_trans_event",
    order: 2,
    cell: {
      type: "text",
    },
    disableSortBy: false,
  },
  EVENT_TIMESTAMP: {
    id: "EVENT_TIMESTAMP",
    accessor: "eventTimestamp",
    header: "col_man_event_timestamp",
    order: 4,
    cell: {
      type: "text",
    },
    disableSortBy: false,
  },
  TRANSACTIONAL_ROOT_EVENT_ID: {
    id: "TRANSACTIONAL_ROOT_EVENT_ID",
    accessor: "rootTransactionalEventId",
    header: "col_man_root_trans_event_id",
    order: 0,
    cell: {
      type: "text",
    },
    disableSortBy: false,
  },
  TRANSACTIONAL_EVENT_ID: {
    id: "TRANSACTIONAL_EVENT_ID",
    accessor: "transactionalEventId",
    header: "col_man_trans_event_id",
    order: 1,
    cell: {
      type: "text",
    },
    disableSortBy: false,
  },
  EVENT_STATUS: {
    id: "EVENT_STATUS",
    accessor: "eventStatus",
    header: "col_man_status",
    order: 3,
    cell: {
      type: "text",
    },
    disableSortBy: false,
  },
  ACCOUNT_NAME: {
    id: "ACCOUNT_NAME",
    accessor: "accountName",
    header: "col_man_account_name",
    order: 28,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  COMPANY_NAME: {
    id: "COMPANY_NAME",
    accessor: "companyName",
    header: "col_man_company_name",
    order: 29,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  CONTRACT_NUMBER: {
    id: "CONTRACT_NUMBER",
    accessor: "contractNumberMerchant",
    header: "col_man_contract_num",
    order: 31,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  COUNTRY_NAME: {
    id: "COUNTRY_NAME",
    accessor: "countryName",
    header: "col_man_country",
    order: 30,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  STORE_NAME: {
    id: "STORE_NAME",
    accessor: "storeName",
    header: "col_man_merchant_name",
    order: 8,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  GROSS_AMOUNT: {
    id: "GROSS_AMOUNT",
    accessor: "grossAmountTransaction",
    header: "col_man_gross_amount",
    order: 7,
    cell: {
      type: "text",
    },
    disableSortBy: false,
  },
  NET_AMOUNT: {
    id: "NET_AMOUNT",
    accessor: "netAmount",
    header: "col_man_net_amount",
    order: 9,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  MERCHANT_TRANSACTION_ID: {
    id: "MERCHANT_TRANSACTION_ID",
    accessor: "merchantTransactionId",
    header: "col_man_merch_trans_id",
    order: 27,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  ORDER_NUMBER: {
    id: "ORDER_NUMBER",
    accessor: "orderNumber",
    header: "col_man_order_num",
    order: 11,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  OPERATION_DATE: {
    id: "OPERATION_DATE",
    accessor: "operationDate",
    header: "tpd_op_date_utc",
    order: 5,
    cell: {
      type: "date",
    },
    disableSortBy: false,
  },
  OPERATION_DATETIME_LOCAL: {
    id: "OPERATION_DATETIME_LOCAL",
    accessor: "operationDatetimeLocal",
    header: "col_operation_time_local",
    order: 6,
    cell: {
      type: "text",
    },
  },
  OPERATION_DATETIME_UTC: {
    id: "OPERATION_DATETIME_UTC",
    accessor: "operationDatetimeUTC",
    header: "col_operation_time_utc",
    order: 6,
    cell: {
      type: "text",
    },
  },
  TRANSACTION_TYPE: {
    id: "TRANSACTION_TYPE",
    accessor: "transactionType",
    header: "col_man_trans_type",
    order: 10,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  ACQUIRER: {
    id: "ACQUIRER",
    accessor: "acquirer",
    header: "col_man_acquirer",
    order: 32,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  BATCH_ID: {
    id: "BATCH_ID",
    accessor: "batchId",
    header: "col_man_settlement_batch",
    order: 33,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  FINANCING_DATE: {
    id: "FINANCING_DATE",
    accessor: "financingDate",
    header: "col_man_settlement_date",
    order: 34,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  FINANCING_STATUS: {
    id: "FINANCING_STATUS",
    accessor: "financingStatus",
    header: "col_man_settlement_status",
    order: 35,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  INTERCHANGE: {
    id: "INTERCHANGE",
    accessor: "interchange",
    header: "col_man_interchange",
    order: 36,
    cell: {
      type: "text",
    },
    disableSortBy: true,
    disableColumn: true,
  },
  MARKETPAY_FEES: {
    id: "MARKETPAY_FEES",
    accessor: "marketpayFees",
    header: "col_man_mkp_fees",
    order: 37,
    cell: {
      type: "text",
    },
    disableSortBy: true,
    disableColumn: true,
  },
  SCHEME_FEES: {
    id: "SCHEME_FEES",
    accessor: "schemeFees",
    header: "col_man_scheme_fees",
    order: 38,
    cell: {
      type: "text",
    },
    disableSortBy: true,
    disableColumn: true,
  },
  TOTAL_COMMISSION: {
    id: "TOTAL_COMMISSION",
    accessor: "totalCommission",
    header: "col_man_total_fees",
    order: 39,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  COLLECTION_TYPE: {
    id: "COLLECTION_TYPE",
    accessor: "collectionTypePayonline",
    // accessor: "collectionType",
    header: "filter_collection_type",
    order: 40,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  SERVICE_TYPE: {
    id: "SERVICE_TYPE",
    accessor: "servicePayOnline",
    header: "filter_service_type",
    order: 41,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  }
};

export const ACCPETATION_COLUMNS: ManagableColumn[] = [
  TRANSACTION_POSSIBLE_COLUMNS.AUTHORISATION_CODE,
  TRANSACTION_POSSIBLE_COLUMNS.CHECKOUT_TYPE,
  TRANSACTION_POSSIBLE_COLUMNS.PAYMENT_METHOD,
  TRANSACTION_POSSIBLE_COLUMNS.PAYMENT_SOLUTION,
  TRANSACTION_POSSIBLE_COLUMNS.PAYMENT_TYPE,
  TRANSACTION_POSSIBLE_COLUMNS.POS_ID,
  TRANSACTION_POSSIBLE_COLUMNS.POS_NAME,
].filter((column) => !column.disableColumn);

export const CUSTOMER_COLUMNS: ManagableColumn[] = [
  TRANSACTION_POSSIBLE_COLUMNS.BANK_DOMICILIATION,
  TRANSACTION_POSSIBLE_COLUMNS.CARD_ISSUING_BANK,
  TRANSACTION_POSSIBLE_COLUMNS.CARD_ISSUING_COUNTRY,
  TRANSACTION_POSSIBLE_COLUMNS.CARD_LEVEL,
  TRANSACTION_POSSIBLE_COLUMNS.CARD_TYPE,
  TRANSACTION_POSSIBLE_COLUMNS.PAN_FIRST_SIX_DIGITS,
  TRANSACTION_POSSIBLE_COLUMNS.PAN_LAST_FOUR_DIGITS,
  TRANSACTION_POSSIBLE_COLUMNS.SCHEME,
].filter((column) => !column.disableColumn);

export const HISTORY_COLUMNS: ManagableColumn[] = [
  TRANSACTION_POSSIBLE_COLUMNS.TRANSACTIONAL_EVENT,
  TRANSACTION_POSSIBLE_COLUMNS.EVENT_TIMESTAMP,
  TRANSACTION_POSSIBLE_COLUMNS.EVENT_STATUS,
  TRANSACTION_POSSIBLE_COLUMNS.TRANSACTIONAL_EVENT_ID,
].filter((column) => !column.disableColumn);

export const TRANSACTION_COLUMNS: ManagableColumn[] = [
  TRANSACTION_POSSIBLE_COLUMNS.GROSS_AMOUNT,
  TRANSACTION_POSSIBLE_COLUMNS.MERCHANT_TRANSACTION_ID,
  TRANSACTION_POSSIBLE_COLUMNS.ORDER_NUMBER,
  TRANSACTION_POSSIBLE_COLUMNS.NET_AMOUNT,
  TRANSACTION_POSSIBLE_COLUMNS.OPERATION_DATE,
  TRANSACTION_POSSIBLE_COLUMNS.OPERATION_DATETIME_LOCAL,
  TRANSACTION_POSSIBLE_COLUMNS.OPERATION_DATETIME_UTC,
  TRANSACTION_POSSIBLE_COLUMNS.TRANSACTION_TYPE,
].filter((column) => !column.disableColumn);

export const MERCHANT_COLUMNS: ManagableColumn[] = [
  TRANSACTION_POSSIBLE_COLUMNS.ACCOUNT_NAME,
  TRANSACTION_POSSIBLE_COLUMNS.COMPANY_NAME,
  TRANSACTION_POSSIBLE_COLUMNS.COUNTRY_NAME,
  TRANSACTION_POSSIBLE_COLUMNS.CONTRACT_NUMBER,
  TRANSACTION_POSSIBLE_COLUMNS.COLLECTION_TYPE,
  TRANSACTION_POSSIBLE_COLUMNS.SERVICE_TYPE,
  TRANSACTION_POSSIBLE_COLUMNS.STORE_NAME,
].filter((column) => !column.disableColumn);

export const FINANCING_COLUMNS: ManagableColumn[] = [
  TRANSACTION_POSSIBLE_COLUMNS.ACQUIRER,
  TRANSACTION_POSSIBLE_COLUMNS.BATCH_ID,
  TRANSACTION_POSSIBLE_COLUMNS.FINANCING_DATE,
  TRANSACTION_POSSIBLE_COLUMNS.FINANCING_STATUS,
  TRANSACTION_POSSIBLE_COLUMNS.INTERCHANGE,
  TRANSACTION_POSSIBLE_COLUMNS.MARKETPAY_FEES,
  TRANSACTION_POSSIBLE_COLUMNS.SCHEME_FEES,
  TRANSACTION_POSSIBLE_COLUMNS.TOTAL_COMMISSION,
].filter((column) => !column.disableColumn);

export const DEFAULT_COLUMNS: ManagableColumn[] = [
  TRANSACTION_POSSIBLE_COLUMNS.TRANSACTIONAL_ROOT_EVENT_ID,
  TRANSACTION_POSSIBLE_COLUMNS.TRANSACTIONAL_EVENT,
  TRANSACTION_POSSIBLE_COLUMNS.EVENT_STATUS,
  TRANSACTION_POSSIBLE_COLUMNS.OPERATION_DATE,
  TRANSACTION_POSSIBLE_COLUMNS.GROSS_AMOUNT,
  TRANSACTION_POSSIBLE_COLUMNS.PAYMENT_METHOD,
  TRANSACTION_POSSIBLE_COLUMNS.FINANCING_STATUS,
].filter((column) => !column.disableColumn);

export const TRANSACTION_MANAGEABLE_COLUMNS: ManageableColumnItem[] = [
  { label: "col_man_titles_acceptation", columns: ACCPETATION_COLUMNS },
  { label: "col_man_titles_last_event", columns: HISTORY_COLUMNS },
  { label: "col_man_titles_transaction", columns: TRANSACTION_COLUMNS },
  { label: "col_man_titles_customer", columns: CUSTOMER_COLUMNS },
  { label: "col_man_titles_settlement", columns: FINANCING_COLUMNS },
  { label: "col_man_titles_merchant", columns: MERCHANT_COLUMNS },
];
