import React, {FunctionComponent} from "react";
import { Card as CardMaterialUI, CardProps as CardPropsMaterialUI, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import styles from "components/atoms/card/Card/card.module.scss";

interface CardProps extends CardPropsMaterialUI {
    cardTitle?: string | JSX.Element,
    header?: string
    headerMarginBottom?: string | number;
}

const Card: FunctionComponent<CardProps> = ({
                                                children,
                                                sx,
                                                cardTitle,
                                                header,
                                                headerMarginBottom = "30px",
                                                ...rest
                                            }) =>
    (<CardMaterialUI {...rest}  sx={{...sx}}>
         {cardTitle && <div className={styles.wrapCardTitle}>{typeof cardTitle === "string" ?
            <FormattedMessage id={cardTitle}/> : cardTitle} </div>}
        {header &&
            <Typography variant="h2" style={{marginBottom: headerMarginBottom}}>
                <FormattedMessage id={header}/>
            </Typography>}
        {children}
    </CardMaterialUI>)


export default Card;
