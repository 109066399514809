import { EmailOutlined } from "@mui/icons-material";
import ButtonOneShot from "components/atoms/button/ButtonOneShot";
import { useInitUser } from "hooks/account/useInitUser";
import { IUserCellProps } from "interfaces/table/table";
import React, { FunctionComponent } from "react";

export const WelcomeEmailCell: FunctionComponent<IUserCellProps> = ({ cell }) => {
  const { isClicked, isLoading, onInitUser } = useInitUser(
    {
      email: cell.row.original.email,
      initialized: cell.row.original.initialized,
    },
    cell
  );
  return (
    <div className="flex flex-center" style={{ height: "100%" }}>
      <ButtonOneShot
        loading={isLoading}
        startIcon={<EmailOutlined fontSize="small" sx={{ ":nth-of-type(1)": { fontSize: 14 } }} />}
        onClick={onInitUser}
        startIconMargin="0px"
        isClicked={isClicked}
      />
    </div>
  );
};
