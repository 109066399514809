import React, {
  FunctionComponent,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { UserDetails } from "interfaces/user/user";
import ContainedButton from "components/atoms/button/ContainedButton";
import UserEdit from "components/atoms/icons/UserEdit";
import { FormattedMessage } from "react-intl";
import OutlinedButton from "components/atoms/button/OutlinedButton";
import Card from "components/atoms/card/Card";
import { PermissionDisplay } from "components/molecules/permission/PermissionDisplay";
import {
  PERMISSIONS,
  PORTFOLIO_PERMISSIONS,
  SETTLEMENTS_PERMISSIONS,
  TRANSACTIONS_PERMISSIONS,
  USERS_PERMISSIONS,
} from "constants/permissions/permissions";
import { MaterialUiColor } from "types/materialui/materialui";
import { useAuthUser } from "hooks/useAuthUser";
import { rightUtils } from "utils/business/rightUtils";
import { IntlMessage } from "constants/intl/intl";
import ConfirmDialog from "components/molecules/dialog/ConfirmDialog";
import { userService } from "services/user/UserService";
import { USER_PATH } from "constants/routes/RoutePaths";
import {
  errorUtils,
  PromisableApiError,
} from "utils/api/errorUtils";
import { toastUtils } from "utils/toast/toastUtils";
import { UserAccountInformationDetails } from "components/molecules/useraccount/details/UserAccountInformationDetails";
import {
  DeleteForeverOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import { createUserUtils } from "utils/business/createUserUtils";
import { BackLink } from "components/molecules/BackLink";
import styles from "components/molecules/useraccount/details/UserAccountDetails/userAccoundDetails.module.scss";
import { UserAccountPayWishUser } from "components/molecules/useraccount/details/UserAccountPayWishUser";

interface AccountDetailsProps {
  title: string | IntlMessage;
  userDetails: UserDetails;
  authPermissions: Array<string>;
  idUser: string;
  onClickEdit: () => void;
}

export const UserAccountDetails: FunctionComponent<AccountDetailsProps> = ({
  title,
  userDetails,
  authPermissions,
  onClickEdit,
  idUser,
  children,
}) => {
  const { user, permissions } = useAuthUser();
  const isOtherUser = !!idUser;
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    const ac = new AbortController();
    userService.deleteUser(parseInt(idUser), { signal: ac.signal })
      .then(() => {
        toastUtils.successToastI8ln("delete_account_success");
        navigate(`/${USER_PATH}`);
      }).catch((e: PromisableApiError) => {
        errorUtils.handleBackErrors(e);
      })
    return () => ac.abort();
  }

  const deleteTitle: IntlMessage = userDetails && { id: "delete_account_title", values: { email: userDetails.email } };
  const displayBacklink = createUserUtils.isNewUser(user) || (idUser && !createUserUtils.isSelfFromParam(idUser));

  return (
    <>
      <UserAccountInformationDetails
        cardTitle={title}
        user={userDetails}
        idUser={idUser}
        onEdit={onClickEdit}
        self={!isOtherUser}
        permissions={permissions}
        onDelete={() => setOpenDeleteDialog(true)} />
      <Card header="peri">
        {children}
      </Card>
      {userDetails.paywishRole &&
        <Card header="title_paywish">
          <UserAccountPayWishUser paywishRole={userDetails.paywishRole}/>
        </Card>
      }
      <Card header="permissions">
        {userDetails && userDetails.permissions &&
          <div className={styles.container}>
            <div className={styles.col}>
              <PermissionDisplay category={TRANSACTIONS_PERMISSIONS} userDetails={userDetails}
                authPermissions={authPermissions} />
              <PermissionDisplay category={PORTFOLIO_PERMISSIONS} userDetails={userDetails}
                authPermissions={authPermissions} />
            </div>
            <div className={styles.col}>
              <PermissionDisplay category={SETTLEMENTS_PERMISSIONS} userDetails={userDetails}
                authPermissions={authPermissions} />
              <PermissionDisplay category={USERS_PERMISSIONS} userDetails={userDetails}
                authPermissions={authPermissions} />
            </div>
          </div>
        }
      </Card>

      <div style={{ justifyContent: "center", display: "flex", position: "relative" }} >
        {displayBacklink &&
          <div className={styles.backlink}>
            <BackLink to={`/${USER_PATH}`} label="back_user_list" />
          </div>
        }

        {(isOtherUser && rightUtils.hasPermission(permissions, PERMISSIONS.USER_DELETE)) &&
          <div className={styles["btn-wrap"]}>
            <OutlinedButton
              onClick={() => setOpenDeleteDialog(true)}
              color={MaterialUiColor.SECONDARY}
              startIcon={<DeleteForeverOutlined />}
              sx={{ marginRight: 2 }}>
              <FormattedMessage id="delete" />
            </OutlinedButton>
          </div>
        }

        {(!isOtherUser || rightUtils.hasPermission(permissions, PERMISSIONS.USER_UPDATE)) &&
          <div className={styles["btn-wrap"]}>
            <ContainedButton onClick={onClickEdit}
              startIcon={<UserEdit />}>
              <FormattedMessage id="edit_button" />
            </ContainedButton>
          </div>
        }

      </div>

      <ConfirmDialog openDialog={openDeleteDialog}
        onCloseDialog={() => setOpenDeleteDialog(false)}
        handleSubmit={handleSubmit}
        dialogIcon={<ErrorOutlineOutlined color="primary" fontSize="large"></ErrorOutlineOutlined>}
        idMessage={deleteTitle}
        idConfirmMessage="delete_account_dialog_box"
        submitMessage="delete">
      </ConfirmDialog>
    </>
  )
}
