import React, { FunctionComponent } from "react";
import { LanguageDropdown } from "components/molecules/menu/LanguageDropdown";
import { LogoArea } from "components/molecules/logoarea";
import HeaderNotification from "components/molecules/notification/HeaderNotification";
import Help from "@mui/icons-material/Help";
import styles from "components/molecules/header/header.module.scss";
import { FEEDBACK_LINK, HELP_LINK } from "constants/menu/menu";
import { BorderButton } from "components/atoms/button/BorderButton";
import { useIntl } from "react-intl";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface HeaderProps {
  className?: string;
  mobileMenuOpen: boolean;
  mobile: boolean;
  onMenuClick: () => void;
}

export interface HeaderCookie {
  [key: string]: string;
}

export const Header: FunctionComponent<HeaderProps> = ({ mobileMenuOpen, mobile, onMenuClick }) => {
  const intl = useIntl();
  const { smDown } = useMediaQueryUtil();
  return (
    <header className={styles.header}>
      <div
        className={`flex flex-col-center ${styles["logo-area"]} ${mobileMenuOpen ? styles["mobile-menu-open"] : ""}`}>
        <LogoArea mobileMenuOpen={mobileMenuOpen} mobile={mobile} onMenuClick={onMenuClick} />
      </div>
      {mobileMenuOpen && <div className={styles["header-underlay"]}></div>}
      <div className={`flex flex-row-end flex-col-center ${styles["icon-area"]}`}>
        {!smDown && (
          <div className={`flex flex-center ${styles["feedback"]}`}>
            <BorderButton href={FEEDBACK_LINK} target="_blanc" title={FEEDBACK_LINK}>
              {intl.formatMessage({ id: "send_feedback" })}
            </BorderButton>
          </div>
        )}

        <a className={`flex flex-center ${styles.icon}`} href={HELP_LINK} target="_blanc" title={HELP_LINK}>
          <Help className={styles["help-icon"]} />
        </a>
        <div className={`flex flex-center ${styles.icon}`}>
          <HeaderNotification />
        </div>
        {!mobile && (
          <div className={styles["switch-wrap"]}>
            <LanguageDropdown mobile={false} onClickMenuItem={undefined} subMenuOpen={false} />
          </div>
        )}
      </div>
    </header>
  );
};
