import React, {FunctionComponent, useRef, useState} from "react";
import {IconButtonProps} from "@mui/material";
import Badge from "@mui/material/Badge";
import Notifications from "@mui/icons-material/Notifications";
import NotificationPopover from "../NotificationPopover";
import {useNotificationContext} from "../../../../constants/context/NotificationContext";
import styles from "./headerNotification.module.scss";

const HeaderNotification: FunctionComponent<IconButtonProps> = () => {
    const anchorRef = useRef<HTMLElement | null>(null);
    const [isOpenNotif, setIsOpenNotif] = useState<boolean>(false);

    const {
        hasUserActiveNotifications,
        userActiveNotificationsCount
    } = useNotificationContext();
 
    const openNotif = () => setIsOpenNotif(true);

    const closeNotif = () => setIsOpenNotif(false);  

    
    return (
        <>
            <Badge     
                ref={anchorRef}             
                badgeContent={userActiveNotificationsCount}
                invisible={!hasUserActiveNotifications || isOpenNotif}
                classes={{root: `${styles.badge} ${isOpenNotif ? styles.open : ""}`,  badge: `${styles.label} ${userActiveNotificationsCount > 99 ? styles.labelSmall : ""}`}}
               >
                <Notifications
                    onClick={openNotif}
                    classes={{root: `${styles.notification}`}}
                />
            </Badge>

            <NotificationPopover
                open={isOpenNotif}
                onClose={closeNotif}
                anchorEl={anchorRef.current}
                hasUserActiveNotifications={hasUserActiveNotifications}
                userActiveNotificationsCount={userActiveNotificationsCount}
            >
            </NotificationPopover>
        </>
    )
}

export default HeaderNotification;
