import React, {
  FunctionComponent,
  useState,
} from "react";
import { UserAccountEdit } from "components/molecules/useraccount/edit/UserAccountEdit";
import { UserAccountDetails } from "components/molecules/useraccount/details/UserAccountDetails";
import { UserDetails } from "interfaces/user/user";
import { Perimeter } from "interfaces/perimeter/perimeter";
import { createUserUtils } from "utils/business/createUserUtils";
import { IntlMessage } from "constants/intl/intl";
import { SaveOutlined } from "@mui/icons-material";
import { UserAccountPerimeterDetails } from "components/molecules/useraccount/details/UserAccountPerimeterDetails";
import { rightUtils } from "utils/business/rightUtils";
import { UserGroupAccountPerimeterDetails } from "components/molecules/useraccount/details/UserGroupAccountPerimeterDetails";
import { AccountResponse } from "interfaces/perimeter/account";

interface AccountDisplayProps {
  userDetails: UserDetails;
  idUser?: string;
  authPermissions?: Array<string>;
  onUpdateUserDetails: () => void;
  perimeter: Perimeter;
  accounts: Array<AccountResponse>,
  onCollapseAccount: (idAccount: string) => Promise<Perimeter>;
  onUpdatePerimeter: () => void;
}

export const UserAccountDisplay: FunctionComponent<AccountDisplayProps> = ({
  idUser,
  authPermissions,
  userDetails,
  onUpdateUserDetails,
  perimeter,
  accounts,
  onCollapseAccount,
  onUpdatePerimeter,
}) => {
  const [edit, setEdit] = useState<boolean>(false);

  const onAfterSubmit = () => {
    setEdit(false);
    onUpdateUserDetails();
    onUpdatePerimeter();
  }

  const onDiscard = () => {
    setEdit(false);
  }

  const onClickEdit = () => {
    setEdit(true);
  }

  const title: string | IntlMessage = userDetails && idUser && !createUserUtils.isSelfFromParam(idUser) ?
    {
      id: "account_user",
      values: { id: userDetails.id, firstName: userDetails.firstName, lastName: userDetails.lastName }
    } : "account_my_account";

  return (<>
    {edit ?
      <UserAccountEdit
        title={title}
        idUser={idUser}
        userDetails={userDetails}
        authPermissions={authPermissions}
        perimeter={perimeter}
        onDiscard={onDiscard}
        onAfterSubmit={onAfterSubmit}
        saveButtonLabel="save_changes"
        onCollapseAccount={onCollapseAccount}
        accounts={accounts}
        saveButtonStartIcon={<SaveOutlined />} /> :
      <UserAccountDetails
        title={title}
        idUser={idUser}
        userDetails={userDetails}
        authPermissions={authPermissions}
        onClickEdit={onClickEdit}>
        {rightUtils.hasGroupPerimeter(userDetails) ?
          <UserGroupAccountPerimeterDetails
            onCollapseAccount={onCollapseAccount}
            user={userDetails}
            accounts={accounts} /> :
          <UserAccountPerimeterDetails
            user={userDetails}
            perimeter={perimeter} />
        }
      </UserAccountDetails>
    }</>
  )
}
