import React, {FunctionComponent} from "react";
import {Box} from "@mui/material";
import {toastUtils} from "../../utils/toast/toastUtils";

interface WrappedDisableClickComponentProps {
    labelErrorOnDisable: string,
    disabled: boolean,
}

const WrappedDisableClickComponent: FunctionComponent<WrappedDisableClickComponentProps> = ({
                                                                                                children,
                                                                                                disabled,
                                                                                                labelErrorOnDisable,
                                                                                            }) =>
    (<Box position="relative">
        {children}
        {disabled &&
            <Box position="absolute" top={0} width="100%" height="100%" zIndex="1" sx={{cursor: "pointer"}}
                 onClick={() => toastUtils.errorToastI8ln(labelErrorOnDisable)}>
            </Box>
        }
    </Box>)


export default WrappedDisableClickComponent;
