import React, { FunctionComponent, useEffect, useState } from "react";
import { useValidation } from "hooks/validation/useValidation";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { validationErrorsScheduleReportUtils } from "utils/business/scheduleReportErrorUtils";
import { FREQUENCY_SELECTOR, FREQUENCY_SELECTOR_OPTIONS } from "constants/report/report";
import { SCHEDULE_REPORT_ERRORS } from "constants/errors/scheduleReport";
import { ReportRequest, ReportRequestFilters, ScheduledReport } from "interfaces/report/report";
import ContainedButton from "components/atoms/button/ContainedButton";
import Label from "components/atoms/Label";
import ErrorMessage from "components/atoms/ErrorMessage";
import { DropdownStyled } from "components/atoms/select/DropdownStyled";
import { SelectChangeEvent } from "@mui/material";
import Typography from "@mui/material/Typography";
import OutlinedTextFieldCardStyled from "styles/components/textfield/OutlinedTextFieldCardStyled";
import DiscardButtonStyled from "styles/components/button/DiscardButtonStyled";
import styles from "components/molecules/dialog/ScheduleReport/ReportSetup/reportSetup.module.scss";
import { useScheduleReport } from "hooks/report/useScheduleReport";
import { reportService } from "services/report/ReportService";
import { toastUtils } from "utils/toast/toastUtils";

type FrequencyInfoText =
  "sr_frequency_monthly_disclaimer" |
  "sr_frequency_weekly_disclaimer" |
  "sr_frequency_daily_disclaimer";

interface ReportError extends Error {
  fieldCode: string;
  error: {
    errorCode: string;
    errorDetails: {
      [key: string]: unknown;
    };
  }
}

interface SaveViewDialogContentProps {
  filters: ReportRequestFilters;
  onCloseDialog: () => void;
  onSetReportData: (report: ScheduledReport) => void;
  confirmSubmit: () => void;
}
const inputErrors = [SCHEDULE_REPORT_ERRORS.SR_ERROR_EXISTING_NAME, SCHEDULE_REPORT_ERRORS.SR_ERROR_EMPTY_NAME, SCHEDULE_REPORT_ERRORS.SR_ERROR_EMPTY_FREQUENCY, SCHEDULE_REPORT_ERRORS.SR_ERROR_NAME_TOO_SHORT];

export const ReportSetup: FunctionComponent<SaveViewDialogContentProps> = ({
  filters,
  onCloseDialog,
  confirmSubmit,
}) => {

  const [reportName, setReportName] = useState("");
  const { formatMessage } = useIntlFormatter();
  const [frequency, setSelectedFrequency] = useState(FREQUENCY_SELECTOR.MONTHLY.id);
  const [frequencyInfoText, setFrequencyInfoText] = useState<FrequencyInfoText>();
  const { generateReportRequest } = useScheduleReport();
  const reportRequestParams = generateReportRequest(reportName, frequency, filters);
  const maxNameLength = 100;

  const validations = () => {
    return validationErrorsScheduleReportUtils.validateReportName(reportName)
  };

  const {
    isValidated,
    errors,
    setErrors,
  } = useValidation(validations);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setReportName(event.target.value);
  };

  const handleFrequencyChange = (event: SelectChangeEvent<string>) => {
    if (event?.target.value) {
      setSelectedFrequency(event.target.value);
    } else {
      setSelectedFrequency("sr_pop_up_frequency_placeholder");
    }
  };

  useEffect(() => {
    switch (frequency) {
      case FREQUENCY_SELECTOR.MONTHLY.id:
        setFrequencyInfoText("sr_frequency_monthly_disclaimer");
        break;
      case FREQUENCY_SELECTOR.WEEKLY.id:
        setFrequencyInfoText("sr_frequency_weekly_disclaimer");
        break;
      case FREQUENCY_SELECTOR.DAILY.id:
        setFrequencyInfoText("sr_frequency_daily_disclaimer");
        break;
      default:
        setFrequencyInfoText(undefined);
        break;
    }
  }, [frequency]);

  const onHandlePost = (request: ReportRequest) => {
    return reportService.postReport(request)
      .then(() => {
        confirmSubmit();
      })
      .catch(
        (error) => {
          if (error) {
            if ("fieldCode" in error) {
              const reportError = error as ReportError;
              if (reportError.error.errorCode === "IS_DUPLICATED") {
                setErrors(validationErrorsScheduleReportUtils.validateReportName(reportError.error.errorCode))
              }
              if (reportError.error.errorCode === "TOO_SHORT") {
                setErrors(validationErrorsScheduleReportUtils.validateReportName(reportError.error.errorCode))
              }
            } else {
              toastUtils.errorToastI8ln("sr_saving_error");
            }
          }
        })
  };

  const handleSubmit = async () => {
    await onHandlePost(reportRequestParams);
  };

  return (
    <>
      {/* Dialog title */}
      <div className={`flex flex-row-between ${styles.title}`}>
        <Typography variant="h1" color="primary">
          {formatMessage("sr_pop_up_title")}
        </Typography>
      </div>

      {/* Report name */}
      <div className={`flex flex-row-between ${styles["report-name-wrapper"]}`}>
        <div className={styles.label}>
          <Label
            id="report_name"
            label="sr_pop_up_report_name_label"
            required />
        </div>

        {/* Error text */}
        <div className={styles["report-name-input-wrapper"]}>
          <OutlinedTextFieldCardStyled
            id="report_name"
            placeholder={formatMessage("sr_pop_up_report_name_placeholder")}
            value={reportName}
            onChange={onChange}
            error={isValidated && inputErrors.some(error => !!errors[error.id])}
            inputProps={{ maxLength: maxNameLength }}
          />
          <div className={styles["error-message"]}>
            {inputErrors.map(_error =>
              <ErrorMessage
                key={_error.id}
                error={_error.id}
                errors={errors} />)}
          </div>
        </div>
      </div>

      {/* Frequency */}
      <div className={`flex flex-row-between ${styles["report-frequency-wrapper"]}`}>
        <div className={styles.label}>
          <Label
            id="frequency"
            label="sr_pop_up_frequency_label"
            required
          />
        </div>
        <div className={styles["report-dropdown-wrapper"]}>
          {/* Error text */}
          <DropdownStyled
            selectedValue={frequency}
            options={FREQUENCY_SELECTOR_OPTIONS}
            onChange={(event: SelectChangeEvent) => { handleFrequencyChange(event) }}
            placeholderId="sr_pop_up_frequency_placeholder" />
          <div className={styles["frequency-info-message-wrapper"]}>
            <Typography className={styles["frequency-info-message-text"]}>
              {frequencyInfoText ? formatMessage(frequencyInfoText) : undefined}
            </Typography>
          </div>
        </div>
      </div>

      <div className={`flex flex-col ${styles["btn-wrapper"]}`}>
        <div className={`flex ${styles.btn} ${styles["btn-cancel"]}`}>
          <DiscardButtonStyled onClick={onCloseDialog}>
            {formatMessage("sr_pop_up_close_button")}
          </DiscardButtonStyled>
        </div>
        <div className={`flex ${styles.btn} ${styles["btn-confirm"]}`}>
          <ContainedButton
            onClick={handleSubmit}>
            {formatMessage("sr_pop_up_save_button")}
          </ContainedButton>
        </div>
      </div>
    </>
  )
}
