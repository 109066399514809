import React, { FunctionComponent } from "react";
import { RouteProps } from "react-router-dom";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import Card from "components/atoms/card/Card";
import Typography from "@mui/material/Typography";
import { paletteTheme } from "styles/themes/palette";
import styles from "view/DocumentsView/documentsView.module.scss";
import { FilterLayout } from "components/layouts/FilterLayout";
import { useDocumentsFilterConfig } from "constants/documents/documentsFilterGridConfig";
import { useDocumentsFilters } from "hooks/filter/useDocumentsFilters";

export const DocumentsView: FunctionComponent<RouteProps> = () => {
  const { formatMessage } = useIntlFormatter();

  const {
    sharedFilterProps,
    perimeterConfig,
    autoCompleteCheckboxConfig,
    multiChoiceConfig,
    queryAndResetConfig,
  } = useDocumentsFilters();

  const {
    filters,
  } = useDocumentsFilterConfig(
    sharedFilterProps,
    perimeterConfig,
    autoCompleteCheckboxConfig,
    multiChoiceConfig,
    queryAndResetConfig,
  );

  return (
    <Card
      cardTitle={
        <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
          {formatMessage("doc_title")}
        </Typography>
      }>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <h2>
            {formatMessage("doc_header")}
          </h2>
        </div>
        <div>
          <FilterLayout mainFilters={filters} />
        </div>
      </div>
    </Card>
  )
}
