
import { CompanyFilterOptions, Options, MerchantFilterOptions } from "constants/options/option";

type PerimeterLevel = "ACCOUNT" | "GROUP" | "COMPANY" | "MERCHANT";

export interface ConstantLabelLine {
    id: string;
    label: string;
}

export const PERIMETER_LEVEL: Record<PerimeterLevel, ConstantLabelLine> = {
    ACCOUNT: {id: "ACCOUNT", label: "peri_account"},
    GROUP: {id: "GROUP", label: "perimeter_group"},
    COMPANY: {id: "COMPANY", label: "peri_company"},
    MERCHANT: {id: "MERCHANT", label: "peri_merchant"}
}

export interface PerimeterOptions {
  accounts: Array<Options>;
  companies: Array<CompanyFilterOptions>;
  merchants: Array<MerchantFilterOptions>;
}

export const INITIAL_PERIMETER_FILTER: PerimeterOptions = {
  accounts: [],
  companies: [],
  merchants: [],
};
