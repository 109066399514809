import * as React from "react";
import { styled } from "@mui/material/styles";
import { TableRow, TableRowProps } from "@mui/material";
import { paletteTheme } from "../../themes/palette";

export default styled(({ onClick, ...props }: TableRowProps) => (
  <TableRow component="div" className="tr" hover onClick={onClick} {...props}></TableRow>
))`
  border: none;
  color: ${paletteTheme.colors.DEFAULT_TEXT_COLOR};
  &:hover,
  &.MuiTableRow-hover:hover {
    background-color: ${paletteTheme.palette.success["main"]};
  }
  &.even {
    background-color: white;
  }
  &.odd {
    background-color: #ebf4f5;
  }
  background-color: ${paletteTheme.palette.primary["main"]};
`;
