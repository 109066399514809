import React, { FunctionComponent } from "react";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import ContainedButton from "./ContainedButton";

interface ExportButtonProps {
  onClick: () => void;
  loading?: boolean;
  children?: React.ReactNode;
}

export const ExportButton: FunctionComponent<ExportButtonProps> = ({
  onClick,
  loading,
  children
}) => {
  const { formatMessage } = useIntlFormatter();

  return (
    <ContainedButton
      onClick={onClick}
      loading={loading}
    >
      {children || formatMessage("export_csv_button")}
    </ContainedButton>
  );
}
