import React, {FunctionComponent} from "react";
import FlexBox from "../atoms/flex/FlexBox";
import {CircularProgress} from "@mui/material";
import {FlexAttributValue} from "../../types/flex/flex";

interface WrappedLoadingContentProps {
    loading: boolean
}

const WrappedLoadingContent: FunctionComponent<WrappedLoadingContentProps> = ({
                                                                                  loading
                                                                                  , children
                                                                              }) =>
    (loading ?
        <FlexBox justifyContent={FlexAttributValue.CENTER}>
            <CircularProgress size={100}/>
        </FlexBox> :
        <>
            {children}
        </>
)


export default WrappedLoadingContent;
