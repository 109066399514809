import React, { FunctionComponent } from "react";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { MaterialUiColor } from "types/materialui/materialui";
import { QueryButton } from "components/atoms/button/SearchButton";
import { ResetButton } from "components/atoms/button/ResetButton";
import { ResetIconButton } from "components/atoms/button/ResetButton/ResetIconButton";
import { Search } from "@mui/icons-material";
import styles from "components/molecules/filters/QueryAndReset/queryAndReset.module.scss";

interface Props {
  onSearch: () => void;
  onReset: () => void;
  errors: Record<string, boolean>;
  labelErrorOnDisable: string;
  disabledWithPopup: boolean;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  buttonText?: string;
  loading?: boolean;
}

export const QueryAndReset: FunctionComponent<Props> = (
  {
    onSearch,
    onReset,
    errors,
    labelErrorOnDisable,
    disabledWithPopup,
    disabled,
    startIcon = <Search />,
    buttonText = "search",
    loading = false,
  }
) => {
  const { smDown } = useMediaQueryUtil();
  const { formatMessage } = useIntlFormatter();

  return (
    <div className={`flex flex-col-center ${styles.wrap}`}>
      <div className={`flex ${styles.search}`}>
        <QueryButton
          fullWidth={true}
          color={MaterialUiColor.PRIMARY}
          onClick={onSearch}
          disabled={disabled || disabledWithPopup || Object.keys(errors).length > 0}
          disabledWithPopup={disabledWithPopup && Object.keys(errors).length === 0}
          labelErrorOnDisable={labelErrorOnDisable}
          startIcon={startIcon}
          loading={loading}
        >
          {formatMessage(buttonText)}
        </QueryButton>
      </div>
      <div className={`flex ${styles.reset}`}>
        {smDown ?
          <ResetButton onClick={onReset} />
          :
          <ResetIconButton onClick={onReset} />
        }
      </div>
    </div>
  )
}
