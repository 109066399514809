import React, {FunctionComponent} from "react";
import CardPopup from "../../atoms/card/CardPopup";
import FlexBox from "../../atoms/flex/FlexBox";
import {FlexAttributValue} from "../../../types/flex/flex";
import {Dialog as DialogMaterialUI, Typography} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {ReactComponent as DownloadExport} from "../../../assets/DownloadExport.svg"
import ContainedButton from "../../atoms/button/ContainedButton";
import {intlUtils} from "../../../utils/intlUtils";

export interface DialogProps {
    openDialog: boolean
    onCloseDialog: () => void
    maxLines?: string
}

const ConfirmDialog: FunctionComponent<DialogProps> = ({
                                                           openDialog,
                                                           onCloseDialog,
                                                           maxLines = "50000"
                                                       }) => {

    const intl = useIntl();
    return (
        <DialogMaterialUI
            open={openDialog}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CardPopup sx={{width: "414px", padding: "30px"}}>
                <FlexBox
                    maxWidth="400px"
                    flexDirection={FlexAttributValue.COLUMN}
                    justifyContent={FlexAttributValue.CENTER}
                    alignItems={FlexAttributValue.CENTER} marginBottom="12px">
                    <DownloadExport/>
                    <Typography textAlign="center" marginTop="10px" lineHeight="18px" fontWeight="400" fontSize="14px">
                        <FormattedMessage
                            id="export_limit_message"
                            values={{max: intlUtils.formatNumber(intl,parseInt(maxLines, 10))}}/>
                    </Typography>
                </FlexBox>
                <FlexBox
                    maxWidth="400px"
                    flexDirection={FlexAttributValue.ROW}
                    justifyContent={FlexAttributValue.CENTER}>
                    <ContainedButton onClick={onCloseDialog}>
                        <FormattedMessage id="ok"/>
                    </ContainedButton>
                </FlexBox>
            </CardPopup>
        </DialogMaterialUI>
    )
}

export default ConfirmDialog;
