import { styled } from "@mui/material/styles";
import {
  OutlinedTextField,
  OutlinedTextFieldProps,
} from "components/atoms/textfield/OutlinedTextField";

export default styled(OutlinedTextField)<OutlinedTextFieldProps>(() => {
  return ({
    height: "24px",
    width: "260px",
    border: "none",
    "& .MuiInputBase-root": {
      height: "24px",
      width: "260px"
    }
  });
});
