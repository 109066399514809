import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {Tooltip, Typography, TypographyProps} from "@mui/material";

interface OverflowTooltipProps {
    className?: string,
    resizeClass?: string,
    overflowText?: string; // To use if children is not wanted as the tooltip text
    typographyProps?: TypographyProps; // Use to customize typography
}

const OverflowTooltip: FunctionComponent<OverflowTooltipProps> = ({
                                                                      className = "",
                                                                      resizeClass = "global",
                                                                      typographyProps,
                                                                      overflowText,
                                                                      children
                                                                  }) => {
    const [isOverflow, setIsOverflow] = useState(false);
    const textElementRef = useRef<HTMLDivElement>();

    const isOverflowed = () => {
        setIsOverflow(textElementRef.current && textElementRef.current.offsetWidth < textElementRef.current.scrollWidth);
    };

    useEffect(() => {
        isOverflowed();
        const elementsByClassName = [...document.getElementsByClassName(resizeClass)];
        elementsByClassName.forEach(resizerClassName => resizerClassName.addEventListener("mouseup", isOverflowed));
        return () => elementsByClassName.forEach(resizerClassName => resizerClassName.removeEventListener("mouseup", isOverflowed));
    }, []);

    return children ?
        <Tooltip title={overflowText ?? children} placement="right" disableHoverListener={!isOverflow}>
            <Typography
                {...typographyProps}
                ref={textElementRef}
                className={`ellipsis ${className}`}>
                {children}
            </Typography>
        </Tooltip>
        : <Typography
            {...typographyProps}
            className={`ellipsis ${className}`}>
            {children}
        </Typography>
}


export default OverflowTooltip;
