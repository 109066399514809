import React, { FunctionComponent } from "react";
import { Grid, Typography } from "@mui/material";
import Card from "components/atoms/card/Card";
import AdvancedFormattedMessage from "components/atoms/intl/AdvancedFormattedMessage";
import WrappedLoadingContent from "components/molecules/WrappedLoadingContent";
import { RouteProps } from "react-router-dom";
import { perimeterNotifTypes, userNotifTypes } from "constants/notification/NotificationSettingsConfig";
import { NotificationChannel } from "types/notifications/NotificationSettingsTypes";
import { HeaderSwitch } from "./HeaderSwitch";
import { ButtonsRow } from "./ButtonsRow";
import { ToggleGroupRow } from "./ToggleGroupRow";
import { ToggleRow } from "./ToggleRow";
import { useNotificationSettingsLogic } from "./NotificationSettingsLogic";
import { paletteTheme } from "styles/themes/palette";

const NotificationSettingsView: FunctionComponent<RouteProps> = () => {

    const {
        isLoading,
        isSaving,
        translateChannel,
        activeToggles,
        triggerToggle,
        checkAllEmail,
        checkAllInApp,
        areAllInAppChecked,
        areAllEmailChecked,
        isNotifTypeVisibleWithUserProfile,
        isFormModified,
        onPersistForm,
        onDiscardForm,
    } = useNotificationSettingsLogic();

    return <>
        <Card cardTitle={<Typography
                variant="h1"
                marginRight="6"
                color={paletteTheme.palette.primary["main"]}>
                <AdvancedFormattedMessage title="my_notifications" />
            </Typography>}>
            <WrappedLoadingContent loading={isLoading}>
                <Grid container maxWidth="25rem">
                    <Grid item xs={6} height="60px" />
                    <Grid item xs={3}>
                        <HeaderSwitch title={translateChannel(NotificationChannel.IN_APP)} checked={areAllInAppChecked} onClick={checkAllInApp} />
                    </Grid>
                    <Grid item xs={3}>
                        <HeaderSwitch title={translateChannel(NotificationChannel.EMAIL)} checked={areAllEmailChecked} onClick={checkAllEmail} />
                    </Grid>
                    <Grid item xs={6}>
                        <ToggleGroupRow groupTitleCode="notif_peri_update" />
                    </Grid>
                    <Grid item xs={6} />
                    {perimeterNotifTypes.filter(isNotifTypeVisibleWithUserProfile).map( notifType =>
                        <ToggleRow key={notifType} activeToggles={activeToggles} notifType={notifType} triggerToggle={triggerToggle} />)}
                    <Grid item xs={12} sx={{height:"40px"}} />
                    <Grid item xs={6}>
                        <ToggleGroupRow groupTitleCode="notif_user_update" />
                    </Grid>
                    <Grid item xs={6} />
                    {userNotifTypes.filter(isNotifTypeVisibleWithUserProfile).map( notifType =>
                        <ToggleRow key={notifType} activeToggles={activeToggles} notifType={notifType}  triggerToggle={triggerToggle} />)}
                </Grid>
            </WrappedLoadingContent>
        </Card>
        {isFormModified && 
            <ButtonsRow 
                isSaving={isSaving} 
                onPersistForm={onPersistForm} 
                onDiscardForm={onDiscardForm} 
            />}
    </>
}

export default NotificationSettingsView;
