import {paletteTheme} from "../../themes/palette";
import {styled} from "@mui/material";
import OutlinedButton from "../../../components/atoms/button/OutlinedButton";
import {ButtonProps} from "../../../components/atoms/button/Button";

export default styled(OutlinedButton)<ButtonProps>(() => {
    return ({
        "&.MuiButton-root": {
            ":hover": {
                borderColor: paletteTheme.colors.DANGER,
                color: paletteTheme.colors.DANGER,
                backgroundColor: paletteTheme.colors.WHITE
            },
            "&.Mui-focused": {
                backgroundColor: paletteTheme.colors.DANGER,
                borderColor: paletteTheme.colors.DANGER,
                color: paletteTheme.colors.WHITE
            }
        }
    });
});
