import React, { FunctionComponent } from "react";
import { FormattedMessage  } from "react-intl";
import { Typography } from "@mui/material";
import styles from "components/molecules/transaction/DetailRow/detailRow.module.scss";

export interface DetailBlocProps {
  label: string;
  value: string;
  isSubItem?: boolean;
}

export const DetailRow: FunctionComponent<DetailBlocProps> = ({ label, value, isSubItem = false }) => {

  return (
    <>
      <div className={`${styles.cell}  ${isSubItem ? styles["sub-cell"] : ""} `}>
        <Typography fontSize="14px" fontWeight={isSubItem? 400 : 600} >
          <FormattedMessage id={label} />
        </Typography>
      </div>
      <div className={styles.cell}>
        <Typography fontSize="14px">{value}</Typography>
      </div>
    </>
  );
};
