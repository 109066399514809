import React, { FunctionComponent } from "react";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import TablePagination from "components/atoms/table/TablePagination";
import Fab from "@mui/material/Fab";
import ArrowUpward from "@mui/icons-material/ArrowUpward";

interface TableFooterProps {
    totalCount: number;
    displayedCount: number;
    isLoadingNextPage: boolean;
    goTo: (toIndex: number) => void;
    last: boolean;
    loading: boolean;
}

export const TableFooter: FunctionComponent<TableFooterProps> = ({
    totalCount,
    displayedCount,
    isLoadingNextPage,
    last,
    loading,
    goTo,
    children
}) => {
    const { smUp } = useMediaQueryUtil();

    return (
        <div className={`flex ${smUp ? "flex-row-between flex-col-center" : "flex-col"}`}>

            {/* Left side area - Arrow up */}

            <div>
                {smUp && !loading && totalCount > 10 &&
                    <Fab size="small" color="primary" aria-label="add" style={{ zIndex: "1000" }}
                        onClick={() => goTo(0)}>
                        <ArrowUpward />
                    </Fab>
                }
            </div>

            {/* Middle area for pagination */}

            <TablePagination
                totalCount={totalCount}
                isLoadingNextPage={isLoadingNextPage}
                displayedCount={displayedCount}
                lastPage={last}
                loading={loading} />

            {/* Right side area - typically for buttons */}

            {!loading && totalCount > 0 &&
                <div className="flex">
                    {children}
                </div>
            }
        </div>
    )
}
