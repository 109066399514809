import { useCallback, useRef, useEffect } from "react";
import { authUtils } from "utils/api/authUtils";

export const DEBOUNCE_DELAY_MS = 500;

export const useDebounce = <ValueType,>(callback: (value: ValueType) => void, delay = DEBOUNCE_DELAY_MS) => {
  const timeout = useRef<number | undefined>();

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined
      }
    };
  }, []);

  return useCallback((val: ValueType) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = window.setTimeout(() => {
      authUtils.refreshTokenExpirationDate();
      callback(val);
    }, delay);
  }, [callback]);
};
