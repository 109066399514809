import React, { FunctionComponent } from "react";
import { authUtils } from "utils/api/authUtils";
import Button, { ButtonProps } from "components/atoms/button/Button";
import { paletteTheme } from "styles/themes/palette";

export interface ContainedButtonProps extends ButtonProps {
  height?: string;
  width?: string;
  borderBottomLeftRadius?: number;
  borderTopLeftRadius?: number;
  marginLeft?: string;
  marginRight?: string;
  padding?: string;
}

const ContainedButton: FunctionComponent<ContainedButtonProps> = ({
  onClick,
  height = "28px",
  width = "initial",
  borderBottomLeftRadius,
  borderTopLeftRadius,
  marginLeft,
  marginRight,
  padding,
  sx,
  ...rest
}) => {

  const onClickWithTracker = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    authUtils.refreshTokenExpirationDate();
    onClick(event);

  }

  return (<Button {...rest}
    sx={{
      ...sx,
      height: height,
      width: width,
      borderBottomLeftRadius: borderBottomLeftRadius,
      borderTopLeftRadius: borderTopLeftRadius,
      marginLeft: marginLeft,
      marginRight: marginRight,
      padding: padding,
      position: "relative",
      "&.Mui-disabled": { color: paletteTheme.colors.WHITE }
    }}

    variant="contained"
    onClick={onClickWithTracker} />);
}


export default ContainedButton;
