import {styled} from "@mui/material/styles";
import {PaperProps} from "@mui/material";
import {paletteTheme} from "../../themes/palette";
import PaperStyled from "./PaperStyled";

export default styled(PaperStyled)<PaperProps>(() => {
    return ({
            "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused, & .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'].Mui-focused":
                {
                    backgroundColor: "transparent",
                    "& p.active":
                        {
                            backgroundColor: paletteTheme.colors.SUCCESS
                        },
                    "& p.inactive":
                        {
                            backgroundColor: paletteTheme.colors.DANGER_LIGHT
                        }
                }
        }
    )
})
