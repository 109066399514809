import { Clear } from "@mui/icons-material";
import ContainedButton from "components/atoms/button/ContainedButton";
import OutlinedButton from "components/atoms/button/OutlinedButton";
import FlexBox from "components/atoms/flex/FlexBox";
import AdvancedFormattedMessage from "components/atoms/intl/AdvancedFormattedMessage";
import React, { FunctionComponent } from "react";
import { VoidNoParamsFn } from "types/common/CommonTypes";
import { FlexAttributValue } from "types/flex/flex";
import { MaterialUiColor } from "types/materialui/materialui";

export const ButtonsRow:FunctionComponent<{
    isSaving:boolean,
    onPersistForm:VoidNoParamsFn, 
    onDiscardForm:VoidNoParamsFn,
}> = ({
    isSaving,
    onPersistForm,
    onDiscardForm,
}) => <FlexBox justifyContent={FlexAttributValue.CENTER} position="relative">
    <OutlinedButton
        onClick={onDiscardForm}
        color={MaterialUiColor.SECONDARY}
        startIcon={<Clear/>}
        sx={{marginRight: 2}}>
        <AdvancedFormattedMessage title="discard_changes"/>
    </OutlinedButton>
    <ContainedButton
        onClick={onPersistForm}
        loading={isSaving}>
        <AdvancedFormattedMessage title="save_changes"/>
    </ContainedButton>
</FlexBox>
