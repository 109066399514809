import React, {FunctionComponent} from "react";
import {
    Box, Button, Typography
} from "@mui/material";
import {Entity} from "../../../interfaces/perimeter/perimeter";
import ChipOption from "./ChipOption";
import {Add} from "@mui/icons-material";
import WrappedTextFieldStyled from "../../../styles/components/textfield/WrappedTextFieldStyled";
import {MaterialUiColor} from "../../../types/materialui/materialui";
import {FormattedMessage} from "react-intl";
import MenuItemStyled from "../../../styles/components/MenuItemStyled";
import {paletteTheme} from "../../../styles/themes/palette";
import {REFERENTIAL_STATUS} from "../../../constants/referential/referential";

interface ChipOptionRenderProps {
    options: Array<Entity>,
    onChange: (value: string) => void,
    onAdd: (value: Entity) => void
    onAddAll: () => void
}

const SearchChipsMenuList: FunctionComponent<ChipOptionRenderProps> = ({
                                                                           onChange,
                                                                           onAdd,
                                                                           onAddAll,
                                                                           options
                                                                       }) => (
    <> <Box sx={{height: "24px"}}>
        <WrappedTextFieldStyled
            id="search-merchant"
            fullWidth
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => onChange(e.target.value)}
        />
    </Box>
        <Box sx={{maxHeight: "81px", overflowY: "scroll"}}>
            {options.length > 0 ?
                <Button
                    sx={
                        {
                            "& .MuiButton-endIcon": {marginLeft: "2px"},
                            "& .MuiButton-endIcon > :nth-of-type(1)": {fontSize: "15px"},
                            ":hover": {
                                backgroundColor: paletteTheme.colors.PRIMARY,
                                "& .MuiSvgIcon-root, & .MuiTypography-root": {
                                    color: "white"
                                }
                            }
                        }
                    }
                    endIcon={<Add fontStyle={15} color={MaterialUiColor.PRIMARY}/>}
                    onClick={onAddAll}>
                    <Typography color={MaterialUiColor.PRIMARY} fontWeight={600}>
                        <FormattedMessage id="add_all"/>
                    </Typography>
                </Button> :
                <Typography textAlign="center">
                    <FormattedMessage id="no_results"/>
                </Typography>}
            {options.map(option =>
                <MenuItemStyled
                    sx={{
                        height: "auto",
                        cursor: "initial",
                        "&:hover, &:focus, &:active":
                            {
                                backgroundColor: "white",
                                cursor: "initial"
                            }
                    }}
                    disableRipple
                    disabled={!option.hasRightOn}
                    key={option.id}
                    value={option.id}>
                    <ChipOption
                        value={option}
                        disabled={option.status === REFERENTIAL_STATUS.INACTIVE.id}
                        onAction={onAdd}
                        color={MaterialUiColor.SUCCESS}
                        icon={<Add/>}/>
                </MenuItemStyled>)}
        </Box>
    </>
)


export default SearchChipsMenuList;
