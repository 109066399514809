import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import ManageColumnLabeledCheckbox from "components/atoms/checkbox/ManageColumnLabeledCheckbox";
import { getManagableColumnsSorted } from "utils/table/tableUtils";
import { ManagableColumn } from "interfaces/table/table";

interface ManageColumnOptionsProps {
  options: ManagableColumn[];
  selectedOptions: ManagableColumn[];
  onChangeOption: (option: ManagableColumn, e: React.ChangeEvent<HTMLInputElement>) => void;
  disableUncheck?: boolean;
}

export const ManageColumnOptions: FunctionComponent<ManageColumnOptionsProps> = ({
  options,
  selectedOptions,
  onChangeOption,
  disableUncheck = false,
}) => {
  const intl = useIntl();
  const sortedOptions = getManagableColumnsSorted(options, intl);
  return (
    <div style={{ paddingRight: "20px", marginBottom:"20px" }}>
      {sortedOptions.map((option) => {
        const checked = !!selectedOptions.find((_option) => _option.id === option.id);
        return (
          <ManageColumnLabeledCheckbox
            labelErrorOnDisable="col_man_err_message"
            key={option.id}
            label={option.header}
            onChange={(e) => onChangeOption(option, e)}
            checked={checked}
            disabled={checked && disableUncheck}
          />
        );
      })}
    </div>
  );
};
