import React, { FunctionComponent, ReactNode } from "react";
import { SvgIconComponent } from "@mui/icons-material";
import styles from "./MenuItem.module.scss";

export interface IMenuItem {
  Icon: SvgIconComponent;
  label: ReactNode;
  isReduced: boolean;
  className?: string;
}

export const ItemMenu: FunctionComponent<IMenuItem> = (props) => {
  const { Icon, label, isReduced, className } = props;
  return (
    <span className={`${styles["menu-item"]} ${className ? styles[className] : ""} ${isReduced ? styles["reduced"] : ""} }`}>
      <span className={styles.icon}>
        <Icon />
      </span>
      <span className={styles.label}>{label}</span>
    </span>
  );
};
