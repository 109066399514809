import React from "react";
import {
  TransactionAdditionalFilter,
  TransactionAdditionalFilterLines,
  TransactionAdditionalFiltersStates,
  TransactionAdditionalFiltersValues,
} from "interfaces/transaction/transaction";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { OptionsValues } from "interfaces/filter/filter";
import { Options } from "constants/options/option";
import { FilterFieldType, TransactionType } from "interfaces/filter/filterColumn";
import {
  DOCUMENTS_FILTERS,
  DOCUMENTS_MONTHS_VALUES,
  DOCUMENTS_YEARS_VALUES,
} from "constants/documents/documentsFilter";
import { filterUtils } from "utils/business/filterUtils";
import { EMPTY_DATE_INITIAL_STATE, INITIAL_RANGE_TODAY } from "constants/datepicker/datepicker";
import { INITIAL_PERIMETER_FILTER } from "constants/perimeter/perimeter";
import { PORTFOLIO_TREND_BY_FILTERS } from "constants/portfolio/portfolioFilters";

export const INITIAL_ACCEPTED_TRANSACTION_FILTER: OptionsValues = {
  ...INITIAL_PERIMETER_FILTER,
  operationDate: INITIAL_RANGE_TODAY,
};

export const INITIAL_ACCEPTED_TRANSACTION_FILTER_ADMIN: OptionsValues = {
  ...INITIAL_PERIMETER_FILTER,
  operationDate: EMPTY_DATE_INITIAL_STATE,
};

export const INITIAL_ACQUIRED_TRANSACTION_FILTER: OptionsValues = {
  ...INITIAL_PERIMETER_FILTER,
  operationDate: EMPTY_DATE_INITIAL_STATE,
  settlementDate: INITIAL_RANGE_TODAY,
};

export const INITIAL_ACQUIRED_TRANSACTION_FILTER_ADMIN: OptionsValues = {
  ...INITIAL_PERIMETER_FILTER,
  operationDate: EMPTY_DATE_INITIAL_STATE,
  settlementDate: INITIAL_RANGE_TODAY,
};

export const INITIAL_SETTLEMENT_FILTER: OptionsValues = {
  ...INITIAL_PERIMETER_FILTER,
  operationDate: INITIAL_RANGE_TODAY,
};

export const COUNTRIES = [
  { id: "FRANCE", name: "country_filter_fr" },
  { id: "ESPAGNE", name: "country_filter_sp" },
  { id: "PAYSBAS", name: "country_filter_nl" },
  { id: "PORTUGAL", name: "country_filter_pt" },
  { id: "ITALIE", name: "country_filter_it" },
  { id: "BELGIQUE", name: "country_filter_bel" },
  { id: "ALLEMAGNE", name: "country_filter_ger" },
];

export const TRANSACTION_ADDITIONAL_FILTERS_STATES: TransactionAdditionalFiltersStates = {
  MORE_FILTERS: {
    id: "more_filters",
    arrow: <KeyboardArrowDown fontSize="small" />,
    showFilters: false,
  },
  LESS_FILTERS: {
    id: "less_filters",
    arrow: <KeyboardArrowUp fontSize="small" />,
    showFilters: true,
  },
};

export const TRANSACTION_ACCEPTED_FILTERS_INITIAL_VALUES: TransactionAdditionalFiltersValues = {
  transactionalEventId: "",
  orderNumber: "",
  transactionalEvent: [],
  eventStatus: [],
  paymentSolution: [],
  paymentMethod: [],
  paymentType: [],
  checkoutType: [],
  grossAmountMin: "",
  grossAmountMax: "",
  bin: "",
  financingStatus: [],
  financingDate: EMPTY_DATE_INITIAL_STATE,
  batchId: "",
  scheme: [],
  collectionType: [],
  serviceType: [],
  posId: "",
};
export const TRANSACTION_ACQUIRED_FILTERS_INITIAL_VALUES: TransactionAdditionalFiltersValues = {
  transactionSettlementId: "",
  cardLevel: [],
  cardBrand: [],
  cardType: [],
  calculationMethod: [],
  transactionType: [],
  grossAmountMin: "",
  grossAmountMax: "",
  netAmountMin: "",
  netAmountMax: "",
  bin: "",
  batchId: "",
  scheme: [],
};

export const TRANSACTION_ADDITIONAL_FILTERS: TransactionAdditionalFilterLines = {
  TRANSACTIONAL_EVENT_ID: {
    id: "transactionalEventId",
    label: "tpd_root_trans_event_id",
    type: FilterFieldType.TEXT,
  },
  ORDER_NUMBER: {
    id: "orderNumber",
    label: "order_number",
    type: FilterFieldType.TEXT,
  },
  TRANSACTIONAL_EVENT: {
    id: "transactionalEvent",
    label: "col_man_trans_event",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  EVENT_STATUS: {
    id: "eventStatus",
    label: "col_man_status",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  PAYMENT_SOLUTION: {
    id: "paymentSolution",
    label: "payment_solution",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  PAYMENT_METHOD: {
    id: "paymentMethod",
    label: "col_man_payment_method",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  PAYMENT_TYPE: {
    id: "paymentType",
    label: "payment_type",
    type: FilterFieldType.MULTISELECT,
  },
  CHECKOUT_TYPE: {
    id: "checkoutType",
    label: "checkout_type",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  GROSS_AMOUNT: {
    id: "grossAmount",
    minId: "grossAmountMin",
    maxId: "grossAmountMax",
    label: "col_man_gross_amount",
    type: FilterFieldType.RANGE,
  },
  SETTLEMENT_STATUS: {
    id: "financingStatus",
    label: "settlement_status",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  SETTLEMENT_DATE: {
    id: "financingDate",
    label: "settlement_date",
    type: FilterFieldType.DATE,
  },
  TRANSACTION_DATE: {
    id: "operationDate",
    label: "transaction_date",
    type: FilterFieldType.DATE,
  },
  BIN: {
    id: "bin",
    label: "col_man_pan_first_six",
    type: FilterFieldType.TEXT,
    config: {
      maxLength: 6,
      onKeyPress: () => filterUtils.onKeyPressOnlyNumber,
    },
  },
  BATCH_ID: {
    id: "batchId",
    label: "col_man_settlement_batch",
    type: FilterFieldType.TEXT,
  },
  SCHEME: {
    id: "scheme",
    label: "col_man_scheme",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  SERVICE_TYPE: {
    id: "serviceType",
    label: "filter_service_type",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  COLLECTION_TYPE: {
    id: "collectionType",
    label: "filter_collection_type",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  REMITTANCE: {
    id: "tlcNumber",
    label: "filter_remittance",
    type: FilterFieldType.TEXT,
  },
  CONTRACT_NUMBER: {
    id: "contractNumber",
    label: "tpd_contract_num",
    type: FilterFieldType.TEXT,
  },
  TRANSACTION_SETTLEMENT_ID: {
    id: "transactionSettlementId",
    label: "col_transaction_settlement_id",
    type: FilterFieldType.TEXT,
  },
  TRANSACTION_TYPE: {
    id: "transactionType",
    label: "col_man_trans_type",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  CARD_LEVEL: {
    id: "cardLevel",
    label: "col_man_card_level",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  CARD_TYPE: {
    id: "cardType",
    label: "col_man_card_type",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  CARD_BRAND: {
    id: "cardBrand",
    label: "col_card_brand",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  NET_AMOUNT: {
    id: "netAmount",
    label: "col_man_net_amount",
    minId: "netAmountMin",
    maxId: "netAmountMax",
    type: FilterFieldType.RANGE,
    translateLabel: true,
  },
  CALCULATION_METHOD: {
    id: "calculationMethod",
    label: "col_calculation_method",
    type: FilterFieldType.MULTISELECT,
    translateLabel: true,
    multiple: true,
  },
  POS_ID: {
    id: "posId",
    label: "pos_id",
    type: FilterFieldType.TEXT,
  },
};

export const ACCEPTED_TRANSACTIONS_MAIN_FILTERS: TransactionAdditionalFilter[] = [
  TRANSACTION_ADDITIONAL_FILTERS.TRANSACTION_DATE,
];

export const ACCEPTED_TRANSACTIONS_ADDITIONAL_FILTERS: TransactionAdditionalFilter[] = [
  TRANSACTION_ADDITIONAL_FILTERS.TRANSACTIONAL_EVENT_ID,
  TRANSACTION_ADDITIONAL_FILTERS.ORDER_NUMBER,
  TRANSACTION_ADDITIONAL_FILTERS.TRANSACTIONAL_EVENT,
  TRANSACTION_ADDITIONAL_FILTERS.EVENT_STATUS,
  TRANSACTION_ADDITIONAL_FILTERS.PAYMENT_SOLUTION,
  TRANSACTION_ADDITIONAL_FILTERS.PAYMENT_METHOD,
  TRANSACTION_ADDITIONAL_FILTERS.PAYMENT_TYPE,
  TRANSACTION_ADDITIONAL_FILTERS.CHECKOUT_TYPE,
  TRANSACTION_ADDITIONAL_FILTERS.GROSS_AMOUNT,
  TRANSACTION_ADDITIONAL_FILTERS.COLLECTION_TYPE,
  TRANSACTION_ADDITIONAL_FILTERS.SERVICE_TYPE,
  TRANSACTION_ADDITIONAL_FILTERS.BIN,
  TRANSACTION_ADDITIONAL_FILTERS.SCHEME,
  TRANSACTION_ADDITIONAL_FILTERS.SETTLEMENT_STATUS,
  TRANSACTION_ADDITIONAL_FILTERS.SETTLEMENT_DATE,
  TRANSACTION_ADDITIONAL_FILTERS.BATCH_ID,
  TRANSACTION_ADDITIONAL_FILTERS.POS_ID,
];

export const ACQUIRED_TRANSACTIONS_MAIN_FILTERS: TransactionAdditionalFilter[] = [
  TRANSACTION_ADDITIONAL_FILTERS.SETTLEMENT_DATE,
  TRANSACTION_ADDITIONAL_FILTERS.TRANSACTION_DATE,
  TRANSACTION_ADDITIONAL_FILTERS.REMITTANCE,
  TRANSACTION_ADDITIONAL_FILTERS.CONTRACT_NUMBER,
];

export const ACQUIRED_TRANSACTIONS_ADDITIONAL_FILTERS: TransactionAdditionalFilter[] = [
  TRANSACTION_ADDITIONAL_FILTERS.TRANSACTION_SETTLEMENT_ID,
  TRANSACTION_ADDITIONAL_FILTERS.TRANSACTION_TYPE,
  TRANSACTION_ADDITIONAL_FILTERS.BATCH_ID,
  TRANSACTION_ADDITIONAL_FILTERS.GROSS_AMOUNT,
  TRANSACTION_ADDITIONAL_FILTERS.NET_AMOUNT,
  TRANSACTION_ADDITIONAL_FILTERS.CARD_LEVEL,
  TRANSACTION_ADDITIONAL_FILTERS.CARD_TYPE,
  TRANSACTION_ADDITIONAL_FILTERS.BIN,
  TRANSACTION_ADDITIONAL_FILTERS.CARD_BRAND,
  TRANSACTION_ADDITIONAL_FILTERS.SCHEME,
  TRANSACTION_ADDITIONAL_FILTERS.CALCULATION_METHOD,
];

export const SCHEME: Array<Options> = [
  { id: "SCHEME_AMERICAN_EXPRESS", name: "American Express" },
  { id: "SCHEME_BANCOMAT", name: "Bancomat" },
  { id: "SCHEME_DINERS_CLUB", name: "Diners Club" },
  { id: "SCHEME_DISCOVER", name: "Discover" },
  { id: "SCHEME_JCB", name: "JCB" },
  { id: "SCHEME_MASTERCARD", name: "MasterCard" },
  { id: "SCHEME_RU_BAY", name: "RuPay" },
  { id: "SCHEME_UNION_PAY", name: "UnionPay" },
  { id: "SCHEME_VIS", name: "Visa" },
  { id: "SCHEME_PB", name: "PagoBancomat" },
];

export const TRANS_EVENT: Array<Options> = [
  { id: "TRANS_AUTHORIZATION", name: "trans_authorization" },
  { id: "TRANS_CANCELLATION", name: "trans_cancellation" },
  { id: "TRANS_CAPTURE", name: "trans_capture" },
  { id: "TRANS_REFUND", name: "trans_refund" },
  { id: "TRANS_REFUND_REQUEST", name: "trans_refund_request" },
];

export const EVENT_STATUS: Array<Options> = [
  { id: "EVENT_SUCCESS", name: "event_success" },
  { id: "EVENT_DECLINED", name: "event_declined" },
  { id: "EVENT_ERROR", name: "event_error" },
];

export const PAYMENT_SOLUTION: Array<Options> = [
  { id: "SOLUTION_PAY_ONLINE", name: "solution_pay_online" },
  { id: "SOLUTION_PAY_WISH", name: "solution_pay_wish" },
  { id: "SOLUTION_PAY_ONSITE", name: "solution_pay_onsite" },
];

export const PAYMENT_METHOD: Array<Options> = [
  { id: "METHOD_APETIZ", name: "Apetiz" },
  { id: "METHOD_BANCONTACT_DEBIT", name: "Bancontact Debit" },
  { id: "METHOD_CIRRUS_DEBIT", name: "Cirrus Debit" },
  { id: "METHOD_COMPTANT", name: "Comptant" },
  { id: "METHOD_CREDIT_CARD", name: "Credit Card" },
  { id: "METHOD_DANKORT", name: "Dankort" },
  { id: "METHOD_DEBITO_EEUU", name: "Debito Eeuu" },
  { id: "METHOD_EBT_DEBIT", name: "EBT Debit" },
  { id: "METHOD_MAESTRO", name: "Maestro" },
  { id: "METHOD_MASTERCARD_CREDIT", name: "Mastercard Credit" },
  { id: "METHOD_MASTERCARD_DEBIT", name: "Mastercard Debit" },
  { id: "METHOD_PAY_BY_BANK", name: "PayByBank" },
  { id: "METHOD_RUPAY", name: "Rupay" },
  { id: "METHOD_US_DEBIT", name: "US Debit" },
  { id: "METHOD_V_PAY", name: "V Pay" },
  { id: "METHOD_VISA_DEBIT", name: "Visa Debit" },
  { id: "METHOD_VISA_CREDIT", name: "Visa Credit" },
  { id: "METHOD_VISA_ELECTRON", name: "Visa Electron" },
  { id: "METHOD_VISA_PLATINUM", name: "Visa Platinum" },
  { id: "METHOD_UNION_PAY", name: "UnionPay" },
  { id: "METHOD_WECHAT_PAY", name: "WeChat Pay" },
  { id: "METHOD_ALIPAY", name: "Alipay" },
  { id: "METHOD_JCB", name: "JCB" },
  { id: "METHOD_DISCOVER", name: "Discover" },
  { id: "METHOD_DINERS", name: "Diners" },
  { id: "METHOD_SODEXO", name: "Sodexo" },
  { id: "METHOD_MONIZZE", name: "Monizze" },
  { id: "METHOD_EDENRED", name: "Edenred" },
  { id: "METHOD_PASS", name: "Pass" },
  { id: "METHOD_BIZUM", name: "Bizum" },
  { id: "METHOD_BANCOMAT_DEBIT", name: "Bancomat Debit" },
  { id: "METHOD_BANCOMAT_PAY", name: "Bancomat Pay" },
  { id: "METHOD_IDEAL", name: "iDEAL" },
  { id: "METHOD_PAYCONIQ", name: "Payconiq" },

];

export const PAYMENT_TYPE: Array<Options> = [
  { id: "PAYMENT_TYPE_CARD", name: "payment_type_card" },
  { id: "PAYMENT_TYPE_BANKTRANSFER", name: "payment_type_banktransfer" },
  { id: "PAYMENT_TYPE_CONTACTLESS", name: "payment_type_contactless" },
  { id: "PAYMENT_TYPE_WALLET", name: "payment_type_wallet" },
];

export const CHECKOUT_TYPE: Array<Options> = [
  { id: "CHECKOUT_CONTACTLESS", name: "checkout_contactless" },
  { id: "CHECKOUT_ONLINE", name: "checkout_online" },
  { id: "CHECKOUT_INSERTION", name: "checkout_insertion" },
  { id: "CHECKOUT_MAGSTRIPE", name: "checkout_magstripe" },
];

export const SETTLEMENT_STATUS: Array<Options> = [
  { id: "FINANCING_PAID", name: "financing_paid" },
  { id: "FINANCING_PENDING", name: "financing_pending" },
];
export const COLLECTION_TYPE: Array<Options> = [
  { id: "WEB", name: "WEB" },
  { id: "APP_MOBILE", name: "APP MOBILE" },
];

export const SERVICE_TYPE: Array<Options> = [
  { id: "PAGOAGIL", name: "PAGOAGIL" },
  { id: "QRHOMEDELIVERY", name: "QRHOMEDELIVERY" },
  { id: "SUSCRIPCIONES", name: "SUSCRIPCIONES" },
];

export const TRANSACTION_TYPE: Array<Options> = [
  { id: "CREDIT", name: "CREDIT" },
  { id: "DEBIT", name: "DEBIT" },
];
export const CARD_LEVEL: Array<Options> = [
  { id: "BUSINESS", name: "Business" },
  { id: "CONSUMER", name: "Consumer" },
];
export const CARD_TYPE: Array<Options> = [
  { id: "CREDIT", name: "Credit" },
  { id: "DEBIT", name: "Debit" },
];
export const CARD_BRAND: Array<Options> = [
  { id: "CB", name: "CB" },
  { id: "MAESTRO", name: "Maestro" },
  { id: "MASTERCARD", name: "MasterCard" },
  { id: "PANGOBANCOMAT", name: "PangoBancomat" },
  { id: "VISA", name: "Visa" },
  { id: "VPAY", name: "VPay" },
];

export const ACQUIRED_SCHEME: Array<Options> = [
  { id: "CB", name: "CB" },
  { id: "MASTERCARD", name: "MasterCard" },
  { id: "VISA", name: "Visa" },
  { id: "BANCOMAT", name: "Bancomat" },
  { id: "BANCONTACT", name: "Bancontact" },
];

export const CALCULATION_METHOD: Array<Options> = [
  { id: "BLENDED", name: "Blended" },
  { id: "IC_PLUS_PLUS", name: "IC++" },
];

export const PORTFOLIO_VOLUME_TRENDS_BY: Array<Options> = [
  { id: "PORTF_FILTER_GROSS", name: "portf_col_gross_sum" },
  { id: "PORTF_FILTER_NBR_TX", name: "portf_col_nbr_tx" },
];

export const MULTISELECT_VALUES = {
  [TransactionType.ACCEPTED]: {
    [TRANSACTION_ADDITIONAL_FILTERS.SCHEME.id]: SCHEME,
    [TRANSACTION_ADDITIONAL_FILTERS.TRANSACTIONAL_EVENT.id]: TRANS_EVENT,
    [TRANSACTION_ADDITIONAL_FILTERS.EVENT_STATUS.id]: EVENT_STATUS,
    [TRANSACTION_ADDITIONAL_FILTERS.PAYMENT_SOLUTION.id]: PAYMENT_SOLUTION,
    [TRANSACTION_ADDITIONAL_FILTERS.PAYMENT_METHOD.id]: PAYMENT_METHOD,
    [TRANSACTION_ADDITIONAL_FILTERS.PAYMENT_TYPE.id]: PAYMENT_TYPE,
    [TRANSACTION_ADDITIONAL_FILTERS.CHECKOUT_TYPE.id]: CHECKOUT_TYPE,
    [TRANSACTION_ADDITIONAL_FILTERS.SETTLEMENT_STATUS.id]: SETTLEMENT_STATUS,
    [TRANSACTION_ADDITIONAL_FILTERS.COLLECTION_TYPE.id]: COLLECTION_TYPE,
    [TRANSACTION_ADDITIONAL_FILTERS.SERVICE_TYPE.id]: SERVICE_TYPE,
  },
  [TransactionType.ACQUIRED]: {
    [TRANSACTION_ADDITIONAL_FILTERS.TRANSACTION_TYPE.id]: TRANSACTION_TYPE,
    [TRANSACTION_ADDITIONAL_FILTERS.CARD_LEVEL.id]: CARD_LEVEL,
    [TRANSACTION_ADDITIONAL_FILTERS.CARD_TYPE.id]: CARD_TYPE,
    [TRANSACTION_ADDITIONAL_FILTERS.CARD_BRAND.id]: CARD_BRAND,
    [TRANSACTION_ADDITIONAL_FILTERS.SCHEME.id]: ACQUIRED_SCHEME,
    [TRANSACTION_ADDITIONAL_FILTERS.CALCULATION_METHOD.id]: CALCULATION_METHOD,
  },
  DOCUMENTS: {
    [DOCUMENTS_FILTERS.SETTLEMENT_MONTH.id]: DOCUMENTS_MONTHS_VALUES(),
    [DOCUMENTS_FILTERS.SETTLEMENT_YEAR.id]: DOCUMENTS_YEARS_VALUES(),
  },
  PORTFOLIO_TREND_BY: {
    [PORTFOLIO_TREND_BY_FILTERS.TREND_BY.id]: PORTFOLIO_VOLUME_TRENDS_BY,
  },
};
