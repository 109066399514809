import {PermissionLine} from "../../constants/permissions/permissions";
import {PROFIL_USER} from "../../constants/user/user";

function isIrreversibleForProfile(permission: PermissionLine, userProfile: string): boolean {
    return userProfile === PROFIL_USER.ADMIN.id
        || (permission.irreversibleForProfiles && permission.irreversibleForProfiles.some(profile => profile === userProfile));
}

export const permissionUtils = {
    isIrreversibleForProfile
}
