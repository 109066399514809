import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { computeColumns, getTransactionDetailColumns, ScreenSize } from "utils/table/tableUtils";
import { intlUtils } from "utils/intlUtils";
import { ManagableColumn } from "interfaces/table/table";
import { TableData } from "interfaces/table/TableInterface";
import { History } from "interfaces/transaction/transaction";
import { formatToEuro } from "constants/intl/number/format";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { TransactionHistoryCardTitle } from "components/molecules/transaction/TransactionDetailHistory/TransactionHistoryTitle";
import Table from "components/atoms/table/Table";
import Card from "components/atoms/card/Card";
import Typography from "@mui/material/Typography";
import { paletteTheme } from "styles/themes/palette";
import { ACCEPTED_TRANSACTIONS_PATH } from "constants/routes/RoutePaths";

interface HistoryData extends TableData {
  transactionalEvent: string;
  eventStatus: string;
  transactionalEventId: string;
  eventTimestamp: string;
  grossAmount: string;
}

interface TransactionHistoryBlocProps {
  history?: History[];
  transactionId: string;
}

export const TransactionDetailHistory: FunctionComponent<TransactionHistoryBlocProps> = ({
  history,
  transactionId,
}) => {
  const intl = useIntl();

  const { smDown, smUp } = useMediaQueryUtil();

  const [columns, setColumns] = useState<Array<ManagableColumn>>([]);

  useEffect(() => {
    let size: ScreenSize = "lg";
    if (smDown) {
      size = "xs";
    }
    setColumns(getTransactionDetailColumns(size));
  }, [smDown, smUp]);

  const preparedColumns = useMemo(() => {
    const defaulStyles = {
      width: smDown ? 60 : 90,
      minWidth: 60,
      maxWidth: 300,
    };
    return [...computeColumns(columns, intl, defaulStyles)];
  }, [columns, intl.locale]);

  const responseToData = (data: Array<History>): Array<HistoryData> => {
    return data.map((historyItem: History) => {
      const grossAmount = intlUtils.formatNumber(intl, historyItem?.grossAmount, formatToEuro);
      return {
        transactionalEvent: historyItem?.transactionalEvent,
        eventStatus: historyItem?.eventStatus,
        transactionalEventId: historyItem?.transactionalEventId,
        eventTimestamp: historyItem?.eventTimestamp,
        grossAmount: grossAmount,
      };
    });
  };

  const lastSyncDate = history?.reverse()?.[0]?.eventTimestamp;

  return (
    <Card
      cardTitle={
        <TransactionHistoryCardTitle
          backUrl={ACCEPTED_TRANSACTIONS_PATH}
          title="transaction_det_page_title_1"
          transactionId={transactionId}
        />
      }
      headerMarginBottom="30px">
      <Typography color={paletteTheme.colors.GRAY} fontSize="10px" style={{ paddingBottom: "20px" }}>
        <FormattedMessage id="transaction_det_last_sync" />
        {lastSyncDate}
      </Typography>
      {history && (
        <Table
          columns={preparedColumns}
          staticPageSize={history.length}
          fetchOnMount
          disablePagination
          onFetch={() =>
            Promise.resolve({
              totalElements: history.length,
              datas: responseToData(history),
              last: true,
            })
          }
        />
      )}
    </Card>
  );
};
