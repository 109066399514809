import { MessageKeys } from "constants/messages/i8ln/EnglishMessages";
import { Options } from "constants/options/option";
import { FilterFieldType } from "interfaces/filter/filterColumn";
import { dateUtils } from "utils/common/dateUtils";

export const DOCUMENTS_MONTHS_VALUES = (): Options[] => {
  return dateUtils.get12MonthsNumberList();
};

export const DOCUMENTS_PAST_MONTH = () => {
  return dateUtils.getPastMonth();
};

export const DOCUMENTS_YEARS_VALUES = (): Options[] => {
  return dateUtils.getYearListSince2023();
};

export interface DocumentsFilterLine {
  id: string;
  label: MessageKeys;
  type?: FilterFieldType.MULTISELECT;
  multiple?: boolean;
  translateLabel?: boolean;
  displayCheckboxes?: boolean;
}

export interface DocumentsFilterLines {
  [key: string]: DocumentsFilterLine;
}

export const DOCUMENTS_FILTERS: DocumentsFilterLines = {
  SETTLEMENT_YEAR: {
    id: "settlementYears",
    label: "doc_settlement_year",
    type: FilterFieldType.MULTISELECT,
    translateLabel: false,
    multiple: false,
    displayCheckboxes: false,
  },
  SETTLEMENT_MONTH: {
    id: "settlementMonths",
    label: "doc_settlement_month",
    type: FilterFieldType.MULTISELECT,
    translateLabel: false,
    multiple: false,
    displayCheckboxes: false,
  },
};
