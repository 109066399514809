import { TransactionDetailTimestampCell } from "components/atoms/table/cells/TransactionDetailTimestampCell";
import { ManagableColumn } from "interfaces/table/table";

export const TRANSACTION_DETAIL_POSSIBLE_COLUMNS: Record<TransactionDetailColumnName, ManagableColumn> = {
  UUID: {
    id: "UUID",
    accessor: "transactionalEventId",
    header: "trans_det_tab_uuid",
    order: 1,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  TIMESTAMP: {
    id: "TIMESTAMP",
    accessor: "eventTimestamp",
    header: "trans_det_tab_timestamp",
    order: 2,
    cell: {
      component: TransactionDetailTimestampCell
    },
    disableSortBy: true,
  },
  EVENT: {
    id: "EVENT",
    accessor: "transactionalEvent",
    header: "trans_det_tab_event",
    order: 3,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  STATUS: {
    id: "STATUS",
    accessor: "eventStatus",
    header: "trans_det_tab_status",
    order: 4,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  AMOUNT: {
    id: "AMOUNT",
    accessor: "grossAmount",
    header: "trans_det_tab_amount",
    order: 5,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
};

export type TransactionDetailColumnName =
  | "UUID"
  | "TIMESTAMP"
  | "EVENT"
  | "STATUS"
  | "AMOUNT"
  ;
