import {styled} from "@mui/material/styles";
import {TextField, TextFieldProps} from "@mui/material";
import {paletteTheme} from "../../themes/palette";

export default styled(TextField)<TextFieldProps>(() => {
    return ({
        height: "24px",
        "& .MuiOutlinedInput-root": {
            height: "24px",
            padding: "0px 45px 0px 5px",
            fontSize: "12px",
            fontWeight: 600,
            "& .MuiOutlinedInput-input": {
                "&.Mui-disabled": {
                    cursor: "default"
                },
                height: "24px",
                paddingTop: "0px",
                paddingBottom: "0px",
            }
        },
        "& .MuiInputLabel-root": {
            fontSize: "10px",
            fontWeight: 600,
            maxWidth: "86px",
            color: paletteTheme.colors.GRAY,
            background: paletteTheme.colors.WHITE,
            padding: "0 1px",
            "&.Mui-focused, &.MuiInputLabel-shrink": {
                color: paletteTheme.colors.PRIMARY,
                maxWidth: "86px",
                transform: "translate(5px, -8px)",
            }
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline > legend, .MuiInputBase-adornedStart .MuiOutlinedInput-notchedOutline > legend": {
            fontSize: "10px",
            maxWidth: "86px",
            "& span": {
                padding: "0 2px 0 2px",
            }
        },
        "&:hover.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: paletteTheme.colors.GRAY,
        }
    })
})
