import React, {FunctionComponent, useEffect, useMemo, useState} from "react"
import {To, useLocation} from "react-router-dom"
import "react-toastify/dist/ReactToastify.css";
import "./styles/css/_app.scss"
import {authUtils} from "./utils/api/authUtils";
import {ChangeContext} from "./constants/context/ChangeContext";
import {UserContext} from "./constants/context/UserContext";
import {User} from "./interfaces/user/user";
import {userService} from "./services/user/UserService";
import {errorUtils} from "./utils/api/errorUtils";
import AppTheme from "./AppTheme";
import {userMapper} from "./mapper/user/user";
import {languageUtils} from "./utils/common/languageUtils";
import { Messages } from "./constants/messages/messages";
import {IntlProvider} from "react-intl";
import {useCookies} from "react-cookie";
import {HeaderCookie} from "./components/molecules/header/Header";
import {USER_PATH} from "./constants/routes/RoutePaths";
import { LocalStorageEnum } from "constants/localstorage/localstortage";


const App: FunctionComponent = () => {

    setInterval(() => authUtils.logoutInactiveUser(), 120000);

    const [, setCookies] = useCookies<string, HeaderCookie>(["lang"]);
    const [unsaveChange, setUnsaveChange] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [navigateTo, setNavigateTo] = useState<To>("");
    const [user, setUser] = useState<User>(null);
    const [locale, setLocale] = useState<string>("en-GB");
    const [permissions, setPermissions] = useState<Array<string>>([]);
    const [language, setLanguage] = useState<string>(languageUtils.getNavigatorLanguage());
    const location = useLocation();

    const userDetails = useMemo(() => {
        return {
            ...user,
            permissions
        }
    }, [user, permissions])

    const refreshUserDetails = (): void => {
        userService.getUserSelf().then(res => {
            setPermissions(res.permissions)
            setUser(userMapper.userResponseToUser(res))
        })
            .catch(errorUtils.handleBackErrors)
    }

    useEffect(() => {
        const navigatorLanguage = user?.language?.toLowerCase() ?? languageUtils.getNavigatorLanguage();
        setLanguage(navigatorLanguage);
        setCookies("lang", navigatorLanguage.toLowerCase(), {path: "/", secure: false, sameSite: "lax"})
        setLocale(languageUtils.getNavigatorLocale().code);
    }, [user])

    useEffect(() => {
        if (!location.pathname.includes(`/${USER_PATH}`) && !!localStorage.getItem(LocalStorageEnum.USER_SEARCH_FILTER)) {
            localStorage.removeItem(LocalStorageEnum.USER_SEARCH_FILTER)
        }
    }, [location])

    return (
        <IntlProvider locale={locale} messages={Messages[language]}>
            <ChangeContext.Provider
                value={{unsaveChange, setUnsaveChange, openDialog, setOpenDialog, navigateTo, setNavigateTo}}>
                <UserContext.Provider
                    value={{user, setUser, permissions, setPermissions, refreshUserDetails, userDetails}}>
                    <AppTheme/>
                </UserContext.Provider>
            </ChangeContext.Provider>
        </IntlProvider>

    )
}

export default App;
