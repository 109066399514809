import { AcquiredTransactionSearchResponse, TransactionSearchResponse } from "interfaces/api/ApiInterfaces";
import { IntlShape } from "react-intl";
import {
  TransactionAdditionalFiltersValues,
  TransactionData,
  TransactionRow,
} from "interfaces/transaction/transaction";
import { GenericSearchData } from "interfaces/table/TableInterface";
import { EMPTY_DATE_INITIAL_STATE } from "constants/datepicker/datepicker";
import {
  INITIAL_ACQUIRED_TRANSACTION_FILTER,
  TRANSACTION_ACCEPTED_FILTERS_INITIAL_VALUES,
  TRANSACTION_ACQUIRED_FILTERS_INITIAL_VALUES,
} from "constants/transaction/transactionFilter";
import { FilterValues } from "interfaces/filter/filter";
import { User } from "interfaces/user/user";
import { ViewContent } from "interfaces/view/view";
import { formatToEuro } from "constants/intl/number/format";
import { ManagableColumn } from "interfaces/table/table";
import { formatISO } from "date-fns";
import { AcquiredTransactionData, AcquiredTransactionResponse } from "interfaces/transaction/acquiredTransaction";
import { dateUtils } from "utils/common/dateUtils";
import { intlUtils } from "utils/intlUtils";
import { filterUtils } from "utils/business/filterUtils";
import { TransactionType } from "interfaces/filter/filterColumn";

function responseToData(
  response: TransactionSearchResponse<TransactionRow>,
  intl: IntlShape
): GenericSearchData<TransactionData> {

  return {
    datas: response.content.map((trans) => {
      const transaction = trans.transactionInfo[0] || {};
      const acceptation = trans.acceptationInfo[0] || {};
      const financing = trans.financingInfo[0] || {};
      const history = trans.historyInfo[0] || {};
      const merchant = trans.merchantInfo[0] || {};
      return {
        ...transaction,
        ...acceptation,
        ...financing,
        ...history,
        ...merchant,
        rootTransactionalEventId: trans.rootTransactionalEventId,
        netAmount: intlUtils.formatNumber(intl, financing.netAmount, formatToEuro),
        grossAmountHistory: intlUtils.formatNumber(intl, history.grossAmount, formatToEuro),
        grossAmountTransaction: intlUtils.formatNumber(intl, transaction.grossAmount, formatToEuro),
        // MPT-461: Hide fees columns
        //  interchange: intlUtils.formatNumber(intl,financing.interchange, formatToEuro),
        //  marketpayFees: intlUtils.formatNumber(intl,financing.marketpayFees, formatToEuro),
        totalCommission: intlUtils.formatNumber(intl, financing.totalCommission, formatToEuro),
        contractNumberHistory: history.contractNumber,
        contractNumberMerchant: merchant.contractNumber,
        collectionTypePayonline: merchant.collectionTypePayonline,
        servicePayOnline: merchant.servicePayOnline,
        original: trans,
      };
    }),
    last: response.last,
    totalElements: response.totalElements,
  };
}

const acquiredTransactionsResponse = (
  response: AcquiredTransactionSearchResponse<AcquiredTransactionResponse>,
  intl: IntlShape
): GenericSearchData<AcquiredTransactionData> => {
  return {
    datas: response.content.map((item) => {
      return {
        GROSS_AMOUNT: item.transactionInfo?.grossAmount?.toString(),
        TLC_NUMBER: item.transactionInfo?.tlcNumber,
        OPERATION_DATE: dateUtils.formatToISO(new Date(item?.transactionInfo?.operationDate)),
        TRANSACTION_SETTLEMENT_ID: item.transactionSettlementId,
        CONTRACT_NUMBER: item.transactionInfo?.contractNumber,
        OPERATION_TIME: dateUtils.formatTime(item?.transactionInfo?.operationDateTime),
        TRANSACTION_TYPE: item.transactionInfo?.transactionType,
        CURRENCY: item.transactionInfo?.currency,
        AUTHORISATION_CODE: item.transactionInfo?.authorisationCode,
        PAYMENT_TYPE: item.transactionInfo?.paymentType,
        PAYMENT_SOLUTION: item.transactionInfo?.paymentSolution,
        BATCH_ID: item.settlement.batchId,
        NET_AMOUNT: item.settlement?.netAmount?.toString(),
        SETTLEMENT_DATE: item.settlement.settlementDate,
        ACQUIRER: item.settlement.acquirer,
        SCHEME: item.settlement.scheme,
        SETTLEMENT_STATUS: item.settlement.settlementStatus,
        CARD_ZONE: item.customer.cardZone,
        CARD_BRAND: item.customer.cardBrand,
        ISSUING_COUNTRY: item.customer.issuingCountry,
        CARD_LEVEL: item.customer.cardLevel,
        CARD_TYPE: item.customer.cardType,
        BIN: item.customer.bin,
        PAN_LAST_FOUR_DIGITS: item.customer.panLastFourDigits,
        ACCOUNT: item.merchant.account,
        COMPANY: item.merchant.company,
        MERCHANT: item.merchant.merchant,
        COUNTRY: item.merchant.country,
        CALCULATION_METHOD: item.feesCalculation.calculationMethod,
        MARKETPAY_FIXED_FEES: item.feesCalculation.marketPayFixedFees,
        MARKETPAY_FEES_RATE: item.feesCalculation.marketPayFeesRate,
        FIXED_SCHEME_FEES: item.feesCalculation.fixedSchemeFees,
        SCHEME_FEES_RATE: item.feesCalculation.schemeFeesRate,
        TOTAL_ACQUIRING_FEES: item.feesAmount?.totalAcquiringFees?.toString(),
        INTERCHANGE_FEES: item.feesAmount.interchangeFees,
        SCHEME_FEES: item.feesAmount.schemeFees,
        TOTAL_MARKETPAY_FEES: item.feesAmount.totalMarketPayFees,
        FIXED_FEES_INCLUDED: item.feesAmount.fixedFeesIncluded,
        VARIABLE_FEES_INCLUDED: item.feesAmount.variableFeesIncluded,
        PROCESSING_HT: item.feesCalculation.processingFeesTaxExcluded,
        PROCESSING_TVA: item.feesCalculation.processingFeesVAT,
        PROCESSING_TTC: item.feesCalculation.processingFeesTaxIncluded,
        DISTRIB_AMOUNT: item.distrib?.distribAmount?.toString(),
        SHARE_MARGIN_AMOUNT: item.distrib?.distribShareMarginAmt?.toString(),
        SHARE_MARGIN_RATE: item.distrib?.distribShareMarginRate?.toString(),
        MP_SHARE_MARGIN_AMOUNT: item.distrib?.mpShareMarginAmt?.toString(),
        MP_SHARE_MARGIN_RATE: item.distrib?.mpShareMarginRate?.toString(),
        original: item,
      };
    }),
    totalElements: response.totalElements,
    last: response.last,
  };
};

const computePerimeterFilters = (defaultFilters: FilterValues, transactionType?: TransactionType) => {
  const accountKey = transactionType === TransactionType.ACQUIRED ? "accountId" : "accounts";
  const companyKey = transactionType === TransactionType.ACQUIRED ? "companyId" : "companies";
  const merchantKey = transactionType === TransactionType.ACQUIRED ? "merchantId" : "merchants";
  return {
    [accountKey]: defaultFilters.accounts.map((account) => account.id).toString(),
    [companyKey]: defaultFilters.companies.selectedOptions.map((company) => company.id).toString(),
    [merchantKey]: defaultFilters.merchants.selectedOptions.map((merchant) => merchant.id).toString(),
  };
};

const computeViewPerimeterFilters = (defaultFilters: FilterValues) => {
  return {
    accounts: defaultFilters.accounts.filter((account) => account.id !== "").map((account) => account.id),
    companies: defaultFilters.companies.selectedOptions
      .filter((account) => account.id !== "")
      .map((company) => company.id),
    merchants: defaultFilters.merchants.selectedOptions
      .filter((account) => account.id !== "")
      .map((merchant) => merchant.id),
  };
};

const computeViewFilters = (
  defaultFilters: FilterValues,
  additionalFilters: TransactionAdditionalFiltersValues,
  columns: Array<ManagableColumn>
): ViewContent => {
  return {
    operationStartDate: dateUtils.formatToISO(defaultFilters.operationDate.startDate),
    operationEndDate: dateUtils.formatToISO(defaultFilters.operationDate.endDate),
    transactionalEventId: additionalFilters.transactionalEventId,
    orderNumber: additionalFilters.orderNumber,
    transactionalEvent: additionalFilters.transactionalEvent.map((event) => event.id),
    eventStatus: additionalFilters.eventStatus.map((status) => status.id),
    paymentSolution: additionalFilters.paymentSolution.map((solution) => solution.id),
    paymentMethod: additionalFilters.paymentMethod.map((method) => method.id),
    paymentType: additionalFilters.paymentType.map((paymentType) => paymentType.id),
    checkoutType: additionalFilters.checkoutType.map((checkoutType) => checkoutType.id),
    grossAmountMin: additionalFilters.grossAmountMin,
    grossAmountMax: additionalFilters.grossAmountMax,
    bin: additionalFilters.bin,
    financingStatus: additionalFilters.financingStatus.map((financingStatus) => financingStatus.id),
    financingStartDate: dateUtils.formatToISO(additionalFilters.financingDate.startDate),
    financingEndDate: dateUtils.formatToISO(additionalFilters.financingDate.endDate),
    batchId: additionalFilters.batchId,
    columns: columns.map((column) => column?.accessor),
    scheme: additionalFilters.scheme.map((item) => item.id),
    posId: additionalFilters.posId
  };
};

const computeFilters = (
  defaultFilters: FilterValues,
  additionalFilters: TransactionAdditionalFiltersValues
): Record<string, string> => {
  return {
    ...computePerimeterFilters(defaultFilters),
    operationStartDate: dateUtils.formatToISO(defaultFilters.operationDate.startDate),
    operationEndDate: dateUtils.formatToISO(defaultFilters.operationDate.endDate),
    transactionalEventId: additionalFilters.transactionalEventId,
    orderNumber: additionalFilters.orderNumber,
    transactionalEvent: additionalFilters.transactionalEvent.map((event) => event.id).toString(),
    eventStatus: additionalFilters.eventStatus.map((status) => status.id).toString(),
    paymentSolution: additionalFilters.paymentSolution.map((solution) => solution.id).toString(),
    paymentMethod: additionalFilters.paymentMethod.map((method) => method.id).toString(),
    paymentType: additionalFilters.paymentType.map((paymentType) => paymentType.id).toString(),
    checkoutType: additionalFilters.checkoutType.map((checkoutType) => checkoutType.id).toString(),
    grossAmountMin: additionalFilters.grossAmountMin.replace(",", "."),
    grossAmountMax: additionalFilters.grossAmountMax.replace(",", "."),
    bin: additionalFilters.bin,
    financingStatus: additionalFilters.financingStatus.map((financingStatus) => financingStatus.id).toString(),
    financingStartDate: dateUtils.formatToISO(additionalFilters.financingDate.startDate),
    financingEndDate: dateUtils.formatToISO(additionalFilters.financingDate.endDate),
    batchId: additionalFilters.batchId,
    scheme: additionalFilters.scheme.map((item) => item.id).toString(),
    // collectionTypePayonline: additionalFilters.collectionTypePayonline.map((item) => item.id).toString(),
    collectionType: additionalFilters.collectionType.map((item) => item.id).toString(),
    // servicePayOnline: additionalFilters.servicePayOnline.map((item) => item.id).toString(),
    serviceType: additionalFilters.serviceType.map((item) => item.id).toString(),
    posId: additionalFilters.posId
  };
};

const computeAcquiredTransactionsFilters = (
  filters: FilterValues,
  additionalFilters: TransactionAdditionalFiltersValues
): Record<string, string> => {
  return {
    ...computePerimeterFilters(filters, TransactionType.ACQUIRED),
    tlcNumber: additionalFilters.tlcNumber ?? "",
    contractNumber: additionalFilters.contractNumber ?? "",
    settlementDateMin: filters.settlementDate ? formatISO(filters.settlementDate?.startDate) : "",
    settlementDateMax: filters.settlementDate ? formatISO(filters.settlementDate?.endDate) : "",
    operationDateMin: filters.operationDate?.startDate ? formatISO(filters.operationDate?.startDate) : "",
    operationDateMax: filters.operationDate?.startDate ? formatISO(dateUtils.getNextDay(filters.operationDate?.endDate)) : "",
    grossAmountMin: additionalFilters.grossAmountMin.replace(",", "."),
    grossAmountMax: additionalFilters.grossAmountMax.replace(",", "."),
    netAmountMin: additionalFilters.netAmountMin.replace(",", "."),
    netAmountMax: additionalFilters.netAmountMax.replace(",", "."),
    bin: additionalFilters.bin,
    batchId: additionalFilters.batchId,
    scheme: additionalFilters.scheme.map((item) => item.id).toString(),
    transactionSettlementId: additionalFilters.transactionSettlementId,
    transactionType: additionalFilters.transactionType.map((item) => item.id).toString(),
    cardLevel: additionalFilters.cardLevel.map((item) => item.id).toString(),
    cardType: additionalFilters.cardType.map((item) => item.id).toString(),
    cardBrand: additionalFilters.cardBrand.map((item) => item.id).toString(),
    calculationMethod: additionalFilters.calculationMethod.map((item) => item.id).toString(),
  };
};

const initAdditionalFilters = (
  typeTransaction: TransactionType,
  savedTransactionAdditionalFilters: TransactionAdditionalFiltersValues
): TransactionAdditionalFiltersValues => {
  if (savedTransactionAdditionalFilters) {
    if (typeTransaction === TransactionType.ACCEPTED) {
      const startDate = savedTransactionAdditionalFilters.financingDate.startDate;
      const endDate = savedTransactionAdditionalFilters.financingDate.endDate;
      return {
        ...savedTransactionAdditionalFilters,
        financingDate: {
          ...savedTransactionAdditionalFilters.financingDate,
          startDate: startDate ? new Date(startDate) : null,
          endDate: endDate ? new Date(endDate) : new Date(""),
        },
      };
    }
    return {
      ...savedTransactionAdditionalFilters,
    };
  }

  return typeTransaction === TransactionType.ACCEPTED
    ? TRANSACTION_ACCEPTED_FILTERS_INITIAL_VALUES
    : TRANSACTION_ACQUIRED_FILTERS_INITIAL_VALUES;
};

const initSavedTransactionFilter = (
  user: User,
  transactionType: TransactionType,
  savedTransactionFilters?: FilterValues
): FilterValues => {
  if (!savedTransactionFilters) {
    return null;
  }

  const initialDates = filterUtils.getInitialRangeState(user);
  const startDate = savedTransactionFilters?.operationDate?.startDate;
  const endDate = savedTransactionFilters?.operationDate?.endDate;

  if (transactionType === TransactionType.ACCEPTED) {
    return {
      ...filterUtils.initSavedPerimeterFilters(savedTransactionFilters),
      operationDate: {
        ...savedTransactionFilters?.operationDate,
        key: "selection",
        startDate: startDate ? new Date(startDate) : initialDates.startDate,
        endDate: endDate ? new Date(endDate) : initialDates.endDate,
      },
    };
  }

  if (transactionType === TransactionType.ACQUIRED) {
    return {
      ...filterUtils.initSavedPerimeterFilters(savedTransactionFilters),
      settlementDate: {
        ...savedTransactionFilters?.settlementDate,
        key: "selection",
        startDate: savedTransactionFilters?.settlementDate
          ? dateUtils.resetTimeToMidnight(savedTransactionFilters?.settlementDate?.startDate)
          : INITIAL_ACQUIRED_TRANSACTION_FILTER.settlementDate.startDate,
        endDate: savedTransactionFilters?.settlementDate
          ? dateUtils.resetTimeToMidnight(savedTransactionFilters?.settlementDate?.endDate)
          : INITIAL_ACQUIRED_TRANSACTION_FILTER.settlementDate.endDate,
      },
      operationDate: {
        ...savedTransactionFilters?.operationDate,
        key: "selection",
        startDate: savedTransactionFilters?.operationDate
          ? new Date(savedTransactionFilters?.operationDate?.startDate)
          : EMPTY_DATE_INITIAL_STATE?.startDate,
        endDate: savedTransactionFilters?.operationDate
          ? new Date(savedTransactionFilters?.operationDate?.endDate)
          : EMPTY_DATE_INITIAL_STATE?.endDate,
      },
    };
  }
};

export const transactionUtils = {
  responseToData,
  acquiredTransactionsResponse,
  initAdditionalFilters,
  initSavedTransactionFilter,
  computeFilters,
  computeAcquiredTransactionsFilters,
  computeViewFilters,
  computePerimeterFilters,
  computeViewPerimeterFilters,
};
