import React, { FunctionComponent } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./mobileMenu.module.scss";

interface MenuProps {
    onMenuClick: () => void,
    mobileMenuOpen: boolean
}
const MobileMenu: FunctionComponent<MenuProps> = ({ onMenuClick, mobileMenuOpen }) => {
    return (
        <div onClick={() => { onMenuClick() }}>
            <IconButton aria-label="menu" role="menu">
                <MenuIcon className={`hamburger ${mobileMenuOpen ? styles.open : ""}`} />
            </IconButton>
        </div>
    )
}

export default MobileMenu;
