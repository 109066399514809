import React, {FunctionComponent} from "react";
import {
    Chip as MuiChip, ChipProps as MuiChipProps
} from "@mui/material";
import {authUtils} from "../../../utils/api/authUtils";

const Chip: FunctionComponent<MuiChipProps> = ({onChange, children, ...rest}) => {

    const onChangeWithTracker = (event: React.ChangeEvent<HTMLInputElement>) => {
        authUtils.refreshTokenExpirationDate();
        onChange && onChange(event);
    };

    return (<MuiChip {...rest} onChange={onChangeWithTracker}>{children}</MuiChip>);
}


export default Chip;
