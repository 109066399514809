import React, {FunctionComponent, useEffect} from "react"
import {RouteProps, useNavigate} from "react-router-dom";
import {HOME_PATH,} from "../../constants/routes/RoutePaths";
import {useAuthUser} from "../../hooks/useAuthUser";
import {PermissionLine} from "../../constants/permissions/permissions";
import {rightUtils} from "../../utils/business/rightUtils";
import {toastUtils} from "../../utils/toast/toastUtils";

/**
 * Route you can only access if you're logged in
 * @param children
 * @constructor
 */

interface PermissionRouteProps extends RouteProps {
    permission?: PermissionLine,
    redirectPath?: string
}

const PermissionRoute: FunctionComponent<PermissionRouteProps> = ({
                                                                permission,
                                                                redirectPath = HOME_PATH,
                                                                children
                                                            }) => {

    const {permissions: authPermissions, user} = useAuthUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (permission && authPermissions && !rightUtils.hasPermission(authPermissions, permission)) {
            toastUtils.errorToastI8ln(permission.forbiddenLabel)
            navigate(redirectPath, {replace: true})
        }
    }, [user])

    return user ? <>{children}</> : <></>;
}

export default PermissionRoute;
