import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";

interface ToastContentProps {
    id: string,
    values?: Record<string, React.ReactNode>,
    className?: string
}

const ToastContentI8ln: FunctionComponent<ToastContentProps> = ({
                                                                id,
                                                                values,
                                                                className
                                                            }) =>
    (
        <div className={className}>
            <span><FormattedMessage id={id} values={values}/></span>
        </div>
    )


export default ToastContentI8ln;
