import {
  CompanyFilterOptions,
  Options,
  MerchantFilterOptions,
} from "constants/options/option";
import { Entity } from "interfaces/perimeter/perimeter";
import { CompanyDetailResponse } from "interfaces/perimeter/company";
import { MerchantDetailResponse } from "interfaces/perimeter/merchant";
import { languageUtils } from "utils/common/languageUtils";
import { arrayUtils } from "utils/common/arrayUtils";

function mapEntityToOptions(companies: Array<Entity>): Array<Options> {
  return companies.map(company => ({ id: company.id, name: company.name }))
}

function mapPerimeterToAccountOptions(account: Entity): Array<Options> {
  return [{ id: account.id, name: account.name }];
}

function buildCheckboxAll() {
  return { id: "", name: languageUtils.getMessage("filter_all_comp").toUpperCase() };
}

function mapPerimeterToCompaniesOptions(companies: Array<CompanyDetailResponse>, hideLabelAll: boolean): Array<CompanyFilterOptions> {

  if (arrayUtils.isEmpty(companies)) {
    return [];
  }

  const companiesOptions = companies.map(company => ({
    id: company.id,
    name: company.name,
    idAccount: company.idAccount
  }));

  if (arrayUtils.hasUniqueElement(companies)) {
    return companiesOptions;
  }
  return hideLabelAll ? [...companiesOptions] : [buildCheckboxAll(), ...companiesOptions]

}

function mapPerimeterToMerchantsOptions(merchants: Array<MerchantDetailResponse>, hideLabelAll: boolean): Array<MerchantFilterOptions> {

  if (arrayUtils.isEmpty(merchants)) {
    return [];
  }

  const merchantOptions = merchants.map(merchant => ({
    id: merchant.id,
    name: merchant.name,
    idAccount: merchant.idAccount,
    idCompany: merchant.idCompany
  }));

  if (arrayUtils.hasUniqueElement(merchants)) {
    return merchantOptions
  }
  return hideLabelAll ? [...merchantOptions] : [buildCheckboxAll(), ...merchantOptions]

}

export const selectUtils = {
  mapEntityToOptions,
  mapPerimeterToAccountOptions,
  mapPerimeterToCompaniesOptions,
  mapPerimeterToMerchantsOptions,
  buildCheckboxAll,
}
