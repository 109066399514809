import React, { FunctionComponent } from "react";
import { intlUtils } from "utils/intlUtils";
import { FormattedMessage, useIntl } from "react-intl";
import { DetailInformation, DetailInformationElement } from "interfaces/transaction/transaction";
import { formatToEuro } from "constants/intl/number/format";
import { Typography } from "@mui/material";
import styles from "components/molecules/transaction/DetailBlock/detailBlock.module.scss";
import { DetailRow } from "components/molecules/transaction/DetailRow";

export interface DetailBlocProps {
  detailInformation: DetailInformation;
  detailInformationElement: DetailInformationElement[];
  header?: string;
}

export const DetailBlock: FunctionComponent<DetailBlocProps> = ({ detailInformation, detailInformationElement, header }) => {
  const intl = useIntl();

  const formatToCurrency = (text: string) => {
    return intlUtils.formatNumber(intl, parseFloat(text), formatToEuro);
  };

  const getFormattedValue = (element: DetailInformationElement): string => {
    const text = detailInformation?.[element.id]?.toString();

    if (text) {
      if (element.type === "currency") {
        return formatToCurrency(text);
      }
      if (element.type === "percent") {
        return text + "%";
      }
      return text;
    }
    return "-";
  };

  return (
    <>
      {header && (
        <Typography variant="h2" style={{ marginBottom: "40px" }}>
          <FormattedMessage id={header} />
        </Typography>
      )}
      <div className={styles.grid}>
        {detailInformationElement.map((element: DetailInformationElement) => (
          <React.Fragment key={element.id}>
            <DetailRow label={element.label} value={getFormattedValue(element)} />
            {element.children &&
              element.children.map((child: DetailInformationElement) => (
                <React.Fragment key={child.id}>
                  <DetailRow label={child.label} value={getFormattedValue(child)} isSubItem={true} />
                </React.Fragment>
              ))}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
