import React, {FunctionComponent} from "react";
import FlexBox from "../flex/FlexBox";
import {FlexAttributValue} from "../../../types/flex/flex";
import {Typography} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import {paletteTheme} from "../../../styles/themes/palette";

interface CircleRoundedButtonProps {
    onClick: () => void
}

const CircleRoundedButton: FunctionComponent<CircleRoundedButtonProps> = ({onClick}) => (
    <FlexBox
        sx={{"& .MuiSvgIcon-root": {color: paletteTheme.colors.SUCCESS}, "&:hover .MuiSvgIcon-root": {color: paletteTheme.colors.PRIMARY}}}
        className="cursor-pointer"
        marginLeft={7}
        display={FlexAttributValue.INLINE}
        flexDirection={FlexAttributValue.ROW}
        alignContent={FlexAttributValue.CENTER}
        onClick={onClick}
        alignItems={FlexAttributValue.CENTER}>
        <AddCircle
            fontSize="small"/>
        <Typography
            marginLeft={1}
            fontWeight={600}>
            <FormattedMessage id="add_company"/>
        </Typography>
    </FlexBox>)


export default CircleRoundedButton;
