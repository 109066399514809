import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { MaterialUiColor } from "types/materialui/materialui";
import WatchedLink from "components/atoms/link/WatchedLink";
import Typography from "@mui/material/Typography";
import { paletteTheme } from "styles/themes/palette";
import { ReactComponent as ArrowBack } from "assets/ArrowBack.svg";
import CloseIcon from "@mui/icons-material/Close";
import styles from "components/molecules/BackLink/backLink.module.scss";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface BackLinkProps {
  to: string;
  label: string;
  showCloseIconOnXSSM?: boolean;
}

export const BackLink: FunctionComponent<BackLinkProps> = (
  {
    to,
    label,
    showCloseIconOnXSSM,
  }) => {

  const { mdDown } = useMediaQueryUtil();

  return (
    <WatchedLink noUnderline to={to}>
      <div className={`flex flex-center ${styles.wrapper}`}>
        {showCloseIconOnXSSM && mdDown ?
          <CloseIcon className={styles["mobile-close"]} /> :
          <>
            <ArrowBack color={MaterialUiColor.PRIMARY}></ArrowBack>
            <Typography display="inline" marginLeft="5px" color={paletteTheme.palette.primary["main"]}>
              <FormattedMessage id={label} />
            </Typography>
          </>
        }
      </div>
    </WatchedLink>
  )
}
