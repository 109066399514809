import React, { FunctionComponent } from "react";
import { MenuItem, MenuItemProps } from "@mui/material";
import styles from "components/atoms/select/DropdownStyled/MenuItemStyled/menuItemStyled.module.scss";

export const MenuItemStyled: FunctionComponent<MenuItemProps> = ({ key, value, children, ...rest }) => {
    return (
        <MenuItem className={styles["menu-item"]} key={key} value={value} {...rest} >
            {children}
        </MenuItem>
    )
}
