import { FilterValues } from "interfaces/filter/filter";
import { TableData } from "interfaces/table/TableInterface";
import { QueryParam } from "interfaces/UserInterface";
import {
  PORTFOLIO_BILLING_ROUTE,
  PORTFOLIO_COMPANY_ROUTE,
  PORTFOLIO_MERCHANT_ROUTE,
  PORTFOLIO_TRENDS_VOLUME_ROUTE,
  PORTFOLIO_BILLING_EXPORT_ROUTE,
} from "constants/routes/ApiRoutes";
import { BlobWithFilename, httpService } from "services/HttpService";
import { perimeterService } from "services/user/PerimeterService";
import { dateUtils } from "utils/common/dateUtils";
import { PortfolioResponse, PortfolioTrendsItem } from "components/molecules/portfolio/PortfolioTrendsSection";
import { PortfolioTopBottomItem } from "components/molecules/portfolio/PortfolioTopBottomSection";
import { BillingResponse } from "components/molecules/portfolio/PortfolioBillingSection";
import { queryUtils } from "utils/api/queryUtils";
import { INITIAL_RANGE_LAST_WEEK } from "constants/datepicker/datepicker";

export interface TopMerchantResponse {
  item: TopMerchantItem[];
  total?: number;
}
export type SortDirection = "ASC" | "DESC";
export interface Sort {
  by: string;
  dir: SortDirection;
}

interface TopMerchantItem extends TableData, AccountIdAndName, CompanyIdAndName, MerchantIdAndName {
  docCount: number; // Integer
  transactionSuccessRate: number; // Double
  numberTransaction: number; // Double
  grossAmount: number; // Double
}

export interface TopMerchantsFilter extends AccountCompanyMerchantIds {
  minAcceptationDatetime: Date; // Datetime
  maxAcceptationDatetime: Date; // Datetime
  sort: Sort;
}

interface AccountIdAndName {
  accountId: number;
  accountName: string;
}

interface CompanyIdAndName {
  companyId: number;
  companyName: string;
}

interface MerchantIdAndName {
  merchantId: number;
  merchantName: string;
}

interface AccountCompanyMerchantIds {
  accountIds: readonly number[]; // Long[]
  companyIds: readonly number[]; // Long[]
  merchantIds: readonly number[]; // Long[]
}

const computeFiltersTrends = (defaultFilters: FilterValues): Record<string, string> => {
  return {
    accountId: defaultFilters.accounts.map((account) => account.id).toString(),
    companyId: defaultFilters.companies.selectedOptions.map((company) => company.id).toString(),
    merchantId: defaultFilters.merchants.selectedOptions.map((merchant) => merchant.id).toString(),
  };
};

const computeFiltersTopBottom = (filters: FilterValues, companyMode: boolean) => {
  return {
    accountId: filters.accounts.map((account) => account.id).toString(),
    companyId: filters.companies.selectedOptions.map((company) => company.id).toString(),
    merchantId: companyMode ? "" : filters.merchants.selectedOptions.map((merchant) => merchant.id).toString(),
    minAcceptationDatetime: dateUtils.getDateTimeFormat(filters.acceptationRange?.startDate ?? INITIAL_RANGE_LAST_WEEK.startDate),
    maxAcceptationDatetime: dateUtils.getDateTimeFormat(filters.acceptationRange?.endDate ?? INITIAL_RANGE_LAST_WEEK.endDate),
  };
};

const buildFiltersTrends = (defaultFilters: FilterValues) => {
  const searchParams: Record<string, string> = computeFiltersTrends(defaultFilters);
  const params = new URLSearchParams({ ...searchParams });
  [...params.entries()].forEach(([key, value]) => {
    if (value === undefined || value === "") {
      params.delete(key);
    }
  });

  return params.toString() ? `${params.toString()}&` : "";
};
const buildFiltersTopBottom = (defaultFilters: FilterValues, companyMode: boolean) => {
  const searchParams: Record<string, string> = computeFiltersTopBottom(defaultFilters, companyMode);
  const params = new URLSearchParams({ ...searchParams });
  [...params.entries()].forEach(([key, value]) => {
    if (value === undefined || value === "") {
      params.delete(key);
    }
  });

  return params.toString() ? `${params.toString()}&` : "";
};

const queryParamToPageable = ({
  page = undefined,
  pageSize = 50,
  sortDirection = "ASC",
  sortField = "rateAmountEvolutionDay",
}: QueryParam): string => {
  const pageParam = page ? `&page=${page}` : "";
  const pageSizeParam = pageSize ? `pageSize=${pageSize}` : "";

  return `${pageSizeParam + pageParam}&sortBy=${sortField}&sortDirection=${sortDirection}`;
};

const getUrlPortfolioTrends = (defaultFilters: FilterValues, searchParams: QueryParam): string => {
  return `?${buildFiltersTrends(defaultFilters)}${queryParamToPageable(searchParams)}`;
};

const getUrlPortfolioTopBottom = (
  defaultFilters: FilterValues,
  searchParams: QueryParam,
  companyMode: boolean
): string => {
  return `?${buildFiltersTopBottom(defaultFilters, companyMode)}${queryParamToPageable(searchParams)}`;
};

export const getPortfolioTrends =
  (defaultFilters: FilterValues, options?: RequestInit) =>
  (searchParams: QueryParam): Promise<PortfolioResponse<PortfolioTrendsItem>> => {
    const url = PORTFOLIO_TRENDS_VOLUME_ROUTE + getUrlPortfolioTrends(defaultFilters, searchParams);
    return httpService.get(url, options);
  };

export const getPortfolioTopBottom =
  (defaultFilters: FilterValues, companyMode: boolean, options?: RequestInit) =>
  (searchParams: QueryParam): Promise<PortfolioResponse<PortfolioTopBottomItem>> => {
    const apiRoute = companyMode ? PORTFOLIO_COMPANY_ROUTE : PORTFOLIO_MERCHANT_ROUTE;

    const url = apiRoute + getUrlPortfolioTopBottom(defaultFilters, searchParams, companyMode);

    return httpService.get(url, options);
  };

// Billing table

const computeFilters = (filters: FilterValues) => {
  return {
    accountId: filters.accounts.map((account) => account.id).toString(),
    companyId: filters.companies.selectedOptions.map((company) => company.id).toString(),
    settlementMonth: filters.settlementYears.at(0).id + "-" + filters.settlementMonths.at(0).id,
  };
};

const buildFilters = (defaultFilters: FilterValues): string => {
  const searchParams: Record<string, string> = computeFilters(defaultFilters);
  const params = new URLSearchParams({ ...searchParams });
  return `&${queryUtils.filterURLSearchParams(params).toString()}`;
};

export const getBillingData =
  (defaultFilters: FilterValues, options?: RequestInit) =>
  (searchParams: QueryParam): Promise<BillingResponse> => {
    const url = `${PORTFOLIO_BILLING_ROUTE}${queryUtils.paginateQuery(searchParams)}${buildFilters(defaultFilters)}`;
    return httpService.get(url, options);
  };

export const downloadExportBilling = (
  defaultFilters: FilterValues,
  options?: RequestInit
): Promise<BlobWithFilename> => {
  const searchParams: Record<string, string> = computeFilters(defaultFilters);
  const params = new URLSearchParams({ ...searchParams });
  const paramsString = queryUtils.filterURLSearchParams(params).toString();
  const url = `${PORTFOLIO_BILLING_EXPORT_ROUTE}?${paramsString}`;
  return httpService.getReadableStreamWithFilename(url, options);
};

export const getAccountCountry = async (accountId: string): Promise<string> => {
  return await perimeterService.getAuthorizedAccounts()
    .then((response) => response.find((account) => parseInt(account.id) === parseInt(accountId)))
    .then((account) => account?.country)
}
