import { LocalStorageEnum } from "constants/localstorage/localstortage";
import {LOGIN_PATH} from "../../constants/routes/RoutePaths";
import {Token} from "../../interfaces/auth/Auth";
import {dateUtils} from "../common/dateUtils";


function cleanSession(): void {
    Object.values(LocalStorageEnum).forEach((localStorageKey) => localStorage.removeItem(localStorageKey));
}

/**
 * Logout Inactive user based on the expiredAt attribute
 * expiredAt is refreshed on every action
 */
function logoutInactiveUser(): void {
    const token: Token = JSON.parse(localStorage.getItem(LocalStorageEnum.TOKEN));
    if (token !== null && new Date(token.expired_at) < new Date()) {
        cleanSession();
        window.location.href = LOGIN_PATH
    }
}

/**
 * Refresh the expiredAt token
 */
function refreshTokenExpirationDate(): void {
    const token: Token = JSON.parse(localStorage.getItem(LocalStorageEnum.TOKEN));
    if (token !== null) {
        token.expired_at = dateUtils.getDateExpiration();
        localStorage.setItem(LocalStorageEnum.TOKEN, JSON.stringify(token));
    }
}

export const authUtils = {
    cleanSession,
    logoutInactiveUser,
    refreshTokenExpirationDate
}
