import React from "react";
import { FixedSizeList, ListOnItemsRenderedProps } from "react-window";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CallbackVirtualTable } from "./Table";

interface VirtualiseRowProps {
  loading: boolean,
  staticPageSize?: number,
  fetchError: boolean,
  onItemsRendered: (props: ListOnItemsRenderedProps) => unknown,
  virtualizeItemCount?: number,
  itemCount: number,
  rows: ({ index, style }: CallbackVirtualTable) => JSX.Element
}

const VirtualizedTable: React.ForwardRefRenderFunction<FixedSizeList, VirtualiseRowProps> = (
  { loading, fetchError, staticPageSize = 10, itemCount, onItemsRendered, rows },
  ref
) => {
  const ITEM_SIZE = 41;
  if (fetchError) {
    return (
      <Typography marginTop={3} variant="h2" textAlign="center">
        <FormattedMessage id="fetch_error" />
      </Typography>
    );
  }

  return (
    !loading &&
    (itemCount > 0 ? (
      <FixedSizeList
        className="scroll-table"
        height={ITEM_SIZE * staticPageSize}
        itemCount={itemCount}
        width="100%"
        ref={ref}
        onItemsRendered={onItemsRendered}
        itemSize={ITEM_SIZE}>
        {rows}
      </FixedSizeList>
    ) : (
      <Typography marginTop={3} variant="h2" textAlign="center" style={{marginBottom: "30px"}}>
        <FormattedMessage id="no_data_found" />
      </Typography>
    ))
  );
};

export default React.forwardRef(VirtualizedTable);
