import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { computeColumns, getBillingColumns } from "utils/table/tableUtils";
import {
  getBillingData,
  downloadExportBilling,
  getAccountCountry,
} from "services/portfolio/PortfolioService";
import { GenericSearchData } from "interfaces/table/TableInterface";
import { Typography } from "@mui/material";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";

import Table from "components/atoms/table/Table";
import { FilterLayout } from "components/layouts/FilterLayout";
import { useBillingFilters } from "hooks/portfolio/useBillingFilters";
import { useBillingFilterConfig } from "hooks/portfolio/useBillingFilterConfig";
import { usePortfolioTableFetch } from "hooks/portfolio/usePortfolioTableFetch";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import { useAuthUser } from "hooks/useAuthUser";
import { rightUtils } from "utils/business/rightUtils";
import { paletteTheme } from "styles/themes/palette";
import styles from "./portfolioBillingSection.module.scss";

import { ExportButton } from "components/atoms/button/ExportButton";
import { CONTENT_TYPE_EXPORT } from "constants/export/export";
import { useExport } from "hooks/export/useExport";
import ManyExportLineDialog from "components/molecules/dialog/ManyExportLineDialog";

export interface BillingResponse {
  content: Array<BillingItem>;
  last: boolean;
  totalElements: number;
  totalDistributorCommission: number;
}

export interface BillingItem {
  accountId: number;
  account: string;
  companyId: number;
  company: string;
  settlementMonth: string;
  country: string;
  impostaDiBollo: number;
  grossAmount: number;
  merchantFees: number;
  netAmount: number;
  interchangeFees: number;
  schemeFees: number;
  transactionNumber: number;
  transactionType: string;
  uuid: string;
  distribAmt: number;
  distribShareMarginRate: number;
  distribShareMarginAmt: number;
  processingFeesAmtTTC: number;
  processingFeesAmtTVA: number;
  processingFeesAmtHT: number;
}

export interface BillingData {
  id: string;
  COMPANY: string;
  SETTLEMENT_MONTH: string;
  DISTRIB_AMT: number;
  SHARE_MARGIN_RATE: number;
  SHARE_MARGIN_AMOUNT: number;
  TRANSACTION_NUMBER: number;
  GROSS_AMOUNT: number;
  MERCHANT_FEES: number;
  NET_AMOUNT: number;
  INTERCHANGE_FEES: number;
  SCHEME_FEES: number;
  PROCESSING_FEES_AMT_TTC: number;
  PROCESSING_FEES_AMT_TVA: number;
  PROCESSING_FEES_AMT_HT: number;
  TRANSACTION_TYPE: string;
  UUID: string;
  COUNTRY: string;
  IMPOSTA_DI_BOLLO: number;
}

interface BillingSectionProps {
  setDistributorComission: (val: number) => void;
}

export const PortfolioBillingSection: FunctionComponent<BillingSectionProps> = ({setDistributorComission}) => {
  const intl = useIntl();
  const { user } = useAuthUser();
  const { formatMessage } = useIntlFormatter();
  const [total, setTotal] = useState(0);
  const [currentAccountId, setCurrentAccountId] = useState<string>("");
  const [isItalianAccount, setIsItalianAccount] = useState(false);

  const responseToData = (response: BillingResponse): GenericSearchData<BillingData> => {
    setTotal(response.totalElements);
    setDistributorComission(response.totalDistributorCommission);
    return {
      datas: response.content.map((item, idx) => {
        return {
          id: idx.toString(),
          COMPANY: item.company,
          SETTLEMENT_MONTH: item.settlementMonth,
          COUNTRY: item.country,
          DISTRIB_AMT: item.distribAmt,
          SHARE_MARGIN_RATE: item.distribShareMarginRate,
          SHARE_MARGIN_AMOUNT: item.distribShareMarginAmt,
          TRANSACTION_NUMBER: item.transactionNumber,
          GROSS_AMOUNT: item.grossAmount,
          MERCHANT_FEES: item.merchantFees,
          NET_AMOUNT: item.netAmount,
          INTERCHANGE_FEES: item.interchangeFees,
          SCHEME_FEES: item.schemeFees,
          PROCESSING_FEES_AMT_TTC: item.processingFeesAmtTTC,
          PROCESSING_FEES_AMT_TVA: item.processingFeesAmtTVA,
          PROCESSING_FEES_AMT_HT: item.processingFeesAmtHT,
          TRANSACTION_TYPE: item.transactionType,
          UUID: item.uuid,
          IMPOSTA_DI_BOLLO: item.impostaDiBollo,
        };
      }),
      last: response.last,
      totalElements: response.totalElements,
    };
  };

  const {
    additionalFilterConfig,
    filters: billingFilters,
    showContent,
    tableRef
  } = useBillingFilters();

  const { filters: mainFilters } = useBillingFilterConfig(additionalFilterConfig);

  useEffect(() => {
    const accountId = billingFilters.accounts.map((account) => account.id).toString();
    if (accountId === "" || accountId === currentAccountId) return;
    setCurrentAccountId(accountId);
    getAccountCountry(accountId)
      .then((country) => setIsItalianAccount(country === "IT"))
      .catch((error) => console.error("Error getting account country", error));
  }, [billingFilters.accounts]);

  const columns = useMemo(() => {
    const defaulStyles = {
      width: 130,
      minWidth: 100,
      maxWidth: 300,
    };

    const isDistributor = rightUtils.isAdmin(user) || rightUtils.isDistributor(user);

    return [
      ...computeColumns(
        getBillingColumns(isDistributor, isItalianAccount),
        intl,
        defaulStyles
      ),
    ];
  }, [intl.locale, user, isItalianAccount]);

  const {
    count,
    onFetchData
  } = usePortfolioTableFetch<BillingResponse, BillingData>(
    getBillingData(billingFilters),
    responseToData
  );

  const {
    openManyExportLine,
    setOpenManyExportLine,
    isExportLoading,
    onDownload
  } = useExport(
    count,
    process.env.REACT_APP_MAX_BILLING_EXPORT,
    () => downloadExportBilling(billingFilters),
    "billing_export"
  );

  return (
    <div>
      <div style={{ marginBottom: "40px" }}>
        <Typography variant="h2" marginBottom="20px">
          {formatMessage("filter_by")}
        </Typography>
        <FilterLayout mainFilters={mainFilters} />
      </div>
      <div>
        {showContent && (
          <>
            <div className={`flex flex-row-between flex-col-end ${styles.wrapTableInfo}`}>
              <Typography>
                {formatMessage("table_label", {
                  count: <strong style={{ color: paletteTheme.colors.PRIMARY }}>{total}</strong>,
                })}
              </Typography>
            </div>

            <Table
              ref={tableRef}
              columns={columns}
              onFetch={onFetchData}
              disableNextPage={!perimeterUtils.hasPerimeterFilters(billingFilters)}
              FooterButtonList={
                <ExportButton
                  loading={isExportLoading}
                  onClick={() => onDownload(CONTENT_TYPE_EXPORT.CSV)}
                />
              }
            />
          </>
        )}
        {!showContent && (
          <div
            className="flex-col flex-col-center"
            style={{ marginBottom: "30px", display: showContent ? "none" : "flex" }}
          >
            <Typography variant="h2">{formatMessage("admin_trans_view_title")}</Typography>
            <Typography>{formatMessage("admin_trans_view_text")}</Typography>
          </div>
        )}
      </div>

      {openManyExportLine && (
        <ManyExportLineDialog
          openDialog={openManyExportLine}
          onCloseDialog={() => setOpenManyExportLine(false)}
          maxLines={process.env.REACT_APP_MAX_TRANSACTION_EXPORT}
        />
      )}
    </div>
  );
};
