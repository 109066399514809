import React, {FunctionComponent} from "react";
import TextButton from "../../../atoms/button/TextButton";
import {useIntl} from "react-intl";
import styles from "./notificationHideButton.module.scss"

interface NotificationHideButtonProps {
    label: string;
    marginLeft?: string;
    onHide: () => void
}

const NotificationHideButton: FunctionComponent<NotificationHideButtonProps> = ({
                                                                                    label,
                                                                                    marginLeft = "0px",
                                                                                    onHide
                                                                                }) => {

    const intl = useIntl();
    return (<TextButton
        onClick={onHide}
        className={styles.button}
        style={{
            marginLeft: marginLeft,
        }}>
        {intl.formatMessage({id: label})}
    </TextButton>)
}

export default NotificationHideButton;
