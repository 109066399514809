import React, { FunctionComponent } from "react";
import { HistogramSelectorLine } from "interfaces/histogram/histogram";
import { HistogramTitle } from "components/molecules/histogram/HistogramHeader/HistogramTitle";
import { HistogramDropDowns } from "components/molecules/histogram/HistogramHeader/HistogramDropdowns";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface HeaderProps {
    chartTitle: string,
    period: string,
    amount: string,
    amountSelectorOptions: HistogramSelectorLine[],
    onChangePeriod: (value: string) => void,
    onChangeAmount: (value: string) => void,
}

export const HistogramHeader: FunctionComponent<HeaderProps> = ({
    chartTitle,
    period,
    amount,
    amountSelectorOptions,
    onChangePeriod,
    onChangeAmount,
}) => {
    const { lgDown } = useMediaQueryUtil();

    return (
        <>
            <HistogramTitle chartTitle={chartTitle} />
            {/* XS, SM and MD scrrens - display dropdowns instead of radio buttons */}
            {lgDown &&
                <HistogramDropDowns
                    period={period}
                    amount={amount}
                    amountSelectorOptions={amountSelectorOptions}
                    onChangePeriod={onChangePeriod}
                    onChangeAmount={onChangeAmount}
                />
            }
        </>
    )
}
