import {MY_ACCOUNT_PATH, USER_PATH} from "../routes/RoutePaths";
import {InfoOutlined} from "@mui/icons-material";
import {paletteTheme} from "../../styles/themes/palette";
import {NotificationType, Notifications} from "../../interfaces/notification/notification";

/**
 * Notification parameters
 * Notifications with specific sentence structures require their own NotificationComponent
 */


export const NOTIFICATIONS: Notifications = {
    NOTIF_NEW_MERCHANT: {
        id: "NOTIF_NEW_MERCHANT",
        label: "notif_new_merchant",
        url: `/${MY_ACCOUNT_PATH}`,
        urlColor: paletteTheme.colors.PRIMARY,
        textColor: paletteTheme.colors.DEFAULT_TEXT_COLOR,
        IconNotification: InfoOutlined,
        iconColor: paletteTheme.colors.PRIMARY,
        type: NotificationType.PERIMETER,
    },
    NOTIF_NEW_COMPANY: {
        id: "NOTIF_NEW_COMPANY",
        label: "notif_new_comp",
        url: `/${MY_ACCOUNT_PATH}`,
        urlColor: paletteTheme.colors.PRIMARY,
        textColor: paletteTheme.colors.DEFAULT_TEXT_COLOR,
        IconNotification: InfoOutlined,
        iconColor: paletteTheme.colors.PRIMARY,
        type: NotificationType.PERIMETER,
    },
    NOTIF_NEW_ACCOUNT: {
        id: "NOTIF_NEW_ACCOUNT",
        label: "notif_new_account",
        url: `/${MY_ACCOUNT_PATH}`,
        urlColor: paletteTheme.colors.PRIMARY,
        textColor: paletteTheme.colors.DEFAULT_TEXT_COLOR,
        IconNotification: InfoOutlined,
        iconColor: paletteTheme.colors.PRIMARY,
        type: NotificationType.PERIMETER,
    },
    NOTIF_NEW_OPERATOR: {
        id: "NOTIF_NEW_OPERATOR",
        label: "notif_new_operator_inapp",
        url: `/${MY_ACCOUNT_PATH}`,
        urlColor: paletteTheme.colors.PRIMARY,
        textColor: paletteTheme.colors.DEFAULT_TEXT_COLOR,
        IconNotification: InfoOutlined,
        iconColor: paletteTheme.colors.PRIMARY,
        type: NotificationType.PERIMETER,
    },    
    NOTIF_NEW_MERCHANT_ADMIN: {
        id: "NOTIF_NEW_MERCHANT_ADMIN",
        label: "notif_new_merchant_admin_inapp",
        url: `/${USER_PATH}`,
        targetUrlBuilder: (userId:number) => `/${USER_PATH}/${userId}`,
        urlColor: paletteTheme.colors.PRIMARY,
        textColor: paletteTheme.colors.DEFAULT_TEXT_COLOR,
        IconNotification: InfoOutlined,
        iconColor: paletteTheme.colors.PRIMARY,
        type: NotificationType.USER,
    },
    NOTIF_NEW_MARKETPAY_ADMIN: {
        id: "NOTIF_NEW_MARKETPAY_ADMIN",
        label: "notif_new_marketpay_admin_inapp",
        url: `/${USER_PATH}`,
        targetUrlBuilder: (userId:number) => `/${USER_PATH}/${userId}`,
        urlColor: paletteTheme.colors.PRIMARY,
        textColor: paletteTheme.colors.DEFAULT_TEXT_COLOR,
        IconNotification: InfoOutlined,
        iconColor: paletteTheme.colors.PRIMARY,
        type: NotificationType.USER,
    },
}
