import {
  AutocompleteCheckboxConfig,
  PerimeterConfig,
  CommonFilterProps,
  useWrappedFilterComponents,
  QueryAndResetConfig,
  MultichoiceConfig
} from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { PORTFOLIO_TREND_BY_FILTERS } from "constants/portfolio/portfolioFilters";
import { FilterColumn } from "interfaces/filter/filterColumn";


type VolumeTrendsFilterConfig = {
  sharedFilterProps: CommonFilterProps;
  perimeterConfig: PerimeterConfig;
  autoCompleteCheckboxConfig: AutocompleteCheckboxConfig;
  multichoiceConfig: MultichoiceConfig;
  queryAndResetConfig: QueryAndResetConfig;
};

export const useVolumeTrendsFilterConfig = (config: VolumeTrendsFilterConfig) => {
  const { sharedFilterProps, perimeterConfig, autoCompleteCheckboxConfig, queryAndResetConfig, multichoiceConfig } = config;

  const { onSearch, onReset, errors } = sharedFilterProps;

  const { autocompleteCheckbox, queryAndReset, multiChoiceCheckbox } = useWrappedFilterComponents();

  const filters: FilterColumn[] = [
    {
      name: "Account dropdown",
      component: autocompleteCheckbox("account", perimeterConfig, autoCompleteCheckboxConfig),
      index: 0,
    },
    {
      name: "Company dropdown",
      component: autocompleteCheckbox("company", perimeterConfig, autoCompleteCheckboxConfig),
      index: 1,
    },
    {
      name: "Merchant dropdown",
      component: autocompleteCheckbox("merchant", perimeterConfig, autoCompleteCheckboxConfig),
      index: 2,
    },
    {
      name: "Trend-by dropdown",
      component: multiChoiceCheckbox(PORTFOLIO_TREND_BY_FILTERS.TREND_BY, multichoiceConfig, "PORTFOLIO_TREND_BY"),
      index: 3,
    },
    {
      name: "Download and reset",
      component: queryAndReset(onSearch, onReset, errors, queryAndResetConfig),
      index: 4,
      config: {
        xs: { colSpan: 2 },
        sm: {
          colSpan: 1,
          colStart: 5,
        },
      },
    },
  ];

  return {
    filters,
  };
};
