import { ReportColumnName } from "constants/report/report";
import { ManagableColumn } from "interfaces/table/table";
import DownloadButton from "components/atoms/button/DownloadButton";
import { MoreActionsReports } from "components/molecules/MoreActions/MoreActionsReports";

export const REPORT_POSSIBLE_COLUMN: Record<ReportColumnName, ManagableColumn> = {
  NAME: {
    id: "NAME",
    accessor: "name",
    header: "reports_table_report_name_col",
    order: 1,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  LATEST_EDITION: {
    id: "LATEST_EDITION",
    accessor: "latestEdition",
    header: "reports_table_latest_edition_col",
    order: 2,
    cell: {
      type: "date",
    },
    disableSortBy: true,
  },
  FREQUENCY: {
    id: "FREQUENCY",
    accessor: "frequency",
    header: "reports_table_frequency_col",
    order: 3,
    cell: {
      type: "text",
    },
    disableSortBy: true,
  },
  LATEST_REPORT: {
    id: "LATEST_REPORT",
    accessor: "latestReport",
    header: "reports_table_latest_report_col",
    order: 4,
    cell: {
      component: DownloadButton,
    },
    disableSortBy: true,
  },
  MORE_ACTIONS: {
    id: "MORE_ACTIONS",
    accessor: "moreActions",
    header: "reports_table_latest_report_col",
    order: 5,
    cell: {
      component: MoreActionsReports,
    },
    options: {
      minWidth: 30,
      width: 30,
      maxWidth: 30,
    },
    disableSortBy: true,
  },
};
