import React, { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { KeyboardTab } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { FEEDBACK_LINK } from "constants/menu/menu";
import { SideMenuPageItems } from "components/molecules/menu/SideMenuPageItems";
import SideMenuUserItems from "components/molecules/menu/SideMenuUserItems";
import styles from "components/molecules/menu/SideMenu/sideMenu.module.scss";
import { BorderButton } from "components/atoms/button/BorderButton";

interface SideMenuProps {
  onReduce: () => void;
  mobileMenuOpen: boolean;
  mobile: boolean;
  onCloseMobileMenu?: () => void;
  reduce?: boolean;
  className?: string;
}

export const SideMenu: FunctionComponent<SideMenuProps> = ({ onReduce, reduce, mobileMenuOpen, mobile, onCloseMobileMenu }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { smDown } = useMediaQueryUtil();
  const cNames = `${styles["side-menu"]} ${reduce ? styles.reduce : ""} ${mobile && !mobileMenuOpen ? styles["mobile-closed"] : ""}`;
  const showSidebarItems = (mobile && mobileMenuOpen) || !mobile;

  useEffect(() => {
    onCloseMobileMenu();
  }, [pathname]);

  return (
    <>
      <div className={cNames}>
        {showSidebarItems && (
          <>
            <SideMenuPageItems isReduced={reduce} />
            <SideMenuUserItems isReduced={reduce} isMobile={mobile} onCloseMobileMenu={onCloseMobileMenu} />

            {smDown && (
              <div className={styles["feedback-wrap"]}>
                <BorderButton href={FEEDBACK_LINK} target="_blanc" title={FEEDBACK_LINK}>
                  {intl.formatMessage({ id: "send_feedback" })}
                </BorderButton>
              </div>
            )}
            {!mobile && (
              <Box className={styles["reduce-icon-wrap"]}>
                <KeyboardTab cursor="pointer" fontSize="small" sx={{ transform: `${reduce ? "" : "rotate(180deg)"}` }} onClick={onReduce} />
              </Box>
            )}
          </>
        )}
      </div>
      {mobile && mobileMenuOpen && <Box className={styles["sidebar-underlay"]} onClick={onCloseMobileMenu} />}
    </>
  );
};
