import React, { FunctionComponent } from "react";
import { FilterTextField, FilterTextFieldProps } from "components/molecules/textfield/FilterTextField";
import { TextFieldConfig } from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { TransactionAdditionalFilter } from "interfaces/transaction/transaction";

interface TextFieldWrapperProps {
  itemConfig: TransactionAdditionalFilter;
  textFieldConfig: TextFieldConfig;
}

interface Config extends FilterTextFieldProps {
  hideItem?: boolean;
}

export const TextFieldWrapper: FunctionComponent<TextFieldWrapperProps> = ({
  textFieldConfig: { transactionAdditionalFilters, onChangeAlphanumericFilter, onChangeFilterValue },
  itemConfig,
}) => {
  const inputProps = itemConfig.config
    ? {
        maxLength: itemConfig.config.maxLength,
        onKeyPress: itemConfig.config.onKeyPress(),
      }
    : undefined;

  const textFieldConfig = {
    filterId: itemConfig.id,
    filterLabel: itemConfig.label,
    inputProps: inputProps,
    value: transactionAdditionalFilters[itemConfig.id],
    onChangeFilter: onChangeAlphanumericFilter,
    hideItem: false,
  };

  return <>{!textFieldConfig.hideItem && <FilterTextField {...textFieldConfig} />}</>;
};
