import React, { FunctionComponent } from "react";
import { InputLabel as MUIInputLabel, InputLabelProps } from "@mui/material";
import styles from "components/atoms/input/InputLabel/inputLabel.module.scss";
import { arrayUtils } from "utils/common/arrayUtils";

interface LabelProps extends InputLabelProps {
    values: Array<unknown>;
}

export const InputLabel: FunctionComponent<LabelProps> = ({
    children,
    className,
    values,
    ...rest
}) => {

    const hasValues = arrayUtils.isEmpty(values) ? "" : "has-values" ;
    const classNameFormatted = `${styles.label} ${styles[`${className}`]} ${styles[`${hasValues}`]}`;

    return <MUIInputLabel className={classNameFormatted} {...rest}> {children} </MUIInputLabel>;
}

