import React, {FunctionComponent} from "react";
import {Entity} from "../../../interfaces/perimeter/perimeter";
import {authUtils} from "../../../utils/api/authUtils";
import {MaterialUiColor, MaterialUiColorType} from "../../../types/materialui/materialui";
import {paletteTheme} from "../../../styles/themes/palette";
import Chip from "../chip/Chip";
import {ChipProps} from "@mui/material";
import {REFERENTIAL_STATUS} from "../../../constants/referential/referential";


interface ChipOptionProps extends ChipProps {
    value: Entity,
    onAction?: (value: Entity) => void,
    icon?: React.ReactElement,
    color?: MaterialUiColorType,
}

const ChipOption: FunctionComponent<ChipOptionProps> = ({
                                                            color = MaterialUiColor.PRIMARY,
                                                            value,
                                                            onAction,
                                                            icon,
                                                            ...rest
                                                        }) => {

    const onActionWithTracker = (_value: Entity) => () => {
        authUtils.refreshTokenExpirationDate();
        onAction(_value)
    }

    return (
        <Chip
            {...rest}
            disabled={!value.hasRightOn}
            color={color}
            size="small"
            sx={{
                backgroundColor: value?.status === REFERENTIAL_STATUS.ACTIVE.id ? paletteTheme.colors.SUCCESS : paletteTheme.colors.DANGER_LIGHT,
                fontSize: 10,
                marginRight: "3px",
                "& .MuiChip-deleteIcon": {
                    fontSize: 10,
                    marginLeft: "1px"
                },
                ":hover": {
                    backgroundColor: value?.status === REFERENTIAL_STATUS.ACTIVE.id ? paletteTheme.colors.PRIMARY : paletteTheme.colors.DANGER_LIGHT,
                },
                "& .MuiSvgIcon-root": {
                    cursor: value?.status === REFERENTIAL_STATUS.ACTIVE.id ? "pointer" : "default",
                }
            }}
            key={value.id}
            label={value.name}
            onDelete={onActionWithTracker(value)}
            deleteIcon={icon}
        />
    );
}


export default ChipOption;
