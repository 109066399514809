import {QueryParam} from "../../interfaces/UserInterface";
import {SearchResponse} from "../../interfaces/api/ApiInterfaces";
import {SearchData, TableData} from "../../interfaces/table/TableInterface";
import {useState} from "react";

interface TableFetchInstance {
    onFetchData: (param: QueryParam) => Promise<SearchData>,
    count: number,
}

interface TableFetchOptions {
    afterFetchingData?: () => void,
    beforeFetchingData?: () => void
}

export function useTableFetch<U>(
    promise: (param: QueryParam) => Promise<SearchResponse<U>>,
    mapper: (datas: Array<U>) => Array<TableData>,
    options?: TableFetchOptions): TableFetchInstance {

    const [count, setCount] = useState<number>();

    const onFetchData = (param: QueryParam): Promise<SearchData> => {
        options?.beforeFetchingData && options.beforeFetchingData();
        return new Promise((resolve, reject) => {
            promise(param)
                .then(response => {
                    options?.afterFetchingData && options.afterFetchingData()
                    setCount(response.totalElements)
                    resolve({
                        datas: mapper(response.content),
                        totalElements: response.totalElements,
                        last: response.last
                    })
                }).catch(reject)
        })
    }

    return {
        onFetchData,
        count
    };
}
