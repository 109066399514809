import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { intlUtils } from "utils/intlUtils";
import { formatToEuro } from "constants/intl/number/format";
import { InfoTooltip } from "components/atoms/tooltip/InfoTooltip";
import styles from "./portfolioInfoTooltip.module.scss";

interface PortfolioInfoTooltip {
  firstTitle: string;
  secondTitle: string;
  labelEvolution: string;
  endDate: string;
  startDate: string;
  amount2: number;
  amount1: number;
  volume1: number;
  volume2: number;
  rateAmount: number;
  rateVolume: number;
}

export const PortfolioInfoTooltip: FunctionComponent<PortfolioInfoTooltip> = ({
  firstTitle,
  secondTitle,
  labelEvolution,
  endDate,
  startDate,
  amount2,
  amount1,
  volume1,
  volume2,
  rateAmount,
  rateVolume,
}) => {
  const intl = useIntl();
  return (
    <InfoTooltip>
      <div className={styles.wrapper}>
        <p className={styles.bold}>{intl.formatMessage({ id: firstTitle })}</p>
        <p>
          {endDate} : {intlUtils.formatNumber(intl, amount2, formatToEuro)}
        </p>
        <p>
          {startDate} : {intlUtils.formatNumber(intl, amount1, formatToEuro)}
        </p>
        <p>
          {intl.formatMessage({ id: labelEvolution })}{" "}
          <span className={styles.bold}>
            {rateAmount}
            {rateAmount ? "%" : ""}
          </span>
        </p>
      </div>
      <div className={styles.wrapper}>
        <p className={styles.bold}>{intl.formatMessage({ id: secondTitle })}</p>
        <p>
          {endDate} : {volume2}
        </p>
        <p>
          {startDate} : {volume1}
        </p>
        <p>
          {intl.formatMessage({ id: labelEvolution })}{" "}
          <span className={styles.bold}>
            {rateVolume.toFixed(2)}
            {rateVolume ? "%" : ""}
          </span>
        </p>
      </div>
    </InfoTooltip>
  );
};
