import React, {FunctionComponent} from "react";
import {
    Box
} from "@mui/material";
import {Entity} from "../../../interfaces/perimeter/perimeter";
import ChipOption from "./ChipOption";
import {Clear} from "@mui/icons-material";

interface ChipOptionRenderProps {
    selected: Array<Entity>,
    onRemove: (value: Entity) => void,
}

const ChipOptionRender: FunctionComponent<ChipOptionRenderProps> = ({
                                                                        selected,
                                                                        onRemove,
                                                                    }) => (
    <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5, padding: "8px"}}>
        {selected.map((value) =>
            <ChipOption
                key={value.id}
                value={value}
                onMouseDown={(event) => event.stopPropagation()}
                onAction={onRemove}
                icon={<Clear/>}/>)}
    </Box>
)


export default ChipOptionRender;
