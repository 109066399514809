import React from "react";
import {toast} from "react-toastify";
import ToastContent from "../../components/atoms/toast/ToastContent";
import ToastContentI8ln from "../../components/atoms/toast/ToastContentI8ln";
import {toastStyleUtils} from "./toastStyleUtils";

function successToast(content: string): React.ReactText {
    return toast.success(<ToastContent message={content}/>, toastStyleUtils.successOptions);
}

function successToastI8ln(label: string, values?: Record<string, React.ReactNode>): React.ReactText {
    return toast.success(<ToastContentI8ln id={label} values={values}/>, toastStyleUtils.successOptions);
}

function warningToast(content: string): React.ReactText {
    return toast.warning(<ToastContent message={content}/>, toastStyleUtils.warningOptions);
}

function infoToastI8ln(label: string, values?: Record<string, React.ReactNode>): React.ReactText {
    return toast.info(<ToastContentI8ln id={label} values={values}/>, toastStyleUtils.infoOptions);
}

function errorToastI8ln(label: string, values?: Record<string, React.ReactNode>): React.ReactText {
    return toast.error(<ToastContentI8ln id={label} values={values}/>, toastStyleUtils.errorOptions);
}

function errorToast(content: string): React.ReactText {
    return toast.error(<ToastContent message={content}/>, toastStyleUtils.errorOptions);
}

export const toastUtils = {
    errorToast,
    successToast,
    infoToastI8ln,
    warningToast,
    errorToastI8ln,
    successToastI8ln
}
