import React, { FunctionComponent } from "react";
import { AmountRangeInput, AmountRangeInputProps } from "components/molecules/input/AmountRangeInput";
import { AmountRangeConfig } from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { TransactionAdditionalFilter } from "interfaces/transaction/transaction";

interface AmountRangeWrapperProps {
  itemConfig: TransactionAdditionalFilter;
  amountRangeConfig: AmountRangeConfig;
}

interface RangeConfig extends AmountRangeInputProps {
  hideItem?: boolean;
}

export const AmountRangeWrapper: FunctionComponent<AmountRangeWrapperProps> = ({
  amountRangeConfig: { errors, setErrors, transactionAdditionalFilters, onChangeAmountRangeFilter },
  itemConfig,
}) => {
  const { formatMessage } = useIntlFormatter();

  const rangeConfig:RangeConfig = {
    name: itemConfig.id,
    label: formatMessage(itemConfig.label),
    onChangeMin: onChangeAmountRangeFilter(itemConfig.minId),
    onChangeMax: onChangeAmountRangeFilter(itemConfig.maxId),
    errors: errors,
    setErrors: setErrors,
    possibleErrors: [],
    min: transactionAdditionalFilters[`${itemConfig.id}Min`],
    max: transactionAdditionalFilters[`${itemConfig.id}Max`],
    hideItem: false,
  };

  return <>{!rangeConfig.hideItem && <AmountRangeInput {...rangeConfig} />}</>;
};
