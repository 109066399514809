import React, { Fragment, FunctionComponent } from "react";
import { Grid, Typography } from "@mui/material";
import AdvancedFormattedMessage from "components/atoms/intl/AdvancedFormattedMessage";
import { mapNotifTypeToMessageCode } from "constants/notification/NotificationSettingsConfig";
import { NotificationChannel, NotificationSettingsType, NotificationToggle } from "types/notifications/NotificationSettingsTypes";
import { ChannelToggle } from "./ChannelToggle";

export const ToggleRow:FunctionComponent<{
    activeToggles: readonly NotificationToggle[],
    notifType: NotificationSettingsType,
    triggerToggle: (toggle:NotificationToggle) => void,
}> = ({
    activeToggles,
    notifType,
    triggerToggle,
}) => <Fragment key={notifType}>
    <Grid item xs={6}>
        <Typography marginLeft={8} variant="body2" paddingBottom={1}>
            <AdvancedFormattedMessage title={mapNotifTypeToMessageCode(notifType)}/>
        </Typography>
    </Grid>
    <Grid item xs={3}>
        <ChannelToggle activeToggles={activeToggles} channel={NotificationChannel.IN_APP} type={notifType} triggerToggle={triggerToggle}/>
    </Grid>
    <Grid item xs={3}>
        <ChannelToggle activeToggles={activeToggles} channel={NotificationChannel.EMAIL} type={notifType} triggerToggle={triggerToggle}/>
    </Grid>
</Fragment>
