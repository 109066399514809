import React, { FunctionComponent } from "react";
import { KeyNumbersCards } from "../../keyNumbersCards";
import { BoxProps } from "@mui/material";
import { UserDetails } from "interfaces/user/user";
import { KeyNumbers } from "interfaces/transaction/transaction";
import { rightUtils } from "utils/business/rightUtils";
import { PERMISSIONS } from "constants/permissions/permissions";
import { DAILY_SALES_KEY_NUMBERS } from "constants/transaction/transaction";
import { DAILY_SETTLEMENT_KEY_NUMBERS } from "constants/settlement/settlement";
import styles from "components/molecules/homepage/DailyKeyNumbersDisplay/dailyKeyNumbersDisplay.module.scss";

interface DailyKeyNumbersDisplayProps extends BoxProps {
    user: UserDetails,
    transDailyKeyNumbers: KeyNumbers,
    settDailyKeyNumbers: KeyNumbers,
}

export const DailyKeyNumbersDisplay: FunctionComponent<DailyKeyNumbersDisplayProps> = ({
    user,
    transDailyKeyNumbers = {
        lastNumberTransaction: 0,
        lastGrossAmount: 0
    },
    settDailyKeyNumbers = {
        lastGrossAmount: 0,
        lastFinanced: 0,
        lastFees: 0
    },
}) => {
    const hasTransactionsConsultRights = rightUtils.userHasPermission(user, PERMISSIONS.TRANSACTIONS_CONSULT);
    const hasSettlementsConsultRights = rightUtils.userHasPermission(user, PERMISSIONS.SETTLEMENTS_CONSULT);

    return (
        <div className={styles.wrapper} >
            {transDailyKeyNumbers && hasTransactionsConsultRights &&
                <KeyNumbersCards
                    keyNumberElements={DAILY_SALES_KEY_NUMBERS}
                    keyNumbers={transDailyKeyNumbers}
                    title="homepage_title_sales"
                    dashboardLayout
                />
            }
            {settDailyKeyNumbers && hasSettlementsConsultRights &&
                <KeyNumbersCards
                    keyNumberElements={DAILY_SETTLEMENT_KEY_NUMBERS}
                    keyNumbers={settDailyKeyNumbers}
                    title="homepage_title_settlements"
                    dashboardLayout
                />
            }
        </div>
    );
}
