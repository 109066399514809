import React, {FunctionComponent} from "react";
import {Box} from "@mui/material";
import {MaterialUiColor} from "../../types/materialui/materialui";
import Chip from "../atoms/chip/Chip";
import {Entity} from "../../interfaces/perimeter/perimeter";
import {REFERENTIAL_STATUS} from "../../constants/referential/referential";

interface ChipListProps {
    entities: Array<Entity>
}

const ChipList: FunctionComponent<ChipListProps> = ({entities}) =>
    (<Box maxWidth={260}>
        {entities.map(entity =>
            <Chip
                key={entity.idSpring}
                color={entity?.status === REFERENTIAL_STATUS.ACTIVE.id ? MaterialUiColor.PRIMARY: MaterialUiColor.ERROR}
                size="small"
                sx={{fontSize: 10, marginRight: "4px"}}
                label={entity.name}/>)}
    </Box>)


export default ChipList;
