import React, {FunctionComponent} from "react";
import {Box, BoxProps, SxProps, Theme} from "@mui/material";
import {
    AlignContentType,
    AlignItemsType,
    FlexDirectionType,
    FlexDisplayType,
    FlexAttributValue,
    FlexWrapType,
    JustifyContentType,
} from "../../../types/flex/flex";

export interface FlexBoxProps extends BoxProps {
    flexDirection?: FlexDirectionType,
    flexWrap?: FlexWrapType,
    justifyContent?: JustifyContentType,
    justifySelf?: JustifyContentType,
    alignItems?: AlignItemsType,
    alignSelf?: AlignItemsType,
    alignContent?: AlignContentType,
    display?: FlexDisplayType
    order?: number,
    sx?: SxProps<Theme>
    flexGrow?: 1 | 0
}

const FlexBox: FunctionComponent<FlexBoxProps> = ({
                                                      flexDirection = FlexAttributValue.ROW,
                                                      flexWrap = FlexAttributValue.INHERIT,
                                                      justifyContent = FlexAttributValue.INHERIT,
                                                      alignItems = FlexAttributValue.INHERIT,
                                                      alignContent = FlexAttributValue.INHERIT,
                                                      display = FlexAttributValue.FLEX,
                                                      justifySelf,
                                                      alignSelf,
                                                      order,
                                                      flexGrow,
                                                      children,
                                                      sx,
                                                      ...rest
                                                  }) =>
    (<Box {...rest} sx={{...sx,
        justifySelf,
        alignSelf,
        display,
        flexDirection,
        flexWrap,
        justifyContent,
        alignItems,
        alignContent,
        order,
        flexGrow
    }}>
        {children}
    </Box>)


export default FlexBox;
