import {
    PerimeterResponse,
    PerimeterUpdate, UpdateEntity
} from "../../interfaces/perimeter/perimeter";
import {perimeterUtils} from "./perimeterUtils";
import {randomUtils} from "../common/randomUtils";
import {AuthorizedMerchantResponseByCompany, AuthorizedMerchantsResponse} from "../../interfaces/perimeter/merchant";
import {AuthorizedCompanyResponse, CompanyUpdate, CompanyWrappedDetailResponse} from "../../interfaces/perimeter/company";

const hasRightCompanyOrAllMerchants = (perimeter: PerimeterUpdate, res: PerimeterResponse) => {
    return perimeter.companies.every(company => !!company.hasAllMerchants
        && res.companies.length === perimeter.companies.length)
}

const hasAllMerchants = (companyUpdate: CompanyUpdate, authorizedMerchantsCompany: AuthorizedMerchantsResponse) => {
    // If the user has 'hasCompanyPerimeter' then he must have all merchants
    return companyUpdate.hasCompanyPerimeter ||
        // If not, check all authorized merchants are included in the companyUpdate
        (companyUpdate.company.id !== "" &&
            authorizedMerchantsCompany.merchants.every(merchant => companyUpdate.merchants.some(item => merchant.id === item.id)))
}

const aggregatePerimeterWithAuthorizedPerimeter = (res: PerimeterResponse, initialPerimeterCalculated: PerimeterUpdate): PerimeterUpdate => {
    // Array<CompanyDetailResponse> to AuthorizedMerchantsResponseByCompany
    const newAuthorizedMerchantsResponseByCompany: AuthorizedMerchantResponseByCompany = {};
    res.companies.forEach(item => newAuthorizedMerchantsResponseByCompany[item.id] = item);
    // Format perimeter to check if user has all authorized merchants
    const authorizedPerimeter: PerimeterUpdate = initialPerimeterCalculated.companies.length > 0 ?
        {...initialPerimeterCalculated} :
        {
            account: {id: res.id, idSpring: res.idSpring, name: res.name, hasRightOn: res.hasRightOn},
            companies: [perimeterUtils.buildEmptyCompanyPerimeter()]
        };
    authorizedPerimeter.companies = authorizedPerimeter.companies
        .map((companyUpdate: CompanyUpdate) => {
            const authorizedMerchantsCompany = newAuthorizedMerchantsResponseByCompany[companyUpdate.company.id] || {merchants: []};
            return ({
                ...companyUpdate,
                hasAllMerchants: hasAllMerchants(companyUpdate, authorizedMerchantsCompany)
            });
        })

    return authorizedPerimeter;
}

/**
 * add All authorized merchant to a company
 * @param editedCompany
 * @param authorizedCompany
 */
const buildAllMerchantToCompany = (editedCompany: CompanyUpdate, authorizedCompany: CompanyWrappedDetailResponse): CompanyUpdate => {
    const newMerchants: Array<UpdateEntity> = [];
    const existingMerchants: Array<UpdateEntity> = [];
    authorizedCompany.merchants.forEach(merchant => editedCompany.merchants.some(item => item.idSpring === merchant.idSpring) ? existingMerchants.push(merchant) : newMerchants.push({
        ...merchant,
        addedByAddAllCompanies: true
    }))
    return {...editedCompany, merchants: [...existingMerchants, ...newMerchants]}
}

const computeAllCompaniesFromAllCompanies = (allCompanies: Array<CompanyWrappedDetailResponse>, editedCompanies: Array<CompanyUpdate>) => {
    const newCompanies: Array<CompanyUpdate> = [];
    const existingCompanies: Array<CompanyUpdate> = [];

    // on new companies/merchants due to the add all button
    // Set an attribute to identify easily added entites
    allCompanies.forEach(company => {
        const companyUpdate = editedCompanies.find(item => company.idSpring === item.company.idSpring);
        return companyUpdate ? existingCompanies.push(buildAllMerchantToCompany(companyUpdate, company)) : newCompanies.push({
            company: {
                id: company.id,
                name: company.name,
                uniqueKey: randomUtils.randomSelector(10),
                idSpring: company.idSpring,
                hasRightOn: company.hasRightOn,
                addedByAddAllCompanies: true
            },
            hasCompanyPerimeter: false,
            merchants: company.merchants,
            addedByAddAllCompanies: true
        });
    })

    return [...existingCompanies, ...newCompanies]
}

const perimeterResToAuthorizedCompanyResponse = (res: PerimeterResponse): AuthorizedCompanyResponse => (
    {
        hasAccountPerimeter: res.hasAccountPerimeter,
        companies: res.companies.map(company => ({
            id: company.id,
            idSpring: company.idSpring,
            name: company.name,
            hasRightOn: company.hasRightOn,
            status: company.status,
            hasAccountPerimeter: res.hasAccountPerimeter,
        }))
    }
)

export const perimeterSelectorUtils = {
    hasRightCompanyOrAllMerchants,
    aggregatePerimeterWithAuthorizedPerimeter,
    computeAllCompaniesFromAllCompanies,
    buildAllMerchantToCompany,
    perimeterResToAuthorizedCompanyResponse
}
