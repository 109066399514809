import { ChangeEvent, useCallback, useEffect, useState } from "react";

interface UseDebouncedInput {
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

/**
 * Manage debounce for inputs
 * @param callback
 * @param initialValue
 */
export const useDebouncedInputEvent = (
  callback: (event: ChangeEvent<HTMLTextAreaElement>) => void,
  initialValue = ""
): UseDebouncedInput => {
  const [value, setValue] = useState<string>(initialValue);
  const [timeoutValue, setTimeoutValue] = useState<number>();

  //this is a “feature” to reset the field value. 
  useEffect(() => {
    if (!initialValue) {
      setValue("");
    }
  }, [initialValue]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      setValue(newValue);
      window.clearTimeout(timeoutValue);
      setTimeoutValue(
        window.setTimeout(() => {
          const trimmedValue = newValue.trim();
          const target = { ...event.target, value: trimmedValue };
          callback({ ...event, target });
        }, 300)
      );
    },
    [callback]
  );

  return {
    value,
    onChange,
  };
};
