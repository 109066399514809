import {ErrorReferential} from "../../interfaces/error/error";

export const LOGIN_ERRORS: ErrorReferential = {
    INVALID_PASSWORD: {id: "INVALID_PASSWORD"},
    PASSWORD_PENDING: {id: "PASSWORD_PENDING"},
    PASSWORD_EMAIL_REQUIRED: {id: "PASSWORD_EMAIL_REQUIRED"},
    BLOCKED_USER: {id: "BLOCKED_USER"},
    EMAIL_WRONG_FORMAT: {id: "EMAIL_WRONG_FORMAT"},
    EMAIL_TOO_LONG: {id: "EMAIL_TOO_LONG"},
    PASSWORD_TOO_LONG: {id: "PASSWORD_TOO_LONG"},
    EMAIL_REQUIRED: {id: "EMAIL_REQUIRED"},
    PASSWORD_REQUIRED: {id: "PASSWORD_REQUIRED"}
}
