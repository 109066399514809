/**
 * This file defines all API endpoints called.
 */

export const API_ROUTE = "/api";

export const TEST_ROUTE = "/server-check";

// LOGIN
export const AUTH_ROUTE = "/auth/login";
export const RESET_PASSWORD_TOKEN_ROUTE = "/reset-password/token";

export const RESET_PASSWORD_ROUTE = "/reset-password";

// USER ROUTE
export const USER_ROUTE = "/users";
export const USER_WITH_PERIMETER_ROUTE = "/users-with-perimeters";
export const USER_SELF_ROUTE = "/users/self";
export const USER_SELF_LANG_ROUTE = `${USER_SELF_ROUTE}/lang`;
export const USER_SELF_DETAILS_ROUTE = `${USER_SELF_ROUTE}/details`;
export const USER_GET_USERS_ROUTE = "/users";
export const USER_DELETE_ROUTE = "/users";
export const USER_EXPORT_ROUTE = `${USER_ROUTE}/export`;

// TRANSACTION ROUTE
export const TRANSACTION_GET_TRANSACTIONS_ROUTE = "/transactions";
export const TRANSACTION_GET_ACQUIRED_TRANSACTIONS_ROUTE = "/acquired-transactions";
export const TRANSACTION_EXPORT_TRANSACTIONS_ROUTE = `${TRANSACTION_GET_TRANSACTIONS_ROUTE}/export`;
export const TRANSACTION_EXPORT_ACQUIRED_TRANSACTIONS_ROUTE = `${TRANSACTION_GET_ACQUIRED_TRANSACTIONS_ROUTE}`;
export const TRANSACTION_GET_TRANSACTIONS_HISTOGRAM_ROUTE = `${TRANSACTION_GET_TRANSACTIONS_ROUTE}/histogram`;

// SETTLEMENT ROUTE
export const SETTLEMENT_GET_SETTLEMENTS_ROUTE = "/settlements";
export const SETTLEMENT_EXPORT_SETTLEMENTS_ROUTE = `${SETTLEMENT_GET_SETTLEMENTS_ROUTE}/export`;
export const SETTLEMENT_EXPORT_SETTLEMENTS_PDF_ROUTE = `${SETTLEMENT_GET_SETTLEMENTS_ROUTE}/export-pdf`;
export const SETTLEMENT_GET_SETTLEMENT_HISTOGRAM_ROUTE = `${SETTLEMENT_GET_SETTLEMENTS_ROUTE}/histogram`;

// REPORTS ROUTE
export const REPORT_GET_REPORTS_ROUTE = "/reports";
export const REPORT_GET_REPORT_ROUTE = "/reports/${id}/generate";

// NOTIFICATIONS ROUTE
export const NOTIFICATION_ROUTE = "/notifications";
export const NOTIFICATION_SELF_ROUTE = `${NOTIFICATION_ROUTE}/self`;
export const NOTIFICATION_SELF_ACTIVE_ROUTE = `${NOTIFICATION_ROUTE}/self/active`;
export const NOTIFICATION_HIDE_ALL_ROUTE = `${NOTIFICATION_ROUTE}/hide-all`;

// NOTIFICATION SETTINGS ROUTE
export const NOTIFICATION_SETTINGS_ROUTE = "/notification-settings";

// VIEW ROUTE
export const VIEW_ROUTE = "/views";

//PORTFOLIO ROUTE
export const PORTFOLIO_COMPANY_ROUTE = "/portfolio/top-company";
export const PORTFOLIO_MERCHANT_ROUTE = "/portfolio/top-merchant";
export const PORTFOLIO_TRENDS_VOLUME_ROUTE = "/portfolio/top-volume";
export const PORTFOLIO_BILLING_ROUTE = "/distributor-billing";
export const PORTFOLIO_BILLING_EXPORT_ROUTE = "/distributor-billing-csv";

// DOCUMENTS ROUTE
export const DOCUMENT_EXPORT_DOCUMENTS_ROUTE = "/ramfec/export";
