import React, { FunctionComponent, HTMLProps } from "react";
import styles from "components/atoms/button/BorderButton/borderButton.module.scss";

export const BorderButton: FunctionComponent<HTMLProps<HTMLAnchorElement>> = ({ children, ...props }) => {
  return (
    <a className={styles["button"]} {...props}>
      {children}
    </a>
  );
};
