import React, { FunctionComponent } from "react";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import { InputLabel } from "components/atoms/input/InputLabel";

export interface AmountInputProps extends BaseTextFieldProps {
    InputProps?: Partial<StandardInputProps>;
    error?: boolean;
    label?: string;
    onChange?: OutlinedInputProps["onChange"];
    onFocus?: OutlinedInputProps["onFocus"];
    onBlur?: OutlinedInputProps["onBlur"];
}

export const AmountInput: FunctionComponent<AmountInputProps> = ({
    InputProps,
    error,
    onChange,
    label,
    value,
    placeholder,
    className,
    name,
    onFocus,
    onBlur,
}) => {

    return (
        <FormControl
            variant="outlined"
        >
            {label && 
            <InputLabel 
            values={[]}
            error={error} 
            className={className} 
            shrink
            > 
            { label } 
            </InputLabel>}
            <OutlinedInput
                {...InputProps}
                endAdornment={
                    <InputAdornment position="start">€</InputAdornment>
                }
                error={error}
                onChange={onChange}
                placeholder={placeholder}
                className={className}
                value={value}
                name={name}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </FormControl>
    )
}
