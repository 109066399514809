import React, {
  FunctionComponent,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Options } from "constants/options/option";
import { useChangeCheckbox } from "hooks/useChangeCheckbox";
import { filterTooltipUtils } from "utils/filterTooltipUtils";
import { useFilterTooltip } from "hooks/tooltip/useFilterTooltip";
import { arrayUtils } from "utils/common/arrayUtils";
import { ResetIconButton } from "components/atoms/button/ResetButton/ResetIconButton";
import Select from "components/atoms/select/Select";
import LabeledCheckbox from "components/atoms/checkbox/LabeledCheckbox";
import FilterTooltip from "components/molecules//tooltip/FilterTooltip";
import { InputLabel } from "components/atoms/input/InputLabel";
import { paletteTheme } from "styles/themes/palette";
import FormControl from "@mui/material/FormControl";
import {
  menuPropsStyles,
  selectStyles,
} from "styles/constants/checkbox/MultichoiceCheckboxStyles";
import styles from "components/molecules/checkbox/MultichoiceCheckbox/multichoiceCheckbox.module.scss";

export interface MultichoiceCheckBoxProps {
  values: Array<Options>;
  options: Array<Options>;
  label?: string;
  selectWidth?: number;
  multiple?: boolean;
  onChange: (values: Array<Options>) => void;
  translatedLabel?: boolean;
  disabled?: boolean;
  displayCheckboxes?: boolean;
  hideReset?: boolean;
}

export const MultichoiceCheckbox: FunctionComponent<MultichoiceCheckBoxProps> = ({
  values = [],
  options,
  label,
  selectWidth = 108,
  onChange,
  translatedLabel = false,
  disabled = false,
  multiple = true,
  displayCheckboxes = true,
  hideReset = false,
}) => {

  const intl = useIntl();

  const [labelHover, setLabelHover] = useState<boolean>(false)

  const onReset = () => {
    onChange([]);
  }
  const {
    displayTooltip,
    setDisplayTooltip,
    closed,             // Needed for the case when the user opens the filter with enter key
    handleToggleFilter
  } = useFilterTooltip();

  const { handleChange } = useChangeCheckbox<Options>(values, onChange, multiple, handleToggleFilter)

  const handleMouseEvent = (enter: boolean) => {
    setDisplayTooltip(enter);
    setLabelHover(enter);
  }

  const handleToggleSelect = (closed: boolean) => {
    handleToggleFilter(closed);
    setLabelHover(false);
  }

  const handleOpenClose = () => {
    return !closed
  }

  const map = Object.values(values).reduce((acc, next) => ({ ...acc, [next.id]: next }), {});
  const divRef = useRef<HTMLDivElement>();
  const isOverflow = filterTooltipUtils.isOverflow(divRef.current?.firstElementChild);

  return (
    <FilterTooltip overflowText={label} placement="top" openTooltip={isOverflow && displayTooltip && closed}
      isPopoverFilter>
      <FormControl className={styles["select-wrapper"]}>
        <div ref={divRef}>
          <InputLabel values={values}>
            {label}
          </InputLabel>
        </div>
        {!hideReset && values.length > 0 &&
          <ResetIconButton
            onClick={onReset}
            disabled={disabled}
            className="select-inline"
          />
        }
        <Select
          open={handleOpenClose()}
          label={label}
          fullWidth
          multiple={multiple}
          value={values}
          onMouseEnter={() => handleMouseEvent(true)}
          onMouseLeave={() => handleMouseEvent(false)}
          onOpen={() => handleToggleSelect(false)}
          onClose={() => handleToggleSelect(true)}
          renderValue={(_values: Options[]) => _values.map(value => translatedLabel ? intl.formatMessage({ id: value.name }) : value.name).join(", ")}
          MenuProps={menuPropsStyles}
          sx={selectStyles(selectWidth, !arrayUtils.isEmpty(values))}>
          {options.length > 0 && options.map(option =>
            <LabeledCheckbox key={option.id}
              label={option.name}
              labelColor={!!map[option.id] ? paletteTheme.colors.DEFAULT_TEXT_COLOR : paletteTheme.colors.GRAY}
              labelFontSize="10px"
              checked={!!map[option.id]}
              onChange={(e) => handleChange(option, e)}
              disabled={disabled}
              translatedLabel={translatedLabel}
              customClassName="multi-dropdown"
              displayCheckboxes={displayCheckboxes}>
            </LabeledCheckbox>
          )}
        </Select>
      </FormControl>
    </FilterTooltip>
  )
}
