import React, { FunctionComponent, useState } from "react";
import styles from "components/molecules/MoreActions/moreActions.module.scss";
import { MoreButton } from "components/atoms/button/more-actions/MoreButton";
import { ListActions } from "components/atoms/button/more-actions/ListActions";
import { MoreActionsProps } from "interfaces/table/table";

export const MoreActions: FunctionComponent<MoreActionsProps> = ({ actions, children }) => {
  const [isSubMenu, setIsSubMenu] = useState<boolean>(false);

  const openSubmenu = (): void => {
    setIsSubMenu(true);
  };

  const closeSubmenu = (): void => {
    setIsSubMenu(false);
  };

  return (
    <>
      <div className={styles["wrapper"]}>
        <MoreButton clickHandler={openSubmenu} />
        {isSubMenu && <ListActions actions={actions} closeHandler={closeSubmenu} />}
      </div>
      {children}
    </>
  );
};
