import React, {FunctionComponent} from "react";
import Button, {ButtonProps} from "./Button";


const TextButton: FunctionComponent<ButtonProps> = ({...rest}) => {

    return (<Button
        {...rest}
        variant="text">
    </Button>);
}


export default TextButton;
