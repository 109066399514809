import {NotificationResponse} from "../../interfaces/notification/notification";
import {httpService} from "../HttpService";
import {
    NOTIFICATION_HIDE_ALL_ROUTE,
    NOTIFICATION_ROUTE,
    NOTIFICATION_SELF_ACTIVE_ROUTE,
    NOTIFICATION_SELF_ROUTE,
    NOTIFICATION_SETTINGS_ROUTE,
} from "../../constants/routes/ApiRoutes";
import {SearchResponse} from "../../interfaces/api/ApiInterfaces";
import {queryUtils} from "../../utils/api/queryUtils";
import {
    NotificationChannel,
    NotificationSettingsType,
    NotificationToggle
} from "types/notifications/NotificationSettingsTypes";

function findActiveNotifications(options?: RequestInit): Promise<Array<NotificationResponse>> {
    return httpService.get(NOTIFICATION_SELF_ACTIVE_ROUTE, options);
}

function findNotifications(page: number, options?: RequestInit): Promise<SearchResponse<NotificationResponse>> {
    const url = `${NOTIFICATION_SELF_ROUTE}${queryUtils.queryParamToPageable({page, pageSize: 10})}`
    return httpService.get(url, options);
}

function hideNotification(idNotification: string, options?: RequestInit): Promise<VoidFunction> {
    return httpService.put(`${NOTIFICATION_ROUTE}/${idNotification}`, null, options);
}

function hideAllNotifications(options?: RequestInit): Promise<VoidFunction> {
    return httpService.put(NOTIFICATION_HIDE_ALL_ROUTE, null, options);
}

export interface NotificationSettingsResponse {
    activeToggles: Array<{ channel: NotificationChannel, type: NotificationSettingsType }>,
}

function getNotificationSettings(options?: RequestInit): Promise<NotificationSettingsResponse> {
    return httpService.get(NOTIFICATION_SETTINGS_ROUTE, options);
}

function updateNotificationSettings(newToggles: ReadonlyArray<NotificationToggle>, options?: RequestInit): Promise<VoidFunction> {
    return httpService.put(NOTIFICATION_SETTINGS_ROUTE, {activeToggles: newToggles}, options);
}


export const notificationService = {
    findActiveNotifications,
    findNotifications,
    hideNotification,
    hideAllNotifications,
    getNotificationSettings,
    updateNotificationSettings,
}
