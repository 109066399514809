import {styled} from "@mui/material/styles";
import {MenuItem, MenuItemProps} from "@mui/material";
import {paletteTheme} from "../themes/palette";

export default styled(MenuItem)<MenuItemProps>(() => ({
    minHeight: "auto",
    paddingLeft: "9px",
    paddingRight: "9px",
    paddingTop: "3px",
    paddingBottom: "3px",
    height: "auto",
    fontSize: 12,
    borderRadius: 0,
    color: paletteTheme.colors.DEFAULT_TEXT_COLOR,
    backgroundColor: paletteTheme.colors.WHITE,
    
    "& .MuiTypography-root, & .MuiListItemIcon-root": {
        color: paletteTheme.colors.DEFAULT_TEXT_COLOR,
    },
    "&.Mui-selected": {
        backgroundColor: paletteTheme.colors.GRAY_LIGHT,
        color: paletteTheme.colors.DEFAULT_TEXT_COLOR,

    },
    "&:hover, &.Mui-selected:hover": {
        backgroundColor: paletteTheme.colors.GRAY,
    }
}));
