import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, AutocompleteRenderInputParams, Box, FormControl } from "@mui/material";
import { ResetIconButton } from "components/atoms/button/ResetButton/ResetIconButton";
import TextFieldStyled from "styles/components/textfield/TextFieldStyled";
import PaperStyled from "styles/components/paper/PaperStyled";
import AutocompletePopper from "components/molecules/autocomplete/AutocompletePopper";
import AutocompleteOption from "components/molecules/autocomplete/AutocompleteOption";
import { useOnChangeAutocomplete } from "hooks/input/useOnChangeAutocomplete";
import { Options } from "constants/options/option";
import FilterTooltip from "components/molecules/tooltip/FilterTooltip";
import { filterTooltipUtils } from "utils/filterTooltipUtils";
import { arrayUtils } from "utils/common/arrayUtils";
import {
    autocompleteStyles,
    paperStyles,
    textFieldStyles
} from "styles/constants/autocomplete/AutocompleteCheckboxStyles";
import { authUtils } from "utils/api/authUtils";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { useDebouncedAutocomplete } from "hooks/input/useDebouncedAutocomplete";
import { MessageKeys } from "constants/messages/i8ln/EnglishMessages";

export interface AutocompleteCheckboxProps {
    values: Array<Options>
    options: Array<Options>
    label?: {
        message: MessageKeys;
        values?: Record<string, string | number>;
    };
    selectWidth?: number
    onChange: (values: Array<Options>) => void
    translatedLabel?: boolean
    showSelection?: boolean
    query?: string
    onChangeQuery?: (query: string, page?: number) => void,
    onResetQuery?: () => void,
    customOnBlur?: () => void,
    uniqueElement?: boolean,
    disabled?: boolean,
    disableChoices?: boolean,
}

const AutocompleteCheckbox: FunctionComponent<AutocompleteCheckboxProps> = ({
    values,
    options,
    label,
    selectWidth = 108,
    onChange,
    translatedLabel = false,
    onChangeQuery,
    uniqueElement,
    disabled = false,
    disableChoices = false,
    customOnBlur
}) => {
    const { formatMessage } = useIntlFormatter();

    const [searchedValue, setSearchedValue] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [isMounted, setIsMounted] = useState<boolean>(false)

    const dropDownRef = useRef<HTMLElement>(null);
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [displayAutocompleteField, setDisplayAutocompleteField] = useState<boolean>(false);
    const allIsSelected = values.find(item => item.id === "");

    const divRef = useRef<HTMLDivElement>();
    const isOverflow = filterTooltipUtils.isOverflow(divRef.current?.firstElementChild.firstElementChild);

    const isDisabled = disabled || disableChoices;

    const { handleUniqueChange } = useOnChangeAutocomplete(onChange, values);

    const onChangeQueryDebounced = useDebouncedAutocomplete(onChangeQuery);

    useEffect(() => {
        if (isMounted) {
            onChangeQueryDebounced(searchedValue);
            setCurrentPage(0);
        } else {
            setIsMounted(true)
        }

    }, [searchedValue])

    useEffect(() => {
        if (currentPage !== 0) {
            onChangeQuery(searchedValue.trim(), currentPage);
        }
    }, [currentPage])

    const handleChange = (_e: React.SyntheticEvent, value: Options[]) => {
        authUtils.refreshTokenExpirationDate();
        // Disable the removeOption on autocomplete input
        // if (_e.type !== "keydown") {
        if (uniqueElement) {
            handleUniqueChange(_e, value);
        } else {
            onChange(value);
        }
        // }
    }

    const onReset = () => {
        onChange([]);
        setSearchedValue("");
        setDisplayAutocompleteField(false);
    }

    // To prevent re-render of component after onChange
    const customPopper = useCallback((props) => <AutocompletePopper {...props} minWidth={selectWidth} />, []);
    const customPaper = useCallback((props) => <PaperStyled {...props} />, []);

    const onChangeAutocompleteField = (value: string) => {
        setSearchedValue(value.trim());
        setIsLoading(true);
    }

    useEffect(() => {
        setIsLoading(false);
        if (dropDownRef.current) {
            dropDownRef.current.scrollTop = scrollPosition;
        }
    }, [options])

    const onBlur = () => {
        setDisplayAutocompleteField(false);
        setScrollPosition(0);
        if (values.length) {
            customOnBlur && customOnBlur();
        }
    }

    const lazyLoadingOptions = (event: React.UIEvent<React.ReactNode>) => {
        dropDownRef.current = event.target as HTMLElement;
        if (Math.ceil(dropDownRef.current.scrollTop + dropDownRef.current.offsetHeight) >= dropDownRef.current.scrollHeight) {
            setCurrentPage(prev => prev + 1);
            setScrollPosition(Math.ceil(dropDownRef.current.scrollTop));
        }
    };



    return (
        <FormControl>
            {values?.length > 0 &&
                <ResetIconButton
                    onClick={onReset}
                    disabled={disabled || disableChoices}
                    className="select-inline"
                />
            }
            <Autocomplete
                multiple
                disableClearable
                disableCloseOnSelect
                disabled={disabled}
                clearOnBlur={false}
                onFocus={() => setDisplayAutocompleteField(true)}
                onBlur={onBlur}
                filterOptions={(x: Options[]) => x}
                value={values}
                isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
                loading={isLoading}
                loadingText={formatMessage("loading")}
                noOptionsText={formatMessage("no_results")}
                renderTags={() => !displayAutocompleteField &&
                    <Box className="ellipsis" sx={{ width: "92%", paddingTop: "3px" }}>
                        {values.length === 1 && <span>{values && values[0].name}</span>}
                        {values.length > 1 ? `+ ${values.length}` : ""}
                    </Box>
                }
                componentsProps={{ paper: paperStyles }}
                inputValue={searchedValue}
                renderOption={(props, option: Options) => (<AutocompleteOption {...props}
                    key={option.id + "-" + option.name}
                    disabled={disableChoices}
                    selectedOptions={values}
                    option={option}
                    allSelected={!!allIsSelected}
                    translatedLabel={translatedLabel} />)}
                options={options}
                onChange={(e, value: Options[]) => handleChange(e, value)}
                renderInput={(params: AutocompleteRenderInputParams) => {

                    return <FilterTooltip overflowText={formatMessage(label.message)} placement="top" openTooltip={isOverflow}>
                        <div ref={divRef}>
                            <TextFieldStyled {...params} label={formatMessage(label.message)}
                                disabled={isDisabled}
                                inputProps={{
                                    ...params.inputProps,
                                    readOnly: isDisabled,
                                }}
                                sx={textFieldStyles(displayAutocompleteField, !arrayUtils.isEmpty(values), isDisabled)}
                                onChange={(e) => onChangeAutocompleteField(e.target.value)} />
                        </div>
                    </FilterTooltip>;
                }}
                sx={autocompleteStyles(selectWidth)}
                PaperComponent={customPaper}
                getOptionLabel={(option: Options) => option.name}
                PopperComponent={customPopper}
                ListboxProps={{
                    onScroll: lazyLoadingOptions,
                }}
            />
        </FormControl>
    )
}

export default AutocompleteCheckbox;
