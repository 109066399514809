import {ErrorReferential} from "../../interfaces/error/error";

export const USER_ERRORS: ErrorReferential = {
    FIRSTNAME_REQUIRED: {id: "FIRSTNAME_REQUIRED"},
    LASTNAME_REQUIRED: {id: "LASTNAME_REQUIRED"},
    EMAIL_REQUIRED: {id: "EMAIL_REQUIRED"},
    FIRSTNAME_WRONG_FORMAT: {id: "FIRSTNAME_WRONG_FORMAT"},
    LASTNAME_WRONG_FORMAT: {id: "LASTNAME_WRONG_FORMAT"},
    EMAIL_ALR_USED: {id: "EMAIL_ALR_USED"}
}
