import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Perimeter } from "interfaces/perimeter/perimeter";
import { User } from "interfaces/user/user";
import { rightUtils } from "utils/business/rightUtils";
import { CardRow } from "components/atoms/card/CardRow";
import { UserAccountPerimeterCompanyList } from "components/molecules/useraccount/details/UserAccountPerimeterCompanyList";
import { BoxProps, Typography } from "@mui/material";

interface AccountPerimeterDetailsProps extends BoxProps {
  user: User;
  perimeter: Perimeter;
}

export const UserAccountPerimeterDetails: FunctionComponent<AccountPerimeterDetailsProps> = ({
  user,
  perimeter,
}) =>
  rightUtils.isAdmin(user) ?
    <CardRow marginBottom={5} id="peri_account" label="peri_account">
      <Typography>
        <FormattedMessage id="account_all_perimeter" />
      </Typography>
    </CardRow>
    :
    <>
      <CardRow marginBottom={5} id="peri_account" label="peri_account">
        {perimeter?.account?.id ?
          <Typography>
            {perimeter.account.name}
          </Typography>
          :
          <Typography>
            <FormattedMessage id="account_no_perimeter" />
          </Typography>
        }
      </CardRow>
      <UserAccountPerimeterCompanyList perimeter={perimeter} />
    </>;
