import { useState } from "react";
import { TransactionAdditionalFiltersValues } from "../../interfaces/transaction/transaction";
import { viewService } from "../../services/view/ViewService";
import { ViewGetResponse } from "../../interfaces/view/view";
import { selectUtils } from "../../utils/business/selectUtils";
import { filterUtils } from "../../utils/business/filterUtils";
import {
  CHECKOUT_TYPE,
  EVENT_STATUS,
  SETTLEMENT_STATUS,
  PAYMENT_METHOD,
  PAYMENT_SOLUTION,
  PAYMENT_TYPE,
  SCHEME,
  TRANS_EVENT,
  COLLECTION_TYPE,
  SERVICE_TYPE,
} from "../../constants/transaction/transactionFilter";
import { errorUtils } from "../../utils/api/errorUtils";
import { Options } from "../../constants/options/option";
import { FilterValues } from "../../interfaces/filter/filter";
import { dateUtils } from "../../utils/common/dateUtils";
import { TRANSACTION_POSSIBLE_COLUMNS } from "../../constants/transaction/transactionColumns";
import { getTransactionColumns } from "utils/table/tableUtils";
import { ManagableColumn } from "interfaces/table/table";

interface ViewsInstance {
  viewSelected: Options,
  viewToDelete: string,
  viewsOptions: Array<Options>,
  openDeleteView: boolean,
  openSaveView,
  onChangeView: (id: string) => void,
  handleDelete: () => void,
  resetViewToDelete: () => void,
  onChangeViewsOptions: (options: Array<Options>) => void,
  openDelete: (id: string) => void,
  onChangeViewSelected: (options: Options) => void,
  closeDialogDelete: () => void,
  closeDialogSave: () => void,
  openDialogSave: () => void
}

/**
 * Hook to manage Views
 *
 * We send a map of filters + columns. All filters must be List of string or strings.
 * Warning: Serialisation/Deserialisation is not automatic
 * This feature should be rebuild. It's way to complicated. Bad move
 * @param onChangeFilters
 * @param onChangeAdditionalFilters
 * @param onChangeColumns
 */
export function useTransactionViews(
  onChangeFilters: (filters: FilterValues) => void,
  onChangeAdditionalFilters: (additionalFiltersValues: TransactionAdditionalFiltersValues) => void,
  onChangeColumns: (columns: Array<ManagableColumn>) => void
): ViewsInstance {
  const [viewSelected, setViewSelected] = useState<Options>();
  const [viewToDelete, setViewToDelete] = useState<string>();
  const [viewsOptions, setViewsOptions] = useState<Array<Options>>([]);
  const [openDeleteView, setOpenDeleteView] = useState<boolean>(false);
  const [openSaveView, setOpenSaveView] = useState<boolean>(false);

  const openDelete = (id: string) => {
    setOpenDeleteView(true);
    setViewToDelete(id);
  };

  const onChangeView = (id: string) => {
    viewService
      .get(id)
      .then((res: ViewGetResponse) => {
        const selectedOptionsCompany = res.perimeters.companies;
        const selectedOptionsMerchant = res.perimeters.merchants;
        const optionsCompany = selectedOptionsCompany?.find((value) => value.id === "");
        const optionsMerchant = selectedOptionsMerchant?.find((value) => value.id === "");
        const operationStartDate: string = res.filters.operationStartDate?.toString();
        const operationEndDate: string = res.filters.operationEndDate?.toString();

        /**
         * View perimeter filters.
         * This has a stable format
         */
        onChangeFilters({
          accounts: res.perimeters.account
            ? [
                {
                  id: res.perimeters.account.id,
                  name: res.perimeters.account.name,
                },
              ]
            : [],
          companies: {
            all: !!optionsCompany,
            selectedOptions: optionsCompany ? [selectUtils.buildCheckboxAll()] : selectedOptionsCompany,
          },
          merchants: {
            all: !!optionsMerchant,
            selectedOptions: optionsMerchant ? [selectUtils.buildCheckboxAll()] : selectedOptionsMerchant,
          },
          operationDate: dateUtils.formatToRange(operationStartDate, operationEndDate),
        });

        const financingStartDate: string = res.filters.financingStartDate?.toString();
        const financingEndDate: string = res.filters.financingEndDate?.toString();

        /**
         * Deserialization of view additionnals filters.
         * This is an Map object from Java. Can have number / number[] / string / string[]
         *
         * When you add a filter, you must add it here if you want views to work
         */
        onChangeAdditionalFilters({
          transactionalEventId: filterUtils.unknownToString(res.filters.transactionalEventId),
          orderNumber: filterUtils.unknownToString(res.filters.orderNumber),
          transactionalEvent: filterUtils.unknownToArrayOfOptions(res.filters.transactionalEvent, TRANS_EVENT),
          eventStatus: filterUtils.unknownToArrayOfOptions(res.filters.eventStatus, EVENT_STATUS),
          paymentSolution: filterUtils.unknownToArrayOfOptions(res.filters.paymentSolution, PAYMENT_SOLUTION),
          paymentMethod: filterUtils.unknownToArrayOfOptions(res.filters.paymentMethod, PAYMENT_METHOD),
          paymentType: filterUtils.unknownToArrayOfOptions(res.filters.paymentType, PAYMENT_TYPE),
          checkoutType: filterUtils.unknownToArrayOfOptions(res.filters.checkoutType, CHECKOUT_TYPE),
          grossAmountMin: filterUtils.unknownToString(res.filters.grossAmountMin),
          grossAmountMax: filterUtils.unknownToString(res.filters.grossAmountMax),
          bin: filterUtils.unknownToString(res.filters.bin),
          financingStatus: filterUtils.unknownToArrayOfOptions(res.filters.financingStatus, SETTLEMENT_STATUS),
          financingDate: dateUtils.formatToRange(financingStartDate, financingEndDate),
          batchId: filterUtils.unknownToString(res.filters.batchId),
          scheme: filterUtils.unknownToArrayOfOptions(res.filters.scheme, SCHEME),
          collectionType: filterUtils.unknownToArrayOfOptions(res.filters.collectionType, COLLECTION_TYPE),
          serviceType: filterUtils.unknownToArrayOfOptions(res.filters.scheme, SERVICE_TYPE),
          posId: filterUtils.unknownToString(res.filters.posId),
        });

        /**
         * Deserialization of columns
         * Based on referential name of columns
         */
        const managableColumns: ManagableColumn[] = filterUtils.unknownToArrayOfManagableColumn(
          res.filters.columns,
          TRANSACTION_POSSIBLE_COLUMNS
        );
        onChangeColumns(managableColumns.length < 5 ? getTransactionColumns() : managableColumns);
      })
      .catch(errorUtils.handleBackErrors);
  };

  /**
   * Delete a view and update options
   */
  const handleDelete = () => {
    viewService
      .delete(viewToDelete)
      .then(() => {
        const options = viewsOptions.find((item) => item.id === viewToDelete);
        const newViewsOptions = [...viewsOptions];
        newViewsOptions.splice(newViewsOptions.indexOf(options), 1);
        setViewsOptions(newViewsOptions);
        if (options === viewSelected) {
          setViewSelected(null);
        }
        setViewToDelete(null);
      })
      .catch(errorUtils.handleBackErrors);
  };

  const openDialogSave = () => {
    setOpenSaveView(true);
    setOpenDeleteView(false);
  };

  return {
    viewSelected,
    viewToDelete,
    viewsOptions,
    openDeleteView,
    openSaveView,
    onChangeView,
    handleDelete,
    resetViewToDelete: () => setViewToDelete(null),
    onChangeViewsOptions: setViewsOptions,
    openDelete,
    onChangeViewSelected: (option: Options) => setViewSelected(option),
    closeDialogDelete: () => setOpenDeleteView(false),
    closeDialogSave: () => setOpenSaveView(false),
    openDialogSave,
  };
}
