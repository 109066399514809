import { Range } from "react-date-range";
import React, { useEffect, useMemo, useState } from "react";
import {
  TransactionAdditionalFiltersState,
  TransactionAdditionalFiltersValues,
} from "interfaces/transaction/transaction";
import { Options } from "constants/options/option";
import { transactionUtils } from "utils/business/transactionUtils";
import { useLocation } from "react-router-dom";
import { SettlementStateFilterValues } from "interfaces/filter/filter";
import { TransactionType } from "interfaces/filter/filterColumn";
import { TRANSACTION_ADDITIONAL_FILTERS_STATES } from "constants/transaction/transactionFilter";

interface AdditionalFilterInstance {
  transactionAdditionalFilters: TransactionAdditionalFiltersValues;
  onChangeTransactionAdditionalFilters: (newFilters: TransactionAdditionalFiltersValues) => void;
  onChangeAlphanumericFilter: (field: string) => (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeMultichoiceFilter: (field: string) => (values: Array<Options>) => void;
  onChangeAmountRangeFilter: (changedField: string) => (value: string) => void;
  onChangeDateFilter: (field: string) => (value: Range) => void;
  appliedFiltersCount: number;
  onResetFilters: () => void;
  moreFiltersButtonState: TransactionAdditionalFiltersState;
  onMoreLessFiltersClick: () => void;
}

export const useTransactionAdditionalFilter = (
  filterInitialValues: TransactionAdditionalFiltersValues,
  typeTransaction: TransactionType
): AdditionalFilterInstance => {
  /*
   * Case when accessing transactions list from a settlement line
   * Perimeter filters and batch id are then passed as filters
   * Saved filters are ignored in this case
   */
  const location = useLocation();
  const settlementStateFilters = location.state as SettlementStateFilterValues;
  const savedTransactionAdditionalFilters: TransactionAdditionalFiltersValues = !!settlementStateFilters
    ? { ...filterInitialValues, ...settlementStateFilters }
    : undefined;

  const [transactionAdditionalFilters, setTransactionAdditionalFilters] = useState<TransactionAdditionalFiltersValues>(
    transactionUtils.initAdditionalFilters(typeTransaction, savedTransactionAdditionalFilters)
  );

  const [moreFiltersButtonState, setMoreFiltersButtonState] = useState<TransactionAdditionalFiltersState>(
    TRANSACTION_ADDITIONAL_FILTERS_STATES.MORE_FILTERS
  );

  const onChangeAlphanumericFilter = (field: string) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTransactionAdditionalFilters({ ...transactionAdditionalFilters, [field]: event.target.value });
  };

  const onChangeMultichoiceFilter = (field: string) => (values: Array<Options>) => {
    setTransactionAdditionalFilters({ ...transactionAdditionalFilters, [field]: values });
  };

  const onChangeAmountRangeFilter = (changedField: string) => (value: string) => {
    setTransactionAdditionalFilters({ ...transactionAdditionalFilters, [changedField]: value });
  };

  const onChangeDateFilter = (field: string) => (value: Range) => {
    setTransactionAdditionalFilters({ ...transactionAdditionalFilters, [field]: value });
  };

  const onResetFilters = () => {
    setTransactionAdditionalFilters(filterInitialValues);
  };

  const calculateAppliedFilter = (): number => {
    const keys = Object.keys(filterInitialValues);
    return keys.filter(
      (key) => JSON.stringify(filterInitialValues[key]) !== JSON.stringify(transactionAdditionalFilters[key])
    ).length;
  };

  const appliedFiltersCount = useMemo(() => {
    return calculateAppliedFilter();
  }, [transactionAdditionalFilters]);

  useEffect(() => {
    if (appliedFiltersCount > 0) {
      setMoreFiltersButtonState(TRANSACTION_ADDITIONAL_FILTERS_STATES.LESS_FILTERS);
    }
  }, []);

  const onMoreLessFiltersClick = () => {
    setMoreFiltersButtonState(
      moreFiltersButtonState.showFilters
        ? TRANSACTION_ADDITIONAL_FILTERS_STATES.MORE_FILTERS
        : TRANSACTION_ADDITIONAL_FILTERS_STATES.LESS_FILTERS
    );
  };

  return {
    transactionAdditionalFilters,
    onChangeTransactionAdditionalFilters: setTransactionAdditionalFilters,
    onChangeAlphanumericFilter,
    onChangeMultichoiceFilter,
    onChangeAmountRangeFilter,
    onChangeDateFilter,
    appliedFiltersCount,
    onResetFilters,
    moreFiltersButtonState,
    onMoreLessFiltersClick,
  };
};
