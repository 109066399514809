import { ChangeEvent, useState } from "react";
import { PerimeterUpdate } from "interfaces/perimeter/perimeter";
import { UserDetailsResponse } from "interfaces/UserInterface";
import {
  UpdateUser,
  UpdateUserInfo,
  UserDetails,
  UserWithPerimeter,
} from "interfaces/user/user";
import { useValidation } from "hooks/validation/useValidation";
import { useUnsaveChange } from "hooks/validation/useUnsaveChange";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import { validationErrorsUtils } from "utils/business/validationErrorsUtils";
import { rightUtils } from "utils/business/rightUtils";
import { toastUtils } from "utils/toast/toastUtils";
import { createUserUtils } from "utils/business/createUserUtils";
import { arrayUtils } from "utils/common/arrayUtils";
import { scrollUtils } from "utils/common/scrollUtils";
import { PromisableApiError } from "utils/api/errorUtils";
import { userService } from "services/user/UserService";
import { perimeterService } from "services/user/PerimeterService";
import { userAccountUtils } from "components/molecules/useraccount/utils/UserAccountUtils";
import { ValidateErrorMap } from "components/molecules/useraccount/edit/UserAccountEdit";
import { SelectChangeEvent } from "@mui/material";

interface AccountEditInstance {
  errors: ValidateErrorMap;
  perimeterErrors: ValidateErrorMap;
  setPerimeterErrors: (errors: ValidateErrorMap,) => void;
  editedPerimeter: PerimeterUpdate;
  onUpdatePerimeter: (perimeter: PerimeterUpdate) => void;
  validated: () => boolean;
  onChangePersInfo: (key: string) => (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeProfile: (event: SelectChangeEvent) => void;
  onChangeDistributor: (value: boolean) => void;
  user: UserDetails;
  setUser: (user: UserDetails) => void;
  onSubmitUpdate: (userInformations: UpdateUserInfo, ac: AbortController) => void;
  onSubmitUpdateSelf: (userInformations: UpdateUserInfo, ac: AbortController) => void;
  onSubmitCreate: (userInformations: UpdateUserInfo, ac: AbortController) => void;
  disableBlockingPopup: () => void;
  openDiscardDialog: boolean;
  onCloseDiscardDialog: () => void;
  onDiscard: () => void;
  payWishMerchants: { id: string, name: string }[];
  onChangePaywishUserRole: (value: string) => void;
  onChangePaywishMerchant: (value: string) => void;
  onTogglePaywishUser: (checked: boolean) => void;
}

interface AccountEditOptions {
  onAfterSubmit: (userUpdated: UserDetailsResponse) => void;
  onFinallyAfterSubmit: () => void;
}

/**
 * Hook to edit information of an account
 * Manage data validation and dialog to discard changes and submitting changes
 */
export const useAccountEdit = (
  perimeter: PerimeterUpdate,
  userDetails: UserDetails,
  idUser: string,
  options?: AccountEditOptions,
): AccountEditInstance => {

  const [user, setUser] = useState<UserDetails>(userDetails);
  const [editedPerimeter, setEditedPerimeter] = useState<PerimeterUpdate>(perimeterUtils.buildPerimeterUpdate(perimeter));
  const [perimeterErrors, setPerimeterErrors] = useState<ValidateErrorMap>({});
  const [payWishMerchants, setPaywishMerchants] = useState<{ id: string, name: string }[]>([]);

  const canUpdatePerimeter = user && (rightUtils.isOperator(user) || createUserUtils.isNewUser(user)) && idUser != null;

  const validations = () => {
    let allValidations = validationErrorsUtils.validateUserPersInfoForm(user.firstName, user.lastName, user.email);
    if (rightUtils.isOperator(user)) {
      allValidations = { ...allValidations, ...validationErrorsUtils.validatePerimeterForm(editedPerimeter) }
    }
    return allValidations;
  }

  const {
    errors,
    setErrors,
    validated,
  } = useValidation(validations)

  const {
    onChangeField,
    disableBlockingPopup,
    openDiscardDialog,
    onCloseDiscardDialog,
    navigateOnAction,
  } = useUnsaveChange({ profile: false })


  // Close the dialog to discard changes
  const disableDiscardDialog = () => {
    disableBlockingPopup()
    if (openDiscardDialog) {
      onCloseDiscardDialog();
      navigateOnAction();
    }
  }

  const onDiscard = () => {
    disableDiscardDialog()
    toastUtils.infoToastI8ln("toast_discard_change");
  }

  const onChangePersInfo = (key: string) => (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeField(key, event.target.value !== userDetails[key])
    setUser({
      ...user,
      [key]: event.target.value,
    })
  }

  const onChangeProfile = (event: SelectChangeEvent) => {
    onChangeField("profile", event.target.value !== userDetails.profile)
    setUser({
      ...user,
      profile: event.target.value,
    })
  }

  const onChangeDistributor = (checked: boolean) => {
    onChangeField("distributor", checked !== userDetails.distributor)
    setUser({
      ...user,
      distributor: checked,
    })
  }

  // if PaywishUserRole has been toggled off, we need to remove 'payWishUserRole' and 'djmId' keys from the user object
  const onTogglePaywishUser = (checked: boolean) => {
    if (!checked) {
      if (user.paywishRole) {
        delete user.paywishRole;
      }
      if (user.djmId) {
        delete user.djmId;
      }
      setUser({ ...user })
    }
  }

  const onChangePaywishUserRole = (value: string) => {
    onChangeField("payWishUserRole", value !== userDetails.paywishRole);
    if (value === "ADMIN" || value === "USER") {
      setUser((prevState) => ({
        ...prevState,
        paywishRole: value,
      }))
    }
  }

  const onChangePaywishMerchant = (value: string) => {
    onChangeField("payWishMerchant", value !== userDetails.djmId);
    setUser((prevState) => ({
      ...prevState,
      djmId: value,
    }))
  }

  /**
   * This function permits to determine if the perimeter has been updated for the discard popup
   */
  const hasChangedPerimeter = (_editedPerimeter: PerimeterUpdate): boolean => {

    if (_editedPerimeter.account.id !== perimeter.account.id) {
      return true;
    }

    const editedPerimeterCompaniesId = _editedPerimeter.companies.map(company => company.company.id);
    const initialPerimeterCompaniesId = perimeter.companies.map(company => company.company.id);
    if (!arrayUtils.hasAllElements(editedPerimeterCompaniesId, initialPerimeterCompaniesId)) {
      return true;
    }

    const editedPerimeterMerchantsId = _editedPerimeter.companies.flatMap(company => company.merchants.map(merchant => merchant.id));
    const initialPerimeterMerchantsId = perimeter.companies.flatMap(company => company.merchants.map(merchant => merchant.id));
    return !arrayUtils.hasAllElements(editedPerimeterMerchantsId, initialPerimeterMerchantsId);
  }

  const onUpdatePerimeter = (_editedPerimeter: PerimeterUpdate) => {
    onChangeField("perimeter", hasChangedPerimeter(_editedPerimeter));
    setEditedPerimeter(_editedPerimeter);
    setPaywishMerchants(createUserUtils.filterPaywishMerchants(_editedPerimeter));
  }

  /*
   * This function permits to update the perimeter of an user
   * It call an endpoint to update the user and create/update the perimeter
   */
  const submitUpdate = (userInformationEndpoint: Promise<UserDetailsResponse>, userPerimeterEndpoint?: Promise<VoidFunction>) => {

    Promise.all([userInformationEndpoint, userPerimeterEndpoint]).then((res) => {
      toastUtils.successToastI8ln("account_updated");
      const userUpdated: UserDetailsResponse = res[0];
      if (options) {
        options.onFinallyAfterSubmit()
        options.onAfterSubmit(userUpdated)
      }
      disableDiscardDialog()
      scrollUtils.scrollToTop();
    }).catch((e: PromisableApiError) => {
      options && options.onFinallyAfterSubmit()
      void userAccountUtils.manageOnSubmitErrors(e, setErrors);
    })
  }

  /**
   * Logic to determine if the perimeter must be created or updated (choose the good endpoint)
   * And call it !
   */
  const onSubmitUpdate = (newUserInformations: UpdateUserInfo, ac: AbortController) => {
    let userPerimeterEndpoint: Promise<VoidFunction>;

    const newUserDetailsInformations: UpdateUser = {
      persInfo: newUserInformations,
      permissions: user.permissions,
    }

    if (canUpdatePerimeter) {
      // If you can modify perimeter
      const perimeterItemUpdates = perimeterUtils.buildPerimeterToRequestPerimeter(editedPerimeter);
      if (perimeterUtils.hasNoPerimeter(perimeter) && !perimeterUtils.hasNoPerimeter(editedPerimeter)) {
        userPerimeterEndpoint = perimeterService.createPerimeter(perimeterItemUpdates, user.id, { signal: ac.signal });
      } else if (!perimeterUtils.hasNoPerimeter(perimeter)) {
        userPerimeterEndpoint = perimeterService.updatePerimeter(perimeterItemUpdates, user.id, { signal: ac.signal });
      }

    }
    submitUpdate(userService.updateUser(parseInt(idUser), newUserDetailsInformations, { signal: ac.signal }), userPerimeterEndpoint);
  }

  const onSubmitUpdateSelf = (newUserInformations: UpdateUserInfo, ac: AbortController) => {
    submitUpdate(userService.updateSelf(newUserInformations, { signal: ac.signal }));
  }

  // Manage the first time creating a user
  // Also manage the discard popup disabling
  const onAsyncSubmitCreate = async (newUserInformations: UpdateUserInfo, ac: AbortController) => {

    // clean up paywishRole and djmId before submitting
    if(newUserInformations.paywishRole === undefined){
        delete newUserInformations.paywishRole;
        delete newUserInformations.djmId;
    }

    const userWithPerimeter: UserWithPerimeter = {
      user: {
        ...newUserInformations,
        profile: user.profile,
        permissions: user.permissions,
      },
      perimeter: {
        perimeters: perimeterUtils.buildPerimeterToRequestPerimeter(editedPerimeter)
      },
    }

    const _userWithPerimeter = await userService.createUserWithPerimeter(
      userWithPerimeter, { signal: ac.signal });

    if (options) {
      options.onFinallyAfterSubmit();
    }
    scrollUtils.scrollToTop();
    options && options.onAfterSubmit(_userWithPerimeter);
    disableDiscardDialog();
  }

  const onSubmitCreate = (newUserInformations: UpdateUserInfo, ac: AbortController) => {
    onAsyncSubmitCreate(newUserInformations, ac).catch((e: PromisableApiError) => {
      options && options.onFinallyAfterSubmit();
      void userAccountUtils.manageOnSubmitErrors(e, setErrors);
    })
  }

  return {
    errors,
    perimeterErrors,
    setPerimeterErrors,
    editedPerimeter,
    onUpdatePerimeter,
    validated,
    user,
    setUser,
    onChangePersInfo,
    onChangeProfile,
    onChangeDistributor,
    onSubmitUpdate,
    onSubmitUpdateSelf,
    onSubmitCreate,
    disableBlockingPopup,
    openDiscardDialog,
    onCloseDiscardDialog,
    onDiscard,
    payWishMerchants,
    onChangePaywishUserRole,
    onChangePaywishMerchant,
    onTogglePaywishUser,
  };
}
