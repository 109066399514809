import { FilterValues } from "interfaces/filter/filter";
import { filterUtils } from "utils/business/filterUtils";
import { dateUtils } from "utils/common/dateUtils";

export interface SettlementPDFExportParams {
  accountId: string[];
  locale: string; //"en", "fr", "sp", "it", "nl";
  companyId?: string[];
  merchantId?: string[];
  endDate?: string;
  startDate?: string;
}

const initSavedSettlementFilter = (savedSettlementFilters?: FilterValues): FilterValues => {
  const filters =
    savedSettlementFilters ?
      {
        ...filterUtils.initSavedPerimeterFilters(savedSettlementFilters),
        operationDate: filterUtils.generateOperationDate(savedSettlementFilters?.operationDate)
      }
      : null;

  return filters;
}

const computeFilters = (filters: FilterValues) => {
  return {
    accounts: filters.accounts.map(account => account.id).toString(),
    companies: filters.companies.selectedOptions.map(company => company.id).toString(),
    merchants: filters.merchants.selectedOptions.map(merchant => merchant.id).toString(),
    operationStartDate: dateUtils.formatToISO(filters.operationDate.startDate),
    operationEndDate: dateUtils.formatToISO(filters.operationDate.endDate),
  }
}

const computePdfExportFilters = (filters: FilterValues, locale: string): Record<string, string> => {
  return {
    accountId: filters.accounts.at(0).id.toString(),
    locale: locale ?? "en",
    companyId: filters.companies.selectedOptions.map(company => company.id).toString(),
    merchantId: filters.merchants.selectedOptions.map(merchant => merchant.id).toString(),
    endDate: dateUtils.formatToISO(filters.operationDate.endDate),
    startDate: dateUtils.formatToISO(filters.operationDate.startDate),
  };
}

export const settlementUtils = {
  initSavedSettlementFilter,
  computeFilters,
  computePdfExportFilters,
}
