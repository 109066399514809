import { TRANSACTION_EXPORT_ACQUIRED_TRANSACTIONS_ROUTE } from "./../../constants/routes/ApiRoutes";
import { SearchResponse } from "interfaces/api/ApiInterfaces";
import {
  PerimeterFilterValues,
  TransactionAdditionalFiltersValues,
  TransactionRow,
} from "interfaces/transaction/transaction";
import {
  TRANSACTION_EXPORT_TRANSACTIONS_ROUTE,
  TRANSACTION_GET_ACQUIRED_TRANSACTIONS_ROUTE,
  TRANSACTION_GET_TRANSACTIONS_HISTOGRAM_ROUTE,
  TRANSACTION_GET_TRANSACTIONS_ROUTE,
} from "constants/routes/ApiRoutes";
import { QueryParam } from "interfaces/UserInterface";
import { BlobWithFilename, httpService } from "services/HttpService";
import { queryUtils } from "utils/api/queryUtils";
import { FilterValues } from "interfaces/filter/filter";
import { TransactionHistogramResponse } from "interfaces/histogram/histogram";
import { transactionUtils } from "utils/business/transactionUtils";
import { HistogramUtils } from "utils/business/histogramUtils";
import { AcquiredTransactionResponse } from "interfaces/transaction/acquiredTransaction";

const buildFilters = (defaultFilters: FilterValues, additionalFilters: TransactionAdditionalFiltersValues): string => {
  const searchParams: Record<string, string> = transactionUtils.computeFilters(defaultFilters, additionalFilters);
  const params = new URLSearchParams({ ...searchParams });
  return `&${queryUtils.filterURLSearchParams(params).toString()}`;
};

const buildAcquiredTransactionsFilters = (
  filters: FilterValues,
  additionalFilters: TransactionAdditionalFiltersValues
): string => {
  const searchParams: Record<string, string> = transactionUtils.computeAcquiredTransactionsFilters(
    filters,
    additionalFilters
  );
  const params = new URLSearchParams({ ...searchParams });
  return `&${queryUtils.filterURLSearchParams(params).toString()}`;
};

const getTransactions =
  (defaultFilters: FilterValues, additionalFilters: TransactionAdditionalFiltersValues, options?: RequestInit) =>
  ({ page, pageSize, sortDirection, sortField }: QueryParam): Promise<SearchResponse<TransactionRow>> => {
    const url = `${TRANSACTION_GET_TRANSACTIONS_ROUTE}${queryUtils.queryParamToPageable({
      page,
      pageSize,
      sortDirection,
      sortField,
    })}${buildFilters(defaultFilters, additionalFilters)}`;
    return httpService.get(url, options);
  };

const getAcquiredTransactions =
  (defaultFilters: FilterValues, additionalFilters: TransactionAdditionalFiltersValues, options?: RequestInit) =>
  (queryParam: QueryParam): Promise<SearchResponse<AcquiredTransactionResponse>> => {
    const url = `${TRANSACTION_GET_ACQUIRED_TRANSACTIONS_ROUTE}${queryUtils.paginateQuery(
      queryParam
    )}${buildAcquiredTransactionsFilters(defaultFilters, additionalFilters)}`;
    return httpService.get(url, options);
  };

const downloadExportTransactions = (
  defaultFilters: FilterValues,
  additionalFilters: TransactionAdditionalFiltersValues,
  type: string,
  options?: RequestInit
): Promise<BlobWithFilename> => {
  const url = `${TRANSACTION_EXPORT_TRANSACTIONS_ROUTE}?type=${type}${buildFilters(defaultFilters, additionalFilters)}`;
  return httpService.getReadableStreamWithFilename(url, options);
};

const downloadExportAcquiredTransactions = (
  defaultFilters: FilterValues,
  additionalFilters: TransactionAdditionalFiltersValues,
  type: string,
  options?: RequestInit
): Promise<BlobWithFilename> => {
  const url = `${TRANSACTION_EXPORT_ACQUIRED_TRANSACTIONS_ROUTE}-${type.toLowerCase()}?${buildAcquiredTransactionsFilters(
    defaultFilters,
    additionalFilters
  )}`;
  return httpService.getReadableStreamWithFilename(url, options);
};

const getTransactionsHistogram = (
  perimeterFilters: PerimeterFilterValues,
  temporality: string,
  options?: RequestInit
): Promise<TransactionHistogramResponse> => {
  const url = `${TRANSACTION_GET_TRANSACTIONS_HISTOGRAM_ROUTE}?${HistogramUtils.buildHistogramFilters(
    perimeterFilters,
    temporality
  )}`;
  return httpService.get(url, options);
};

export const transactionService = {
  getTransactions,
  getAcquiredTransactions,
  downloadExportTransactions,
  getTransactionsHistogram,
  downloadExportAcquiredTransactions,
};
