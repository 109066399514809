import React, { FunctionComponent } from "react";
import MenuList from "@mui/material/MenuList";
import { MENU_ITEMS } from "constants/menu/menu";
import { useAuthUser } from "hooks/useAuthUser";
import { rightUtils } from "utils/business/rightUtils";
import { SideMenuItem } from "components/molecules/menu/SideMenu/SideMenuItem";

export interface SideMenuItemsProps {
  isReduced: boolean;
}

export const SideMenuPageItems: FunctionComponent<SideMenuItemsProps> = ({ isReduced }) => {
  const { user, userDetails } = useAuthUser();

  const permissions = userDetails.permissions;
  const menuItems = MENU_ITEMS.filter((item) => !item.permission || rightUtils.hasPermission(permissions, item.permission));

  return (
    <>
      {!!user && (
        <MenuList sx={{ padding: 0 }}>
          {menuItems.map((item, i) => {
            return (
              <li key={i}>
                <SideMenuItem isReduced={isReduced} item={item} />
              </li>
            );
          })}
        </MenuList>
      )}
    </>
  );
};
