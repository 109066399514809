import React, { FunctionComponent } from "react";
import styles from "components/atoms/button/more-actions/ListActions/listActions.module.scss";
import useOutsideClick from "hooks/useOutsideClick";

export interface Action {
  id: string | number;
  icon: JSX.Element;
  title: string;
  handler: () => void | Promise<void>;
  typeButton?: "primary" | "danger";
}

interface ListActionsProps {
  actions: Action[];
  closeHandler: () => void;
}

export const ListActions: FunctionComponent<ListActionsProps> = ({ actions, closeHandler }) => {

  const ref = useOutsideClick<HTMLDivElement>(closeHandler);
  return (
    <div ref={ref} className={styles["submenu"]}>
      <div className={styles["submenu-inner"]}>
        {actions.map((action, idx) => (
          <button key={idx} className={`${styles["submenu-button"]} ${ action.typeButton ? styles[action.typeButton] : styles["primary"] } `} onClick={action.handler}>
            <span className={styles["submenu-icon"]}>
              {action.icon}
            </span>
            {action.title}
          </button>
        ))}
      </div>
    </div>
  );
};
