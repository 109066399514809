import React, { FunctionComponent, useState } from "react";
import { ErrorReferentialLine } from "interfaces/error/error";
import { stringUtils } from "utils/common/stringUtils";
import { InputProps, LoginTextInput } from "components/atoms/input/LoginTextInput";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material";
import styles from "components/atoms/input/LoginPasswordInput/loginPasswordInput.module.scss";

interface PasswordInputProps extends InputProps {
    id: string,
    value: string,
    error?: boolean,
    required?: boolean,
    errors?: Record<string, boolean>,
    possibleErrors?: Array<ErrorReferentialLine>,
    LabelSx?: SxProps<Theme>
}

export const LoginPasswordInput: FunctionComponent<PasswordInputProps> = ({
    id,
    errors = {},
    error,
    possibleErrors,
    value,
    required = false,
    LabelSx = {},
    ...rest
}) => {

    const [showPassword, setShowPassword] = useState<boolean>(true);

    return (
        <div className={styles.wrapper}>
            <LoginTextInput
                {...rest}
                id={id}
                required={required}
                type={showPassword ? "password" : "text"}
                value={value}
                error={error}
                LabelSx={LabelSx}
                inputProps={{ maxLength: 255 }}
                possibleErrors={possibleErrors}
                errors={errors}
            />
            {!stringUtils.isEmpty(value) &&
                <div className={styles.svg}>
                    {showPassword ?
                        <VisibilityOutlined cursor="pointer" onClick={() => setShowPassword(false)} /> :
                        <VisibilityOffOutlined cursor="pointer" onClick={() => setShowPassword(true)} />}
                </div>
            }
        </div>
    )
}
