import React, { FunctionComponent } from "react";

export const SearchIcon: FunctionComponent = () => (
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 2.4H0V0.8H4V2.4ZM4 4.8H0V6.4H4V4.8ZM14.872 10.4L11.808 7.336C11.168 7.752 10.416 8 9.6 8C7.392 8 5.6 6.208 5.6 4C5.6 1.792 7.392 0 9.6 0C11.808 0 13.6 1.792 13.6 4C13.6 4.816 13.352 5.568 12.936 6.2L16 9.272L14.872 10.4ZM12 4C12 2.68 10.92 1.6 9.6 1.6C8.28 1.6 7.2 2.68 7.2 4C7.2 5.32 8.28 6.4 9.6 6.4C10.92 6.4 12 5.32 12 4ZM0 10.4H8V8.8H0V10.4Z"
      fill="#373F51"
    />
  </svg>
);
