import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import Select from "components/atoms/select/Select";
import { MenuItemStyled } from "components/atoms/select/DropdownStyled/MenuItemStyled";
import { KeyboardArrowDown } from "@mui/icons-material";
import styles from "components/atoms/select/DropdownStyled/dropdownStyled.module.scss";

export type DropdownProps = {
  selectedValue: string;
  options: { id: string, name: string }[];
  onChange?: (event) => void;
  onOpen?: () => void;
  customClassName?: string;
  placeholderId?: string;
  menuId?: string;
  disabled?: boolean;
}

export const DropdownStyled: FunctionComponent<DropdownProps> = ({
  selectedValue,
  options,
  onChange,
  onOpen,
  customClassName,
  placeholderId,
  menuId = "standard",
  disabled = false,
}) => {
  return (
    <div className={styles.wrapper}>
      <Select className={`${styles.select} ${customClassName ? styles[customClassName] : ""} `}
        MenuProps={
          { id: `${styles[`${menuId}`]}` }
        }
        value={selectedValue}
        IconComponent={(props) => <KeyboardArrowDown fontSize="small" {...props} />}
        onChange={(event) => { onChange(event) }}
        onOpen={onOpen}
        disabled={disabled}
      >
        {placeholderId &&
          <MenuItemStyled key={"placeholder"} value={"placeholder"} classes={{ root: `${styles["menu-item"]}`, selected: styles.selected }} disabled>
            <FormattedMessage id={placeholderId} ></FormattedMessage>
          </MenuItemStyled>
        }
        {options.map(item =>
          <MenuItemStyled key={item.id} value={item.id} classes={{ root: `${styles["menu-item"]}`, selected: styles.selected }}>
            <FormattedMessage id={item.name} />
          </MenuItemStyled>
        )}
      </Select>
    </div>
  )
}
