import { PortfolioTopBottomItem, PortfolioTopBottomResponse } from "components/molecules/portfolio/PortfolioTopBottomSection";

const TABLE_SIZE = 10;

const assignIndex = (item: PortfolioTopBottomItem, idx: number): PortfolioTopBottomItem => {
  item.idx = idx;
  return item;
};

export const getTopBottomItems = (response: PortfolioTopBottomResponse): Array<PortfolioTopBottomItem> => {
  const { items, total } = response;

  if (total < TABLE_SIZE * 2) {
    return items.map((item, idx) => assignIndex(item, idx + 1));
  }
  const topResults = items.slice(0, TABLE_SIZE).map((item, idx) => assignIndex(item, idx + 1));
  const bottomResults = items.slice(-TABLE_SIZE);
  bottomResults.forEach((item, idx) => assignIndex(item, total - bottomResults.length + idx + 1));
  return [...topResults, ...bottomResults];
};
