/**
 * File listing every translated label in French
 */

export const FrenchMessages = {

  // LOGIN PAGE
  welcome: "Bienvenue sur le", // welcome
  merchant_portal: "MERCHANT PORTAL!", // welcome
  email: "E-mail",
  password: "Mot de passe",
  email_example: "email@email.com",
  login_button: "Se connecter",
  forgot_password_cta: "Mot de passe oublié?",
  logo_alt: "Logo MarketPay",

  // LOGIN ERROR MESSAGES
  email_wrong_format: "Format de l'E-mail incorrect",
  email_required: "l'E-mail est obligatoire",
  password_required: "Le mot de passe est obligatoire",
  user_unknown: "We couldn’t find your email address",
  invalid_password: "Mot de passe / email incorrect(s)",
  password_pending: "La création de votre compte n'est pas finalisée. Un mail vous a été envoyé pour valider votre compte.",
  blocked_user: "Votre compte est bloqué. Contactez le support pour plus d'informations.",

  // RESET PASSWORD VIEW
  pw_recovery_title: "Récupération du mot de passe",
  reset_password_text: "Renseignez votre adresse email pour recevoir un lien de réinitialisation de votre mot de passe.",
  submit_button: "Envoyer",

  // RESET PASSWORD CONFIRM VIEW
  pw_confirm_title: "Votre demande de réinitialisation de mot de passe a été effectuée",
  pw_recovery_confirmation_body: "Nous allons vous envoyer un mail avec un lien de réinitialisation de mot de passe à l'adresse renseignée.",
  pw_recovery_confirmation_not_sent: "Si vous ne recevez pas ce mail, vérifiez vos filtres et vos spam.",
  pw_confirm_your_email: "Votre email : {email}",
  pw_resend_init_password_mail: "Votre mot de passe n'a pas encore été initialisé. Nous allons vous renvoyer le mail d'initialisation à l'adresse renseignée",

  // CHANGE PASSWORD
  change_password_sent_email: "Un lien de reinitialisation de mot de passe a été envoyé dans votre boite mail",
  change_password_title: "Mot de passe",
  change_password_reset_password: "Réinitialiser votre mot de passe",
  change_password_send_email: "Cliquez sur le bouton ci-dessous pour recevoir un email de réinitilisation à cette adresse",
  change_password_button: "Réinitialiser mon mot de passe",

  // UPDATE PASSWORD VIEW

  // INIT PASSWORD
  init_pw_page_title: "Choisir un mot de passe",
  init_pw_page_field2: "Confirmation",
  init_pw_page2_text: "Votre mot de passe a été défini avec succès !",

  // UPDATE PASSWORD
  set_password: "Modifier mon mot de passe",
  new_password_field: "Nouveau mot de passe",
  confirm_new_password_field: "Confirmation",
  password_requirements_label: "Afin de protéger votre compte, vérifiez que votre mot de passe :", // password_instructions
  password_requirements_include_label: "Contient au moins :", // password_instructions
  password_min_length: "Contient au moins 8 caractères", // password_instructions
  reset_password_button: "Changer mon mot de passe",
  unmatched_passwords: "La confirmation doit être similaire au mot de passe",
  password_lowercase: "1 caractère minuscule", // password_instructions
  password_uppercase: "1 caractère majuscule", // password_instructions
  password_number: "1 chiffre", // password_instructions
  password_special_character: "1 caractère spécial (~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/)", // password_instructions
  confirm_password_required: "La confirmation est obligatoire",
  reset_password_confirmation: "Votre nouveau mot de passe a été sauvegardé",
  back_login: "Retour à la page de connexion",
  send_feedback: "Envoyer un feedback",

  // SIDE MENU
  menu_home: "Dashboard",
  menu_transactions: "Transactions",
  menu_accepted: "Accepté",
  menu_acquired: "Acquis",
  menu_settlements: "Versements",
  menu_portfolio: "Portefeuille",
  menu_users: "Utilisateurs",
  menu_my_account: "Mon compte",
  menu_my_reports: "Mes rapports",
  menu_password: "Mot de passe",
  log_out: "Déconnexion",
  menu_notification_settings: "Mes notifications",
  menu_language: "Langue",

  // PROFILES
  merchant_admin: "Admin Marchand",
  market_pay_admin: "Administrateur",
  operator: "Opérateur",
  profil_spring: "SPRING",

  // ACCOUNT PAGE

  // PERSONAL INFORMATIONS
  account_my_account: "Mon compte",
  account_user: "Utilisateur {id} : {firstName} {lastName}",
  create_user_title: "Créer un nouvel utilisateur",
  pers_info: "Informations personnelles",
  first_name: "Prénom",
  last_name: "Nom",
  creation_date: "Date de création",
  last_activity: "Dernière activité",
  account_email: "Email",
  firstname_required: "Le prénom est obligatoire",
  firstname_wrong_format: "Votre prénom ne peut inclure que des lettres, des espaces et des tirets.", // name_rule
  lastname_required: "Le nom est obligatoire",
  lastname_wrong_format: "Votre nom ne peut inclure que des lettres, des espaces et des tirets.", // name_rule
  email_alr_used: "Cette adresse est déjà liée à un autre compte",
  distributor: "Distributeur",
  distributor_toggle: "Vue distributeur",

  // TYPE
  account_account: "Compte",
  account_type: "Type",

  // PERIMETER
  level_col: "Niveau",
  peri: "Périmètre",
  title_paywish: "Compte PayWish",
  permissions: "Droits",
  peri_account: "Compte",
  perimeter_group: "Groupe",
  peri_company: "Société",
  peri_merchant: "Magasin(s)",
  edit_button: "Modifier",
  delete: "Supprimer",
  account_all_perimeter: "Tous les comptes",
  account_no_perimeter: "Aucun",
  save_changes: "Enregistrer les modifications",
  discard_changes: "Ignorer les modifications",
  account_updated: "Vos modifications ont été enregistrées",
  account_tooltip_remove: "Supprimer cette société du périmètre",
  account_tooltip_reset: "Réinitialiser les choix",
  add_company: "Ajouter une société",
  no_results: "Aucun(s) résultat(s)",
  add_all: "Ajouter tous",
  required_account: "Champ obligatoire",
  required_company: "Champ obligatoire",
  required_merchants: "Champ obligatoire",
  inherit_future_merchants: "Hériter des magasins futurs",
  inherit_future_companies: "Hériter des sociétés futures",
  account_add_all_companies: "Ajouter toutes les sociétés",
  paywish_user_subtitle: "Utilisateur de PayWish",
  paywish_usertype: "Type",
  paywish_admin: "PayWish Admin",
  paywish_user: "PayWish User",
  paywish_select_usertype: "Sélectionner le type",
  paywish_select_merchant: "Sélectionner marchand",
  paywish_pw_merchant: "PayWish marchand",
  paywish_user_success_msg: "Un utilisateur PayWish a été créé avec succès",
  paywish_user_error_msg: "Une erreur technique s'est produite, il n'a pas été possible de créer un utilisateur pour le moment. Veuillez réessayer plus tard.",
  paywish_user_error_duplicate: "Un utilisateur PayWish avec cette adresse e-mail existe déjà.",

  // TRANSACTIONS
  permission_transactions: "Transactions",
  permission_approve_ref: "Approuver une demande de remboursement",
  permission_view_acc_acq_tx: "Visualiser les transactions acceptées et acquises",
  permission_trig_two_steps_ref: "Déclencher un remboursement en deux étapes",
  permission_trig_direct_ref: "Déclencher un remboursement direct",

  // SETTLEMENTS
  permission_category_settlements: "Versements",
  permission_view_settlements: "Consulter la liste des versements",
  settlements_view_acq_transactions: "Voir les transactions acquises",

  // USERS
  permission_users_man: "Utilisateurs",
  permission_add_user: "Ajouter un nouvel utilisateur au Merchant Portal",
  permission_del_user: "Supprimer un utilisateur",
  permission_user_update: "Mettre à jour un utilisateur",
  permission_export_users: "Exporter la liste des utilisateurs",

  // PORTFOLIO
  portfolio: "Portefeuille",
  permission_category_portfolio: "Portefeuille",
  permission_portfolio: "Accéder au portefeuille",
  forbiddenLabel: "Vous n'avez pas le droit nécessaire pour accéder à cette page",
  my_portfolio: "Mon Portfolio",
  title_volume_trends: "Tendances",
  portf_title_billing: "Facturation",
  portf_title_top_bottom: "Haut/Bas",
  portf_col_day: "Jour %",
  portf_col_week: "Semaine %",
  portf_col_month: "Mois %",
  portf_merchants: "Magasins",
  portf_companies: "Sociétés",
  portf_dist_com: "Distributor commission",
  portf_tx_err: "Transaction errors",
  switch_merchant: "Magasin",
  switch_company: "Société",
  portf_sort: "Trié par",
  portf_col_nbr_tx: "Nombre de transactions",
  portf_col_gross_sum: "Montant brut",
  portf_col_success_rate: "Taux de succès",
  portf_col_dist_com: "Commission distributeur ",
  portf_col_avg_com_rate: "Taux de commission moyen",
  portf_col_mp_com: "Commission Market Pay",
  portf_col_total_commission: "Commission totale ",
  portf_col_total_fees: "Commission totale ",
  rank: "#",
  portf_filter_display_trend_by: "Affichage des valeurs en % basées sur :",
  portf_tooltip_nbr_tx: "Le nombre de transactions affichées est basé sur le tri des colonnes.",
  portf_tooltip_gross: "Le montant brut est affiché en fonction du tri des colonnes",
  portf_col_day_tooltip: "Trier sur cette colonne pour afficher les données de transaction d'hier",
  portf_col_week_tooltip: "Trier sur cette colonne pour afficher les données de transaction des 7 derniers jours",
  portf_col_month_tooltip: "Trier sur cette colonne pour afficher les données de transaction du dernier mois",
  portf_tooltip_tx_success_rate: "Le taux de réussite des transactions représente le pourcentage de transactions acceptées avec succès par rapport au nombre total de transactions dans le résultat de recherche.",
  portf_tooltip_distributor_commission: "La commission du distributeur représente la partie de la commission de Market Pay qui est perçue au nom du distributeur.",
  portf_tooltip_mp_commission: "La commission Market Pay comprend le montant de la commission perçue par Market Pay et le montant de la commission perçue au nom du distributeur.",
  portf_tooltip_total_commission: "La commission totale comprend le montant total des frais Market Pay, des frais de scheme et des frais d'interchange.",
  portf_tooltip_avg_commission_rate: "Le taux de commission moyen représente le taux moyen de la commission prélevée par Market Pay sur toutes les transactions du résultat de recherche.",
  tooltip_daily_evolution: "Evolution quotidienne",
  tooltip_weekly_evolution: "Evolution hebdomadaire",
  tooltip_monthly_evolution: "Evolution mensuelle",
  tooltip_nb_transaction: "Nombre de transactions",
  tooltip_gross_sum: "Montant net",
  // REPORTS PAGE
  my_reports_title: "Mes rapports",
  reports_table_report_name_col: "Nom",
  reports_table_latest_edition_col: "Dernière édition",
  reports_table_frequency_col: "Fréquence",
  reports_table_frequency_col_daily: "Quotidien",
  reports_table_frequency_col_weekly: "Hebdomadaire",
  reports_table_frequency_col_monthly: "Mensuel",
  reports_table_latest_report_col: "Dernier rapport",
  reports_table_download_button: "Télécharger",
  reports_table_delete: "Supprimer",
  reports_table_delete_message: "Êtes-vous sûr de vouloir supprimer {name} ?",
  reports_table_delete_confirm: "Supprimer",
  reports_table_delete_cancel: "Annuler",

  // DOCUMENTS PAGE
  doc_menu: "Documents",
  doc_title: "Documents",
  doc_header: "Télécharcher votre Récapitulatif des Frais d'Encaissement par Carte",
  doc_settlement_year: "Année",
  doc_settlement_month: "Mois",
  doc_rafec_download: "Télécharger",

  // USERS PAGE
  users_acc_col_all: "Toutes",
  users_acc_col: "Organisation",
  users_search: "Rechercher",
  users_search_bar: "Rechercher par prénom, nom ou mail",
  add_user_button: "Ajouter utilisateur",
  user_not_found: "Utilisateur introuvable",
  acc_creation_conf_message: "Le nouveau compte utilisateur a été créé. Un email d'initialisation de mot de passe a été envoyé à {email}",
  back_user_list: "Retour à la liste d'utilisateurs",
  create_user_button: "Créer utilisateur",
  trigger_welcome_email: "Envoyer email d'accueil",
  welcome_email_header: "Email",
  welcome_email_sent: "Email d'accueil envoyé",
  welc_email_butt_error: "Nous n'avons pas pu envoyer l'email. Veuillez réessayer plus tard.",

  // LANGUAGE
  language_en: "EN",
  language_fr: "FR",
  language_it: "IT",
  language_es: "SP",
  language_nl: "NL",
  language_pt: "PT",

  // LOADER
  loader_search_results: "Recherche de résultats...",

  // TABLE
  number_element: "{displayedCount} sur {totalCount}",
  show_more: "Montrer {pageSize} de plus",
  no_data_found: "Oups ! Votre recherche n'a abouti sur aucun résultat.",
  fetch_error: "Oups ! Une erreur s'est produite.",
  table_label: "Votre recherche a donné {count, plural, =0 {0 résultats} one {1 résultat} other {{count} résultats}}",
  query_results: "Votre recherche a donné",
  query_results_return: " {count, plural, one {résultat} other {résultats}}:",
  query_results_no_results: "Aucun résultat",
  export_csv_button: "Export CSV",
  export_button: "Export",
  sr_schedule_button: "Programmer un rapport",
  sr_pop_up_title: "Programmer un rapport",
  sr_pop_up_report_name_label: "Nom du rapport",
  sr_pop_up_report_name_placeholder: "Export hebdomadaire des transactions échouées",
  sr_pop_up_frequency_label: "Fréquence",
  sr_pop_up_frequency_placeholder: "Sélectionner",
  sr_pop_up_close_button: "Fermer",
  sr_pop_up_save_button: "Enregistrer",
  sr_frequency_monthly: "Mensuel",
  sr_frequency_weekly: "Hebdomadaire",
  sr_frequency_daily: "Quotidien",
  sr_frequency_monthly_disclaimer: "Un nouveau rapport comprenant les données du mois précédent sera établi pour vous le premier jour de chaque mois.",
  sr_frequency_weekly_disclaimer: "Un nouveau rapport comprenant les données de la semaine dernière sera établi pour vous chaque lundi matin.",
  sr_frequency_daily_disclaimer: "Un nouveau rapport comprenant les données du jour précédent sera établi pour vous tous les matins.",
  sr_conf_1_message: "Vous êtes sur le point de programmer un rapport {frequency} nommé {reportName}.",
  sr_conf_1_message_confirm: "Confirmez-vous?",
  sr_conf_1_cancel_button: "Annuler",
  sr_conf_1_confirm_button: "Confirmer",
  sr_conf_2_message_1: "Votre rapport a été programmé.",
  sr_conf_2_message_2: "Visitez la page {myReportsLink} pour trouver et gérer tous vos rapports programmés.",
  sr_conf_2_message_link: "Mes rapports",
  sr_conf_2_button: "OK",
  sr_error_empty_name: "Veuillez donner un nom au rapport.",
  sr_error_existing_name: "Ce nom est déjà utilisé. Veuillez en choisir un autre.",
  sr_error_empty_frequency: "Veuillez choisir la fréquence de votre rapport.",
  sr_error_name_too_short: "Le nom doit comporter au moins 3 caractères.",
  sr_saving_error: "Votre rapport ne peut être sauvegardé pour le moment en raison d'une erreur technique. Veuillez réessayer plus tard.",
  export_limit_message_no_number: "Le rapport que vous essayez de télécharger est trop volumineux. Lancez une recherche plus restreinte et réessayez.",
  toggle_sorting: "Cliquez pour trier",
  no_reports_scheduled: "Vous n'avez pas de rapports programmés.",

  // ACTIVITY
  activity_years_ago: "Il y a {interval} ans",
  activity_month_ago: "Il y a {interval} mois",
  activity_day_ago: "Il y a {interval, plural, one {1 jour} other {{interval} jours}}",
  activity_today: "Aujourd'hui",
  activity_yesterday: "Hier",
  activity_never: " ",

  // PERMISSIONS FORBIDDEN
  not_allowed_to_create_user: "Vous n'êtes pas autorisé à créer des utilisateurs",
  not_allowed_to_delete_user: "Vous n'êtes pas autorisé à supprimer des utilisateurs",
  not_allowed_to_get_user: "Vous n'êtes pas autorisé à consulter des utilisateurs",

  // DISCARD POPUP
  toast_discard_change: "Vos changements ont été abandonnés",
  unsaved_ch_err_mess: "Vos changements pourraient être perdus.",
  unsaved_ch_err_mess_save: "Souhaitez-vous les enregistrer?",
  discard: "Ignorer",
  cancel: " Annuler",
  ok: "Ok",
  save: "Enregistrer",

  // DELETE POPUP
  delete_account_title: "Supprimer le compte : {email}",
  delete_account_dialog_box: "Êtes-vous sûr de vouloir supprimer ce compte ?",
  delete_account_success: "L'utilisateur a été supprimé",

  // MONTHS
  january: "Janvier",
  february: "Février",
  march: "Mars",
  april: "Avril",
  may: "Mai",
  june: "Juin",
  july: "Juillet",
  august: "Août",
  september: "Septembre",
  october: "Octobre",
  november: "Novembre",
  december: "Décembre",

  // AMOUNT RANGE FILTER
  min_sup_max: "Le montant minimum doit être égal ou inférieur au montant maximum.",
  negative_value: "Les valeurs doivent être supérieures ou égales à 0",
  card_transactions: "Transactions",
  card_trans_gross_amount: "Montant total brut",
  card_trans_net_amount: "Montant total net",
  card_trans_refunds_amount: "Remboursements",
  card_trans_fees_amount: "Commission",

  // TRANSACTION PAGE
  transactions_title: "Transactions",
  acc_transactions_title: "Transactions acceptées",
  filter_country: "Pays",
  filter_account: "Organisation",
  filter_company: "Société",
  filter_merchant: "Magasin",
  filter_operation_date: "Date d'acceptation",
  search: "Rechercher",
  filter_by: "Filtrer par",
  admin_trans_view_title: "Appliquer au moins un filtre pour voir les transactions",
  admin_trans_view_text: "Filtrer par pays, organisation, société, magasin ou date de paiement pour affiner votre recherche",
  export_limit_message: "Vous ne pouvez exporter plus de {max} lignes dans un fichier. Réduisez votre recherche puis réessayez.",
  filter_search_button_toaster: "Sélectionnez une Organisation avant de lancer votre recherche",
  col_man_err_message: "Vous ne pouvez afficher moins de 5 colonnes dans le tableau. Sélectionnez un autre champ avant de déselectionner celui-ci.",

  // ADDITIONAL FILTERS
  more_filters: "Plus de filtres",
  less_filters: "Moins de filtres",
  order_number: "N° commande",
  payment_solution: "Solution de paiement",
  payment_type: "Type de paiement",
  checkout_type: "Type d'encaissement",
  settlement_status: "Statut versement",
  settlement_date: "Date versement",
  total_fees: "Commission",
  trans_authorization: "Autorisation",
  trans_cancellation: "Annulation",
  trans_capture: "Capture",
  trans_settled: "Versée",
  trans_refund: "Remboursement",
  trans_refund_request: "Demande remboursement",
  event_success: "Succès",
  event_declined: "Refus",
  event_error: "Erreur",
  solution_pay_online: "PayOnline",
  solution_pay_wish: "PayWish",
  solution_pay_onsite: "PayOnsite",
  type_debit: "Debit",
  type_credit: "Credit",
  checkout_contactless: "Sans contact",
  checkout_online: "En ligne",
  checkout_insertion: "Insertion",
  checkout_magstripe: "Magstripe",
  financing_paid: "Versé",
  financing_pending: "En attente",
  batch_id: "ID du batch",
  scheme: "Scheme",
  filter_remittance: "Télécollecte",
  filter_service_type: "Type de service",
  filter_collection_type: "Type de collecte",

  // POPUP COLUMNS
  col_man_button: "Colonnes",
  col_man_title: "Colonnes affichées",
  col_man_apply: "Appliquer",
  col_man_discard: "Abandonner",
  col_man_titles_acceptation: "Acceptation",
  col_man_titles_last_event: "Dernier événement",
  col_man_titles_transaction: "Transaction",
  col_man_titles_settlement: "Versement",
  col_man_titles_customer: "Client",
  col_man_titles_merchant: "Marchand",
  col_man_checkout_type: "Type d'encaissement",
  col_man_payment_method: "Méthode de paiement",
  col_man_payment_solution: "Solution de paiement",
  col_man_payment_type: "Type de paiement",
  col_man_authorisation_code: "Code autorisation",
  col_man_pos_id: "ID POS",
  col_man_pos_name: "Nom du POS",
  col_man_bank_acc_dom_country: "Domiciliation",
  col_man_card_iss_bank: "Banque émettrice",
  col_man_card_iss_coun: "Pays d'émission",
  col_man_card_level: "Niveau de carte",
  col_man_card_type: "Type de carte",
  col_man_pan_first_six: "BIN",
  col_man_pan_last_four: "Quatre derniers chiffres",
  col_man_scheme: "Scheme",
  col_man_op_date: "Date de l'opération",
  col_man_op_time: "Heure de l'opération",
  col_man_currency: "Devise",
  col_man_gross_amount: "Montant brut",
  col_man_net_amount: "Montant net",
  col_man_merch_trans_id: "ID Transaction du marchand",
  col_man_order_num: "Numéro de commande",
  col_man_trans_type: "Type de transaction",
  col_man_trans_event: "Événement",
  col_man_status: "Statut",
  col_man_trans_event_id: "ID de l'événement",
  col_man_root_trans_event_id: "ID Transaction",
  col_man_event_timestamp: "Date et heure de l'événement",
  col_man_acquirer: "Acquéreur",
  col_man_settlement_batch: "ID Versement",
  col_man_settlement_date: "Date de versement",
  col_man_settlement_status: "Statut de versement",
  col_man_interchange: "Interchange",
  col_man_intermediate_fees: "Frais d'intermédiation",
  col_man_mkp_fees: "Frais Market Pay",
  col_man_scheme_fees: "Frais du Scheme",
  col_man_total_fees: "Commission totale",
  col_man_account_name: "Organisation",
  col_man_company_name: "Société",
  col_man_merchant_name: "Magasin",
  col_man_country: "Pays",
  col_man_contract_num: "Numéro de contrat",
  col_man_reset_button: "Retour aux paramètres par défaut",
  col_man_titles_fee_calc: "Calcul des frais",
  col_man_titles_fee_amount: "Montant des frais",
  col_settlement_month: "Mois de versement",
  col_imposta_di_bollo: "Imposta di bollo",
  category_distribution: "Distribution",
  col_retrocession: "Rétrocession",
  distrib_share_margin_amt: "Part de marge distributeur",
  distrib_share_margin_rate: "Part de marge distributeur (taux)",
  mp_share_margin_amt: "Part de marge Market Pay",
  mp_share_margin_rate: "Part de marge Market Pay (taux)",
  col_operation_time_utc: "Heure de l'opération (UTC)",
  col_operation_time_local: "Heure de l'opération (Locale)",

  // COUNTRIES
  country_filter_fr: "France",
  country_filter_sp: "Espagne",
  country_filter_nl: "Pays-Bas",
  country_filter_pt: "Portugal",
  country_filter_it: "Italie",
  country_filter_bel: "Belgique",
  country_filter_ger: "Allemagne",

  // LOADING
  loading: "Chargement...",

  // SETTLEMENTS
  card_settlements_gross_am: "Montant brut",
  card_settlements_comm: "Commission totale",
  card_settlements_fin_am: "Montant versé",
  settled_by_mp: "Versé par Market Pay",
  filter_settlement_date: "Date de versement",

  // HOMEPAGE
  good_morning: "Bonjour,",
  good_evening: "Bonsoir,",
  homepage_today: "Aujourd'hui",

  // GRAPH

  // COMMON
  homepage_graph_month: "Mois",
  homepage_graph_week: "Semaine",
  homepage_graph_day: "Jour",
  week: "Semaine",
  graph_tooltip_number: "Nombre",
  graph_tooltip_amount: "Montant",
  graph_tooltip_week_leg: "S",
  graph_dropdown_interval: "Intervalle de temps",
  graph_dropdown_amount: "Type de montant",

  // TRANSACTIONS
  homepage_graph_leg_gross: "Montant brut",
  homepage_graph_leg_net: "Montant net",
  homepage_graph_leg_num: "Nombre de transactions",
  homepage_graph_leg_am: "Montant (€)",
  graph_trans_hp_bot_leg_num: "Nombre de transactions",
  graph_trans_hp_bot_leg_am: "Montant (€)",
  graph_transactions: "TRANSACTIONS",

  // SETTLEMENTS
  graph_hp_sett_filter_value_1: "Versé",
  graph_hp_sett_filter_value_2: "Commission",
  graph_hp_sett_leg_num: "Nombre de versements",
  graph_hp_sett_leg_am: "Montant (€)",
  graph_hp_bot_sett_leg_num: "Nombre de versements",
  graph_hp_bot_sett_leg_am: "Montant (€)",
  graph_settlements: "VERSEMENTS",

  // MONTHS
  january_abr: "Jan",
  february_abr: "Fev",
  march_abr: "Mar",
  april_abr: "Avril",
  may_abr: "Mai",
  june_abr: "Juin",
  july_abr: "Jul",
  august_abr: "Août",
  september_abr: "Sep",
  october_abr: "Oct",
  november_abr: "Nov",
  december_abr: "Dec",

  //HOMEPAGE DAILY KEY NUMBERS
  homepage_title_sales: "Ventes",
  homepage_title_settlements: "Versements (transactions acceptées à J-1)",
  homepage_key_numb_acc: "Transactions acceptées",
  homepage_key_numb_am: "Montant total accepté",
  homepage_key_numb_settlement_gr_am: "Montant brut",
  homepage_key_numb_fees: "Commission totale",
  homepage_key_numb_fin: "Montant versé",

  // ACQUIRED TRANSACTIONS VIEW
  acq_transactions_title: "Transactions acquises",
  transaction_date: "Date de la transaction",
  gross_amount: "Montant",
  col_card_brand: "Marque de la carte",
  col_man_fees: "Commission",
  col_tlc_number: "Numéro de télécollecte",
  acq_trans_key_numbers_title: "Versé par Market Pay",
  card_acq_trans_number: "Transactions",
  card_acq_trans_gross_amount: "Montant brut",
  card_acq_trans_fees_amount: "Commission",
  card_acq_trans_net_amount: "Montant net",
  acq_details_title: "Transactions acquises  > ",

  // SETTLEMENTS VIEW
  settlements_title: "Versements",
  settlement_tab_batch: "ID du batch",
  set_tab_merchant: "Marchand",
  pay_tab_settlement_date: "Date du versement",
  pay_tab_pay_sol: "Solution",
  pay_tab_pay_amo: "Montant brut",
  settlement_tab_fees: "Commission",
  pay_tab_settlement_am: "Versé",

  // TRANSACTION DETAILS
  tp_titles_acceptation: "Acceptation",
  tp_titles_customer: "Client",
  tp_titles_transaction: "Transaction",
  tp_titles_history: "Historique",
  tp_titles_settlement: "Versement",
  tp_titles_merchant: "Marchand",
  tp_fees_calculation: "Calcul des taxes",
  tp_fees_amount: "Montant des taxes",
  tpd_checkout_type: "Type d'encaissement",
  tpd_payment_method: "Méthode de paiement",
  tpd_payment_solution: "Solution de paiement",
  tpd_payment_type: "Type de paiement",
  tpd_authorisation_code: "Code autorisation",
  tpd_pos_id: "ID POS",
  tpd_pos_name: "Nom du POS",
  tpd_bank_acc_dom_country: "Domiciliation",
  tpd_card_iss_bank: "Banque émettrice",
  tpd_card_iss_coun: "Pays d'émission",
  tpd_card_level: "Niveau de carte",
  tpd_card_type: "Type de carte",
  tpd_pan_first_six: "BIN",
  tpd_pan_last_four: "Quatre derniers chiffres",
  tpd_scheme: "Scheme",
  tpd_op_date: "Date de l'opération",
  tpd_op_date_utc: "Date de l'opération (UTC)",
  tpd_op_time: "Heure de l'opération",
  tpd_currency: "Devise",
  tpd_gross_amount: "Montant brut",
  tpd_net_amount: "Montant net",
  tpd_trans_id: "ID Transaction",
  tpd_merch_trans_id: "ID Transaction du marchand",
  tpd_order_num: "Numéro de commande",
  tpd_trans_type: "Type de transaction",
  tpd_trans_event: "Événement",
  tpd_status: "Statut",
  tpd_trans_event_id: "ID de l'événement",
  tpd_root_trans_event_id: "ID Transaction",
  tpd_event_timestamp: "Date et heure de l'événement",
  tpd_acquirer: "Acquéreur",
  tpd_settlement_batch: "Batch de versement",
  tpd_settlement_date: "Date de versement",
  tpd_settlement_status: "Statut de versement",
  tpd_interchange: "Interchange",
  tpd_intermediate_fees: "Frais d'intermédiation",
  tpd_mkp_fees: "Frais Market Pay",
  tpd_scheme_fees: "Frais du Scheme",
  tpd_total_fees: "Commission totale",
  tpd_account_name: "Organisation",
  tpd_company_name: "Société",
  tpd_merchant_name: "Magasin",
  tpd_country: "Pays",
  tpd_contract_num: "Numéro de contrat",
  tpd_transaction_settlement_id: "ID de règlement de la transaction",
  tpd_tlc_number: "Numéro de télécollecte",
  tpd_card_zone: "Zone des cartes",
  tpd_card_brand: "Marque de la carte",
  tpd_settlement_id: "ID Versement",
  tpd_calculation_method: "Méthode de calcul",
  tpd_marketpay_fixed_fees: "Frais fixes de Market Pay",
  tpd_markeetpay_fees_rate: "Taux de frais de Market Pay",
  tpd_fixed_scheme_fees: "Frais fixes du système",
  tpd_scheme_fees_rate: "Taux de redevance du système",
  tpd_total_marketpay_fees: "Total des frais de Market Pay",
  tpd_fixed_fees: "Frais fixes",
  tpd_variable_fees: "Frais variables",
  tpd_total_acquiring_fees: "Total des frais d'acquisition",
  col_total_marketpay_fees: "Total des frais de Market Pay",
  col_interchange_fees: "Commissions d'interchange",
  col_scheme_fees_rate: "Taux de redevance du système",
  col_fixed_scheme_fees: "Frais fixes du système",
  col_marketpay_fees_rate: "Taux de frais de Market Pay",
  col_marketpay_fixed_fees: "Frais fixes de Market Pay",
  col_calculation_method: "Méthode de calcul",
  col_card_zone: "Zone des cartes",
  col_transaction_settlement_id: "ID de règlement de la transaction",
  payment_type_card: "Carte",
  payment_type_banktransfer: "Virement",
  payment_type_contactless: "Sans contact",
  payment_type_wallet: "Wallet",
  pos_id: "POS ID",
  col_processing_ht: "Frais de traitement HT",
  col_processing_tva: "Frais de traitement TVA",
  col_processing_ttc: "Frais de traitement TTC",

  // TRANSACTION HISTORY TABLE
  trans_det_tab_uuid: "UUID",
  trans_det_tab_timestamp: "Timestamp",
  trans_det_tab_event: "Événement",
  trans_det_tab_status: "Statut",
  trans_det_tab_amount: "Montant",
  transaction_det_last_sync: "Last sync: ",
  transaction_det_page_title_1: "Transactions >",
  transaction_det_page_title_2: "Transaction {transactionId} details",
  back_transactions_list: "Retour à la liste des transactions",

  // NOTIFICATIONS
  notif_new_merchant: "Un nouveau magasin a été ajouté à votre",
  notif_new_comp: "Une nouvelle société a été ajoutée à votre",
  notif_new_account: "Un nouveau compte a été ajouté à votre",
  notif_new_merchant_admin_inapp: "Un nouveau Merchant Admin a été créé :",
  notif_new_marketpay_admin_inapp: "Un nouveau Market Pay Admin a été créé :",
  notif_new_operator_inapp: "Un nouvel Opérateur a été créé dans votre",
  notif_hide_button: "Masquer",
  notif_hide_all_button: "Tout masquer",
  new_notif: "Vous avez {count} nouvelle(s) notification(s) :",
  no_new_notif: "Vous n'avez pas de nouvelles notifications",
  notif_viewed: "Vu",

  // NOTIFICATION SETTINGS
  my_notifications: "Mes notifications",
  notif_title: "Mes notifications",
  notif_peri_update: "Changements de périmètre",
  notif_user_update: "Utilisateurs",
  notif_in_app: "In-App",
  notif_email: "Par email",
  notif_edit_merchant: "Nouveau marchand",
  notif_edit_company: "Nouvelle société",
  notif_edit_acc: "Nouvelle organisation",
  notif_edit_group: "Nouveau groupe",
  notif_edit_operator: "Nouvel opérateur",
  notif_edit_merchant_admin: "Nouveau Merchant Admin",
  notif_edit_markerpay_admin: "Nouveau Market Pay Admin",
  perim_creat_account: "Rechercher une Organisation",
  perim_creat_company: "Rechercher une Société",

  // FILTERS
  filter_all_comp: "Tous",
  reset_button_text: "Réinitialiser",

  // VIEWS
  sv_deletion_confirmation_toaster: "Votre vue a été supprimée",
  sv_creation_confirmation: "Votre vue a été sauvegardée",
  sv_save_this_view: "Enregistrer cette vue",
  sv_view_name: "Nom de la vue",
  sv_saved_views: "Vues sauvegardées",
  sv_name_is_required: "Le nom est obligatoire",
  sv_name_is_too_short: "Le nom doit contenir 3 caractères minimum",
  view_name_already_exists: "Ce nom de favoris existe déjà",
  sv_deletion_confirmation: "Êtes-vous sûr(e) de vouloir supprimer cette vue ?",

  // DATEPICKER DEFINED RANGES
  date_picker_today: "Aujourd'hui",
  date_picker_yesterday: "Hier",
  date_picker_current_month: "Mois en cours",
  date_picker_last_week: "Semaine dernière",
  date_picker_last_two_weeks: "Deux dernières semaines",
  date_picker_last_month: "Mois dernier",
};
