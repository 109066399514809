import {PROFIL_USER} from "../user/user";
import {ConstantLabelLine} from "../perimeter/perimeter";

export interface PermissionLine  extends ConstantLabelLine{
    forbiddenLabel: string,
    irreversibleForProfiles?: Array<string>,
}

export interface Permissions {
    [key: string]: PermissionLine
}

export interface PermissionCategory {
    label: string,
    permissions: PermissionLine[]
}

export const PERMISSIONS: Permissions = {
    // TRANSACTIONS
    TRANSACTIONS_APPROVE_REF: {
        id: "TRANSACTIONS_APPROVE_REF",
        label: "permission_approve_ref",
        forbiddenLabel: "not_allowed_to_approve_transaction_ref"
    },
    TRANSACTIONS_CONSULT: {
        id: "TRANSACTIONS_CONSULT",
        label: "permission_view_acc_acq_tx",
        forbiddenLabel: "not_allowed_to_consult_transaction_ref"
    },
    TRANSACTIONS_TRIG_TWO_STEPS_REF: {
        id: "TRANSACTIONS_TRIG_TWO_STEPS_REF",
        label: "permission_trig_two_steps_ref",
        forbiddenLabel: "not_allowed_to_trigger_two_step_transaction_ref"
    },
    TRANSACTIONS_TRIG_DIRECT_REF: {
        id: "TRANSACTIONS_TRIG_DIRECT_REF",
        label: "permission_trig_direct_ref",
        forbiddenLabel: "not_allowed_to_trigger_direct_transaction_ref"
    },
    // SETTLEMENTS
    SETTLEMENTS_CONSULT: {
        id: "SETTLEMENTS_CONSULT",
        label: "permission_view_settlements",
        forbiddenLabel: "not_allowed_to_consult_settlements"
    },
    // USERS
    USER_CREATE: {
        id: "USER_CREATE",
        label: "permission_add_user",
        forbiddenLabel: "not_allowed_to_create_user",
        irreversibleForProfiles: [PROFIL_USER.MERCHANT_ADMIN.id]
    },
    USER_UPDATE: {
        id: "USER_UPDATE",
        label: "permission_user_update",
        forbiddenLabel: "not_allowed_to_update_user",
        irreversibleForProfiles: [PROFIL_USER.MERCHANT_ADMIN.id]
    },
    USER_DELETE: {
        id: "USER_DELETE",
        label: "permission_del_user",
        forbiddenLabel: "not_allowed_to_delete_user",
        irreversibleForProfiles: [PROFIL_USER.MERCHANT_ADMIN.id]
    },
    USER_GET: {
        id: "USER_GET",
        label: "permission_user_get",
        forbiddenLabel: "not_allowed_to_get_user",
        irreversibleForProfiles: [PROFIL_USER.MERCHANT_ADMIN.id, PROFIL_USER.OPERATOR.id]
    },
    USER_EXPORT: {
        id: "USER_EXPORT",
        label: "permission_export_users",
        forbiddenLabel: "not_allowed_to_export_users",
        irreversibleForProfiles: [PROFIL_USER.ADMIN.id]
    },
    PORTFOLIO_CONSULT: {
        id: "PORTFOLIO_CONSULT",
        label: "permission_portfolio",
        forbiddenLabel: "not_allowed_to_consult_portfolio",
        irreversibleForProfiles: [PROFIL_USER.ADMIN.id]
    }
}

export const TRANSACTIONS_PERMISSIONS: PermissionCategory = {
    label: "permission_transactions",
    permissions: [
        PERMISSIONS.TRANSACTIONS_CONSULT,
        // PERMISSIONS.TRANSACTIONS_TRIG_DIRECT_REF,
        // PERMISSIONS.TRANSACTIONS_TRIG_TWO_STEPS_REF,
        // PERMISSIONS.TRANSACTIONS_APPROVE_REF,

    ]
}

export const SETTLEMENTS_PERMISSIONS: PermissionCategory = {
    label: "permission_category_settlements",
    permissions: [
        PERMISSIONS.SETTLEMENTS_CONSULT
    ]
}

export const USERS_PERMISSIONS: PermissionCategory = {
    label: "permission_users_man",
    permissions: [
        PERMISSIONS.USER_CREATE,
        PERMISSIONS.USER_UPDATE,
        PERMISSIONS.USER_DELETE,
        PERMISSIONS.USER_EXPORT
    ]
}

export const PORTFOLIO_PERMISSIONS: PermissionCategory = {
    label: "permission_category_portfolio",
    permissions: [
        PERMISSIONS.PORTFOLIO_CONSULT,
    ]
}

