import React, { FunctionComponent } from "react";
import { ExpandCollapseFilters } from "components/molecules/filters/ExpandCollapseFilters";
import Collapse from "@mui/material/Collapse";
import styles from "components/layouts/FilterLayout/filters.module.scss";
import { Filters } from "components/molecules/filters";
import { FilterColumn } from "interfaces/filter/filterColumn";
import { ExpandCollapseFiltersConfig } from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";

export interface FilterLayoutProps {
    mainFilters:  FilterColumn[];
    additionalFilters?: FilterColumn[];
    expandCollapseFiltersConfig?: ExpandCollapseFiltersConfig;
}

export const FilterLayout: FunctionComponent<FilterLayoutProps> = ({
    mainFilters,
    additionalFilters,
    expandCollapseFiltersConfig,
}) => {



    return (
        <div className={styles.container}>
            <div className={`${styles.filters} ${styles.main}`}>
                <Filters columns={mainFilters} />
            </div>
          { additionalFilters && expandCollapseFiltersConfig &&
          <>
            <div className={`flex flex-center ${styles.expand}`}>
                <ExpandCollapseFilters expandCollapseFiltersConfig={expandCollapseFiltersConfig} />
            </div>
            <Collapse in={expandCollapseFiltersConfig.moreFiltersButtonState.showFilters}>
                <div className={`${styles.filters} ${styles.additional}`}>
                    <Filters columns={additionalFilters} />
                </div>
            </Collapse>
            </>
          }
        </div>
    )
}
