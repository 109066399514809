import {SxProps, Theme} from "@mui/material";
import {paletteTheme} from "../../themes/palette";

export const amountsStyles = (validAmounts: boolean, focused: boolean): SxProps<Theme> => {
    return {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: validAmounts ? paletteTheme.colors.PRIMARY : "",
        },
        "& .MuiFormLabel-root.MuiInputLabel-root": {
            color: focused || validAmounts ? paletteTheme.colors.PRIMARY : "",
        },
        "&:hover": {
            "& .MuiFormLabel-root.MuiInputLabel-root": {
                color: !focused && !validAmounts ? paletteTheme.colors.DEFAULT_TEXT_COLOR : paletteTheme.colors.PRIMARY
            }
        },
    }
}
