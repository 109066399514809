import { useState } from "react";
import { rightUtils } from "utils/business/rightUtils";
import { UserDetails } from "interfaces/user/user";

interface FilterInstance {
  showContent: boolean;
  onHideIfAdmin: () => void;
  onShow: () => void;
  onHide: () => void;
}

/**
 * Perimeter filter hook to get perimeterOptions easily on your pages
 */
export function useShowContentToUser(user: UserDetails, documents? : boolean): FilterInstance {

  const [showContent, setShowContent] = useState<boolean>(!documents && !rightUtils.isAdminOrGroupPerimeter(user));

  const onHideIfAdmin = () => {
    setShowContent(!documents && !rightUtils.isAdminOrGroupPerimeter(user)) // Hide content for users with multiple account
  }

  const onShow = () => {
    setShowContent(true)
  }
  
  const onHide = () => {
    setShowContent(false)
  }

  return {
    onHideIfAdmin,
    onShow,
    onHide,
    showContent,
  }
}
