import {USER_ERRORS} from "../errors/user";
import {ErrorReferentialLine} from "../../interfaces/error/error";


interface UserPersInfo {
    id: string,
    marginBottom?: number,
    key: string,
    possibleErrors: ErrorReferentialLine[]
}


export const PERS_INFO_INPUTS: Array<UserPersInfo> = [
    {
        id: "first_name",
        marginBottom: 3,
        key: "firstName",
        possibleErrors: [USER_ERRORS.FIRSTNAME_REQUIRED, USER_ERRORS.FIRSTNAME_WRONG_FORMAT]
    },
    {
        id: "last_name",
        marginBottom: 3,
        key: "lastName",
        possibleErrors: [USER_ERRORS.LASTNAME_REQUIRED, USER_ERRORS.LASTNAME_WRONG_FORMAT]
    }
]
