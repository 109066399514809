import React, { FunctionComponent } from "react";
import Label from "components/atoms/Label";
import styles from "components/atoms/card/cardRowCommon.module.scss";

interface CardRowProps {
  id: string;
  label: string;
  required?: boolean;
  marginBottom?: number | string;
  marginLeft?: number;
  fontWeight?: number;
  color?: string;
  customClassName?: string;
  customLabelClassName?: string;
  customContentClassName?: string;
}

export const CardRow: FunctionComponent<CardRowProps> = ({
  id,
  required,
  label,
  marginLeft = 0,
  fontWeight = 600,
  color,
  children,
  customClassName,
  customLabelClassName,
  customContentClassName,
}) => {
  return (
    <div className={`${styles.container} ${customClassName ? styles[customClassName] : ""}`}>
      <div className={`${styles.label} ${customLabelClassName ? styles[customLabelClassName] : ""}`}>
        {id !== "no-label" &&
          <Label
            id={id}
            required={required}
            label={label}
            sx={{ fontSize: 14, fontWeight, position: "initial", marginLeft, color }}
          />
        }
      </div>
      <div className={`${styles.content} ${customContentClassName ? styles[customContentClassName] : ""}`}>
        {children}
      </div>
    </div>
  );
};
