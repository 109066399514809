import React, { FunctionComponent, useState } from "react";
import { Collapse } from "@mui/material";
import { TMenuItem } from "interfaces/menu/menu";
import { IMenuItem, ItemMenu } from "components/molecules/menu/SideMenu/ItemMenu";
import { SideMenuItem } from "components/molecules/menu/SideMenu/SideMenuItem";

export const ParentItemMenu: FunctionComponent<IMenuItem & { subItems: TMenuItem[]; isSubItemActive: boolean }> = ({
  subItems,
  Icon,
  label,
  isReduced,
  isSubItemActive,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(isSubItemActive);
  return (
    <>
      <div onClick={() => setIsMenuOpen((prev) => !prev)} className={`${isSubItemActive ? "active" : ""} ${isMenuOpen ? "is-open" : ""}`}>
        <ItemMenu label={label} Icon={Icon} isReduced={isReduced} className="parent" />
      </div>
      <Collapse in={isMenuOpen} className={isSubItemActive ? "active" : ""}>
        {subItems.map((subItem, idx) => (
          <SideMenuItem key={idx} item={subItem} isReduced={isReduced} className="sub-item" />
        ))}
      </Collapse>
    </>
  );
};
