import React, { FunctionComponent, useMemo } from "react";
import { Checkbox, CheckboxProps, FormControlLabel, Typography } from "@mui/material";
import { authUtils } from "utils/api/authUtils";
import { FormattedMessage } from "react-intl";
import { paletteTheme } from "styles/themes/palette";
import { FlexAttributValue } from "types/flex/flex";
import { CheckBoxOutlined } from "@mui/icons-material";
import styles from "components/atoms/checkbox/labelCheckbox.module.scss";
import FlexBox from "components/atoms/flex/FlexBox";

export interface LabeledCheckboxProps extends CheckboxProps {
    label: string,
    labelColor?: string;
    labelFontSize?: string;
    translatedLabel?: boolean;
    alignLabel?: string;
    marginTop?: string;
    disableClick?: boolean;
    marginBottom?: string;
    displayCheckboxes?: boolean;
    customClassName? : string;
}

const LabeledCheckbox: FunctionComponent<LabeledCheckboxProps> = ({
    label,
    labelColor = "",
    labelFontSize = "12px",
    translatedLabel = true,
    alignLabel = FlexAttributValue.CENTER,
    marginTop = "0px",
    onChange,
    checked,
    disabled,
    disableClick = false,
    displayCheckboxes = true,
    customClassName,
    sx,
    ...rest
}) => {

    const onChangeWithTracker = (event: React.ChangeEvent<HTMLInputElement>, check: boolean) => {
        authUtils.refreshTokenExpirationDate();
        onChange && onChange(event, check);
    };

    const labeledCheckboxColor = useMemo(() => {
        if (disabled) {
            return checked ? paletteTheme.colors.SUCCESS : paletteTheme.colors.GRAY_LIGHT;
        } else {
            return checked ? paletteTheme.colors.PRIMARY : paletteTheme.colors.GRAY;
        }
    }, [disabled]);

    return (
        <FlexBox className={styles.wrap}>
            <FormControlLabel
                className={`${displayCheckboxes ? "" : styles["hidden-checkboxes"]} ${customClassName ? styles[customClassName] : ""}` }
                disabled={disabled}
                onClick={(e) => disableClick && e.preventDefault()}
                sx={{
                    ...sx,
                    marginLeft: 0,
                    width: "100%",
                    margin: 0,
                    padding: "0 5px",
                }}
                control={
                    <Checkbox
                        {...rest}
                        sx={{
                            padding: 0,
                            color: labeledCheckboxColor,
                            "&.Mui-disabled": {
                                color: labeledCheckboxColor,
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: "18px"
                            },
                            display: displayCheckboxes ? "block" : "none",
                        }}
                        checked={checked}
                        checkedIcon={<CheckBoxOutlined />}
                        onChange={onChangeWithTracker}
                    />
                }
                label={
                    <Typography
                        color={labelColor || labeledCheckboxColor}
                        lineHeight={2}
                        marginLeft={0.3}
                        fontSize={labelFontSize}
                        marginTop={marginTop}>
                        {translatedLabel ? (<FormattedMessage id={label} />) : label}
                    </Typography>
                }
            />
        </FlexBox>
    )
}

export default LabeledCheckbox;
