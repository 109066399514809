import { WelcomeEmailCell } from "./../../components/atoms/table/cells/user/WelcomeEmailCell";
import { LevelCell } from "components/atoms/table/cells/user/LevelCell";
import { AccountNameCell } from "components/atoms/table/cells/user/AccountNameCell";
import { ManagableColumn } from "interfaces/table/table";
import { UserPossibleColumns } from "constants/user/user";
import { ProfileCell } from "components/atoms/table/cells/user/ProfileCell";

export const USER_POSSIBLE_COLUMNS: Record<UserPossibleColumns, ManagableColumn> = {
  ACCOUNT_EMAIL: {
    id: "ACCOUNT_EMAIL",
    header: "account_email",
    accessor: "email",
    order: 0,
    cell: {
      type: "text",
    },
  },
  FIRST_NAME: {
    id: "FIRST_NAME",
    header: "first_name",
    accessor: "firstname",
    order: 1,
    cell: {
      type: "capitalize",
    },
    options: {
      styles: { textTransform: "capitalize" },
    },
  },
  LAST_NAME: {
    id: "LAST_NAME",
    header: "last_name",
    accessor: "lastname",
    order: 2,
    cell: {
      type: "text",
    },
    options: {
      styles: {
        textTransform: "uppercase",
      },
    },
  },
  ACCOUNT_NAME: {
    id: "ACCOUNT_NAME",
    header: "users_acc_col",
    accessor: "accountName",
    order: 3,
    disableSortBy: true,
    cell: {
      component: AccountNameCell,
    },
    options: {
      width: 120,
    },
  },
  PERIMETER_LEVEL: {
    id: "PERIMETER_LEVEL",
    header: "level_col",
    accessor: "perimeterLevel",
    order: 2,
    disableSortBy: true,
    cell: {
      component: LevelCell,
    },
  },
  //user
  ACCOUNT_TYPE: {
    id: "ACCOUNT_TYPE",
    header: "account_type",
    accessor: "profile",
    order: 5,
    cell: {
      component: ProfileCell,
    },
  },
  //---
  CREATION_DATE: {
    id: "CREATION_DATE",
    header: "creation_date",
    accessor: "createdAt",
    order: 6,
    cell: {
      type: "date",
    },
  },
  LAST_ACTIVITY: {
    id: "LAST_ACTIVITY",
    header: "last_activity",
    accessor: "lastConnection",
    order: 7,
    cell: {
      type: "transformDate",
    },
  },
  //admin
  WELCOME_EMAIL: {
    id: "WELCOME_EMAIL",
    header: "welcome_email_header",
    accessor: "initialized",
    order: 8,

    cell: {
      component: WelcomeEmailCell,
    },
    options: {
      maxWidth: 45,
    },
  },
};
