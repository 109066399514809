import React, {FunctionComponent} from "react";
import ContainedButton from "./ContainedButton";
import {FormattedMessage} from "react-intl";
import {Typography} from "@mui/material";
import {ButtonProps} from "./Button";
import FlexBox from "../flex/FlexBox";
import {MarkEmailReadOutlined} from "@mui/icons-material";
import {FlexAttributValue} from "../../../types/flex/flex";
import {MaterialUiColor} from "../../../types/materialui/materialui";

interface ButtonOneShotProps extends ButtonProps {
    labelClicked?: string,
    label?: string,
    isClicked?: boolean,
    startIconMargin?: string
}

const ButtonOneShot: FunctionComponent<ButtonOneShotProps> = ({
                                                                  labelClicked = null,
                                                                  label = null,
                                                                  isClicked = false,
                                                                  startIconMargin = null,
                                                                  onClick,
                                                                  ...rest
                                                              }) => {

    const onClickButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        onClick(event);
    }

    return (isClicked ?
        <FlexBox alignItems={FlexAttributValue.CENTER}>
            <MarkEmailReadOutlined color={MaterialUiColor.PRIMARY} fontSize="small" sx={{fontSize: 14}}/>
            {labelClicked &&
                <Typography color={MaterialUiColor.PRIMARY} marginX={1}><FormattedMessage
                    id={labelClicked}/></Typography>
            }
        </FlexBox> :
        <ContainedButton
            {...rest}
            sx={{
                minWidth: "40px",
                ...(startIconMargin && {
                    "& .MuiButton-startIcon": {
                        margin: startIconMargin
                    }
                })
            }}
            onClick={onClickButton}>
            {label && <FormattedMessage id={label}/>}
        </ContainedButton>)
}


export default ButtonOneShot;
