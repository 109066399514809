import React, {
  FunctionComponent,
  useState,
} from "react";
import {
  FormControl,
  SelectProps as SelectPropsMaterialUI,
} from "@mui/material";
import { Entity } from "interfaces/perimeter/perimeter";
import { arrayUtils } from "utils/common/arrayUtils";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useDebouncedInput } from "hooks/input/useDebouncedInput";
import { paletteTheme } from "styles/themes/palette";
import ChipOptionRender from "components/atoms/select/ChipOptionRender";
import SearchChipsMenuList from "components/atoms/select/SearchChipsMenuList";
import Select from "components/atoms/select/Select";
import { REFERENTIAL_STATUS } from "constants/referential/referential";

interface SelectProps extends SelectPropsMaterialUI<Array<Entity>> {
  disableOnUnique?: boolean;
  options: Array<Entity>;
  value: Array<Entity>;
  onChangeValue: (values: Array<Entity>) => void;
}

export const SelectChips: FunctionComponent<SelectProps> = ({
  id,
  sx = { width: "100%" },
  value,
  options,
  onChangeValue,
  ...rest
}) => {

  const [filter, setFilter] = useState<string>("");

  let remainingOptions = [...options];
  remainingOptions = remainingOptions.filter(option => !value.some(item => item.id === option.id))

  const filteredOptions = filter !== "" && filter.length > 2 ? remainingOptions.filter(option => option.name.toLowerCase().includes(filter.toLowerCase())) : remainingOptions;

  const onRemoveChips = (removedValue: Entity) => {
    onChangeValue(value.filter(option => option.id !== removedValue.id));
  }

  const onAddAll = () => {
    setFilter("")
    onChangeValue(arrayUtils.arrayOfUnique([...filteredOptions.filter(e => e.status === REFERENTIAL_STATUS.ACTIVE.id), ...value]));
  }

  const onChangeFilter = useDebouncedInput((_value: string) => setFilter(_value), "").onChange

  const onAddChips = (newValue: Entity) => {
    const entity = remainingOptions.find(item => item.id === newValue.id);
    if (entity && entity.status === REFERENTIAL_STATUS.ACTIVE.id) {
      onChangeValue(arrayUtils.arrayOfUnique([...value, newValue]))
    }
  }

  return (
    <FormControl sx={sx}>
      <Select
        {...rest}
        labelId={id}
        value={value}
        multiple
        IconComponent={(props) => <KeyboardArrowDown fontSize="small" {...props} />}
        sx={{
          height: "auto",
          "& .MuiSelect-icon": { top: "3px" },
          "& .MuiSelect-select.MuiSelect-outlined": {
            minHeight: "24px",
            padding: 0
          }
        }}
        renderValue={(selected: Entity[]) => <ChipOptionRender selected={selected} onRemove={onRemoveChips} />}
        onChange={e => e.stopPropagation()}
        onClose={() => setFilter("")}
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            style: { width: "257px", maxHeight: "none", minHeight: "24px" },
            elevation: 0,
            sx: {
              borderRadius: "0 0 2px 2px",
              border: `1px solid ${paletteTheme.colors.GRAY}`,
            }
          },
          MenuListProps: {
            sx: {
              height: "auto",
              minHeight: "24px",
              position: "relative",
              borderRadius: "0 0 2px 2px",
              padding: 0,
              margin: 0,
              boxShadow: "none",
            },
          },
        }}
      >
        <SearchChipsMenuList
          options={filteredOptions}
          onChange={onChangeFilter}
          onAdd={onAddChips}
          onAddAll={onAddAll}
        />
      </Select>
    </FormControl>);
}
