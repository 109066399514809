import React, { useState } from "react";
import { useAuthUser } from "hooks/useAuthUser";
import { useAutocompleteSearchFilter } from "hooks/filter/useAutocompleteSearchFilter";
import { useOnChangeFilter } from "hooks/filter/useOnChangeFilter";
import { useFilter } from "hooks/filter/useFilter";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import { filterUtils } from "utils/business/filterUtils";
import { rightUtils } from "utils/business/rightUtils";
import {
  AutocompleteCheckboxConfig,
  DatePickerConfig,
  QueryAndResetConfig,
} from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { FilterValues } from "interfaces/filter/filter";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import { initSavedTopBottomFilter } from "utils/business/portfolioUtils";
import { Range } from "react-date-range";

export const useTopBottomFilters = () => {
  const { user, userDetails } = useAuthUser();
  const isAdminOrGroupPerimeter = rightUtils.isAdminOrGroupPerimeter(user);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const savedInitialFilters: FilterValues = JSON.parse(localStorage.getItem(LocalStorageEnum.GLOBAL_PERIMETER_FILTERS));
  
  const {
    perimeterOptions,
    onChangePerimeterOptions,
    initialPerimeterOptions: initialOptions,
    filters,
    onChangeFilters,
    onSearchFilter,
    onResetFilter,
    showContent,
    tableRef,
  } = useFilter<FilterValues>(
    userDetails,
    filterUtils.getTopBottomFilters(),
    [LocalStorageEnum.GLOBAL_PERIMETER_FILTERS],
    initSavedTopBottomFilter(savedInitialFilters),
  );

  const perimeterConfig = {
    isAdminOrGroupPerimeter,
    filters: filters,
    initialOptions,
    perimeterOptions,
    onChangeFilters,
    onChangePerimeterOptions,
  };

  const { onChangeAccountsSelection, onChangeCompaniesSelection, onChangeMerchantsSelection } = useOnChangeFilter(
    filters,
    onChangeFilters
  );

  const {
    onChangeAccountName,
    onChangeCompanyName,
    onChangeMerchantName,
    handleAccountsSelection,
    handleCompaniesSelection,
  } = useAutocompleteSearchFilter(perimeterOptions, onChangePerimeterOptions, filters);

  const autoCompleteCheckboxConfig: AutocompleteCheckboxConfig = {
    onChangeAccountsSelection,
    onChangeCompaniesSelection,
    onChangeMerchantsSelection,
    onChangeAccountName,
    onChangeCompanyName,
    onChangeMerchantName,
    handleAccountsSelection,
    handleCompaniesSelection,
  };

  const onChangeDateFilter = (acceptationRange: Range) => {
      onChangeFilters({ ...filters, acceptationRange });
  };

  const datePickerConfig: DatePickerConfig = {
    // TODO: remove fixed value
    isAdmin: true,
    filters,
    onChangeAcceptationDate: onChangeDateFilter
  };

  const queryAndResetConfig: QueryAndResetConfig = {
    disabledWithPopup: !perimeterUtils.hasPerimeterFilters(filters),
    labelErrorOnDisable: "filter_search_button_toaster",
  };

  const onReset = () => {
    onChangeFilters(filterUtils.getTopBottomFilters());
    onResetFilter();
  };

  const onSearch = () => {
    onSearchFilter();
  };

  const sharedFilterProps = {
    onSearch,
    onReset,
    errors,
  };

  const additionalFilterConfig = {
    sharedFilterProps,
    perimeterConfig,
    autoCompleteCheckboxConfig,
    datePickerConfig,
    queryAndResetConfig,
  };

  return {
    additionalFilterConfig,
    filters,
    showContent,
    tableRef,
    sharedFilterProps,
  };
};
