import { Params } from "interfaces/api/ApiInterfaces"
import { authUtils } from "utils/api/authUtils";
import { ContentTypeEnum } from "types/file";
import { LOGIN_PATH } from "constants/routes/RoutePaths";
import { API_ROUTE } from "constants/routes/ApiRoutes";
import { Token } from "interfaces/auth/Auth";
import { LocalStorageEnum } from "constants/localstorage/localstortage";

function handleResponseError(response: Response, data) {
  const error = data || response.statusText
  return Promise.reject(error)
}

/***
 * Handle response from endpoints
 * When you get a http error 401, you're automaticly disconnect from front side.
 * @param response
 */
function handleResponse<T>(response: Response): Promise<T> {
  return response.text().then((text: string) => {
    let data;
    try {
      data = text && JSON.parse(text)
    } catch (e) {
      data = {
        status: response.status,
        error: response.statusText,
        message: response.statusText,
      }
    }

    if (response.status === 401) {
      authUtils.cleanSession();
      window.location.href = LOGIN_PATH;
    }
    
    if (response.status === 409) {
      const error = {
        status: response.status,
        body: data,
      }
      return Promise.reject(error);
    }

    if (!response.ok) {
      return handleResponseError(response, data)
    }

    return data as Promise<T>
  })
}

function get<T>(url: string, options?: RequestInit): Promise<T> {
  const token: Token = JSON.parse(localStorage.getItem(LocalStorageEnum.TOKEN));
  let formattedOptions: RequestInit = options;

  if (!options) {
    formattedOptions = {}
  }

  const requestOptions: RequestInit = {
    ...formattedOptions,
    method: "GET",
    headers: {
      Accept: ContentTypeEnum.JSON,
      Authorization: `Bearer ${token.token}`
    },
  }

  return fetch(API_ROUTE + url, requestOptions).then((response) => {
    return handleResponse<T>(response)
  })
}

function getAsAnonymous<T>(url: string, options?: RequestInit): Promise<T> {
  let formattedOptions: RequestInit = options;

  if (!options) {
    formattedOptions = {}
  }

  const requestOptions: RequestInit = {
    ...formattedOptions,
    method: "GET",
    headers: { Accept: ContentTypeEnum.JSON }
  }

  return fetch(API_ROUTE + url, requestOptions).then((response) => {
    return handleResponse<T>(response)
  })
}

function submit<T>(
  url: string,
  body: Params | null,
  method: string,
  auth: boolean,
  options?: RequestInit
): Promise<T> {

  const headers = { "Content-Type": `${ContentTypeEnum.JSON};` }
  let formattedOptions: RequestInit = options;

  if (auth) {
    const token: Token = JSON.parse(localStorage.getItem(LocalStorageEnum.TOKEN));
    headers["Authorization"] = `Bearer ${token.token}`
  }

  if (!options) {
    formattedOptions = {}
  }

  const requestOptions: RequestInit = {
    ...formattedOptions,
    method: method,
    headers,
    body: body && JSON.stringify(body)

  }
  return fetch(API_ROUTE + url, requestOptions).then((response) =>
    handleResponse<T>(response)
  )
}

function submitAsConnected<T>(
  url: string,
  body: Params | null,
  method: string,
  options?: RequestInit
): Promise<T> {
  return submit(url, body, method, true, options)
}

function submitAsAnonymous<T>(
  url: string,
  body: Params | null,
  method: string,
  options?: RequestInit
): Promise<T> {
  return submit(url, body, method, false, options)
}


function postAsAnonymous<T>(url: string, body: Params | null, options?: RequestInit): Promise<T> {
  return submitAsAnonymous(url, body, "POST", options)
}

function post<T>(url: string, body: Params | null, options?: RequestInit): Promise<T> {
  return submitAsConnected(url, body, "POST", options)
}

function put<T>(url: string, body: Params, options?: RequestInit): Promise<T> {
  return submitAsConnected(url, body, "PUT", options)
}

function patch<T>(url: string, body: Params, options?: RequestInit): Promise<T> {
  return submitAsConnected(url, body, "PATCH", options)
}

function postFormData<T>(url: string, body: FormData): Promise<T> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: body,
  }
  return fetch(API_ROUTE + url, requestOptions).then((response) =>
    handleResponse<T>(response)
  )
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete<T>(url: string, body: Params | null, options?: RequestInit): Promise<T> {
  return submitAsConnected(url, body, "DELETE", options)
}


function fetchReadableStream(url: string, options: RequestInit): Promise<Blob> {
  return fetch(API_ROUTE + url, options)
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response.json());
      }
      return response.blob();
    })
}


function getReadableStream(url: string, options?: RequestInit): Promise<Blob> {

  const headers = { "Content-Type": `${ContentTypeEnum.JSON};` }
  const token: Token = JSON.parse(localStorage.getItem(LocalStorageEnum.TOKEN));
  headers["Authorization"] = `Bearer ${token.token}`
  const requestOptions: RequestInit = {
    ...options,
    headers,
    method: "GET",
  }

  return fetchReadableStream(url, requestOptions)
}

export interface BlobWithFilename {
  blob: Promise<Blob>;
  filename?: string;
}

function fetchReadableStreamWithFilename(url: string, options: RequestInit): Promise<BlobWithFilename> {
  return fetch(API_ROUTE + url, options)
    .then((response) => {
      if (!response.ok) {
        throw response.json();
      }
      // Extract filename from header
      const filename = extractFilenameFromContentDisposition(response.headers.get("content-disposition"));
      const blob = response.blob();
      return {
        filename: filename,
        blob: blob,
      };
    })
}

function extractFilenameFromContentDisposition(input?: string): string | undefined {
  if (!input) {
    return undefined;
  }
  const filenamePart = input.split(";")
    .find(n => n.includes("filename="));

  if (!filenamePart) {
    return undefined;
  }

  const filename = filenamePart.replace("filename=", "").trim();
  return filename.length === 0 ? undefined : filename;

}

function getReadableStreamWithFilename(url: string, options?: RequestInit): Promise<BlobWithFilename> {

  const headers = { "Content-Type": `${ContentTypeEnum.JSON};` }
  const token: Token = JSON.parse(localStorage.getItem(LocalStorageEnum.TOKEN));
  headers["Authorization"] = `Bearer ${token.token}`
  const requestOptions: RequestInit = {
    ...options,
    headers,
    method: "GET",
  }

  return fetchReadableStreamWithFilename(url, requestOptions)
}

function postReadableStream(url: string, body: BodyInit, options?: RequestInit): Promise<Blob> {

  const requestOptions: RequestInit = {
    ...options,
    method: "POST",
    body
  }

  return fetchReadableStream(url, requestOptions)
}

export const httpService = {
  get,
  getAsAnonymous,
  post,
  postAsAnonymous,
  postFormData,
  put,
  patch,
  getReadableStream,
  getReadableStreamWithFilename,
  postReadableStream,
  delete: _delete,
}
