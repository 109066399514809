function passwordFieldHasErrors(errors: Record<string, boolean>): boolean {
    return errors.PASSWORD_REQUIRED || errors.PASSWORD_LOWERCASE || errors.PASSWORD_UPPERCASE || errors.PASSWORD_NUMBER || errors.PASSWORD_SPECIAL_CHARACTER
}

function passwordConfirmFieldHasErrors(errors: Record<string, boolean>): boolean {
    return errors.CONFIRM_PASSWORD_REQUIRED || errors.UNMATCHED_PASSWORDS
}
export const passwordErrorUtils = {
    passwordFieldHasErrors,
    passwordConfirmFieldHasErrors
}
