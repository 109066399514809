import {createTheme, Theme} from "@mui/material";
import {typography} from "./typography";
import {paletteTheme} from "./palette";
import {components} from "./components/component";

export const MP_THEME: Theme = createTheme({
    palette: paletteTheme.palette,
    typography,
    components
})
