import { stringUtils } from "utils/common/stringUtils";

export type ValidateError = boolean | Record<string, boolean>;

export interface ValidateErrorMap {
    [key: string]: ValidateError
}

function validateReportName(errorCode: string): ValidateErrorMap {
    let errors = {};
    if (errorCode === "IS_DUPLICATED") {
        errors = { SR_ERROR_EXISTING_NAME: true }
    }

    if (errorCode === "TOO_SHORT") {
        errors = { SR_ERROR_NAME_TOO_SHORT: true }
    }
    if (Object.keys(errors).length > 0) {
        return errors;
    }

    return {};
}

function validateFrequency(name: string): ValidateErrorMap {
    let errors = {};
    if (stringUtils.isEmpty(name)) {
        errors = { SR_ERROR_EMPTY_FREQUENCY: true }
    }

    if (Object.keys(errors).length > 0) {
        return errors;
    }

    return {};
}


export const validationErrorsScheduleReportUtils = {
    validateReportName,
    validateFrequency,
}
