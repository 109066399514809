import React, { FunctionComponent, ReactNode, useRef } from "react";
import { useIntl } from "react-intl";
import { intlUtils } from "utils/intlUtils";
import { formatToEuro } from "constants/intl/number/format";
import { IBaseCellProps } from "interfaces/table/table";
import { activityUtils } from "utils/business/activityUtils";
import { PortfolioTrendsData } from "components/molecules/portfolio/PortfolioTrendsSection";
import TooltipOverflowText from "components/molecules/tooltip/TooltipOverflowText/TooltipOverflowText";
export const TableCell: FunctionComponent<IBaseCellProps> = ({
  value,
  style,
  type,
  defaultValue,
  isTooltipCell,
  tooltipCell,
  row,
}) => {
  const intl = useIntl();
  let formattedValue = value;
  switch (type) {
    case "number":
      formattedValue = value ? intlUtils.formatNumber(intl, parseFloat(value)) : defaultValue ?? "";
      break;
    case "money":
      if (value === "0") {
        formattedValue = intlUtils.formatNumber(intl, 0, formatToEuro);
      } else {
        formattedValue = value ? intlUtils.formatNumber(intl, parseFloat(value), formatToEuro) : defaultValue ?? "";
      }
      break;
    case "date":
      formattedValue = value ? intl.formatDate(value) : defaultValue ?? "";
      break;
    case "dateTime":
      formattedValue = value ? intl.formatTime(value) : defaultValue ?? "";
      break;
    case "capitalize":
      formattedValue = value?.toLowerCase();
      break;
    case "transformDate":
      const activity = activityUtils.formatDateLastActivity(value ? new Date(value) : null);
      formattedValue = intl.formatMessage({ id: activity.interval.label }, { interval: activity.number });
      break;
    case "percent":
      formattedValue = value ? (+value).toFixed(2) + " %" : defaultValue ?? "";
      break;
    default:
  }

  let tooltipLabel: string | ReactNode = formattedValue;
  if (isTooltipCell && tooltipCell) {
    tooltipLabel = tooltipCell && tooltipCell((row as { original: PortfolioTrendsData }).original);
  }
  const elRef = useRef<HTMLDivElement>();

  return (
    <div style={{ height: "100%", ...style }} className="flex flex-col-center">
      <TooltipOverflowText
        textElementRef={elRef}
        text={tooltipLabel || formattedValue}
        isShowAlways={!!isTooltipCell}
        resizeClass="resizer"
      >
        <div ref={elRef} style={{ cursor: isTooltipCell ? "help" : "default" }} className="ellipsis ">
          {formattedValue}
        </div>
      </TooltipOverflowText>
    </div>
  );
};
