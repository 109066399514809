/***
 * Return false if array is defined and not empty
 * @param array => Any array
 */
import {Options} from "../../constants/options/option";

function isEmpty(array: Array<unknown>): boolean {
    return array === null || array === undefined || array.length === 0
}

function hasUniqueElement(array?: Array<unknown>): boolean {
    return array?.length === 1
}

function arrayOfUnique<T extends  Options>(array: Array<T>): Array<T> {
    return [...array.reduce((map, obj) => map.set(obj.id, obj), new Map<string,T>()).values()]
}

function arrayOfUniqueAny<T>(array: Array<T>): Array<T> {
    return [...new Set(array)]
}

function  pickNoneEmptyArray<T>(array1?: Array<T>,array2?: Array<T>): Array<T> {
    return !isEmpty(array1) ?  array1 : array2;
}

const hasAllElements = (o1: Array<string>, o2: Array<string>) => {
    return o1.length === o2.length && o1.every(x => o2.includes(x))
}

const hasAllElementsWithEquality = <T>(
    arr1: readonly T[], 
    arr2: readonly T[], 
    eqFn:(a:T, b:T) => boolean
):boolean => {
    arr1loop: for(const arr1item of arr1){
        for(const arr2item of arr2){
            if(eqFn(arr1item, arr2item)){
                continue arr1loop;
            }
        }
        return false;
    }
    return true;
}

const hasSameContent = <T>(
    arr1: readonly T[], 
    arr2: readonly T[], 
    eqFn:(a:T, b:T) => boolean,
):boolean => {
    return arr1.length === arr2.length 
        && hasAllElementsWithEquality(arr1, arr2, eqFn)
        && hasAllElementsWithEquality(arr2, arr1, eqFn);
}

/**
 * 
 * @param arr array to search in
 * @param item item to find
 * @param eqFn equality function
 * @returns index of item with equality function of -1 if nothing found
 */
const indexOf = <T>(
    arr:readonly T[],
    item:T,
    eqFn: (a:T, b:T) => boolean,
) => {
    return arr.findIndex((current) => eqFn(item, current));
}

const removeAtIndex = <T>(
    arr: readonly T[],
    index: number,
): readonly T[] => {
    return [...arr.slice(0, index), ... arr.slice(index+1)];
}

const append = <T>(
    arr: readonly T[],
    item: T,
): readonly T[] => {
    return [...arr, item];
}

export const arrayUtils = {
    isEmpty,
    arrayOfUnique,
    arrayOfUniqueAny,
    pickNoneEmptyArray,
    hasAllElements,
    hasAllElementsWithEquality,
    hasSameContent,
    hasUniqueElement,
    removeAtIndex,
    append,
    indexOf,
}
