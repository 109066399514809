import React, {
  FunctionComponent,
  useState,
} from "react";
import { CardRow } from "components/atoms/card/CardRow";
import { AutocompleteSelect } from "components/molecules/autocomplete/AutocompleteSelect";
import { Switch } from "components/atoms/switch/Switch";
import {
  Entity,
  Perimeter,
  PerimeterUpdate,
} from "interfaces/perimeter/perimeter";
import { Options } from "constants/options/option";
import { useIntl } from "react-intl";
import ErrorMessage from "components/atoms/ErrorMessage";
import { PERIMETER_ERROR } from "constants/errors/perimeter";
import { ValidateErrorMap } from "components/molecules/useraccount/edit/UserAccountEdit";

interface UserAccountPerimeterSwitchOptionsProps {
  accountOptions: Entity[];
  onChangeQueryAccount: (query: string) => void;
  editedPerimeter: PerimeterUpdate;
  initialPerimeter: Perimeter;
  allCompaniesAdded: boolean;
  hasAccountPerimeter: boolean;
  authorizedCompaniesCount: number;
  onAddAllCompanies: (id: string) => void;
  onRemoveAddAllCompanies: () => void;
  onChangeAccount: (Option: Options[]) => void;
  onChangeAccountTypePerimeter: (e, checked) => void;
  validationError?: ValidateErrorMap;
}

export const UserAccountPerimeterAccountSelect: FunctionComponent<UserAccountPerimeterSwitchOptionsProps> = ({
  onChangeQueryAccount: onChangeQueryAccountProps,
  accountOptions,
  editedPerimeter,
  initialPerimeter,
  allCompaniesAdded,
  onAddAllCompanies,
  authorizedCompaniesCount,
  hasAccountPerimeter,
  onRemoveAddAllCompanies,
  onChangeAccount,
  onChangeAccountTypePerimeter,
  validationError = {},
}) => {

  const intl = useIntl();

  const [queryAccount, setQueryAccount] = useState<string>("");

  const onChangeQueryAccount = (_queryAccount: string) => {
    setQueryAccount(_queryAccount)
    if (_queryAccount === "" || (!initialPerimeter.account.id && !!_queryAccount && _queryAccount.length >= 3)) {
      onChangeQueryAccountProps(_queryAccount)
    }
  }
  return (
    <div>
      <CardRow
        marginBottom={1}
        id="peri_account"
        label="peri_account"
        customContentClassName="autoselect"
      >
        <AutocompleteSelect
          values={[editedPerimeter.account]}
          options={accountOptions}
          placeholder={intl.formatMessage({ id: "perim_creat_account" })}
          onChange={onChangeAccount}
          query={queryAccount}
          onChangeQuery={onChangeQueryAccount}
          uniqueElement
          disable={!!initialPerimeter.account.id}
          hasError={validationError.REQUIRED_ACCOUNT}
        />
        <div style={{ width: "100%" }}>
          {validationError.REQUIRED_ACCOUNT && <ErrorMessage error={PERIMETER_ERROR.REQUIRED_ACCOUNT.id} errors={validationError} />}
        </div>
      </CardRow>
      {authorizedCompaniesCount >= 1 &&
        <CardRow
          marginBottom={0.5}
          marginLeft={2}
          id="account_add_all_companies"
          label="account_add_all_companies"
          customLabelClassName="sub-label"
          customContentClassName="switch"
        >
          <Switch checked={allCompaniesAdded}
            onChange={(_e, checked) => checked ? onAddAllCompanies(editedPerimeter.account.id) : onRemoveAddAllCompanies()} />
        </CardRow>
      }
      {hasAccountPerimeter && allCompaniesAdded &&
        <CardRow
          marginBottom={0.5}
          marginLeft={2}
          fontWeight={400}
          id="inherit_future_companies"
          label="inherit_future_companies"
          customLabelClassName="sub-label"
          customContentClassName="switch"
        >
          <Switch checked={editedPerimeter.hasAccountPerimeter}
            onChange={onChangeAccountTypePerimeter} inputProps={{ "aria-label": "controlled" }} />
        </CardRow>
      }
    </div>
  );
}
