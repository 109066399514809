import React, { FunctionComponent, useState } from "react";
import { BoxProps } from "@mui/material";
import { Entity, UpdateEntity } from "interfaces/perimeter/perimeter";
import { CardRow } from "components/atoms/card/CardRow";
import { CancelRounded } from "@mui/icons-material";
import { paletteTheme } from "styles/themes/palette";
import { UserAccountMerchantPerimeterEdit } from "./UserAccountMerchantPerimeterEdit";
import { MaterialUiColor } from "types/materialui/materialui";
import ErrorMessage from "../../../atoms/ErrorMessage";
import { PERIMETER_ERROR } from "constants/errors/perimeter";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import IconTooltip from "../../tooltip/IconToolTip";
import { AuthorizedMerchantsResponse } from "interfaces/perimeter/merchant";
import { CompanyUpdate } from "interfaces/perimeter/company";
import { AutocompleteSelect } from "components/molecules/autocomplete/AutocompleteSelect";
import { useIntl } from "react-intl";
import { Options } from "constants/options/option";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface AccountCompanyPerimeterEditProps extends BoxProps {
  company: CompanyUpdate,
  remainingCompanies: Array<Entity>,
  defaultMerchants?: Array<Entity>
  authorizedMerchants: AuthorizedMerchantsResponse,
  onChangeCompany: (option: Options) => void,
  onChangeMerchant: (values: Entity[], hasAllMerchants: boolean) => void,
  onFutureMerchantChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  onRemove: (company: UpdateEntity) => void,
  canDelete?: boolean,
  allFutureCompaniesChecked?: boolean,
  validationError?: Record<string, boolean>
}

export const UserAccountCompanyPerimeterEdit: FunctionComponent<AccountCompanyPerimeterEditProps> = ({
  company,
  onChangeCompany,
  canDelete = true,
  onChangeMerchant,
  onFutureMerchantChange,
  defaultMerchants = [],
  authorizedMerchants,
  remainingCompanies,
  onRemove,
  validationError = {},
  allFutureCompaniesChecked,
}) => {

  const { smUp } = useMediaQueryUtil();
  const [queryCompany, setQueryCompany] = useState<string>("");

  const onChange = (_options: Options[]) => {
    onChangeCompany(_options?.[0]);
  }

  const intl = useIntl();
  const isExistingPerimeter = !perimeterUtils.isEmptyCompany(company);
  const formattedMerchants = company.merchants.map(item => ({
    ...item,
    hasRightOn: authorizedMerchants.merchants.some(i => i.id === item.id)
  }));

  const formattedDefaultMerchants = defaultMerchants.map(item => ({
    ...item,
    hasRightOn: true
  }));

  const canDeleteCompany = canDelete && formattedMerchants.every(merchant => !!merchant.hasRightOn);
  const displayError = validationError.REQUIRED_COMPANY && !isExistingPerimeter;

  // Front filter for companies, only display 30 first elements
  const filteredRemainingCompanies = remainingCompanies.filter(item => queryCompany.length < 3 ||
    queryCompany === "" ||
    item.name.toLowerCase().includes(queryCompany.toLowerCase())).slice(0, 50);

  return (<>
    <CardRow
      id={`company_name_${company.company.id}`}
      label="peri_company"
      customContentClassName="autoselect"
    >
      <AutocompleteSelect
        values={[company.company]}
        options={isExistingPerimeter ? [...filteredRemainingCompanies, company.company] : [...filteredRemainingCompanies]}
        placeholder={intl.formatMessage({ id: "perim_creat_company" })}
        onChange={onChange}
        query={queryCompany}
        onChangeQuery={setQueryCompany}
        uniqueElement
        disable={isExistingPerimeter && !formattedMerchants.every(merchant => !!merchant.hasRightOn)}
        hasError={displayError}
      />
      {
        canDeleteCompany ?
          <div style={{ position: "absolute", right: smUp ? "-25px" : "-20px", top: "1px" }}>
            <IconTooltip title="account_tooltip_remove">
              <CancelRounded
                sx={{
                  marginLeft: "4px",
                  color: paletteTheme.colors.DANGER_LIGHT,
                  ":hover": { color: paletteTheme.colors.DANGER }
                }}
                fontSize="small"
                cursor="pointer"
                color={MaterialUiColor.ERROR}
                onClick={() => onRemove(company.company)} />
            </IconTooltip>
          </div>
          :
          <></>
      }
      {displayError &&
        <div style={{ width: "100%" }}>
          <ErrorMessage error={PERIMETER_ERROR.REQUIRED_COMPANY.id} errors={validationError} />
        </div>
      }
    </CardRow>
    <UserAccountMerchantPerimeterEdit
      validationError={validationError}
      merchants={formattedMerchants}
      defaultMerchants={formattedDefaultMerchants}
      authorizedMerchants={authorizedMerchants}
      onChangeMerchant={onChangeMerchant}
      hasCompanyPerimeter={company.hasCompanyPerimeter}
      onFutureMerchantChange={onFutureMerchantChange}
      allFutureCompaniesChecked={allFutureCompaniesChecked}
    />
  </>);
}
