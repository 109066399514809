import React, { FunctionComponent } from "react";
import { RangeKeyDict } from "react-date-range";
import { dateUtils } from "utils/common/dateUtils";
import { 
  EMPTY_DATE_INITIAL_STATE, 
  INITIAL_RANGE_LAST_WEEK, 
  INITIAL_RANGE_TODAY, 
} from "constants/datepicker/datepicker";
import {
  TRANSACTION_ADDITIONAL_FILTERS,
} from "constants/transaction/transactionFilter";
import { DateRangePickerInput, DateRangePickerInputProps } from "components/molecules/datepicker/DateRangePickerInput";
import { DatePickerConfig } from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { TransactionType } from "interfaces/filter/filterColumn";

interface DateRangePickerInputWrapperProps {
  name: string;
  datePickerConfig: DatePickerConfig;
  type: TransactionType | "PortfolioTopBottom";
}

interface Config extends DateRangePickerInputProps {
  hideItem?: boolean;
}

interface IDatePickerConfig {
  [key: string]: Config;
}

export const DateRangePickerInputWrapper: FunctionComponent<DateRangePickerInputWrapperProps> = ({
  datePickerConfig: {
    isAdmin,
    filters,
    transactionAdditionalFilters,
    onChangeDateFilter,
    onChangeOperationDate,
    onChangeSettlementDate,
    onChangeAcceptationDate,
  },
  name,
  type,
}) => {

  const { formatMessage } = useIntlFormatter();
  const today = dateUtils.formatToLocale2Digit(new Date());

  const resetSettlementDateFilter = () =>
    onChangeOperationDate(
      type === TransactionType.ACCEPTED && !isAdmin ? INITIAL_RANGE_TODAY : EMPTY_DATE_INITIAL_STATE
    );

  const changeSettlementDateFilter = (settlementRange: RangeKeyDict) =>
    type === TransactionType.ACQUIRED
      ? onChangeSettlementDate(settlementRange["selection"])
      : onChangeDateFilter(TRANSACTION_ADDITIONAL_FILTERS.SETTLEMENT_DATE.id)(settlementRange["selection"]);

  const resetOperationDateFilter = () =>
    onChangeOperationDate(
      type === TransactionType.ACCEPTED && !isAdmin ? INITIAL_RANGE_TODAY : EMPTY_DATE_INITIAL_STATE
    );

  const changeOperationDateFilter = (accTrxOperationRange: RangeKeyDict) =>
    onChangeOperationDate(accTrxOperationRange["selection"]);

  const resetAcceptationDateFilter = () =>
    onChangeAcceptationDate(INITIAL_RANGE_LAST_WEEK);

  const changeAcceptationDateFilter = (acceptationRange: RangeKeyDict) =>
    onChangeAcceptationDate(acceptationRange["selection"]);

  const DatePickerConfig: IDatePickerConfig = {
    [TRANSACTION_ADDITIONAL_FILTERS.SETTLEMENT_DATE.id]: {
      id: TRANSACTION_ADDITIONAL_FILTERS.SETTLEMENT_DATE.id,
      range:
        type === TransactionType.ACCEPTED
          ? transactionAdditionalFilters.financingDate
          : filters.settlementDate,
      label: formatMessage(TRANSACTION_ADDITIONAL_FILTERS.SETTLEMENT_DATE.label),
      onReset: resetSettlementDateFilter,
      onChange: changeSettlementDateFilter,
      initialStartDate: type === TransactionType.ACQUIRED ? today : "",
      initialEndDate: type === TransactionType.ACQUIRED ? today : "",
    },
    [TRANSACTION_ADDITIONAL_FILTERS.TRANSACTION_DATE.id]: {
      id: TRANSACTION_ADDITIONAL_FILTERS.TRANSACTION_DATE.id,
      range: filters.operationDate,
      label: formatMessage(TRANSACTION_ADDITIONAL_FILTERS.TRANSACTION_DATE.label),
      onReset: resetOperationDateFilter,
      onChange: changeOperationDateFilter,
      initialStartDate: type === TransactionType.ACCEPTED && !isAdmin ? today : "",
      initialEndDate: type === TransactionType.ACCEPTED && !isAdmin ? today : "",
    },
    ["PortfolioTopBottom"]: {
      id: "PortfolioTopBottom",
      range: filters?.acceptationRange ?? 
      INITIAL_RANGE_LAST_WEEK,
      label: formatMessage(TRANSACTION_ADDITIONAL_FILTERS.TRANSACTION_DATE.label),
      onReset: resetAcceptationDateFilter,
      onChange: changeAcceptationDateFilter,
      initialStartDate: "",
      initialEndDate: "",
    },
    DEFAULT: {
      id: undefined,
      range: {},
      initialStartDate: undefined,
      initialEndDate: undefined,
      onChange: undefined,
      onReset: undefined,
      hideItem: true,
    },
  };

  const config = DatePickerConfig[name] || DatePickerConfig.DEFAULT;

  return <>{!config?.hideItem && <DateRangePickerInput {...config} />}</>;
};
