import {ValidationError} from "../api/errorUtils";
import {patternUtils} from "../common/patternUtils";
import {PerimeterUpdate} from "../../interfaces/perimeter/perimeter";
import {ValidateErrorMap} from "../../components/molecules/useraccount/edit/UserAccountEdit";
import {stringUtils} from "../common/stringUtils";

function cleanAndUpdate(errors: Array<ValidationError>): Record<string, boolean> {
    const newErrors = {};
    errors.forEach(error => newErrors[error.code] = true)
    return newErrors;
}

function add(currentErrors: Record<string, boolean>, errors: Array<ValidationError>) {
    const newErrors = {...currentErrors};
    errors.forEach(error => newErrors[error.code] = true)
    return newErrors;
}

function remove(currentErrors: Record<string, boolean>, error: ValidationError) {
    const newErrors = {...currentErrors};
    delete newErrors[error.code];
    return newErrors;
}



function validateEmailForm(email: string) {
    if (stringUtils.isEmpty(email)) {
        return {EMAIL_REQUIRED: true}
    }

    const errorEmail = !patternUtils.checkEmail(email);
    if (errorEmail) {
        return {EMAIL_WRONG_FORMAT: true}
    }
    return null;
}

function validatePasswordLoginForm(password: string): Record<string, boolean> {
    let errors: Record<string, boolean> = {};
    if (stringUtils.isEmpty(password)) {
        errors = {PASSWORD_REQUIRED: true}
    }
    return errors;
}

function validateLoginForm(email: string, password: string): Record<string, boolean> {
    return {
        ...validateEmailForm(email),
        ...validatePasswordLoginForm(password)
    };
}

/**
 * Validate User informations
 * @param firstName
 * @param lastName
 * @param email
 */
function validateUserPersInfoForm(firstName: string, lastName: string, email: string): ValidateErrorMap {
    let errors = {};
    if (stringUtils.isEmpty(firstName)) {
        errors = {FIRSTNAME_REQUIRED: true}
    } else if (!patternUtils.checkName(firstName)) {
        errors = {FIRSTNAME_WRONG_FORMAT: true}
    }

    if (stringUtils.isEmpty(lastName)) {
        errors = {...errors, LASTNAME_REQUIRED: true}
    } else if (!patternUtils.checkName(lastName)) {
        errors = {...errors, LASTNAME_WRONG_FORMAT: true}
    }

    if (stringUtils.isEmpty(email)) {
        errors = {...errors, EMAIL_REQUIRED: true}
    } else if (!patternUtils.checkEmail(email)) {
        errors = {...errors, EMAIL_WRONG_FORMAT: true}
    }

    if (Object.keys(errors).length > 0) {
        return errors;
    }

    return {};
}

function validatePerimeterForm(perimeter: PerimeterUpdate): ValidateErrorMap {

    if (!perimeter) {
        return {};
    }

    if (!perimeter?.account.name) {
        return {REQUIRED_ACCOUNT: true}
    }

    let errors = {};

    perimeter.companies.forEach(company => {
        let e = {};
        if (company.company.id === "") {
            e = {REQUIRED_COMPANY: true}
        }

        if (company.merchants && company.merchants.length === 0) {
            e = {...e, REQUIRED_MERCHANTS: true}
        }
        if (Object.keys(e).length > 0) {
            errors = {...errors, [company.company.uniqueKey]: e}
        }
    })

    return errors;
}


function checkPasswordHasLowerCase(password: string): boolean {
    return password.toUpperCase() !== password;
}

function checkPasswordHasUpperCase(password: string): boolean {
    return password.toLowerCase() !== password;
}

function checkPasswordHasNumber(password: string): boolean {
    return /\d/.test(password);
}

function validatePasswordsForm(password: string, confirmPassword: string): Record<string, boolean> {
    let errors = {};
    if (stringUtils.isEmpty(password)) {
        errors = {PASSWORD_REQUIRED: true}
    }
    if (stringUtils.isEmpty(confirmPassword)) {
        errors = {...errors, CONFIRM_PASSWORD_REQUIRED: true}
    } else if (password !== confirmPassword) {
        errors = {...errors, UNMATCHED_PASSWORDS: true}
    }

    if (password.length < 8) {
        errors = {...errors, PASSWORD_MIN_LENGTH: true}
    }

    const errorLowerCase = !checkPasswordHasLowerCase(password);
    if (errorLowerCase) {
        errors = {...errors, PASSWORD_LOWERCASE: true}
    }

    const errorUpperCase = !checkPasswordHasUpperCase(password);
    if (errorUpperCase) {
        errors = {...errors, PASSWORD_UPPERCASE: true}
    }

    const errorNumber = !checkPasswordHasNumber(password);
    if (errorNumber) {
        errors = {...errors, PASSWORD_NUMBER: true}
    }

    const errorSpecialChar = !patternUtils.checkPasswordHasSpecial(password);
    if (errorSpecialChar) {
        errors = {...errors, PASSWORD_SPECIAL_CHARACTER: true}
    }

    if (Object.keys(errors).length > 0) {
        return errors;
    }

    return null;
}

/**
 * Validate User information
 * @param min
 * @param max
 */
function validateAmountMinMax(min: number, max: number): Record<string, boolean> {
    let errors = {};
    if (!stringUtils.isEmpty(min) && !stringUtils.isEmpty(max) && min > max) {
        errors = {...errors, MIN_SUP_MAX: true}
    }
    if (!stringUtils.isEmpty(max) && 0 > max) {
        errors = {...errors, NEGATIVE_VALUE: true}
    }

    if (!stringUtils.isEmpty(min) && 0 > min) {
        errors = {...errors, NEGATIVE_VALUE: true}
    }

    if (Object.keys(errors).length > 0) {
        return errors;
    }

    return {};
}


export const validationErrorsUtils = {
    cleanAndUpdate,
    add,
    remove,
    validateLoginForm,
    validateEmailForm,
    validatePasswordsForm,
    validatePasswordLoginForm,
    validateUserPersInfoForm,
    validatePerimeterForm,
    validateAmountMinMax
}
