import React, {FunctionComponent} from "react";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {PopoverProps} from "@mui/material";
import Notification from "../Notification";
import {FormattedMessage, useIntl} from "react-intl";
import {useNotificationContext} from "../../../../constants/context/NotificationContext";
import {NotificationResponse} from "../../../../interfaces/notification/notification";
import styles from "./popover.module.scss";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface NotificationPopoverProps extends PopoverProps {
    hasUserActiveNotifications: boolean,
    userActiveNotificationsCount: number
}

const NotificationPopover: FunctionComponent<NotificationPopoverProps> = ({
                                                                              open,
                                                                              onClose,
                                                                              anchorEl,
                                                                              hasUserActiveNotifications,
                                                                              userActiveNotificationsCount
                                                                          }) => {

    const intl = useIntl();
    const formatUserCountMessage = () => {
        return userActiveNotificationsCount > 0 ? intl.formatMessage({id: "new_notif"}, {count: userActiveNotificationsCount}) :
            intl.formatMessage({id: "no_new_notif"});
    }
    const {smDown} = useMediaQueryUtil();
    
    const marginBottom = "10px";

    const {
        userActiveNotifications,
        userHiddenNotifications,
        hasUserHiddenNotifications,
        onChangePage,
        last,
        isLoading,
    } = useNotificationContext();

    /**
     * Trigger data fetching when reaching the bottom of the popup
     */
    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const {scrollHeight, scrollTop, clientHeight} = e.currentTarget;
        const scrolledToBottom = scrollHeight - Math.ceil(scrollTop) === clientHeight;
        if (scrolledToBottom && !last && !isLoading) {
            onChangePage();
        }
    }

    const buildNotifs = (notifs: Array<NotificationResponse>) =>
        notifs.map(notif => (
            <Notification
                key={notif.id}
                notification={notif}
                marginBottom={marginBottom}>
               
            </Notification>
        ))
         
            
    return (
        <Popover
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            transformOrigin={{vertical: "top", horizontal: "right"}}
            classes={{root: styles.popover, paper: `${styles.paper} ${smDown ? styles.paperMobile : ""}`}}
            PaperProps={{
                onScroll: (e) => handleScroll(e),
            }}>
            <Typography variant="body2" classes={{body2:styles.heading}}>
                {formatUserCountMessage()}
            </Typography>
            {hasUserActiveNotifications &&
                <div>
                    {buildNotifs(userActiveNotifications)}
                </div>
            }
            { hasUserHiddenNotifications &&
                <div>
                    {hasUserActiveNotifications &&
                        <>
                            <Typography classes={{body1: styles.message}}>
                                <FormattedMessage id="notif_viewed"></FormattedMessage>
                            </Typography>
                            <Divider classes={{root: styles.divider}}/>
                        </>
                    }
                    {buildNotifs(userHiddenNotifications)}
                </div>
            }
            {isLoading &&
                <div
                    className={`flex flex-col flex-center ${styles.wrapCircular}`}
                >
                    <CircularProgress disableShrink style={{width: "15px", height: "15px"}}/>
                </div>
            }
        </Popover>
    )
}

export default NotificationPopover;
