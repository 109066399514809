const MORE_THAN_2_YEARS_AGO = 63072000
const MORE_THAN_1_MONTH = 2592000
const MORE_THAN_1_DAY = 86400


interface LastActivity {
    number?: number
    interval: Activity
}

interface Activity {
    label: string,
    seconds?: number,
}

const INTERVALS: Activity[] = [
    {label: "activity_years_ago", seconds: MORE_THAN_2_YEARS_AGO},
    {label: "activity_month_ago", seconds: MORE_THAN_1_MONTH},
    {label: "activity_day_ago", seconds: MORE_THAN_1_DAY},
]

function computeInterval(seconds: number, temp: number): number {
    return Math.floor(seconds / temp)
}

function isDateToday(date: Date) {
    const today = new Date();
    return date.getUTCDate() === today.getUTCDate() && date.getUTCMonth() === today.getUTCMonth() && date.getUTCFullYear() === today.getUTCFullYear()
}

function isDateYesterday(date: Date) {
    const today = new Date();
    return date.getUTCDate() === today.getUTCDate() - 1 && date.getUTCMonth() === today.getUTCMonth() && date.getUTCFullYear() === today.getUTCFullYear()
}

function formatDateLastActivity(date: Date): LastActivity {

    const today = new Date();
    if (!date || date.getTime() > today.getTime()) {
        return {interval: {label: "activity_never"}}
    }
    if (isDateToday(date)) {
        return {interval: {label: "activity_today"}}
    }

    if (isDateYesterday(date)) {
        return {interval: {label: "activity_yesterday"}}
    }

    const seconds = Math.floor((today.getTime() - date.getTime()) / 1000);
    const foundInterval: Activity = INTERVALS.find(interval => computeInterval(seconds, interval.seconds) >= 1) || {label: "activity_today"};
    return {number: computeInterval(seconds, foundInterval.seconds), interval: foundInterval}
}


export const activityUtils = {
    formatDateLastActivity,
}
