import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { RouteProps, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { LOGIN_ERRORS } from "constants/errors/login";
import { RESET_PASSWORD_CONFIRM_PATH } from "constants/routes/RoutePaths";
import { MaterialUiColor } from "types/materialui/materialui";
import { errorUtils, PromisableApiError } from "utils/api/errorUtils";
import { validationErrorsUtils } from "utils/business/validationErrorsUtils";
import { toastUtils } from "utils/toast/toastUtils";
import { resetPasswordTokenService } from "services/resetPasswordToken/ResetPasswordTokenService";
import { LoginTextInput } from "components/atoms/input/LoginTextInput";
import ContainedExternButton from "components/atoms/button/ContainedExterneButton";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import styles from "view/password/password.module.scss";

export const ResetPasswordView: FunctionComponent<RouteProps> = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const [disabled, setDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { smDown } = useMediaQueryUtil();

    const onChange = (setter: (value: string) => void) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        setDisabled(false)
        setter(event.target.value)
    }

    const onSubmitEmail = () => {
        setDisabled(true)
        const validationError = validationErrorsUtils.validateEmailForm(email);
        if (validationError) {
            setErrors(validationError)
        } else {
            setLoading(true)
            resetPasswordTokenService.createToken(email)
                .then(response => {
                    setLoading(false)
                    if (response.userInitialized === true) {
                        navigate(RESET_PASSWORD_CONFIRM_PATH, { state: { email } })
                    } else {
                        toastUtils.infoToastI8ln("pw_resend_init_password_mail");
                    }
                }).catch((e: PromisableApiError) => {
                    setLoading(false)
                    errorUtils.handleBackErrors(e)
                    errorUtils.handleErrors(e, (apiError) => setErrors(validationErrorsUtils.cleanAndUpdate(apiError.errors)))
                })
        }
    };

    return (
        <Stack className={styles["stack-auth-layout"]}>
            <div className={"flex flex-col flex-col-center"} >
                <Typography variant="h1" color={MaterialUiColor.PRIMARY} textAlign="center">
                    <FormattedMessage id="pw_recovery_title" />
                </Typography>
            </div>
            <div className={"flex flex-col flex-col-center"} >
                <Typography variant="h4" fontWeight={300} textAlign="center">
                    <FormattedMessage id="reset_password_text" />
                </Typography>
            </div>
            <div>
                <LoginTextInput
                    id="email"
                    required
                    placeholder="email@email.com"
                    type="email"
                    label="email"
                    error={errors.EMAIL_WRONG_FORMAT || errors.EMAIL_REQUIRED}
                    onChange={onChange(setEmail)}
                    inputProps={{ maxLength: 99 }}
                    LabelSx={{ fontSize: 14, fontWeight: 300 }}
                    errors={errors}
                    possibleErrors={[LOGIN_ERRORS.EMAIL_REQUIRED, LOGIN_ERRORS.EMAIL_WRONG_FORMAT, LOGIN_ERRORS.EMAIL_TOO_LONG]}
                />
            </div>
            <div className={"flex flex-col flex-col-center"}>
                <ContainedExternButton
                    disabled={disabled}
                    type="button"
                    loading={loading}
                    onClick={onSubmitEmail}
                    style={{ width: smDown ? "100%" : "auto", height: smDown ? "40px" : "32px" }}
                >
                    <FormattedMessage id="submit_button" />
                </ContainedExternButton>
            </div>
        </Stack>
    )
}
