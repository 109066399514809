import React, { FunctionComponent } from "react";
import { CardRow } from "components/atoms/card/CardRow";
import { PROFIL_USER } from "constants/user/user";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { UserDetails } from "interfaces/user/user";

interface ProfileDisplayProps {
  user: UserDetails;
  marginBottom?: number;
}

export const ProfileDisplay: FunctionComponent<ProfileDisplayProps> = ({
  user,
  marginBottom = 3,
}) => (
  <CardRow id="account_type" label="account_type" marginBottom={marginBottom}>

    {user && PROFIL_USER[user.profile] &&
      <Typography>
        <FormattedMessage id={PROFIL_USER[user.profile].label} />
      </Typography>
    }
  </CardRow>
)
