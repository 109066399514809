import {createContext, useContext} from "react";
import {User, UserDetails} from "../../interfaces/user/user";

interface UserContextInterface {
    user: User,
    userDetails: UserDetails,
    permissions: Array<string>,
    setPermissions: (permissions: Array<string>) => void,
    setUser: (user: User) => void,
    refreshUserDetails: () => void
}

export const UserContext = createContext<UserContextInterface>({
    user: null,
    permissions: [],
    userDetails: null,
    setPermissions: () => false,
    setUser: () => false,
    refreshUserDetails: () => false,
});

export const useUserContext = () => useContext(UserContext);
