import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { ManagableColumn } from "interfaces/table/table";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { computeColumns, getTrendsColumns } from "utils/table/tableUtils";
import TrendsTable from "components/atoms/table/TrendsTable";
import { getPortfolioTrends } from "services/portfolio/PortfolioService";
import { GenericSearchData } from "interfaces/table/TableInterface";
import { Typography } from "@mui/material";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { UserDetails } from "interfaces/user/user";
import Card from "components/atoms/card/Card";
import { Options } from "constants/options/option";
import { usePortfolioTableFetch } from "hooks/portfolio/usePortfolioTableFetch";
import { FilterLayout } from "components/layouts/FilterLayout";
import { useVolumeTrendFilters } from "hooks/portfolio/useVolumeTrendFilters";
import { useVolumeTrendsFilterConfig } from "hooks/portfolio/useVolumeTrendsFilterConfig";
import { PORTFOLIO_VOLUME_TRENDS_BY } from "constants/transaction/transactionFilter";

export interface PortfolioResponse<T> {
  items: Array<T>;
}

export interface PortfolioTooltip {
  tooltipDay1Amount: number;
  tooltipDay1Date: string;
  tooltipDay1Volume: number;
  tooltipDay2Amount: number;
  tooltipDay2Date: string;
  tooltipDay2Volume: number;
  tooltipMonth1Amount: number;
  tooltipMonth1End: string;
  tooltipMonth1Start: string;
  tooltipMonth1Volume: number;
  tooltipMonth2Amount: number;
  tooltipMonth2End: string;
  tooltipMonth2Start: string;
  tooltipMonth2Volume: number;
  tooltipWeek1Amount: number;
  tooltipWeek1End: string;
  tooltipWeek1Start: string;
  tooltipWeek1Volume: number;
  tooltipWeek2Amount: number;
  tooltipWeek2End: string;
  tooltipWeek2Start: string;
  tooltipWeek2Volume: number;
}
export interface PortfolioTrendsItem extends PortfolioTooltip {
  accountId: number;
  accountName: string;
  amountEvolutionDay: number;
  amountEvolutionMonth: number;
  amountEvolutionWeek: number;
  companyId: number;
  companyName: string;
  merchantId: number;
  merchantName: string;
  paymentSolution: string;
  rateAmountEvolutionDay: number;
  rateAmountEvolutionMonth: number;
  rateAmountEvolutionWeek: number;
  rateVolumeEvolutionDay: number;
  rateVolumeEvolutionMonth: number;
  rateVolumeEvolutionWeek: number;
  uuid: string;
  volumeEvolutionDay: number;
  volumeEvolutionMonth: number;
  volumeEvolutionWeek: number;
}

export interface PortfolioTrendsData {
  id: string;
  merchant: string;
  RATE_AMOUNT_EVOLUTION_DAY: number;
  RATE_AMOUNT_EVOLUTION_WEEK: number;
  RATE_AMOUNT_EVOLUTION_MONTH: number;
  RATE_VOLUME_EVOLUTION_DAY: number;
  RATE_VOLUME_EVOLUTION_WEEK: number;
  RATE_VOLUME_EVOLUTION_MONTH: number;
  tooltipData: PortfolioTooltip;
}

interface PortfolioTrendsSectionProps {
  userDetails: UserDetails;
}

export const PortfolioTrendsSection: FunctionComponent<PortfolioTrendsSectionProps> = () => {
  const intl = useIntl();

  const { smDown, mdDown, lgDown, lgUp } = useMediaQueryUtil();
  const [ratioType, setRatioType] = useState<Options>(PORTFOLIO_VOLUME_TRENDS_BY[0]);
  const [initialColumns, setInitialColumns] = useState<Array<ManagableColumn>>(getTrendsColumns("lg", true));
  const { formatMessage } = useIntlFormatter();

  const isAmountRatioMode = (value: Options) => {
    return value.id === "PORTF_FILTER_GROSS";
  };

  useEffect(() => {
    if (smDown) {
      setInitialColumns(getTrendsColumns("xs", isAmountRatioMode(ratioType)));
      return;
    }
    if (mdDown) {
      setInitialColumns(getTrendsColumns("sm", isAmountRatioMode(ratioType)));
      return;
    }

    setInitialColumns(getTrendsColumns("lg", isAmountRatioMode(ratioType)));
  }, [smDown, mdDown, lgDown, lgUp, ratioType]);

  const responseToData = (
    response: PortfolioResponse<PortfolioTrendsItem>,
  ): GenericSearchData<PortfolioTrendsData> => {
    return {
      datas: response.items.map((item, idx) => {
        return {
          id: (idx + 1).toString(),
          merchant: item.merchantName,
          RATE_AMOUNT_EVOLUTION_DAY: item.rateAmountEvolutionDay,
          RATE_AMOUNT_EVOLUTION_WEEK: item.rateAmountEvolutionWeek,
          RATE_AMOUNT_EVOLUTION_MONTH: item.rateAmountEvolutionMonth,
          RATE_VOLUME_EVOLUTION_DAY: item.rateVolumeEvolutionDay,
          RATE_VOLUME_EVOLUTION_WEEK: item.rateVolumeEvolutionWeek,
          RATE_VOLUME_EVOLUTION_MONTH: item.rateVolumeEvolutionMonth,
          tooltipData: {
            tooltipDay1Amount: item.tooltipDay1Amount,
            tooltipDay1Date: item.tooltipDay1Date,
            tooltipDay1Volume: item.tooltipDay1Volume,
            tooltipDay2Amount: item.tooltipDay2Amount,
            tooltipDay2Date: item.tooltipDay2Date,
            tooltipDay2Volume: item.tooltipDay2Volume,
            tooltipMonth1Amount: item.tooltipMonth1Amount,
            tooltipMonth1End: item.tooltipMonth1End,
            tooltipMonth1Start: item.tooltipMonth1Start,
            tooltipMonth1Volume: item.tooltipMonth1Volume,
            tooltipMonth2Amount: item.tooltipMonth2Amount,
            tooltipMonth2End: item.tooltipMonth2End,
            tooltipMonth2Start: item.tooltipMonth2Start,
            tooltipMonth2Volume: item.tooltipMonth2Volume,
            tooltipWeek1Amount: item.tooltipWeek1Amount,
            tooltipWeek1End: item.tooltipWeek1End,
            tooltipWeek1Start: item.tooltipWeek1Start,
            tooltipWeek1Volume: item.tooltipWeek1Volume,
            tooltipWeek2Amount: item.tooltipWeek2Amount,
            tooltipWeek2End: item.tooltipWeek2End,
            tooltipWeek2Start: item.tooltipWeek2Start,
            tooltipWeek2Volume: item.tooltipWeek2Volume,
          },
        };
      }),
      last: true,
      totalElements: response.items?.length,
    };
  };

  const columns = useMemo(() => {
    const defaulStyles = {
      width: smDown ? 70 : 90,
      minWidth: 70,
      maxWidth: 300,
    };
    return [...computeColumns(initialColumns, intl, defaulStyles)];
  }, [initialColumns, intl.locale]);

  const { additionalFilterConfig, filters: trendFilters, showContent, tableRef } = useVolumeTrendFilters();

  const { filters: mainFilters } = useVolumeTrendsFilterConfig(additionalFilterConfig);

  const { onFetchData } = usePortfolioTableFetch<PortfolioResponse<PortfolioTrendsItem>, PortfolioTrendsData>(
    getPortfolioTrends(trendFilters),
    responseToData
  );

  useEffect(() => {
    setRatioType(trendFilters.trendBy[0]);
  }, [trendFilters.trendBy]);
  
  return (
    <Card>
      <div style={{ marginBottom: "40px" }}>
        <Typography variant="h2" marginBottom="20px">
          {formatMessage("filter_by")}
        </Typography>
        <FilterLayout mainFilters={mainFilters} />
      </div>

      {showContent && (
        <div style={{ margin: "0 -20px" }}>
          <TrendsTable
            ref={tableRef}
            columns={columns}
            onFetch={onFetchData}
            isAmountRatioMode={isAmountRatioMode(ratioType)}
          />
        </div>
      )}
      {!showContent && (
        <div
          className="flex-col flex-col-center"
          style={{ marginTop: "30px", marginBottom: "30px", display: showContent ? "none" : "flex" }}
        >
          <Typography variant="h2">{formatMessage("admin_trans_view_title")}</Typography>
          <Typography>{formatMessage("admin_trans_view_text")}</Typography>
        </div>
      )}
    </Card>
  );
};
