import React, {FunctionComponent} from "react";
import WrappedDisableClickComponent from "../WrappedDisableClickComponent";
import LabeledCheckbox, {LabeledCheckboxProps} from "./LabeledCheckbox";

interface ManageColumnLabeledCheckboxProps extends LabeledCheckboxProps {
    labelErrorOnDisable: string,
    disabled: boolean,
}

const ManageColumnLabeledCheckbox: FunctionComponent<ManageColumnLabeledCheckboxProps> = ({
                                                                                              disabled,
                                                                                              labelErrorOnDisable,
                                                                                              ...rest
                                                                                          }) =>
    (<WrappedDisableClickComponent
        labelErrorOnDisable={labelErrorOnDisable}
        disabled={disabled}>
        <LabeledCheckbox
            {...rest}
            disabled={disabled}
        />
    </WrappedDisableClickComponent>)


export default ManageColumnLabeledCheckbox;
