import {httpService} from "../HttpService";
import {RESET_PASSWORD_TOKEN_ROUTE, RESET_PASSWORD_ROUTE} from "../../constants/routes/ApiRoutes";

interface CreateTokenResponse {
    userInitialized: boolean
}

/**
 * Create a reset password token
 * This token is available for 30 minutes
 * The URL is sent by email
 * @param email
 */
function createToken(email: string): Promise<CreateTokenResponse> {
    return httpService.postAsAnonymous(RESET_PASSWORD_TOKEN_ROUTE, {email})
}

/**
 * Update your password based on your token
 * @param token
 * @param password
 * @param confirmPassword
 */
function updatePassword(token: string, password: string, confirmPassword: string): Promise<void> {
    return httpService.postAsAnonymous(`${RESET_PASSWORD_ROUTE}/${token}`, {password, confirmPassword})
}

export const resetPasswordTokenService = {
    createToken,
    updatePassword
}
