import React from "react"
import { FileCopy } from "@mui/icons-material";

export interface ExportReferential {
  id: string,
  ext: string,
  Icon: JSX.Element
}

export const CONTENT_TYPE_EXPORT: Record<string, ExportReferential> = {
  CSV: { id: "CSV", ext: ".csv", Icon: <FileCopy fontSize="small" /> },
  EXCEL: { id: "EXCEL", ext: ".xls", Icon: <FileCopy fontSize="small" /> },
  PDF: { id: "PDF", ext: ".pdf", Icon: <FileCopy fontSize="small" /> },
}

export const CONTENT_TYPE_EXPORT_TRANSACTION: Record<string, ExportReferential> = {
  [CONTENT_TYPE_EXPORT.CSV.id]: { ...CONTENT_TYPE_EXPORT.CSV },
  [CONTENT_TYPE_EXPORT.EXCEL.id]: { ...CONTENT_TYPE_EXPORT.EXCEL },
}

export const CONTENT_TYPE_EXPORT_SETTLEMENT: Record<string, ExportReferential> = {
  [CONTENT_TYPE_EXPORT.CSV.id]: { ...CONTENT_TYPE_EXPORT.CSV },
  [CONTENT_TYPE_EXPORT.PDF.id]: { ...CONTENT_TYPE_EXPORT.PDF },
}

export const CONTENT_TYPE_EXPORT_USER: Record<string, ExportReferential> = {
  [CONTENT_TYPE_EXPORT.CSV.id]: { ...CONTENT_TYPE_EXPORT.CSV },
}

export const CONTENT_TYPE_EXPORT_REPORT: Record<string, ExportReferential> = {
  [CONTENT_TYPE_EXPORT.CSV.id]: { ...CONTENT_TYPE_EXPORT.CSV },
}

export const CONTENT_TYPE_EXPORT_BILLING: Record<string, ExportReferential> = {
  [CONTENT_TYPE_EXPORT.CSV.id]: { ...CONTENT_TYPE_EXPORT.CSV },
}