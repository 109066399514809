import React, { useState } from "react";
import { Range } from "react-date-range";
import { FilterDateValues, FilterValues, RedirectFilters } from "interfaces/filter/filter";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import { EMPTY_DATE_INITIAL_STATE } from "constants/datepicker/datepicker";
import {
  INITIAL_ACCEPTED_TRANSACTION_FILTER,
  INITIAL_ACCEPTED_TRANSACTION_FILTER_ADMIN,
  INITIAL_ACQUIRED_TRANSACTION_FILTER,
  TRANSACTION_ACCEPTED_FILTERS_INITIAL_VALUES,
  TRANSACTION_ACQUIRED_FILTERS_INITIAL_VALUES,
} from "constants/transaction/transactionFilter";
import { useAuthUser } from "hooks/useAuthUser";
import { useTransactionAdditionalFilter } from "hooks/filter/useTransactionAdditionalFilter";
import { useAutocompleteSearchFilter } from "hooks/filter/useAutocompleteSearchFilter";
import { useOnChangeFilter } from "hooks/filter/useOnChangeFilter";
import { useFilter } from "hooks/filter/useFilter";
import { transactionUtils } from "utils/business/transactionUtils";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import { filterUtils } from "utils/business/filterUtils";
import { rightUtils } from "utils/business/rightUtils";
import {
  AmountRangeConfig,
  AutocompleteCheckboxConfig,
  DatePickerConfig,
  ExpandCollapseFiltersConfig,
  MultichoiceConfig,
  QueryAndResetConfig,
  TextFieldConfig,
} from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { TransactionType } from "interfaces/filter/filterColumn";
import { useLocation } from "react-router-dom";

export const useTransactionFilters = (transactionsType: TransactionType) => {
  const location = useLocation();
  const redirectFilters = location.state as RedirectFilters;
  const { user, userDetails } = useAuthUser();
  const isAdmin = rightUtils.isAdmin(user);
  const isAdminOrGroupPerimeter = rightUtils.isAdminOrGroupPerimeter(user);

  const initialOperationOptions = (): Range => {
    if (transactionsType === TransactionType.ACQUIRED) {
      return isAdminOrGroupPerimeter
        ? INITIAL_ACCEPTED_TRANSACTION_FILTER_ADMIN.operationDate
        : INITIAL_ACCEPTED_TRANSACTION_FILTER.operationDate;
    } else {
      return EMPTY_DATE_INITIAL_STATE;
    }
  };

  const initialSettlementOptions = (): Range => {
    if (transactionsType === TransactionType.ACQUIRED) {
      return INITIAL_ACQUIRED_TRANSACTION_FILTER.settlementDate;
    } else {
      return undefined;
    }
  };

  const savedInitialTransactionFilters = (redirectFilters: RedirectFilters): FilterValues | undefined => {
    let values: FilterValues = undefined;

    const global = JSON.parse(localStorage.getItem(LocalStorageEnum.GLOBAL_PERIMETER_FILTERS)) as FilterValues;
    if (redirectFilters) {
      return {
        ...global,
        settlementDate: redirectFilters.settlementDate,
        operationDate: redirectFilters.operationDate,
      };
    }

    const acquired = JSON.parse(
      localStorage.getItem(LocalStorageEnum.ACQUIRED_TRANSACTIONS_FILTERS)
    ) as FilterDateValues;
    const accepted = JSON.parse(localStorage.getItem(LocalStorageEnum.ACCEPTED_TRANSACTIONS_FILTERS)) as Range;

    if (transactionsType === TransactionType.ACCEPTED) {
      values = { ...global, operationDate: accepted };
    } else {
      values = { ...global, settlementDate: acquired?.settlementDate, operationDate: acquired?.operationDate };
    }
    return values;
  };

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [resetCounter, setResetCounter] = useState(0);
  /*
   * Counter used as key in the additional filters component
   * Incremented every time filters are reset to unmount/remount the component
   */
  const [resetFilterCount, setResetFilterCount] = useState<number>(0);

  const {
    perimeterOptions,
    onChangePerimeterOptions,
    initialPerimeterOptions: initialOptions,
    filters: transactionFilters,
    onChangeFilters,
    onSearchFilter,
    onResetFilter,
    showContent,
    tableRef,
  } = useFilter<FilterValues>(
    userDetails,
    filterUtils.getTransactionInitialFilters(user, transactionsType),
    [
      LocalStorageEnum.GLOBAL_PERIMETER_FILTERS,
      transactionsType === TransactionType.ACCEPTED
        ? LocalStorageEnum.ACCEPTED_TRANSACTIONS_FILTERS
        : LocalStorageEnum.ACQUIRED_TRANSACTIONS_FILTERS,
    ],
    transactionUtils.initSavedTransactionFilter(user, transactionsType, savedInitialTransactionFilters(redirectFilters))
  );

  const { onChangeAccountsSelection, onChangeCompaniesSelection, onChangeMerchantsSelection } = useOnChangeFilter(
    transactionFilters,
    onChangeFilters
  );

  const {
    onChangeAccountName,
    onChangeCompanyName,
    onChangeMerchantName,
    handleAccountsSelection,
    handleCompaniesSelection,
  } = useAutocompleteSearchFilter(perimeterOptions, onChangePerimeterOptions, transactionFilters);

  const {
    transactionAdditionalFilters,
    onChangeTransactionAdditionalFilters,
    onChangeMultichoiceFilter,
    onChangeAmountRangeFilter,
    onChangeDateFilter,
    onChangeAlphanumericFilter,
    onResetFilters: onResetAdditionalFilters,
    appliedFiltersCount,
    moreFiltersButtonState,
    onMoreLessFiltersClick,
  } = useTransactionAdditionalFilter(
    transactionsType === TransactionType.ACCEPTED
      ? TRANSACTION_ACCEPTED_FILTERS_INITIAL_VALUES
      : TRANSACTION_ACQUIRED_FILTERS_INITIAL_VALUES,
    transactionsType
  );

  const onChangeOperationDate = (operationDate: Range) => {
    onChangeFilters({ ...transactionFilters, operationDate });
  };

  const onChangeSettlementDate = (settlementDate: Range) => {
    if (transactionsType === TransactionType.ACQUIRED) {
      onChangeFilters({ ...transactionFilters, settlementDate });
    }
  };

  const onChangeFilterValue = (field: string) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChangeFilters({ ...transactionFilters, [field]: event.target.value });
  };

  const perimeterConfig = {
    isAdminOrGroupPerimeter,
    filters: transactionFilters,
    initialOptions,
    perimeterOptions,
    onChangeFilters,
    onChangePerimeterOptions,
  };

  const autoCompleteCheckboxConfig: AutocompleteCheckboxConfig = {
    onChangeAccountsSelection,
    onChangeCompaniesSelection,
    onChangeMerchantsSelection,
    onChangeAccountName,
    onChangeCompanyName,
    onChangeMerchantName,
    handleAccountsSelection,
    handleCompaniesSelection,
  };

  const datePickerConfig: DatePickerConfig = {
    isAdmin,
    filters: transactionFilters,
    transactionAdditionalFilters,
    onChangeDateFilter,
    onChangeOperationDate,
    onChangeSettlementDate,
  };

  const amountRangeConfig: AmountRangeConfig = {
    errors,
    setErrors,
    onChangeAmountRangeFilter,
    transactionAdditionalFilters,
  };

  const multiChoiceConfig: MultichoiceConfig = {
    transactionAdditionalFilters,
    onChangeMultichoiceFilter,
  };

  const textFieldConfig: TextFieldConfig = {
    filters: transactionFilters,
    transactionAdditionalFilters,
    onChangeAlphanumericFilter,
    onChangeFilterValue,
  };

  const expandCollapseFiltersConfig: ExpandCollapseFiltersConfig = {
    appliedFiltersCount,
    resetFilterCount,
    iconSize: 12,
    onMoreLessFiltersClick,
    moreFiltersButtonState,
  };

  const queryAndResetConfig: QueryAndResetConfig = {
    disabledWithPopup: !perimeterUtils.hasPerimeterFilters(transactionFilters),
    labelErrorOnDisable: "filter_search_button_toaster",
  };

  const onReset = () => {
    // On reset, recalculate initial options based on options/perimeters
    onChangeFilters(
      filterUtils.getInitialFiltersByPerimeter(perimeterOptions, initialOperationOptions(), initialSettlementOptions())
    );
    onResetFilter();
    onResetAdditionalFilters();
    setResetFilterCount(resetFilterCount + 1);
    setResetCounter((prevCounter) => prevCounter + 1);
  };

  const onSearch = () => {
    onSearchFilter();
  };

  const sharedFilterProps = {
    onSearch,
    onReset,
    errors,
  };

  const additionalFilterConfig = {
    perimeterConfig,
    datePickerConfig,
    amountRangeConfig,
    multiChoiceConfig,
    textFieldConfig,
    autoCompleteCheckboxConfig,
    expandCollapseFiltersConfig,
    queryAndResetConfig,
  };

  return {
    additionalFilterConfig,
    transactionFilters,
    transactionAdditionalFilters,
    showContent,
    tableRef,
    sharedFilterProps,
    onChangeFilters,
    onChangeTransactionAdditionalFilters,
    resetCounter,
  };
};
