import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from "react";
import { RouteProps, useNavigate } from "react-router-dom";
import { Row } from "react-table";
import { FormattedMessage, useIntl } from "react-intl";
// INTERFACES
import { KeyNumbers, TransactionData, TransactionRow } from "interfaces/transaction/transaction";
import { ManagableColumn } from "interfaces/table/table";
import { TransactionSearchResponse } from "interfaces/api/ApiInterfaces";
import { GenericSearchData } from "interfaces/table/TableInterface";
// CONSTANTS
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import { ACCEPTED_TRANSACTIONS_PATH } from "constants/routes/RoutePaths";
import { TRANSACTION_KEY_NUMBERS } from "constants/transaction/transaction";
import { TRANSACTION_MANAGEABLE_COLUMNS } from "constants/transaction/transactionColumns";
import { CONTENT_TYPE_EXPORT_TRANSACTION } from "constants/export/export";
// HOOKS
import { useEffectAbortable } from "hooks/useEffectAbortable";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { useExport } from "hooks/export/useExport";
import { useTransactionFilters } from "hooks/filter/useTransactionFilters";
import { useGenericTableFetch } from "hooks/table/useGenericTableFetch";
import { useScheduleReport } from "hooks/report/useScheduleReport";
import { useTransactionViews } from "hooks/filter/useTransactionViews";
// UTILS
import { transactionUtils } from "utils/business/transactionUtils";
import { errorUtils } from "utils/api/errorUtils";
import { computeColumns, getTransactionColumns } from "utils/table/tableUtils";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
// SERVICES
import { transactionService } from "services/transaction/TransactionService";
import { viewService } from "services/view/ViewService";
// COMPONENTS
import { DropdownButton } from "components/atoms/button/DropdownButton";
import ManyExportLineDialog from "components/molecules/dialog/ManyExportLineDialog";
import SelectView from "components/atoms/select/SelectView";
import DeleteViewDialog from "components/molecules/dialog/DeleteViewDialog";
import Table from "components/atoms/table/Table";
import ContainedButton from "components/atoms/button/ContainedButton";
import { FilterLayout } from "components/layouts/FilterLayout";
import { ScheduleReport } from "components/molecules/dialog/ScheduleReport";
import { KeyNumbersCards } from "components/molecules/keyNumbersCards";
import ManageColumnDialog from "components/molecules/dialog/ManageColumn/ManageColumnDialog";
import SaveViewDialog from "components/molecules/dialog/SaveView/SaveViewDialog";
import Card from "components/atoms/card/Card";
import Typography from "@mui/material/Typography";
import NoteAddOutlined from "@mui/icons-material/NoteAddOutlined";
// STYLES
import { paletteTheme } from "styles/themes/palette";
import styles from "view/TransactionView/AcceptedTransactionsView/acceptedTrransactionsView.module.scss";
import { useTransactionFilterConfig } from "hooks/filter/useTransactionFilterConfig";
import { TransactionType } from "interfaces/filter/filterColumn";
import {
  ACCEPTED_TRANSACTIONS_ADDITIONAL_FILTERS,
  ACCEPTED_TRANSACTIONS_MAIN_FILTERS,
} from "constants/transaction/transactionFilter";

export const AcceptedTransactionsView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const { formatMessage } = useIntlFormatter();

  const { smDown, smUp, mdDown, lgDown, lgUp } = useMediaQueryUtil();
  const [managedColumns, setManagedColumns] = useState<Array<ManagableColumn>>([]);
  const [totalCount, setTotalCount] = useState<number | string>(0);
  const [openScheduleReport, setOpenScheduleReport] = useState(false);
  const [openManageColumn, setOpenManageColumn] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);

  const localStorageManagedColumns: ManagableColumn[] = JSON.parse(
    localStorage.getItem(LocalStorageEnum.TRANSACTION_COLUMN)
  );

  const defaultTransactionKeyNumbers = useMemo(
    () => ({
      totalTransaction: 0,
      totalGrossAmount: 0,
      totalNetAmount: 0,
      totalRefunds: 0,
      totalComission: 0,
    }),
    []
  );

  const [keyNumbers, setKeyNumbers] = useState<KeyNumbers>(defaultTransactionKeyNumbers);

  const onChangeColumns = (_columns: Array<ManagableColumn>) => {
    const enabledColumn = _columns.filter((column) => !column.disableColumn);
    localStorage.setItem(LocalStorageEnum.TRANSACTION_COLUMN, JSON.stringify(enabledColumn));
    setManagedColumns(enabledColumn);
  };

  const {
    additionalFilterConfig,
    transactionFilters,
    transactionAdditionalFilters,
    showContent,
    tableRef,
    sharedFilterProps,
    onChangeFilters,
    onChangeTransactionAdditionalFilters,
    resetCounter,
  } = useTransactionFilters(TransactionType.ACCEPTED);

  const { mainFilters, additionalFilters } = useTransactionFilterConfig({
    typeTransaction: TransactionType.ACCEPTED,
    initialMainFilter: ACCEPTED_TRANSACTIONS_MAIN_FILTERS,
    initialAdditionalFilters: ACCEPTED_TRANSACTIONS_ADDITIONAL_FILTERS,
    configs: {
      sharedFilterProps,
      ...additionalFilterConfig,
    },
  });

  const {
    viewSelected,
    viewsOptions,
    openSaveView,
    openDeleteView,
    onChangeView,
    resetViewToDelete,
    handleDelete,
    onChangeViewsOptions,
    openDelete,
    closeDialogSave,
    closeDialogDelete,
    onChangeViewSelected,
    openDialogSave,
  } = useTransactionViews(onChangeFilters, onChangeTransactionAdditionalFilters, onChangeColumns);

  const navigate = useNavigate();

  useEffect(() => {
    if (smDown) {
      setManagedColumns(getTransactionColumns("xs"));
      setIsMobile(true);
      setOpenManageColumn(false);
      return;
    }
    if (smUp) {
      setIsMobile(false);
      setOpenManageColumn(false);
      return;
    }
    if (mdDown) {
      setManagedColumns(getTransactionColumns("sm"));
      setIsMobile(false);
      setOpenManageColumn(false);
      return;
    }
    if (lgDown) {
      setManagedColumns(getTransactionColumns());
      setIsMobile(false);
      return;
    }
    setManagedColumns(localStorageManagedColumns || getTransactionColumns("lg"));
  }, [smDown, smUp, mdDown, lgDown, lgUp]);

  // map response to line
  // Formating date/money should not be done here
  // We should have a cell type added to columns
  const responseToData = (response: TransactionSearchResponse<TransactionRow>): GenericSearchData<TransactionData> => {
    setKeyNumbers({ ...response.aggregation });
    return transactionUtils.responseToData(response, intl);
  };

  const { onFetchData, count } = useGenericTableFetch<TransactionRow, TransactionData>(
    transactionService.getTransactions(transactionFilters, transactionAdditionalFilters),
    responseToData
  );

  const { openManyExportLine, setOpenManyExportLine, isExportLoading, onDownload } = useExport(
    count,
    process.env.REACT_APP_MAX_TRANSACTION_EXPORT,
    (type: string) =>
      transactionService.downloadExportTransactions(transactionFilters, transactionAdditionalFilters, type),
    "export_transactions"
  );

  useEffectAbortable((ac) => {
    viewService
      .find({ signal: ac.signal })
      .then((res) => {
        onChangeViewsOptions(res.content.map((value) => ({ id: value.id, name: value.name })));
      })
      .catch(errorUtils.handleBackErrors);
  }, []);

  // Memoize columns for performance and enable intl to recompute rows when it changes
  // ManagedColumns is the real state of columns, computeColumns must only convert it to a proper format for react-table
  const columns = useMemo(() => {
    const defaulStyles = {
      width: 85,
      minWidth: 70,
      maxWidth: 300,
    };
    const enabledColumn = managedColumns.filter((column) => !column.disableColumn);
    return computeColumns(enabledColumn, intl, defaulStyles);
  }, [managedColumns, intl.locale]);

  useEffect(() => {
    setKeyNumbers(defaultTransactionKeyNumbers);
    resetViewToDelete();
  }, [resetCounter]);

  const handleOpenCloseScheduleReportDialog = () => {
    setOpenScheduleReport((prevState) => !prevState);
  };

  const { generateReportRequestFilters } = useScheduleReport();
  const reportFilters = generateReportRequestFilters(Object.assign(transactionFilters, transactionAdditionalFilters));

  return (
    <Card
      cardTitle={
        <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
          {formatMessage("acc_transactions_title")}
        </Typography>
      }>
      <div className={`flex flex-row-between flex-col-start ${styles["label-saved-view-wrap"]}`}>
        <Typography variant="h2" marginBottom="10px">
          <FormattedMessage id="filter_by" />
        </Typography>
        <SelectView
          options={viewsOptions}
          value={viewSelected}
          disabled={viewsOptions?.length === 0}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const options = viewsOptions.find((value) => event.target.value === value.id);
            onChangeViewSelected(options);
            if (options) {
              onChangeView(options.id);
            }
          }}
          handleDelete={openDelete}
        />
      </div>
      <FilterLayout
        mainFilters={mainFilters}
        additionalFilters={additionalFilters}
        expandCollapseFiltersConfig={additionalFilterConfig.expandCollapseFiltersConfig}
      />

      <KeyNumbersCards
        keyNumberElements={TRANSACTION_KEY_NUMBERS}
        keyNumbers={showContent ? keyNumbers : defaultTransactionKeyNumbers}
      />
      <div style={{ display: showContent ? "block" : "none" }}>
        <div className={`flex flex-row-between flex-col-end ${styles["wrap-man-button"]}`}>
          <Typography>
            {formatMessage("table_label", {
              count: <strong style={{ color: paletteTheme.colors.PRIMARY }}>{totalCount}</strong>,
            })}
          </Typography>
          {lgUp && (
            <div>
              <ContainedButton
                marginRight="5px"
                disabled={!perimeterUtils.hasPerimeterFilters(transactionFilters)}
                onClick={openDialogSave}>
                {formatMessage("sv_save_this_view")}
              </ContainedButton>
              <ContainedButton onClick={() => setOpenManageColumn(true)}>
                {formatMessage("col_man_button")}
              </ContainedButton>
            </div>
          )}
        </div>

        <Table
          ref={tableRef}
          columns={columns}
          onFetch={onFetchData}
          disableNextPage={!perimeterUtils.hasPerimeterFilters(transactionFilters)}
          onClickRow={(row: Row<TransactionData>) =>
            navigate(`/${ACCEPTED_TRANSACTIONS_PATH}/${encodeURIComponent(row.original.rootTransactionalEventId)}`, {
              state: { transactionDetails: row.original.original },
            })
          }
          FooterButtonList={
            <>
              {/* Schedule report button */}
              <div className={isMobile ? `${styles["btn-wrapper"]} ${styles["left-btn"]}` : ""}>
                <ContainedButton
                  startIcon={<NoteAddOutlined />}
                  marginRight="10px"
                  onClick={handleOpenCloseScheduleReportDialog}>
                  <Typography color={paletteTheme.colors.WHITE}>{formatMessage("sr_schedule_button")}</Typography>
                </ContainedButton>
              </div>
              <div className={isMobile ? `${styles["btn-wrapper"]} ${styles["right-btn"]}` : ""}>
                {/* Export button  */}
                <DropdownButton
                  isExportLoading={isExportLoading}
                  onDownload={onDownload}
                  availableExports={CONTENT_TYPE_EXPORT_TRANSACTION}
                />
              </div>
            </>
          }
          updateTotalCountParent={(count: number | string) => setTotalCount(count)}
        />
      </div>

      <div
        className="flex-col flex-col-center"
        style={{ marginBottom: "30px", display: showContent ? "none" : "flex" }}>
        <Typography variant="h2">{formatMessage("admin_trans_view_title")}</Typography>
        <Typography>{formatMessage("admin_trans_view_text")}</Typography>
      </div>

      {openManageColumn && lgUp && (
        <ManageColumnDialog
          manageableColumns={TRANSACTION_MANAGEABLE_COLUMNS}
          defaultColumns={getTransactionColumns()}
          openDialog={openManageColumn}
          onCloseDialog={() => setOpenManageColumn(false)}
          submitMessage="col_man_apply"
          columns={managedColumns}
          onChangeColumns={onChangeColumns}
        />
      )}

      {openManyExportLine && (
        <ManyExportLineDialog
          openDialog={openManyExportLine}
          onCloseDialog={() => setOpenManyExportLine(false)}
          maxLines={process.env.REACT_APP_MAX_TRANSACTION_EXPORT}
        />
      )}

      {openDeleteView && (
        <DeleteViewDialog
          openDialog={openDeleteView}
          onCloseDialog={closeDialogDelete}
          handleDelete={handleDelete}></DeleteViewDialog>
      )}

      {openSaveView && (
        <SaveViewDialog
          openDialog={openSaveView}
          onCloseDialog={closeDialogSave}
          submitMessage="save"
          onSave={() => {
            viewService
              .find()
              .then((res) => {
                onChangeViewsOptions(res.content.map((value) => ({ id: value.id, name: value.name })));
              })
              .catch(errorUtils.handleBackErrors);
          }}
          filters={transactionUtils.computeViewFilters(
            transactionFilters,
            transactionAdditionalFilters,
            managedColumns
          )}
          perimeterFilters={transactionUtils.computeViewPerimeterFilters(transactionFilters)}
        />
      )}

      {openScheduleReport && (
        <ScheduleReport
          openDialog={openScheduleReport}
          onCloseDialog={handleOpenCloseScheduleReportDialog}
          filters={reportFilters}
        />
      )}
    </Card>
  );
};
