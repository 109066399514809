import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { RouteProps } from "react-router-dom";
import { useIntl } from "react-intl";
import { dateUtils } from "utils/common/dateUtils";
import { Histogram } from "components/molecules/histogram";
import {
    HistogramAmount,
    HistogramAmountSelectors,
    HistogramResponse,
    HistogramTypeLine
} from "interfaces/histogram/histogram";
import { TEMPORALITY_SELECTOR } from "constants/histogram/histogram";
import { Temporality } from "types/temporality";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface HomepageHistogramProps extends RouteProps {
    data: HistogramResponse,
    temporality: Temporality,
    onChangeTemporality: (temporality: string) => void,
    loading: boolean,
    histogramType: HistogramTypeLine,
    histogramAmountSelector: HistogramAmountSelectors
}

function getItemsForSmallDevice<T>(array: T[]): T[] {
    return array.slice(-6);
}

export const HomepageHistogram: FunctionComponent<HomepageHistogramProps> = ({
    data,
    temporality,
    onChangeTemporality,
    loading,
    histogramType,
    histogramAmountSelector
}) => {

    const intl = useIntl();
    const { smUp } = useMediaQueryUtil();
    const [amount, setAmount] = useState<string>(histogramAmountSelector.firstSelector.id);
    const [barData, setBarData] = useState<number[]>([])
    const [lineData, setLineData] = useState<number[]>([])
    const [xAxis, setXAxis] = useState<string[]>(dateUtils.getLastTwelveMonths())

    const setGraphData = (amounts: HistogramAmount[]) => {
        setBarData(amounts.map(amount => amount.value))
        setLineData(amounts.map(amount => amount.docCount))
    }

    /**
     * Changes axis format
     * Triggers a useEffect from useGraph hook to retrieve histogram datas
     */
    const handleChangePeriod = (value: Temporality) => {
        onChangeTemporality(value)
        let axis: string[];
        switch (value) {
            case TEMPORALITY_SELECTOR.DAY.id:
                axis = dateUtils.getLastTwelveDays().map(dateUtils.formatDate);
                break;
            case TEMPORALITY_SELECTOR.WEEK.id:
                axis = dateUtils.getLastTwelveWeeks();
                break;
            default:
                axis = dateUtils.getLastTwelveMonths();
        }
        setXAxis(axis);
    };

    // No need to call the API as both amounts are retrieved at the same time
    const handleChangeAmount = (value: string) => {
        setAmount(value);
        setGraphData(value === histogramAmountSelector.firstSelector.id ? data.firstAmount : data.secondAmount);
    };

    useEffect(() => {
        if (!!data) {
            setGraphData(amount === histogramAmountSelector.firstSelector.id ? data.firstAmount : data.secondAmount);
        }
    }, [data])

    // Translations for axis
    const translatedAxis: string[] = useMemo(() => {
        let result: string[];
        switch (temporality) {
            case TEMPORALITY_SELECTOR.DAY.id:
                result = dateUtils.getLastTwelveDays().map(date => intl.formatDate(date, {
                    month: "numeric",
                    day: "numeric",
                }));
                break;
            case TEMPORALITY_SELECTOR.WEEK.id:
                result = xAxis.map(week => `${intl.formatMessage({ id: "week" })} ${week}`);
                break;
            default:
                result = xAxis.map((month: string) => intl.formatMessage({ id: `${month}_abr` }));
        }
        return result;
    }, [intl.locale, temporality, xAxis]);

    const getXAxisLabelsForSmallDevice = (xAxisArray: string[]) => {
        const arr: string[] = temporality === "WEEK" ? xAxis.map(week => `${intl.formatMessage({ id: "graph_tooltip_week_leg" })} ${week}`) : xAxisArray;
        return getItemsForSmallDevice(arr);
    };

    return (
        <Histogram
            barData={smUp ? barData : getItemsForSmallDevice(barData)}
            lineData={smUp ? lineData : getItemsForSmallDevice(lineData)}
            linedChartLabel={intl.formatMessage({ id: histogramType.linedChartLabel })}
            barChartLabel={intl.formatMessage({ id: histogramType.barChartLabel })}
            linedChartAxisLabel={intl.formatMessage({ id: histogramType.linedChartAxisLabel })}
            barChartAxisLabel={intl.formatMessage({ id: histogramType.barChartAxisLabel })}
            chartTitle={intl.formatMessage({ id: histogramType.title })}
            amount={amount}
            amountSelectorOptions={[histogramAmountSelector.firstSelector, histogramAmountSelector.secondSelector]}
            onChangeAmount={handleChangeAmount}
            period={temporality}
            onChangePeriod={handleChangePeriod}
            xAxis={smUp ? translatedAxis : getXAxisLabelsForSmallDevice(translatedAxis)}
            loading={loading}
        />
    )
}
