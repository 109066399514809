import { AppRouteInterface } from "interfaces/RouteInterfaces";
import { USER_PATH } from "constants/routes/RoutePaths";
import UserDetailView from "view/UserDetailView";
import UserSearchView from "view/UserSearchView";
import { UserNewView } from "view/UserNewView";
import { PERMISSIONS } from "constants/permissions/permissions";

export const UserRoutes: AppRouteInterface = {
  path: USER_PATH,
  private: true,
  index: false,
  children: [
    {
      element: UserSearchView,
      private: true,
      index: true,
    },
    {
      element: UserDetailView,
      path: ":idUser",
      private: true,
      index: true,
      permission: PERMISSIONS.USER_GET,
      redirectPath: `/${USER_PATH}`
    },
    {
      element: UserNewView,
      path: "new",
      private: true,
      index: true,
      permission: PERMISSIONS.USER_CREATE,
      redirectPath: `/${USER_PATH}`
    }
  ]
}
