import React, { FunctionComponent } from "react";
import { authUtils } from "utils/api/authUtils";
import WrappedDisableClickComponent from "components/atoms/WrappedDisableClickComponent";
import Button, { ButtonProps } from "components/atoms/button/Button";
import styles from "components/atoms/button/SearchButton/searchButton.module.scss";

export interface SearchButtonProps extends ButtonProps {
  labelErrorOnDisable: string,
  disabledWithPopup: boolean,
}

export const QueryButton: FunctionComponent<SearchButtonProps> = ({
  onClick,
  disabled,
  disabledWithPopup,
  labelErrorOnDisable,
  startIcon,
  loading,
  ...rest
}) => {

  const onClickWithTracker = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    authUtils.refreshTokenExpirationDate();
    onClick(event);
  }

  return (
    <WrappedDisableClickComponent
      disabled={disabledWithPopup}
      labelErrorOnDisable={labelErrorOnDisable}>
      <Button {...rest}
        startIcon={startIcon}
        variant="contained"
        disabled={disabled}
        onClick={onClickWithTracker}
        className={styles.wrap}
        loading={loading}
      />
    </WrappedDisableClickComponent>
  );
}
