import {
  AccountCircleOutlined,
  Dashboard,
  EditNotifications,
  DescriptionOutlined,
  FormatListBulleted,
  Groups,
  Lock,
  Rule,
  Analytics,
  Done,
  DoneAll,
  Download,
} from "@mui/icons-material";
import {
  CHANGE_PASSWORD_PATH,
  HOME_PATH,
  MY_ACCOUNT_PATH,
  MY_REPORTS_PATH,
  SETTLEMENTS_PATH,
  NOTIFICATION_SETTINGS_PATH,
  ACCEPTED_TRANSACTIONS_PATH,
  ACQUIRED_TRANSACTIONS_PATH,
  USER_PATH,
  PORTFOLIO_PATH,
  DOCUMENTS_PATH,
} from "constants/routes/RoutePaths";
import { TMenuItem } from "interfaces/menu/menu";
import { PERMISSIONS } from "constants/permissions/permissions";

export const MENU_ITEMS: Array<TMenuItem> = [
  {
    Icon: Dashboard,
    label: "menu_home",
    href: HOME_PATH,
    isParentItem: false,
  },
  {
    isParentItem: true,
    Icon: FormatListBulleted,
    label: "menu_transactions",
    permission: PERMISSIONS.TRANSACTIONS_CONSULT,
    subItems: [
      {
        Icon: Done,
        label: "menu_accepted",
        href: `/${ACCEPTED_TRANSACTIONS_PATH}`,
        permission: PERMISSIONS.TRANSACTIONS_CONSULT,
        isParentItem: false,
      },
      {
        Icon: DoneAll,
        label: "menu_acquired",
        href: `/${ACQUIRED_TRANSACTIONS_PATH}`,
        permission: PERMISSIONS.TRANSACTIONS_CONSULT,
        isParentItem: false,
      },
    ],
  },
  {
    Icon: Rule,
    label: "menu_settlements",
    href: `/${SETTLEMENTS_PATH}`,
    permission: PERMISSIONS.SETTLEMENTS_CONSULT,
    isParentItem: false,
  },
  {
    Icon: Analytics,
    label: "menu_portfolio",
    href: `/${PORTFOLIO_PATH}`,
    permission: PERMISSIONS.PORTFOLIO_CONSULT,
    isParentItem: false,
  },
  {
    Icon: Groups,
    label: "menu_users",
    href: `/${USER_PATH}`,
    isParentItem: false,
  },
];

export const MENU_ITEMS_USER: Array<TMenuItem> = [
  {
    Icon: AccountCircleOutlined,
    label: "menu_my_account",
    href: `/${MY_ACCOUNT_PATH}`,
    isParentItem: false,
  },
  {
    Icon: DescriptionOutlined,
    label: "menu_my_reports",
    href: `/${MY_REPORTS_PATH}`,
    isParentItem: false,
  },
  {
    Icon: Download,
    label: "doc_menu",
    href: `/${DOCUMENTS_PATH}`,
    isParentItem: false,
  },
  {
    Icon: Lock,
    label: "menu_password",
    href: `/${CHANGE_PASSWORD_PATH}`,
    isParentItem: false,
  },
  {
    Icon: EditNotifications,
    label: "menu_notification_settings",
    href: `/${NOTIFICATION_SETTINGS_PATH}`,
    isParentItem: false,
  },
];

export const HELP_LINK = "https://stonly.com/guide/merchant-portal-help-center-8H2UXnThvS/Steps/2155106";

export const FEEDBACK_LINK = "https://stonly.com/guide/merchant-portal-feedback-3cGhpyk0Mv/Steps/2237879S";
