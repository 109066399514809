export type ReportColumnName =
    | "NAME"
    | "LATEST_EDITION"
    | "FREQUENCY"
    | "LATEST_REPORT"
    | "MORE_ACTIONS";

export interface FrequencySelector {
    id: string;
    name: string;
}

export interface FrequencySelectors {
    [key: string]: FrequencySelector;
}

export const FREQUENCY_SELECTOR: FrequencySelectors = {
    MONTHLY: { id: "MONTHLY", name: "sr_frequency_monthly" },
    WEEKLY: { id: "WEEKLY", name: "sr_frequency_weekly" },
    DAILY: { id: "DAILY", name: "sr_frequency_daily" },
}

export const FREQUENCY_SELECTOR_OPTIONS: FrequencySelector[] = [
    FREQUENCY_SELECTOR.MONTHLY,
    FREQUENCY_SELECTOR.WEEKLY,
    FREQUENCY_SELECTOR.DAILY,
]
