/**
 * File listing every translated label in Spanish
 */

export const SpanishMessages = {

  // LOGIN PAGE
  welcome: "Bienvenido al",
  merchant_portal: "PORTAL COMERCIAL!",
  email: "Dirección de correo electrónico",
  password: "Clave",
  email_placeholder: "john_doe@mail.com",
  login_button: "Iniciar sesión",
  forgot_password_cta: "Forgot your password?",
  logo_alt: "MarketPay logo",

  // LOGIN ERROR MESSAGES
  email_wrong_format: "Por favor revise el formato de su correo electrónico",
  email_required: "Correo electronico es requerido",
  password_required: "Se requiere contraseña",
  user_unknown: "No pudimos encontrar su dirección de correo electrónico",
  invalid_password: "Tu contraseña y correo electrónico no coinciden. Vuelve a intentarlo o restablece tu contraseña.",
  password_pending: "La creación de su cuenta no está completa. Haga clic en el enlace que ha recibido por correo electrónico para configurar su contraseña.",
  blocked_user: "Tu cuenta ha sido bloqueada. Póngase en contacto con nuestro soporte tecnico para obtener más información.",

  // RESET PASSWORD VIEW
  pw_recovery_title: "Recuperar contraseña",
  reset_password_text: "Haga clic en el botón a continuación para recibir un correo electrónico de reinicio a su dirección de correo electrónico: *Dirección de correo electrónico del usuario *",
  submit_button: "Enviar",

  // RESET PASSWORD CONFIRM VIEW
  pw_confirm_title: "Su solicitud de contraseña ha sido enviada",
  pw_recovery_confirmation_body: "Acabamos de enviarle un enlace de restablecimiento de contraseña a la dirección de correo electrónico que proporcionó.",
  pw_recovery_confirmation_not_sent: "Si no recibió el correo electrónico de restablecimiento de contraseña, consulte los filtros de spam y basura de su cuenta de correo electrónico.",
  pw_confirm_your_email: "Su dirección de correo electrónico : {email}",
  pw_resend_init_password_mail: "Su contraseña aún no se ha inicializado. Enviaremos el correo electrónico de contraseña de configuración a la dirección que proporcionó",

  // CHANGE PASSWORD
  change_password_sent_email: "Se ha enviado un enlace para restablecer su contraseña a su dirección de correo electrónico",
  change_password_title: "Clave",
  change_password_reset_password: "Restablecer su contraseña",
  change_password_send_email: "Haga clic en el botón a continuación para recibir un correo electrónico de reinicio a su dirección de correo electrónico",
  change_password_button: "Restablecer su contraseña",

  // UPDATE PASSWORD VIEW

  // INIT PASSWORD
  init_pw_page_title: "Establecer una contraseña",
  init_pw_page_field2: "Confirmar contraseña",
  init_pw_page2_text: "Su contraseña ha sido cambiada exitosamente",

  // UPDATE PASSWORD
  set_password: "Establecer una nueva contraseña",
  new_password_field: "Nueva contraseña",
  confirm_new_password_field: "Confirmar nueva contraseña",
  password_requirements_label: "Para proteger su cuenta, asegúrese de su contraseña:", // password_instructions
  password_requirements_include_label: "Incluye al menos:", // password_instructions
  password_min_length: "Es más largo de 8 caracteres", // password_instructions
  reset_password_button: "Restablecer mi contraseña",
  unmatched_passwords: "La confirmación de la contraseña debe coincidir con la contraseña",
  password_lowercase: "1 letra minúscula", // password_instructions
  password_uppercase: "1 letra mayúscula", // password_instructions
  password_number: "1 número", // password_instructions
  password_special_character: "1 carácter especial (~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/)", // password_instructions
  confirm_password_required: "Es necesario confirmar la contraseña",
  reset_password_confirmation: "Su contraseña ha sido cambiada exitosamente",
  back_login: "Atrás para iniciar sesión",
  send_feedback: "Enviar comentarios",

  // SIDE MENU
  menu_home: "Dashboard",
  menu_transactions: "Transacciones",
  menu_accepted: "Aceptado",
  menu_acquired: "Adquirido",
  menu_settlements: "Transferencias",
  menu_portfolio: "Portfolio",
  menu_users: "Usuarios",
  menu_my_account: "Mi cuenta",
  menu_my_reports: "Mis informes",
  menu_password: "Clave",
  log_out: "Cerrar sesión",
  menu_notification_settings: "Mis notificaciones",
  menu_language: "Idioma",

  // PROFILES
  merchant_admin: "Administrador Comercio",
  market_pay_admin: "Administrador Market Pay",
  operator: "Operador",
  profil_spring: "SPRING",

  // ACCOUNT PAGE

  // PERSONAL INFORMATION
  account_my_account: "Mi cuenta",
  account_user: "Usario {id} : {firstName} {lastName}",
  create_user_title: "Crear nuevo usuario",
  pers_info: "Informacion personal",
  first_name: "Nombre",
  last_name: "Apellido",
  creation_date: "Fecha de creación",
  last_activity: "Última actividad",
  account_email: "Correo electrónico",
  firstname_required: "Nombre es requerido",
  firstname_wrong_format: "Su nombre solo puede incluir letras, espacios y discontinuos", // name_rule
  lastname_required: "Apellido es requerido",
  lastname_wrong_format: "Su apellido solo puede incluir letras, espacios y discontinuos", // name_rule
  email_alr_used: "Esta dirección de correo electrónico ya está relacionada con una cuenta existente.",
  distributor: "Distributor",
  distributor_toggle: "Distributor view",

  // TYPE
  account_account: "Cuenta",
  account_type: "Tipo de cuenta",

  // PERIMETER
  level_col: "Nivel",
  peri: "Perímetro",
  title_paywish: "Cuenta PayWish",
  permissions: "Derecho",
  peri_account: "Cuenta",
  perimeter_group: "Grupo",
  peri_company: "Sociedad",
  peri_merchant: "Tienda",
  edit_button: "Editar",
  delete: "Borrar",
  account_all_perimeter: "Todas las cuentas",
  account_no_perimeter: "Ninguno",
  save_changes: "Guardar cambios",
  discard_changes: "Descartar los cambios",
  account_updated: "Se han guardado los cambios",
  account_tooltip_remove: "Eliminar esta empresa del perímetro",
  account_tooltip_reset: "Opciones de reinicio",
  add_company: "Agregar una empresa",
  no_results: "Sin resultados",
  add_all: "Añadir todo",
  required_account: "Este campo es obligatorio",
  required_company: "Este campo es obligatorio",
  required_merchants: "Este campo es obligatorio",
  inherit_future_merchants: "Heredar futuras tiendas",
  inherit_future_companies: "Heredar futuras empresas",
  account_add_all_companies: "Agregar todas las empresas",
  paywish_user_subtitle: "Usuario de PayWish",
  paywish_usertype: "Tipo",
  paywish_admin: "PayWish Admin",
  paywish_user: "PayWish User",
  paywish_select_usertype: "Seleccione el tipo",
  paywish_select_merchant: "Seleccione comerciante",
  paywish_pw_merchant: "PayWish comerciante",
  paywish_user_success_msg: "Se ha creado correctamente un usuario PayWish",
  paywish_user_error_msg: "Se ha producido un error técnico, no ha sido posible crear un usuario en este momento. Por favor, inténtelo más tarde.",
  paywish_user_error_duplicate: "Ya existe un usuario PayWish con esta dirección de correo electrónico",

  // TRANSACTIONS
  permission_transactions: "Ventas",
  permission_approve_ref: "Aprobar una solicitud de reembolso",
  permission_view_acc_acq_tx: "Ver las transacciones aceptadas y adquiridas",
  permission_trig_two_steps_ref: "Activar un reembolso de dos pasos",
  permission_trig_direct_ref: "Activar un reembolso directo",

  // SETTLEMENTS
  permission_category_settlements: "Transferencias",
  permission_view_settlements: "Consulte la lista de transferencias",
  settlements_view_acq_transactions: "Ver transacciones adquiridas",

  // USERS
  permission_users_man: "Usuarios",
  permission_add_user: "Agregue un nuevo usuario al portal comercial",
  permission_del_user: "Eliminar una cuenta de usuario",
  permission_user_update: "Actualizar una cuenta de usuario",
  permission_export_users: "Exportar lista de usuarios",

  // PORTFOLIO
  portfolio: "Portafolio",
  permission_category_portfolio: "Portafolio",
  permission_portfolio: "Acceder a la vista Portfolio",
  forbiddenLabel: "You do not have the required permissions to access this page",
  my_portfolio: "Mi Portafolio",
  title_volume_trends: "Tendencias",
  portf_title_billing: "Facturación",
  portf_title_top_bottom: "Arriba/Abajo",
  portf_col_day: "Día %",
  portf_col_week: "Semana %",
  portf_col_month: "Mes %",
  portf_merchants: "Comerciantes",
  portf_companies: "Sociedades",
  portf_dist_com: "Comisión del distribuidor",
  portf_tx_err: "Errores de transacción",
  switch_merchant: "Comerciante",
  switch_company: "Sociedad",
  portf_sort: "Ordenar por",
  portf_col_nbr_tx: "Número de transacciones",
  portf_col_gross_sum: "Importe bruto",
  portf_col_success_rate: "Tasa de éxito de las transacciones",
  portf_col_dist_com: "Comisión del distribuidor",
  portf_col_avg_com_rate: "Comisión media",
  portf_col_mp_com: "Comisión Market Pay",
  portf_col_total_commission: "Comisión total",
  portf_col_total_fees: "Comisión total",
  rank: "#",
  portf_filter_display_trend_by: "Mostrar valores en % basados en:",
  portf_tooltip_nbr_tx: "El número de transacciones mostradas se basa en la clasificación por columnas",
  portf_tooltip_gross: "El importe bruto se muestra en función de la clasificación por columnas",
  portf_col_day_tooltip: "Ordenar en esta columna para mostrar los datos de las transacciones de ayer",
  portf_col_week_tooltip: "Ordenar en esta columna para mostrar los datos de las transacciones de los últimos 7 días",
  portf_col_month_tooltip: "Ordenar en esta columna para mostrar los datos de las transacciones del último mes",
  portf_tooltip_tx_success_rate: "Tasa de éxito de la transacción representa el porcentaje de transacciones aceptadas con éxito en comparación con el número total de transacciones en el resultado de la búsqueda.",
  portf_tooltip_distributor_commission: "Comisión del distribuidor representa la parte de la comisión de Market Pay que se cobra en nombre del distribuidor.",
  portf_tooltip_mp_commission: "La comisión de Market Pay representa tanto el importe de la comisión cobrada por Market Pay, como el importe de la comisión cobrada en nombre del distribuidor.",
  portf_tooltip_total_commission: "Comisión total representa el importe total de las comisiones de Market Pay, las comisiones de esquema y las comisiones de intercambio.",
  portf_tooltip_avg_commission_rate: "Tasa media de comisión representa la tasa media de comisión de Market Pay sobre todas las transacciones de la busqueda.",
  tooltip_daily_evolution: "Evolución diaria",
  tooltip_weekly_evolution: "Evolución semanal",
  tooltip_monthly_evolution: "Evolución mensual",
  tooltip_nb_transaction: "Número de transacciones",
  tooltip_gross_sum: "Importe bruto",
  // REPORTS PAGE
  my_reports_title: "Mis informes",
  reports_table_report_name_col: "Nombre",
  reports_table_latest_edition_col: "Última edición",
  reports_table_frequency_col: "Frecuencia",
  reports_table_frequency_col_daily: "Diario",
  reports_table_frequency_col_weekly: "Semanal",
  reports_table_frequency_col_monthly: "Mensualmente",
  reports_table_latest_report_col: "Último informe",
  reports_table_download_button: "Descargar",
  reports_table_delete: "Eliminar",
  reports_table_delete_message: "¿Está seguro de que desea eliminar {name}?",
  reports_table_delete_confirm: "Eliminar",
  reports_table_delete_cancel: "Cancelar",

  // DOCUMENTS PAGE
  doc_menu: "Documentos",
  doc_title: "Documentos",
  doc_header: "Descargue el extracto de sus cuotas mensuales",
  doc_settlement_year: "Año",
  doc_settlement_month: "Mes",
  doc_rafec_download: "Descargar",

  // USERS PAGE
  users_acc_col_all: "Todos",
  users_acc_col: "Cuenta",
  users_search: "Búsqueda",
  users_search_bar: "Buscar por primer nombre, apellido o correo electrónico",
  add_user_button: "Agregar usuario",
  user_not_found: "Usuario no encontrado",
  acc_creation_conf_message: "La nueva cuenta se ha creado con éxito. Se ha enviado un correo electrónico de configuración de contraseña a {email}",
  back_user_list: "Volver a la lista de usuarios",
  create_user_button: "Crear usuario",
  trigger_welcome_email: "Enviar correo electrónico de bienvenida",
  welcome_email_header: "Email",
  welcome_email_sent: "Correo electrónico de bienvenida enviado",
  welc_email_butt_error: "No pudimos enviar el correo electrónico. Por favor, inténtelo de nuevo más tarde.",

  // LANGUAGE
  language_en: "EN",
  language_fr: "FR",
  language_it: "IT",
  language_es: "SP",
  language_nl: "NL",
  language_pt: "PT",

  // LOADER
  loader_search_results: "Buscando resultados ...",

  // TABLE
  number_element: "{displayedCount} de {totalCount}",
  show_more: "Mostrar {pageSize} más",
  no_data_found: "¡Ups! Su consulta devolvió 0 resultados",
  fetch_error: "¡Ups! Ha ocurrido algo malo",
  table_label: "Su consulta devolvió {count, plural, =0 {0 resultados} one {1 resultado} other {{count} resultados}}",
  query_results: "Su consulta devolvió",
  query_results_return: " {count, plural, one {resultado} other {resultados}}:",
  query_results_no_results: "No hay resultados",
  export_csv_button: "CSV de exportación",
  export_button: "Exportación",
  sr_schedule_button: "Programar un informe",
  sr_pop_up_title: "Programar un informe",
  sr_pop_up_report_name_label: "Nombre del informe",
  sr_pop_up_report_name_placeholder: "Exportación semanal de transacciones fallidas",
  sr_pop_up_frequency_label: "Frecuencia",
  sr_pop_up_frequency_placeholder: "Seleccione",
  sr_pop_up_close_button: "Cerrar",
  sr_pop_up_save_button: "Grabar",
  sr_frequency_monthly: "Mensualmente",
  sr_frequency_weekly: "Semanal",
  sr_frequency_daily: "Diario",
  sr_frequency_monthly_disclaimer: "El primer día de cada mes se creará un nuevo informe con los datos del mes anterior.",
  sr_frequency_weekly_disclaimer: "Todos los lunes por la mañana se creará un nuevo informe con los datos de la semana anterior.",
  sr_frequency_daily_disclaimer: "Cada mañana se creará un nuevo informe con los datos del día anterior.",
  sr_conf_1_message: "Está a punto de programar un informe {frequency} llamado {reportName}.",
  sr_conf_1_message_confirm: "¿Confirma?",
  sr_conf_1_cancel_button: "Cancelar",
  sr_conf_1_confirm_button: "Confirmar",
  sr_conf_2_message_1: "Su informe ha sido programado.",
  sr_conf_2_message_2: "Visita {myReportsLink} para encontrar y gestionar todos tus informes programados.",
  sr_conf_2_message_link: "Mis informes",
  sr_conf_2_button: "OK",
  sr_error_empty_name: "Por favor, asigne un nombre al informe.",
  sr_error_existing_name: "Este nombre ya está en uso. Elija otro.",
  sr_error_empty_frequency: "Elija la frecuencia del informe.",
  sr_error_name_too_short: "El nombre debe tener al menos 3 caracteres.",
  sr_saving_error: "Su informe no puede guardarse en este momento debido a un error técnico. Vuelva a intentarlo más tarde.",
  export_limit_message_no_number: "El informe que está intentando descargar es demasiado grande. Realiza una búsqueda más restringida e inténtalo de nuevo.",
  toggle_sorting: "Haga clic para ordenar",
  no_reports_scheduled: "No tiene ningún informe programado.",

  // ACTIVITY
  activity_years_ago: "{interval} hace años",
  activity_month_ago: "hace {interval, plural, one {1 mes} other {{interval} meses}}",
  activity_day_ago: "hace {interval, plural, one {1 día} other {{interval} días}}",
  activity_today: "Este Dia",
  activity_yesterday: "El dia de ayer",
  activity_never: " ",

  // PERMISSIONS FORBIDDEN
  not_allowed_to_create_user: "No se le permite crear usuarios",
  not_allowed_to_delete_user: "No se le permite eliminar los usuarios",
  not_allowed_to_get_user: "No se le permite consultar a los usuarios",

  // DISCARD POPUP
  toast_discard_change: "Your change has been discarded.",
  unsaved_ch_err_mess: "Sus cambios podrían perderse.",
  unsaved_ch_err_mess_save: "¿Quieres guardarlos?",
  discard: "Desechar",
  cancel: "Cancelar",
  ok: "Ok",
  save: "Grabar",

  // DELETE POPUP
  delete_account_title: "Borrar cuenta: {email}",
  delete_account_dialog_box: "¿Estás seguro de que quieres eliminar esta cuenta?",
  delete_account_success: "El usuario ha sido eliminado",

  // MONTHS
  january: "Enero",
  february: "Febrero",
  march: "Marzo",
  april: "Abril",
  may: "Mayo",
  june: "Junio",
  july: "Julio",
  august: "Agosto",
  september: "Septiembre",
  october: "Octubre",
  november: "Noviembre",
  december: "Diciembre",

  //AMOUNT RANGE FILTER
  min_sup_max: "La cantidad min debe ser igual o menor que la cantidad máxima",
  negative_value: "Los valores deben ser iguales o más de 0",
  card_transactions: "Transacciones",
  card_trans_gross_amount: "Cantidad total bruta",
  card_trans_net_amount: "Cantidad neta total",
  card_trans_refunds_amount: "Reembolsos",
  card_trans_fees_amount: "Importe comisiòn",

  //TRANSACTION PAGE
  transactions_title: "Transacciones",
  acc_transactions_title: "Transacciones aceptadas",
  filter_country: "País",
  filter_account: "Cuenta",
  filter_company: "Compañía",
  filter_merchant: "Tienda",
  filter_operation_date: "Fecha de aceptación",
  search: "Búsqueda",
  filter_by: "Filtrado por",
  admin_trans_view_title: "Aplicar al menos un filtro para ver transacciones",
  admin_trans_view_text: "Filtrar por país, cuenta, empresa, tienda o fecha de pago para reducir su investigación",
  export_limit_message: "No se pueden exportar más de {max} transacciones en un archivo. Por favor, acote su búsqueda e inténtelo de nuevo",
  filter_search_button_toaster: "Elija una cuenta antes de comenzar su búsqueda",
  col_man_err_message: "No puede mostrar menos de 5 columnas en la tabla. Seleccione otro campo antes de desconectar este.",

  // ADDITIONAL FILTERS
  more_filters: "Más filtros",
  less_filters: "Menos filtros",
  order_number: "Número de pedido",
  payment_solution: "Solución de pago",
  payment_type: "Tipo de pago",
  checkout_type: "Modo Lectura",
  settlement_status: "Estado de financiación",
  settlement_date: "Fecha de transferencia",
  total_fees: "Importe comisiòn",
  trans_authorization: "Authorization",
  trans_cancellation: "Cancelación",
  trans_capture: "Captura",
  trans_settled: "Pagado",
  trans_refund: "Reembolso",
  trans_refund_request: "Petición para reembolso",
  event_success: "Aceptada",
  event_declined: "Rechazada",
  event_error: "Error",
  solution_pay_online: "PayOnline",
  solution_pay_wish: "PayWish",
  solution_pay_onsite: "PayOnsite",
  type_debit: "Débito",
  type_credit: "Crédito",
  checkout_contactless: "Contactless",
  checkout_online: "Online",
  checkout_insertion: "Chip",
  checkout_magstripe: "Banda Magnetica",
  financing_paid: "Pagado",
  financing_pending: "Pendiente",
  batch_id: "ID del lote",
  scheme: "Scheme",
  filter_remittance: "Remesa",
  filter_service_type: "Tipo de servicio",
  filter_collection_type: "Tipo de colección",

  // POPUP COLUMNS
  col_man_button: "Administrar columnas",
  col_man_title: "Columnas mostradas",
  col_man_apply: "Aplicar",
  col_man_discard: "Desechar",
  col_man_titles_acceptation: "Aceptación",
  col_man_titles_last_event: "Último evento",
  col_man_titles_transaction: "Transacción",
  col_man_titles_settlement: "Financiación",
  col_man_titles_customer: "Cliente",
  col_man_titles_merchant: "Comerciante",
  col_man_checkout_type: "Modo Lectura",
  col_man_payment_method: "Método de pago",
  col_man_payment_solution: "Solución de pago",
  col_man_payment_type: "Tipo de pago",
  col_man_authorisation_code: "Código de autorización",
  col_man_pos_id: "POS ID",
  col_man_pos_name: "Nombre",
  col_man_bank_acc_dom_country: "Domiciliación",
  col_man_card_iss_bank: "Banco emisor",
  col_man_card_iss_coun: "País emisor",
  col_man_card_level: "Nivel de tarjeta",
  col_man_card_type: "Tipo de tarjeta",
  col_man_pan_first_six: "BIN",
  col_man_pan_last_four: "Pan Últimos cuatro dígitos",
  col_man_scheme: "Marca",
  col_man_op_date: "Fecha de operación",
  col_man_op_time: "Tiempo de operacion",
  col_man_currency: "Divisa",
  col_man_gross_amount: "Importe bruto",
  col_man_net_amount: "Importe neto",
  col_man_merch_trans_id: "ID de transacción comercial",
  col_man_order_num: "Número de pedido",
  col_man_trans_type: "Tipo de transacción",
  col_man_trans_event: "Evento",
  col_man_status: "Estado",
  col_man_trans_event_id: "ID de evento",
  col_man_root_trans_event_id: "ID de transacción",
  col_man_event_timestamp: "Fecha/Hora transacción",
  col_man_acquirer: "Adquiriente",
  col_man_settlement_batch: "Lote de financiación",
  col_man_settlement_date: "Fecha de transferencia",
  col_man_settlement_status: "Estado de financiación",
  col_man_interchange: "Intercambio",
  col_man_intermediate_fees: "Tarifas intermedias",
  col_man_mkp_fees: "Tarifas de Market Pay",
  col_man_scheme_fees: "Tarifas de Marca",
  col_man_total_fees: "Comisión total",
  col_man_account_name: "Cuenta",
  col_man_company_name: "Compañía",
  col_man_merchant_name: "Nombre de la tienda",
  col_man_country: "País",
  col_man_contract_num: "Número de contrato",
  col_man_reset_button: "Volver a la configuración predeterminada",
  col_man_titles_fee_calc: "Cálculo de comisión",
  col_man_titles_fee_amount: "Monto de comisión",
  col_settlement_month: "Mes de financiación",
  col_imposta_di_bollo: "Imposta di bollo",
  category_distribution: "Distribución",
  col_retrocession: "Retrocesión",
  distrib_share_margin_amt: "Margen de participación distribuidor",
  distrib_share_margin_rate: "Ratio del margen de participación distribuidor",
  mp_share_margin_amt: "Margen de participación Market Pay",
  mp_share_margin_rate: "Ratio del margen de participación Market Pay",
  col_operation_time_utc: "Tiempo de operacion (UTC)",
  col_operation_time_local: "Tiempo de operacion (Local)",

  //COUNTRIES
  country_filter_fr: "Francia",
  country_filter_sp: "España",
  country_filter_nl: "Nederlands",
  country_filter_pt: "Portugal",
  country_filter_it: "Italia",
  country_filter_bel: "Bélgica",
  country_filter_ger: "Alemania",

  // LOADING
  loading: "Cargando...",

  // SETTLEMENTS
  card_settlements_gross_am: "Importe bruto",
  card_settlements_comm: "Importe comisiòn",
  card_settlements_fin_am: "Cantidad financiada",
  settled_by_mp: "Pagado por Market Pay",
  filter_settlement_date: "Fecha de transferencia",

  // HOMEPAGE
  good_morning: "Hola,",
  good_evening: "Buenas noches,",
  homepage_today: "Este Dia",

  // GRAPH

  // COMMON
  homepage_graph_month: "Mes",
  homepage_graph_week: "Semana",
  homepage_graph_day: "Día",
  week: "Semana",
  graph_tooltip_number: "Número",
  graph_tooltip_amount: "Importe",
  graph_tooltip_week_leg: "S",
  graph_dropdown_interval: "Intervalo de tiempo",
  graph_dropdown_amount: "Tipo de importe",

  // TRANSACTIONS
  homepage_graph_leg_gross: "Importe bruto",
  homepage_graph_leg_net: "Importe neto",
  homepage_graph_leg_num: "Número de transacciones",
  homepage_graph_leg_am: "Importe (€)",
  graph_trans_hp_bot_leg_num: "Número de transacciones",
  graph_trans_hp_bot_leg_am: "Importe (€)",
  graph_transactions: "TRANSACCIONES",

  // SETTLEMENTS
  graph_hp_sett_filter_value_1: "Importe neto",
  graph_hp_sett_filter_value_2: "Importe comisiòn",
  graph_hp_sett_leg_num: "Número de transferencias",
  graph_hp_sett_leg_am: "Importe (€)",
  graph_hp_bot_sett_leg_num: "Número de transferencias",
  graph_hp_bot_sett_leg_am: "Importe (€)",
  graph_settlements: "TRANSFERENCIAS",

  // MONTHS
  january_abr: "Ene",
  february_abr: "Feb",
  march_abr: "Mar",
  april_abr: "Abr",
  may_abr: "Mayo",
  june_abr: "Jun",
  july_abr: "Jul",
  august_abr: "Ago",
  september_abr: "Sep",
  october_abr: "Oct",
  november_abr: "Nov",
  december_abr: "Dic",

  // HOMEPAGE DAILY KEY NUMBERS
  homepage_title_sales: "Ventas",
  homepage_title_settlements: "Transferencias",
  homepage_key_numb_acc: "Transacciones aceptadas",
  homepage_key_numb_am: "Cantidad total aceptada",
  homepage_key_numb_settlement_gr_am: "Importe bruto",
  homepage_key_numb_fees: "Comisión",
  homepage_key_numb_fin: "Cantidad financiada",

  // ACQUIRED TRANSACTIONS VIEW
  acq_transactions_title: "Transacciones adquiridas",
  transaction_date: "Fecha de la transacción",
  gross_amount: "Importe bruto",
  col_card_brand: "Marca de la tarjeta",
  col_man_fees: "Importe comisiòn",
  col_tlc_number: "Número de remesa",
  acq_trans_key_numbers_title: "Pagado por Market Pay",
  card_acq_trans_number: "Transacciones",
  card_acq_trans_gross_amount: "Importe bruto",
  card_acq_trans_fees_amount: "Importe comisiòn",
  card_acq_trans_net_amount: "Importe neto",
  acq_details_title: "Transacciones adquiridas > ",

  // SETTLEMENTS VIEW
  settlements_title: "Transferencias",
  settlement_tab_batch: "ID del lote",
  set_tab_merchant: "Comerciante",
  pay_tab_settlement_date: "Fecha de transferencia",
  pay_tab_pay_sol: "Solución",
  pay_tab_pay_amo: "Importe bruto",
  settlement_tab_fees: "Importe comisiòn",
  pay_tab_settlement_am: "Importe neto",

  // TRANSACTION DETAILS
  tp_titles_acceptation: "Aceptación",
  tp_titles_customer: "Cliente",
  tp_titles_transaction: "Transacción",
  tp_titles_history: "Historia",
  tp_titles_settlement: "Financiación",
  tp_titles_merchant: "Comerciante",
  tp_fees_calculation: "Cálculo de las tasas",
  tp_fees_amount: "Importe de las tasas",
  tpd_checkout_type: "Modo Lectura",
  tpd_payment_method: "Método de pago",
  tpd_payment_solution: "Solución de pago",
  tpd_payment_type: "Tipo de pago",
  tpd_authorisation_code: "Código de autorización",
  tpd_pos_id: "POS ID",
  tpd_pos_name: "Nombre",
  tpd_bank_acc_dom_country: "Domiciliación",
  tpd_card_iss_bank: "Banco emisor",
  tpd_card_iss_coun: "País emisor",
  tpd_card_level: "Nivel de tarjeta",
  tpd_card_type: "Tipo de tarjeta",
  tpd_pan_first_six: "BIN",
  tpd_pan_last_four: "Pan Últimos cuatro dígitos",
  tpd_scheme: "Marca",
  tpd_op_date: "Fecha de operación",
  tpd_op_date_utc: "Fecha de operación (UTC)",
  tpd_op_time: "Tiempo de operacion",
  tpd_currency: "Divisa",
  tpd_gross_amount: "Importe bruto",
  tpd_net_amount: "Importe neto",
  tpd_trans_id: "ID de transacción",
  tpd_merch_trans_id: "ID de transacción comercial",
  tpd_order_num: "Número de pedido",
  tpd_trans_type: "Tipo de transacción",
  tpd_trans_event: "Evento",
  tpd_status: "Estado",
  tpd_trans_event_id: "ID de evento",
  tpd_root_trans_event_id: "ID de transacción",
  tpd_event_timestamp: "Fecha/Hora transacción",
  tpd_acquirer: "Adquiriente",
  tpd_settlement_batch: "Lote de financiación",
  tpd_settlement_date: "Fecha de transferencia",
  tpd_settlement_status: "Estado de financiación",
  tpd_interchange: "Intercambio",
  tpd_intermediate_fees: "Tarifas intermedias",
  tpd_mkp_fees: "Tarifas de pago del mercado",
  tpd_scheme_fees: "Tarifas de Marca",
  tpd_total_fees: "Comisión total",
  tpd_account_name: "Cuenta",
  tpd_company_name: "Compañía",
  tpd_merchant_name: "Nombre de la tienda",
  tpd_country: "País",
  tpd_contract_num: "Número de contrato",
  tpd_transaction_settlement_id: "ID de liquidación de la operación",
  tpd_tlc_number: "Número de remesa",
  tpd_card_zone: "Zona de tarjetas",
  tpd_card_brand: "Marca de la tarjeta",
  tpd_settlement_id: "ID del lote",
  tpd_calculation_method: "Método de cálculo",
  tpd_marketpay_fixed_fees: "Comisiones fijas Market Pay",
  tpd_markeetpay_fees_rate: "Tasa de la tarifa Market Pay",
  tpd_fixed_scheme_fees: "Tasas fijas",
  tpd_scheme_fees_rate: "Tasa de la comisión de régimen",
  tpd_total_marketpay_fees: "Total tasas Market Pay",
  tpd_fixed_fees: "Comisiones fijas",
  tpd_variable_fees: "Comisiones variables",
  tpd_total_acquiring_fees: "Total comisiones de adquisición",
  col_total_marketpay_fees: "Total tasas Market Pay",
  col_interchange_fees: "Tasas de intercambio",
  col_scheme_fees_rate: "Tasa de la comisión de régimen",
  col_fixed_scheme_fees: "Tasas fijas",
  col_marketpay_fees_rate: "Tasa de la tarifa Market Pay",
  col_marketpay_fixed_fees: "Comisiones fijas Market Pay",
  col_calculation_method: "Método de cálculo",
  col_card_zone: "Zona de tarjetas",
  col_transaction_settlement_id: "ID de liquidación de la operación",
  payment_type_card: "Tarjeta",
  payment_type_banktransfer: "Transferencia bancaria",
  payment_type_contactless: "Sin contacto",
  payment_type_wallet: "Wallet",
  pos_id: "POS ID",
  col_processing_ht: "Tasa de procesamiento neto",
  col_processing_tva: "IVA sobre el procesamiento",
  col_processing_ttc: "Tasa de procesamiento bruto",

  // TRANSACTION HISTORY TABLE
  trans_det_tab_uuid: "Uuid",
  trans_det_tab_timestamp: "Fecha y hora",
  trans_det_tab_event: "Evento",
  trans_det_tab_status: "Estado",
  trans_det_tab_amount: "Montante",
  transaction_det_last_sync: "Última sincronización: ",
  transaction_det_page_title_1: "Transacciones>",
  transaction_det_page_title_2: "Transacción {transactionId} Detalles",
  back_transactions_list: "Volver a la lista de transacciones",

  // NOTIFICATIONS
  notif_new_merchant: "Se ha agregado una nueva tienda a tu",
  notif_new_comp: "Se ha agregado una nueva empresa a tu",
  notif_new_account: "Se ha agregado una nueva cuenta a tu",
  notif_new_merchant_admin_inapp: "Se ha creado un nuevo Merchant Admin:",
  notif_new_marketpay_admin_inapp: "Se ha creado un nuevo Market Pay Admin:",
  notif_new_operator_inapp: "Se ha creado un nuevo Operator",
  notif_hide_button: "Ocultar",
  notif_hide_all_button: "Ocultar todas",
  new_notif: "Tienes {count} notificacione(s) nueva(s):",
  no_new_notif: "No tienes notificaciones nuevas",
  notif_viewed: "Visto",

  // NOTIFICATION SETTINGS
  my_notifications: "Mis notificaciones",
  notif_title: "Mis notificaciones",
  notif_peri_update: "Actualizaciones perimetrales",
  notif_user_update: "Usuarios",
  notif_in_app: "In-App",
  notif_email: "Email",
  notif_edit_merchant: "Nuevo comerciante",
  notif_edit_company: "Nueva compañia",
  notif_edit_acc: "Nueva cuenta",
  notif_edit_group: "Nuevo grupo",
  notif_edit_operator: "Nuevo Operador",
  notif_edit_merchant_admin: "Nuevo Administrador Comercio",
  notif_edit_markerpay_admin: "Nuevo Administrador Market Pay",
  perim_creat_account: "Escriba un nombre de cuenta",
  perim_creat_company: "Escriba un nombre de empresa",

  // FILTERS
  filter_all_comp: "Todos",
  reset_button_text: "Restablecer",

  // VIEWS
  sv_deletion_confirmation_toaster: "Tu vista ha sido eliminada",
  sv_creation_confirmation: "Tu vista ha sido guardada",
  sv_save_this_view: "Guardar esta vista",
  sv_view_name: "Nombre de la vista",
  sv_saved_views: "Vistas guardadas",
  sv_name_is_required: "La vista debe tener un nombre",
  sv_name_is_too_short: "El nombre debe tener al menos 3 caracteres",
  view_name_already_exists: "Este nombre de vista ya existe",
  sv_deletion_confirmation: "¿Está seguro de que desea eliminar esta vista?",

  // DATEPICKER DEFINED RANGES
  date_picker_today: "Este Dia",
  date_picker_yesterday: "Ayer",
  date_picker_current_month: "Mes actual",
  date_picker_last_week: "La semana pasada",
  date_picker_last_two_weeks: "Últimas dos semanas",
  date_picker_last_month: "El mes pasado",
};
