import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { UserDetails } from "interfaces/user/user";
import { paletteTheme } from "styles/themes/palette";
import { CardRowWithInput } from "components/atoms/card/CardRowWithInput";
import { PERS_INFO_INPUTS } from "constants/account/account";
import { ValidateErrorMap } from "../UserAccountEdit";
import { PROFIL_USER } from "constants/user/user";
import { CardRow } from "components/atoms/card/CardRow";
import { SelectAccount } from "components/atoms/select/SelectAccount";
import { useAuthUser } from "hooks/useAuthUser";
import Card from "components/atoms/card/Card";
import { createUserUtils } from "utils/business/createUserUtils";
import { ProfileDisplay } from "components/molecules/useraccount/display/ProfileDisplay";
import { IntlMessage } from "constants/intl/intl";
import { Options } from "constants/options/option";
import { LOGIN_ERRORS } from "constants/errors/login";
import { USER_ERRORS } from "constants/errors/user";
import { Switch } from "components/atoms/switch/Switch";
import { rightUtils } from "utils/business/rightUtils";
import { isSwitchVisible } from "services/user/DistributorToggleService";
import {
  BoxProps,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import styles from "components/molecules/useraccount/edit/UserAccountInformationEdit/userAccountInformationEdit.module.scss";
import { BackLink } from "components/molecules/BackLink";
import { USER_PATH } from "constants/routes/RoutePaths";
import AdvancedFormattedMessage from "components/atoms/intl/AdvancedFormattedMessage";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface AccountInformationsProps extends BoxProps {
  user: UserDetails;
  idUser: string;
  onChangePersInfo: (key: string) => (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeProfile: (event: SelectChangeEvent) => void;
  onChangeDistributor: (type: boolean) => void;
  errors?: ValidateErrorMap;
  cardTitle: string | IntlMessage;
  isCreate: boolean;
}

export const UserAccountInformationEdit: FunctionComponent<AccountInformationsProps> = ({
  user,
  idUser,
  onChangePersInfo,
  onChangeProfile,
  onChangeDistributor,
  errors,
  cardTitle,
  isCreate = false,
}) => {

  const intl = useIntl();
  const { user: authUser } = useAuthUser();
  const [profileOptions, setProfileOptions] = useState<Array<Options>>([]);
  const { smDown } = useMediaQueryUtil();

  useEffect(() => {
    let profile = [];

    if (authUser && PROFIL_USER[authUser.profile].id === PROFIL_USER.ADMIN.id) {
      profile = [{ id: PROFIL_USER.ADMIN.id, name: intl.formatMessage({ id: PROFIL_USER.ADMIN.label }) }]
    }

    setProfileOptions([...profile,
    {
      id: PROFIL_USER.OPERATOR.id,
      name: intl.formatMessage({ id: PROFIL_USER.OPERATOR.label })
    }
    ])

  }, [authUser])

  const isUserAdmin = useMemo(() => rightUtils.isAdmin(user), [user])
  const marginBottom = isUserAdmin ? 5 : 1;

  const visible = isSwitchVisible(authUser, user, isCreate);

  return (
    <>
      <Card
        cardTitle={
          <>
            <div className={`flex flex-col-center flex-row-between ${styles.wrapper}`}>
              <Typography variant="h1" paddingTop="4px" color={paletteTheme.palette.primary["main"]}>
                <AdvancedFormattedMessage title={cardTitle} />
              </Typography>
              <BackLink to={`/${USER_PATH}`} label="back_user_list" showCloseIconOnXSSM={true} />
            </div>
          </>
        }
        header="pers_info">
        {PERS_INFO_INPUTS.map(item => (
          <CardRowWithInput id={item.id}
            key={item.key}
            label={item.id}
            onChange={onChangePersInfo(item.key)}
            error={item.possibleErrors.some(error => !!errors[error.id])}
            errors={errors}
            possibleErrors={item.possibleErrors}
          >{user?.[item.key]}
          </CardRowWithInput>
        ))}
        <CardRowWithInput id="account_email"
          key="email"
          label="account_email"
          onChange={onChangePersInfo("email")}
          error={[LOGIN_ERRORS.EMAIL_REQUIRED, LOGIN_ERRORS.EMAIL_WRONG_FORMAT, USER_ERRORS.EMAIL_ALR_USED].some(error => !!errors[error.id])}
          errors={errors}
          possibleErrors={[LOGIN_ERRORS.EMAIL_REQUIRED, LOGIN_ERRORS.EMAIL_WRONG_FORMAT, USER_ERRORS.EMAIL_ALR_USED]}
        >{user?.email}
        </CardRowWithInput>
      </Card>
      <Card header="account_account">
        {createUserUtils.isNewUser(user) ?
          <CardRow marginBottom={marginBottom} id="account_type" label="account_type">
            <SelectAccount
              id="account_type"
              value={{
                id: PROFIL_USER[user.profile].id,
                name: intl.formatMessage({ id: PROFIL_USER[user.profile].label })
              }}
              disableOnUnique
              onChange={onChangeProfile}
              options={profileOptions}>
            </SelectAccount>
          </CardRow> :
          <ProfileDisplay marginBottom={marginBottom} user={user}></ProfileDisplay>
        }
        {visible &&
          <CardRow
            marginBottom={0.5}
            fontWeight={400}
            id="distributor"
            label="distributor"
            customClassName="sub-item"
            customLabelClassName="sub-label"
            customContentClassName="switch"
            >
            <Switch
              checked={user.distributor}
              disabled={true}
              onChange={(_e, checked) => onChangeDistributor(checked)}
            />
          </CardRow>
        }
      </Card>
    </>
  )
}
