import React, { FunctionComponent, useState } from "react";
import CardPopup from "components/atoms/card/CardPopup";
import FlexBox from "components/atoms/flex/FlexBox";
import { FlexAttributValue } from "types/flex/flex";
import { Dialog as DialogMaterialUI, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ContainedButton from "components/atoms/button/ContainedButton";
import DiscardButtonStyled from "styles/components/button/DiscardButtonStyled";
import { useChangeCheckbox } from "hooks/useChangeCheckbox";
import { CloseOutlined } from "@mui/icons-material";
import ManageColumnWrapped from "components/molecules/dialog/ManageColumn/ManageColumnWrapped";
import OutlinedButton from "components/atoms/button/OutlinedButton";
import { paletteTheme } from "styles/themes/palette";
import { ManagableColumn, ManageableColumnItem } from "interfaces/table/table";

export interface DialogProps {
  openDialog: boolean,
  onCloseDialog: () => void,
  columns: ManagableColumn[],
  manageableColumns: ManageableColumnItem[],
  defaultColumns: ManagableColumn[],
  onChangeColumns: (columns: ManagableColumn[]) => void,
  submitMessage: string
}

const ManageColumnDialog: FunctionComponent<DialogProps> = ({
  openDialog,
  columns,
  onCloseDialog,
  submitMessage,
  onChangeColumns,
  defaultColumns,
  manageableColumns
}) => {
  const [selectedOptions, setSelectedOptions] = useState<ManagableColumn[]>(columns);

  const { handleChange } = useChangeCheckbox<ManagableColumn>(selectedOptions, (options) =>
    setSelectedOptions(options)
  );

  const onClose = () => {
    setSelectedOptions(columns);
    onCloseDialog();
  };

  const onReset = () => {
    setSelectedOptions(defaultColumns);
  };

  const disableUncheck = selectedOptions.length === 5;

  return (
    <DialogMaterialUI
      open={openDialog}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { maxWidth: "unset" } }}>
      <CardPopup sx={{ width: "100%" }}>
        <FlexBox justifyContent={FlexAttributValue.SPACE_BETWEEN} marginBottom="20px">
          <Typography variant="h1" color="primary">
            <FormattedMessage id="col_man_button" />
          </Typography>
          <CloseOutlined
            sx={{ cursor: "pointer", color: paletteTheme.colors.GRAY }}
            onClick={() => onClose()}></CloseOutlined>
        </FlexBox>

        <FlexBox justifyContent={FlexAttributValue.SPACE_AROUND}>
          {manageableColumns.map((manageableColumn) => (
            <ManageColumnWrapped
              title={manageableColumn.label}
              key={manageableColumn.label}
              options={manageableColumn.columns}
              selectedOptions={selectedOptions}
              onChangeOption={handleChange}
              disableUncheck={disableUncheck}
            />
          ))}
        </FlexBox>

        <FlexBox flexDirection={FlexAttributValue.ROW} justifyContent={FlexAttributValue.SPACE_BETWEEN} marginTop="20px">
          <OutlinedButton onClick={onReset}>
            <FormattedMessage id="col_man_reset_button" />
          </OutlinedButton>
          <FlexBox flexDirection={FlexAttributValue.ROW}>
            <DiscardButtonStyled sx={{ marginRight: 1 }} onClick={onClose}>
              <FormattedMessage id="discard" />
            </DiscardButtonStyled>
            <ContainedButton
              onClick={() => {
                onChangeColumns(selectedOptions);
                onCloseDialog();
              }}>
              <FormattedMessage id={submitMessage} />
            </ContainedButton>
          </FlexBox>
        </FlexBox>
      </CardPopup>
    </DialogMaterialUI>
  );
};

export default ManageColumnDialog;
