import {NotificationResponse} from "../../interfaces/notification/notification";
import {createContext, useContext} from "react";

interface NotificationContextInstance {
    userHomepageActiveNotifications: Array<NotificationResponse>,
    userActiveNotifications: Array<NotificationResponse>,
    userHiddenNotifications: Array<NotificationResponse>,
    hideNotification: (notificationToHide: NotificationResponse) => void,
    hasUserActiveNotifications: boolean,
    hasUserHiddenNotifications: boolean,
    userActiveNotificationsCount: number,
    onChangePage: () => void,
    last: boolean,
    isLoading: boolean,
    onHideAll: () => void
}

/**
 * Context used because notifications are shared between Dashboard and HeaderNotification components
 */
export const NotificationContext = createContext<NotificationContextInstance>({
    userHomepageActiveNotifications: [],
    userActiveNotifications: [],
    userHiddenNotifications: [],
    hideNotification: null,
    hasUserActiveNotifications: false,
    hasUserHiddenNotifications: false,
    userActiveNotificationsCount: 0,
    onChangePage: null,
    last: false,
    isLoading: false,
    onHideAll: null
})

export const useNotificationContext = () => useContext(NotificationContext)
