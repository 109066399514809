import {ValidateErrorMap} from "../../components/molecules/useraccount/edit/UserAccountEdit";
import {stringUtils} from "../common/stringUtils";

/**
 * Validate View informations
 * @param name
 */
function validateViewName(name: string): ValidateErrorMap {
    let errors = {};
    if (stringUtils.isEmpty(name)) {
        return {SV_NAME_IS_REQUIRED: true}
    }

    if(name.length < 3) {
        errors = {SV_NAME_IS_TOO_SHORT: true}
    }

    if (Object.keys(errors).length > 0) {
        return errors;
    }

    return {};
}


export const validationErrorsViewUtils = {
    validateViewName,
}
