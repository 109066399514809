import {httpService} from "../HttpService";
import {
    Entity,
    Perimeter, PerimeterCount,
    PerimeterResponse,
} from "../../interfaces/perimeter/perimeter";
import {arrayUtils} from "../../utils/common/arrayUtils";
import {PerimeterItemUpdate, perimeterUtils} from "../../utils/perimeter/perimeterUtils";
import {queryUtils} from "../../utils/api/queryUtils";
import {SearchResponse} from "../../interfaces/api/ApiInterfaces";
import {AccountResponse} from "../../interfaces/perimeter/account";
import {AuthorizedMerchantsResponse, MerchantDetailResponse} from "../../interfaces/perimeter/merchant";
import {CompanyDetailResponse} from "../../interfaces/perimeter/company";
import { Options } from "constants/options/option";

export const NUMBERS_ELEMENTS = 70;
export const NUMBERS_ELEMENTS_WITH_FILTER = 15;

function completeSelfRight(item: AccountResponse){
    return {...item, name: item.name.toUpperCase(), hasRightOn: true}
}

/**
 * PerimeterResponse to Perimeter
 * @param response
 */
function resolvePerimeter(response: Array<PerimeterResponse>): Perimeter {
    if (arrayUtils.isEmpty(response)) {
        return null
    }
    // We only take the first account because for now a user can only have ONE account perimeter
    const firstAccount = response[0];
    return perimeterUtils.responseToPerimeter(firstAccount)
}


/**
 * Get your own full Perimeter
 */
function getSelfPerimeter(options?: RequestInit): Promise<Perimeter> {
    return new Promise<Perimeter>((resolve, reject) => {
        httpService.get<Array<PerimeterResponse>>("/perimeters/self", options)
            .then(res => resolve(resolvePerimeter(res)))
            .catch(reject)
    });
}

/**
 * Get a user full Perimeter
 */
function getPerimeter(idUser: number, options?: RequestInit): Promise<Perimeter> {
    return new Promise<Perimeter>((resolve, reject) => {
        httpService.get<Array<PerimeterResponse>>(`/perimeters/${idUser}`, options)
            .then(res => resolve(resolvePerimeter(res)))
            .catch(reject)
    });
}

function getAuthorizedAccounts(options?: RequestInit, searchedAccount?: string): Promise<Array<AccountResponse>> {
    const nameParam = searchedAccount ? `&name=${searchedAccount}` : "";
    return new Promise<Array<AccountResponse>>((resolve, reject) => {
        httpService.get<Array<AccountResponse>>(`/perimeters/accounts?sort=name,asc${nameParam}`, options)
            .then(res => resolve(res.map(completeSelfRight)))
            .catch(reject)
    });
}

function getUserAuthorizedAccounts(idUser: string,options?: RequestInit, searchedAccount?: string): Promise<Array<AccountResponse>> {
    const nameParam = searchedAccount ? `&name=${searchedAccount}` : "";
    return new Promise<Array<AccountResponse>>((resolve, reject) => {
        httpService.get<Array<AccountResponse>>(`/perimeters/${idUser}/accounts?sort=name,asc${nameParam}`, options)
            .then(res => resolve(res.map(completeSelfRight)))
            .catch(reject)
    });
}

function getDetailsAuthorizedCompanies(idAccount: string, options?: RequestInit): Promise<PerimeterResponse> {
    return new Promise<PerimeterResponse>((resolve, reject) => {
        httpService.get<PerimeterResponse>(`/perimeters/accounts/${idAccount}`, options)
            .then(res => resolve(perimeterUtils.responseToUppercaseResponse(res)))
            .catch(reject)
    });
}

function getUserDetailsAuthorizedCompanies(idUser: string,idAccount: string, options?: RequestInit): Promise<PerimeterResponse> {
    return new Promise<PerimeterResponse>((resolve, reject) => {
        httpService.get<PerimeterResponse>(`/perimeters/${idUser}/accounts/${idAccount}`, options)
            .then(res => resolve(perimeterUtils.responseToUppercaseResponse(res)))
            .catch(reject)
    });
}

/**
 * Authorized merchants by idCompany
 * @param idCompany
 * @param options
 */
function getMerchantsCompanies(idCompany: string, options?: RequestInit): Promise<AuthorizedMerchantsResponse> {
    return new Promise<AuthorizedMerchantsResponse>((resolve, reject) => {
        httpService.get<AuthorizedMerchantsResponse>(`/perimeters/companies/${idCompany}/merchants`, options)
            .then(res => resolve({
                ...res,
                merchants: res.merchants.map(item => ({...item, name: item.name.toUpperCase(), hasRightOn: true}))
            }))
            .catch(reject)
    });

}

/**
 * Authorized merchants by idAccount
 * @param idAccount
 * @param options
 */
function getAuthorizedMerchantsByAccount(idAccount: string, options?: RequestInit): Promise<PerimeterResponse> {
    return new Promise<PerimeterResponse>((resolve, reject) => {
        httpService.get<PerimeterResponse>(`/perimeters/accounts/${idAccount}`, options)
            .then(res => resolve({
                ...res,
                name: res.name.toUpperCase(),
                companies: res.companies.map(item => ({
                    ...item,
                    name: item.name.toUpperCase(),
                    merchants: item.merchants.map(merchant => ({...merchant, name: merchant.name.toUpperCase(), hasRightOn: true}))
                }))
            }))
            .catch(reject)
    });
}

/**
 * Update a perimeter based on PerimeterItemUpdate structure
 * You can't give perimeter you don't have access to
 * @param perimeters
 * @param idUser
 * @param options
 */
function updatePerimeter(perimeters: Array<PerimeterItemUpdate>, idUser: string, options?: RequestInit): Promise<VoidFunction> {
    return httpService.put(`/perimeters/${idUser}`, {perimeters}, options)
}

/**
 * Create a perimeter based on PerimeterItemUpdate structure
 * You can't give perimeter you don't have access to
 * @param perimeters
 * @param idUser
 * @param options
 */
function createPerimeter(perimeters: Array<PerimeterItemUpdate>, idUser: string, options?: RequestInit): Promise<VoidFunction> {
    return httpService.post(`/perimeters/${idUser}`, {perimeters}, options)
}

function getSearchReferentialQueryParams(referential: string, search?: string, additionalQuery?: string, page = 0): string {
    const nameParam = search ? `&name=${search}` : "";
    const pagination = queryUtils.queryParamToPageable({page: page, pageSize: search === "" ? NUMBERS_ELEMENTS : NUMBERS_ELEMENTS_WITH_FILTER});
    return `${referential}${pagination}${nameParam}${additionalQuery}&sort=name,asc`
}

export interface SearchMerchant {
    account: Options;
    company: Options;
    merchant: Options;
}

function searchMerchantById(springId:string): Promise<SearchMerchant> {
    return  httpService.get<SearchMerchant>(`/helpers/merchant-info/${springId}`)
}

function searchReferential(referential: string, options?: RequestInit, search = "", additionalQuery = "", page = 0): Promise<SearchResponse<Entity>> {

    return new Promise<SearchResponse<Entity>>((resolve, reject) => {
        httpService.get<SearchResponse<Entity>>(`/referential/${getSearchReferentialQueryParams(referential, search, additionalQuery, page)}`, options)
            .then(res => resolve({
                ...res,
                content: res.content.map(item => ({...item, name: item.name.toUpperCase()}))
            }))
            .catch(reject)
    });
}



function searchAccounts(options?: RequestInit, search?: string, page?: number): Promise<SearchResponse<Entity>> {
    return searchReferential("accounts", options, search, "", page)
}

function searchCompanies(options?: RequestInit, search?: string, accounts?: string[], page?: number): Promise<SearchResponse<CompanyDetailResponse>> {
    const accountsParam = !arrayUtils.isEmpty(accounts) ? `&accounts=${accounts?.join(",")}` : "";
    return searchReferential("companies", options, search, accountsParam, page)
}

function searchMerchants(options?: RequestInit, search?: string, accounts?: string[], companies?: string[],page?: number): Promise<SearchResponse<MerchantDetailResponse>> {
    const companiesParam = !arrayUtils.isEmpty(companies) ? `&companies=${companies?.join(",")}` : "";
    const accountsParam = !arrayUtils.isEmpty(accounts) ? `&accounts=${accounts?.join(",")}` : "";
    return searchReferential("merchants", options, search, `${companiesParam}${accountsParam}`, page)
}

function count(options?: RequestInit): Promise<PerimeterCount>  {
    return httpService.get("/perimeters/count",  {signal: options?.signal})
}

export const perimeterService = {
    getSelfPerimeter,
    getPerimeter,
    getMerchantsCompanies,
    updatePerimeter,
    createPerimeter,
    getAuthorizedAccounts,
    getUserAuthorizedAccounts,
    getAuthorizedMerchantsByAccount,
    getDetailsAuthorizedCompanies,
    getUserDetailsAuthorizedCompanies,
    searchCompanies,
    searchMerchants,
    searchAccounts,
    count,
    searchMerchantById
}
