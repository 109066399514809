import {styled} from "@mui/material/styles";
import {paletteTheme} from "../../themes/palette";
import {FormControlLabelProps, FormControlLabel} from "@mui/material";

export default styled(FormControlLabel)<FormControlLabelProps>(({checked}) => {
    return ({
        margin: 0,
        "& .MuiFormControlLabel-label": checked && {
            color: paletteTheme.colors.PRIMARY
        },
        "& .MuiRadio-root": {
            padding: "2px"
        },
    });
});
