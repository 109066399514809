const EMAIL_PATTERN_STRING = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const PASSWORD_PATTERN_STRING = /[~`!@#$%^&*()_\-+={\[}\]|\\:;"'<,>.?\/]/;
const NAME_PATTERN_STRING = /^[A-Za-zÀ-ÿ\s-'œ]+$/;
export const MIN_MAX_AMOUNT_PATTERN = /^\d{1,6}(?:,\d{0,2}|\.\d{0,2})?$/;


function checkEmail(email: string): boolean {
    return EMAIL_PATTERN_STRING.test(email);
}

function checkPasswordHasSpecial(password: string) {
    return PASSWORD_PATTERN_STRING.test(password);
}

function checkName(name: string): boolean {
    return NAME_PATTERN_STRING.test(name);
}

export const patternUtils = {
    checkEmail,
    checkPasswordHasSpecial,
    checkName
}
