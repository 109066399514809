import React, {
  FunctionComponent,
  useRef,
} from "react";
import { useIntl } from "react-intl";
import { filterTooltipUtils } from "utils/filterTooltipUtils";
import { useDebouncedInputEvent } from "hooks/input/useDebouncedInputEvent";
import FilterTooltip from "components/molecules/tooltip/FilterTooltip";
import { OutlinedTextField } from "components/atoms/textfield/OutlinedTextField";
import { InputBaseProps } from "@mui/material/InputBase";
import styles from "components/molecules/textfield/FilterTextField/filterTextField.module.scss";

export interface FilterTextFieldProps {
  filterId: string;
  filterLabel: string;
  value: string;
  onChangeFilter: (field: string) => (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  inputProps?: InputBaseProps["inputProps"];
}

export const FilterTextField: FunctionComponent<FilterTextFieldProps> = ({
  filterId,
  filterLabel,
  value: valueProps,
  onChangeFilter,
  inputProps,
}) => {
  const intl = useIntl();

  const { value, onChange } = useDebouncedInputEvent((event) => onChangeFilter(filterId)(event), valueProps);

  const divRef = useRef<HTMLDivElement>()
  const isOverflow = filterTooltipUtils.isOverflow(divRef.current?.firstElementChild.firstElementChild);

  return (
    <FilterTooltip overflowText={intl.formatMessage({ id: filterLabel })} placement="top" openTooltip={isOverflow}>
      <div ref={divRef} className={styles.wrap}>
        <OutlinedTextField
          label={intl.formatMessage({ id: filterLabel })}
          value={value}
          inputProps={inputProps}
          onChange={onChange}
        />
      </div>
    </FilterTooltip>
  )
}
