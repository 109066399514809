import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import FlexBox from "../../../atoms/flex/FlexBox";
import { FlexAttributValue } from "../../../../types/flex/flex";
import { Typography } from "@mui/material";
import { ManageColumnOptions } from "./ManageColumnOptions";
import { ManagableColumn } from "interfaces/table/table";

interface ManageColumnWrappedProps {
  options: ManagableColumn[],
  selectedOptions: ManagableColumn[],
  onChangeOption: (option: ManagableColumn, e: React.ChangeEvent<HTMLInputElement>) => void,
  disableUncheck?: boolean,
  title: string
}

const ManageColumnWrapped: FunctionComponent<ManageColumnWrappedProps> = ({
  options,
  selectedOptions,
  onChangeOption,
  title,
  disableUncheck = false,
}) => (
  <FlexBox flexDirection={FlexAttributValue.COLUMN} justifyContent={FlexAttributValue.START}>
    <Typography variant="h2" fontSize={14} marginBottom={"8px"}>
      <FormattedMessage id={title} />
    </Typography>
    <ManageColumnOptions
      options={options}
      selectedOptions={selectedOptions}
      onChangeOption={onChangeOption}
      disableUncheck={disableUncheck}
    />
  </FlexBox>
);

export default ManageColumnWrapped;
