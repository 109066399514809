import React, {
    FunctionComponent,
    useEffect,
    useState
} from "react";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import {
    Outlet,
    RouteProps,
} from "react-router-dom";
import { NotificationContext } from "constants/context/NotificationContext";
import { useNotification } from "hooks/notification/useNotification";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { Header } from "components/molecules/header/Header";
import { SideMenu } from "components/molecules/menu/SideMenu";
import { Typography } from "@mui/material";
import { MP_THEME } from "styles/themes/theme";
import styles from "components/layouts/AppLayout/appLayout.module.scss";
import { ToastContainer } from "react-toastify";

/**
 * General app layout menu layout
 */

export const AppLayout: FunctionComponent<RouteProps> = ({
}) => {
    const [reduce, setReduce] = useState<boolean>(localStorage.getItem(LocalStorageEnum.MENU_SETTINGS) === "reduce");
    const [mobileMenuOpen, setMenuOpen] = useState(false);
    const [mobile, setIsMobile] = useState(false);

    const { mdDown } = useMediaQueryUtil();

    const {
        userHomepageActiveNotifications,
        userActiveNotifications,
        userHiddenNotifications,
        hideNotification,
        hasUserActiveNotifications,
        hasUserHiddenNotifications,
        userActiveNotificationsCount,
        onChangePage,
        last,
        isLoading,
        onHideAll
    } = useNotification();


    const handleReduce = () => {
        setReduce(!reduce);
        localStorage.setItem(LocalStorageEnum.MENU_SETTINGS, !reduce ? "reduce" : "full");
    }

    const handleMenuClick = () => {
        setMenuOpen(prev => !prev);
    };

    const handleOnCloseMenu = () => {
        if (mobileMenuOpen) {
            setMenuOpen(false);
        }
    }

    useEffect(() => {
        setIsMobile(mdDown);
        setMenuOpen(false);
        // to avoid reduced menu size, we set reduce to false, on a small screen
        if (mdDown) {
            setReduce(false);
        }
    }, [mdDown]);

    // style consts:
    const stackStyles = `${styles["stack-app-layout"]} ${reduce ? styles.reduce : ""}`;
    const appContentStyles = `${styles["box-app-content"]} ${reduce ? styles.reduce : ""}`;

    return (
        <>
            <NotificationContext.Provider value={{
                userHomepageActiveNotifications,
                userActiveNotifications,
                userHiddenNotifications,
                hideNotification,
                hasUserActiveNotifications,
                hasUserHiddenNotifications,
                userActiveNotificationsCount,
                onChangePage,
                last,
                isLoading,
                onHideAll,
            }}>
                <div className={stackStyles}>
                    <Header mobileMenuOpen={mobileMenuOpen} mobile={mobile} onMenuClick={handleMenuClick} />
                    <div className={styles.sidebar}>
                        <SideMenu
                            onReduce={handleReduce}
                            reduce={reduce}
                            mobileMenuOpen={mobileMenuOpen}
                            mobile={mobile}
                            onCloseMobileMenu={handleOnCloseMenu}
                        />
                    </div>
                    <div className={appContentStyles}>
                        <Outlet />
                        <ToastContainer />
                        <Typography textAlign={"end"} color={MP_THEME.palette.primary.main}>{process.env.REACT_APP_VERSION}</Typography>
                    </div>
                </div>
            </NotificationContext.Provider>
        </>
    );
}
