import {useState} from "react";
import {resetPasswordTokenService} from "../../services/resetPasswordToken/ResetPasswordTokenService";
import {toastUtils} from "../../utils/toast/toastUtils";
import {CellProps} from "react-table";
import {UserData} from "../../view/UserSearchView";

interface UseInitUser {
    isClicked: boolean,
    isLoading: boolean,
    onInitUser: () => void
}

interface UserInfo {
    email: string,
    initialized: boolean
}

export const useInitUser = (userInfo: UserInfo, cell?: CellProps<UserData>): UseInitUser => {
    const [isClicked, setIsClicked] = useState<boolean>(userInfo.initialized);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onInitUser = () => {
        // Generating token initialize the account!
        resetPasswordTokenService.createToken(userInfo.email)
            .then(() => {
                setIsClicked(true);
                setIsLoading(false);

                /*
                * Manually updating the row after sending the email to avoid rerendering with previous data
                * Not clean but have not found a better way to do this
                */
                if (!!cell) {
                    cell.row.original.initialized = true;
                }
                toastUtils.successToastI8ln("welcome_email_sent")
            })
            .catch(() => {
                    setIsLoading(false)
                    toastUtils.errorToastI8ln("welc_email_butt_error")
                }
            )
    };

    return {
        isClicked,
        isLoading,
        onInitUser
    }
}
