import React, { FunctionComponent } from "react";
import { Logo } from "components/atoms/Logo";
import { Outlet } from "react-router-dom";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import Card from "@mui/material/Card";
import styles from "components/layouts/AuthLayout/authLayout.module.scss"

const authPadding = (smUp: boolean, mdUp: boolean) => {
    let padding: React.CSSProperties["padding"] = "24px 5%";
    if (mdUp) {
        padding = "24px 96px";
    }
    else if (smUp) {
        padding = "24px 7%";
    }
    return {
        padding
    };
}

export const AuthLayout: FunctionComponent = () => {

    const { smUp, mdUp } = useMediaQueryUtil();
    const { padding } = authPadding(smUp, mdUp);

    return <div className={styles.wrapper}>
        <Card
            className={styles.card}
            style={{ padding: padding }}
        >
            <Logo />
            <Outlet />
        </Card>
    </div >
}
