import {
  CHANGE_PASSWORD_PATH,
  MY_ACCOUNT_PATH,
  SETTLEMENTS_PATH,
  PORTFOLIO_PATH,
  NOTIFICATION_SETTINGS_PATH,
  MY_REPORTS_PATH,
  DOCUMENTS_PATH,
} from "constants/routes/RoutePaths";
import HomeView from "view/HomeView";
import { AppRouteInterface } from "interfaces/RouteInterfaces";
import NotificationSettingsView from "view/NotificationSettingsView/NotificationSettingsView";
import AccountView from "view/AccountView";
import ChangePasswordView from "view/ChangePasswordView";
import { UserRoutes } from "constants/routes/subroutes/UserRoutes";
import { SettlementView } from "view/SettlementView";
import { PERMISSIONS } from "constants/permissions/permissions";
import { ReportsView } from "view/ReportsView";
import { PortfolioView } from "view/PortfolioView";
import { AcceptedTransactionRoutes } from "constants/routes/subroutes/AcceptedTransactionRoutes";
import { DocumentsView } from "view/DocumentsView";
import { AcquiredTransactionRoutes } from "constants/routes/subroutes/AcquiredTransactionRoutes";

const MenuRoutes: AppRouteInterface[] = [
  {
    private: true,
    index: true,
    element: HomeView,
  },
  {
    path: SETTLEMENTS_PATH,
    private: true,
    index: true,
    element: SettlementView,
    permission: PERMISSIONS.SETTLEMENTS_CONSULT,
  },
  {
    path: PORTFOLIO_PATH,
    private: true,
    index: true,
    element: PortfolioView,
    // permission: PERMISSIONS.PORTFOLIO_CONSULT,
    permission: PERMISSIONS.SETTLEMENTS_CONSULT, // TODO rollback to portfolio permissions
  },
  {
    path: MY_ACCOUNT_PATH,
    element: AccountView,
    private: true,
    index: true,
  },
  {
    path: DOCUMENTS_PATH,
    element: DocumentsView,
    private: true,
    index: true,
  },
  {
    path: MY_REPORTS_PATH,
    element: ReportsView,
    private: true,
    index: true,
  },
  {
    path: CHANGE_PASSWORD_PATH,
    element: ChangePasswordView,
    private: true,
    index: true,
  },
  {
    path: NOTIFICATION_SETTINGS_PATH,
    element: NotificationSettingsView,
    private: true,
    index: true,
  },

  UserRoutes,
  AcceptedTransactionRoutes,
  AcquiredTransactionRoutes
]

export default MenuRoutes;
