import { Referential } from "../../interfaces/UserInterface";
import { MaterialUiColor } from "../../types/materialui/materialui";

export const PROFIL_USER: Referential = {
  MERCHANT_ADMIN: { id: "MERCHANT_ADMIN", label: "merchant_admin", color: MaterialUiColor.WARNING },
  ADMIN: { id: "ADMIN", label: "market_pay_admin", color: MaterialUiColor.SECONDARY },
  OPERATOR: { id: "OPERATOR", label: "operator", color: MaterialUiColor.INFO },
  SPRING: { id: "SPRING", label: "profil_spring", color: MaterialUiColor.ERROR },
  JOB_ADMIN: { id: "JOB_ADMIN", label: "job_admin", color: MaterialUiColor.ERROR },
};

export type UserPossibleColumns =
  | "FIRST_NAME"
  | "LAST_NAME"
  | "ACCOUNT_NAME"
  | "PERIMETER_LEVEL"
  | "ACCOUNT_TYPE"
  | "CREATION_DATE"
  | "LAST_ACTIVITY"
  | "WELCOME_EMAIL"
  | "ACCOUNT_EMAIL";
