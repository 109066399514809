import {
    Entity,
    PerimeterResponse,
    PerimeterSelectorParam,
    PerimeterUpdate,
    UpdateEntity
} from "../interfaces/perimeter/perimeter";
import {perimeterService} from "../services/user/PerimeterService";
import {errorUtils} from "../utils/api/errorUtils";
import {ChangeEvent, useEffect, useState} from "react";
import {arrayUtils} from "../utils/common/arrayUtils";
import {perimeterUtils} from "../utils/perimeter/perimeterUtils";
import {perimeterSelectorUtils} from "../utils/perimeter/perimeterSelectorUtils";
import {AuthorizedMerchantResponseByCompany, AuthorizedMerchantsResponse} from "../interfaces/perimeter/merchant";
import {AuthorizedCompanyResponse, CompanyUpdate} from "../interfaces/perimeter/company";
import {Options} from "../constants/options/option";

interface PerimeterSelectorInstance {
    remainingCompanies: Array<Entity>,
    authorizedMerchantResponseByCompany: AuthorizedMerchantResponseByCompany,
    onRemoveCompany: (company: UpdateEntity) => void,
    onChangeAccount: (values: Array<Options>) => void,
    onChangeCompany: (option: Options, index: number) => void,
    onChangeMerchants: (merchants: Entity[], index: number, hasAllMerchants: boolean) => void,
    onChangeCompanyTypePerimeter?: (checked: boolean, index: number) => void,
    onChangeAccountTypePerimeter?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    onAddCompany: () => void,
    allCompaniesAdded: boolean,
    onAddAllCompanies: (idAccount: string) => void,
    setAllCompaniesAdded: (boolean: boolean) => void,
    onRemoveAddAllCompanies: () => void,
    onGetAuthorizedMerchantsByAccount: (res: PerimeterResponse, initialPerimeterCalculated: PerimeterUpdate) => void,
    onChangeAuthorizedMerchants: (idCompany: string, authorizedMerchantsResponse: AuthorizedMerchantsResponse) => void,
    authorizedCompanies: AuthorizedCompanyResponse,
}

export const usePerimeterSelector = (authorizedAccounts: Array<Entity>,
                                     {
                                         initialPerimeter,
                                         editedPerimeter
                                     }: PerimeterSelectorParam,
                                     onChangePerimeter: (perimeter: PerimeterUpdate) => void)
    : PerimeterSelectorInstance => {

    const [remainingCompanies, setRemainingCompanies] = useState<Array<Entity>>([]);
    // AddAllCompany button state
    const [allCompaniesAdded, setAllCompaniesAdded] = useState<boolean>(editedPerimeter.hasAccountPerimeter);
    const [authorizedMerchantResponseByCompany, setAuthorizedMerchantResponseByCompany] = useState<AuthorizedMerchantResponseByCompany>({});
    const [authorizedCompanies, setAuthorizedCompanies] = useState<AuthorizedCompanyResponse>({companies: []});

    const onGetAuthorizedMerchantsByAccount = (res: PerimeterResponse, initialPerimeterCalculated: PerimeterUpdate) => {
        // Array<CompanyDetailResponse> to AuthorizedMerchantsResponseByCompany
        const newAuthorizedMerchantsResponseByCompany: AuthorizedMerchantResponseByCompany = {};
        res.companies.forEach(item => newAuthorizedMerchantsResponseByCompany[item.id] = item);
        // Format perimeter to check if user has all authorized merchants
        const authorizedPerimeter: PerimeterUpdate = perimeterSelectorUtils.aggregatePerimeterWithAuthorizedPerimeter(res, initialPerimeterCalculated)
        setAuthorizedCompanies(perimeterSelectorUtils.perimeterResToAuthorizedCompanyResponse(res))
        // Update perimeter and auto switch addAllCompany button if perimeter has all merchants or account perimeter
        onChangePerimeter(authorizedPerimeter)
        setAllCompaniesAdded(authorizedPerimeter.companies.length > 0 &&
            (authorizedPerimeter.hasAccountPerimeter ||
                perimeterSelectorUtils.hasRightCompanyOrAllMerchants(authorizedPerimeter, res)))
        setAuthorizedMerchantResponseByCompany(newAuthorizedMerchantsResponseByCompany)
    }

    const getRemainingCompaniesCalculated = (companies: Array<Entity>, editedCompanies: Array<Entity>): Array<Entity> => {
        const entities = arrayUtils.arrayOfUnique<Entity>([...authorizedCompanies.companies, ...companies]);
        return entities.filter(company => !editedCompanies.some(item => company.id === item.id));
    }

    /**
     * Add All authorized company and all authorized merchants to companies
     * @param idAccount
     */
    const onAddAllCompanies = (idAccount: string) => {
        perimeterService.getDetailsAuthorizedCompanies(idAccount).then(res => {
            setAllCompaniesAdded(true)
            setRemainingCompanies([])
            onChangePerimeter({
                ...editedPerimeter,
                companies: perimeterSelectorUtils.computeAllCompaniesFromAllCompanies(res.companies, editedPerimeter.companies)
            });
        }).catch(errorUtils.handleBackErrors)
    }

    const onRemoveAddAllCompanies = () => {
        const companies = initialPerimeter.companies.map(company => company.company);
        const editedCompanies: Array<CompanyUpdate> = [...editedPerimeter.companies];
        const companiesAddedManually: Array<CompanyUpdate> = editedCompanies.filter(company => !company.addedByAddAllCompanies);

        setAllCompaniesAdded(false)

        const perimeter: PerimeterUpdate = {
            ...editedPerimeter,
            hasAccountPerimeter: false,
            companies: companiesAddedManually.map(company => ({
                ...company,
                merchants: company.merchants.filter(merchant => !merchant.addedByAddAllCompanies)
            }))
        };

        // Avoid people to delete all perimeters via the addAll button
        // Add an empty company perimeter if no companies has been added manually
        if (companiesAddedManually.length === 0) {
            perimeter.companies = [perimeterUtils.buildEmptyCompanyPerimeter()]
        }
        setRemainingCompanies(getRemainingCompaniesCalculated(companies, companiesAddedManually.map(item => item.company)))

        onChangePerimeter(perimeter);
    }

    const onAddCompany = () => {
        const companies = initialPerimeter.companies.map(company => company.company);
        const editedCompanies: Array<CompanyUpdate> = editedPerimeter.companies;
        const newEditedCompanies: Array<CompanyUpdate> = [...editedCompanies, perimeterUtils.buildEmptyCompanyPerimeter()];
        setRemainingCompanies(getRemainingCompaniesCalculated(companies, newEditedCompanies.map(item => item.company)))
        onChangePerimeter({...editedPerimeter, companies: newEditedCompanies})
    }

    const onRemoveCompany = (company: UpdateEntity): void => {
        const companies = initialPerimeter.companies.map(_company => _company.company);
        const tempRemainingCompanies = editedPerimeter.companies.filter(item => item.company.uniqueKey !== company.uniqueKey);
        setRemainingCompanies(getRemainingCompaniesCalculated(companies, tempRemainingCompanies.map(item => item.company)));
        setAllCompaniesAdded(false)
        onChangePerimeter({
            ...editedPerimeter,
            hasAccountPerimeter: false,
            companies: tempRemainingCompanies
        });
    }

    const onChangeAccount = (accounts: Array<Options>): (() => void) => {
        const ac = new AbortController();
        onChangePerimeter({
            ...editedPerimeter,
            account: authorizedAccounts.find(authorizedAccount => authorizedAccount.id === accounts?.[0].id),
            companies: accounts?.[0] ? [perimeterUtils.buildEmptyCompanyPerimeter()] : []
        });

        if (!!accounts?.[0].id) {
            perimeterService.getAuthorizedMerchantsByAccount(accounts?.[0].id)
                .then(res => onGetAuthorizedMerchantsByAccount(res, initialPerimeter))
                .catch(errorUtils.handleBackErrors)
        }

        return () => ac.abort();
    }

    /**
     * on Company select
     * This event is considered to be called manually
     * It tags edited companies as manual
     *
     * If all merchants are added, set addAllCompany switch to checked
     * @param option
     * @param index - Company index
     */
    const onChangeCompany = (option: Options, index: number): void => {
        const newEditedCompanies = [...editedPerimeter.companies]
        const companies = initialPerimeter.companies.map(_company => _company.company);
        const changeCompany = remainingCompanies.find(_company => _company.id === option?.id);

        newEditedCompanies[index] = {
            ...newEditedCompanies[index],
            addedByAddAllCompanies: false,
            company: {
                ...changeCompany,
                hasRightOn: authorizedCompanies.companies.some(item => item.id === changeCompany.id),
                uniqueKey: newEditedCompanies[index].company.uniqueKey
            },
            merchants: initialPerimeter.companies.find(_company => _company.company.id === changeCompany.id)?.merchants || []
        }

        setRemainingCompanies(getRemainingCompaniesCalculated(companies, newEditedCompanies.map(item => item.company)));
        setAllCompaniesAdded(remainingCompanies.length === 0 && newEditedCompanies.every(company => company.hasAllMerchants))
        onChangePerimeter({
            ...editedPerimeter,
            hasAccountPerimeter: false,
            companies: newEditedCompanies
        });
    }


    const onEditCompany = (newCompany: CompanyUpdate, index: number) => {
        const newEditedCompanies = [...editedPerimeter.companies]
        newEditedCompanies[index] = {
            ...newCompany
        }
        onChangePerimeter({
            ...editedPerimeter,
            hasAccountPerimeter: false,
            companies: newEditedCompanies
        });

        setAllCompaniesAdded(remainingCompanies.length === 0 && newEditedCompanies.every(company => company.hasAllMerchants || company.addedByAddAllCompanies))
    }

    /**
     * On add/remove chips from merchants select
     * This event is considered to be called manually
     * It tags edited companies/merchants as manual
     *
     * If all merchants are added, set addAllCompany switch to checked
     * @param merchants
     * @param index
     * @param hasAllMerchants
     */
    const onChangeMerchants = (merchants: Entity[], index: number, hasAllMerchants: boolean): void => {
        const companyUpdate = editedPerimeter.companies[index];
        const editedCompany: CompanyUpdate = {
            ...companyUpdate,
            addedByAddAllCompanies: false,
            merchants,
            hasAllMerchants: hasAllMerchants,
            hasCompanyPerimeter: hasAllMerchants && companyUpdate.hasCompanyPerimeter
        };

        onEditCompany(editedCompany, index);
    }

    const onChangeCompanyTypePerimeter = (checked: boolean, index: number): void => {
        const editedCompany: CompanyUpdate = {
            ...editedPerimeter.companies[index],
            hasCompanyPerimeter: checked
        };
        onEditCompany(editedCompany, index);
    }

    const onChangeAccountTypePerimeter = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onChangePerimeter({
            ...editedPerimeter,
            hasAccountPerimeter: checked,
        });
    }

    const onChangeAuthorizedMerchants = (idCompany: string, authorizedMerchantsResponse: AuthorizedMerchantsResponse) => {
        setAuthorizedMerchantResponseByCompany({
            ...authorizedMerchantResponseByCompany,
            [idCompany]: authorizedMerchantsResponse
        });
    }

    // Calculated remaining companies when authorizedCompanies is updated
    useEffect(() => {
        const companies = initialPerimeter.companies.map(company => company.company);
        setRemainingCompanies(getRemainingCompaniesCalculated(companies, companies))
    }, [authorizedCompanies])

    return {
        remainingCompanies,
        allCompaniesAdded,
        authorizedMerchantResponseByCompany,
        authorizedCompanies,
        onAddCompany,
        onRemoveCompany,
        onChangeAccount,
        onChangeCompany,
        onChangeMerchants,
        onChangeCompanyTypePerimeter,
        onChangeAccountTypePerimeter,
        onRemoveAddAllCompanies,
        onAddAllCompanies,
        setAllCompaniesAdded,
        onGetAuthorizedMerchantsByAccount,
        onChangeAuthorizedMerchants,
    }

}
