import React from "react";
import {Options} from "../../constants/options/option";

interface UseOnChangeAutocomplete {
    handleUniqueChange: (e: React.SyntheticEvent, value: Options[]) => void;
}

/**
 * Manage on change functions autocomplete
 */
export const useOnChangeAutocomplete = (onChange: (values: Array<Options>) => void, values: Array<Options>): UseOnChangeAutocomplete => {

    const handleUniqueChange = (e: React.SyntheticEvent, value: Options[]) => {
        const newOptions = value.find(item => values.every(item2 => item.id !== item2.id));
        onChange((newOptions && [newOptions]) || [])
    }

    return {
        handleUniqueChange
    }
}
