import React, { FunctionComponent } from "react";
import { ErrorReferentialLine } from "interfaces/error/error";
import { MaterialUiColor } from "types/materialui/materialui";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import Label from "components/atoms/Label";
import ErrorMessage from "components/atoms/ErrorMessage";
import { OutlinedTextFieldProps } from "components/atoms/textfield/OutlinedTextField";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material";
import LoginTextFieldStyled from "styles/components/textfield/LoginTextFieldStyled";
import styles from "components/atoms/input/LoginTextInput/loginTextInput.module.scss"

export interface InputProps extends OutlinedTextFieldProps {
    className?: string,
    id: string,
    label: string,
    error?: boolean,
    errors?: Record<string, boolean>,
    possibleErrors?: Array<ErrorReferentialLine>,
    required?: boolean,
    validated?: boolean,
    validationPill?: boolean,
    LabelSx?: SxProps<Theme>
}

export const LoginTextInput: FunctionComponent<InputProps> = ({
    className,
    id,
    label,
    error = false,
    possibleErrors = [],
    errors = {},
    required = false,
    validationPill = false,
    validated = false,
    LabelSx = {},
    ...rest
}) => {

    const { smDown } = useMediaQueryUtil();

    const pillStyles = smDown ? {
        width: "0.85em",
        marginRight: "5px",
        marginRop: "-3px",
    } : {};

    return (
        <div className={styles.container}>
            <Label id={id} sx={LabelSx} required={required} className={`mp-label ${error ? "Mui-error" : ""}`}
                label={label}>
                <div>
                    <LoginTextFieldStyled
                        {...rest}
                        error={error}
                        id={id}
                        className={`mp-input-text ${className}`}
                        required={required}
                        style={{ width: "100%", paddingRight: "8px" }}
                    />
                    {validationPill && validated &&
                        <div className={styles["icon-wrapper"]}>
                            {
                                error ?
                                    <CancelRounded color={MaterialUiColor.ERROR} style={pillStyles} /> :
                                    <CheckCircle color={MaterialUiColor.PRIMARY} style={pillStyles} />}
                        </div>
                    }
                </div>
            </Label>
            <div className={styles["error-wrap"]}>
                {possibleErrors.map(_error => <ErrorMessage fontWeight={300} key={_error.id} error={_error.id}
                    errors={errors} />)}
            </div>
        </div>
    )
}
