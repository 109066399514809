import {TypographyOptions} from "@mui/material/styles/createTypography";
import {paletteTheme} from "./palette";

export const typography: TypographyOptions = {
    allVariants: {
        color: paletteTheme.colors.DEFAULT_TEXT_COLOR
    },
    h1: {
        fontSize: 16,
        textTransform: "uppercase",
        fontWeight: "bold"
    },
    h2: {
        fontSize: 16,
        fontWeight: 600
    },
    h3: {
        fontSize: 16,
        fontWeight: "normal"
    },
    h4: {
        fontSize: 14,
        fontWeight: 600
    },
    h5: {
        fontSize: 12,
        fontWeight: 600
    },
    h6: {
        fontSize: 10,
        fontWeight: "normal"
    },
    body1: {
        fontSize: 12,
    },
    body2: {
        fontSize: 14,
    },
    button: {
        textTransform: "none"
    },
    fontWeightBold: 700,
    fontWeightRegular: 400,

    fontFamily: [
        "Source Sans Pro",
        "sans-serif"
    ].join(","),

}
