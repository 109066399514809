import {httpService} from "../HttpService";
import {VIEW_ROUTE} from "../../constants/routes/ApiRoutes";
import {ViewCreateResponse, ViewGetResponse, ViewItemResponse} from "../../interfaces/view/view";
import {SearchResponse} from "../../interfaces/api/ApiInterfaces";
import {queryUtils} from "../../utils/api/queryUtils";

function create(view: ViewCreateResponse, options?: RequestInit): Promise<ViewCreateResponse> {
    return httpService.post(VIEW_ROUTE, {...view}, options)
}

function find(options?: RequestInit): Promise<SearchResponse<ViewItemResponse>> {
    const url = `${VIEW_ROUTE}${queryUtils.queryParamToPageable({
        page: 0,
        pageSize: 100,
        sortField: "name",
        sortDirection: "ASC"
    })}`
    return httpService.get(url, options)
}


function get(id: string, options?: RequestInit): Promise<ViewGetResponse> {
    return httpService.get(`${VIEW_ROUTE}/${id}`, options)
}

function _delete(id: string, options?: RequestInit): Promise<void> {
    return httpService.delete(`${VIEW_ROUTE}/${id}`, {}, options)
}

export const viewService = {
    create,
    find,
    get,
    delete: _delete
}
