import React, { FunctionComponent } from "react";
import { ChartProps } from "react-chartjs-2";
import {
    ChartTypeRegistry,
    LegendItem,
    TooltipItem,
} from "chart.js";
import Chart from "components/atoms/chart/Chart";
import { formatToEuro } from "constants/intl/number/format";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { CircularProgress } from "@mui/material";
import { paletteTheme } from "styles/themes/palette";
import { MP_THEME } from "styles/themes/theme";
import styles from "components/molecules/histogram/HistogramBody/BarLineChart/barLineChart.module.scss";

interface BarLineChartProps {
    linedChartLabel: string;
    barChartLabel: string;
    lineData: number[];
    barData: number[];
    linedChartAxisLabel?: string;
    barChartAxisLabel?: string;
    xAxis: string[];
    loading: boolean;
}

/**
 * Formats the currency for a given tooltip item.
 * @param {function} formatNumber A function for formatting numbers.
 * @return {function} A function for formatting the currency of a TooltipItem.
 */

const addCurrencySymbol = (
    formatNumber: (
        value: number,
        formatOptions: string | Intl.NumberFormatOptions,
    ) => string,
) => (numberToFormat: number): string => `${formatNumber(numberToFormat, formatToEuro)}`;

/**
Calculates the maximum value for a given array of numbers, multiplied by a given factor. If all values in the array are 0, the default value of 2 is returned.
@param {number[]} data Array of numbers to calculate the maximum value from
@param {number} [factor=1.1] Factor to multiply the maximum value by
@returns {number} The maximum value for the given array of numbers, multiplied by the given factor, or the default value of 2 if all values in the array are 0. 
This is needed to ensure that the graph is drawn even if all values in the array are 0.
*/
const calculateMaxValue = (data: number[], factor: number): number => {
    const maxNumber = Math.max(...data) * factor;
    return maxNumber > 0 ? Math.ceil(maxNumber) : 2;
};

export const BarLineChart: FunctionComponent<BarLineChartProps> = ({
    linedChartLabel,
    barChartLabel,
    lineData,
    barData,
    xAxis,
    loading,
}) => {

    const { formatMessage, formatNumber } = useIntlFormatter();
    /**
     * Formats the label of a chart's tooltip, by adding a currency next to the number, if that label is for the `amount`
     * @param {function(number, string | Intl.NumberFormatOptions): string} formatNumber `intl.formatNumber` function that can format number in various ways depending on provided options
     * @param {TooltipItem<keyof ChartTypeRegistry>} tooltipContext Tooltip context, that has details such as label and y axis number
     * @returns {string} Formatted label displaying amount and currency or default label value
     */
    const formatTooltipLabel = (tooltipContext: TooltipItem<keyof ChartTypeRegistry>): string => {
        let y = `${tooltipContext.parsed.y}`;
        if (tooltipContext.dataset.label === formatMessage("trans_det_tab_amount")) {
            y = addCurrencySymbol(formatNumber)(tooltipContext.parsed.y);
        }
        return `${tooltipContext.dataset.label}: ${y}`;

    }

    const data = {
        labels: xAxis,
        datasets: [
            {
                type: "bar" as const,
                label: barChartLabel,
                data: barData,
                yAxisID: "A",
                borderColor: paletteTheme.colors.LIGHT_PURPLE,
                backgroundColor: paletteTheme.colors.LIGHT_PURPLE,
                order: 1
            },
            {
                type: "line" as const,
                label: linedChartLabel,
                data: lineData,
                yAxisID: "B",
                borderColor: paletteTheme.colors.PRIMARY,
                backgroundColor: paletteTheme.colors.PRIMARY,
                order: 0
            },
        ]
    };

    const maxBar = calculateMaxValue(barData, 1.1);
    const maxLine = calculateMaxValue(lineData, 1.1);

    const config: ChartProps = {
        type: "bar",
        data: data,
        options: {
            maintainAspectRatio: false,
            interaction: {
                intersect: false,
                mode: "index",
            },
            plugins: {
                legend: {
                    position: "bottom",
                    labels: {
                        usePointStyle: true,
                        font: {
                            size: 12,
                        },
                        generateLabels: (chart) => {
                            return chart.data.datasets.map(
                                (dataset, index) => ({
                                    datasetIndex: index,
                                    text: dataset.label,
                                    fillStyle: dataset.backgroundColor,
                                    lineWidth: dataset.type === "line" ? 3 : 0,
                                    strokeStyle: dataset.backgroundColor,
                                    pointStyle: dataset.type === "line" ? "line" : "rect",
                                    hidden: !chart.isDatasetVisible(index),
                                })
                            ) as LegendItem[]
                        },
                    },
                },
                tooltip: {
                    backgroundColor: paletteTheme.colors.DEFAULT_TEXT_COLOR,
                    titleFont: {
                        family: MP_THEME.typography.fontFamily,
                        size: 12,
                    },
                    bodyFont: {
                        family: "Source Sans Pro",
                        size: 12,
                        lineHeight: "20px",
                    },
                    callbacks: {
                        label: formatTooltipLabel,
                    },
                },
            },
            scales: {
                A: {
                    type: "linear",
                    beginAtZero: true,
                    position: "left",
                    min: 0,
                    max: maxBar,
                    grid: {
                        borderDash: [8, 4],
                        drawBorder: false,
                        drawTicks: false,
                    },
                    ticks: {
                        stepSize: maxBar / 2,
                        precision: 0,
                        font: {
                            size: 10,
                        },
                    },
                },
                B: {
                    type: "linear",
                    beginAtZero: true,
                    position: "right",
                    min: 0,
                    max: maxLine,
                    grid: {
                        borderDash: [8, 4],
                        drawBorder: false,
                        drawOnChartArea: false,
                        drawTicks: false,

                    },
                    ticks: {
                        stepSize: maxLine / 2,
                        precision: 0,
                        font: {
                            size: 10,
                        },
                    }
                },
                x: {
                    grid: {
                        drawOnChartArea: false
                    },
                    ticks: {
                        font: {
                            size: 11,
                        },
                    },
                },
            },
        },
    };

    return (
        <div className={`flex flex-col ${styles.wrapper}`}>
            {loading &&
                <div className={styles["loader-wrap"]}>
                    <CircularProgress color="primary" disableShrink size={100} />
                </div>
            }
            <div className={`${styles["chart-wrap"]} ${loading ? styles.loading : ""}`}>
                <Chart
                    type="bar"
                    options={config.options}
                    data={config.data}
                />
            </div>
        </div>
    );
}
