import React, { FunctionComponent } from "react";
import {
    Box,
    Button as ButtonMaterialUI,
    ButtonProps as ButtonMaterialUIProps,
    CircularProgress,
} from "@mui/material";
import { authUtils } from "utils/api/authUtils";
import { paletteTheme } from "styles/themes/palette";

export interface ButtonProps extends ButtonMaterialUIProps {
    loading?: boolean;
    loadingStart?: boolean;
}

const Button: FunctionComponent<ButtonProps> = ({
    loading = false,
    loadingStart,
    startIcon,
    endIcon,
    onClick,
    disabled,
    children,
    ...rest
}) => {

    const onClickWithTracker = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        authUtils.refreshTokenExpirationDate();
        onClick(event);
    }

    const circularProgress = <CircularProgress sx={{ color: paletteTheme.colors.WHITE, position: !!startIcon || !!endIcon ? "relative" : "absolute" }} size={15} />;
    return (<ButtonMaterialUI
        {...rest}
        classes={{
            root: loading ? "labelLoadingCenter" : ""
        }}
        onClick={onClickWithTracker}
        disabled={disabled || loading}
        startIcon={!!startIcon && (loadingStart || loading) ? circularProgress : startIcon}
        endIcon={!!endIcon && loading ? circularProgress : endIcon}>
        {!startIcon && !endIcon && loading && circularProgress}
        <Box className={!startIcon && !endIcon && loading ? "isLoading" : ""} sx={{ "&.isLoading": { visibility: "hidden" } }}>
            {children}
        </Box>
    </ButtonMaterialUI>);
}


export default Button;
