import {
  REPORT_GET_REPORTS_ROUTE,
  REPORT_GET_REPORT_ROUTE,
} from "constants/routes/ApiRoutes";
import { QueryParam } from "interfaces/UserInterface";
import { SearchResponse } from "interfaces/api/ApiInterfaces";
import {
  ReportRequest,
  ReportResponse,
} from "interfaces/report/report";
import {
  BlobWithFilename,
  httpService,
} from "services/HttpService";

const getReports = () => ({
}: QueryParam): Promise<SearchResponse<ReportResponse>> => {
  return httpService.get(REPORT_GET_REPORTS_ROUTE);
}

const postReport = (reportRequestParams: ReportRequest): Promise<VoidFunction> => {
  return httpService.post(REPORT_GET_REPORTS_ROUTE, { ...reportRequestParams })
}

const downloadReport = (id: string): Promise<BlobWithFilename> => {
  const downloadReportURL = REPORT_GET_REPORT_ROUTE.replace("${id}", id);
  return httpService.getReadableStreamWithFilename(downloadReportURL);
}

const deleteReport = (id: string): Promise<VoidFunction> => {
  const body = { id };
  return httpService.delete(`${REPORT_GET_REPORTS_ROUTE}/${id}`, body);
}

export const reportService = {
  getReports,
  postReport,
  downloadReport,
  deleteReport,
}
