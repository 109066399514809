
import { RESET_PASSWORD_PATH } from "constants/routes/RoutePaths";
import { AppRouteInterface } from "interfaces/RouteInterfaces";
import { ResetPasswordView } from "view/password/ResetPassword/ResetPasswordView";
import { ResetPasswordConfirmView } from "view/password/ResetPassword/ResetPasswordConfirmView";

const ResetPasswordRoutes: AppRouteInterface = {
    path: RESET_PASSWORD_PATH,
    private: false,
    children: [
        {
            path: "confirm",
            element: ResetPasswordConfirmView,
            private: false,
            index: true,
        },
        {
            element: ResetPasswordView,
            private: false,
            index: true,
        }
    ]
}

export default ResetPasswordRoutes;
