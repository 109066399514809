import { SortingRule } from "react-table";
import { QueryParam } from "interfaces/UserInterface";

const sortToPageable = (page, pageSize, sortBy: SortingRule<object>[]): QueryParam => {
    if (sortBy.length !== 1) {
        return {
            page,
            pageSize
        }
    }

    return {
        page,
        pageSize,
        sortField: sortBy[0].id,
        sortDirection: sortBy[0].desc ? "DESC" : "ASC"
    }
}

const getCurrentSort = (sortBy: SortingRule<object>[], defaultSortBy: SortingRule<object>) => {
    if (!defaultSortBy) {
        return sortBy;
    }
    return sortBy.length === 1 ? sortBy : [defaultSortBy];
}

export const sortUtils = {
    sortToPageable,
    getCurrentSort,
}