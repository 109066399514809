import React, { FunctionComponent } from "react";
import FlexBox, { FlexBoxProps } from "../../atoms/flex/FlexBox";
import { useIntl } from "react-intl";
import { 
    EMPTY_DATE_INITIAL_STATE, 
    INITIAL_RANGE_TODAY, 
} from "constants/datepicker/datepicker";
import { DateRangePickerInput } from "../datepicker/DateRangePickerInput";
import { RangeKeyDict, Range } from "react-date-range";
import { rightUtils } from "../../../utils/business/rightUtils";
import { PerimeterOptions } from "constants/perimeter/perimeter";
import { dateUtils } from "../../../utils/common/dateUtils";
import { User } from "../../../interfaces/user/user";
import { FilterValues } from "../../../interfaces/filter/filter";
import { WrappedPerimeterFilters } from "components/molecules/filter/WrappedPerimeterFilters";

export interface TransactionFiltersProps extends FlexBoxProps {
    onChangePerimeterOptions: (value: PerimeterOptions) => void,
    perimeterOptions: PerimeterOptions,
    initialOptions: PerimeterOptions,
    filters: FilterValues
    onChangeFilters: (filters: FilterValues) => void
    user: User
    onSearch: () => void
    onReset: () => void
    errors?: Record<string, boolean>
    disabledSearch?: boolean,
    marginTop?: string,
    operationLabel?: string
    disabledMerchant?: boolean;
}

const MainFilters: FunctionComponent<TransactionFiltersProps> = ({
    onChangePerimeterOptions,
    perimeterOptions,
    initialOptions,
    filters,
    onChangeFilters,
    user,
    errors = {},
    onSearch,
    onReset,
    disabledSearch = false,
    marginTop = "10px",
    operationLabel = "filter_operation_date",
    disabledMerchant = false,
    ...rest
}) => {
    const intl = useIntl();
    const today = dateUtils.formatToLocale2Digit(new Date());
    const isAdmin = rightUtils.isAdmin(user);

    const onChangeOperationDate = (operationDate: Range) => {
        onChangeFilters({ ...filters, operationDate })
    }

    const dateRangePickerConfig = {
        range: filters.operationDate,
        label: intl.formatMessage({ id: operationLabel }),
        id: "datepicker",
        onReset: () => () => onChangeOperationDate(isAdmin ? EMPTY_DATE_INITIAL_STATE : INITIAL_RANGE_TODAY),
        onChange: (w: RangeKeyDict) => onChangeOperationDate(w["selection"]),
        initialStartDate: isAdmin ? "" : today,
        initialEndDate: isAdmin ? "" : today,
    };

    return (
        <WrappedPerimeterFilters
            {...rest}
            errors={errors}
            onSearch={onSearch}
            onReset={onReset}
            disabledSearch={disabledSearch}
            filters={filters}
            initialOptions={initialOptions}
            onChangeFilters={onChangeFilters}
            onChangePerimeterOptions={onChangePerimeterOptions}
            perimeterOptions={perimeterOptions}
            marginTop={marginTop}
            user={user}
            disabledMerchant={disabledMerchant}>
            <FlexBox marginRight="10px" marginTop={marginTop}>
                <DateRangePickerInput {...dateRangePickerConfig} />
            </FlexBox>
        </WrappedPerimeterFilters>);
}

export default MainFilters;
