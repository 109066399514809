import { FrenchMessages } from "constants/messages/i8ln/FrenchMessages"
import { EnglishMessages } from "constants/messages/i8ln/EnglishMessages"
import { MessageMap } from "interfaces/messages/messages";
import { PortugeseMessages } from "constants/messages/i8ln/PortugeseMessages";
import { SpanishMessages } from "constants/messages/i8ln/SpanishMessages";
import { ItalianMessages } from "constants/messages/i8ln/ItalianMessages";
import { DutchMessages } from "constants/messages/i8ln/DutchMessages";

export const Messages: MessageMap = {
  fr: FrenchMessages,
  en: EnglishMessages,
  it: ItalianMessages,
  es: SpanishMessages,
  pt: PortugeseMessages,
  nl: DutchMessages,
}
