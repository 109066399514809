import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import SwitchToggle from "components/atoms/switch/SwitchToggle";
import { Column } from "react-table";
import { UserDetails } from "interfaces/user/user";
import { getPortfolioTopBottom } from "services/portfolio/PortfolioService";
import { GenericSearchData } from "interfaces/table/TableInterface";
import { ManagableColumn } from "interfaces/table/table";
import { computeColumns, getTopBottomColumns } from "utils/table/tableUtils";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import TopBottomTable from "components/atoms/table/TopBottomTable";
import { getTopBottomItems } from "utils/table/portfolioUtils";
import { PortfolioResponse } from "components/molecules/portfolio/PortfolioTrendsSection";
import { usePortfolioTableFetch } from "hooks/portfolio/usePortfolioTableFetch";
import { FilterLayout } from "components/layouts/FilterLayout";
import { useTopBottomFilterConfig } from "hooks/portfolio/useTopBottomFilterConfig";
import { useTopBottomFilters } from "hooks/portfolio/useTopBottomFilters";

interface PortfolioSectionProps {
  userDetails: UserDetails;
  onChangeCompanyMode: (val: boolean) => void;
}

export interface PortfolioTopBottomResponse extends PortfolioResponse<PortfolioTopBottomItem> {
  total?: number;
}

export interface PortfolioTopBottomData {
  id: string;
  COMPANY_NAME: string;
  MERCHANT_NAME: string;
  GROSS_AMOUNT: number;
  NUMBER_TRANSACTION: number;
  SUCCESS_RATE: string;
  DISTRIBUTOR_COMMISSION: number;
  MARKET_PAY_COMMISSION: number;
  AVG_COMMISSION_RATE: number;
  TOTAL_COMMISSION: number;
}
export interface PortfolioTopBottomItem {
  idx?: number;
  companyName?: string;
  merchantName?: string;
  companyId?: number;
  merchantId?: number;
  transactionSuccessRate: string;
  numberTransaction: number;
  grossAmount: number;
  commissionAmount: number;
  marketpayCommissionAmount: number;
  commissionRate: number;
  commissionDistribAmount: number;
}

export const PortfolioTopBottomSection: FunctionComponent<PortfolioSectionProps> = ({
  onChangeCompanyMode,
}) => {
  const intl = useIntl();
  const { smDown, mdDown, lgDown, lgUp } = useMediaQueryUtil();
  const [companyMode, setCompanyMode] = useState<boolean>(false);
  const [initialColumns, setInitialColumns] = useState<Array<ManagableColumn>>(
    getTopBottomColumns("lg", companyMode)
  );

  const { formatMessage } = useIntlFormatter();

  useEffect(() => {
    if (smDown) {
      setInitialColumns(getTopBottomColumns("xs", companyMode));
      return;
    }
    if (mdDown) {
      setInitialColumns(getTopBottomColumns("sm", companyMode));
      return;
    }
    setInitialColumns(getTopBottomColumns("lg", companyMode));
  }, [smDown, mdDown, lgDown, lgUp, companyMode]);

  // // header/accessor may change when API will be available
  const PORTFOLIO_COLUMNS: Array<Column> = useMemo(() => {
    const defaulStyles = {
      width: smDown ? 70 : 90,
      minWidth: 70,
      maxWidth: 300,
    };
    return [...computeColumns(initialColumns, intl, defaulStyles)];
  }, [intl.locale, initialColumns]);


  const responseToData = (response: PortfolioTopBottomResponse): GenericSearchData<PortfolioTopBottomData> => {
    const items = [...getTopBottomItems(response)];
    return {
      datas: items.map((item) => {
        return {
          id: item.idx.toString(),
          COMPANY_NAME: item?.companyName,
          MERCHANT_NAME: item?.merchantName,
          NUMBER_TRANSACTION: item.numberTransaction,
          GROSS_AMOUNT: item.grossAmount,
          SUCCESS_RATE: item.transactionSuccessRate,
          DISTRIBUTOR_COMMISSION: item.commissionDistribAmount,
          MARKET_PAY_COMMISSION: item.marketpayCommissionAmount,
          AVG_COMMISSION_RATE: item.commissionRate,
          TOTAL_COMMISSION: item.commissionAmount,
        };
      }),
      last: true,
      totalElements: response.total,
    };
  };

  useEffect(() => {
    onChangeCompanyMode(companyMode);
  }, [companyMode]);

  const onToggleCompany = () => {
    setCompanyMode((prev) => !prev);
  };

  const { additionalFilterConfig, filters: topBottomFilters, showContent, tableRef } = useTopBottomFilters();
  const { filters: mainFilters } = useTopBottomFilterConfig(additionalFilterConfig, companyMode);

  const { onFetchData } = usePortfolioTableFetch<PortfolioResponse<PortfolioTopBottomItem>, PortfolioTopBottomData>(
    getPortfolioTopBottom(topBottomFilters, companyMode),
    responseToData
  );


  return (
    <>
      <div style={{position: "relative"}}>
      <div style={{ marginBottom: "40px" }}>
        <Typography variant="h2" marginBottom="20px">
          {formatMessage("filter_by")}
        </Typography>
        <FilterLayout mainFilters={mainFilters} />
      </div>
        <div style={{position: "absolute", top: lgUp ? "30px" : 0, right:0}}>
        <SwitchToggle checked={companyMode} onChange={onToggleCompany} label={["switch_merchant", "switch_company"]} />
        </div>
      </div>
      {showContent && (
        <TopBottomTable ref={tableRef} columns={PORTFOLIO_COLUMNS} onFetch={onFetchData} updateTable={companyMode} />
      )}
      {!showContent && (
        <div
          className="flex-col flex-col-center"
          style={{ marginBottom: "30px", display: showContent ? "none" : "flex" }}>
          <Typography variant="h2">{formatMessage("admin_trans_view_title")}</Typography>
          <Typography>{formatMessage("admin_trans_view_text")}</Typography>
        </div>
      )}
    </>
  );
};
