import React, {FunctionComponent} from "react";
import {NotificationProps, NotificationType} from "../../../../interfaces/notification/notification";
import {FormattedMessage} from "react-intl";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import styles from "./notificationBody.module.scss"

export const NotificationBody: FunctionComponent<NotificationProps> = ({ 
                                                                          notificationMessage, 
                                                                          targetUrl, 
                                                                          isHidden,
                                                                          type
                                                                      }) => {

  const { formatDate } = useIntlFormatter();

  const isPerimeterNotif = type ===  NotificationType.PERIMETER;

  const LinkBody = isPerimeterNotif 
        ? <FormattedMessage id="peri"></FormattedMessage>
        : <span className={styles.detail}>
        {notificationMessage.notification.detail}
      </span>

  return (
    <Typography variant="body2" classes={{body2: `${isHidden ? styles.text : ""}`}}>
      <span className={styles.icon}>
          {notificationMessage.icon}
      </span>
      <span className={styles.date}>
        {formatDate(notificationMessage.notification.createdAt)}
      </span>
      <span className={styles.message}>
        <FormattedMessage id={notificationMessage.label}/>
        <Link to={targetUrl} className={`${styles.link} ${isHidden ? styles.text : styles.textColored}`}>
          {LinkBody}
        </Link>
      </span>
      {isPerimeterNotif && <span className={styles.detail}>
         { `: ${notificationMessage.notification.detail}`}
       </span>
      }
    </Typography>
  )
}
