import React, { FunctionComponent, useContext, useState } from "react";
import { DeleteIcon } from "components/atoms/icons/DeleteIcon";
import { Action } from "components/atoms/button/more-actions/ListActions";
import { ICellProps } from "interfaces/table/table";
import { useIntl } from "react-intl";
import { authUtils } from "utils/api/authUtils";
import { ApiError } from "utils/api/errorUtils";
import { toastUtils } from "utils/toast/toastUtils";
import { reportService } from "services/report/ReportService";
import { TableContext } from "components/atoms/table/Table";
import { DeleteReportDialog } from "components/atoms/popup/DeleteReportDialog";
import { ReportData } from "interfaces/report/report";
import { MoreActions } from "components/molecules/MoreActions";

export const MoreActionsReports: FunctionComponent<ICellProps> = ({ value, cell }) => {
  const intl = useIntl();
  const { onFetchDataWithFilters } = useContext(TableContext);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const reportName = (cell.row.original as ReportData).name;

  const [loading, setLoading] = useState<boolean>(false);
  const deleteReport = async () => {
    setLoading(true);
    authUtils.refreshTokenExpirationDate();
    try {
      await reportService.deleteReport(value);
      onFetchDataWithFilters();
    } catch (error) {
      if (error instanceof Promise) {
        const promiseError = error as Promise<Error>;
        promiseError
          .then((result: ApiError) => {
            toastUtils.errorToast(intl.formatMessage({ id: "fetch_error" }));
          })
          .catch((e: Error) => toastUtils.errorToast(e.message));
      }
    } finally {
      setLoading(false);
      setIsDialogOpen(false);
    }
  };

  const openConfirmationDialog = () => {
    setIsDialogOpen(true);
  };
  const closeConfirmationDialog = () => {
    setIsDialogOpen(false);
  };

  const actions: Action[] = [
    {
      id: "DELETE",
      icon: <DeleteIcon />,
      title: intl.formatMessage({ id: "reports_table_delete" }),
      handler: openConfirmationDialog,
      typeButton: "danger",
    },
  ];

  return (
    <MoreActions actions={actions}>
      <DeleteReportDialog
        message={intl.formatMessage({ id: "reports_table_delete_message" }, { name: reportName })}
        cancelBtnLabel={intl.formatMessage({ id: "reports_table_delete_cancel" })}
        confirmBtnLabel={intl.formatMessage({ id: "reports_table_delete_confirm" })}
        isDialogOpen={isDialogOpen}
        cancelHandler={closeConfirmationDialog}
        confirmHandler={deleteReport}
        loading={loading}
      />
    </MoreActions>
  );
};
