import React, { useState } from "react";
import { useAuthUser } from "hooks/useAuthUser";
import { useAutocompleteSearchFilter } from "hooks/filter/useAutocompleteSearchFilter";
import { useOnChangeFilter } from "hooks/filter/useOnChangeFilter";
import { useFilter } from "hooks/filter/useFilter";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import { filterUtils } from "utils/business/filterUtils";
import { rightUtils } from "utils/business/rightUtils";
import {
  AutocompleteCheckboxConfig,
  MultichoiceConfig,
  QueryAndResetConfig,
} from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { Options } from "constants/options/option";
import { FilterValues } from "interfaces/filter/filter";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import { initSavedBillingFilter } from "utils/business/portfolioUtils";

export const useBillingFilters = () => {
  const { user, userDetails } = useAuthUser();
  const isAdminOrGroupPerimeter = rightUtils.isAdminOrGroupPerimeter(user);
  const [errors, ] = useState<Record<string, boolean>>({});
 const savedInitialFilters: FilterValues = {...JSON.parse(localStorage.getItem(LocalStorageEnum.GLOBAL_PERIMETER_FILTERS)), ...JSON.parse(localStorage.getItem(LocalStorageEnum.PORTFOLIO_BILLING_FILTERS))};

  const {
    perimeterOptions,
    onChangePerimeterOptions,
    initialPerimeterOptions: initialOptions,
    filters,
    onChangeFilters,
    onSearchFilter,
    onResetFilter,
    showContent,
    tableRef,
  } = useFilter<FilterValues>(
    userDetails,
    filterUtils.getDocumentsInitialFilters(),
    [
      LocalStorageEnum.GLOBAL_PERIMETER_FILTERS,
      LocalStorageEnum.PORTFOLIO_BILLING_FILTERS
    ],
    initSavedBillingFilter(savedInitialFilters)
  );

  const perimeterConfig = {
    isAdminOrGroupPerimeter,
    filters: filters,
    initialOptions,
    perimeterOptions,
    onChangeFilters,
    onChangePerimeterOptions,
  };

  const { onChangeAccountsSelection, onChangeCompaniesSelection, onChangeMerchantsSelection } = useOnChangeFilter(
    filters,
    onChangeFilters
  );

  const {
    onChangeAccountName,
    onChangeCompanyName,
    onChangeMerchantName,
    handleAccountsSelection,
    handleCompaniesSelection,
  } = useAutocompleteSearchFilter(perimeterOptions, onChangePerimeterOptions, filters);

  const autoCompleteCheckboxConfig: AutocompleteCheckboxConfig = {
    onChangeAccountsSelection,
    onChangeCompaniesSelection,
    onChangeMerchantsSelection,
    onChangeAccountName,
    onChangeCompanyName,
    onChangeMerchantName,
    handleAccountsSelection,
    handleCompaniesSelection,
  };

  const onChangeMultichoiceFilter = (field: string) => (values: Array<Options>) => {
    onChangeFilters({ ...filters, [field]: values });
  };

  const multichoiceConfig: MultichoiceConfig = {
    documentsFilters: filters,
    onChangeMultichoiceFilter,
  };

  const queryAndResetConfig: QueryAndResetConfig = {
    disabledWithPopup: !perimeterUtils.hasPerimeterFilters(filters),
    labelErrorOnDisable: "filter_search_button_toaster",
  };

  const onReset = () => {
    // On reset, recalculate initial options based on options/perimeters
    onChangeFilters(filterUtils.getDocumentsInitialFilters(perimeterOptions));
    onResetFilter();
  };

  const onSearch = () => {
    onSearchFilter();
  };

  const sharedFilterProps = {
    onSearch,
    onReset,
    errors,
  };

  const additionalFilterConfig = {
    sharedFilterProps,
    perimeterConfig,
    autoCompleteCheckboxConfig,
    multichoiceConfig,
    queryAndResetConfig,
  };

  return {
    additionalFilterConfig,
    filters,
    showContent,
    tableRef,
    sharedFilterProps,
  };
};
