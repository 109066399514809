import React, {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useState,
} from "react";
import { OutlinedTextField } from "components/atoms/textfield/OutlinedTextField";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import { localStorageUtils } from "utils/common/localStorageUtils";
import { QueryAndReset } from "components/molecules/filters/QueryAndReset";
import styles from "components/molecules/SearchBar/searchBar.module.scss";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";

interface SearchBarProps {
  onClick: (value: string) => void;
  onReset: () => void;
}

export const SearchBar: FunctionComponent<SearchBarProps> = ({
  onClick: onClickProps,
  onReset: onResetProps,
}) => {

  const [disabled, setDisabled] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(localStorageUtils.getStringItem(LocalStorageEnum.USER_SEARCH_FILTER));
  const { formatMessage } = useIntlFormatter();

  const onChangeSearchInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(event.target.value);
    setDisabled(false);
  }

  const onClick = () => {
    setDisabled(true);
    !disabled && onClickProps(query);
  }

  const onKeyboardEnter = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      setDisabled(true);
      !disabled && onClickProps(query);
    }
  }

  const onReset = () => {
    setQuery("");
    setDisabled(true);
    onResetProps();
  }

  return (
    <div className={styles.container}>
      <div className={styles["input-wrapper"]}>
        <OutlinedTextField
          value={query}
          placeholder={formatMessage("users_search_bar")}
          onChange={onChangeSearchInput}
          onFocus={(e) => e.target.placeholder = ""}
          onBlur={(e) => e.target.placeholder = formatMessage("users_search_bar")}
          onKeyDown={onKeyboardEnter} />
      </div>
      <div>
        <QueryAndReset
          onSearch={onClick}
          onReset={onReset}
          errors={{}}
          labelErrorOnDisable={"error"}
          disabled={disabled && query === "" || !query}
          disabledWithPopup={false}
        />
      </div>
    </div>
  );
}
