import React, {FunctionComponent} from "react";
import {CancelRounded, CheckCircle, CircleRounded} from "@mui/icons-material";
import FlexBox from "../flex/FlexBox";
import {FormattedMessage} from "react-intl";
import {FlexAttributValue} from "../../../types/flex/flex";
import {Typography} from "@mui/material";
import {MaterialUiColor} from "../../../types/materialui/materialui";

interface InputRequirementProps {
    requirement: string
    validated: boolean
    error: boolean
    bulletSize: number
}

const InputRequirement: FunctionComponent<InputRequirementProps> = ({
                                                                        error = false,
                                                                        validated = false,
                                                                        requirement,
                                                                        bulletSize,
                                                                    }) => {

    const color = error ? "error" : "primary";
    const iconSize = 13;

    const icon = error ? <CancelRounded color={MaterialUiColor.ERROR} style={{fontSize: iconSize}}></CancelRounded> :
        <CheckCircle color={MaterialUiColor.SUCCESS} style={{fontSize: iconSize}}></CheckCircle>

    return (
        <FlexBox justifyContent={FlexAttributValue.CENTER} position="relative">
            <FlexBox position="absolute" left={-8} width={0}>
                {!validated ?
                    <CircleRounded preserveAspectRatio="none" style={{fontSize: bulletSize}}></CircleRounded> :
                    <>
                        {icon}
                    </>
                }
            </FlexBox>
            <Typography variant="h4" fontWeight={300} color={!validated ? "" : color}>
                <FormattedMessage id={requirement}></FormattedMessage>
            </Typography>
        </FlexBox>
    )
}

export default InputRequirement;
