import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import {
  Column,
  Row,
  SortingRule,
  TableInstance,
  useColumnOrder,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useTable as useReactTable,
} from "react-table";
import { TableHeader } from "./TableHeader";
import {
  Table as TableMaterialUI,
  TableBody as TableBodyMaterialUI,
  TableHead,
  Typography,
  CircularProgress,
  TableContainer,
} from "@mui/material";
import { SearchData, TableData } from "../../../interfaces/table/TableInterface";
import { QueryParam } from "../../../interfaces/UserInterface";
import { FixedSizeList } from "react-window";
import VirtualiseRow from "./VirtualiseRow";
import { TableFooter } from "components/atoms/table/TableFooter";
import VirtualizedTable from "./VirtualizedTable";
import InfiniteLoader from "react-window-infinite-loader";
import { useTable } from "../../../hooks/table/useTable";
import { paletteTheme } from "styles/themes/palette";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";

export interface TableProps {
  columns: Array<Column>;
  onFetch: (param: QueryParam) => Promise<SearchData>;
  fetchOnMount?: boolean;
  defaultSortBy?: SortingRule<object>;
  onClickRow?: (row: Row) => void;
  columnWidth?: number;
  pageSize?: number;
  staticPageSize?: number;
  disablePagination?: boolean;
  disableNextPage?: boolean;
  FooterButtonList?: JSX.Element;
  updateTotalCountParent?: (total: number | string) => void;
}

export interface TableRef {
  fetch: () => void;
}

export interface CallbackVirtualTable {
  index: number;
  style?: object;
}

/**
 * Table with react-table hooks :
 *  - Resize columns
 *  - Sort by columns
 *
 * Row are virtualize by react-windows => You only display 10 rows and buffer 20 rows
 *
 *
 */

interface TableContext {
  onFetchDataWithFilters: () => void;
}
export const TableContext = createContext<TableContext>(null);

const Table: React.ForwardRefRenderFunction<TableRef, TableProps> = (
  {
    columns,
    onFetch,
    pageSize = 50,
    staticPageSize,
    fetchOnMount = false,
    defaultSortBy = null,
    onClickRow = null,
    disablePagination = false,
    disableNextPage = false,
    FooterButtonList,
    updateTotalCountParent,
  },
  ref
) => {
  const { formatMessage } = useIntlFormatter();

  const refVirtualRow = useRef<FixedSizeList>();
  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  const [data, setData] = useState<Array<TableData>>([]);

  /**
   * react-table instance
   * Table is rerender based on "data" attribute
   *
   * We do not allow multisorting and sorting is made by hand to be managed by the back end
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, columnResizing },
  }: TableInstance = useReactTable(
    {
      columns,
      data,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        sortBy: defaultSortBy ? [defaultSortBy] : [],
      },
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    useColumnOrder
  );

  /**
   * Hook managing:
   * - Fetching datas
   * - Mounting
   * - Loading state
   */
  const {
    totalCount,
    last,
    loading,
    displayedCount,
    isNextPageLoading,
    fetchError,
    isMounted,
    onNextPage,
    onFetchDataWithFilters,
  } = useTable(setData, onFetch, { defaultSortBy, fetchOnMount, sortBy, pageSize }, ref);

  useEffect(() => {
    if (updateTotalCountParent) {
      updateTotalCountParent(totalCount);
    }
  }, [totalCount]);

  const goTo = (item: number) => {
    refVirtualRow?.current?.scrollToItem(item);
  };

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => last || index < data.length;
  // If disableNextPage or a loading state, InfiniteLoader will not fetch any datas
  const loadMoreItems =
    disableNextPage || isNextPageLoading
      ? function () {
          /* Do nothing */
        }
      : onNextPage;
  const itemCount = !last ? data.length + 1 : data.length;

  /**
   * Using callback to prepare virtualized rows asynchronously
   */
  const preparedVirtualiseRow = useCallback(
    ({ index, style }: CallbackVirtualTable) => {
      if (!isItemLoaded(index) || (!last && index === data.length)) {
        return <div></div>;
      }

      const row = rows[index];

      return row ? (
        <VirtualiseRow
          key={`row-${row.id}`}
          prepareRow={prepareRow}
          row={row}
          style={{ ...style, overflow: "unset" }}
          onClickRow={onClickRow}
        />
      ) : null;
    },
    [prepareRow, rows, columnResizing]
  );

  /**
   * Trigger fetch when you click on sorting
   * Sorting remove all datas from table and fetchs {pageSize} results
   *
   * 3 types of sorting in this order:
   * - Default
   * - Ascending
   * - Descending
   *
   * Can be disabled on a column based on disableSorBy attribute
   */
  useEffect(() => {
    if (isMounted && infiniteLoaderRef && infiniteLoaderRef.current) {
      goTo(0);
      infiniteLoaderRef.current.resetloadMoreItemsCache(false);
      onFetchDataWithFilters();
    }
  }, [sortBy]);

  /**
   * Table use MaterialUI component and doesn't use table html
   * Rows/Columns are treated as div
   */
  const tableHeight = `${totalCount < 10 ? totalCount * 41 + 45 : 470}px`;
  
  return (
    <TableContext.Provider value={{ onFetchDataWithFilters }}>
      <div>
        {isMounted && (
          <>
            <TableContainer sx={{ maxHeight: 470, minHeight: 150, height: tableHeight, overflowY: totalCount < 10 ? "hidden": "auto"}}>
              <TableMaterialUI component="div" {...getTableProps()} className="table">
                <TableHead component="div" style={{ background: paletteTheme.palette.primary["main"], color: "white" }}>
                  {headerGroups.map((headerGroup) => (
                    <TableHeader key={`header-${headerGroup.id}`} headerGroup={headerGroup} />
                  ))}
                </TableHead>
                <TableBodyMaterialUI
                  component="div"
                  {...getTableBodyProps()}
                  sx={{ "& .MuiTableRow-root": { overflowX: "hidden" } }}>
                  <InfiniteLoader
                    ref={infiniteLoaderRef}
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={loadMoreItems}>
                    {({ onItemsRendered }) => (
                      <VirtualizedTable
                        loading={loading}
                        fetchError={fetchError}
                        staticPageSize={staticPageSize}
                        onItemsRendered={onItemsRendered}
                        itemCount={itemCount}
                        ref={refVirtualRow}
                        rows={preparedVirtualiseRow}
                        virtualizeItemCount={rows.length}
                      />
                    )}
                  </InfiniteLoader>
                </TableBodyMaterialUI>
              </TableMaterialUI>
            </TableContainer>
            {loading && (
              <div className="flex flex-col flex-center" style={{ height: "100%", marginTop: "24px" }}>
                <CircularProgress color="primary" disableShrink size={100} />
                <Typography marginTop={3} variant="h2">
                  {formatMessage("loader_search_results")}
                </Typography>
              </div>
            )}

            {!disablePagination && (
              <TableFooter
                totalCount={totalCount}
                displayedCount={displayedCount}
                isLoadingNextPage={isNextPageLoading}
                last={last}
                loading={loading}
                goTo={goTo}>
                {FooterButtonList}
              </TableFooter>
            )}
          </>
        )}
      </div>
    </TableContext.Provider>
  );
};

export default React.forwardRef(Table);
