import React, { useCallback, useEffect, useState } from "react";
import {
  Column,
  Row,
  SortingRule,
  TableInstance,
  useColumnOrder,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useTable as useReactTable,
} from "react-table";

import {
  Table as TableMaterialUI,
  TableBody as TableBodyMaterialUI,
  TableHead,
  Typography,
  CircularProgress,
  TableContainer,
} from "@mui/material";
import { GenericSearchData, TableData } from "interfaces/table/TableInterface";
import { paletteTheme } from "styles/themes/palette";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import VirtualiseRow from "components/atoms/table/VirtualiseRow";
import { TableHeader } from "components/atoms/table/TableHeader";
import { QueryParam } from "interfaces/UserInterface";
import { PortfolioTopBottomData } from "components/molecules/portfolio/PortfolioTopBottomSection";
import { PortfolioTrendsData } from "components/molecules/portfolio/PortfolioTrendsSection";
import { useStaticTable } from "hooks/table/useStaticTable";
import VirtualizedStaticTable from "components/atoms/table/VirtualizedStaticTable";

export interface TrendsTableProps<ITEM> {
  columns: Array<Column>;
  onFetch: (param: QueryParam) => Promise<GenericSearchData<ITEM>>;
  defaultSortBy?: SortingRule<object>;
  onClickRow?: (row: Row) => void;
  columnWidth?: number;
  pageSize?: number;
  staticPageSize?: number;

  isAmountRatioMode: boolean;
}
export interface TableRef {
  fetch: () => void;
}

export interface CallbackVirtualTable {
  index: number;
  style?: object;
}

/**
 * Table with react-table hooks :
 *  - Resize columns
 *  - Sort by columns
 *
 * Row are virtualize by react-windows => You only display 10 rows and buffer 20 rows
 *
 *
 */
const TrendsTable: React.ForwardRefRenderFunction<
  TableRef,
  TrendsTableProps<PortfolioTrendsData | PortfolioTopBottomData>
> = (
  {
    columns,
    onFetch,
    pageSize = 50,
    defaultSortBy = null,
    onClickRow = null,
    isAmountRatioMode,
    staticPageSize = 10
  },
  ref
) => {
  const { formatMessage } = useIntlFormatter();
  const [data, setData] = useState<Array<TableData>>([]);

  /**
   * react-table instance
   * Table is rerender based on "data" attribute
   *
   * We do not allow multisorting and sorting is made by hand to be managed by the back end
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, columnResizing },
  }: TableInstance = useReactTable(
    {
      columns,
      data,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        sortBy: [
          {
            id: isAmountRatioMode ? "RATE_AMOUNT_EVOLUTION_DAY" : "RATE_VOLUME_EVOLUTION_DAY",
            desc: true,
          },
        ],
      },
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    useColumnOrder
  );

  /**
   * Hook managing:
   * - Fetching datas
   * - Mounting
   * - Loading state
   */
  const { loading, fetchError, isMounted, onFetchDataWithFilters } = useStaticTable(
    setData,
    onFetch,
    { defaultSortBy, sortBy, pageSize },
    ref
  );

  const itemCount = data.length;

  /**
   * Using callback to prepare virtualized rows asynchronously
   */
  const preparedVirtualiseRow = useCallback(
    ({ index, style }: CallbackVirtualTable) => {
 
      const row = rows[index];

      return row ? (
        <VirtualiseRow key={`row-${row.id}`} prepareRow={prepareRow} row={row} style={style} onClickRow={onClickRow} />
      ) : null;
    },
    [prepareRow, rows, columnResizing]
  );

  /**
   * Trigger fetch when you click on sorting
   * Sorting remove all datas from table and fetchs {pageSize} results
   *
   * 3 types of sorting in this order:
   * - Default
   * - Ascending
   * - Descending
   *
   * Can be disabled on a column based on disableSorBy attribute
   */
  useEffect(() => {
    if (isMounted) {
      onFetchDataWithFilters();
    }
  }, [sortBy]);

  /**
   * Table use MaterialUI component and doesn't use table html
   * Rows/Columns are treated as div
   */
  return (
    <div>
      {isMounted && (
        <>
          <TableContainer sx={{ maxHeight: 470 }}>
            <TableMaterialUI component="div" {...getTableProps()} className="table">
              <TableHead component="div" style={{ background: paletteTheme.palette.primary["main"], color: "white" }}>
                {headerGroups.map((headerGroup) => (
                  <TableHeader key={`header-${headerGroup.id}`} headerGroup={headerGroup} />
                ))}
              </TableHead>
              <TableBodyMaterialUI
                component="div"
                {...getTableBodyProps()}
                sx={{ "& .MuiTableRow-root": { overflowX: "hidden" } }}>
                <VirtualizedStaticTable
                  loading={loading}
                  fetchError={fetchError}
                  itemCount={itemCount}
                  rows={preparedVirtualiseRow}
                  staticPageSize={staticPageSize}
                />
              </TableBodyMaterialUI>
            </TableMaterialUI>
          </TableContainer>
          {loading && (
            <div className="flex flex-col flex-center" style={{ height: "100%", marginTop: "24px" }}>
              <CircularProgress color="primary" disableShrink size={100} />
              <Typography marginTop={3} variant="h2">
                {formatMessage("loader_search_results")}
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default React.forwardRef(TrendsTable);
