import React, {FunctionComponent, useState} from "react";
import CardPopup from "../../../atoms/card/CardPopup";
import {Dialog as DialogMaterialUI} from "@mui/material";
import {ViewContent, ViewPerimeterContent} from "../../../../interfaces/view/view";
import SaveViewConfirmDialogContent from "./SaveViewConfirmDialogContent";
import SaveViewDialogContent from "./SaveViewDialogContent";

export interface DialogProps {
    openDialog: boolean
    onCloseDialog: () => void,
    filters: ViewContent,
    perimeterFilters: ViewPerimeterContent,
    submitMessage: string,
    onSave: () => void
}

const SaveViewDialog: FunctionComponent<DialogProps> = ({
                                                            openDialog,
                                                            onCloseDialog,
                                                            submitMessage,
                                                            filters,
                                                            perimeterFilters,
                                                            onSave: onSaveProps
                                                        }) => {


    const [confirm, setConfirm] = useState<boolean>(false);

    const onSave = () => {
        setConfirm(true)
        onSaveProps()
    }

    return (
        <DialogMaterialUI
            open={openDialog}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CardPopup sx={{width: "490px"}}>
                {confirm ?
                    <SaveViewConfirmDialogContent onConfirm={onCloseDialog}/> :
                    <SaveViewDialogContent
                        onCloseDialog={onCloseDialog}
                        filters={filters}
                        perimeterFilters={perimeterFilters}
                        onSave={onSave}
                        submitMessage={submitMessage}/>
                }
            </CardPopup>
        </DialogMaterialUI>
    )
}

export default SaveViewDialog;
