import {createContext, useContext} from "react";
import {To} from "react-router-dom";

interface ChangeContextInstance {
    unsaveChange: boolean,
    openDialog: boolean,
    navigateTo: To,
    setUnsaveChange: (value: boolean) => void,
    setNavigateTo: (value: To) => void,
    setOpenDialog: (value: boolean) => void,
}

export const ChangeContext = createContext<ChangeContextInstance>({
    unsaveChange: false,
    setUnsaveChange: () => false,
    openDialog: false,
    setOpenDialog: () => false,
    navigateTo: "",
    setNavigateTo: () => false
});

export const useChangeContext = () => useContext(ChangeContext);
