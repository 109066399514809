import {PerimeterFilterValues} from "../../interfaces/transaction/transaction";
import {queryUtils} from "../api/queryUtils";

const buildHistogramFilters = (perimeterFilters: PerimeterFilterValues, temporality: string): string => {
    const params = new URLSearchParams({
        accounts: perimeterFilters.accounts.map(account => account.id).toString(),
        companies: perimeterFilters.companies.selectedOptions.map(company => company.id).toString(),
        merchants: perimeterFilters.merchants.selectedOptions.map(merchants => merchants.id).toString(),
        temporality: temporality,
    })

    return `${queryUtils.filterURLSearchParams(params).toString()}`
}

export const HistogramUtils = {
    buildHistogramFilters
}
