import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { authUtils } from "utils/api/authUtils";
import { SwitchProps as SwitchPropsUI } from "@mui/material";
import Typography from "@mui/material/Typography";
import SwitchStyled from "styles/components/switch/SwitchStyled";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

interface SwitchProps extends SwitchPropsUI {
  label?: string;
  cursor?: string;
  marginLeft?: string;
}

export const Switch: FunctionComponent<SwitchProps> = ({
  checked = false,
  onChange,
  id,
  label,
  disabled,
  marginLeft = "0px",
  ...rest
}) => {

  const { smDown } = useMediaQueryUtil();

  const onChangeWithTracker = (event: React.ChangeEvent<HTMLInputElement>, check: boolean) => {
    authUtils.refreshTokenExpirationDate();
    if (onChange) {
      onChange(event, check);
    }
  };

  return (
    <div className={`flex  ${label && smDown ? "flex-row-between" : "flex-col-center"}`} style={{ marginLeft: marginLeft, width:  label && smDown ? "100%": "initial" }}>
      {label &&
        <Typography marginRight={1}><FormattedMessage id={label} /></Typography>}
      <SwitchStyled
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={onChangeWithTracker}
        {...rest}
      />
    </div>
  )
}
