import { ReportResponse } from "interfaces/report/report";
import {QueryParam} from "interfaces/UserInterface";
import {SearchResponse} from "interfaces/api/ApiInterfaces";
import {GenericSearchData, SearchData, TableData} from "interfaces/table/TableInterface";
import {useState} from "react";

interface TableFetchInstance {
    onFetchData: (param: QueryParam) => Promise<SearchData>,
    count: number,
}

interface TableFetchOptions {
    afterFetchingData?: () => void,
    beforeFetchingData?: () => void
}

/**
 * Strict format for DPF tables
 * Get generic data, map it with the mapper function and set totalElements to count
 * 
 * We expect a promise without more args than a queryParam. All filters, sorting must be done using an arrow function returning a function!
 */
export function useGenericTableFetch<U, T extends TableData>(
    promise: (param: QueryParam) => Promise<SearchResponse<U>>,
    mapper: (response: SearchResponse<U> | ReportResponse[]) => GenericSearchData<T>,
    options?: TableFetchOptions): TableFetchInstance {

    const [count, setCount] = useState<number>();

    const onFetchData = (param: QueryParam): Promise<GenericSearchData<T>> => {
        options?.beforeFetchingData && options.beforeFetchingData();
        return new Promise((resolve, reject) => {
            promise(param)
                .then(response => {
                    options?.afterFetchingData && options.afterFetchingData()
                    setCount(response.totalElements)
                    resolve(mapper(response))
                }).catch(reject)
        })
    }

    return {
        onFetchData,
        count
    };
}
