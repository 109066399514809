import React, { FunctionComponent } from "react";
import { ErrorReferentialLine } from "interfaces/error/error";
import Label from "components/atoms/Label";
import ErrorMessage from "components/atoms/ErrorMessage";
import { ValidateErrorMap } from "components/molecules/useraccount/edit/UserAccountEdit";
import {
  OutlinedTextField,
  OutlinedTextFieldProps,
} from "components/atoms/textfield/OutlinedTextField";
import styles from "components/atoms/card/cardRowCommon.module.scss";

export interface CardRowWithInputInputProps extends OutlinedTextFieldProps {
  id: string;
  required?: boolean;
  label: string;
  error?: boolean;
  errors?: ValidateErrorMap;
  possibleErrors?: Array<ErrorReferentialLine>;
}

export const CardRowWithInput: FunctionComponent<CardRowWithInputInputProps> = ({
  id,
  required,
  label,
  error,
  errors,
  possibleErrors,
  children,
  ...rest
}) => {

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <Label id={id} required={required} label={label} />
      </div>
      <div className={styles.input}>
        <OutlinedTextField
          {...rest}
          id={id}
          value={children}
          error={error} />
        <div className={styles.error}>
          {possibleErrors.map(_error =>
            <ErrorMessage
              key={_error.id}
              error={_error.id}
              errors={errors} />)}
        </div>
      </div>
    </div>
  )
}
