import { AppRouteInterface } from "interfaces/RouteInterfaces";
import { ACCEPTED_TRANSACTIONS_PATH } from "constants/routes/RoutePaths";
import { PERMISSIONS } from "constants/permissions/permissions";
import { AcceptedTransactionsView } from "view/TransactionView/AcceptedTransactionsView";
import TransactionDetailView from "view/TransactionDetailView";

export const AcceptedTransactionRoutes: AppRouteInterface = {
  path: ACCEPTED_TRANSACTIONS_PATH,
  private: true,
  index: false,
  children: [
    {
      element: AcceptedTransactionsView,
      private: true,
      index: true,
      permission: PERMISSIONS.TRANSACTIONS_CONSULT,
    },
    {
      element: TransactionDetailView,
      path: ":idTransaction",
      private: true,
      index: true,
      permission: PERMISSIONS.TRANSACTIONS_CONSULT,
      redirectPath: `/${ACCEPTED_TRANSACTIONS_PATH}`,
    },
  ],
};
