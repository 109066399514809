import OverflowTooltip from "components/molecules/tooltip/OverflowTooltip";
import { PROFIL_USER } from "constants/user/user";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { IUserCellProps } from "interfaces/table/table";
import React, { FunctionComponent } from "react";

export const AccountNameCell: FunctionComponent<IUserCellProps> = ({ cell }) => {
  const { formatMessage } = useIntlFormatter();
  return (
    <div className="flex flex-col-center" style={{ height: "100%" }}>
      {PROFIL_USER[cell?.row.original.profile].id === PROFIL_USER.ADMIN.id ? (
        <OverflowTooltip className="uppercase" resizeClass="resizer">
          {formatMessage("users_acc_col_all")}
        </OverflowTooltip>
      ) : (
        <OverflowTooltip className="uppercase" resizeClass="resizer">
          {cell?.row.original.groupName || cell?.row.original.accountName || ""}
        </OverflowTooltip>
      )}
    </div>
  );
};
