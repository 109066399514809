import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Typography, TypographyProps, Box} from "@mui/material";
import {MaterialUiColor} from "../../types/materialui/materialui";
import {ValidateErrorMap} from "../molecules/useraccount/edit/UserAccountEdit";

interface ErrorMessageProps extends TypographyProps {
    error: string,
    errors: ValidateErrorMap,
    icon?: JSX.Element,
    iconTop?: number,
    iconLeft?: number
}

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
                                                                error,
                                                                errors,
                                                                icon,
                                                                iconTop = 1,
                                                                iconLeft = -20,
                                                                ...rest
                                                            }) =>
    (errors[error] ?
        <Typography {...rest} color={MaterialUiColor.ERROR} key={error}>
            <Box component="span" position="relative">
                <Box component="span" position="absolute" top={iconTop} left={iconLeft}>
                    {!!icon && icon}
                </Box>
                <span><FormattedMessage id={error.toLowerCase()}/></span>
            </Box>
        </Typography> : <></>)


export default ErrorMessage;
