import React, {FunctionComponent, useEffect} from "react";
import {RouteProps, useLocation} from "react-router-dom";
import {scrollUtils} from "../utils/common/scrollUtils";


/**
 * Always scroll to top when the location change
 * @param children
 * @constructor
 */
const ScrollToTop : FunctionComponent<RouteProps> = ({children}) => {
    const location = useLocation();
    useEffect(() => {
        scrollUtils.scrollToTop();
    }, [location]);
    return <>{children}</>
};



export default ScrollToTop;
