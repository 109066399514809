import React, { FunctionComponent, MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { RouteProps } from "react-router-dom";
import { useIntl } from "react-intl";
import { GenericSearchData } from "interfaces/table/TableInterface";
import { ManagableColumn } from "interfaces/table/table";
import { ReportData, ReportResponse } from "interfaces/report/report";
import { computeColumns, getReportsColumns } from "utils/table/tableUtils";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { useGenericTableFetch } from "hooks/table/useGenericTableFetch";
import { reportService } from "services/report/ReportService";
import Table from "components/atoms/table/Table";
import { TableRef } from "components/atoms/table/Table";
import Card from "components/atoms/card/Card";
import Typography from "@mui/material/Typography";
import { paletteTheme } from "styles/themes/palette";

export const getFrequencyKey = (data: "DAILY" | "WEEKLY" | "MONTHLY") => {
  switch (data) {
    case "WEEKLY":
      return "reports_table_frequency_col_weekly";
    case "MONTHLY":
      return "reports_table_frequency_col_monthly";
    default:
      return "reports_table_frequency_col_daily";
  }
};

export const ReportsView: FunctionComponent<RouteProps> = () => {
  const tableRef: MutableRefObject<TableRef> = useRef<TableRef>();
  const intl = useIntl();
  const { formatMessage } = useIntlFormatter();
  const { smDown, smUp } = useMediaQueryUtil();
  const [showContent, setShowContent] = useState<boolean>(false);

  const [initialColumns, setInitialColumns] = useState<Array<ManagableColumn>>(getReportsColumns());

  useEffect(() => {
    if (smDown) {
      setInitialColumns(getReportsColumns("xs"));
      return;
    }
    if (smUp) {
      setInitialColumns(getReportsColumns("sm"));
      return;
    }
    setInitialColumns(getReportsColumns());
  }, [smDown, smUp]);

  const columns = useMemo(() => {
    const defaulStyles = {
      width: smDown ? 70 : 90,
      minWidth: 70,
      maxWidth: 300,
    };
    return [...computeColumns(initialColumns, intl, defaulStyles)];
  }, [initialColumns, intl.locale]);

  const responseToData = (response: ReportResponse[]): GenericSearchData<ReportData> => {
    setShowContent(response.length > 0);
    return {
      datas: response.map((reports: ReportResponse) => {
        return {
          name: reports.name,
          latestEdition: reports.latestEdition,
          frequency: formatMessage(getFrequencyKey(reports.frequency)),
          latestReport: reports.id.toString(),
          moreActions: reports.id.toString(),
        };
      }),
      totalElements: response.length,
      last: true,
    };
  };

  const { onFetchData } = useGenericTableFetch<ReportResponse, ReportData>(reportService.getReports(), responseToData);

  return (
    <Card
      cardTitle={
        <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
          {formatMessage("my_reports_title")}
        </Typography>
      }>
      <div style={{ display: showContent ? "block" : "none" }}>
        <Table ref={tableRef} columns={columns} onFetch={onFetchData} />
      </div>

      <div
        className="flex flex-col flex-col-center"
        style={{ display: showContent ? "none" : "flex", marginBottom: "30px", textAlign: "center" }}>
        <Typography variant="h2">{formatMessage("no_reports_scheduled")}</Typography>
      </div>
    </Card>
  );
};
