export type MaterialUiColorType = "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";

export enum MaterialUiColor {
    DEFAULT = "default",
    PRIMARY = "primary",
    SECONDARY = "secondary",
    ERROR = "error",
    INFO = "info",
    SUCCESS = "success",
    WARNING = "warning"
}
