import React, { FunctionComponent } from "react";
import TextField from "components/atoms/textfield/TextField";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";


export interface OutlinedTextFieldProps extends BaseTextFieldProps {
  onChange?: OutlinedInputProps["onChange"];
  InputProps?: Partial<StandardInputProps>;
}

export const OutlinedTextField: FunctionComponent<OutlinedTextFieldProps> = ({
  InputProps,
  onChange,
  ...rest
}) =>
  <TextField {...rest} InputProps={InputProps} onChange={onChange} variant="outlined" style={{ width: "100%" }} />

export default OutlinedTextField;
