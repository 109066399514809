import React, { FunctionComponent } from "react";
import { Typography } from "@mui/material";
import AdvancedFormattedMessage from "components/atoms/intl/AdvancedFormattedMessage";

export const ToggleGroupRow:FunctionComponent<{
    groupTitleCode:string,
}> = ({
    groupTitleCode,
}) => <Typography
        variant="h2"
        marginLeft={6}
        paddingBottom={1}
        fontSize="14px"
    >
    <AdvancedFormattedMessage title={groupTitleCode} />
</Typography>
