import React, {FunctionComponent} from "react";
import ContainedButton from "./ContainedButton";
import {ButtonProps} from "./Button";

const ContainedExterneButton: FunctionComponent<ButtonProps> = ({...rest}) => {

    return (<ContainedButton {...rest} padding="4px 25px"/>);
}


export default ContainedExterneButton;
