import {
  AutocompleteCheckboxConfig,
  PerimeterConfig,
  CommonFilterProps,
  useWrappedFilterComponents,
  QueryAndResetConfig,
  DatePickerConfig,
} from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { FilterColumn } from "interfaces/filter/filterColumn";
type TopBottomFilterConfig = {
  sharedFilterProps: CommonFilterProps;
  perimeterConfig: PerimeterConfig;
  autoCompleteCheckboxConfig: AutocompleteCheckboxConfig;
  queryAndResetConfig: QueryAndResetConfig;
  datePickerConfig: DatePickerConfig;
};

export const useTopBottomFilterConfig = (config: TopBottomFilterConfig, companyMode:boolean) => {
  const { sharedFilterProps, perimeterConfig, autoCompleteCheckboxConfig, queryAndResetConfig, datePickerConfig } =
    config;

  const { onSearch, onReset, errors } = sharedFilterProps;

  const { autocompleteCheckbox, queryAndReset, dateRange } = useWrappedFilterComponents();

  const filters: FilterColumn[] = [
    {
      name: "Account dropdown",
      component: autocompleteCheckbox("account", perimeterConfig, autoCompleteCheckboxConfig),
      index: 0,
    },
    {
      name: "Company dropdown",
      component: autocompleteCheckbox("company", perimeterConfig, autoCompleteCheckboxConfig),
      index: 1,
    },
    // merchant placeholder here-----
    {
      name: "Transaction date",
      component: dateRange("PortfolioTopBottom", datePickerConfig, "PortfolioTopBottom"),
      index: 3,
    },
    {
      name: "Download and reset",
      component: queryAndReset(onSearch, onReset, errors, queryAndResetConfig),
      index: 4,
      config: {
        xs: { colSpan: 2 },
        sm: {
          colSpan: 1,
          colStart: 5,
        },
      },
    },
  ];
  
  if(!companyMode){
    filters.splice( 2, 0, {
      name: "Merchant dropdown",
      component: autocompleteCheckbox("merchant", perimeterConfig, autoCompleteCheckboxConfig),
      index: 2,
    },)
  }

  return {
    filters,
  };
};
