import React, {
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  RouteProps,
  useNavigate,
} from "react-router-dom";
import Card from "components/atoms/card/Card";
import { Typography } from "@mui/material";
import { paletteTheme } from "styles/themes/palette";
import {
  FormattedMessage,
  useIntl,
} from "react-intl";
import { userService } from "services/user/UserService";
import Table, { TableRef } from "components/atoms/table/Table";
import {
  Column,
  Row,
  SortingRule,
} from "react-table";
import { useTableFetch } from "hooks/table/useTableFetch";
import { TableData } from "interfaces/table/TableInterface";
import {
  UserDataResponse,
  UserDetailsResponse,
} from "interfaces/UserInterface";
import { SearchBar } from "components/molecules/SearchBar";
import { rightUtils } from "utils/business/rightUtils";
import { PERMISSIONS } from "constants/permissions/permissions";
import ContainedButton from "components/atoms/button/ContainedButton";
import { PersonAddOutlined } from "@mui/icons-material";
import { USER_PATH } from "constants/routes/RoutePaths";
import { useAuthUser } from "hooks/useAuthUser";
import ManyExportLineDialog from "components/molecules/dialog/ManyExportLineDialog";
import { useExport } from "hooks/export/useExport";
import { CONTENT_TYPE_EXPORT } from "constants/export/export";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import { localStorageUtils } from "utils/common/localStorageUtils";
import {
  computeColumns,
  getUserColumns,
} from "utils/table/tableUtils";
import { ManagableColumn } from "interfaces/table/table";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import styles from "view/UserSearchView/userSearchView.module.scss";

export interface UserData extends TableData {
  email?: string;
  firstname?: string;
  lastname?: string;
  profile?: string;
  groupName?: string;
  accountName?: string;
  perimeterLevel?: string;
  lastConnection?: string;
  createdAt?: string;
  initialized?: boolean;
}

const UserSearchView: FunctionComponent<RouteProps> = () => {
  const { smDown, mdDown, lgDown, lgUp } = useMediaQueryUtil();
  const [persInfo, setPersInfo] = useState<string>(
    localStorageUtils.getStringItem(LocalStorageEnum.USER_SEARCH_FILTER)
  );
  const [hasNewFilters, setHasNewFilters] = useState<boolean>(false);
  const { user, userDetails } = useAuthUser();
  const intl = useIntl();
  const navigate = useNavigate();

  const userTablePermitions = useMemo(
    () => ({
      isOperator: !rightUtils.isOperator(user),
      isAdmin: rightUtils.isAdmin(user),
    }),
    [user]
  );

  const [initialColumns, setInitialColumns] = useState<Array<ManagableColumn>>(
    getUserColumns({ ...userTablePermitions })
  );

  useEffect(() => {
    if (smDown) {
      setInitialColumns(getUserColumns({ size: "xs", ...userTablePermitions }));
      return;
    }
    if (mdDown) {
      setInitialColumns(getUserColumns({ size: "sm", ...userTablePermitions }));
      return;
    }
    if (lgDown) {
      setInitialColumns(getUserColumns({ size: "md", ...userTablePermitions }));
      return;
    }
    setInitialColumns(getUserColumns({ ...userTablePermitions }));
  }, [smDown, mdDown, lgDown, lgUp]);

  const tableRef: MutableRefObject<TableRef> = useRef<TableRef>();

  const responseToData = (datas: Array<UserDataResponse>): Array<UserData> => {
    return datas.map((_user) => ({
      id: _user.id,
      email: _user.email,
      firstname: _user.firstName,
      lastname: _user.lastName,
      profile: _user.profile,
      groupName: _user.groupName,
      accountName: _user.accountName,
      createdAt: _user.createdAt,
      perimeterLevel: _user.perimeterLevel,
      lastConnection: _user.lastConnection,
      initialized: _user.initialized,
    }));
  };

  const onClickSearch = (value: string) => {
    setPersInfo(value);
    setHasNewFilters(true);
  };

  const onReset = () => {
    setPersInfo("");
    setHasNewFilters(true);
  };

  const onClickRow = (row: Row<UserData>) => {
    localStorage.setItem(LocalStorageEnum.USER_SEARCH_FILTER, persInfo);
    navigate(`/${USER_PATH}/${row.original.id}`);
  };

  const onClickNewUser = () => {
    localStorage.removeItem(LocalStorageEnum.USER_SEARCH_FILTER);
    navigate(`/${USER_PATH}/new`);
  };

  const { onFetchData, count } = useTableFetch<UserDetailsResponse>(userService.getUsers(persInfo), responseToData);

  const { openManyExportLine, setOpenManyExportLine, isExportLoading, onDownload } = useExport(
    count,
    process.env.REACT_APP_MAX_USERS_EXPORT,
    () => userService.downloadExportUsers(persInfo),
    "export_user"
  );

  useEffect(() => {
    if (hasNewFilters) {
      setHasNewFilters(false);
      if (tableRef?.current) {
        tableRef.current.fetch();
      }
    }
  }, [hasNewFilters]);

  const COLUMNS: Array<Column> = useMemo(() => {
    const defaulStyles = {
      width: 100,
      minWidth: 80,
      maxWidth: 150,
    };
    return computeColumns(initialColumns, intl, defaulStyles);
  }, [initialColumns, user, intl.locale]);

  const defaultSortingRule: SortingRule<object> = useMemo(() => ({ id: "firstname", desc: true }), []);
  return (
    <Card
      sx={{ minHeight: "700px" }}
      cardTitle={
        <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
          <FormattedMessage id="menu_users" />
        </Typography>
      }>

      <div className={styles["searchbar-wrapper"]}>
        <SearchBar onClick={onClickSearch} onReset={onReset} />
      </div>

      <div className={styles["add-user-query-result-wrapper"]}>
        {rightUtils.userHasPermission(userDetails, PERMISSIONS.USER_CREATE) && (
          <div className={`flex flex-row-end ${styles["add-user-wrap"]}`}>
            <ContainedButton
              onClick={onClickNewUser}
              startIcon={<PersonAddOutlined
              />}
              width={smDown ? "100%" : "initial"}
              height={smDown ? "28px" : "24px"}
            >
              <FormattedMessage id="add_user_button" />
            </ContainedButton>
          </div>
        )}
        {persInfo &&
          <div>
            {(count > 0 ? (
              <Typography variant="h4" fontWeight="normal" component="span">
                <FormattedMessage id="query_results" />{" "}
                <Typography variant="h4" component="span" color="primary" fontWeight="bold">
                  {count}
                </Typography>
                <FormattedMessage id="query_results_return" values={{ count }} />
              </Typography>
            ) : (
              <Typography variant="h4" fontWeight="normal" component="span">
                <FormattedMessage id="query_results_no_results" />
              </Typography>
            ))}
          </div>
        }
      </div>
      <Table
        defaultSortBy={defaultSortingRule}
        fetchOnMount
        ref={tableRef}
        columns={COLUMNS}
        onFetch={onFetchData}
        onClickRow={onClickRow}
        FooterButtonList={
          rightUtils.userHasPermission(userDetails, PERMISSIONS.USER_EXPORT) && (
            <ContainedButton loading={isExportLoading} onClick={() => onDownload(CONTENT_TYPE_EXPORT.CSV)} width={smDown ? "100%" : "initial"}>
              <FormattedMessage id="export_csv_button" />
            </ContainedButton>
          )
        }
      />
      {openManyExportLine && (
        <ManyExportLineDialog
          openDialog={openManyExportLine}
          onCloseDialog={() => setOpenManyExportLine(false)}
          maxLines={process.env.REACT_APP_MAX_USERS_EXPORT}
        />
      )}
    </Card>
  );
};

export default UserSearchView;
