import React, { FunctionComponent } from "react";
import FlexBox from "../flex/FlexBox";
import { FlexAttributValue } from "../../../types/flex/flex";
import OverflowTooltip from "../../molecules/tooltip/OverflowTooltip";
import { CellProps } from "utils/common/columnUtils";

const TextCell: FunctionComponent<CellProps> = ({ value, style }) => <FlexBox
        height="100%"
        alignItems={FlexAttributValue.CENTER}
        style={style}
    >
        <OverflowTooltip resizeClass="resizer">
            {value}
        </OverflowTooltip>
    </FlexBox>

export default TextCell;