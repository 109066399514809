import React, { FunctionComponent } from "react";
import { RouteProps, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { MaterialUiColor } from "types/materialui/materialui";
import styles from "view/password/password.module.scss";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

interface LocationResetPasswordConfirm {
    email?: string
}

export const ResetPasswordConfirmView: FunctionComponent<RouteProps> = () => {

    const location = useLocation();
    const state: LocationResetPasswordConfirm = location.state;

    return (
        <Stack className={styles["stack-auth-layout"]}>
            <div>
                <Typography variant="h1" textAlign="center" color={MaterialUiColor.PRIMARY} paddingBottom={2}>
                    <FormattedMessage id="pw_confirm_title" />
                </Typography>
                <Typography variant="h4" textAlign="center" fontWeight="bold" style={{ wordBreak: "break-all" }}>
                    <FormattedMessage id="pw_confirm_your_email" values={{ email: state.email }} />
                </Typography>
                <Typography variant="h4" textAlign="center" fontWeight={300} paddingTop={2}>
                    <FormattedMessage id="pw_recovery_confirmation_body" />
                </Typography>
                <Typography variant="h4" textAlign="center" fontWeight={300} paddingTop={2} paddingBottom={6}>
                    <FormattedMessage id="pw_recovery_confirmation_not_sent" />
                </Typography>
            </div>
        </Stack>
    )
}
