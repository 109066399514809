import React, { FunctionComponent } from "react";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { ResetButtonProps } from "components/atoms/button/ResetButton/ResetIconButton";
import Button from "@mui/material/Button";
import { Refresh } from "@mui/icons-material";

export const ResetButton: FunctionComponent<ResetButtonProps> = (
    {
        onClick,
        disabled = false,
        ...rest
    }) => {

    const { formatMessage } = useIntlFormatter();

    return <Button {...rest}
        startIcon={<Refresh />}
        disableRipple={true}
        variant="text"
        onClick={onClick} >
        {formatMessage("reset_button_text")}
    </Button>

}
