import { QueryParam } from "interfaces/UserInterface";
import { GenericSearchData } from "interfaces/table/TableInterface";
import { useState } from "react";

interface TableFetchInstance<ITEM> {
  count: number;
  onFetchData: (params: QueryParam) => Promise<GenericSearchData<ITEM>>;
}

interface TableFetchOptions {
  afterFetchingData?: () => void;
  beforeFetchingData?: () => void;
}

export function usePortfolioTableFetch<RESP, ITEM>(
  promise: (params?: QueryParam) => Promise<RESP>,
  mapper: (response: RESP, option?: string) => GenericSearchData<ITEM>,
  options?: TableFetchOptions
): TableFetchInstance<ITEM> {
  const [count, setCount] = useState<number>(0);
  const onFetchData = (params: QueryParam): Promise<GenericSearchData<ITEM>> => {
    options?.beforeFetchingData && options.beforeFetchingData();
    return new Promise((resolve, reject) => {
      promise(params)
        .then((response) => {
          options?.afterFetchingData && options.afterFetchingData();
          const mappedResponse = mapper(response, params.sortField);
          setCount(mappedResponse.totalElements);
          resolve(mappedResponse);
        })
        .catch(reject);
    });
  };

  return {
    count,
    onFetchData,
  };
}
