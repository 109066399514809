import { UPDATE_PASSWORD_PATH } from "constants/routes/RoutePaths";
import { AppRouteInterface } from "interfaces/RouteInterfaces";
import { UpdatePasswordView } from "view/password/UpdatePassword/UpdatePassword";
import { UpdatePasswordConfirmView } from "view/password/UpdatePassword/UpdatePasswordConfirmView";

export const UpdatePasswordRoutes: AppRouteInterface = {
    path: UPDATE_PASSWORD_PATH,
    private: false,
    children: [
        {
            element: UpdatePasswordView,
            private: false,
            index: true,
        },
        {
            path: "confirm",
            element: UpdatePasswordConfirmView,
            private: false,
            index: true,
        }
    ]

}
