import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { CORPORATE_URL } from "constants/logo/assets";
import WatchedLinkUI from "components/atoms/link/WatchedLinkUI";
import styles from "components/atoms/Logo/logo.module.scss"

export const Logo: FunctionComponent = () => {
    const intl = useIntl();
    return (
        <WatchedLinkUI to="/">
            <img src={CORPORATE_URL} alt={intl.formatMessage({ id: "logo_alt" })} className={styles.logo} />
        </WatchedLinkUI>
    )
}
