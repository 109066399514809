import {useState} from "react";
import {NotificationResponse} from "../../interfaces/notification/notification";
import {useEffectAbortable} from "../useEffectAbortable";
import {notificationService} from "../../services/notification/NotificationService";
import {NOTIFICATIONS} from "../../constants/notification/notification";
import {ApiError, errorUtils} from "../../utils/api/errorUtils";
import {arrayUtils} from "../../utils/common/arrayUtils";

interface UseNotification {
    userHomepageActiveNotifications: Array<NotificationResponse>,
    userActiveNotifications: Array<NotificationResponse>,
    userHiddenNotifications: Array<NotificationResponse>,
    hideNotification: (notificationToHide: NotificationResponse) => void,
    hasUserActiveNotifications: boolean,
    hasUserHiddenNotifications: boolean,
    userActiveNotificationsCount: number,
    onChangePage: () => void,
    last: boolean,
    isLoading: boolean,
    onHideAll: () => void
}

export const useNotification = (): UseNotification => {

    /**
     * userHomepageActiveNotifications contains ALL of user's active notifications
     * userActiveNotifications contains user's active notifications from a paginated response
     */
    const [userHomepageActiveNotifications, setUserHomepageActiveNotifications] = useState<Array<NotificationResponse>>([]);
    const [userActiveNotifications, setUserActiveNotifications] = useState<Array<NotificationResponse>>([]);
    const [userHiddenNotifications, setUserHiddenNotifications] = useState<Array<NotificationResponse>>([]);
    const [page, setPage] = useState<number>(0);
    const [last, setLast] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hideAll, setHideAll] = useState<boolean>(false);

    // Dashboard notifications
    useEffectAbortable((ac) => {
        notificationService.findActiveNotifications({signal: ac.signal}).then(response => {
            setUserHomepageActiveNotifications(response.filter(notif => !!NOTIFICATIONS[notif.notificationType]));
        }).catch(errorUtils.handleBackErrors)

        return () => ac.abort();
    }, []);

    // Popover notifications
    useEffectAbortable((ac) => {
        setIsLoading(true);
        notificationService.findNotifications(page, {signal: ac.signal}).then(response => {
            const notifications = response.content.filter(notif => !!NOTIFICATIONS[notif.notificationType]);
            setUserHiddenNotifications(prev => [...prev, ...notifications.filter(notif => notif.hidden)]);
            setUserActiveNotifications(prev => [...prev, ...notifications.filter(notif => !notif.hidden)]);
            setLast(response.last);
            setIsLoading(false);
        }).catch((e: Promise<ApiError> | ApiError) => {
            errorUtils.handleBackErrors(e);
            setIsLoading(false)
        })

        return () => ac.abort();
    }, [page, hideAll]);

    const hideNotification = (notificationToHide: NotificationResponse) => {
        const ac = new AbortController();
        notificationService.hideNotification(notificationToHide.id, {signal: ac.signal})
            .then(() => {
                setUserActiveNotifications(userActiveNotifications.filter(notif => notif.id !== notificationToHide.id));
                setUserHomepageActiveNotifications(userHomepageActiveNotifications.filter(notif => notif.id !== notificationToHide.id));
            })
            .catch(errorUtils.handleBackErrors)
        return () => ac.abort();
    }

    const onHideAll = () => {
        const ac = new AbortController();
        notificationService.hideAllNotifications({signal: ac.signal}).then(() => {
            setUserHomepageActiveNotifications([]);
            setUserHiddenNotifications([]);
            page !== 0 ? setPage(0) : setHideAll(true);
        }).catch(errorUtils.handleBackErrors)
        return () => ac.abort();
    }

    const onChangePage = () => {
        setPage(page + 1);
    }

    const hasUserActiveNotifications = !arrayUtils.isEmpty(userHomepageActiveNotifications);
    const hasUserHiddenNotifications = !arrayUtils.isEmpty(userHiddenNotifications);
    const userActiveNotificationsCount = userHomepageActiveNotifications.length;

    return {
        hideNotification,
        hasUserActiveNotifications,
        hasUserHiddenNotifications,
        userActiveNotificationsCount,
        userHomepageActiveNotifications,
        userActiveNotifications,
        userHiddenNotifications,
        onChangePage,
        last,
        isLoading,
        onHideAll
    }
}
