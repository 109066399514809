import React, { FunctionComponent } from "react";
import Refresh from "@mui/icons-material/Refresh";
import styles from "components/atoms/button/ResetButton/ResetIconButton/resetIconButton.module.scss";

export interface ResetButtonProps {
    disabled?: boolean;
    onClick?: () => void;
    className?: "select-inline" | "search";
}

export const ResetIconButton: FunctionComponent<ResetButtonProps> = (
    {
        onClick,
        disabled = false,
        className = "",
    }) => <Refresh
        className={`${styles.button} ${styles[`${className}`]}`}
        onClick={() => !disabled && onClick()}
    />
