export enum LocalStorageEnum {
  TOKEN = "token",
  USER = "user",
  MENU_SETTINGS = "menuSettings",
  PERIMETER = "perimeter",
  HOMEPAGE_FILTERS = "homepageFilters",
  TRANSACTION_COLUMN = "transactionColumn",
  GLOBAL_PERIMETER_FILTERS = "globalPerimeterFilters",
  USER_SEARCH_FILTER = "userSearchFilter",
  PORTFOLIO_BILLING_FILTERS = "portfolioBillingFilters",
  PORTFOLIO_TOP_BOTTOM_FILTERS = "portfolioTopBottomFilters",
  PORTFOLIO_TRENDS_FILTERS = "portfolioTrendsFilters",
  ACCEPTED_TRANSACTIONS_FILTERS = "acceptedTransactionsFilters",
  ACQUIRED_TRANSACTIONS_FILTERS = "acquiredTransactionsFilters",
  SETTLEMENTS_FILTERS = "settlementsFilters",
  ACQUIERED_TRANSACTION_COLUMN = "acquieredTransactionColumn",
}
