import {perimeterUtils} from "../../utils/perimeter/perimeterUtils";
import {errorUtils, PromisableApiError} from "../../utils/api/errorUtils";
import {useEffect, useState} from "react";
import {Perimeter, PerimeterResponse} from "../../interfaces/perimeter/perimeter";
import {AccountResponse} from "../../interfaces/perimeter/account";
import {rightUtils} from "../../utils/business/rightUtils";
import {UserDetails} from "../../interfaces/user/user";

interface AccountPerimeterInstance {
    loading: boolean,
    perimeter: Perimeter,
    accounts: Array<AccountResponse>,
    refreshPerimeter: () => void
    refreshAccounts: () => void,
    getAccount: (idAccount: string) => Promise<Perimeter>
}


interface AccountPerimeterFunction {
    onGetPerimeter: () => Promise<Perimeter>,
    onGetAccounts: () => Promise<Array<AccountResponse>>,
    onGetAccount: (idAccount: string) => Promise<PerimeterResponse>
}

/**
 * User hook to get self information easily on your pages
 */
export const useAccountPerimeter = (userDetails: UserDetails, {
    onGetPerimeter,
    onGetAccounts,
    onGetAccount
}: AccountPerimeterFunction): AccountPerimeterInstance => {

    const [loading, setLoading] = useState<boolean>(true);
    const [perimeter, setPerimeter] = useState<Perimeter>();
    // Accounts needed for Group Perimeters
    const [accounts, setAccounts] = useState<Array<AccountResponse>>();

    const refreshPerimeter = () => {
        onGetPerimeter().then(res => {
            setPerimeter(res)
            setPerimeter(perimeterUtils.buildPerimeterUpdate(res))
            setLoading(false)
        })
            .catch((e: PromisableApiError) => {
                // Only display error if it's not a 404. The other call manage the 404 case
                if (!errorUtils.interceptErrorStatus(e, 404)) {
                    errorUtils.handleBackErrors(e);
                }
            })
    }

    const refreshAccounts = () => {
        onGetAccounts().then(res => {
            setAccounts(res)
            setLoading(false)
        })
            .catch((e: PromisableApiError) => {
                // Only display error if it's not a 404. The other call manage the 404 case
                if (!errorUtils.interceptErrorStatus(e, 404)) {
                    errorUtils.handleBackErrors(e);
                }
            })
    }

    const getAccount = async (idAccount: string): Promise<Perimeter> => {
        const perimeterResponse = await onGetAccount(idAccount);
        return perimeterUtils.responseToPerimeter(perimeterResponse);
    }

    useEffect(() => {
        if (userDetails) {
            if (rightUtils.hasGroupPerimeter(userDetails)) {
                refreshAccounts();
            } else {
                refreshPerimeter();
            }
        }
    }, [userDetails?.id])


    return {
        loading,
        perimeter,
        accounts,
        getAccount,
        refreshPerimeter,
        refreshAccounts
    }

}
