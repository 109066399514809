import React, { FunctionComponent, ReactElement, ReactNode, RefObject, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import styles from "./tooltipOverflowText.module.scss";

interface TooltipOverflowTextProps {
  textElementRef: RefObject<HTMLElement>;
  resizeClass?: string;
  text: ReactNode;
  children: ReactElement;
  isShowAlways?: boolean;
  customTooltip?: ReactNode;
}

const TooltipOverflowText: FunctionComponent<TooltipOverflowTextProps> = ({
  textElementRef,
  resizeClass = "global",
  text,
  isShowAlways,
  children,
}) => {
  const [isOverflow, setIsOverflow] = useState(false);

  const isOverflowed = () => {
    setIsOverflow(textElementRef.current && textElementRef.current.offsetWidth < textElementRef.current.scrollWidth);
  };

  useEffect(() => {
    isOverflowed();
    const elementsByClassName = [...document.getElementsByClassName(resizeClass)];
    elementsByClassName.forEach((resizerClassName) => resizerClassName.addEventListener("mouseup", isOverflowed));
    return () =>
      elementsByClassName.forEach((resizerClassName) => resizerClassName.removeEventListener("mouseup", isOverflowed));
  }, [text]);

  return (
    <Tooltip
      title={
        <div className={styles["tooltip"]}>
          {text}
        </div>
      }
      placement="bottom-start"
      disableHoverListener={!isOverflow && !isShowAlways}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipOverflowText;
