import { useEffect, useState } from "react";
import { useAuthUser } from "hooks/useAuthUser";
import { useIntl } from "react-intl";
import { notificationService } from "services/notification/NotificationService";
import { arrayUtils } from "utils/common/arrayUtils";
import { toastUtils } from "utils/toast/toastUtils";
import { getNotifTypesForProfile, mapNotifChannelToMessageCode } from "constants/notification/NotificationSettingsConfig";
import { newToggle, NotificationChannel, NotificationSettingsType, NotificationToggle, toggleEq } from "types/notifications/NotificationSettingsTypes";
import { VoidNoParamsFn } from "types/common/CommonTypes";
import {errorUtils} from "../../utils/api/errorUtils";

export interface NotificationSettingsLogic{
    userProfile:string,
    activeToggles: readonly NotificationToggle[],
    isLoading: boolean,
    isSaving: boolean,
    translateChannel: (channel:NotificationChannel) => string,
    triggerToggle:(toggle:NotificationToggle) => void,
    checkAllInApp: VoidNoParamsFn,
    checkAllEmail: VoidNoParamsFn,
    areAllEmailChecked: boolean,
    areAllInAppChecked: boolean,
    isNotifTypeVisibleWithUserProfile: (toggleType:NotificationSettingsType) => boolean,
    isFormModified: boolean,
    onPersistForm: VoidNoParamsFn,
    onDiscardForm: VoidNoParamsFn,
}

export const useNotificationSettingsLogic = ():NotificationSettingsLogic => {
    const {user:{profile:userProfile}} = useAuthUser();
    const [activeToggles, setActiveToggles] = useState<readonly NotificationToggle[]>([]);
    const [originalToggles, setOriginalToggles] = useState<readonly NotificationToggle[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [isSaving, setSaving] = useState(false);
    const intl = useIntl();
    
    const triggerToggle = (
        toggle:NotificationToggle
    ):void => {
        const toggleIndex:number = arrayUtils.indexOf(activeToggles, toggle, toggleEq);
        if(toggleIndex !== -1){
            setActiveToggles(arrayUtils.removeAtIndex(activeToggles, toggleIndex));
        }else{
            setActiveToggles(arrayUtils.append(activeToggles,toggle));
        }
    }
    
    useEffect( () => {
        notificationService.getNotificationSettings().then(resp => {
            const loadedToggles:NotificationToggle[] = resp.activeToggles;
            setLoading(false);
            setActiveToggles(loadedToggles);
            setOriginalToggles(loadedToggles);
        }).catch(errorUtils.handleBackErrors);
    }, []);
    
    function translateChannel(
        channel:NotificationChannel,
    ):string {
        return intl.formatMessage({id:mapNotifChannelToMessageCode(channel)});
    }
    
    const profileNotifType:NotificationSettingsType[] = getNotifTypesForProfile(userProfile);
    
    function areAllInChannelSet(
        channel: NotificationChannel,
    ):boolean {
        for(const notifType of profileNotifType){
            if(arrayUtils.indexOf(activeToggles, newToggle(channel, notifType), toggleEq) === -1){
                return false;
            }
        }
        return true;
    }
    
    const areAllInAppChecked = areAllInChannelSet(NotificationChannel.IN_APP);
    
    const triggerAllChannelToggles = (
        channel:NotificationChannel,
        activate:boolean,
    ):NotificationToggle[] => {
        if(activate){
            return activeToggles.filter(notifType => notifType.channel !== channel)
            .concat(profileNotifType.map(notifType => newToggle(channel, notifType)));
        }
        return activeToggles.filter(t => t.channel !== channel);
    }
    
    const checkAllInApp:VoidNoParamsFn = () => {
        setActiveToggles (
            triggerAllChannelToggles (NotificationChannel.IN_APP, !areAllInAppChecked)
        );
    }
    
    const areAllEmailChecked = areAllInChannelSet(NotificationChannel.EMAIL);
    
    const checkAllEmail:VoidNoParamsFn = () => {
        setActiveToggles (
            triggerAllChannelToggles (NotificationChannel.EMAIL, !areAllEmailChecked)
        );
    }
    
    const isFormModified = !arrayUtils.hasSameContent(originalToggles, activeToggles, (a,b) => a === b );
    
    const onPersistForm:VoidNoParamsFn = () => {
        setSaving(true);
        notificationService.updateNotificationSettings(activeToggles)
        .then(() => {
            setOriginalToggles(activeToggles);
            setSaving(false);
            toastUtils.successToastI8ln("account_updated");
        }).catch(errorUtils.handleBackErrors);
    }
    
    const onDiscardForm:VoidNoParamsFn = () => {
        setActiveToggles(originalToggles);
    }
    
    const isNotifTypeVisibleWithUserProfile = (
        toggleType:NotificationSettingsType,
    ):boolean => {
        return profileNotifType.some(type => type === toggleType);
    }

    return {
        userProfile,
        activeToggles,
        isLoading,
        isSaving,
        triggerToggle,
        checkAllEmail,
        checkAllInApp,
        translateChannel,
        areAllEmailChecked,
        areAllInAppChecked,
        isNotifTypeVisibleWithUserProfile,
        isFormModified,
        onPersistForm,
        onDiscardForm,
    }
}