import React, { FunctionComponent } from "react";
import { Link as ReactLink } from "react-router-dom";
import { useChangeContext } from "constants/context/ChangeContext";

interface LinkProps {
    to: string;
    search?: string;
    openNewTab?: boolean;
    className?: string;
    noUnderline?: boolean;
    external?: boolean;
    disableWatch?: boolean;
    mobileMenuOpen?: boolean;
    onCloseMobileMenu?: () => void;
    isLanguageItemSelected?: boolean;
    isParent?: boolean;
}

const WatchedLink: FunctionComponent<LinkProps> = ({
    to,
    search = "",
    children,
    openNewTab = false,
    className = "",
    noUnderline = false,
    external = false,
    disableWatch = false,
    mobileMenuOpen,
    onCloseMobileMenu,
    isLanguageItemSelected,
    isParent,
}) => {

    const { unsaveChange, setOpenDialog, setNavigateTo } = useChangeContext();
    const toFormatted = { pathname: to, search };

    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!isParent && !isLanguageItemSelected && mobileMenuOpen) {
            // after selected a menu item from the sidebar, close the menu on mobile devices 
            onCloseMobileMenu()
        }
        if (unsaveChange && !disableWatch) {
            e.preventDefault()
            setOpenDialog(true)
            setNavigateTo(toFormatted)
        }
    }

    if (external) {
        return (
            <a href={to}
                className={`mp-text-link ${noUnderline ? "no-underline " : " "} ${className}`}
                target={openNewTab ? "_blank" : "_self"} rel="noreferrer">
                {children}
            </a>
        )
    } else {

        return (
            <ReactLink
                to={toFormatted}
                className={`mp-text-link ${noUnderline ? "no-underline" : " "} ${className}`}
                onClick={onClick}
                target={openNewTab ? "_blank" : ""} rel="noreferrer">
                {children}
            </ReactLink>
        )
    }
}


export default WatchedLink;
