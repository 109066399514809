import {styled} from "@mui/material/styles";
import {paletteTheme} from "../../themes/palette";
import WatchedLinkUI from "../../../components/atoms/link/WatchedLinkUI";
import {LinkProps} from "@mui/material";

export default styled(WatchedLinkUI)<LinkProps>(() => {
    return ({
        color: paletteTheme.colors.DEFAULT_TEXT_COLOR,
        "& .MuiLink-root": {
            color: paletteTheme.colors.DEFAULT_TEXT_COLOR
        },
        "& .MuiLink-root:hover, & .MuiLink-root:hover > .MuiBox-root .MuiTypography-root": {
            color: paletteTheme.colors.PRIMARY
        },
        "& .MuiLink-root:active, & .MuiLink-root:active > .MuiBox-root MuiTypography-root": {
            color: paletteTheme.colors.DEEP_GREEN
        },
    });
});
