import React, {FunctionComponent} from "react";
import LabeledCheckbox from "../../atoms/checkbox/LabeledCheckbox";
import {Options} from "../../../constants/options/option";

interface AutocompleteOptionProps extends React.HTMLAttributes<HTMLLIElement> {
    option: Options,
    selectedOptions: Options[],
    translatedLabel?: boolean
    disabled?: boolean,
    allSelected?: boolean
}

const AutocompleteOption: FunctionComponent<AutocompleteOptionProps> = ({
                                                                            option,
                                                                            selectedOptions,
                                                                            translatedLabel = true,
                                                                            allSelected = false,
                                                                            disabled = false,
                                                                            ...rest
                                                                        }) => {

    const map = Object.values(selectedOptions).reduce((acc, next) => ({...acc, [next.id]: next}), {});
    return (
        <li {...rest} aria-disabled={disabled} style={{marginTop:"3px",  marginBottom:"3px"}}>
            <LabeledCheckbox
                label={option.name}
                labelFontSize="10px"
                sx={{padding: 0}}
                disableClick
                checked={!!map[option.id] || allSelected}
                translatedLabel={translatedLabel}
                customClassName="autocomplete-dropdown"
                />
        </li>
    )
}

export default AutocompleteOption
