import React, { FunctionComponent } from "react";
import OverflowTooltip from "components/molecules/tooltip/OverflowTooltip";
import { IUserCellProps } from "interfaces/table/table";

export const TransactionDetailTimestampCell: FunctionComponent<IUserCellProps> = ({ value }) => {
    return <div className="flex flex-col-center" style={{ height: "100%" }}>
        <OverflowTooltip className="remove-ellipsis" resizeClass="resizer">
            {value}
        </OverflowTooltip>
    </div>;
};
