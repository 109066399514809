import { useState } from "react";
import { perimeterService } from "services/user/PerimeterService";
import { errorUtils } from "utils/api/errorUtils";
import { selectUtils } from "utils/business/selectUtils";
import { PerimeterOptions } from "constants/perimeter/perimeter";
import {
  PerimeterFilterValues,
} from "interfaces/transaction/transaction";

interface AutocompleteSearchFilterInstance {
  onChangeAccountName: (search: string) => void
  onChangeCompanyName: (search: string, page?: number, accounts?: Array<string>) => void
  onChangeMerchantName: (search: string) => void,
  handleAccountsSelection: () => void,
  handleCompaniesSelection: () => void,
}

/**
 * Transaction filter hook to get perimeterOptions easily on your pages
 */
export function useAutocompleteSearchFilter<T extends PerimeterFilterValues>(
  perimeterOptions: PerimeterOptions,
  setPerimeterOptions: (value: PerimeterOptions) => void,
  filtersSelected: T,
  uniqueCompany?: boolean,
  uniqueMerchant?: boolean,
): AutocompleteSearchFilterInstance {

  const [totalPagesAccount, setTotalPagesAccount] = useState<number>(1)
  const [totalPagesCompany, setTotalPagesCompany] = useState<number>(1)
  const [totalPagesMerchant, setTotalPagesMerchant] = useState<number>(1)

  const onChangeAccountName = (search: string, page = 0) => {
    if (totalPagesAccount === 0 || totalPagesAccount > page) {
      const ac = new AbortController();
      perimeterService.searchAccounts({ signal: ac.signal }, search, page).then(res => {
        setTotalPagesAccount(() => res.totalPages === 0 ? 1 : res.totalPages);
        setPerimeterOptions({
          ...perimeterOptions,
          accounts: page ? perimeterOptions.accounts.concat(selectUtils.mapEntityToOptions(res.content)) : selectUtils.mapEntityToOptions(res.content),
          companies: [],
          merchants: []
        })
      }).catch(errorUtils.handleBackErrors)
      return () => ac.abort();
    }
  }

  const onChangeCompanyName = (search: string, page = 0) => {
    if (totalPagesCompany === 0 || totalPagesCompany > page) {
      const ac = new AbortController();
      perimeterService.searchCompanies({ signal: ac.signal }, search, filtersSelected.accounts.map(account => account.id), page).then(res => {
        setTotalPagesCompany(() => res.totalPages === 0 ? 1 : res.totalPages);
        setPerimeterOptions({
          ...perimeterOptions,
          companies: page ? perimeterOptions.companies.concat(selectUtils.mapPerimeterToCompaniesOptions(res.content, uniqueCompany).filter(companie => companie.id)) : selectUtils.mapPerimeterToCompaniesOptions(res.content, uniqueCompany),
          merchants: []
        })
      }).catch(errorUtils.handleBackErrors)
      return () => ac.abort();
    }
  }

  const onChangeMerchantName = (search: string, page = 0) => {
    if (totalPagesMerchant === 0 || totalPagesMerchant > page) {
      const ac = new AbortController();
      perimeterService.searchMerchants({ signal: ac.signal }, search, filtersSelected.accounts.filter(item => item.id !== "").map(company => company.id), filtersSelected.companies.selectedOptions.filter(item => item.id !== "").map(company => company.id), page).then(res => {
        setTotalPagesMerchant(() => res.totalPages === 0 ? 1 : res.totalPages);
        setPerimeterOptions({
          ...perimeterOptions,
          merchants: page ? perimeterOptions.merchants.concat(selectUtils.mapPerimeterToMerchantsOptions(res.content, uniqueMerchant).filter(merchant => merchant.id)) : selectUtils.mapPerimeterToMerchantsOptions(res.content, uniqueMerchant)
        })
      }).catch(errorUtils.handleBackErrors)
      return () => ac.abort();
    }
  }

  const handleAccountsSelection = () => {
    onChangeCompanyName("")
  }

  const handleCompaniesSelection = () => {
    onChangeMerchantName("")
  }

  // endregion

  return {
    onChangeAccountName,
    onChangeCompanyName,
    onChangeMerchantName,
    handleAccountsSelection,
    handleCompaniesSelection,
  };
}
