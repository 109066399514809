import {Components} from "@mui/material/styles/components";
import {Theme} from "@mui/material";
import {paletteTheme} from "../palette";

export const components: Components<Theme> = {
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: "2px",
                height: "28px",
                fontSize: "12px",
                boxShadow: "none"
            }
        }
    },
    MuiCard: {
        styleOverrides: {
            root: {
                boxShadow: "none",
                padding: "20px",
                borderRadius: "1px",
                color: paletteTheme.colors.DEFAULT_TEXT_COLOR,
                background: paletteTheme.colors.WHITE,
            }
        }
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                "&.Mui-error": {
                    color: paletteTheme.colors.DANGER
                }
            }
        }
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: "2px",
                fontSize: 14,
            }
        }
    },
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: "2px",
            },
            sizeSmall: {
                height: "13px"
            },
            label: {
                padding: "2px 3px"
            }
        }
    },
    MuiTypography: {
        styleOverrides: {
            root: {
                "&.MuiLink-root": {
                    color: "inherit",
                    textDecorationColor: "inherit"
                }
            },
        }
    },
    MuiTooltip: {
        styleOverrides: {
            tooltipPlacementRight: {
                marginLeft: "3px"
            },
            tooltip: {
                backgroundColor: paletteTheme.colors.GRAY_LIGHT,
                color: paletteTheme.colors.DEFAULT_TEXT_COLOR,
                padding: "2px",
                fontSize: 10,
                borderRadius: "2px"
            }
        }
    },
    MuiSelect: {
        styleOverrides: {
            outlined: {
                padding: "8px",
                paddingLeft: "10px",
            },
            multiple: {
                "&.MuiOutlinedInput-input.MuiInputBase-input": {
                    paddingRight: "25px"
                }
            }
        }
    },
    MuiDialog: {
        styleOverrides: {
            container: {
                backgroundColor: "rgba(88, 100, 125, 0.3)"
            }
        }
    },
    MuiGrid: {
        styleOverrides: {
            root: {
                width: "unset"
            }
        }
    },
    MuiAppBar:{
        styleOverrides:{
            root:{
                height: "inherit",
                overflow: "hidden",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                background: paletteTheme.colors.WHITE,
            }
        }
    },
    MuiToolbar:{
        styleOverrides:{
            root:{
                display:"grid",
                background: paletteTheme.colors.WHITE,                
            }
        }
    },


}
