import React, {FunctionComponent} from "react";
import {NotificationMessage, NotificationResponse} from "../../../../interfaces/notification/notification";
import {NOTIFICATIONS} from "../../../../constants/notification/notification";
import {paletteTheme} from "../../../../styles/themes/palette";
import NotificationHideButton from "../NotificationHideButton";
import { NotificationBody } from "../NotificationBody";

interface NotificationProps {
    notification: NotificationResponse,
    onClick?: (notificationToHide: NotificationResponse) => void,
    marginLeft?: string,
    marginBottom?: string,
    canHide?: boolean
}

const Notification: FunctionComponent<NotificationProps> = ({
                                                                notification,
                                                                onClick,
                                                                marginLeft = "",
                                                                marginBottom = "",
                                                                canHide = false,
                                                            }) => {

    const notifType = NOTIFICATIONS[notification.notificationType];
    const {IconNotification,  type} = notifType;
    const notifMessage: NotificationMessage = {
        notification: notification,
        label: notifType.label,
        url: notifType.url,
        icon: <IconNotification style={{color: notification.hidden ? paletteTheme.colors.GRAY_DARK : notifType.iconColor, fontSize: "16px"}}></IconNotification>,
    }

    const targetUrl: string = notifType.targetUrlBuilder
      ? notifType.targetUrlBuilder(notifMessage.notification.targetId)
      : notifType.url;

    return (
        <div className="flex flex-col-baseline" style={{marginLeft: marginLeft, marginBottom: marginBottom}}>
            <NotificationBody
                notificationMessage={notifMessage}
                targetUrl={targetUrl}
                isHidden={notifMessage.notification.hidden}
                type={type}
            />
            {canHide && <NotificationHideButton 
                            label="notif_hide_button"
                            onHide={() => onClick(notification)}>    
                        </NotificationHideButton>
            }
        </div>
    )
}

export default Notification;
