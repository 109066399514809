import {User, UserDetails} from "../../interfaces/user/user";
import {PROFIL_USER} from "../../constants/user/user";
import {PermissionLine} from "../../constants/permissions/permissions";

function isAdmin(user: User): boolean {
    return user && PROFIL_USER[user.profile] === PROFIL_USER.ADMIN
}

function hasGroupPerimeter(user: User): boolean {
    return user && PROFIL_USER[user.profile] === PROFIL_USER.MERCHANT_ADMIN && !!user.idGroup
}

function isAdminOrGroupPerimeter(user: User): boolean{
    return isAdmin(user) || hasGroupPerimeter(user)
}

function userHasPermission(user: UserDetails, permission: PermissionLine): boolean {
    return user && user.permissions?.includes(permission.id) || isAdmin(user);
}

function hasPermission(permissions: Array<string>, permission: PermissionLine): boolean {
    return permissions?.includes(permission.id);
}

function isOperator(user: User): boolean {
    return user && (PROFIL_USER[user.profile] === PROFIL_USER.OPERATOR)
}

function isDistributor(user: User): boolean {
    return user.distributor;
}

export const rightUtils = {
    isAdmin,
    isOperator,
    hasPermission,
    userHasPermission,
    hasGroupPerimeter,
    isAdminOrGroupPerimeter,
    isDistributor,
}
