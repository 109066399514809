import React, { FunctionComponent } from "react";
import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as LogoWhite } from "assets/logo-white.svg";
import WatchedLink from "components/atoms/link/WatchedLink";
import styles from "components/molecules/logoarea/logoArea.module.scss";
import MobileMenu from "components/molecules/menu/MobileMenu";

type LogoAreaProps = {
    onMenuClick?: () => void,
    mobileMenuOpen: boolean
    mobile: boolean,
}

export const LogoArea: FunctionComponent<LogoAreaProps> = ({
    onMenuClick,
    mobileMenuOpen,
    mobile,
}) => {

    const showMobileMenuOpenLogo = mobile && mobileMenuOpen;

    return (
        <div className={"flex"}>
            <div>
                {mobile &&
                    <MobileMenu onMenuClick={onMenuClick} mobileMenuOpen={mobileMenuOpen} />
                }
            </div>
            <div className={styles["logo-wrap"]}>
                <WatchedLink noUnderline key="logo" to="/" >
                    {showMobileMenuOpenLogo ? <LogoWhite style={{ marginTop: "7px" }} /> : <Logo />}
                </WatchedLink>
            </div>
        </div>
    )
}
