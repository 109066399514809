import React, {FunctionComponent, useMemo} from "react";
import {RouteProps} from "react-router-dom";
import HomepageIntroduction from "../components/molecules/homepage/HomepageIntroduction";
import {useAuthUser} from "../hooks/useAuthUser";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import {PerimeterFilterValues} from "../interfaces/transaction/transaction";
import {useFilter} from "../hooks/filter/useFilter";
import {filterUtils} from "../utils/business/filterUtils";
import { HomepageHistogram } from "../components/molecules/homepage/HomepageHistogram";
import {useGraph} from "../hooks/graph/useGraph";
import {
    HISTOGRAM_TYPES,
    SETTLEMENT_AMOUNT_SELECTOR,
    TRANSACTION_AMOUNT_SELECTOR
} from "../constants/histogram/histogram";
import {rightUtils} from "utils/business/rightUtils";
import {PERMISSIONS} from "constants/permissions/permissions";

const HomeView: FunctionComponent<RouteProps> = () => {

    const savedInitialFilters: PerimeterFilterValues = JSON.parse(localStorage.getItem(LocalStorageEnum.HOMEPAGE_FILTERS));
    const {userDetails} = useAuthUser()

    const userDetail = userDetails;
    const {
        perimeterOptions,
        onChangePerimeterOptions,
        initialPerimeterOptions: initialOptions,
        onChangeFilters,
        filters,
        onSearchFilter,
        onResetFilter,
        showContent
    } = useFilter<PerimeterFilterValues>(
        userDetails,
        filterUtils.buildEmptyPerimeterFilterValues(),
        LocalStorageEnum.HOMEPAGE_FILTERS,
        filterUtils.initSavedPerimeterFilters(savedInitialFilters)
    );

    const {
        temporality: transTemporality,
        data: transData,
        dailyKeyNumbers: transDailyKeyNumbers,
        loading: transLoading,
        onChangeTemporality: onChangeTransTemporality,
        onSearchTransactions,
        onResetGraph: onResetTransGraph,
    } = useGraph(
        () => onSearchFilter(),
        filters,
        showContent,
        true
    )

    const {
        temporality: settlementTemporality,
        data: settlementData,
        dailyKeyNumbers: settDailyKeyNumbers,
        loading: settlementLoading,
        onChangeTemporality: onChangeSettlementTemporality,
        onSearchSettlements,
        onResetGraph: onResetSettlementGraph,
    } = useGraph(
        () => onSearchFilter(),
        filters,
        showContent,
        false
    )

    const onSearch = () => {
        onSearchTransactions();
        onSearchSettlements();
    }

    const onReset = () => {
        onChangeFilters(filterUtils.getInitialFiltersByPerimeterOptionsAndPerimeter<PerimeterFilterValues>(filterUtils.buildEmptyPerimeterFilterValues(), perimeterOptions));
        onResetFilter();
        onResetTransGraph();
        onResetSettlementGraph();
    }

    const canViewSettlements: boolean = useMemo(() => {
        return rightUtils.userHasPermission(userDetail, PERMISSIONS.SETTLEMENTS_CONSULT)
    }, [userDetail.id])

    const canViewTransactions: boolean = useMemo(() => {
        return rightUtils.userHasPermission(userDetail, PERMISSIONS.TRANSACTIONS_CONSULT)
    }, [userDetail.id])
    return (
        <>
            <HomepageIntroduction
                perimeterOptions={perimeterOptions}
                onChangePerimeterOptions={onChangePerimeterOptions}
                initialOptions={initialOptions}
                onChangeFilters={onChangeFilters}
                filters={filters}
                onReset={onReset}
                onSearch={onSearch}
                transDailyKeyNumbers={transDailyKeyNumbers}
                settDailyKeyNumbers={settDailyKeyNumbers}
            />

            {canViewTransactions &&
                <HomepageHistogram
                    data={transData}
                    temporality={transTemporality}
                    onChangeTemporality={onChangeTransTemporality}
                    loading={transLoading}
                    histogramType={HISTOGRAM_TYPES.TRANSACTION}
                    histogramAmountSelector={TRANSACTION_AMOUNT_SELECTOR}/>
            }
            {canViewSettlements &&
                <HomepageHistogram
                    data={settlementData}
                    temporality={settlementTemporality}
                    onChangeTemporality={onChangeSettlementTemporality}
                    loading={settlementLoading}
                    histogramType={HISTOGRAM_TYPES.SETTLEMENT}
                    histogramAmountSelector={SETTLEMENT_AMOUNT_SELECTOR}/>
            }
        </>
    )
}

export default HomeView;
