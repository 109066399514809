import React, { FunctionComponent } from "react";
import { filterUtils } from "utils/business/filterUtils";
import { arrayUtils } from "utils/common/arrayUtils";
import AutocompleteCheckbox, { AutocompleteCheckboxProps } from "components/molecules/autocomplete/AutocompleteCheckbox";
import {
    AutocompleteCheckboxConfig,
    PerimeterConfig,
} from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";

interface AutocompleteCheckboxWrapperProps {
    name: string;
    perimeterConfig: PerimeterConfig;
    autoCompleteCheckboxConfig: AutocompleteCheckboxConfig;
}

interface Config extends AutocompleteCheckboxProps {
    hideItem?: boolean;
}

export const AutocompleteCheckboxWrapper: FunctionComponent<AutocompleteCheckboxWrapperProps> = (
    {
        perimeterConfig: {
            isAdminOrGroupPerimeter,
            filters,
            initialOptions,
            perimeterOptions,
            uniqueCompany,
            uniqueMerchant,
        },
        autoCompleteCheckboxConfig: {
            onChangeAccountsSelection,
            onChangeCompaniesSelection,
            onChangeMerchantsSelection,
            onChangeAccountName,
            handleAccountsSelection,
            handleCompaniesSelection,
            onChangeCompanyName,
            onChangeMerchantName
        },
        name
    }) => {

    const getConfig = (name: string) => {
        let config: Config = {
            values: undefined,
            options: undefined,
            onChange: undefined,
            hideItem: false,
        };

        switch (name) {
            case "account":
                config = {
                    values: filters.accounts,
                    options: perimeterOptions.accounts,
                    onChange: onChangeAccountsSelection,
                    label: { message: "filter_account" },
                    onChangeQuery: onChangeAccountName,
                    customOnBlur: handleAccountsSelection,
                    disableChoices: filterUtils.hasUniqueElementNotAdmin(isAdminOrGroupPerimeter, initialOptions?.accounts),
                    uniqueElement: true,
                    disabled: false,
                    hideItem: false,
                }
                break;
            case "company":
                config = {
                    values: filters.companies.selectedOptions,
                    options: perimeterOptions.companies,
                    onChange: onChangeCompaniesSelection,
                    label: { message: "filter_company" },
                    onChangeQuery: (query: string, page: number) => onChangeCompanyName(query, page, filters.accounts.map(option => option.id)),
                    customOnBlur: handleCompaniesSelection,
                    disableChoices: filterUtils.hasUniqueElementNotAdmin(isAdminOrGroupPerimeter, initialOptions?.companies),
                    uniqueElement: uniqueCompany,
                    disabled: arrayUtils.isEmpty(filters.accounts),
                    hideItem: false,
                }
                break;
            case "merchant":
                config = {
                    values: filters.merchants.selectedOptions,
                    options: perimeterOptions.merchants,
                    onChange: onChangeMerchantsSelection,
                    label: { message: "filter_merchant" },
                    onChangeQuery: onChangeMerchantName,
                    disableChoices: filterUtils.hasUniqueElementNotAdmin(isAdminOrGroupPerimeter, initialOptions?.merchants),
                    uniqueElement: uniqueMerchant,
                    disabled: arrayUtils.isEmpty(filters.accounts) || arrayUtils.isEmpty(filters.companies.selectedOptions),
                    hideItem: false,
                }
                break;
            default:
                config = {
                    hideItem: true,
                    ...config,
                }
                break;
        }
        return { ...config }

    }
    const { hideItem } = getConfig(name);

    return <>
        {!hideItem &&
            <AutocompleteCheckbox {...getConfig(name)} />
        }
    </>

}
