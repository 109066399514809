import React, { FunctionComponent } from "react";
import { MULTISELECT_VALUES } from "constants/transaction/transactionFilter";
import { MultichoiceCheckbox, MultichoiceCheckBoxProps } from "components/molecules/checkbox/MultichoiceCheckbox";
import { MultichoiceConfig } from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import { TransactionAdditionalFilter } from "interfaces/transaction/transaction";
import { TransactionType } from "interfaces/filter/filterColumn";

type FilterType = TransactionType | "DOCUMENTS" | "PORTFOLIO_TREND_BY";

interface MultiChoiceCheckboxWrapperProps {
  itemConfig: TransactionAdditionalFilter;
  multichoiceConfig: MultichoiceConfig;
  type: FilterType;
}

interface MultiselectConfig extends MultichoiceCheckBoxProps {
  hideItem?: boolean;
}

export const MultiChoiceCheckboxWrapper: FunctionComponent<MultiChoiceCheckboxWrapperProps> = ({
  multichoiceConfig: { transactionAdditionalFilters, documentsFilters, trendByFilters, onChangeMultichoiceFilter },
  itemConfig,
  type,
}) => {
  const { formatMessage } = useIntlFormatter();
  const values = (type: FilterType) =>{
    if(type === "DOCUMENTS"){
      return documentsFilters[itemConfig.id] ?? [];
    }
    if(type === "PORTFOLIO_TREND_BY"){
      return trendByFilters[itemConfig.id] ?? [];
    }
    return transactionAdditionalFilters[itemConfig.id] ?? []
  }

  const multiselectConfig: MultiselectConfig = {
    hideReset: !itemConfig.multiple,
    displayCheckboxes: itemConfig.displayCheckboxes ?? true,
    hideItem: itemConfig.id ? false : true,
    multiple: itemConfig.multiple,
    label: formatMessage(itemConfig.label),
    values: values(type),
    options: MULTISELECT_VALUES[type]?.[itemConfig.id].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) || [],
    translatedLabel: type !== "DOCUMENTS", // don't translate years and months dropdowns used for Documents and Billing filters
    onChange: onChangeMultichoiceFilter(itemConfig.id),
  };

  return <>{!multiselectConfig.hideItem && <MultichoiceCheckbox {...multiselectConfig} />}</>;
};
