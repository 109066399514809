import {User, UserDetails} from "../interfaces/user/user";
import {useUserContext} from "../constants/context/UserContext";
import { LocalStorageEnum } from "constants/localstorage/localstortage";

interface UserInstance {
    user: User,
    userDetails: UserDetails,
    setUser: (user: User) => void,
    cleanAndRefreshUserDetails: () => void,
    refreshUserDetails: () => void,
    permissions: Array<string>,
    setPermissions: (permissions: Array<string>) => void,
}

/**
 * User hook to get self information easily on your pages
 */
export const useAuthUser = (): UserInstance => {

    const {user, setUser, refreshUserDetails, setPermissions, permissions, userDetails} = useUserContext();
    const cleanAndRefreshUserDetails = () => {
        localStorage.removeItem(LocalStorageEnum.USER)
        refreshUserDetails()
    }

    return {
        user,
        userDetails,
        permissions,
        setUser,
        cleanAndRefreshUserDetails,
        refreshUserDetails,
        setPermissions,
    };
}
