import React, {FunctionComponent, useState} from "react";
import {RouteProps} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useAuthUser} from "../hooks/useAuthUser";
import Card from "../components/atoms/card/Card";
import {Typography} from "@mui/material";
import ContainedButton from "../components/atoms/button/ContainedButton";
import {resetPasswordTokenService} from "../services/resetPasswordToken/ResetPasswordTokenService";
import {errorUtils} from "../utils/api/errorUtils";
import {toastUtils} from "../utils/toast/toastUtils";
import {paletteTheme} from "../styles/themes/palette";
import {MaterialUiColor} from "../types/materialui/materialui";

const ChangePasswordView: FunctionComponent<RouteProps> = () => {

    const {user} = useAuthUser();
    const [disabled, setDisabled] = useState<boolean>(false);

    const onClick = () => {
        resetPasswordTokenService.createToken(user.email)
            .then(() => {
                toastUtils.successToastI8ln("change_password_sent_email")
                setDisabled(true)
            })
            .catch(errorUtils.handleBackErrors)
    }
    return (
    <>
        {user && 
        <Card
            cardTitle={
                <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
                    <FormattedMessage id="change_password_title"/>
                </Typography>
            }>
            <Typography variant="h2">
                <FormattedMessage id="change_password_reset_password"/>
                </Typography>
            <Typography marginBottom={2}>
                <FormattedMessage id="change_password_send_email"/>{" "}
            <Typography sx={{textDecoration: "underline"}} component="span" display="inline" color={MaterialUiColor.PRIMARY}>{user.email}</Typography>
            </Typography>
            <ContainedButton disabled={disabled} onClick={onClick}><FormattedMessage
                id="change_password_button"/></ContainedButton>
        </Card>}
    </>);
}

export default ChangePasswordView;
