import React, {FunctionComponent, useRef} from "react";
import {
    Chart as ChartJS,
    registerables
} from "chart.js";
import {
    Chart as ReactChart,
    ChartProps,
} from "react-chartjs-2";

ChartJS.register(
    ...registerables
);


const Chart: FunctionComponent<ChartProps> = ({
                                                  options,
                                                  type,
                                                  data,
                                                  ...rest
                                              }) => {
    const chartRef = useRef<ChartJS | null>(null);

    return (
        <ReactChart
            ref={chartRef}
            type={type}
            options={options}
            data={data}
            {...rest}
        />
    );
}

export default Chart;
