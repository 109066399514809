/**
 * File listing every translated label in Portugese
 */

export const PortugeseMessages = {
  // LANGUAGE
  language_en: "EN",
  language_fr: "FR",
  language_it: "IT",
  language_es: "SP",
  language_nl: "NL",
  language_pt: "PT"
};
