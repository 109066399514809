import React, { FunctionComponent, useState } from "react";
import { UserDetails } from "interfaces/user/user";
import { AccountResponse } from "interfaces/perimeter/account";
import { CardRow } from "components/atoms/card/CardRow";
import { Box, Typography } from "@mui/material";
import { paletteTheme } from "styles/themes/palette";
import { Perimeter } from "interfaces/perimeter/perimeter";
import { UserAccountPerimeterCompanyList } from "components/molecules/useraccount/details/UserAccountPerimeterCompanyList";
import { errorUtils, PromisableApiError } from "utils/api/errorUtils";
import { ArrowForwardIos } from "@mui/icons-material";
import FlexBox from "components/atoms/flex/FlexBox";
import { FlexAttributValue } from "types/flex/flex";

interface UserGroupAccountPerimeterDetailsProps {
  user: UserDetails;
  accounts: Array<AccountResponse>;
  onCollapseAccount: (idAccount: string) => Promise<Perimeter>;
}

export const UserGroupAccountPerimeterDetails: FunctionComponent<UserGroupAccountPerimeterDetailsProps> = ({
  user,
  accounts,
  onCollapseAccount,
}) => {

  const [cachedPerimeters, setCachedPerimeters] = useState<Array<Perimeter>>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<Perimeter>();

  const onCollapse = (idAccount: string) => {

    if (fetching) {
      return;
    }

    if (selectedAccount?.account.id === idAccount) {
      setSelectedAccount(null);
      return;
    }

    const cachedPerimeter = cachedPerimeters.find(perimeter => perimeter.account.id === idAccount);

    if (cachedPerimeter) {
      setSelectedAccount(cachedPerimeter);
    } else {
      setFetching(true)
      onCollapseAccount(idAccount).then(perimeter => {
        setCachedPerimeters([...cachedPerimeters, perimeter]);
        setSelectedAccount(perimeter);
        setFetching(false);
      }).catch((e: PromisableApiError) => {
        errorUtils.handleBackErrors(e);
        setFetching(false);
      })
    }
  }

  return (
    <div>
      <CardRow color={paletteTheme.colors.PRIMARY} id="group_name" label="perimeter_group">
        <Typography color="primary">{user?.groupName.toUpperCase()}</Typography>
      </CardRow>
      <Box marginX={7} marginTop="43px" borderBottom={`solid 1px ${paletteTheme.colors.GRAY_LIGHT}`}></Box>
      {accounts.map((account, index) => {
        const selected = selectedAccount?.account.id === account.id;
        const last = accounts.length - 1 === index;
        return <Box position="relative" key={`${account.id}-${account.name}`} paddingTop="43px">
          <Box position="absolute" sx={{ cursor: "pointer", width: "100%", height: "110px", maxWidth: 1300 }}
            zIndex={10}
            onClick={() => onCollapse(account.id)}
            right={65} top={0}>
            <FlexBox alignItems={FlexAttributValue.CENTER} height="100%" justifyContent={FlexAttributValue.END}>
              <ArrowForwardIos
                sx={{
                  cursor: "pointer",
                  color: paletteTheme.colors.GRAY,
                  transform: selected && "rotate(90deg)"
                }}
              />
            </FlexBox>
          </Box >

          <CardRow
            id="peri_account"
            label="peri_account"
          >
            <Typography>{account.name}</Typography>
          </CardRow>
          {selected && <UserAccountPerimeterCompanyList perimeter={selectedAccount} />}
          <Box
            marginX={7}
            marginTop="43px"
            borderBottom={last ? "" : `solid 1px ${paletteTheme.colors.GRAY_LIGHT}`}></Box>
        </Box>;
      }
      )}
    </div>
  )
}
