import React, { useState } from "react";
import { DocumentsFilterValues } from "interfaces/filter/filter";
import { Options } from "constants/options/option";
import { useAuthUser } from "hooks/useAuthUser";
import { useFilter } from "hooks/filter/useFilter";
import { useOnChangeFilter } from "hooks/filter/useOnChangeFilter";
import { useAutocompleteSearchFilter } from "hooks/filter/useAutocompleteSearchFilter";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";
import { filterUtils } from "utils/business/filterUtils";
import { rightUtils } from "utils/business/rightUtils";
import {
  AutocompleteCheckboxConfig,
  MultichoiceConfig,
  QueryAndResetConfig,
} from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import { Download } from "@mui/icons-material";

export const useDocumentsFilters = () => {

  const { user, userDetails } = useAuthUser();
  const isAdminOrGroupPerimeter = rightUtils.isAdminOrGroupPerimeter(user);

  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const {
    perimeterOptions,
    onChangePerimeterOptions,
    initialPerimeterOptions: initialOptions,
    filters,
    onChangeFilters,
    onSearchFilter,
    onResetFilter,
    showContent,
  } = useFilter<DocumentsFilterValues>(
    userDetails,
    filterUtils.getDocumentsInitialFilters(),
    undefined,
    undefined,
    true,
  );

  const perimeterConfig = {
    isAdminOrGroupPerimeter,
    filters: filters,
    initialOptions,
    perimeterOptions,
    onChangeFilters,
    onChangePerimeterOptions,
    uniqueMerchant: true,
    uniqueCompany: true,
  };

  const {
    onChangeAccountsSelection,
    onChangeCompaniesSelection,
    onChangeMerchantsSelection,
  } = useOnChangeFilter(filters, onChangeFilters);

  const {
    onChangeAccountName,
    onChangeCompanyName,
    onChangeMerchantName,
    handleAccountsSelection,
    handleCompaniesSelection,
  } = useAutocompleteSearchFilter(
    perimeterOptions,
    onChangePerimeterOptions,
    filters,
    perimeterConfig.uniqueCompany,
    perimeterConfig.uniqueMerchant,
  );

  const autoCompleteCheckboxConfig: AutocompleteCheckboxConfig = {
    onChangeAccountsSelection,
    onChangeCompaniesSelection,
    onChangeMerchantsSelection,
    onChangeAccountName,
    onChangeCompanyName,
    onChangeMerchantName,
    handleAccountsSelection,
    handleCompaniesSelection,
  };


  const onChangeMultichoiceFilter = (field: string) => (values: Array<Options>) => {
    onChangeFilters({ ...filters, [field]: values });
  }

  const multiChoiceConfig: MultichoiceConfig = {
    documentsFilters: filters,
    onChangeMultichoiceFilter,
  };

  const queryAndResetConfig: QueryAndResetConfig = {
    disabledWithPopup: !perimeterUtils.hasALLPerimeterFilters(filters),
    labelErrorOnDisable: "filter_search_button_toaster",
    startIcon: <Download />,
    buttonText: "doc_rafec_download",
    loading: showContent,
  };

  const onReset = () => {
    // On reset, recalculate initial options based on options/perimeters    
    onChangeFilters(filterUtils.getDocumentsInitialFilters({ accounts: !isAdminOrGroupPerimeter ? filters.accounts: [], companies: [], merchants: [] }));
    onResetFilter();
  };

  const onSearch = () => {
    onSearchFilter();
  };

  const sharedFilterProps = {
    onSearch,
    onReset,
    errors,
  };

  return (
    {
      filters,
      showContent,
      sharedFilterProps,
      onChangeFilters,
      perimeterConfig,
      autoCompleteCheckboxConfig,
      multiChoiceConfig,
      queryAndResetConfig,
    }
  )
}
