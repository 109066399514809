import {Check, Close, ErrorOutlineOutlined, Info, WarningRounded} from "@mui/icons-material";
import {paletteTheme} from "../../styles/themes/palette";
import React from "react";
import {ToastOptions} from "react-toastify/dist/types";

const setStyle = (color: string, backgroundColor: string, borderColor: string) => {
    return {
        width: "320px",
        minHeight: "112px",
        color: color,
        backgroundColor: backgroundColor,
        border: "2px solid",
        borderRadius: "2px",
        borderColor: borderColor,
        fontSize: "14px",
        padding: 0
    }
}

const setIconStyle = (color: string, strokeWidth: number) => {
    return {
        color: color,
        stroke: color,
        strokeWidth: strokeWidth,
        fontSize: "38px",
    }
}

const setProgressStyle = (color: string) => {
    return {
        backgroundColor: color,
        height: "2px",
        marginBottom: "10px",
        left: "20px",
        width: "276px"
    }
}

const closeButton = <Close sx={{
    color: paletteTheme.colors.GRAY,
    stroke: paletteTheme.colors.PRIMARY,
    strokeWidth: 0,
    fontSize: "28px",
    position: "absolute",
    right: "20px",
    top: "14px"
}}/>

const bodyStyle = {
   
    maxHeight: "120px",
    padding: "20px 49px 0px 69px",
    margin: 0,
    marginBottom: "20px"
}

const successOptions: ToastOptions = {
    icon: <Check sx={setIconStyle(paletteTheme.colors.PRIMARY, 2)}/>,
    closeButton: closeButton,
    style: setStyle(paletteTheme.colors.DEFAULT_TEXT_COLOR, paletteTheme.colors.GREEN_WHITE, paletteTheme.colors.PRIMARY),
    bodyStyle: bodyStyle,
    progressStyle: setProgressStyle(paletteTheme.colors.PRIMARY)
}

const errorOptions: ToastOptions = {
    icon: <ErrorOutlineOutlined sx={setIconStyle(paletteTheme.colors.DANGER, 0)}/>,
    closeButton: closeButton,
    style: setStyle(paletteTheme.colors.DANGER, paletteTheme.colors.TOAST_ERROR, paletteTheme.colors.DANGER),
    bodyStyle: bodyStyle,
    progressStyle: setProgressStyle(paletteTheme.colors.DANGER)
}

const infoOptions: ToastOptions = {
    icon: <Info sx={setIconStyle(paletteTheme.colors.TOAST_INFO, 0)}/>,
    closeButton: closeButton,
    style: setStyle(paletteTheme.colors.DEFAULT_TEXT_COLOR, paletteTheme.colors.TOAST_INFO_LIGHT, paletteTheme.colors.TOAST_INFO),
    bodyStyle: bodyStyle,
    progressStyle: setProgressStyle(paletteTheme.colors.TOAST_INFO)
}

const warningOptions: ToastOptions = {
    icon: <WarningRounded sx={setIconStyle(paletteTheme.colors.TOAST_WARNING, 0)}/>,
    closeButton: closeButton,
    style: setStyle(paletteTheme.colors.DEFAULT_TEXT_COLOR, paletteTheme.colors.TOAST_WARNING_LIGHT, paletteTheme.colors.TOAST_WARNING),
    bodyStyle: bodyStyle,
    progressStyle: setProgressStyle(paletteTheme.colors.TOAST_WARNING)
}

export const toastStyleUtils = {
    successOptions,
    errorOptions,
    infoOptions,
    warningOptions
}
