import {
  Entity,
  Perimeter,
  PerimeterResponse,
  PerimeterUpdate,
} from "interfaces/perimeter/perimeter";
import { arrayUtils } from "utils/common/arrayUtils";
import { randomUtils } from "utils/common/randomUtils";
import { ValidateErrorMap } from "components/molecules/useraccount/edit/UserAccountEdit";
import { CompanyUpdate } from "interfaces/perimeter/company";
import { FilterValues } from "interfaces/filter/filter";

function mapToEntity(entity: Entity) {
  return {
    id: entity.id,
    name: entity.name.toUpperCase(),
    idSpring: entity.idSpring,
    status: entity.status
  }
}

function responseToPerimeter(response: PerimeterResponse): Perimeter {
  return {
    account: mapToEntity(response),
    hasAccountPerimeter: response.hasAccountPerimeter,
    companies: response.companies.map(company => ({
      company: mapToEntity(company),
      hasCompanyPerimeter: company.hasCompanyPerimeter,
      merchants: company.merchants.map(merchant => ({ ...merchant, ...mapToEntity(merchant) }))
    }))
  }
}

function responseToUppercaseResponse(response: PerimeterResponse): PerimeterResponse {
  return {
    ...response,
    ...mapToEntity(response),
    companies: response.companies.map(company => ({
      ...company,
      ...mapToEntity(company),
      merchants: company.merchants.map(merchant => ({ ...merchant, ...mapToEntity(merchant) }))
    }))
  }
}


function hasNoPerimeter(perimeter: Perimeter) {
  return perimeter && perimeter.account.id === "";
}

function buildEmptyPerimeter(): PerimeterUpdate {
  return {
    account: { name: "", hasRightOn: false, idSpring: "", id: "" },
    companies: []
  };
}

function buildEmptyCompanyPerimeter(): CompanyUpdate {
  return {
    company: {
      id: "",
      name: "",
      idSpring: "",
      uniqueKey: randomUtils.randomSelector(10)
    },
    merchants: []
  }
}

function buildPerimeterUpdate(perimeter: Perimeter): PerimeterUpdate {
  if (!perimeter) {
    return buildEmptyPerimeter()
  }

  return {
    ...perimeter,
    companies: perimeter.companies.map(_company => ({
      ..._company,
      company: { ..._company.company, uniqueKey: randomUtils.randomSelector(10) }
    }))
  }
}

function errrorsToPerimeterErrors(uniqueKey: string, perimetersErrors: ValidateErrorMap): Record<string, boolean> {
  let formattedPerimeterErrors: Record<string, boolean> = {};
  const perimetersError: boolean | Record<string, boolean> = perimetersErrors[uniqueKey];
  if (typeof perimetersError !== "boolean") {
    formattedPerimeterErrors = perimetersError;
  }
  return formattedPerimeterErrors;
}

function isEmptyCompany(company: CompanyUpdate) {
  return company.company.id === "";
}

function buildPerimeterToRequestPerimeter(perimeter: PerimeterUpdate): Array<PerimeterItemUpdate> {

  if (perimeter.hasAccountPerimeter) {
    return [{
      perimeterLevel: "ACCOUNT",
      accountIds: [perimeter.account.idSpring]
    }]
  }

  const companyPerimeter = perimeter.companies.filter(company => company.hasCompanyPerimeter);
  const merchantPerimeter = perimeter.companies.filter(company => !company.hasCompanyPerimeter);

  const perimeters: Array<PerimeterItemUpdate> = [];

  if (!arrayUtils.isEmpty(companyPerimeter)) {
    perimeters.push({
      perimeterLevel: "COMPANY",
      companyIds: companyPerimeter.map(item => item.company.idSpring)
    })
  }

  if (!arrayUtils.isEmpty(merchantPerimeter)) {
    perimeters.push({
      perimeterLevel: "MERCHANT",
      storeIds: merchantPerimeter.map(item => item.merchants.map(merchant => merchant.idSpring)).flatMap(x => x)
    })
  }

  return perimeters;

}

function canAddPerimeter(numberOfRemainingCompanies: number, numberOfCompanies: number, allUniqueCompanyIds: Array<string>): boolean {
  return numberOfRemainingCompanies > 0 &&
    numberOfCompanies < arrayUtils.arrayOfUniqueAny<string>(allUniqueCompanyIds).length
}

export interface PerimeterItemUpdate {
  perimeterLevel: string,
  accountIds?: Array<string>,
  companyIds?: Array<string>,
  storeIds?: Array<string>,
}

const hasPerimeterFilters = (filters: FilterValues): boolean => {
  return [filters.accounts, filters.companies.selectedOptions, filters.merchants.selectedOptions].some(array => !arrayUtils.isEmpty(array))
}

const hasALLPerimeterFilters = (filters: FilterValues): boolean => {
  const account = [filters.accounts].some(array => !arrayUtils.isEmpty(array));
  const company = [filters.companies.selectedOptions].some(array => !arrayUtils.isEmpty(array));
  const merchant = [filters.merchants.selectedOptions].some(array => !arrayUtils.isEmpty(array));
  return account && company && merchant;
}

export const perimeterUtils = {
  buildEmptyPerimeter,
  responseToPerimeter,
  responseToUppercaseResponse,
  isEmptyCompany,
  buildPerimeterToRequestPerimeter,
  buildPerimeterUpdate,
  hasNoPerimeter,
  errrorsToPerimeterErrors,
  buildEmptyCompanyPerimeter,
  canAddPerimeter,
  hasPerimeterFilters,
  hasALLPerimeterFilters,
}
