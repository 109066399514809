import { User } from "interfaces/user/user";
import { Perimeter } from "interfaces/perimeter/perimeter";

function isNewUser(user: User) {
  return user && user.id === "";
}

function isSelfFromParam(idUser: string) {
  return idUser === null || idUser === undefined;
}

const filterPaywishMerchants = (perimeter: Perimeter) => {
  if (perimeter.account.id && perimeter.companies) {
    return perimeter.companies
      .flatMap(item => item.merchants)
      .filter(merch => merch.djmId)
      .map((item)=> {return {id:item.djmId, name: item.name}})
  }
}

export const createUserUtils = {
  isNewUser,
  isSelfFromParam,
  filterPaywishMerchants,
}
