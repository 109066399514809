import { TableCell } from "components/atoms/table/cells/TableCell";
import { IntlShape } from "react-intl";
import { Column } from "react-table";
import { WrappedCell } from "components/atoms/table/cells/WrappedCell";
import { ManagableColumn } from "interfaces/table/table";
import { SETTLEMENT_POSSIBLE_COLUMN } from "constants/settlement/settlementColumns";
import { USER_POSSIBLE_COLUMNS } from "constants/user/userColumns";
import { REPORT_POSSIBLE_COLUMN } from "constants/report/reportColumns";
import { TRANSACTION_DETAIL_POSSIBLE_COLUMNS } from "constants/transaction/transactionDetail/transactionDetailColumns";
import { BILLING_COLUMNS, PORTFOLIO_COLUMNS, TRENDS_PORTFOLIO_COLUMNS } from "constants/portfolio/portfolio";
import {
  ACQUIERED_ADMIN_DISTRIBUTION_COLUMNS,
  ACQUIERED_DISTRIBUTION_COLUMNS,
  ACQUIERED_TRANSACTION_MANAGEABLE_COLUMNS,
  ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS,
} from "constants/transaction/acquiredTransactionColumns";
import { TRANSACTION_POSSIBLE_COLUMNS } from "constants/transaction/transactionColumns";

export const buildTableCell = (
  column: ManagableColumn,
  intl: IntlShape,
  defaultStyles: IDefaultStyles,
  clickCellHandler?: (id: string) => void
): Column & { tooltip?: string } => {
  const { header, cell, accessor, disableSortBy, options, tooltip, tooltipCell, isTooltipCell } = column;
  const component = cell.component ? cell.component : TableCell;
  const stylesCell = options?.styles ? { ...options?.styles } : {};
  return {
    Header: header ? intl.formatMessage({ id: header }) : "",
    accessor: accessor,
    disableSortBy: !!disableSortBy,
    minWidth: options?.minWidth ?? defaultStyles.minWidth,
    maxWidth: options?.maxWidth ?? defaultStyles.maxWidth,
    width: options?.width ?? defaultStyles.width,
    tooltip: tooltip ? intl.formatMessage({ id: tooltip }) : "",
    Cell: WrappedCell(component, {
      type: cell.type,
      defaultValue: cell.defaultValue,
      styles: stylesCell,
      cellClickHandler: clickCellHandler,
      isTooltipCell: isTooltipCell,
      tooltipCell: tooltipCell,
    }),
  };
};

interface IClickCellHandlers {
  [id: string]: (id: string) => void;
}
interface IDefaultStyles {
  width: number;
  minWidth: number;
  maxWidth: number;
}

export const computeColumns = (
  columns: ManagableColumn[],
  intl: IntlShape,
  defaultStyles: IDefaultStyles,
  clickCellHandlers?: IClickCellHandlers
): Column[] => {
  let formattedColumns = [...columns];
  formattedColumns = formattedColumns.sort((a, b) => (a.order > b.order ? 1 : -1));
  return formattedColumns.map((column) => buildTableCell(column, intl, defaultStyles, clickCellHandlers?.[column.id]));
};

export const getManagableColumnsSorted = (columns: ManagableColumn[], intl: IntlShape): ManagableColumn[] => {
  const formattedColumns = [...columns];
  return formattedColumns.sort((a, b) =>
    intl.formatMessage({ id: a.header }) > intl.formatMessage({ id: b.header }) ? 1 : -1
  );
};

export type ScreenSize = "xs" | "sm" | "md" | "lg";

export const getTransactionColumns = (size: ScreenSize = "lg"): ManagableColumn[] => {
  const baseColumns = [
    TRANSACTION_POSSIBLE_COLUMNS.OPERATION_DATE,
    TRANSACTION_POSSIBLE_COLUMNS.GROSS_AMOUNT,
    TRANSACTION_POSSIBLE_COLUMNS.PAYMENT_METHOD,
  ];
  if (size === "xs") {
    return baseColumns;
  }
  const smColumns = [
    TRANSACTION_POSSIBLE_COLUMNS.TRANSACTIONAL_EVENT,
    TRANSACTION_POSSIBLE_COLUMNS.EVENT_STATUS,
    ...baseColumns,
    TRANSACTION_POSSIBLE_COLUMNS.FINANCING_STATUS,
  ];
  if (size === "sm") {
    return smColumns;
  }
  const mdColumns = [TRANSACTION_POSSIBLE_COLUMNS.TRANSACTIONAL_ROOT_EVENT_ID, ...smColumns];
  if (size === "md") {
    return mdColumns;
  }
  return mdColumns.filter((column) => !column.disableColumn);
};

export const getTransactionDetailColumns = (size: ScreenSize = "lg"): ManagableColumn[] => {
  const baseColumns = [
    TRANSACTION_DETAIL_POSSIBLE_COLUMNS.TIMESTAMP,
    TRANSACTION_DETAIL_POSSIBLE_COLUMNS.EVENT,
    TRANSACTION_DETAIL_POSSIBLE_COLUMNS.STATUS,
    TRANSACTION_DETAIL_POSSIBLE_COLUMNS.AMOUNT,
  ];
  // timestamp column width is adjusted here to be wider on the XS screens
  baseColumns.find((col) => col.id === "TIMESTAMP").options = { width: 85 };

  if (size === "xs") {
    return baseColumns;
  } else {
    const smColumns = [TRANSACTION_DETAIL_POSSIBLE_COLUMNS.UUID, ...baseColumns];
    // timestamp and UUID column widths are adjusted here to be wider on the SM screens
    smColumns.find((col) => col.id === "TIMESTAMP").options = { width: 150 };
    smColumns.find((col) => col.id === "UUID").options = { width: 100 };
    return smColumns;
  }
};

export const getSettlementsColumns = (size: ScreenSize = "lg"): ManagableColumn[] => {
  const baseColumns = [
    SETTLEMENT_POSSIBLE_COLUMN.SETTLEMENT_DATE,
    SETTLEMENT_POSSIBLE_COLUMN.PAYMENT_SOLUTION,
    SETTLEMENT_POSSIBLE_COLUMN.SETTLED_AMOUNT,
    SETTLEMENT_POSSIBLE_COLUMN.VIEW_TRANSACTION,
  ];
  if (size === "xs") {
    return baseColumns;
  }
  const smColumns = [
    SETTLEMENT_POSSIBLE_COLUMN.FEES,
    SETTLEMENT_POSSIBLE_COLUMN.GROSS_AMOUNT_SETTLEMENTS,
    ...baseColumns,
  ];
  if (size === "sm") {
    return smColumns;
  }
  const mdColumns = [SETTLEMENT_POSSIBLE_COLUMN.BATCH_ID, ...smColumns];
  if (size === "md") {
    return mdColumns;
  }

  return [SETTLEMENT_POSSIBLE_COLUMN.STORE_NAME, ...mdColumns];
};

export const getReportsColumns = (size: ScreenSize = "lg"): ManagableColumn[] => {
  const baseColumns = [
    REPORT_POSSIBLE_COLUMN.NAME,
    REPORT_POSSIBLE_COLUMN.LATEST_EDITION,
    REPORT_POSSIBLE_COLUMN.LATEST_REPORT,
    REPORT_POSSIBLE_COLUMN.MORE_ACTIONS,
  ];
  if (size === "xs") {
    return baseColumns;
  } else {
    const smColumns = [REPORT_POSSIBLE_COLUMN.FREQUENCY, ...baseColumns];
    return smColumns;
  }
};

export interface IUserColumnsParametrs {
  size?: ScreenSize;
  isOperator?: boolean;
  isAdmin?: boolean;
}

export const getUserColumns: (options?: IUserColumnsParametrs) => ManagableColumn[] = ({
  size = "lg",
  isOperator = false,
  isAdmin = false,
}): ManagableColumn[] => {
  const baseColumns = [USER_POSSIBLE_COLUMNS.FIRST_NAME, USER_POSSIBLE_COLUMNS.LAST_NAME];
  if (isAdmin) {
    baseColumns.push(USER_POSSIBLE_COLUMNS.WELCOME_EMAIL);
  }
  if (size === "xs") {
    return baseColumns;
  }
  const smColumns = [USER_POSSIBLE_COLUMNS.ACCOUNT_EMAIL, ...baseColumns];
  if (isOperator) {
    smColumns.push(USER_POSSIBLE_COLUMNS.ACCOUNT_TYPE);
  }
  if (size === "sm") {
    return smColumns;
  }
  const mdColumns = [USER_POSSIBLE_COLUMNS.ACCOUNT_NAME, ...smColumns];

  if (size === "md") {
    return mdColumns;
  }
  return [
    USER_POSSIBLE_COLUMNS.CREATION_DATE,
    USER_POSSIBLE_COLUMNS.PERIMETER_LEVEL,
    USER_POSSIBLE_COLUMNS.LAST_ACTIVITY,
    ...mdColumns,
  ];
};

export const getTrendsColumns = (size: ScreenSize, amountMode: boolean): ManagableColumn[] => {
  const baseColumns = [
    TRENDS_PORTFOLIO_COLUMNS.ID,
    TRENDS_PORTFOLIO_COLUMNS.MERCHANT_NAME,
    amountMode ? TRENDS_PORTFOLIO_COLUMNS.DAY_PERCENTAGE_AMOUNT : TRENDS_PORTFOLIO_COLUMNS.DAY_PERCENTAGE_VOLUME,
    amountMode ? TRENDS_PORTFOLIO_COLUMNS.WEEK_PERCENTAGE_AMOUNT : TRENDS_PORTFOLIO_COLUMNS.WEEK_PERCENTAGE_VOLUME,
  ];
  if (size === "xs") {
    return baseColumns;
  }
  const smColumns = [
    ...baseColumns,
    amountMode ? TRENDS_PORTFOLIO_COLUMNS.MONTH_PERCENTAGE_AMOUNT : TRENDS_PORTFOLIO_COLUMNS.MONTH_PERCENTAGE_VOLUME,
  ];
  return smColumns;
};

export const getTopBottomColumns = (size: ScreenSize, isCompany: boolean): ManagableColumn[] => {
  const baseColumns = [
    PORTFOLIO_COLUMNS.ID,
    isCompany ? PORTFOLIO_COLUMNS.COMPANY_NAME : PORTFOLIO_COLUMNS.MERCHANT_NAME,
    PORTFOLIO_COLUMNS.NUMBER_TRANSACTION,
    PORTFOLIO_COLUMNS.GROSS_AMOUNT,
  ];
  if (size === "xs") {
    return baseColumns;
  }
  const smColumns = [...baseColumns, PORTFOLIO_COLUMNS.TOTAL_COMMISSION];

  if (size === "sm") {
    return smColumns;
  }
  const mdColumns = [...smColumns, PORTFOLIO_COLUMNS.SUCCESS_RATE, PORTFOLIO_COLUMNS.AVG_COMMISSION_RATE];
  return mdColumns;
};

export const getAcquiredTransactionsColumns = (size: ScreenSize = "lg", isDistributor: boolean, isAdmin: boolean): ManagableColumn[] => {
  const baseColumns = [
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.BATCH_ID,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.SETTLEMENT_DATE,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.NET_AMOUNT,
  ];
  if (size === "xs") {
    return baseColumns;
  }
  const smColumns = [
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.OPERATION_DATE,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.BATCH_ID,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.SETTLEMENT_DATE,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.FEES,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.NET_AMOUNT,
  ];
  if (size === "sm") {
    return smColumns;
  }
  const mdColumns = [
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.OPERATION_DATE,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.GROSS_AMOUNT,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.CARD_BRAND,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.BATCH_ID,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.SETTLEMENT_DATE,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.TLC_NUMBER,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.FEES,
    ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.NET_AMOUNT,
  ];

  if (isDistributor || isAdmin) {
    mdColumns.push(ACQUIRED_TRANSACTION_POSSIBLE_COLUMNS.DISTRIB_AMOUNT);
  }
  return [...mdColumns];
};

export const getManagableColumns = (isDistributor: boolean, isAdmin: boolean) => {

  if (isAdmin) {
    return [...ACQUIERED_TRANSACTION_MANAGEABLE_COLUMNS, { label: "category_distribution", columns: ACQUIERED_ADMIN_DISTRIBUTION_COLUMNS }];
  }

  if (isDistributor) {
    return [...ACQUIERED_TRANSACTION_MANAGEABLE_COLUMNS, { label: "category_distribution", columns: ACQUIERED_DISTRIBUTION_COLUMNS }];
  }
  return ACQUIERED_TRANSACTION_MANAGEABLE_COLUMNS;
};

export const getBillingColumns = (isDistributor?: boolean, isItalianAccount?: boolean): ManagableColumn[] => {
  const baseColumns = [
    BILLING_COLUMNS.COMPANY,
    BILLING_COLUMNS.SETTLEMENT_MONTH,
    BILLING_COLUMNS.TRANSACTION_NUMBER,
    BILLING_COLUMNS.GROSS_AMOUNT,
    BILLING_COLUMNS.MERCHANT_FEES,
    BILLING_COLUMNS.NET_AMOUNT,
    BILLING_COLUMNS.INTERCHANGE_FEES,
    BILLING_COLUMNS.SCHEME_FEES,
    
    // MPT-1352 - hide columns 

    // BILLING_COLUMNS.PROCESSING_FEES_AMT_TTC,
    // BILLING_COLUMNS.PROCESSING_FEES_AMT_TVA,
    // BILLING_COLUMNS.PROCESSING_FEES_AMT_HT,
    // BILLING_COLUMNS.TRANSACTION_TYPE,
    // BILLING_COLUMNS.UUID,
    // BILLING_COLUMNS.COUNTRY,
  ];
  if (isItalianAccount) baseColumns.push(BILLING_COLUMNS.IMPOSTA_DI_BOLLO);
  return !isDistributor ? baseColumns : [...baseColumns, BILLING_COLUMNS.DISTRIB_AMT, BILLING_COLUMNS.SHARE_MARGIN_RATE, BILLING_COLUMNS.SHARE_MARGIN_AMOUNT];
};
