import React, {FunctionComponent} from "react";
import {TextField as TextFieldMaterialUI, TextFieldProps} from "@mui/material";
import {authUtils} from "../../../utils/api/authUtils";


const TextField: FunctionComponent<TextFieldProps> = ({
                                                                  onChange,
                                                                  value,
                                                                  ...rest
                                                              }) => {


    const OnChangeWithTracker = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        authUtils.refreshTokenExpirationDate();
        onChange(event)
    }

    return <TextFieldMaterialUI {...rest} value={value} onChange={OnChangeWithTracker}/>;
}


export default TextField;
