import React, {FunctionComponent} from "react";
import {SwitchProps as SwitchPropsUI, Typography} from "@mui/material";
import {authUtils} from "../../../utils/api/authUtils";
import {FormattedMessage} from "react-intl";
import FlexBox from "../flex/FlexBox";
import {FlexAttributValue} from "../../../types/flex/flex";
import SwitchToggleStyled from "../../../styles/components/switch/SwitchToggleStyled";
import {paletteTheme} from "../../../styles/themes/palette";

interface SwitchProps extends SwitchPropsUI {
    label?: [string, string],
    cursor?: string,
}

const SwitchToggle: FunctionComponent<SwitchProps> = ({
                                                          checked = false,
                                                          onChange,
                                                          id,
                                                          label,
                                                          disabled,
                                                          ...rest
                                                      }) => {

    const onChangeWithTracker = (event: React.ChangeEvent<HTMLInputElement>, check: boolean) => {
        authUtils.refreshTokenExpirationDate();
        if (onChange) {
            onChange(event, check);
        }
    };

    const disabledStyle = {color: paletteTheme.colors.GRAY};
    const enabledStyle = {fontWeight: "bold"};

    const [labelOn, labelOff] = label;
    return (<FlexBox flexDirection={FlexAttributValue.ROW} alignItems={FlexAttributValue.CENTER}>
        <Typography
            color={!checked ? "primary" : ""}
            sx={checked ? disabledStyle : enabledStyle}
            marginRight="4px"><FormattedMessage
            id={labelOn}/></Typography>
        <SwitchToggleStyled {...rest} checked={checked} disabled={disabled} id={id}
                            onChange={onChangeWithTracker}/>
        <Typography
            color={checked ? "primary" : ""}
            sx={!checked ? disabledStyle : enabledStyle}
            marginLeft="4px"><FormattedMessage
            id={labelOff}/></Typography>
    </FlexBox>)
}


export default SwitchToggle;
