import React, {FunctionComponent} from "react";
import {
    Radio,
    RadioGroup as RadioGroupMUI,
    RadioGroupProps as RadioGroupMUIProps
} from "@mui/material";
import FormControlLabelRadioStyled from "../../../styles/components/label/FormControlLabelRadioStyled";
import {useIntl} from "react-intl";
import {Options} from "../../../constants/options/option";

interface RadioGroupProps extends RadioGroupMUIProps {
    options: Options[]
}

const RadioGroup: FunctionComponent<RadioGroupProps> = ({
                                                            options,
                                                            value,
                                                            sx,
                                                            ...rest
                                                        }) => {
    const intl = useIntl();

    return (
        <RadioGroupMUI
            value={value}
            sx={{...sx, justifyContent: "center"}}
            {...rest}
        >
            {options.map((option) => (
                <FormControlLabelRadioStyled
                    key={option.id}
                    value={option.id}
                    control={<Radio/>}
                    label={intl.formatMessage({id: option.name})}
                    checked={value === option.id}
                />
            ))}
        </RadioGroupMUI>
    );
}

export default RadioGroup;
