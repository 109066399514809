import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { RouteProps, useLocation, useNavigate, useParams } from "react-router-dom";
import { TransactionKeyDetailCard } from "components/molecules/transaction/TransactionDetailCard";
import { ACCEPTATION, CUSTOMER, SETTLEMENT, HISTORY, MERCHANT, TRANSACTION } from "constants/transaction/transaction";
import { DetailInformation, History, TransactionRow } from "interfaces/transaction/transaction";
import { HOME_PATH, ACCEPTED_TRANSACTIONS_PATH } from "constants/routes/RoutePaths";
import { dateUtils } from "utils/common/dateUtils";
import { TransactionDetailHistory } from "components/molecules/transaction/TransactionDetailHistory";
import { BackLink } from "components/molecules/BackLink";

interface TransactionDetailState {
    transactionDetails?: TransactionRow
}

const TransactionDetailView: FunctionComponent<RouteProps> = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const transactionDetails: TransactionRow = (location.state as TransactionDetailState)?.transactionDetails;
    const [history, setHistory] = useState<History[]>();
    const { idTransaction } = useParams();
    const intl = useIntl();
    const paidTransaction = "Paid";

    const acceptation = transactionDetails?.acceptationInfo[0];
    const acceptationInterfaceInfo = {
        checkoutType: acceptation.checkoutType,
        paymentMethod: acceptation.paymentMethod,
        paymentSolution: acceptation.paymentSolution,
        paymentType: acceptation.paymentType,
        authorisationCode: acceptation.authorisationCode,
        posId: acceptation.posID,
        posName: acceptation.posName,
    }
    const customerInterfaceInfo = {
        bankAccountDomiciliationCountry: acceptation.bankAccountDomiciliationCountry,
        cardIssuingBank: acceptation.cardIssuingBank,
        cardIssuingCountry: acceptation.cardIssuingCountry,
        cardLevel: acceptation.cardLevel,
        cardType: acceptation.cardType,
        panfirstSixDigits: acceptation.panfirstSixDigits,
        panlastFourDigits: acceptation.panlastFourDigits,
        scheme: acceptation.scheme,
    }

    const transaction: DetailInformation = {
        ...transactionDetails?.transactionInfo[0],
        operationDatetime: intl.formatTime(transactionDetails?.transactionInfo[0].operationDatetime),
        operationDate: intl.formatDate(transactionDetails?.transactionInfo[0].operationDate),
        transactionId: idTransaction
    }

    const settlement: DetailInformation = {
        ...transactionDetails?.financingInfo[0],
        financingDate: transactionDetails?.financingInfo[0].financingDate ? intl.formatDate(transactionDetails?.financingInfo[0].financingDate) : "-",
    }

    useEffect(() => {
        if (!transactionDetails) {
            navigate(HOME_PATH)
        } else {
            let historyToSort = [...transactionDetails.historyInfo];
            historyToSort = historyToSort.sort((historyInfoA, historyInfoB) => {
                return dateUtils.getTimeFromTimestampString(historyInfoA.eventTimestamp) - dateUtils.getTimeFromTimestampString(historyInfoB.eventTimestamp);
            })

            // For paid transactions, we manually add a line at the end of the history table
            if (transactionDetails.financingInfo[0].financingStatus === paidTransaction) {
                historyToSort.push({
                    transactionalEventId: transactionDetails.historyInfo[0].transactionalEventId,
                    eventTimestamp: transactionDetails.financingInfo[0].financingDate,
                    transactionalEvent: intl.formatMessage({ id: "trans_settled" }),
                    eventStatus: intl.formatMessage({ id: "event_success" }),
                    grossAmount: transactionDetails.financingInfo[0].netAmount
                })
            }
            // sort history by asc order
            setHistory(historyToSort)
        }
    }, [transactionDetails])

    return (
        <>
            <TransactionDetailHistory
                history={history}
                transactionId={idTransaction}
            />
            <TransactionKeyDetailCard
                title="tp_titles_acceptation"
                detailInformation={acceptationInterfaceInfo}
                detailInformationElement={ACCEPTATION}
            />
            <TransactionKeyDetailCard
                title="tp_titles_customer"
                detailInformation={customerInterfaceInfo}
                detailInformationElement={CUSTOMER}
            />
            <TransactionKeyDetailCard
                title="tp_titles_transaction"
                detailInformation={transaction}
                detailInformationElement={TRANSACTION}
            />
            <TransactionKeyDetailCard
                title="tp_titles_history"
                detailInformation={history?.reverse()?.[0] as DetailInformation}
                detailInformationElement={HISTORY}
            />
            <TransactionKeyDetailCard
                title="tp_titles_settlement"
                detailInformation={settlement}
                detailInformationElement={SETTLEMENT}
            />
            <TransactionKeyDetailCard
                title="tp_titles_merchant"
                detailInformation={transactionDetails?.merchantInfo[0] as DetailInformation}
                detailInformationElement={MERCHANT}
            />
            <BackLink to={`/${ACCEPTED_TRANSACTIONS_PATH}`} label="back_transactions_list" />
        </>
    )
}
export default TransactionDetailView;
