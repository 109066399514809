import React, { ChangeEvent, FunctionComponent, useRef, useState } from "react";
import { validationErrorsUtils } from "utils/business/validationErrorsUtils";
import { MIN_MAX_AMOUNT_PATTERN } from "utils/common/patternUtils";
import { ErrorReferentialLine } from "interfaces/error/error";
import { filterTooltipUtils } from "utils/filterTooltipUtils";
import FilterTooltip from "components/molecules/tooltip/FilterTooltip";
import { AmountInput } from "components/molecules/input/AmountInput/index.";
import ErrorMessage from "components/atoms/ErrorMessage";
import FormGroup from "@mui/material/FormGroup";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import { amountsStyles } from "styles/constants/textfield/AmountRangeFilterStyles";
import styles from "components/molecules/input/AmountRangeInput/amountRangeInput.module.scss";
import { stringUtils } from "utils/common/stringUtils";

export interface AmountRangeInputProps extends BaseTextFieldProps {
    name: string;
    label: string;
    min?: string;
    max?: string;
    onChangeMin: (value: string) => void;
    onChangeMax: (value: string) => void;
    possibleErrors?: ErrorReferentialLine[];
    errors: Record<string, boolean>;
    setErrors: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const AmountRangeInput: FunctionComponent<AmountRangeInputProps> = ({
    name,
    label,
    className = "no-move",
    min,
    onChangeMin,
    max,
    onChangeMax,
    possibleErrors,
    errors,
    setErrors,
}) => {

    const [focused, setFocused] = useState<boolean>(false);
    const amountErrors = errors.MIN_SUP_MAX || errors.NEGATIVE_VALUE;
    const validAmounts = min && max && !amountErrors;

    const divRef = useRef<HTMLDivElement>();
    const isOverflow = filterTooltipUtils.isOverflow(divRef.current?.firstElementChild.firstElementChild);

    const onlyNumbersOnChange = (event: ChangeEvent<HTMLTextAreaElement>, onChange: (value: string) => void) => {
        const { value } = event.target;
        if (stringUtils.isEmpty(value)) {
            onChange(value)
        } else {
            MIN_MAX_AMOUNT_PATTERN.test(value) && onChange(value)
        }
    }

    const validationOnBlur = () => {
        setErrors(validationErrorsUtils.validateAmountMinMax(parseInt(min), parseInt(max)))
    }

    const handleOnBlur = () => {
        validationOnBlur();
        setFocused(false);
    }

    return (
        <div className={styles.container}>
            <FilterTooltip overflowText={label} placement="top" openTooltip={isOverflow}>
                <FormGroup row sx={amountsStyles(validAmounts, focused)}>
                    <div ref={divRef}>
                        <AmountInput
                            value={min}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onlyNumbersOnChange(e, onChangeMin)}
                            placeholder="Min"
                            className={className}
                            name={`${name}_min`}
                            error={amountErrors}
                            label={label}
                            onFocus={() => setFocused(true)}
                            onBlur={handleOnBlur}
                        />
                    </div>
                        <AmountInput
                            value={max}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onlyNumbersOnChange(e, onChangeMax)}
                            placeholder="Max"
                            className={className}
                            name={`${name}_max`}
                            error={amountErrors}
                            onFocus={() => setFocused(true)}
                            onBlur={handleOnBlur}
                        />
                </FormGroup>
            </FilterTooltip>
            <div className={styles.errors}>
                {possibleErrors?.map(error =>
                    <ErrorMessage
                        key={error.id}
                        error={error.id}
                        errors={errors}
                        fontSize={10}
                        lineHeight={1}
                    />)}
            </div>
        </div>
    )
}
