import {UserDataResponse, UserDetailsResponse} from "../../interfaces/UserInterface";
import {User, UserDetails} from "../../interfaces/user/user";

function userResponseToUser(user: UserDataResponse): User {
    return {
        id: user.id,
        email: user.email,
        idGroup: user.idGroup,
        groupName: user.groupName,
        idSpring: user.idSpring,
        profile: user.profile,
        firstName: user.firstName,
        lastName: user.lastName,
        language: user.language,
        activated: user.activated,
        distributor: user.distributor,
        initialized: user.initialized,
        paywishRole: user.paywishRole,
    }
}

function userResponseToUserDetails(userDetailResponse: UserDetailsResponse): UserDetails {
    return {
        ...userResponseToUser(userDetailResponse),
        permissions: userDetailResponse.permissions
    }
}

export const userMapper = {
    userResponseToUser,
    userResponseToUserDetails
}
