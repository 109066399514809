import React, { FunctionComponent } from "react";
import { DetailInformation, DetailInformationElement } from "interfaces/transaction/transaction";
import Card from "components/atoms/card/Card";
import { DetailBlock } from "components/molecules/transaction/DetailBlock";

export interface TransactionKeyDetailBlocProps {
  title: string;
  detailInformation?: DetailInformation;
  detailInformationElement: DetailInformationElement[];
  headerMarginBottom?: string | number;
}

export const TransactionKeyDetailCard: FunctionComponent<TransactionKeyDetailBlocProps> = ({
  title,
  detailInformation,
  detailInformationElement,
  headerMarginBottom = "30px",
}) => {
  return (
    <Card header={title} headerMarginBottom={headerMarginBottom}>
      <DetailBlock detailInformation={detailInformation} detailInformationElement={detailInformationElement} />
    </Card>
  );
};
