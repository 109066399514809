import React, { FunctionComponent, useMemo } from "react";
import { useAuthUser } from "hooks/useAuthUser";
import { useIntl } from "react-intl";
import { isSameDay } from "date-fns";
import {
    DateRangePicker,
    Range,
    RangeKeyDict,
    StaticRange,
} from "react-date-range";
import { languageUtils } from "utils/common/languageUtils";
import { NavigatorMethod } from "interfaces/datepicker/datepicker";
import { SHORTCUT_RANGES } from "constants/datepicker/datepicker";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import styles from "components/molecules/datepicker/DateRangePickerCalendar/dateRangePickerCalendar.module.scss";

interface DateRangePickerProps {
    range: Range;
    onChange: ((rangesByKey: RangeKeyDict) => void);
    onReset: () => void;
    initialStartDate: string;
    initialEndDate: string;
    isMobile?: boolean;
}

export const DateRangePickerCalendar: FunctionComponent<DateRangePickerProps> = ({
    range,
    onChange,
    isMobile = false,
}) => {
    const { user } = useAuthUser();
    const intl = useIntl();

    const isRangeSelected = (shortcutRange: Range) => {
        return isSameDay(range.startDate, shortcutRange.startDate) && isSameDay(range.endDate, shortcutRange.endDate)
    }

    const shortcutRanges: StaticRange[] = useMemo(() => {
        return SHORTCUT_RANGES.map(shortcutRange => {
            return {
                label: intl.formatMessage({ id: shortcutRange.label }),
                range: () => (shortcutRange.range),
                isSelected: () => isRangeSelected(shortcutRange.range)
            }
        })
    }, [range]);

    const navigatorRenderer = (currFocusedDate: Date,
        changeShownDate: ((
            value: Date | number | string,
            mode?: NavigatorMethod,
        ) => void)) => {
        const sxArrow = { fontSize: 8, cursor: "pointer", "&:hover": { transform: "scale(1.30,1.25)" } };
        return (
            <div className="position-relative" style={{ zIndex: 1 }}>
                <div className="flex flex-row-between">
                    <ArrowBackIos sx={sxArrow} onClick={() => changeShownDate(-1, "monthOffset")} />
                    <ArrowForwardIos sx={sxArrow} onClick={() => changeShownDate(+1, "monthOffset")} />
                </div>
            </div>)
    }

    return <DateRangePicker
        staticRanges={shortcutRanges}
        color="none"
        locale={languageUtils.getNavigatorLocale(user)}
        navigatorRenderer={navigatorRenderer}
        months={isMobile ? 1 : 2}
        preventSnapRefocus
        calendarFocus="backwards"
        direction="horizontal"
        className={`${styles["range-datepicker"]} ${isMobile ? styles.mobile : ""}`}
        ranges={[range]}
        onChange={onChange}
    />
}
