import React, {
  FunctionComponent
} from "react";
import { CardRow } from "components/atoms/card/CardRow";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";

interface UserAccountPayWishUserProps {
  paywishRole: string;
}

export const UserAccountPayWishUser: FunctionComponent<UserAccountPayWishUserProps> = ({ paywishRole }) => {

  return (
    <CardRow id="paywish_usertype" label="paywish_usertype">
      <Typography>{paywishRole === "ADMIN" ? <FormattedMessage id="paywish_admin" /> : <FormattedMessage id="paywish_user" />}</Typography>
    </CardRow>
  );
}
