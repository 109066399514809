import React, { FunctionComponent, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Card from "components/atoms/card/Card";
import { useAuthUser } from "hooks/useAuthUser";
import { paletteTheme } from "styles/themes/palette";
import { PerimeterCount } from "interfaces/perimeter/perimeter";
import { KeyNumbers } from "interfaces/transaction/transaction";
import { useEffectAbortable } from "hooks/useEffectAbortable";
import { perimeterService } from "services/user/PerimeterService";
import { errorUtils } from "utils/api/errorUtils";
import { PortfolioKeyNumbersDisplay } from "components/molecules/portfolio/PortfolioKeyNumbersDisplay";
import { PortfolioTopBottomSection } from "components/molecules/portfolio/PortfolioTopBottomSection";
import { PortfolioTrendsSection } from "components/molecules/portfolio/PortfolioTrendsSection";
import { PortfolioBillingSection } from "components/molecules/portfolio/PortfolioBillingSection";
import { rightUtils } from "utils/business/rightUtils";

export const PortfolioView: FunctionComponent = () => {
  const { user, userDetails } = useAuthUser();
  const [toggleCompanyMode, setToggleCompanyMode] = useState<boolean>(true);

  const handleToggleCompany = (value: boolean) => {
    setToggleCompanyMode(value);
  };

  const [nbEntities, setNbEntities] = useState<PerimeterCount>({
    nbMerchants: 0,
    nbCompanies: 0,
  });

  const [totalDistributorComission, setTotalDistributorComission] = useState(0);


  useEffectAbortable((ac) => {
    perimeterService
      .count({ signal: ac.signal })
      .then((response) => {
        setNbEntities(response);
      })
      .catch(errorUtils.handleBackErrors);
  }, []);


  // Calculate keyNumbers only when entities, aggregations or toggle change
  const keyNumbers: KeyNumbers = useMemo(
    () => {
      const includeTotalDistributorComission =
        rightUtils.isAdmin(user) || rightUtils.isDistributor(user);
  
      return {
        nbEntities: toggleCompanyMode ? nbEntities.nbCompanies : nbEntities.nbMerchants,
        ...(includeTotalDistributorComission && { totalDistributorComission }),
      };
    },
    [nbEntities, totalDistributorComission, toggleCompanyMode, user])

  return (
    <>
      <Card
        cardTitle={
          <>
            <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
              <FormattedMessage id="my_portfolio" />
            </Typography>
          </>
        }
      >
        <PortfolioKeyNumbersDisplay
          distributorMode={rightUtils.isAdmin(user) || rightUtils.isDistributor(user)}
          keyNumbers={keyNumbers}
          isCompany={toggleCompanyMode}
        />
        <div>
          <Typography variant="h1" color={paletteTheme.palette.primary["main"]} style={{ marginBottom: "40px" }}>
            <FormattedMessage id="portf_title_billing" />
          </Typography>

          <PortfolioBillingSection setDistributorComission={(val) => setTotalDistributorComission(val)}/>
        </div>
      </Card>

      <Card
        cardTitle={
          <>
            <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
              <FormattedMessage id="portf_title_top_bottom" />
            </Typography>
          </>
        }
      >
        <PortfolioTopBottomSection
          userDetails={userDetails}
          onChangeCompanyMode={handleToggleCompany}
        />
      </Card>
      <Card
        cardTitle={
          <Typography variant="h1" color={paletteTheme.palette.primary["main"]}>
            <FormattedMessage id="title_volume_trends" />
          </Typography>
        }
      >
        <PortfolioTrendsSection userDetails={userDetails} />
      </Card>
    </>
  );
};
