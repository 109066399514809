import React, { FunctionComponent } from "react";
import { User } from "interfaces/user/user";
import { PROFIL_USER } from "constants/user/user";
import { FormattedMessage } from "react-intl";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import Typography from "@mui/material/Typography";
import styles from "components/molecules/menu/SideMenu/SideMenuItem/UserMenuLabel/userMenuLabel.module.scss";

interface UserMenuLabelProps {
    user: User,
}

const UserMenuLabel: FunctionComponent<UserMenuLabelProps> = ({
    user
}) => {

    const { mdDown } = useMediaQueryUtil();
    const mWidth = mdDown ? 180 : 85;

    return (
        <div className="flex-col">
            <Typography className={`${styles.username} ellipsis`} maxWidth={mWidth}>
                <Typography component="span" className={styles["first-name"]}>
                    {`${user.firstName} `}
                </Typography>
                <Typography component="span" className={styles["last-name"]}>
                    {user.lastName}
                </Typography>
            </Typography>
            <Typography className={`ellipsis ${styles["profile"]}`} maxWidth={mWidth}>
                <FormattedMessage id={PROFIL_USER[user.profile].label} />
            </Typography>
        </div>
    )
}

export default UserMenuLabel;
