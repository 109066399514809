import { useCallback, useState } from "react";
import {authUtils} from "../../utils/api/authUtils";

interface UseDebouncedInput {
  query: string;
  onChange: (value: string) => void;
}

/**
 * Manage debounce for inputs
 * @param callback
 * @param initialValue
 */
export const useDebouncedInput = (callback: (value: string) => void, initialValue = ""): UseDebouncedInput => {
  const [query, setQuery] = useState<string>(initialValue);
  const [timeoutValue, setTimeoutValue] = useState<number>();

  const onChange = useCallback((value: string) => {
    setQuery(value);
    window.clearTimeout(timeoutValue);
    setTimeoutValue(window.setTimeout(() => {
      authUtils.refreshTokenExpirationDate();
      const trimmedValue = value.trim();
      callback(trimmedValue)
    }, 500));
  }, [callback])

  return {
    query,
    onChange
  }
}
