import React, { FunctionComponent } from "react";
import { FlexBoxProps } from "components/atoms/flex/FlexBox";
import { PerimeterOptions } from "constants/perimeter/perimeter";
import { User } from "interfaces/user/user";
import { FilterValues } from "interfaces/filter/filter";
import { MainFiltersLayout } from "components/layouts/MainFiltersLayout";
import PerimeterFilters from "components/molecules/filter/PerimeterFilters";
import { perimeterUtils } from "utils/perimeter/perimeterUtils";

export interface TransactionFiltersProps extends FlexBoxProps {
  onChangePerimeterOptions: (value: PerimeterOptions) => void;
  perimeterOptions: PerimeterOptions;
  initialOptions: PerimeterOptions;
  filters: FilterValues;
  onChangeFilters: (filters: FilterValues) => void;
  user: User;
  onSearch: () => void;
  onReset: () => void;
  errors?: Record<string, boolean>;
  disabledSearch?: boolean;
  marginTop?: string;
  hideTitle?: boolean;
  disabledMerchant?: boolean;
  uniqueCompany?: boolean;
  uniqueMerchant?: boolean;
}

export const WrappedPerimeterFilters: FunctionComponent<TransactionFiltersProps> = ({
  onChangePerimeterOptions,
  perimeterOptions,
  initialOptions,
  filters,
  onChangeFilters,
  user,
  errors = {},
  onSearch,
  onReset,
  disabledSearch = false,
  marginTop = "10px",
  children,
  hideTitle,
  disabledMerchant = false,
  uniqueCompany = false,
  uniqueMerchant = false,
  ...rest
}) => (
  <MainFiltersLayout
    {...rest}
    hideTitle={hideTitle}
    errors={errors}
    onSearch={onSearch}
    onReset={onReset}
    disabledWithPopup={disabledSearch || !perimeterUtils.hasPerimeterFilters(filters)}>
    <PerimeterFilters
      onChangePerimeterOptions={onChangePerimeterOptions}
      perimeterOptions={perimeterOptions}
      initialOptions={initialOptions}
      filters={filters}
      onChangeFilters={onChangeFilters}
      marginTop={marginTop}
      user={user}
      disabledMerchant={disabledMerchant}
      uniqueCompany={uniqueCompany}
      uniqueMerchant={uniqueMerchant}
      />
    {children}
  </MainFiltersLayout>
);
