import { useMediaQuery } from "@mui/material";
import { MP_THEME } from "styles/themes/theme";

export const useMediaQueryUtil = () => {

    const xsUp = useMediaQuery(MP_THEME.breakpoints.up("xs"));
    const smDown = useMediaQuery(MP_THEME.breakpoints.down("sm"));
    const smUp = useMediaQuery(MP_THEME.breakpoints.up("sm"));
    const mdDown = useMediaQuery(MP_THEME.breakpoints.down("md"));
    const mdUp = useMediaQuery(MP_THEME.breakpoints.up("md"));
    const lgDown = useMediaQuery(MP_THEME.breakpoints.down("lg"));
    const lgUp = useMediaQuery(MP_THEME.breakpoints.up("lg"));

    return {
        xsUp,
        smDown,
        smUp,
        mdDown,
        mdUp,
        lgDown,
        lgUp,
    }
}