import {
  BlobWithFilename,
  httpService,
} from "services/HttpService";
import {
  QueryParam,
  UserDetailsResponse,
} from "interfaces/UserInterface";
import {
  USER_DELETE_ROUTE,
  USER_EXPORT_ROUTE,
  USER_GET_USERS_ROUTE,
  USER_ROUTE,
  USER_SELF_DETAILS_ROUTE,
  USER_SELF_LANG_ROUTE,
  USER_SELF_ROUTE,
  USER_WITH_PERIMETER_ROUTE,
} from "constants/routes/ApiRoutes";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import {
  UpdateUser,
  UpdateUserInfo,
  UserWithPerimeter,
} from "interfaces/user/user";
import { SearchResponse } from "interfaces/api/ApiInterfaces";
import { queryUtils } from "utils/api/queryUtils";

/**
 * Get self User and merchant it in the localStorage
 * @param options
 */
function getUserSelf(options?: RequestInit): Promise<UserDetailsResponse> {
  return new Promise<UserDetailsResponse>((resolve, reject) => {
    const userInformations = localStorage.getItem(LocalStorageEnum.USER);
    if (userInformations) {
      const parse: UserDetailsResponse = JSON.parse(userInformations);
      resolve(parse);
    } else {
      httpService.get<UserDetailsResponse>(USER_SELF_DETAILS_ROUTE, options)
        .then((response) => {
          localStorage.setItem(LocalStorageEnum.USER, JSON.stringify(response))
          resolve(response);
        }).catch(reject);
    }
  })
}

/**
 * Update your preferred language
 * Based on the current user
 * @param language
 */
function updateSelfLanguage(language: string): Promise<VoidFunction> {
  return httpService.put(USER_SELF_LANG_ROUTE, { language })
}


function updateSelf({ firstname, lastname, email }: UpdateUserInfo, options?: RequestInit): Promise<UserDetailsResponse> {
  return httpService.put(USER_SELF_ROUTE, { firstname, lastname, email }, options)
}

const getUsers = (query: string) => ({
  page,
  pageSize,
  sortDirection,
  sortField
}: QueryParam): Promise<SearchResponse<UserDetailsResponse>> => {
  const formattedQuery = query ? `&persInfo=${query}` : "";
  const url = `${USER_GET_USERS_ROUTE}${queryUtils.queryParamToPageable({
    page,
    pageSize,
    sortDirection,
    sortField
  })}${formattedQuery}`;
  return httpService.get(url);
}

const downloadExportUsers = (query: string): Promise<BlobWithFilename> => {
  const formattedQuery = query ? `?persInfo=${query}` : "";
  return httpService.getReadableStreamWithFilename(`${USER_EXPORT_ROUTE}${formattedQuery}`)
}

function getUser(idUser: number, options?: RequestInit): Promise<UserDetailsResponse> {
  return httpService.get(`${USER_ROUTE}/${idUser}`, options);
}

function updateUser(idUser: number, {
  persInfo,
  permissions
}: UpdateUser, options?: RequestInit): Promise<UserDetailsResponse> {
  return httpService.put(`${USER_ROUTE}/${idUser}`, { persInfo, permissions }, options)
}

function createUserWithPerimeter(user: UserWithPerimeter, options?: RequestInit): Promise<UserDetailsResponse> {
  return httpService.post(`${USER_WITH_PERIMETER_ROUTE}`, { ...user }, options)
}

function deleteUser(idUser: number, options?: RequestInit): Promise<VoidFunction> {
  return httpService.delete(`${USER_DELETE_ROUTE}/${idUser}`, null, options)
}

export const userService = {
  getUserSelf,
  updateSelfLanguage,
  updateSelf,
  getUsers,
  getUser,
  updateUser,
  createUserWithPerimeter,
  deleteUser,
  downloadExportUsers
}
