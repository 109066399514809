/**
 * File listing every translated label in English
 */

export const EnglishMessages = {

  // LOGIN PAGE
  welcome: "Welcome to the",
  merchant_portal: "MERCHANT PORTAL!",
  email: "Email Address",
  password: "Password",
  email_example: "email@email.com",
  login_button: "Log in",
  forgot_password_cta: "Forgot your password?",
  logo_alt: "MarketPay logo",

  // LOGIN ERROR MESSAGES
  email_wrong_format: "Please check the format of your email address",
  email_required: "Email is required",
  password_required: "Password is required",
  user_unknown: "We couldn’t find your email address",
  invalid_password: "Your password and email do not match. Please try again or reset your Password.",
  password_pending: "Your account creation is not complete. Please click on the link you've received by email to set your password.",
  blocked_user: "Your account has been blocked. Please contact our support for more information.",

  // RESET PASSWORD VIEW
  pw_recovery_title: "Recover password",
  reset_password_text: "To reset your password, type your e-mail address and we will email you a link to set a new password.",
  submit_button: "Submit",

  // RESET PASSWORD CONFIRM VIEW
  pw_confirm_title: "Your password request has been submitted",
  pw_recovery_confirmation_body: "We just sent you a password reset link to the email address you provided.",
  pw_recovery_confirmation_not_sent: "If you did not receive the password reset email, check your email account's spam and junk filters.",
  pw_confirm_your_email: "Your email : {email}",
  pw_resend_init_password_mail: "Your password has not been initialized yet. We will send back the set up password email to the address you provided",

  // CHANGE PASSWORD
  change_password_sent_email: "A link to reset your password has been sent to your email address",
  change_password_title: "Password",
  change_password_reset_password: "Reset your password",
  change_password_send_email: "Click on the button below to receive a reset email to the email address",
  change_password_button: "Reset my password",

  // UPDATE PASSWORD VIEW

  // INIT PASSWORD
  init_pw_page_title: "Set a password",
  init_pw_page_field2: "Confirm password",
  init_pw_page2_text: "Your password has been set up successfully!",

  // UPDATE PASSWORD
  set_password: "Set new password",
  new_password_field: "New password",
  confirm_new_password_field: "Confirm new password",
  password_requirements_label: "Password must contain", // password_instructions
  password_requirements_include_label: "and one of the following: ", // password_instructions
  password_min_length: "at least 8 characters ", // password_instructions
  reset_password_button: "Reset my password",
  unmatched_passwords: "Password confirmation must match password",
  password_lowercase: "an uppercase letter,", // password_instructions
  password_uppercase: "a lowercase letter,", // password_instructions
  password_number: "a number", // password_instructions
  password_special_character: "and a special symbol (~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/)", // password_instructions
  confirm_password_required: "Confirm password is required",
  reset_password_confirmation: "Your password has been successfully changed",
  back_login: "Back to log in page",
  send_feedback: "Send feedback",

  // SIDE MENU
  menu_home: "Dashboard",
  menu_transactions: "Transactions",
  menu_accepted: "Accepted",
  menu_acquired: "Acquired",
  menu_settlements: "Settlements",
  menu_portfolio: "Portfolio",
  menu_users: "Users",
  menu_my_account: "My account",
  menu_my_reports: "My reports",
  menu_password: "Password",
  log_out: "Log out",
  menu_notification_settings: "My notifications",
  menu_language: "Language",

  // PROFILES
  merchant_admin: "Merchant Admin",
  market_pay_admin: "Administrator",
  operator: "Operator",
  profil_spring: "SPRING",

  // ACCOUNT PAGE

  // PERSONAL INFORMATION
  account_my_account: "My account",
  account_user: "User {id} : {firstName} {lastName}",
  create_user_title: "Create New User",
  pers_info: "Personal information",
  first_name: "First name",
  last_name: "Last name",
  creation_date: "Creation date",
  last_activity: "Last activity",
  account_email: "Email",
  firstname_required: "First name is required",
  firstname_wrong_format: "Your first name can only include letters, spaces and dashes", // name_rule
  lastname_required: "Last name is required",
  lastname_wrong_format: "Your last name can only include letters, spaces and dashes", // name_rule
  email_alr_used: "This email address is already related to an existing account",
  distributor: "Distributor",
  distributor_toggle: "Distributor view",

  // TYPE
  account_account: "Account",
  account_type: "Type",

  // PERIMETER
  level_col: "Level",
  peri: "Perimeter",
  title_paywish: "PayWish Account",
  permissions: "Permissions",
  peri_account: "Account",
  perimeter_group: "Group",
  peri_company: "Company",
  peri_merchant: "Merchant",
  edit_button: "Edit",
  delete: "Delete",
  account_all_perimeter: "All accounts",
  account_no_perimeter: "None",
  save_changes: "Save changes",
  discard_changes: "Discard changes",
  account_updated: "Your changes have been saved",
  account_tooltip_remove: "Remove this company from the perimeter",
  account_tooltip_reset: "Reset choices",
  add_company: "Add company",
  no_results: "No results",
  add_all: "Add all",
  required_account: "Field required",
  required_company: "Field required",
  required_merchants: "Field required",
  inherit_future_merchants: "Inherit future Merchants",
  inherit_future_companies: "Inherit future companies",
  account_add_all_companies: "Add all companies",
  paywish_user_subtitle: "PayWish User",
  paywish_usertype: "Type",
  paywish_admin: "PayWish Admin",
  paywish_user: "PayWish User",
  paywish_select_usertype: "Select user type",
  paywish_select_merchant: "Select merchant",
  paywish_pw_merchant: "PayWish merchant",
  paywish_user_success_msg: "A PayWish user has been successfully created",
  paywish_user_error_msg: "A technical error occurred, it was not possible to create a user at this time. Please try later",
  paywish_user_error_duplicate: "A PayWish user with this email address already exists",

  // TRANSACTIONS
  permission_transactions: "Transactions",
  permission_approve_ref: "Approve a refund request",
  permission_view_acc_acq_tx: "View accepted and acquired transactions",
  permission_trig_two_steps_ref: "Trigger a two-step refund",
  permission_trig_direct_ref: "Trigger a direct refund",

  // SETTLEMENTS
  permission_category_settlements: "Settlements",
  permission_view_settlements: "View the list of settlements",
  settlements_view_acq_transactions: "View acquired transactions",

  // USERS
  permission_users_man: "Users",
  permission_add_user: "Create new user on the Merchant Portal",
  permission_del_user: "Delete a user account",
  permission_user_update: "Update a user account",
  permission_export_users: "Export user list",

  // PORTFOLIO
  portfolio: "Portfolio",
  permission_category_portfolio: "Portfolio",
  permission_portfolio: "Access the Portfolio view",
  forbiddenLabel: "You do not have the required permissions to access this page",
  my_portfolio: "My Portfolio",
  title_volume_trends: "Volume trends",
  portf_title_billing: "Billing",
  portf_title_top_bottom: "Top/Bottom",
  portf_col_day: "Day %",
  portf_col_week: "Week %",
  portf_col_month: "Month %",
  portf_merchants: "Merchants",
  portf_companies: "Companies",
  portf_dist_com: "Distributor commission",
  portf_tx_err: "Transaction errors",
  switch_merchant: "Merchant",
  switch_company: "Company",
  portf_sort: "Sort by",
  portf_col_nbr_tx: "Nbr of transactions",
  portf_col_gross_sum: "Gross amount",
  portf_col_success_rate: "Transaction success rate",
  portf_col_dist_com: "Distributor commission",
  portf_col_avg_com_rate: "Average commission rate",
  portf_col_mp_com: "Market Pay commission",
  portf_col_total_commission: "Total commission",
  portf_col_total_fees: "Total fees",
  rank: "#",
  portf_filter_display_trend_by: "Display % values based on:",
  portf_tooltip_nbr_tx: "Nbr of transactions displayed are based on column sorting",
  portf_tooltip_gross: "Gross amount is displayed based on column sorting",
  portf_col_day_tooltip: "Sort on this column to display transaction data from yesterday",
  portf_col_week_tooltip: "Sort on this column to display transaction data from the last 7 days",
  portf_col_month_tooltip: "Sort on this column to display transaction data from the last month",
  portf_tooltip_tx_success_rate: "Transaction success rate represents the percentage of successfully accepted transactions compared to the total number of transactions in the search result.",
  portf_tooltip_distributor_commission: "Distributor commission represents the part of the Market Pay commission that is collected on behalf of the distributor.",
  portf_tooltip_mp_commission: "Market Pay commission represents both the amount of commission collected by Market Pay, and the amount of commission collected on behalf of the distributor.",
  portf_tooltip_total_commission: "Total commission represents the total amount of Market Pay fees, scheme fees, and interchange fees.",
  portf_tooltip_avg_commission_rate: "Average commission rate represents the average rate of Market Pay commission on all the transactions in the result.",
  tooltip_daily_evolution: "Daily evolution",
  tooltip_weekly_evolution: "Weekly evolution",
  tooltip_monthly_evolution: "Monthly evolution",
  tooltip_nb_transaction: "Number of transactions",
  tooltip_gross_sum: "Gross amount",
  // REPORTS PAGE
  my_reports_title: "My reports",
  reports_table_report_name_col: "Name",
  reports_table_latest_edition_col: "Latest edition",
  reports_table_frequency_col: "Frequency",
  reports_table_frequency_col_daily: "Daily",
  reports_table_frequency_col_weekly: "Weekly",
  reports_table_frequency_col_monthly: "Monthly",
  reports_table_latest_report_col: "Latest report",
  reports_table_download_button: "Download",
  reports_table_delete: "Delete",
  reports_table_delete_message: "Are you sure you want to delete {name}?",
  reports_table_delete_confirm: "Delete",
  reports_table_delete_cancel: "Cancel",

  // DOCUMENTS PAGE
  doc_menu: "Documents",
  doc_title: "Documents",
  doc_header: "Download your monthly payment fees statement",
  doc_settlement_year: "Year",
  doc_settlement_month: "Month",
  doc_rafec_download: "Download",

  // USERS PAGE
  users_acc_col_all: "All",
  users_acc_col: "Account",
  users_search: "Search",
  users_search_bar: "Search by first name, last name or email",
  add_user_button: "Add user",
  user_not_found: "User not found",
  acc_creation_conf_message: "The new account has been successfully created. A password set up email has been sent to {email}",
  back_user_list: "Back to users list",
  create_user_button: "Create user",
  trigger_welcome_email: "Send Welcome Email",
  welcome_email_header: "Email",
  welcome_email_sent: "Welcome email sent",
  welc_email_butt_error: "Oops, we were unable to send the email. Please try again later.",

  // LANGUAGE
  language_en: "EN",
  language_fr: "FR",
  language_it: "IT",
  language_es: "SP",
  language_nl: "NL",
  language_pt: "PT",

  // LOADER
  loader_search_results: "Looking for results...",

  // TABLE
  number_element: "{displayedCount} of {totalCount}",
  show_more: "Show {pageSize} more",
  no_data_found: "Oops! Your query returned 0 results",
  fetch_error: "Oops! Something wrong happened.",
  table_label: "Your query returned {count, plural, =0 {0 results} one {1 result} other {{count} results}}",
  query_results: "Your query returned",
  query_results_return: " {count, plural, one {result} other {results}}:",
  query_results_no_results: "No results",
  export_csv_button: "Export CSV",
  export_button: "Export",
  sr_schedule_button: "Schedule a report",
  sr_pop_up_title: "Schedule a report",
  sr_pop_up_report_name_label: "Report name",
  sr_pop_up_report_name_placeholder: "Weekly failed transactions export",
  sr_pop_up_frequency_label: "Frequency",
  sr_pop_up_frequency_placeholder: "Select",
  sr_pop_up_close_button: "Close",
  sr_pop_up_save_button: "Save",
  sr_frequency_monthly: "Monthly",
  sr_frequency_weekly: "Weekly",
  sr_frequency_daily: "Daily",
  sr_frequency_monthly_disclaimer: "A new report including last month's data will be built for you on the first day of every month",
  sr_frequency_weekly_disclaimer: "A new report including last week's data will be built for you every Monday morning",
  sr_frequency_daily_disclaimer: "A new report including last day's data will be built for you every morning",
  sr_conf_1_message: "You're about to schedule a {frequency} report named {reportName}.",
  sr_conf_1_message_confirm: "Do you confirm?",
  sr_conf_1_cancel_button: "Cancel",
  sr_conf_1_confirm_button: "Confirm",
  sr_conf_2_message_1: "Your report has been scheduled.",
  sr_conf_2_message_2: "Visit {myReportsLink} to find and manage all your scheduled reports.",
  sr_conf_2_message_link: "My reports",
  sr_conf_2_button: "OK",
  sr_error_empty_name: "Please give the report a name.",
  sr_error_existing_name: "This name is already used. Please choose another one.",
  sr_error_name_too_short: "Name must be at least 3 characters long.",
  sr_error_empty_frequency: "Please choose the frequency of your report.",
  sr_saving_error: "Your report can't be saved at this time due to a technical error. Please try again later.",
  export_limit_message_no_number: "The report you're trying to download is too large to be downloaded. Please narrow your search and try again.",
  toggle_sorting: "Click to sort",
  no_reports_scheduled: "You do not have any reports scheduled.",

  // ACTIVITY
  activity_years_ago: "{interval} years ago",
  activity_month_ago: "{interval, plural, one {1 month} other {{interval} months}} ago",
  activity_day_ago: "{interval, plural, one {1 day} other {{interval} days}} ago",
  activity_today: "Today",
  activity_yesterday: "Yesterday",
  activity_never: " ",

  // PERMISSIONS FORBIDDEN
  not_allowed_to_create_user: "You are not allowed to create users",
  not_allowed_to_delete_user: "You are not allowed to delete users",
  not_allowed_to_get_user: "You are not allowed to view users",

  // DISCARD POPUP
  toast_discard_change: "Your changes have been discarded.",
  unsaved_ch_err_mess: "Your changes could be lost.",
  unsaved_ch_err_mess_save: "Do you want to save them?",
  discard: "Discard",
  cancel: " Cancel",
  ok: "Ok",
  save: "Save",

  // DELETE POPUP
  delete_account_title: "Delete account: {email}",
  delete_account_dialog_box: "Are you sure you want to delete this account?",
  delete_account_success: "The user has been deleted",

  // MONTHS
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",

  // AMOUNT RANGE FILTER
  min_sup_max: "The min amount must be equal to, or smaller than, the max amount",
  negative_value: "Values must be equal to or more than 0",
  card_transactions: "Transactions",
  card_trans_gross_amount: "Total gross amount",
  card_trans_net_amount: "Total net amount",
  card_trans_refunds_amount: "Refunds",
  card_trans_fees_amount: "Fees",

  // TRANSACTION PAGE
  transactions_title: "Transactions",
  acc_transactions_title: "Accepted Transactions",
  filter_country: "Country",
  filter_account: "Account",
  filter_company: "Company",
  filter_merchant: "Merchant",
  filter_operation_date: "Transaction date",
  search: "Search",
  filter_by: "Filter by",
  admin_trans_view_title: "Apply at least one filter to see transactions",
  admin_trans_view_text: "Filter by country, account, company, merchant or payment date to narrow your search",
  export_limit_message: "You can’t export more than {max} transactions in a file. Please narrow your search and try again.",
  filter_search_button_toaster: "Choose an Account before starting your search",
  col_man_err_message: "You can't display less than 5 columns in the table. Select another field before unselecting this one.",

  // ADDITIONAL FILTERS
  more_filters: "More filters",
  less_filters: "Less filters",
  order_number: "Order number",
  payment_solution: "Payment solution",
  payment_type: "Payment type",
  checkout_type: "Checkout type",
  settlement_status: "Settlement status",
  settlement_date: "Settlement date",
  total_fees: "Fees",
  trans_authorization: "Authorization",
  trans_cancellation: "Cancellation",
  trans_capture: "Capture",
  trans_settled: "Settled",
  trans_refund: "Refund",
  trans_refund_request: "Refund request",
  event_success: "Success",
  event_declined: "Decline",
  event_error: "Error",
  solution_pay_online: "PayOnline",
  solution_pay_wish: "PayWish",
  solution_pay_onsite: "PayOnsite",
  type_debit: "Debit",
  type_credit: "Credit",
  checkout_contactless: "Contactless",
  checkout_online: "Online",
  checkout_insertion: "Insertion",
  checkout_magstripe: "Magstripe",
  financing_paid: "Settled",
  financing_pending: "Pending",
  batch_id: "Batch ID",
  scheme: "Scheme",
  filter_remittance: "Remittance",
  filter_service_type: "Service type",
  filter_collection_type: "Collection type",

  // POPUP COLUMNS
  col_man_button: "Manage columns",
  col_man_title: "Displayed columns",
  col_man_apply: "Apply",
  col_man_discard: "Discard",
  col_man_titles_acceptation: "Acceptance",
  col_man_titles_last_event: "Last event",
  col_man_titles_transaction: "Transaction",
  col_man_titles_settlement: "Settlement",
  col_man_titles_customer: "Customer",
  col_man_titles_merchant: "Merchant",
  col_man_checkout_type: "Checkout type",
  col_man_payment_method: "Payment method",
  col_man_payment_solution: "Payment solution",
  col_man_payment_type: "Payment type",
  col_man_authorisation_code: "Authorisation code",
  col_man_pos_id: "POS ID",
  col_man_pos_name: "POS Name",
  col_man_bank_acc_dom_country: "Domicile",
  col_man_card_iss_bank: "Issuing bank",
  col_man_card_iss_coun: "Issuing country",
  col_man_card_level: "Card level",
  col_man_card_type: "Card type",
  col_man_pan_first_six: "BIN",
  col_man_pan_last_four: "Pan last four digits",
  col_man_scheme: "Scheme",
  col_man_op_date: "Transaction date",
  col_man_op_time: "Transaction time",
  col_man_currency: "Currency",
  col_man_gross_amount: "Gross amount",
  col_man_net_amount: "Net amount",
  col_man_merch_trans_id: "Merchant transaction ID",
  col_man_order_num: "Order number",
  col_man_trans_type: "Transaction type",
  col_man_trans_event: "Event",
  col_man_status: "Status",
  col_man_trans_event_id: "Event ID",
  col_man_root_trans_event_id: "Transaction ID",
  col_man_event_timestamp: "Event timestamp",
  col_man_acquirer: "Acquirer",
  col_man_settlement_batch: "Settlement ID",
  col_man_settlement_date: "Settlement date",
  col_man_settlement_status: "Settlement status",
  col_man_interchange: "Interchange",
  col_man_intermediate_fees: "Intermediate fees",
  col_man_mkp_fees: "Market Pay fees",
  col_man_scheme_fees: "Scheme fees",
  col_man_total_fees: "Total fees",
  col_man_account_name: "Account",
  col_man_company_name: "Company",
  col_man_merchant_name: "Merchant Name",
  col_man_country: "Country",
  col_man_contract_num: "Contract number",
  col_man_reset_button: "Back to default settings",
  col_man_titles_fee_calc: "Fee calculation",
  col_man_titles_fee_amount: "Fee amount",
  col_settlement_month: "Settlement month",
  col_imposta_di_bollo: "Imposta di bollo",
  category_distribution: "Distribution",
  col_retrocession: "Retrocession",
  distrib_share_margin_amt: "Distributor share margin",
  distrib_share_margin_rate: "Distributor share margin rate",
  mp_share_margin_amt: "Market Pay share margin",
  mp_share_margin_rate: "Market Pay share margin rate",
  col_operation_time_utc: "Operation time (UTC)",
  col_operation_time_local: "Operation time (Local)",

  // COUNTRIES
  country_filter_fr: "France",
  country_filter_sp: "Spain",
  country_filter_nl: "Netherlands",
  country_filter_pt: "Portugal",
  country_filter_it: "Italia",
  country_filter_bel: "Belgium",
  country_filter_ger: "Germany",

  // LOADING
  loading: "Loading...",

  // SETTLEMENTS
  card_settlements_gross_am: "Gross amount",
  card_settlements_comm: "Fees",
  card_settlements_fin_am: "Settled amount",
  settled_by_mp: "Settled by Market Pay",
  filter_settlement_date: "Settlement date",

  // HOMEPAGE
  good_morning: "Hello,",
  good_evening: "Good evening,",
  homepage_today: "Today",

  // GRAPH

  // COMMON
  homepage_graph_month: "Month",
  homepage_graph_week: "Week",
  homepage_graph_day: "Day",
  week: "Week",
  graph_tooltip_number: "Number",
  graph_tooltip_amount: "Amount",
  graph_tooltip_week_leg: "W",
  graph_dropdown_interval: "Time interval",
  graph_dropdown_amount: "Amount type",

  // TRANSACTIONS
  homepage_graph_leg_gross: "Gross amount",
  homepage_graph_leg_net: "Net amount",
  homepage_graph_leg_num: "Number of transactions",
  homepage_graph_leg_am: "Amount (€)",
  graph_trans_hp_bot_leg_num: "Number of transactions",
  graph_trans_hp_bot_leg_am: "Amount (€)",
  graph_transactions: "TRANSACTIONS",

  // SETTLEMENTS
  graph_hp_sett_filter_value_1: "Settled",
  graph_hp_sett_filter_value_2: "Fees",
  graph_hp_sett_leg_num: "Number of settlements",
  graph_hp_sett_leg_am: "Amount (€)",
  graph_hp_bot_sett_leg_num: "Number of settlements",
  graph_hp_bot_sett_leg_am: "Amount (€)",
  graph_settlements: "SETTLEMENTS",

  // MONTHS
  january_abr: "Jan",
  february_abr: "Feb",
  march_abr: "Mar",
  april_abr: "Apr",
  may_abr: "May",
  june_abr: "Jun",
  july_abr: "Jul",
  august_abr: "Aug",
  september_abr: "Sep",
  october_abr: "Oct",
  november_abr: "Nov",
  december_abr: "Dec",

  // HOMEPAGE DAILY KEY NUMBERS
  homepage_title_sales: "Sales",
  homepage_title_settlements: "Settlements (previous day transactions)",
  homepage_key_numb_acc: "Accepted transactions",
  homepage_key_numb_am: "Total accepted amount",
  homepage_key_numb_settlement_gr_am: "Gross amount",
  homepage_key_numb_fees: "Fees",
  homepage_key_numb_fin: "Settled amount",

  // ACQUIRED TRANSACTIONS VIEW
  acq_transactions_title: "Acquired Transactions",
  transaction_date: "Transaction date",
  gross_amount: "Gross amount",
  col_card_brand: "Card brand",
  col_man_fees: "Fees",
  col_tlc_number: "Remittance number",
  acq_trans_key_numbers_title: "Settled by Market Pay",
  card_acq_trans_number: "Transactions",
  card_acq_trans_gross_amount: "Gross amount",
  card_acq_trans_fees_amount: "Fees",
  card_acq_trans_net_amount: "Net amount",
  acq_details_title: "Acquired Transactions  > ",

  // SETTLEMENTS VIEW
  settlements_title: "Settlements",
  settlement_tab_batch: "Batch ID",
  set_tab_merchant: "Merchant",
  pay_tab_settlement_date: "Settlement date",
  pay_tab_pay_sol: "Solution",
  pay_tab_pay_amo: "Gross amount",
  settlement_tab_fees: "Fees",
  pay_tab_settlement_am: "Settled amount",

  // TRANSACTION DETAILS
  tp_titles_acceptation: "Acceptance",
  tp_titles_customer: "Customer",
  tp_titles_transaction: "Transaction",
  tp_titles_history: "Last event",
  tp_titles_settlement: "Settlement",
  tp_titles_merchant: "Merchant",
  tp_fees_calculation: "Fees calculation",
  tp_fees_amount: "Fees amount",
  tpd_checkout_type: "Checkout type",
  tpd_payment_method: "Payment method",
  tpd_payment_solution: "Payment solution",
  tpd_payment_type: "Payment type",
  tpd_authorisation_code: "Authorisation code",
  tpd_pos_id: "POS ID",
  tpd_pos_name: "POS Name",
  tpd_bank_acc_dom_country: "Domicile",
  tpd_card_iss_bank: "Issuing bank",
  tpd_card_iss_coun: "Issuing country",
  tpd_card_level: "Card level",
  tpd_card_type: "Card type",
  tpd_pan_first_six: "BIN",
  tpd_pan_last_four: "Pan last four digits",
  tpd_scheme: "Scheme",
  tpd_op_date: "Operation date",
  tpd_op_date_utc: "Operation date (UTC)",
  tpd_op_time: "Operation time",
  tpd_currency: "Currency",
  tpd_gross_amount: "Gross amount",
  tpd_net_amount: "Net amount",
  tpd_trans_id: "Transaction ID",
  tpd_merch_trans_id: "Merchant transaction ID",
  tpd_order_num: "Order number",
  tpd_trans_type: "Transaction type",
  tpd_trans_event: "Event",
  tpd_status: "Status",
  tpd_trans_event_id: "Event ID",
  tpd_root_trans_event_id: "Transaction ID",
  tpd_event_timestamp: "Event timestamp",
  tpd_acquirer: "Acquirer",
  tpd_settlement_batch: "Settlement batch",
  tpd_settlement_date: "Settlement date",
  tpd_settlement_status: "Settlement status",
  tpd_interchange: "Interchange",
  tpd_intermediate_fees: "Intermediate fees",
  tpd_mkp_fees: "Market Pay fees",
  tpd_scheme_fees: "Scheme fees",
  tpd_total_fees: "Total fees",
  tpd_account_name: "Account",
  tpd_company_name: "Company",
  tpd_merchant_name: "Merchant",
  tpd_country: "Country",
  tpd_contract_num: "Contract number",
  tpd_transaction_settlement_id: "Transaction settlement ID",
  tpd_tlc_number: "Remittance number",
  tpd_card_zone: "Card zone",
  tpd_card_brand: "Card brand",
  tpd_settlement_id: "Settlement ID",
  tpd_calculation_method: "Calculation method",
  tpd_marketpay_fixed_fees: "Market Pay fixed fees",
  tpd_markeetpay_fees_rate: "Market Pay fee rate",
  tpd_fixed_scheme_fees: "Fixed scheme fees",
  tpd_scheme_fees_rate: "Scheme fee rate",
  tpd_total_marketpay_fees: "Total Market Pay fees",
  tpd_fixed_fees: "Fixed fees included",
  tpd_variable_fees: "Variable fees included",
  tpd_total_acquiring_fees: "Total acquiring fees",
  col_total_marketpay_fees: "Total Market Pay fees",
  col_interchange_fees: "Interchange fees",
  col_scheme_fees_rate: "Scheme fee rate",
  col_fixed_scheme_fees: "Fixed scheme fees",
  col_marketpay_fees_rate: "Market Pay fee rate",
  col_marketpay_fixed_fees: "Market Pay fixed fees",
  col_calculation_method: "Calculation method",
  col_card_zone: "Card zone",
  col_transaction_settlement_id: "Transaction settlement ID",
  payment_type_card: "Card",
  payment_type_banktransfer: "Bank Transfer",
  payment_type_contactless: "Contactless",
  payment_type_wallet: "Wallet",
  pos_id: "POS ID",
  col_processing_ht: "Net processing fees",
  col_processing_tva: "Processing fees VAT",
  col_processing_ttc: "Gross processing fees",


  // TRANSACTION HISTORY TABLE
  trans_det_tab_uuid: "UUID",
  trans_det_tab_timestamp: "Timestamp",
  trans_det_tab_event: "Event",
  trans_det_tab_status: "Status",
  trans_det_tab_amount: "Amount",
  transaction_det_last_sync: "Last sync: ",
  transaction_det_page_title_1: "Transactions >",
  transaction_det_page_title_2: "Transaction {transactionId} details",
  back_transactions_list: "Back to transactions list",

  // NOTIFICATIONS
  notif_new_merchant: "A new merchant has been added to your",
  notif_new_comp: "A new company has been added to your",
  notif_new_account: "A new account has been added to your",
  notif_new_merchant_admin_inapp: "A new Merchant Admin has been created:",
  notif_new_marketpay_admin_inapp: "A new Market Pay Admin has been created:",
  notif_new_operator_inapp: "A new Operator has been created in your",
  notif_hide_button: "Hide",
  notif_hide_all_button: "Hide all",
  new_notif: "You have {count} new notification(s):",
  no_new_notif: "You don't have any new notifications",
  notif_viewed: "Viewed",

  // NOTIFICATION SETTINGS
  my_notifications: "My notifications",
  notif_title: "My notifications",
  notif_peri_update: "Perimeter updates",
  notif_user_update: "Users",
  notif_in_app: "In-App",
  notif_email: "Email",
  notif_edit_merchant: "New Merchant",
  notif_edit_company: "New Company",
  notif_edit_acc: "New Account",
  notif_edit_group: "New Group",
  notif_edit_operator: "New Operator",
  notif_edit_merchant_admin: "New Merchant Admin",
  notif_edit_markerpay_admin: "New Market Pay Admin",
  perim_creat_account: "Type an Account name",
  perim_creat_company: "Type a Company name",

  // FILTERS
  filter_all_comp: "All",
  reset_button_text: "Reset",

  // VIEWS
  sv_deletion_confirmation_toaster: "Your view has been succesfully deleted",
  sv_creation_confirmation: "Your view has been succesfully saved",
  sv_save_this_view: "Save this view",
  sv_view_name: "View name",
  sv_saved_views: "Saved views",
  sv_name_is_required: "name is required",
  sv_name_is_too_short: "name must have 3 characters min",
  view_name_already_exists: "This view name already exists",
  sv_deletion_confirmation: "Are you sure you want to delete this view?",

  // DATEPICKER DEFINED RANGES
  date_picker_today: "Today",
  date_picker_yesterday: "Yesterday",
  date_picker_current_month: "Current month",
  date_picker_last_week: "Last week",
  date_picker_last_two_weeks: "Last two weeks",
  date_picker_last_month: "Last month",
};

export type MessageKeys = keyof typeof EnglishMessages;
