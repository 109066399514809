import React, {ChangeEvent, FunctionComponent, KeyboardEvent, useState} from "react";
import {RouteProps, useLocation, useNavigate} from "react-router-dom";
import { LoginTextInput } from "../components/atoms/input/LoginTextInput";
import {Box, Link, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {authService} from "../services/auth/AuthService";
import {errorUtils, PromisableApiError} from "../utils/api/errorUtils";
import {validationErrorsUtils} from "../utils/business/validationErrorsUtils";
import FlexBox from "../components/atoms/flex/FlexBox";
import {FlexAttributValue} from "../types/flex/flex";
import ErrorMessage from "../components/atoms/ErrorMessage";
import {LOGIN_ERRORS} from "../constants/errors/login";
import {HOME_PATH, RESET_PASSWORD_PATH} from "constants/routes/RoutePaths";
import { LoginPasswordInput } from "../components/atoms/input/LoginPasswordInput";
import { LocalStorageEnum } from "constants/localstorage/localstortage";
import {dateUtils} from "../utils/common/dateUtils";
import {WarningAmber} from "@mui/icons-material";
import ContainedExterneButton from "../components/atoms/button/ContainedExterneButton";
import {MaterialUiColor} from "../types/materialui/materialui";
import {useAuthUser} from "../hooks/useAuthUser";
import {paletteTheme} from "../styles/themes/palette";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";

const LoginView: FunctionComponent<RouteProps> = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const loggedInPath = location.state;

    const { smDown } = useMediaQueryUtil();
    
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const [disabled, setDisabled] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    
    const {cleanAndRefreshUserDetails} = useAuthUser();

    const onChangeLoginInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const _email = event.target.value;
        setDisabled(false)
        setEmail(_email);
        if (validated) {
            const emailErrors = validationErrorsUtils.validateEmailForm(_email);
            setErrors({
                ...errors, EMAIL_WRONG_FORMAT: false,
                INVALID_PASSWORD: false,
                EMAIL_REQUIRED: false,
                ...emailErrors
            })
        }
    }

    const onChangePasswordInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const _password = event.target.value;
        setDisabled(false)
        setPassword(_password);
        if (validated) {
            const passwordErrors = validationErrorsUtils.validatePasswordLoginForm(_password);
            setErrors({
                ...errors,
                PASSWORD_REQUIRED: false,
                INVALID_PASSWORD: false,
                ...passwordErrors
            })
        }
    }

    const onLogin = () => {
        setValidated(true)
        setDisabled(true)
        const validationError = validationErrorsUtils.validateLoginForm(email, password);
        if (Object.keys(validationError).length > 0) {
            setErrors(validationError)
        } else {
            setLoading(true)
            authService.auth(email, password)
                .then(response => {
                    const token = {"token": response.token, "expired_at": dateUtils.getDateExpiration()};
                    localStorage.setItem(LocalStorageEnum.TOKEN, JSON.stringify(token))
                    cleanAndRefreshUserDetails()
                    setLoading(false)
                    navigate(!!loggedInPath ? loggedInPath : HOME_PATH);
                })
                .catch((e: PromisableApiError) => {
                        setLoading(false)
                        // Get all error code from back
                        errorUtils.handleErrors(e, (apiError) => setErrors(validationErrorsUtils.cleanAndUpdate(apiError.errors)))
                    }
                );
        }
    }

    const onKeyboardEnter = (event: KeyboardEvent<HTMLSpanElement>) => {
        if (event.key === "Enter") {
            !disabled && onLogin()
        }
    }

    return (<FlexBox justifyContent={FlexAttributValue.CENTER}
                     flexDirection={FlexAttributValue.COLUMN}>
            <FlexBox paddingBottom={2}>
                <Typography variant="h3">
                   <span>
                        <FormattedMessage id="welcome"/>
                   </span>
                   </Typography>
                    <Typography variant="h3" color={paletteTheme.colors.PRIMARY} fontWeight="bold" display="inline">
                        &nbsp;<FormattedMessage id="merchant_portal"></FormattedMessage>
                    </Typography>
            </FlexBox>
            <FlexBox fontSize={14} style={{paddingBottom: "13px"}}>
                    {
                        [LOGIN_ERRORS.INVALID_PASSWORD,
                            LOGIN_ERRORS.PASSWORD_EMAIL_REQUIRED,
                            LOGIN_ERRORS.PASSWORD_PENDING,
                            LOGIN_ERRORS.BLOCKED_USER,
                            LOGIN_ERRORS.EMAIL_WRONG_FORMAT,
                            LOGIN_ERRORS.EMAIL_TOO_LONG,
                            LOGIN_ERRORS.PASSWORD_TOO_LONG]
                            .map(error =>
                                <ErrorMessage 
                                    icon={<WarningAmber sx={{fontSize: 16}} color={MaterialUiColor.ERROR}/>}
                                    fontSize={14} 
                                    textAlign="center" 
                                    key={error.id} 
                                    error={error.id}
                                    errors={errors}
                                    width="calc(100% - 38px)"
                                    />)
                    }
                </FlexBox>

            <Box>
                <FlexBox
                    justifyContent={FlexAttributValue.CENTER}
                    flexDirection={FlexAttributValue.COLUMN}>

                    <LoginTextInput
                        id="email"
                        required
                        validated={validated}
                        placeholder="email@email.com"
                        type="email"
                        label="email"
                        value={email}
                        error={errors.EMAIL_WRONG_FORMAT || errors.INVALID_PASSWORD || errors.EMAIL_REQUIRED}
                        onChange={onChangeLoginInput}
                        inputProps={{maxLength: 99}}
                        errors={errors}
                        onKeyDown={onKeyboardEnter}
                        LabelSx={{fontSize: 14, fontWeight: 300}}
                        possibleErrors={[LOGIN_ERRORS.EMAIL_REQUIRED]}
                    />

                    <Box marginTop={3} marginBottom={4}>
                        <LoginPasswordInput
                            id="password"
                            required
                            label="password"
                            value={password}
                            validated={validated}
                            error={errors.PASSWORD_REQUIRED || errors.INVALID_PASSWORD}
                            onChange={onChangePasswordInput}
                            inputProps={{maxLength: 255}}
                            LabelSx={{fontSize: 14, fontWeight: 300}}
                            possibleErrors={[LOGIN_ERRORS.PASSWORD_REQUIRED]}
                            onKeyDown={onKeyboardEnter}
                            errors={errors}>
                        </LoginPasswordInput>
                    </Box>

                    <FlexBox marginBottom={2} display={FlexAttributValue.FLEX}>
                        <ContainedExterneButton
                            sx={loading && {"&.MuiButton-containedPrimary.Mui-disabled": {backgroundColor: paletteTheme.colors.PRIMARY}}}
                            disabled={disabled}
                            type="button"
                            loading={loading}
                            onClick={onLogin}
                            style={
                                {
                                    width: smDown ? "100%" : "auto", 
                                    height: smDown ? "40px" : "32px",
                                }
                            }
                            >
                            <FormattedMessage id="login_button"/>
                        </ContainedExterneButton>
                    </FlexBox>

                    <FlexBox marginBottom={4} display={FlexAttributValue.INLINE}>
                        <Link fontSize={12}
                              fontWeight={300}
                              href={`/${RESET_PASSWORD_PATH}`}
                              onKeyDown={onKeyboardEnter}>
                            <FormattedMessage id="forgot_password_cta"></FormattedMessage>
                        </Link>
                    </FlexBox>
                </FlexBox>
            </Box>
        </FlexBox>
    )
}


export default LoginView;
