import React, { FunctionComponent } from "react";
import { ExpandCollapseFiltersConfig } from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";
import TransactionFilterCollapseButton from "components/molecules/transaction/TransactionFilterCollapseButton";
import { MaterialUiColor } from "types/materialui/materialui";
import Badge from "@mui/material/Badge";

interface ExpandCollapseFiltersProps {
    expandCollapseFiltersConfig: ExpandCollapseFiltersConfig;
}

export const ExpandCollapseFilters: FunctionComponent<ExpandCollapseFiltersProps> = (
    {
        expandCollapseFiltersConfig: {
            appliedFiltersCount,
            iconSize,
            moreFiltersButtonState,
            onMoreLessFiltersClick
        }
    },
) => {

    return <Badge
        badgeContent={appliedFiltersCount}
        invisible={moreFiltersButtonState.showFilters && appliedFiltersCount > 0}
        onClick={onMoreLessFiltersClick}
        color={MaterialUiColor.PRIMARY}
        sx={{
            paddingRight: "6px",
            "& .MuiBadge-badge": {
                height: iconSize,
                width: iconSize,
                minWidth: iconSize,
                fontSize: "10px",
                fontWeight: "bold"
            }
        }}>
        <TransactionFilterCollapseButton buttonState={moreFiltersButtonState} />
    </Badge>
}
