import {httpService} from "../HttpService";
import {AUTH_ROUTE} from "../../constants/routes/ApiRoutes";
import {authUtils} from "../../utils/api/authUtils";
import { LocalStorageEnum } from "constants/localstorage/localstortage";

interface AuthResponse {
    token: string
}

/**
 * Call the login route
 * Merchant the token in the localStorage
 * @param email
 * @param password
 */
function auth(email: string, password: string): Promise<AuthResponse> {
    localStorage.removeItem(LocalStorageEnum.TOKEN)
    return httpService.postAsAnonymous(AUTH_ROUTE, {email, password})
}

/**
 * Logout by removing token and cleaning user information from the localStorage
 */
function logout(): void {
    authUtils.cleanSession();
}

export const authService = {
    auth,
    logout
}
