import React, { FunctionComponent } from "react";
import FlexBox from "components/atoms/flex/FlexBox";
import { Switch } from "components/atoms/switch/Switch";
import { mapNotifChannelToMessageCode, mapNotifTypeToMessageCode } from "constants/notification/NotificationSettingsConfig";
import { useIntl } from "react-intl";
import { FlexAttributValue } from "types/flex/flex";
import { newToggle, NotificationChannel, NotificationSettingsType, NotificationToggle, toggleEq } from "types/notifications/NotificationSettingsTypes";
import { arrayUtils } from "utils/common/arrayUtils";

export const ChannelToggle:FunctionComponent<{
    channel: NotificationChannel,
    type: NotificationSettingsType,
    activeToggles: readonly NotificationToggle[],
    triggerToggle: (toggle:NotificationToggle) => void,
}> = ({
    channel, 
    type,
    activeToggles,
    triggerToggle,
}) => {
    const intl = useIntl();
    const channelTranlated = intl.formatMessage({id:mapNotifChannelToMessageCode(channel)});
    const typeTranlated = intl.formatMessage({id:mapNotifTypeToMessageCode(type)});
    const title = `${channelTranlated} - ${typeTranlated}`;
    const toggle = newToggle(channel, type);
    const checked = arrayUtils.indexOf(activeToggles, toggle, toggleEq) !== -1;
    return <FlexBox justifyContent={FlexAttributValue.END}>
        <Switch
            title={title}
            checked={checked} 
            onClick={() => triggerToggle(toggle)}
        />
    </FlexBox>
};
