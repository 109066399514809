import React, {FunctionComponent} from "react";
import CardPopup from "../../atoms/card/CardPopup";
import {Dialog as DialogMaterialUI, Typography} from "@mui/material";
import {DeleteOutline, PriorityHigh} from "@mui/icons-material";
import FlexBox from "../../atoms/flex/FlexBox";
import {FlexAttributValue} from "../../../types/flex/flex";
import OutlinedButton from "../../atoms/button/OutlinedButton";
import {FormattedMessage} from "react-intl";
import {paletteTheme} from "../../../styles/themes/palette";

export interface DialogProps {
    openDialog: boolean
    onCloseDialog: () => void,
    handleDelete: () => void
}

const DeleteViewDialog: FunctionComponent<DialogProps> = ({
                                                              openDialog,
                                                              onCloseDialog,
                                                              handleDelete
                                                          }) => {


    return (
        <DialogMaterialUI
            open={openDialog}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CardPopup sx={{width: "414px"}}>
                <FlexBox
                    flexDirection={FlexAttributValue.ROW}
                    justifyContent={FlexAttributValue.CENTER}
                >
                    <span className="position-relative">
                        <DeleteOutline
                            sx={{
                                color: paletteTheme.colors.DANGER,
                                marginBottom: 2
                            }}
                            fontSize="large"
                        />
                        <PriorityHigh
                            sx={{
                                position: "absolute",
                                top: 9,
                                left: "22px",
                                color: paletteTheme.colors.DANGER
                            }}
                            fontSize="small"/>
                         </span>
                </FlexBox>
                <Typography textAlign="center" marginBottom={4}><FormattedMessage id="sv_deletion_confirmation"/></Typography>
                <FlexBox flexDirection={FlexAttributValue.ROW} justifyContent={FlexAttributValue.SPACE_BETWEEN}>
                    <OutlinedButton color="secondary" onClick={onCloseDialog}>
                        <FormattedMessage id="cancel"/>
                    </OutlinedButton>

                    <OutlinedButton onClick={() => {
                        handleDelete()
                        onCloseDialog()
                    }}>
                        <FormattedMessage id="delete"/>
                    </OutlinedButton>
                </FlexBox>
            </CardPopup>
        </DialogMaterialUI>
    )
}

export default DeleteViewDialog;
