import React, {FunctionComponent} from "react";
import FlexBox from "../../atoms/flex/FlexBox";
import {FlexAttributValue} from "../../../types/flex/flex";
import {Box, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {TransactionAdditionalFiltersState} from "../../../interfaces/transaction/transaction";
import WatchedLinkUIStyled from "../../../styles/components/link/WatchedLinkUIStyled";

interface TransactionFilterCollapseButtonProps {
    buttonState: TransactionAdditionalFiltersState
}

const TransactionFilterCollapseButton: FunctionComponent<TransactionFilterCollapseButtonProps> = ({
                                                                                                      buttonState
                                                                                                  }) =>

    (<WatchedLinkUIStyled
            component="button"
            underline="none"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
            <FlexBox
                flexDirection={!buttonState.showFilters ? FlexAttributValue.COLUMN : FlexAttributValue.COLUMN_REVERSE}
                alignItems={FlexAttributValue.CENTER}>
                <Typography
                    fontWeight={600}
                    textAlign="center"
                    marginTop={buttonState.showFilters ? "-6px" : 0}
                    lineHeight="10px">
                    <FormattedMessage id={buttonState.id}/>
                </Typography>
                <Box marginTop={!buttonState.showFilters ? "-3px" : 0}>
                    {buttonState.arrow}
                </Box>
            </FlexBox>
        </WatchedLinkUIStyled>
    )


export default TransactionFilterCollapseButton;
