import {Tooltip, Typography, TypographyProps} from "@mui/material";
import React, {FunctionComponent} from "react";
import {Placement} from "../../../types/placement/placement";

interface FilterTooltipProps {
    overflowText?: string; // To use if children is not wanted as the tooltip text
    typographyProps?: TypographyProps; // Use to customize typography
    placement?: Placement;
    openTooltip: boolean;
    isPopoverFilter?: boolean;
}

const FilterTooltip: FunctionComponent<FilterTooltipProps> = ({
                                                                  overflowText,
                                                                  typographyProps,
                                                                  placement = "right",
                                                                  openTooltip,
                                                                  isPopoverFilter = false,
                                                                  children,
                                                              }) => {

    return isPopoverFilter ?
        <Tooltip title={overflowText ?? children} placement={placement} open={openTooltip}
                 disableHoverListener disableFocusListener>
            <Typography component="span"{...typographyProps}>
                {children}
            </Typography>
        </Tooltip> :
        <Tooltip title={overflowText ?? children} placement={placement} disableHoverListener={!openTooltip}
                 disableFocusListener>
            <Typography component="span"{...typographyProps}>
                {children}
            </Typography>
        </Tooltip>
}

export default FilterTooltip;
