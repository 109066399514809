import {Popper, PopperProps} from "@mui/material";
import React, {FunctionComponent} from "react";

interface AutocompletePopperProps extends PopperProps {
    minWidth?: number
}

const AutocompletePopper: FunctionComponent<AutocompletePopperProps> = ({
                                                                            minWidth,
                                                                            ...rest
                                                                        }) => {
    return (
        <Popper {...rest}
                style={{
                    width: "auto",
                    minWidth: minWidth,
                    maxHeight: "250px",
                    zIndex: 1
                }}
                placement="bottom-start"/>
    )
}

export default AutocompletePopper
