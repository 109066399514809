import React, { FunctionComponent } from "react";
import ContainedButton from "components/atoms/button/ContainedButton";
import { useIntlFormatter } from "hooks/intl/useIntlFormatter";
import Typography from "@mui/material/Typography";
import styles from "components/molecules/dialog/ScheduleReport/ScheduledReportConfirmation/scheduledReportConfirmation.module.scss";
import CheckIcon from "@mui/icons-material/Check";

import WatchedLink from "components/atoms/link/WatchedLink";
import { MY_REPORTS_PATH } from "constants/routes/RoutePaths";

interface SaveViewDialogContentProps {
    onCloseDialog: () => void,
}

export const ScheduledReportConfirmation: FunctionComponent<SaveViewDialogContentProps> = ({
    onCloseDialog,
}) => {

    const { formatMessage } = useIntlFormatter();

    const onClose = () => {
        onCloseDialog();
    }

    return (
        <div className={`flex flex-col flex-center ${styles["dialog-wrapper"]}`} >
            <div>
                <CheckIcon className={styles.icon} />
            </div>
            <div>
                <div className={styles["text-wrapper"]}>
                    <Typography>
                        {formatMessage("sr_conf_2_message_1")}
                    </Typography>
                    <Typography>
                        {formatMessage("sr_conf_2_message_2",
                            {
                                myReportsLink: (
                                    <WatchedLink className={styles["my-reports-link"]} to={`/${MY_REPORTS_PATH}`}>
                                        {formatMessage("sr_conf_2_message_link")}
                                    </WatchedLink>
                                )
                            })}
                    </Typography>
                </div>
            </div>
            <div className={styles["btn-wrapper"]}>
                <ContainedButton onClick={onClose}>
                    {formatMessage("sr_conf_2_button")}
                </ContainedButton>
            </div>
        </div>
    )
}
