import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  subDays,
  subMonths,
  subWeeks,
  formatISO,
  format,
} from "date-fns";
import { Range } from "react-date-range";

function getDateExpiration(): Date {
  const date = new Date();
  date.setMinutes(date.getMinutes() + parseInt(process.env.REACT_APP_EXPIRATION_TOKEN_IN_MINUTE, 10));
  return date;
}

function formatToLocale(date: Date): string {
  return date instanceof Date && !isNaN(date.getTime()) ? date.toLocaleDateString() : "";
}

function formatToISO(date: Date): string | undefined {
  return date instanceof Date && !isNaN(date.getTime()) ? formatISO(new Date(date), { representation: "date" }) : "";
}

function formatToEn(date: Date): string {
  return date instanceof Date && !isNaN(date.getTime()) ? date.toLocaleDateString("en") : "";
}

function formatToRange(start: string, end: string, key = "selection"): Range {
  return {
    startDate: start && start !== "" ? new Date(start) : null,
    endDate: new Date(end || ""),
    showDateDisplay: false,
    key: key,
  };
}

function formatToLocale2Digit(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  };
  return date !== null ? date.toLocaleDateString(navigator.language, options) : "";
}

function formatFullToLocale(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return date.toLocaleDateString(navigator.language, options);
}

function formatToTime(date: string): string {
  if (!date.includes("UTC")) {
    return "-";
  }

  const modifiedDate = date.replaceAll("-", "/");
  const newDate = new Date(modifiedDate);
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return newDate.toLocaleTimeString(navigator.language, options);
}

function formatDate(date: Date): string {
  return [
    date.getDate().toString().padStart(2, "0"),
    (date.getMonth() + 1).toString().padStart(2, "0"),
    date.getFullYear(),
  ].join("/");
}

function getLastTwelveDays(): Date[] {
  const today = new Date();
  return eachDayOfInterval({
    start: subDays(today, 11),
    end: today,
  });
}

function formatTime(date: string): string {
  if (!date) {
    return "-";
  }
  const newDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  return new Intl.DateTimeFormat(navigator.language, options).format(newDate);
}

const labels = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

function getLastTwelveMonths(): string[] {
  const today = new Date();
  return eachMonthOfInterval({
    start: subMonths(today, 11),
    end: today,
  }).map((date) => labels[date.getMonth()]);
}

function getWeekDate(date: Date): number {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
}

function getLastTwelveWeeks(): string[] {
  const today = new Date();
  return eachWeekOfInterval({ start: subWeeks(today, 11), end: today }, { weekStartsOn: 1 }).map((date) =>
    getWeekDate(date).toString()
  );
}

function getTimeFromTimestampString(timestamp: string): number {
  return new Date(timestamp).getTime();
}

function getDateTimeFormat(date: Date): string {
  const originalDate = new Date(date);
  return format(originalDate, "yyyy-MM-dd'T'HH:mm:ss");
}

function getNextDay(date: Date): Date {
  const initialDate = new Date(date);
  initialDate.setDate(initialDate.getDate() + 1);
  initialDate.setHours(0, 0, 0, 0);
  return initialDate;
}

function resetTimeToMidnight(date: Date): Date {
  const currentDate = new Date(date);
  currentDate.setHours(0, 0, 0, 0);
  return new Date(currentDate);
}

const get12MonthsNumberList = ()=>{
  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  return months.map((month) => {
    return {
      id: month,
      name: month,
    };
  });
}

const getPastMonth = ()=>{
  const currentMonth = new Date().getUTCMonth();
  // if current month = 0 (January), pre-select December (past month)
  const pastMonth = currentMonth < 1 ? 12 : currentMonth;
  const formattedMonth = pastMonth.toString().length < 2 ? `0${pastMonth.toString()}` : pastMonth.toString();
  return {
    id: formattedMonth,
    name: formattedMonth,
  };
}

const getYearListSince2023 = ()=>{
  const minYear = 2023;
  const today = new Date();
  let currentYear = today.getUTCFullYear();
  // if Januray, pre-select last year
  if (today.getUTCMonth() === 0) {
    currentYear -= 1;
  }
  const numberOfYears = currentYear - minYear + 1;
  return Array.from({ length: numberOfYears }, (_, index) => {
    const years = (minYear + index).toString();
    return {
      id: years,
      name: years,
    };
  });
}

export const dateUtils = {
  getDateExpiration,
  formatToLocale,
  formatToLocale2Digit,
  formatFullToLocale,
  formatToTime,
  getLastTwelveWeeks,
  getLastTwelveDays,
  getLastTwelveMonths,
  formatToEn,
  formatToRange,
  getTimeFromTimestampString,
  formatToISO,
  formatDate,
  getDateTimeFormat,
  formatTime,
  getNextDay,
  resetTimeToMidnight,
  get12MonthsNumberList,
  getPastMonth,
  getYearListSince2023,
};
