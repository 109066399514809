import React, { FunctionComponent } from "react";
import {
  FormControl,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";
import { SelectProps as SelectPropsMaterialUI } from "@mui/material/Select/Select";
import { arrayUtils } from "utils/common/arrayUtils";
import MenuItemStyled from "styles/components/MenuItemStyled";
import { paletteTheme } from "styles/themes/palette";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Options } from "constants/options/option";
import Select from "components/atoms/select//Select";

interface SelectProps extends SelectPropsMaterialUI {
  disableOnUnique?: boolean;
  disabled?: boolean;
  emptyOnAddition?: boolean;
  multiple?: boolean;
  sx?: SxProps<Theme>;
  options: Array<Options>;
  value?: Options;
  onChange: (option: SelectChangeEvent) => void;
}

export const SelectAccount: FunctionComponent<SelectProps> = ({
  id,
  sx = { width: 260 },
  value,
  options,
  multiple = false,
  disableOnUnique = false,
  disabled,
  onChange,
  ...rest
}) => {
  const optionsUnique = arrayUtils.arrayOfUnique(options)

  return (
    <FormControl>
      <Select
        {...rest}
        labelId={id}
        value={value ? value.id : ""}
        multiple={multiple}
        MenuProps={{
          PaperProps: {
            elevation: 0,
            sx: {
              borderRadius: "0 0 2px 2px",
            }
          },
          MenuListProps: {
            sx: {
              border: `1px solid ${paletteTheme.colors.GRAY}`,
              borderRadius: "0 0 2px 2px",
              padding: 0,
              margin: 0,
              boxShadow: "none",
            }
          }
        }}
        IconComponent={(props) => <KeyboardArrowDown fontSize="small" {...props} />}
        disabled={(disableOnUnique && optionsUnique.length === 1) || disabled}
        onChange={onChange}
        sx={{
          height: "24px",
          width: "260px",
          "& .MuiSelect-select": { paddingTop: 0, paddingBottom: "1px" }
        }}
      >
        {optionsUnique.length === 1 &&
          <MenuItemStyled
            key={optionsUnique[0].id}
            value={optionsUnique[0].id}>
            {optionsUnique[0].name}
          </MenuItemStyled>}
        {optionsUnique.length > 1 && optionsUnique.map(item =>
          <MenuItemStyled
            key={item.id}
            value={item.id}>
            {item.name}
          </MenuItemStyled>)}
      </Select>
    </FormControl>);
}
