import React, { FunctionComponent } from "react";
import styles from "components/atoms/button/more-actions/MoreButton/moreButton.module.scss";
import { MoreVertical } from "components/atoms/icons/MoreVertical";

interface MoreButtonProps {
  clickHandler: () => void;
}

export const MoreButton: FunctionComponent<MoreButtonProps> = ({ clickHandler }) => {
  return (
    <button className={styles["button"]} onClick={clickHandler}>
      <MoreVertical />
    </button>
  );
};
