import React, {
    FunctionComponent,
    useState,
} from "react";
import CardPopup from "components/atoms/card/CardPopup";
import { ReportSetup } from "components/molecules/dialog/ScheduleReport/ReportSetup";
import { ScheduledReportConfirmation } from "components/molecules/dialog/ScheduleReport/ScheduledReportConfirmation";
import { ReportRequestFilters, ScheduledReport } from "interfaces/report/report";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "components/molecules/dialog/ScheduleReport/scheduleReport.module.scss";

export interface DialogProps {
    openDialog: boolean;
    filters: ReportRequestFilters;
    onCloseDialog: () => void;
}

export type DialogType =
    "REPORT_SETUP" |
    "SCHEDULED_REPORT_CONFIRMATION";

export const ScheduleReport: FunctionComponent<DialogProps> = ({
    openDialog,
    onCloseDialog,
    filters,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [currentDialog, setCurrentDialog] = useState<DialogType>("REPORT_SETUP");
    const [report, setReportData] = useState<ScheduledReport>(undefined);

    const handleSetDialog = (dialog: DialogType) => {
        setLoading(true);
        // timeout is added to indicate to users that the dialogs are switching
        setTimeout(() => {
            setCurrentDialog(dialog ?? undefined);
            setLoading(false);
        }, 500);
    };

    const handleSetReportData = (report: ScheduledReport) => {
        setReportData(report);
    };

    const handleConfirmSubmit = () => {
        handleSetDialog("SCHEDULED_REPORT_CONFIRMATION");

    };

    return (
        <Dialog
            open={openDialog}
            onClose={onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={styles.dialog}
            style={{
                width: "100vw"
            }}
        >

            <CardPopup className={styles.card}>
                {loading &&
                    <div className={`flex flex-row-center flex-col-center ${styles["loader-wrap"]}`}>
                        <CircularProgress color="primary" disableShrink size={100} />
                    </div>
                }
                {!loading &&
                    <>
                        {currentDialog === "REPORT_SETUP" &&
                            <ReportSetup
                                filters={filters}
                                onCloseDialog={onCloseDialog}
                                onSetReportData={handleSetReportData}
                                confirmSubmit={handleConfirmSubmit}
                            />
                        }
                        {currentDialog === "SCHEDULED_REPORT_CONFIRMATION" &&
                            <ScheduledReportConfirmation
                                onCloseDialog={onCloseDialog}
                            />
                        }
                    </>
                }
            </CardPopup>
        </Dialog>
    )
}

