import { DOCUMENT_EXPORT_DOCUMENTS_ROUTE } from "constants/routes/ApiRoutes";
import {
  BlobWithFilename,
  httpService,
} from "services/HttpService";
import { DocumentRequest } from "interfaces/document/document";

const downloadDocument = (documentRequest: DocumentRequest): Promise<BlobWithFilename> => {
  const downloadReportURL = DOCUMENT_EXPORT_DOCUMENTS_ROUTE
    .concat(`?accountId=${documentRequest.accountId}`,
      `&companyId=${documentRequest.companyId}`,
      `&merchantId=${documentRequest.merchantId}`,
      `&settlementMonth=${documentRequest.settlementMonth}`);
  return httpService.getReadableStreamWithFilename(downloadReportURL);
}

export const documentService = {
  downloadDocument,
}
