import React, { FunctionComponent } from "react";
import Card from "components/atoms/card/Card";
import { HistogramSelectorLine } from "interfaces/histogram/histogram";
import { useMediaQueryUtil } from "hooks/styles/useMediaQuery";
import { HistogramHeader } from "components/molecules/histogram/HistogramHeader";
import { HistogramBody } from "components/molecules/histogram/HistogramBody";
import styles from "components/molecules/histogram/histogram.module.scss";

interface HistogramProps {
    chartTitle: string,
    linedChartLabel: string,
    barChartLabel: string,
    lineData: number[],
    barData: number[],
    linedChartAxisLabel?: string,
    barChartAxisLabel?: string,
    xAxis: string[],
    period: string,
    onChangePeriod: (value: string) => void,
    amount: string,
    amountSelectorOptions: HistogramSelectorLine[],
    onChangeAmount: (value: string) => void,
    loading: boolean,
}

export const Histogram: FunctionComponent<HistogramProps> = ({
    chartTitle,
    period,
    onChangePeriod,
    amount,
    amountSelectorOptions,
    onChangeAmount,
    linedChartLabel,
    barChartLabel,
    lineData,
    barData,
    linedChartAxisLabel,
    barChartAxisLabel,
    xAxis,
    loading,
}) => {

    const { smDown, lgUp } = useMediaQueryUtil();

    return (
        <Card>
            <div className={`flex flex-col ${styles.wrap}`}>
                <div className={`flex ${styles["graph-header-area"]}  ${smDown || lgUp ? "flex-col" : "flex-row"}`}>
                    <HistogramHeader
                        chartTitle={chartTitle}
                        period={period}
                        amount={amount}
                        amountSelectorOptions={amountSelectorOptions}
                        onChangePeriod={onChangePeriod}
                        onChangeAmount={onChangeAmount}
                    />
                </div>
                <div className="flex">
                    <HistogramBody
                        linedChartLabel={linedChartLabel}
                        barChartLabel={barChartLabel}
                        lineData={lineData}
                        barData={barData}
                        linedChartAxisLabel={linedChartAxisLabel}
                        barChartAxisLabel={barChartAxisLabel}
                        xAxis={xAxis}
                        period={period}
                        onChangePeriod={onChangePeriod}
                        amount={amount}
                        amountSelectorOptions={amountSelectorOptions}
                        onChangeAmount={onChangeAmount}
                        loading={loading}
                    />
                </div>
            </div>
        </Card>
    );
}
