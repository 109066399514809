import React, {FunctionComponent} from "react";
import {SvgIcon, SvgIconProps} from "@mui/material";

const UserEdit: FunctionComponent<SvgIconProps> = ({...rest}) =>
    (<SvgIcon  {...rest} viewBox="-3 -4 18 18">
        <path d="M0 7.66962V9.43953H4.83445V8.32448H1.14818V7.66962C1.14818 7.29204 3.03966 6.43068 4.83445 6.43068C5.41458 6.43658 5.98867 6.51327 6.54463 6.65487L7.46318 5.75811C6.61715 5.48083 5.7409 5.32743 4.83445 5.30973C3.22095 5.30973 0 6.09439 0 7.66962ZM4.83445 0C3.49893 0 2.41722 1.05605 2.41722 2.35988C2.41722 3.66372 3.49893 4.71976 4.83445 4.71976C6.16996 4.71976 7.25167 3.66372 7.25167 2.35988C7.25167 1.05605 6.16996 0 4.83445 0ZM4.83445 3.53982C4.16971 3.53982 3.62583 3.01475 3.62583 2.35988C3.62583 1.70501 4.16971 1.17994 4.83445 1.17994C5.49918 1.17994 6.04306 1.71091 6.04306 2.35988C6.04306 3.00885 5.50522 3.53982 4.83445 3.53982ZM11.9048 5.51622L11.3005 6.10619L10.0617 4.92625L10.666 4.33628C10.7281 4.27683 10.8117 4.24353 10.8987 4.24353C10.9857 4.24353 11.0692 4.27683 11.1313 4.33628L11.9048 5.09145C12.0317 5.21534 12.0317 5.42183 11.9048 5.54572V5.51622ZM6.04306 8.81416L9.70515 5.23894L10.944 6.41888L7.31814 10H6.04306V8.81416Z"/>
    </SvgIcon>)


export default UserEdit;
