import {
  AmountRangeConfig,
  AutocompleteCheckboxConfig,
  CommonFilterProps,
  DatePickerConfig,
  MultichoiceConfig,
  PerimeterConfig,
  QueryAndResetConfig,
  TextFieldConfig,
} from "components/molecules/filters/FilterComponentsWrappers/useWrappedFilterComponents";

export interface ColParams {
  colSpan?: number;
  colStart?: number;
  hide?: boolean;
}

export interface ColumnConfig {
  xs?: ColParams;
  sm?: ColParams;
  md?: ColParams;
  lg?: ColParams;
}

export interface FilterColumn {
  name: string;
  component: React.ReactNode;
  config?: ColumnConfig;
  index?: number;
  styles?: React.CSSProperties;
  className?: string;
}

export interface FiltersFieldConfigs {
  sharedFilterProps: CommonFilterProps;
  perimeterConfig: PerimeterConfig;
  datePickerConfig: DatePickerConfig;
  amountRangeConfig: AmountRangeConfig;
  multiChoiceConfig: MultichoiceConfig;
  textFieldConfig: TextFieldConfig;
  autoCompleteCheckboxConfig: AutocompleteCheckboxConfig;
  queryAndResetConfig: QueryAndResetConfig;
}

export enum FilterFieldType {
  "AUTOCOMPLETE",
  "TEXT",
  "SELECT",
  "MULTISELECT",
  "RANGE",
  "DATE",
  "CHECKBOX",
  "QUERY",
}

export enum TransactionType {
  "ACQUIRED",
  "ACCEPTED",
}
