import React, { FunctionComponent, useMemo } from "react";
import { KeyNumbersCards } from "components/molecules/keyNumbersCards";
import { BoxProps } from "@mui/material";
import { KeyNumberElement, KeyNumbers } from "interfaces/transaction/transaction";
import { PORTFOLIO_KEY_NUMBERS } from "constants/portfolio/portfolio";

interface DailyKeyNumbersDisplayProps extends BoxProps {
  keyNumbers?: KeyNumbers;
  distributorMode: boolean;
  isCompany?: boolean;
}

export const PortfolioKeyNumbersDisplay: FunctionComponent<DailyKeyNumbersDisplayProps> = ({
  isCompany,
  keyNumbers = {
    numberOfMerchant: 0,
    totalDistributorComission: 0,
  },
  distributorMode,
  ...rest
}) => {
  const keyNumberElements: KeyNumberElement[] = useMemo(
    () => {
      const result = [ isCompany ? PORTFOLIO_KEY_NUMBERS.NUMBER_OF_COMPANY : PORTFOLIO_KEY_NUMBERS.NUMBER_OF_MERCHANT];
      if (distributorMode) {
        result.push(PORTFOLIO_KEY_NUMBERS.TOTAL_DISTRIBUTOR_COMISSION);
      }

      return result;
    },
    [distributorMode, isCompany]
  );

  return <KeyNumbersCards keyNumberElements={keyNumberElements} keyNumbers={keyNumbers} {...rest} />;
};
