import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Typography} from "@mui/material";
import {paletteTheme} from "../../../styles/themes/palette";
import {Entity} from "../../../interfaces/perimeter/perimeter";
import {REFERENTIAL_STATUS} from "../../../constants/referential/referential";

interface AutocompleteChipOptionProps extends React.HTMLAttributes<HTMLLIElement> {
    option: Entity,
    selectedOptions: Entity[],
    translatedLabel?: boolean
    active?: boolean
    search?: string;
}

const AutocompleteChipOption: FunctionComponent<AutocompleteChipOptionProps> = ({
                                                                                    option,
                                                                                    selectedOptions,
                                                                                    translatedLabel = true,
                                                                                    search,
                                                                                    ...rest
                                                                                }) => {

    const map = Object.values(selectedOptions).reduce((acc, next) => ({...acc, [next.id]: next}), {});
    const checked = !!map[option.id]

    function formatSearchResult(result: string, needle?: string) {
        if (needle && !checked) {
            const indexNeedle = result.search(new RegExp(needle, "ig"))
            if (indexNeedle > -1) {
                return (<>
                        {result.substr(0, indexNeedle)}
                        <b>{result.substr(indexNeedle, needle.length)}</b>
                        {result.substr(indexNeedle + needle.length)}</>
                )
            }
        }
        return result
    }


    let textBackground = checked ? paletteTheme.colors.SUCCESS : "transparent";

    const isActive = option.status === REFERENTIAL_STATUS.ACTIVE.id;

    if (checked && !isActive) {
        textBackground = paletteTheme.colors.DANGER_LIGHT;
    }

    return (
        <li {...rest}>
            <Typography
                color={isActive ? paletteTheme.colors.DEFAULT_TEXT_COLOR : paletteTheme.colors.GRAY}
                className={isActive ? "active" : "inactive"}
                lineHeight={1}
                marginLeft={0.5}
                fontWeight={checked ? 600 : 400}
                sx={{padding: "2px", borderRadius: "2px", backgroundColor: textBackground}}>
                {translatedLabel ? (<FormattedMessage id={option.name}/>) : formatSearchResult(option.name, search)}
            </Typography>
        </li>
    )
}

export default AutocompleteChipOption
